import classNames from 'classnames';

import CardOverlayWithIcon from './card-overlay-with-icon';
import styles from './card.module.scss';
import * as Helpers from 'wa-storybook/global/helpers';
import { CSSProperties, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isActive?: boolean;
  noIcon?: boolean;
  onClick?: () => null | undefined | void;
  onClickOverlayIcon?: () => void;
  ribbon?: string;
  logo?: string | null;
  image?: string | null;
  imageAlt?: string | null;
  imageTitle?: string;
  backgroundColor?: string;
  className?: Array<string> | string;
  style?: CSSStyleDeclaration;
  overlayStyle?: CSSStyleDeclaration;
  overlayImageStyle?: CSSStyleDeclaration;
  overlayClassName?: string[] | string;
  overlayImageClassName?: Array<string> | string;
  imageStyle?: CSSProperties;
  imageClassName?: string;
  removeTabFocus?: boolean;
  isOffer?: boolean;
};

type State = {
  isActivityTriggered: boolean;
  imageError: boolean;
};

export default class PerksCard extends React.Component<Props, State> {
  static displayName = 'PerksCard';

  constructor(props: Props) {
    super(props);
    this.state = {
      isActivityTriggered: false,
      imageError: false,
    };
  }

  handleImageError = () => {
    this.setState({ imageError: true });
  };

  handleIconClick = () => {
    this.setState(
      {
        isActivityTriggered: !this.props.isActive,
      },
      this.props.onClickOverlayIcon,
    );
  };

  render() {
    const {
      className = '',
      style = {},
      onClickOverlayIcon,
      onClick = () => {},
      logo,
      backgroundColor,
      imageTitle = '',
      imageAlt = '',
      isActive = false,
      overlayStyle = {},
      overlayClassName = '',
      overlayImageStyle = {},
      overlayImageClassName = '',
      imageStyle,
      imageClassName,
      noIcon,
      ribbon,
      image,
      children,
      removeTabFocus,
      isOffer,
    } = this.props;

    return (
      <div
        data-hook='card'
        role='button'
        tabIndex={removeTabFocus ? -1 : 0}
        className={classNames(
          styles.card,
          Array.isArray(className)
            ? className.map(className => styles[className])
            : styles[className],
          'perks-card',
        )}
        style={style}
        onClick={onClick}
        onKeyDown={Helpers.onEnterOrSpace(onClick)}
      >
        {onClickOverlayIcon && !isOffer ? (
          <CardOverlayWithIcon
            image={image}
            imageAlt={imageAlt || ''}
            logo={logo}
            backgroundColor={backgroundColor}
            imageTitle={imageTitle}
            onClick={this.handleIconClick}
            isActive={isActive}
            style={overlayStyle}
            className={overlayClassName}
            imageStyle={overlayImageStyle}
            imageClassName={overlayImageClassName}
            isActivityTriggered={this.state.isActivityTriggered}
            icon={noIcon ? '' : 'star'}
            onImageError={this.handleImageError}
          />
        ) : (
          !!image && (
            <div
              data-hook='image'
              className={classNames(
                !imageStyle && styles.image,
                imageClassName && styles[imageClassName],
              )}
            >
              <img
                alt={imageAlt || ''}
                src={image}
                onError={this.handleImageError}
                style={imageStyle}
              />
            </div>
          )
        )}
        {ribbon && (
          <div data-hook='ribbon' className={styles.ribbon}>
            <div className={styles['ribbon__text']}>
              <div className={styles['ribbon--before']} />
              {ribbon}
              <div className={styles['ribbon--after']} />
            </div>
          </div>
        )}
        {children}
      </div>
    );
  }
}
