import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';

const wellnessSessionModule = {
  name: 'wellnessSession',
  url: '/wellness/session',
  resolver: () =>
    import(
      /* webpackChunkName: "wellness-session" */ './wellness-session-comp'
    ),
  restrictions: {
    user: [restrictions['eap_wellness']],
  },
  data: {
    title: 'components.main_menu.wellness_sessions',
    includeInMenu: false,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default wellnessSessionModule;
