const rules = [
  // if it says it's a webview, let's go with that
  'WebView',
  // iOS webview will be the same as safari but missing "Safari"
  '(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)|AppleCoreMedia',
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
  'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
  // old chrome android webview agent
  'Linux; Android',
  'CriOS',
  'Opera Mini|Opera Mobi',
];

const webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig');
const uAgent = window.navigator.userAgent;

export const isWebViewOnMobile = (userAgent = uAgent) => {
  return !!userAgent.match(webviewRegExp);
};

export const isAppleMobile = (userAgent = uAgent) => {
  return /iPhone|iPad|iPod/i.test(userAgent);
};
