import USER_ROLES from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';
import { CarePlanRoutes } from './care-plan.constants';
import { hasCarePlanAssigned } from './care-plan.helpers';

const createResolver = pageImport =>
  Promise.all([pageImport]).then(([component]) => {
    return component;
  });

const shouldGrantAccess = async () => {
  const hasCarePlan = await hasCarePlanAssigned();

  return Promise.resolve(hasCarePlan);
};

const carePlanModule = {
  carePlanOverview: {
    name: CarePlanRoutes.OVERVIEW,
    url: '/care-plan/overview',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "care-plan-overview" */ './containers/overview/overview'
        ),
      ),
    restrictions: {
      user: [restrictions['care_plans']],
    },
    shouldGrantAccess,
    data: {
      title: 'care_plan.title',
      includeInMenu: false,
      mainMenu: 'myCare',
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  carePlanActivity: {
    name: CarePlanRoutes.ACTIVITY,
    url: '/care-plan/activity/:activityId',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "care-plan-activity" */ './containers/activity-screen/activity-screen'
        ),
      ),
    restrictions: {
      user: [restrictions['care_plans']],
    },
    data: {
      title: 'care_plan.title',
      includeInMenu: false,
      mainMenu: 'myCare',
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  carePlanIntro: {
    name: CarePlanRoutes.INTRO,
    url: '/care-plan/intro',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "care-plan-intro" */ './containers/guide-screen/guide-screen'
        ),
      ),
    restrictions: {
      user: [restrictions['care_plans']],
    },
    shouldGrantAccess,
    data: {
      title: 'care_plan.title',
      includeInMenu: false,
      mainMenu: 'myCare',
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  carePlanEnd: {
    name: CarePlanRoutes.END,
    url: '/care-plan/next-steps',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "care-plan-end" */ './containers/guide-screen/guide-screen'
        ),
      ),
    restrictions: {
      user: [restrictions['care_plans']],
    },
    shouldGrantAccess,
    data: {
      title: 'care_plan.title',
      includeInMenu: false,
      mainMenu: 'myCare',
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default carePlanModule;
