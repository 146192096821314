import { useEffect, lazy, Suspense } from 'react';
import { connectWithViewport } from 'app/hermes-redux';
import { fetchCinemasPayload, reset } from './common/state/actions';
import Spinner from 'wa-storybook/components/general/spinner/spinner';
import { AppState, Dispatch } from 'globals/types';
import { Model } from './cinemas.types';
import { useRouterState } from 'router/router-state-provider';

export const referrer = 'cinema';

const CinemasHubPage = lazy(() => import('./hub/view/hub-page'));
const CinemasDetailsPage = lazy(() => import('./details/view/details-page'));
const CinemasErrorPage = lazy(() => import('./shared/error-page'));

type Props = {
  dispatch: Dispatch;
} & Model;

const Cinemas = (props: Props) => {
  const { currentRoute } = useRouterState();

  useEffect(() => {
    props.dispatch(fetchCinemasPayload());
    return () => {
      props.dispatch(reset());
    };
  }, []);

  const renderCinemas = (name: string) => {
    const cinemas = {
      cinemasDetails: <CinemasDetailsPage {...props} />,
      cinemasCodes: <CinemasHubPage {...props} showCodes referrer={referrer} />,
      cinemas: <CinemasHubPage {...props} referrer={referrer} />,
      cinemasError: <CinemasErrorPage />,
    };

    return cinemas[name] || null;
  };

  return (
    <Suspense fallback={<Spinner top='10%' />}>
      {renderCinemas(currentRoute?.name)}
    </Suspense>
  );
};

Cinemas.displayName = 'Cinemas';

export default connectWithViewport(Cinemas, (state: AppState) => {
  const {
    cinemas: { hub, details, common },
  } = state;

  return { hub, details, common };
});
