export const selfAssessmentsHub = 'selfAssessmentHub';
export const selfAssessmentAssessment = 'selfAssessmentAssessment';
export const selfAssessmentHistoricalResult = 'selfAssessmentHistoricalResult';
export const selfAssessmentResults = 'selfAssessmentResults';

export const QUESTION_TYPES = {
  MEASUREMENT_LEGACY: 'Measurement',
  MEASUREMENT: 'UnitsOfMeasurements',
  MULTIPLE_CHOICE: 'MultipleChoice',
  SINGLE_CHOICE: 'SingleChoice',
} as const;

export const genderFilters = () => [
  { value: 'male', name: polyglot.t('sign_up.gender.male') },
  { value: 'female', name: polyglot.t('sign_up.gender.female') },
  { value: 'other', name: polyglot.t('sign_up.gender.other') },
];
