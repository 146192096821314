import USER_ROLES from 'constants/user-roles.constant';
import $script from 'scriptjs';

function resolver() {
  return new Promise(resolve => {
    $script(
      'https://js.braintreegateway.com/web/dropin/1.33.0/js/dropin.min.js',
      () => {
        return Promise.all([import('./checkout')]).then(([component]) => {
          resolve(component);
        });
      },
    );
  });
}

const checkoutModule = {
  name: 'checkout',
  url: '/checkout/:step',
  resolver,
  data: {
    includeInMenu: false,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default checkoutModule;
