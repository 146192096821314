// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** function to get dimensions
  usage example to get \`tablet\` value from \`\$page-max-widths\`
  .class {
    max-width: dim(\$page-max-widths, tablet);
  }

 */
.perks-newsletter-consent-module__container___BOa0F {
  margin: 28px 0 16px;
}

.perks-newsletter-consent-module__content___XZt7p {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: var(--neutral15);
}

.perks-newsletter-consent-module__label___KeNFS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 16px;
}

.perks-newsletter-consent-module__text___L1vsE {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./node_modules/wa-storybook/styles/tools/functions/_widths.scss","webpack://./client/app/react-components/walk-through/perks-newsletter-consent.module.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;ACEA;EACE,mBAAA;AAMF;;AAHA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,4BAAA;AAMF;;AAHA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AAMF;;AAHA;EACE,gBAAA;AAMF","sourcesContent":["/** function to get dimensions\n  usage example to get `tablet` value from `$page-max-widths`\n  .class {\n    max-width: dim($page-max-widths, tablet);\n  }\n\n */\n\n@function dim($prop, $key) {\n  @return map-get($prop, $key);\n}\n","@import '~wa-storybook/styles/utils.scss';\n\n.container {\n  margin: 28px 0 16px;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  padding: 16px;\n  background: color(neutral15);\n}\n\n.label {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: rem(14);\n  font-weight: 600;\n  margin-bottom: 16px;\n}\n\n.text {\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `perks-newsletter-consent-module__container___BOa0F`,
	"content": `perks-newsletter-consent-module__content___XZt7p`,
	"label": `perks-newsletter-consent-module__label___KeNFS`,
	"text": `perks-newsletter-consent-module__text___L1vsE`
};
export default ___CSS_LOADER_EXPORT___;
