import { useState, useEffect } from 'react';
import type { Model as SessionModel } from 'state/session/types';
import type { Dispatch } from 'globals/types';
import { connectWithProvider } from 'app/hermes-redux';
import ToggleComp from 'wa-storybook/components/inputs/toggle-comp/toggle-comp';
import { updateEmailNotification } from 'pages/settings/state/actions';
import { AnalyticsEvent } from 'services/google-analytics/analytics-events';
import { useInView } from 'react-intersection-observer';
import styles from './perks-newsletter-consent.module.scss';

type Props = {
  dispatch: Dispatch;
  session: SessionModel;
};

const PerksNewsletterConsent = ({ dispatch, session: { user } }: Props) => {
  const hasUserConsent = !!user && user.notification_email.benefit_news;
  const [isToggleOn, setToggle] = useState<boolean>(hasUserConsent);
  const [saving, setIsSaving] = useState<boolean>(false);
  const [newsMsgContainerRef, inView] = useInView();

  useEffect(() => {
    setToggle(hasUserConsent);
    setIsSaving(false);
  }, [hasUserConsent]);

  const handleChange = () => {
    if (!saving) {
      setIsSaving(true);
      setToggle(!isToggleOn);
      AnalyticsEvent.Perks.Newsletter.Walkthrough.OptOutOfNewsletter.selected();
      dispatch(updateEmailNotification('benefit_news', !hasUserConsent));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <label className={styles.label}>
          {polyglot.t('walk_through.shopandsave.perks_newsletter')}
          <ToggleComp
            theme='green'
            showIcons={false}
            checked={isToggleOn}
            onChange={handleChange}
            data-hook='walkthrough--perks-newsletter-consent-toggle'
            trackClassName='perks-newsletter-consent'
            trackUncheckedClassName='react-toggle-track--unchecked-perks-newsletter-consent'
            thumbClassName='react-toggle-thumb--perks-newsletter-consent'
            tabIndex={inView ? 0 : -1}
          />
        </label>

        <span className={styles.text} ref={newsMsgContainerRef}>
          {polyglot.t('walk_through.shopandsave.never_miss_savings')}
        </span>
      </div>
    </div>
  );
};

PerksNewsletterConsent.displayName = 'PerksNewsletterConsent';

export default connectWithProvider(
  ({ session }) => ({ session }),
  PerksNewsletterConsent,
);
