import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "leaderboard" */ './view/leaderboard-comp'),
    import(/* webpackChunkName: "leaderboard" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('leaderboard', reducer.leaderboardReducer);
    return component;
  });
}

const leaderboardModule = {
  name: 'leaderboard',
  url: '/leaderboard',
  restrictions: {
    user: [restrictions['social_recognition_leaderboard']],
  },
  resolver,
  data: {
    title: 'components.main_menu.leaderboard',
    mainMenu: 'leaderboard',
    section: 'company',
    includeInMenu: true,
    order: 3,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default leaderboardModule;
