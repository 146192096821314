/* eslint-disable */
export const EMAIL_REGEX = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
export const PHONE_REGEX = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
export const POSTCODE_REGEX = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i;
export const PHONE_REGEX_MATCH = /\+?\(?\d*\)? ?\(?\d+\)?\d*([\s.\/-]?\d{2,})+/g;
export const THIRD_POSITION_IN_NUMBER_STRING = /\B(?=(\d{3})+(?!\d))/g;
export const BETWEEN_ONE_AND_TWO = /.{1,2}/g;
export const YMD_DATE = /([0-9]{4})-([0-9]{2})-([0-9]{2})/;
export const NUMERICAL_AND_SYMBOLS = /([0-9~!@#$%£^&*()_|+\=?;:",.<>\{\}\[\]\\\/])/g;
export const ALPHABETICAL_AND_SYMBOLS = /[^0-9]/g;
export const isInternalAbsoluteUrlRegex = /^(\/{2}|https?:\/\/)?([\w\-.]+)?lifeworks\.com/;
export const isInternalStaticUrlRegex = /^(\/{2}|https?:\/\/)?(static\.)?lifeworks\.com/;
export const removeUrlHostRegex = /^.*\/\/[^/]+/;

/* eslint-enable */
