import { restrictions } from '../../../../state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { HUB_NAME } from '../hub/constants';

const baseModule = {
  resolver: () =>
    import(/* webpackChunkName: "challenges-activity" */ './activity'),
  restrictions: {
    user: [restrictions['eap_challenges']],
  },
} as const;

const baseData = {
  includeInMenu: false,
  authorizedRoles: [USER_ROLES.employee],
  hideFooter: true,
} as const;

export const habitModuleName = 'challengesHabitActivity';
export const stepsModuleName = 'challengeActivity';
export const challengeDetails = 'challengeDetails';

const activityModule = {
  stepActivity: {
    ...baseModule,
    name: stepsModuleName,
    url: '/challenges/:participationType/:challengeId/activity',
    checkConsent: () => ({
      consentType: 'challenges',
      customBackLink: HUB_NAME,
    }),
    data: {
      ...baseData,
      mainMenu: 'wellBeing',
      section: 'wellBeing',
    },
  },
  habitActivity: {
    ...baseModule,
    name: habitModuleName,
    url: '/challenges/habit/:challengeId/activity/:participationType?',
    checkConsent: () => ({
      consentType: 'challenges',
      customBackLink: HUB_NAME,
    }),
    data: {
      ...baseData,
      mainMenu: 'habitChallenge',
      section: 'life',
    },
  },
} as const;

export default activityModule;
