import USER_ROLES from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';

const trustedAppsAndWebsitesModule = {
  name: 'trustedAppsAndWebsites',
  url: '/life/employee-assistance/trusted-apps-websites',
  resolver: () =>
    import(
      /* webpackChunkName: "trustedAppsAndWebsites" */ './trusted-apps-and-websites-container'
    ),
  restrictions: {
    user: [restrictions['eap']],
  },
  data: {
    authorizedRoles: [USER_ROLES.employee],
    includeInMenu: false,
    mainMenu: 'employeeAssistance',
    section: 'life',
    title: 'components.main_menu.trusted_apps_and_websites',
  },
} as const;

export default trustedAppsAndWebsitesModule;
