// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** function to get dimensions
  usage example to get \`tablet\` value from \`\$page-max-widths\`
  .class {
    max-width: dim(\$page-max-widths, tablet);
  }

 */
.select-dropdown-module__item___xCDis {
  color: var(--neutral100, #2c2e30);
  font-size: 0.875rem;
  cursor: pointer;
  padding: 10px;
  z-index: 9999;
}
.select-dropdown-module__item--active___bMcOi {
  background-color: rgba(var(--base-rgb, 244, 244, 247), 0.3);
}
.select-dropdown-module__item--inactive___y9AoE {
  background-color: var(--surface1, #fff);
}

.select-dropdown-module__wrapper___kZvPs {
  margin-top: 10px;
  display: none;
  background-color: var(--surface1, #fff);
  border: 1px solid var(--base, #f4f4f7);
  border-top: 0;
  max-height: 310px;
  overflow: auto;
  -ms-overflow-style: none;
  position: absolute;
  z-index: 10;
  box-shadow: 0 2px 12px 0 rgba(var(--neutral100-rgb, 44, 46, 48), 0.1);
  width: 100%;
}
.select-dropdown-module__wrapper--visible___eSILK {
  display: block;
}

.select-dropdown-module__title___e8xUN {
  color: var(--neutral100, #2c2e30);
  font-size: 0.875rem;
}`, "",{"version":3,"sources":["webpack://./node_modules/wa-storybook/styles/tools/functions/_widths.scss","webpack://./client/app/react-components/inputs-comp/select-comp/select-dropdown.module.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;ACEA;EACE,iCAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,aAAA;AAMF;AALE;EACE,2DAAA;AAOJ;AALE;EACE,uCAAA;AAOJ;;AAHA;EACE,gBAAA;EACA,aAAA;EACA,uCAAA;EACA,sCAAA;EACA,aAAA;EACA,iBAAA;EACA,cAAA;EACA,wBAAA;EACA,kBAAA;EACA,WAAA;EACA,qEAAA;EACA,WAAA;AAMF;AAJE;EACE,cAAA;AAMJ;;AAFA;EACE,iCAAA;EACA,mBAAA;AAKF","sourcesContent":["/** function to get dimensions\n  usage example to get `tablet` value from `$page-max-widths`\n  .class {\n    max-width: dim($page-max-widths, tablet);\n  }\n\n */\n\n@function dim($prop, $key) {\n  @return map-get($prop, $key);\n}\n","@import '~wa-storybook/styles/utils.scss';\n\n.item {\n  color: color(neutral100);\n  font-size: rem(14);\n  cursor: pointer;\n  padding: $spacing * 0.5;\n  z-index: zIndex(select-dropdown);\n  &--active {\n    background-color: rgba(color(base, true), 0.3);\n  }\n  &--inactive {\n    background-color: color(surface1);\n  }\n}\n\n.wrapper {\n  margin-top: 10px;\n  display: none;\n  background-color: color(surface1);\n  border: 1px solid color(base);\n  border-top: 0;\n  max-height: 310px;\n  overflow: auto;\n  -ms-overflow-style: none;\n  position: absolute;\n  z-index: zIndex(select-dropdown-wrapper);\n  box-shadow: 0 2px 12px 0 rgba(color(neutral100, true), 0.1);\n  width: 100%;\n\n  &--visible {\n    display: block;\n  }\n}\n\n.title {\n  color: color(neutral100);\n  font-size: rem(14);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `select-dropdown-module__item___xCDis`,
	"item--active": `select-dropdown-module__item--active___bMcOi`,
	"item--inactive": `select-dropdown-module__item--inactive___y9AoE`,
	"wrapper": `select-dropdown-module__wrapper___kZvPs`,
	"wrapper--visible": `select-dropdown-module__wrapper--visible___eSILK`,
	"title": `select-dropdown-module__title___e8xUN`
};
export default ___CSS_LOADER_EXPORT___;
