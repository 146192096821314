import styles from './custom-inner.module.scss';
import ReadMore from 'wa-storybook/components/general/read-more-comp/read-more-comp';

type Props = {
  title: string;
  description: string;
};

const CustomInner = (props: Props) => (
  <div className={styles.container}>
    <div className={styles.title}>{props.title}</div>
    <div className={styles.description}>
      <ReadMore
        text={props.description}
        limit={props.description && props.description.length >= 175 ? 80 : 175}
        hasButton
        ariaLabel={props.title}
        buttonClass={[
          'read-more--primary',
          'read-more--link',
          'read-more--centre',
        ]}
      />
    </div>
  </div>
);

CustomInner.displayName = 'CustomInner';

export default CustomInner;
