import USER_ROLES from 'constants/user-roles.constant';

const mfaSetupModule = {
  name: 'mfaSetup',
  url: '/mfa-setup/landing',
  resolver: () => import('./view/mfa-setup'),
  data: {
    title: 'mfa_setup.start.tab_title_telus',
    showEmptyNavbar: true,
    authorizedRoles: [USER_ROLES.all],
  },
} as const;

export default mfaSetupModule;
