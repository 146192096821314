import USER_ROLES from 'constants/user-roles.constant';
import { CareAssessmentsRoutes } from './care-assessments.constants';
import {
  shouldGrantAccessToCareAssessments,
  shouldGrantAccessToRetake,
} from './care-assessments.helpers';
import { restrictions } from 'state/features-list/constants';

const createResolver = pageImport =>
  Promise.all([pageImport]).then(([component]) => {
    return component;
  });

const careAssessmentsModule = {
  questionnaire: {
    name: CareAssessmentsRoutes.CARE_ASSESSMENTS_QUESTIONNAIRE,
    url: '/care-assessments/:screenId?',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "care-assessments" */ './containers/assessments/assessments'
        ),
      ),
    restrictions: {
      user: [restrictions['care_plans']],
    },
    shouldGrantAccess: shouldGrantAccessToCareAssessments,
    data: {
      title: 'care_assessments.page_title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  retake: {
    name: CareAssessmentsRoutes.CARE_ASSESSMENTS_RETAKE,
    url: '/care-assessments/retake',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "retake-assessments" */ './containers/retake-assessments/retake-assessments'
        ),
      ),
    restrictions: {
      user: [restrictions['care_plans']],
    },
    shouldGrantAccess: shouldGrantAccessToRetake,
    data: {
      title: 'care_assessments.page_title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default careAssessmentsModule;
