import { isSharedAccount } from 'stores/user-store';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';
import { limitedAccountCreateRouteName } from './limited-account.constants';

function resolver(loadComponent) {
  return () =>
    Promise.all([
      loadComponent(),
      import(/* webpackChunkName: "limited-account" */ './state/reducer'),
    ]).then(([component, reducer]) => {
      injectAsyncReducer('limitedAccount', reducer.limitedAccountReducer);
      return component;
    });
}

const limitedAccountModule = {
  base: {
    name: 'limitedAccount',
    url: '/limited-account',
    resolver: resolver(
      () =>
        import(
          /* webpackChunkName: "limited-account" */ './view/containers/upgrade-limited-account/upgrade-limited-account'
        ),
    ),
    shouldGrantAccess() {
      return Promise.resolve(isSharedAccount());
    },
    data: {
      title: 'components.main_menu.limited_account_upsell.upgrade',
      authorizedRoles: [USER_ROLES.employee],
      hideFooter: true,
    },
  },
  create: {
    name: limitedAccountCreateRouteName,
    url: '/limited-account/create',
    resolver: resolver(
      () =>
        import(
          /* webpackChunkName: "create-limited-account" */ './view/containers/create-limited-account/create-limited-account'
        ),
    ),
    shouldGrantAccess() {
      return Promise.resolve(isSharedAccount());
    },
    data: {
      title: 'components.main_menu.limited_account_upsell.create',
      authorizedRoles: [USER_ROLES.employee],
      hideFooter: true,
    },
  },
  confirmation: {
    name: 'limitedAccountConfirmation',
    url: '/limited-account/confirmation',
    resolver: resolver(
      () =>
        import(
          /* webpackChunkName: "confirm-limited-account" */ './view/containers/confirm-limited-account/confirm-limited-account'
        ),
    ),
    data: {
      title: 'components.main_menu.limited_account_upsell.confirmation',
      authorizedRoles: [USER_ROLES.all],
      hideFooter: true,
    },
  },
};

export default limitedAccountModule;
