import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import * as api from './api';
import { showToast } from 'toaster-comp/state/actions';
import { Action } from 'redux';
import { ToastType } from 'react-components/toaster-comp/state/types';
import { goTo } from 'router/navigation';
import type { CheckoutAction } from './types';
import {
  requestTokenAction,
  submitOrderAction,
  submitSpotRewardsOrderAction,
} from './constants';

import { triggerGAEvent } from '../helpers';

export function* requestToken(): Generator<any, any, any> {
  try {
    const tokenRes = yield call(api.requestToken);
    yield put(actions.tokenReceived(tokenRes.body.client_token));
  } catch (err: any) {
    yield put(
      showToast({
        type: ToastType.ERROR,
        message: polyglot.t('global.update_error'),
      }) as unknown as Action,
    );
    yield put(actions.tokenFailed());
  }
}

export function* submitOrder(action: CheckoutAction): Generator<any, any, any> {
  try {
    // @ts-expect-error - TS2769 - No overload matches this call.
    const orderRes = yield call(
      api.submitOrder,
      action.btNonce,
      action.order,
      action.email,
    );

    yield put(actions.orderFulfilled(orderRes.body.transaction_id));

    if (action.order) {
      triggerGAEvent(action.order, orderRes.body);
    }

    yield call(goTo, 'checkout', { step: 'confirmation' });
  } catch (err: any) {
    if (err.error.code === 20501 || err.error.code === 20601) {
      yield put(
        showToast({
          type: ToastType.ERROR,
          message: polyglot.t('checkout.payment_failed_out_of_stock'),
        }) as unknown as Action,
      );
    } else {
      yield put(
        showToast({
          type: ToastType.ERROR,
          message: polyglot.t('checkout.payment_failed_generic'),
        }) as unknown as Action,
      );
    }
    yield put(actions.orderFailed(err));
  }
}

export function* submitSpotRewardsOrder(
  action: CheckoutAction,
): Generator<any, any, any> {
  try {
    // @ts-expect-error - TS2769 - No overload matches this call.
    const orderRes = yield call(
      api.submitSpotRewardsOrder,
      action.order,
      action.paymentMethod,
      action.email,
    );
    yield put(actions.orderFulfilled(orderRes.body.transaction_id));
    yield call(goTo, 'checkout', { step: 'confirmation' });
  } catch (err: any) {
    if (err.error.code === 20501 || err.error.code === 20601) {
      yield put(
        showToast({
          type: ToastType.ERROR,
          message: polyglot.t('checkout.payment_failed_out_of_stock'),
        }) as unknown as Action,
      );
    } else {
      yield put(
        showToast({
          type: ToastType.ERROR,
          message: polyglot.t('checkout.payment_failed_generic'),
        }) as unknown as Action,
      );
    }
    yield put(actions.orderFailed(err));
  }
}

export default function* listener(): Generator<any, any, any> {
  yield takeLatest(requestTokenAction, requestToken);
  yield takeLatest(submitOrderAction, submitOrder);
  yield takeLatest(submitSpotRewardsOrderAction, submitSpotRewardsOrder);
}
