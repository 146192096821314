import type { Model, ModalAction } from './types';
import isUndefined from 'lodash/isUndefined';

export const initialState = {
  isModalOpen: false,
  template: null,
  payload: null,
  style: {},
  isClosable: true,
  index: 0,
  className: '',
};

export function modalReducer(
  state: Model = initialState,
  action: ModalAction,
): Model {
  switch (action.type) {
    case 'MODAL/OPEN':
      return Object.assign({}, state, {
        isModalOpen: true,
        template: action.template,
        payload: action.payload,
        style: action.style,
        isClosable: isUndefined(action.isClosable) ? true : action.isClosable,
        className: action.className,
        fitContents: action.fitContents,
        shouldCloseOnOverlayClick: action.shouldCloseOnOverlayClick,
      });

    case 'MODAL/CLOSE':
      return initialState;

    case 'MODAL/CHANGE_SLIDE':
      return Object.assign({}, state, { index: action.index });

    case 'MODAL/UPDATE_PAYLOAD':
      return Object.assign({}, state, { payload: action.payload });

    default:
      return state;
  }
}
