import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

// this route cannot be navigated to from within the app
// it is reached by requesting a password reset link
// and clicking the link
function resolver() {
  return Promise.all([
    import(
      /* webpackChunkName: "reset-password" */ './view/reset-password-comp'
    ),
    import(/* webpackChunkName: "reset-password" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('resetPassword', reducer.resetPasswordReducer);
    return component;
  });
}

const resetPasswordModule = {
  name: 'resetPassword',
  url: '/reset-password/:c',
  data: {
    title: 'components.main_menu.reset_pwd',
    authorizedRoles: [USER_ROLES.all],
  },
  resolver,
};

export default resetPasswordModule;
