import { matchPath } from 'react-router-dom';

import type { Module } from 'router/types';

import { getFeature } from 'stores/features-list-store';
import { injectParamsInUrl } from 'globals/helpers';

/**
 * IMPORTANT:
 * This mapping is used to trigger the Wondering study based on the route.
 * If there are multiple routes matching the same URL and query params, the first one will be triggered.
 */
const routeTriggerMapping = {
  '/life/employee-assistance': 'support-resources-main-page',
  '/wellbeing': 'wellbeing-main-page',
  '/programs': 'carenow-main-page',
  '/assessments': 'assessments-main-page',
  '/challenges': 'challenges-main-page',
  '/tiering': 'tiering-main-page',
  '/offers/tiering-rewards': 'rewards-main-page',
  '/home': 'home-page',
  '/wellbeing/recommendations': 'wellbeing-recommendations-main-page',
  // Corporate challenges
  '/challenges/corporate/:challengeId': 'challenges-corporate-info-and-join',
  '/challenges/corporate/:challengeId/activity':
    'challenges-corporate-steps-activity',
  '/challenges/habit/:challengeId/activity/corporate':
    'challenges-corporate-habit-activity',
  '/challenges/habit/:challengeId/select-habits?participationType=corporate':
    'challenges-corporate-select-habits',
  // Individual challenges
  '/challenges/individual/:challengeId': 'challenges-individual-info-and-join',
  '/challenges/individual/:challengeId/activity':
    'challenges-individual-steps-activity',
  '/challenges/habit/:challengeId/activity/individual':
    'challenges-individual-habit-activity',
  '/challenges/habit/:challengeId/select-habits?participationType=individual':
    'challenges-individual-select-habits',
};

export const showWonderingStudy = (route: Module) => {
  if (!window.wondering) return;

  const wonderingFeature = getFeature('ribbon');
  if (!wonderingFeature?.value) return;

  if (!route.url) return;

  const pageUrl = injectParamsInUrl(route.url, route.params);

  for (const mappingPath in routeTriggerMapping) {
    const [routePath, routeQuery] = mappingPath.split('?');
    const [pageUrlPath, pageUrlQuery] = pageUrl.split('?');

    const isPathMatched = matchPath(
      { path: routePath || '', caseSensitive: false, end: true },
      pageUrlPath || '',
    );

    if (isPathMatched) {
      if (!routeQuery || compareQueryParams(routeQuery, pageUrlQuery)) {
        window.wondering('trigger', routeTriggerMapping[mappingPath]);
        break;
      }
    }
  }
};

function compareQueryParams(routeQuery, pageQuery) {
  if (!routeQuery) return true;
  if (!pageQuery) return false;

  const routeParams = new URLSearchParams(routeQuery);
  const pageParams = new URLSearchParams(pageQuery);

  for (const [key, value] of routeParams) {
    if (pageParams.get(key) !== value) {
      return false;
    }
  }

  return true;
}

export const initiliaseWondering = () => {
  const scriptSource = window.WAM.ENV.cdnEnabled
    ? `${window.WAM.ENV.cdnUrl}/app/vendor/wondering.js`
    : '/app/vendor/wondering.js';

  const wonderingScript = document.createElement('script');
  wonderingScript.type = 'text/javascript';
  wonderingScript.src = scriptSource;

  document.head.appendChild(wonderingScript);
};

export const setWonderingLocale = (locale: string) => {
  if (window.wondering) {
    window.wondering(
      'attribute',
      'locale',
      locale === 'fr_CA' ? locale : 'en_GB',
    );
  }
};
