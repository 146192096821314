import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "directory" */ './view/directory-comp'),
    import(/* webpackChunkName: "directory" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('directory', reducer.directoryReducer);
    return component;
  });
}

const directoryModule = {
  name: 'directory',
  url: '/directory',
  resolver,
  restrictions: {
    user: [restrictions['social_colleague_directory']],
  },
  data: {
    title: 'components.main_menu.colleague_directory',
    mainMenu: 'directory',
    section: 'company',
    includeInMenu: true,
    order: 1,
    authorizedRoles: [USER_ROLES.employee],
    shortcutKeyCode: 50,
  },
};

export default directoryModule;
