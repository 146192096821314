import { logout } from 'state/session/actions';
import USER_ROLES from 'constants/user-roles.constant';
import { getStore } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import('./view/view-email-browser-comp'),
    getStore().dispatch(logout(true)), // force logout
    // @ts-expect-error - TS2345 - Argument of type '([component]: [any]) => any' is not assignable to parameter of type '(value: [typeof import("/Users/jrbaron/dev/lifeworks/wa-hermes/client/app/pages/view-email-browser/view/view-email-browser-comp"), any]) => any'.
  ]).then(([component]: [any]) => {
    return component;
  });
}

const viewEmailBrowserModule = {
  name: 'viewEmailBrowser',
  url: '/view-email-browser',
  resolver,
  data: {
    authorizedRoles: [USER_ROLES.all],
  },
} as const;

export default viewEmailBrowserModule;
