export const CREDIT_CARDS = [
  { name: 'Visa', icon: require('./assets/Visa_card@2x.png') },
  { name: 'Mastercard', icon: require('./assets/Mastercard_card@2x.png') },
  { name: 'American Express', icon: require('./assets/AMEX_card@2x.png') },
] as const;

export const UNSPPORTED_CREDIT_CARDS = [
  { name: 'Discover', icon: require('./assets/Discover_card@2x.png') },
  { name: 'JCB', icon: require('./assets/JCB_card@2x.png') },
  { name: 'Diners Club', icon: require('./assets/DinersClub_card@2x.png') },
] as const;

export const DISCOUNTED_PRICE_RANGE = 'discounted_price_range';
export const DISCOUNTED_PRICE_LIST = 'discounted_price_list';
