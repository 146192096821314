import { useEffect } from 'react';

import type { Dispatch, Paging } from 'globals/types';
import type { GiftCard } from '../gift-cards.types';

import GiftCardOffer from './gift-card-offer';

import ThreeQuartersPage from 'perks/layouts/three-quarters';

import Pagination from 'pagination/pagination';
import PageTitle from 'head/state/actions';
import { referrer as spotRewardsReferrer } from '../../spot-rewards/view/spot-rewards-comp';
import { getStore } from '../../../hermes-redux';
import EmptyState from '../../shop-online/view/components/empty-state';
import Spinner from 'wa-storybook/components/general/spinner/spinner';
import { goTo } from '../../../router/navigation';

import styles from './gift-cards-list.module.scss';

type Props = {
  fetching: boolean;
  giftCards: Array<GiftCard>;
  loadNext: (limit: number, offset: number) => Promise<Dispatch>;
  paging: Paging;
  categoryTitle?: string | null;
  referrer: string;
  query?: string;
  categoryId: string;
};

export const updatePageTitle = (
  referrer: string,
  categoryTitle: string | null | undefined,
) => {
  if (!categoryTitle) return;

  getStore().dispatch(
    PageTitle.setCurrentSection(
      `${categoryTitle} - ${polyglot.t(
        referrer === spotRewardsReferrer
          ? 'components.main_menu.rewards'
          : 'components.main_menu.gift_cards',
      )}`,
    ),
  );
};

const GiftCardsList = (props: Props) => {
  useEffect(() => {
    const { paging } = props;

    props.loadNext(12, paging.offset);
    updatePageTitle(props.referrer, props.categoryTitle);
  }, [props.categoryId]);

  const { giftCards, fetching, categoryTitle, paging, loadNext, query } = props;
  const noResults = !fetching && giftCards.length === 0;
  const cta = {
    action: () => goTo('giftcards'),
    title: polyglot.t('giftcards.go_back'),
  } as const;

  return (
    <ThreeQuartersPage
      aria-label={categoryTitle || ''}
      className={styles.container}
    >
      {categoryTitle && !noResults && (
        <h2 className={styles['container__selected-category']}>
          {categoryTitle}
        </h2>
      )}
      {noResults && (
        <EmptyState
          title={`${polyglot.t('giftcards.no_gift_cards')} ${
            query ? query : ''
          }`}
          cta={cta}
        />
      )}
      {fetching && <Spinner />}
      {giftCards.length !== 0 && (
        <div>
          {giftCards.map((giftCard: GiftCard, index: number) => (
            <GiftCardOffer
              key={giftCard.id}
              index={index}
              giftCard={giftCard}
              referrer={props.referrer}
            />
          ))}
          <div className={styles['container__pagination-container']}>
            <Pagination
              prevLabel={polyglot.t('giftcards.prev')}
              nextLabel={polyglot.t('giftcards.next')}
              total={paging.totalCount / paging.limit}
              margins={1}
              range={2}
              fetching={fetching}
              forceSelected={Math.floor(paging.offset / paging.limit)}
              onPageChange={page =>
                loadNext(paging.limit, page.selected * paging.limit)
              }
            />
          </div>
        </div>
      )}
    </ThreeQuartersPage>
  );
};

export default GiftCardsList;
