import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';

import { getOriginalTierStateCacheKey } from '../pages/self-assessment/view/results/results-page.helpers';
import introspectionResults from './__generated__/introspection-results';

const apolloCache = () =>
  new InMemoryCache({
    dataIdFromObject(object) {
      switch (object.__typename) {
        case 'AssessmentItemAnswersMeasurements':
          return `${object.__typename}:${object['measurementUuid']}`;
        case 'AssessmentListItem':
        case 'AssessmentItemQuestionnaire':
        case 'AssessmentItemQuestionnaireOptionsList':
          return `${object.__typename}:${object['uuid']}`;
        case 'DigitalClinicalModuleListItem':
        case 'DigitalClinicalModule':
          return `${object.__typename}:${object['moduleId']}`;
        case 'DigitalClinicalProgram':
          return `${object.__typename}:${object['programId']}`;
        case 'CustomTierActivity':
          return `${object.__typename}:${object['activityId']}`;
        case 'ChallengeListItem':
        case 'HabitChallenge':
        case 'StepChallenge':
          return `Challenge:${object['challengeId']}`;
        case 'HistoricalAssessment':
          return `${object.__typename}:${object['archiveId']}`;
        // Objects returned here will always be specific to this user so we can just key them based on typename
        case 'AllTiers':
        case 'CurrentTier':
        case 'FetchTimeSlotSuggestionsResponse':
        case 'NextTier':
        case 'TierPoints':
        case 'TierRegressionInfo':
          /**
           * in some cases serverless are not returning the id, however
           * they guarantee that in these cases the data is the latest
           * available so we can use this sensible default
           */
          return `${object.__typename}:${object['id'] || 'current'}`;
        case 'HabitActivityFeed':
          return `${object.__typename}:${object['challengeID']}:${object['date']}`;
        case 'AssessmentItemCurrentTierProgress':
          return getOriginalTierStateCacheKey(object);
        case 'HowPersonalisationWorksPermission':
          return `${object.__typename}:${object['title']}`;
        // TODO: Remove this after we are no longer using mock, the mock data currently accidentally returns options with duplicate IDs.
        case 'CounsellorFilterOption':
          return `${object.__typename}:${object['title']}:${object['id']}`;
        case 'PreAssessmentResult':
          return `${object.__typename}:${object['id']}:${object['sessionId']}`;
        default:
          return defaultDataIdFromObject(object);
      }
    },
    possibleTypes: introspectionResults.possibleTypes,
    /*
      by default, Apollo normalises data by any field named 'id'
      challenge.introduction has child objects with an id field
      but this field represents sections / titles and is not unique
      so we need to tell Apollo not to normalise challenge introductions
      https://www.apollographql.com/docs/react/caching/cache-configuration/#:~:text=To%20disable%20normalization
     */
    typePolicies: {
      ChallengeIntroduction: {
        keyFields: false,
      },
      AssessmentType: {
        keyFields: false,
      },
      CounsellorFilter: {
        keyFields: false,
      },
      CounsellorFilterOption: {
        keyFields: false,
      },
    },
  });

export default apolloCache;
