import styles from './empty-state.module.scss';
import Button from 'wa-storybook/components/general/button/button';

type Props = {
  title: string;
  subtitle?: string;
  cta: {
    title: string | null;
    action: () => void | null;
  };
};

const EmptyState = (props: Props) => (
  <div className={styles.container}>
    <div className={styles.title}>{props.title}</div>
    {props.subtitle && <div className={styles.subtitle}>{props.subtitle}</div>}
    <div className={styles.cta}>
      <Button
        text={props.cta.title}
        onClick={props.cta.action}
        dataHook='go-back-to-perk-section'
      />
    </div>
  </div>
);

EmptyState.displayName = 'EmptyState';

export default EmptyState;
