import USER_ROLES from 'constants/user-roles.constant';

const msTeamsDoneModule = {
  name: 'msTeamsDone',
  url: '/return-to-teams',
  resolver: () =>
    import(/* webpackChunkName: "ms-teams-done" */ './ms-teams-done.comp'),
  data: {
    hideNavbar: true,
    showEmptyNavbar: true,
    title: 'ms_teams.title',
    authorizedRoles: [USER_ROLES.all],
  },
} as const;

export default msTeamsDoneModule;
