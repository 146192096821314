import USER_ROLES from 'constants/user-roles.constant';

const pageNotFoundModule = {
  name: 'pageNotFound',
  url: '/404',
  data: {
    title: 'components.main_menu.not_found',
    authorizedRoles: [USER_ROLES.all],
  },
  resolver: () => import(/* webpackChunkName: "404" */ './page-not-found-comp'),
};

export default pageNotFoundModule;
