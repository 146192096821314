import { getShortFormatDate } from 'globals/date-utils';

import CodeComp from 'code-comp/code-comp';
import Card from 'wa-storybook/components/general/card/card';
import cloudinary from 'globals/cloudinary';
import { getFullPrice } from 'globals/helpers';
import type { GiftCardOrder } from '../../../gift-cards.types';
import Button from 'wa-storybook/components/general/button/button';

import styles from './gift-card-order.module.scss';
import classNames from 'classnames';
import merchant2x from '../assets/merchant@2x.png';
import merchant from '../assets/merchant.png';

type Props = {
  isLast: boolean;
  giftCardOrder: GiftCardOrder;
};

type State = {
  isTermsHidden: boolean;
};

export default class GCOrder extends React.Component<Props, State> {
  static displayName = 'GiftCardOrder';

  constructor(props: Props) {
    super(props);
    this.state = {
      isTermsHidden: true,
    };
  }

  onKeyDown = (e: React.KeyboardEvent<EventTarget>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.setState({ isTermsHidden: !this.state.isTermsHidden });
    }
  };

  render() {
    const {
      short_order_id: shortOrderId,
      merchants,
      created_on: createdOn,
    } = this.props.giftCardOrder;
    // @ts-expect-error - TS2339 - Property 'gift_cards' does not exist on type 'Merchant | undefined'.
    const { gift_cards: giftCards } = merchants[0];
    const {
      image,
      face_value: faceValue,
      items,
      terms_and_conditions: termsAndConditions,
      type,
    } = giftCards[0];

    if (!items.length) {
      return null;
    }

    const {
      secret_code: secretCode,
      card_pin: cardPin,
      claim_url: claimUrl,
    } = items[0];
    const price = getFullPrice(faceValue, 2, true);

    return (
      <Card className={styles.wrapper}>
        {image?.[0] ? (
          <img
            className={styles.image}
            src={cloudinary(
              image[0].url,
              `w_${image[0].width},h_${image[0].height},c_fill`,
            )}
          />
        ) : (
          <img
            className={styles.image}
            src={merchant}
            srcSet={`${merchant}, ${merchant2x} 2x`}
          />
        )}

        <div className={styles.description}>
          <div data-hook='gift-card-name' className={styles.name}>
            {type}
          </div>
          <div className={styles.price}>{price}</div>
          <div className={styles['order-id']}>
            <span>{polyglot.t(`giftcards.order_id`)}</span>{' '}
            <span>{shortOrderId}</span>
          </div>
          <div className={styles['order-id']}>
            {polyglot.t('giftcards.purchase_date', {
              purchaseDate: getShortFormatDate(createdOn),
            })}
          </div>
        </div>
        {(!!secretCode || !!cardPin) && (
          <div>
            <div className={styles['codes-wrapper']}>
              {secretCode && (
                <CodeComp
                  title={polyglot.t('giftcards.secret_code')}
                  className={styles['code-container']}
                  codes={[secretCode]}
                  instruction={polyglot.t('giftcards.secret_code_instruction')}
                />
              )}
              {cardPin && (
                <CodeComp
                  title={polyglot.t('giftcards.your_pin')}
                  className={styles['code-container']}
                  codes={[cardPin]}
                  instruction={polyglot.t('giftcards.your_pin_instruction')}
                />
              )}
            </div>
            <hr />
          </div>
        )}
        <div className={styles['terms-container']}>
          <span
            tabIndex={0}
            role='button'
            onClick={() =>
              this.setState({ isTermsHidden: !this.state.isTermsHidden })
            }
            onKeyDown={this.onKeyDown}
            className={styles['terms-link']}
            aria-label={polyglot.t(
              this.state.isTermsHidden
                ? 'giftcards.terms_conditions_title'
                : 'giftcards.hide_terms_conditions',
            )}
          >
            {polyglot.t(
              this.state.isTermsHidden
                ? 'giftcards.terms_conditions_title'
                : 'giftcards.hide_terms_conditions',
            )}
          </span>
          <div className={styles.get}>
            <Button
              dataHook={`get card ${shortOrderId}`}
              onClick={() => {
                window.open(claimUrl, '_blank');
              }}
              size='small'
              text={polyglot.t('giftcards.get_gift_card')}
            />
          </div>
        </div>
        <div
          className={classNames(
            this.state.isTermsHidden
              ? styles['terms-text']
              : styles['terms-text--visible'],
          )}
        >
          {termsAndConditions}
        </div>
      </Card>
    );
  }
}
