import { connectWithProvider } from 'app/hermes-redux';
import { changeSlide } from 'app/state/modal/actions';
import CarouselArrow from './carousel-arrow';
import Slider from 'react-slick';
import Cross from 'wa-storybook/components/general/icon/components/cross';
import { FullHeightWrapper } from 'wa-storybook/components/layouts/full-height-wrapper/full-height-wrapper';
import styles from './carousel-modal-comp.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { AppState, Dispatch } from 'globals/types';

type Props = {
  index: number;
  payload: {
    onCloseHandler: () => void;
    isCrossButton: boolean;
    slideComp: React.ComponentType<any>;
    slides: Slider;
    renderSlide: () => ReactNode[];
    className: string;
    children: React.ComponentType<any>;
  };
  dispatch: Dispatch;
};

type State = {
  slideIndex: number;
};

export class CarouselModal extends React.Component<Props, State> {
  static displayName = 'CarouselModal';

  constructor(props: Props) {
    super(props);
    this.state = { slideIndex: 0 };
  }

  renderSlides = () => {
    const SlideComp = this.props.payload.slideComp;
    return this.props.payload.slides.map((slide, index) => (
      <div key={index}>
        <SlideComp
          slide={slide}
          index={index}
          activeSlide={index === this.state.slideIndex}
          payload={this.props.payload}
        />
      </div>
    ));
  };

  onCloseClick = () => {
    const {
      payload: { onCloseHandler, isCrossButton },
    } = this.props;

    if (!isCrossButton && onCloseHandler) {
      onCloseHandler();
    }
  };

  render() {
    const sliderSettings = {
      dots: this.props.index !== this.props.payload.slides.length - 1,
      customPaging: i => {
        const options = {
          label: polyglot.t('aria.carousel_slide'),
          index: i + 1,
          total: this.props.payload.slides.length,
        };
        const ariaLabel =
          i === this.props.index
            ? polyglot.t('aria.button_selected.true', options)
            : polyglot.t('aria.button_selected.false', options);
        return (
          <button tabIndex={-1} aria-label={ariaLabel}>
            {i + 1}
          </button>
        );
      },
      arrows: context.viewport.isDesktop || context.viewport.isDesktopXl,
      infinite: false,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: false,
      autoplay: false,
      accessability: true,
      focusOnSelect: false,
      className: 'inner-slider',
      nextArrow: <CarouselArrow isLeft={false} />,
      prevArrow: <CarouselArrow isLeft={true} />,
      afterChange: index => this.props.dispatch(changeSlide(index)),
    };

    const ChildComp = this.props.payload ? this.props.payload.children : null;
    const className = this.props.payload?.className
      ? this.props.payload.className
      : '';

    return (
      <FullHeightWrapper>
        <div className={classNames(styles['carousel-modal'], className)}>
          <div
            className={styles['carousel-modal__content']}
            role='dialog'
            aria-label={polyglot.t('walk_through.dialogue')}
            aria-modal='true'
          >
            {this.props.payload ? (
              <div className={styles['carousel-modal__slider-wrap']}>
                <div className={styles['carousel-modal__slider']}>
                  <Slider {...sliderSettings}>{this.renderSlides()}</Slider>
                </div>
                {ChildComp && (
                  <div className={styles['carousel-modal__child-wrap']}>
                    <ChildComp slides={this.props.payload.slides} />
                  </div>
                )}
                {this.props.payload && !this.props.payload.isCrossButton && (
                  <button
                    className={styles['carousel-modal__close']}
                    onClick={this.onCloseClick}
                    aria-label={polyglot.t('aria.close_modal')}
                    data-id='close-walkthrough'
                  >
                    <Cross />
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </FullHeightWrapper>
    );
  }
}

export default connectWithProvider((state: AppState) => {
  const { modal } = state;
  return modal;
}, CarouselModal);
