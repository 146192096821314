import styles from './three-quarters.module.scss';
import classNames from 'classnames';

type Props = {
  children?: any;
  className?: string;
  classString?: string;
  ['aria-label']?: string;
};

const ThreeQuartersPage = (props: Props) => {
  return (
    <section
      aria-label={props['aria-label']}
      className={classNames(
        styles['three-quarters-page'],
        props.className,
        props.classString && styles[props.classString],
      )}
    >
      {props.children}
    </section>
  );
};

ThreeQuartersPage.displayName = 'ThreeQuartersPage';
export default ThreeQuartersPage;
