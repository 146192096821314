// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  font-size: 16px;
  display: block;

  margin: 20vh auto 0 auto;
  width: 400px;

  a {
    text-decoration: underline;
  }

  @media only screen and (max-width: 767px) {
    box-shadow: none;
    width: 100%;
    border: none;
    margin: 0;
    padding-top: 20px;
    border-radius: unset;
    height: 100vh;
  }
}

.eap-message {
  font-size: 14px;
  margin-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./client/app/run.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;;EAEd,wBAAwB;EACxB,YAAY;;EAEZ;IACE,0BAA0B;EAC5B;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;EACf;AACF;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".container {\n  font-size: 16px;\n  display: block;\n\n  margin: 20vh auto 0 auto;\n  width: 400px;\n\n  a {\n    text-decoration: underline;\n  }\n\n  @media only screen and (max-width: 767px) {\n    box-shadow: none;\n    width: 100%;\n    border: none;\n    margin: 0;\n    padding-top: 20px;\n    border-radius: unset;\n    height: 100vh;\n  }\n}\n\n.eap-message {\n  font-size: 14px;\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
