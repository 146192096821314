import styles from './two-thirds.module.scss';
import classNames from 'classnames';

type Props = {
  children?: any;
  style?: any;
  className?: string;
  ['aria-label']?: string;
};

const TwoThirdsPage = (props: Props) => {
  return (
    <section
      aria-label={props['aria-label']}
      style={props.style}
      className={classNames(
        styles['two-thirds-page'],
        props.className && styles[props.className],
      )}
    >
      {props.children}
    </section>
  );
};

TwoThirdsPage.displayName = 'TwoThirdsPage';
export default TwoThirdsPage;
