import type { GiftCard } from '../gift-cards.types';
import { goTo } from 'router/navigation';
import cloudinary from 'globals/cloudinary';

import PerksCard from 'perks/components/card';

import InnerCard from 'perks/components/inner-card/inner-card';
import Spinner from 'wa-storybook/components/general/spinner/spinner';

type Props = {
  giftCard: GiftCard;
  renderInner?: () => React.ReactNode;
  className?: string;
  overlayClassName?: string;
  overlayImageClassName?: string;
  referrer: string;
  index?: number;
};

const GiftCardOffer = (props: Props) => {
  const { giftCard, className = '', renderInner, referrer } = props;
  const logo =
    giftCard && giftCard.photography && giftCard.photography.length > 0
      ? cloudinary(giftCard.photography[0]?.url, '')
      : require('perks/assets/placeholder.jpg');

  const isRewards = referrer === 'spotRewards';

  if (!giftCard) return <Spinner />;

  return (
    <PerksCard
      key={giftCard.id}
      image={logo}
      noIcon
      onClick={() => goTo(`${referrer}Details`, { id: giftCard.id })}
      imageClassName={'image--gift-card-offer'}
      className={['card--gift-card-offer', className]}
    >
      {renderInner ? (
        renderInner()
      ) : (
        <InnerCard
          title={
            isRewards
              ? ''
              : polyglot.t('giftcards.discount_percentage', {
                  val: giftCard.value.data.discount,
                })
          }
          isSmallerCard={true}
          provider={giftCard.title}
          description={''}
          type={polyglot.t('giftcards.type')}
          extra={''}
        />
      )}
    </PerksCard>
  );
};

GiftCardOffer.displayName = 'GiftCardOffer';

export default GiftCardOffer;
