import { logout } from 'state/session/actions';
import USER_ROLES from 'constants/user-roles.constant';
import { getStore } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import('./signup-invitation'),
    getStore().dispatch(logout(true)), // force logout
  ]).then(([component]) => {
    return component;
  });
}

const signupInvitationModule = {
  name: 'signupInvitation',
  url: '/signup/invitation',
  resolver,
  data: {
    title: 'components.main_menu.signup',
    authorizedRoles: [USER_ROLES.all],
  },
};

export default signupInvitationModule;
