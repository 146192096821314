/*  BE CAREFUL THE ORDER HERE IS VERY IMPORTANT
    DO PUT A ROUTE WITH AN ID AS THE LAST ONE (BEFORE SEARCH/DETAILS/...)
    FOR EXAMPLE:
    restaurantsDetails: /restaurants/:id
    should be place after
    restaurantsError: /restaurants/error
*/
import { testableFeaturesEnabled } from 'globals/testable';
import pageNotFoundRoute from './pages/page-not-found/page-not-found.module';
import getLifeworksInChinaRoute from './pages/get-lifeworks-in-china/get-lifeworks-in-china.module';
import confirmEmailRoute from './pages/confirm-email/confirm-email.module';
import signupMainRoute from './pages/signup/main/signup-main.module';
import signupInvitationRoute from './pages/signup/invitation/signup-invitation.module';
import resendInvitationRoute from './pages/signup/resend-invitation/resend-invitation.module';
import resendInvitationSuccessRoute from './pages/signup/resend-invitation-success/resend-invitation-success.module';
import forgottenPasswordRoute from './pages/forgotten-password/forgotten-password.module';
import resetPasswordRoute from './pages/reset-password/reset-password.module';
import ssoRedirectRoute from './pages/sso-redirect/sso-redirect.module';
import mfaSetupRoute from './pages/mfa-setup/mfa-setup.module';
import msTeamsDoneRoute from './pages/ms-teams-done/ms-teams-done.module';
import viewEmailBrowserRoute from './pages/view-email-browser/view-email-browser.module';
import feedModule from './pages/feed/feed.module';
import groupsRoute from './pages/groups/groups.module';
import directoryRoute from './pages/directory/directory.module';
import leaderboardRoute from './pages/leaderboard/leaderboard.module';
import notificationsRoute from './pages/notifications/notifications.module';
import userNotFoundRoute from './pages/user-not-found/user-not-found.module';
import profileEditRoute from './pages/profile-edit/profile-edit.module';
import profileRoute from './pages/profile/profile.module';
import settingsModule from './pages/settings/settings.module';
import employeeAssistanceModule from './pages/employee-assistance/employee-assistance.module';
import limitedAccountModule from './pages/limited-account/limited-account.module';
import dependantAccountsRoute from './pages/dependant-accounts/dependant-accounts.module';
import needHelpRoute from './pages/need-help/need-help.module';
import generalEnquiryRoute from './pages/general-enquiry/general-enquiry.module';
import childCareRoute from './pages/child-care/child-care.module';
import lactcorpRoute from './pages/lactcorp/lactcorp.module';
import chatRoute from './pages/chat/chat.module';
import legalServicesRoute from './pages/legal-services/legal-services.module';
import wellnessToolsRoute from './pages/wellness-tools/wellness-tools.module';
import wellnessCategoriesRoute from './pages/wellness-categories/wellness-categories.module';
import wellnessSessionRoute from './pages/wellness-session/wellness-session.module';
import selfAssessmentModule from './pages/self-assessment/self-assessment.module';
import healthCoachingModule from './pages/health-coaching/health-coaching.module';
import confidentialSupportRequestModule from './pages/confidential-support-request/confidential-support-request.module';
import trustedAppsAndWebsitesRoute from './pages/trusted-apps-and-websites/trusted-apps-and-websites.module';
import perksSearchResultsModule from './pages/perks-search-results/perks-search-results.module';
import cinemasModule from './pages/cinemas/cinemas.module';
import offersModule from './pages/offers/offers.module';
import giftCardsModule from './pages/gift-cards/gift-cards.module';
import svmGiftCardsRoute from './pages/offers/svm-giftcards.module';
import walletModule from './pages/wallet/wallet.module';
import shopOnlineModule from './pages/shop-online/shop-online.module';
import shopOnlineModuleGraphQL from './pages/shop-online-graphql/shop-online.module';
import perksHomePageRoute from './pages/perks-home-page/perks-home-page.module';
import retailerRedirectRoute from './pages/retailer-redirect/retailer-redirect.module';
import tieringModule from './pages/tiering/tiering.module';
import challengesHabitSelectRoute from './pages/challenges/containers/habit-select/habit-select.module';
import challengesHubPageRoute from './pages/challenges/containers/hub/hub-page.module';
import challengesJoinRoute from './pages/challenges/containers/join/join.module';
import activityModule from './pages/challenges/containers/activity/activity.module';
import checkoutRoute from './pages/checkout-braintree/checkout.module';
import spotRewardsModule from './pages/spot-rewards/spot-rewards.module';
import wellBeingHubModule from './pages/well-being-hub/well-being-hub.module';
import programsModule from './pages/programs/programs.module';
import programsAllRoute from './pages/programs-all/programs-all.module';
import pensionBenefitsRoute from './pages/pension-benefits/pension-benefits.module';
import redirectingRoute from './pages/redirecting/redirecting.module';
import consentModule from './pages/consent/consent.module';
import careIssuesModule from './pages/care-issues/care-issues.module';
import appointmentsModule from './pages/appointments/appointments.module';
import careAssessmentsModule from './pages/care-assessments/care-assessments.module';
import myCareModule from './pages/my-care/my-care.module';
import carePlanModule from './pages/care-plan/care-plan.module';
import ccsChatMobileModule from './pages/chat/ccs/mobile/ccs-chat.module';
import moodTrackingModule from './pages/mood-tracking/mood-tracking.module';
import journalingModule from './pages/journaling/journaling.module';
import homeModule from 'pages/home/home.module';
import searchModule from 'pages/search/search.module';

export type AppRoutes = {
  redirects: {
    from: string;
    to: string;
  }[];
  modules: any[];
  publicRoutes: any[];
};

// REDIRECTS
// ===========================
const redirects = [
  {
    from: '/employee-assistance',
    to: '/life/employee-assistance',
  },
  {
    from: '/employee-assistance/employee-assistance/*',
    to: '/life/employee-assistance/*',
  },
  {
    from: '/employee-assistance/*',
    to: '/life/employee-assistance/*',
  },
  {
    from: '/eap-numbers/*',
    to: '/',
  },
];

const testableRedirects = [];
// ===========================

// ROUTES
// ===========================
//Public
const publicRoutes = [
  pageNotFoundRoute,
  getLifeworksInChinaRoute,

  // Work
  confirmEmailRoute,
  signupMainRoute,
  signupInvitationRoute,
  resendInvitationRoute,
  resendInvitationSuccessRoute,
  forgottenPasswordRoute,
  resetPasswordRoute,
  ssoRedirectRoute,
  mfaSetupRoute,
  msTeamsDoneRoute,

  // View Email Browser
  viewEmailBrowserRoute,
  redirectingRoute,
  consentModule.popUp,
  limitedAccountModule.confirmation,
];

/* Order is important in handing same routes.
The one with extra params restrictions has to come first
*/
const moduleRoutes = [
  // Private
  // Work
  feedModule.search,
  feedModule.detail,
  feedModule.base,
  feedModule.group,
  groupsRoute,
  directoryRoute,
  leaderboardRoute,
  notificationsRoute,
  userNotFoundRoute,
  profileEditRoute,
  profileRoute,
  settingsModule.settings,
  settingsModule.passwordChange,

  // Wellness
  employeeAssistanceModule.listing,
  employeeAssistanceModule.categories,
  employeeAssistanceModule.search,
  employeeAssistanceModule.articles,
  employeeAssistanceModule.error,
  employeeAssistanceModule.eapHome,
  employeeAssistanceModule.hwrHome,
  employeeAssistanceModule.internationalNumbers,
  limitedAccountModule.base,
  limitedAccountModule.create,

  dependantAccountsRoute,
  needHelpRoute,
  generalEnquiryRoute,
  childCareRoute,
  lactcorpRoute,
  chatRoute,
  legalServicesRoute,
  wellnessToolsRoute,
  wellnessCategoriesRoute,
  wellnessSessionRoute,
  selfAssessmentModule.hub,
  selfAssessmentModule.assessment,
  selfAssessmentModule.results,
  selfAssessmentModule.historicalResult,
  healthCoachingModule.form,
  confidentialSupportRequestModule.form,
  confidentialSupportRequestModule.complete,
  trustedAppsAndWebsitesRoute,

  // Perks
  cinemasModule.hub,
  perksSearchResultsModule.cinemasSearch,
  cinemasModule.codes,
  cinemasModule.error,
  cinemasModule.details,
  offersModule.colleagueOffers,
  perksSearchResultsModule.exclusiveOffersSearch,
  offersModule.colleagueOffersDetails,
  offersModule.instoreOffers,
  offersModule.instoreOffersDetails,
  giftCardsModule.hub,
  giftCardsModule.codes,
  giftCardsModule.popular,
  giftCardsModule.category,
  perksSearchResultsModule.giftCardsSearch,
  giftCardsModule.error,
  giftCardsModule.details,
  svmGiftCardsRoute,
  walletModule.wallet,
  perksSearchResultsModule.cashbackSearch,
  perksHomePageRoute,
  retailerRedirectRoute,

  // TIERING
  tieringModule.hub,
  tieringModule.hubWithActivityModalOpen,
  tieringModule.rewards,

  // HABITS
  challengesHabitSelectRoute,

  // COMMON
  challengesHubPageRoute,
  challengesJoinRoute,
  activityModule.habitActivity,
  activityModule.stepActivity,
  checkoutRoute,

  // SPOT REWARDS
  spotRewardsModule.main,
  spotRewardsModule.hub,
  spotRewardsModule.codes,
  spotRewardsModule.popular,
  spotRewardsModule.category,
  perksSearchResultsModule.spotRewardsSearch,
  spotRewardsModule.error,
  spotRewardsModule.details,

  // WELL-BEING
  wellBeingHubModule.hub,
  wellBeingHubModule.recommendations,

  programsModule.programDetail,
  programsModule.moduleDetail,
  programsModule.programDetailOverview,
  programsAllRoute,
  consentModule.jit,

  // PENSION AND BENEFITS
  pensionBenefitsRoute,

  settingsModule.consentSettings,

  // CARE ISSUES
  careIssuesModule.selectCareIssue,

  // BETA BOOK APPOINTMENTS
  appointmentsModule.appointmentIntro,
  appointmentsModule.userLocation,
  appointmentsModule.counsellorSelection,
  appointmentsModule.counsellorProfile,
  appointmentsModule.timeslotSelection,
  appointmentsModule.appointmentType,
  appointmentsModule.contactDetails,
  appointmentsModule.appointmentConfirmation,
  appointmentsModule.viewAppointments,
  appointmentsModule.appointmentDetails,
  appointmentsModule.appointmentCancel,
  appointmentsModule.paymentDetails,
  consentModule.jitMandatory,

  // CARE PLAN PRE ASSESSMENTS
  careAssessmentsModule.retake,
  careAssessmentsModule.questionnaire,

  // MY CARE
  myCareModule.hub,
  myCareModule.otherServices,
  myCareModule.assessmentResults,

  // CARE PLAN
  carePlanModule.carePlanOverview,
  carePlanModule.carePlanActivity,
  carePlanModule.carePlanIntro,
  carePlanModule.carePlanEnd,

  // MOOD TRACKING
  moodTrackingModule.moodTrackingHistory,
  moodTrackingModule.moodTrackingNewEntry,

  // JOURNALING
  journalingModule.journalingHistory,
  journalingModule.journalingNewEntry,

  // ASYNC CHAT
  ccsChatMobileModule,

  //HOME
  homeModule.home,
  homeModule.services,

  //SEARCH
  searchModule.search,

  // CASHBACK ROUTES CURRENTLY UNDER MIGRATION
  ...(testableFeaturesEnabled()
    ? [
        shopOnlineModuleGraphQL.shop,
        shopOnlineModuleGraphQL.detailsCashback,
        shopOnlineModuleGraphQL.detailsOffer,
        shopOnlineModuleGraphQL.category,
      ]
    : [
        shopOnlineModule.shop,
        shopOnlineModule.detailsCashback,
        shopOnlineModule.detailsOffer,
        shopOnlineModule.category,
      ]),
];

const testableModuleRoutes = [
  myCareModule.bookAppointment,
  myCareModule.tmhChat,
  appointmentsModule.teamsPaymentRedirect,
];
// ===========================

const routes: AppRoutes = {
  publicRoutes,
  redirects: [
    ...redirects,
    ...(testableFeaturesEnabled() ? testableRedirects : []),
  ],
  modules: [
    ...moduleRoutes,
    ...(testableFeaturesEnabled() ? testableModuleRoutes : []),
  ],
};

// @ts-ignore
export default routes;
