import RadioboxComp from 'wa-storybook/components/inputs/radiobox-comp/radiobox-comp';
import PictureRetinaAssets from 'wa-storybook/components/general/picture-fill-assets/picture-fill-assets';
import Button from 'wa-storybook/components/general/button/button';
import styles from './password-change.module.scss';
import { modalExitAction } from '../../../../state/modal/constants';

type Props = {
  onClose: (arg1: string) => void;
};

type State = {
  option: string;
};

const OPTIONS = [modalExitAction.logOut, modalExitAction.logIn];

class PasswordChangedModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      option: modalExitAction.logOut,
    };
  }

  state: State;

  render() {
    return (
      <div>
        <div className={styles.container}>
          <div className={styles['image-container']}>
            <PictureRetinaAssets
              url={require('./assets/lock.png')}
              url2x={require('./assets/lock@2x.png')}
              className={'image--password-change-modal'}
            />
          </div>
        </div>
        <div className={styles.separator}>
          <div className={styles.message}>
            <div className={styles.title}>
              {polyglot.t('settings.password_change_modal.title')}
            </div>
            <div className={styles.subtitle}>
              {polyglot.t('settings.password_change_modal.subtitle')}
            </div>
            {OPTIONS.map(option => (
              <div key={option} className={styles.option}>
                <RadioboxComp
                  inputName='option'
                  inputValue={option}
                  label={polyglot.t(`settings.password_change_modal.${option}`)}
                  subtitle={polyglot.t(
                    `settings.password_change_modal.${option}_subtitle`,
                  )}
                  isChecked={option === this.state.option}
                  handleChange={() => this.setState({ option })}
                />
              </div>
            ))}
            <div>
              <div className={styles.button}>
                <Button
                  dataHook={'password_change_modal.submit'}
                  text={polyglot.t('settings.password_change_modal.submit')}
                  onClick={() =>
                    this.props.onClose(
                      this.state.option || modalExitAction.logIn,
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordChangedModal;
