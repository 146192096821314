import { restrictions } from 'state/features-list/constants';

import USER_ROLES from 'constants/user-roles.constant';

const lactcorpModule = {
  name: 'lactcorp',
  url: '/lactcorp',
  resolver: () => import(/* webpackChunkName: "lactcorp" */ './lactcorp-comp'),
  restrictions: {
    user: [restrictions['eap_lactcorp']],
  },
  data: {
    title: 'components.main_menu.lactcorp',
    includeInMenu: false,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default lactcorpModule;
