import type { Store, CategoriesResponse } from 'globals/perks.types';
import type { Action, Dispatch, ThunkedAction } from 'globals/types';
import { POPULAR_CATEGORY, spotRewardsReferrer } from '../../shared/constants';
import type {
  GiftCardsOffersResponse,
  GiftCardsOrdersResponse,
} from '../../gift-cards.types';
import { getUser } from 'stores/user-store';
import fetch from 'globals/wa-fetch';
import { goTo } from 'router/navigation';
import type { CarouselResponse } from '../../../shop-online/state/types';
import { showToast } from '../../../../react-components/toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';

export function getGiftCardsOffers(
  limit: number,
  offset: number,
  categoryId: string,
  referrer: string,
): Promise<GiftCardsOffersResponse> {
  const urlParams = Object.assign(
    {},
    limit ? { limit } : { limit: 12 },
    offset ? { offset: offset } : { offset: 0 },
    referrer === spotRewardsReferrer ? { rewardable: 1 } : {},
    categoryId
      ? categoryId === POPULAR_CATEGORY
        ? { sort: 'popularity.desc' }
        : { categories: [categoryId] }
      : {},
  );

  return fetch('/offers?primary_feature=benefit_global_gift_cards', {
    urlParameters: urlParams,
    version: 1.5,
    token: true,
  });
}

export function fetchGiftCardsOffers(
  limit: number,
  offset: number,
  categoryId: string,
  referrer: string,
) {
  return function (dispatch: Dispatch): Promise<Dispatch> {
    dispatch({ type: 'GIFT_CARDS_HUB/FETCH_OFFERS' });
    return getGiftCardsOffers(limit, offset, categoryId, referrer).then(
      (response: GiftCardsOffersResponse) =>
        dispatch({ type: 'GIFT_CARDS_HUB/FETCH_OFFERS_SUCCESS', response }),
      () => goTo(`${referrer}Error`),
    );
  };
}

function getGiftCardsCategories(): Promise<CategoriesResponse> {
  return fetch('/categories?primary_feature=benefit_global_gift_cards', {
    version: 1.5,
    token: true,
  });
}

export function fetchGiftCardsOrders(referrer: string, offsetRef?: string) {
  return function (dispatch: Dispatch): Promise<Dispatch> {
    dispatch({ type: 'GIFT_CARDS_HUB/FETCH_ORDERS' });
    return getGiftCardsOrders(offsetRef).then(
      (response: GiftCardsOrdersResponse) =>
        dispatch({ type: 'GIFT_CARDS_HUB/FETCH_ORDERS_SUCCESS', response }),
      () => goTo(`${referrer}Error`),
    );
  };
}

export function getGiftCardsOrders(
  offsetRef?: string,
): Promise<GiftCardsOrdersResponse> {
  return fetch(`/views/users/${getUser().user_id}/gift-card-orders`, {
    version: 1.5,
    token: true,
    urlParameters: offsetRef ? { offset_ref: offsetRef } : {},
  });
}

export function fetchGiftCardsCategories(
  categoryId: string | null | undefined,
  referrer: string,
) {
  return function (dispatch: Dispatch): Promise<void> {
    dispatch({ type: 'GIFT_CARDS_HUB/FETCH_CATEGORIES' });

    return getGiftCardsCategories().then(
      (response: CategoriesResponse) => {
        if (categoryId) {
          const selectedCategory = response.body.find(
            (c: Store) => c.id === categoryId,
          );
          if (selectedCategory) {
            dispatch({
              type: 'GIFT_CARDS_HUB/SELECT_CATEGORY',
              selectedCategory,
            });
          }
        }
        dispatch({ type: 'GIFT_CARDS_HUB/FETCH_CATEGORIES_SUCCESS', response });
      },
      () => goTo(`${referrer}Error`),
    );
  };
}

export function getCarousel(): Promise<CarouselResponse> {
  return fetch('/benefit/online-shop/carousel', {
    token: true,
    version: 1.3,
  });
}

export function fetchCarousel(): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'GIFT_CARDS_HUB/FETCH_CAROUSEL' });

    return getCarousel()
      .then((response: CarouselResponse) => {
        dispatch({
          type: 'GIFT_CARDS_HUB/FETCH_CAROUSEL_SUCCESS',
          resource: response.body,
        });
      })
      .catch(() => {
        dispatch(
          showToast({
            type: ToastType.ERROR,
            message: window.polyglot.t('shop_online.error'),
          }),
        );
        dispatch({
          type: 'GIFT_CARDS_HUB/FETCH_CAROUSEL_FAILURE',
        });
      });
  };
}

export const reset = (): Action => ({ type: 'GIFT_CARDS_HUB/RESET' });
export const resetPaging = (): Action => ({
  type: 'GIFT_CARDS_HUB/RESET_PAGING',
});
