import type { Model, Action } from './types';

export const initialState = [];

export function featuresListReducer(
  state: Model = initialState,
  action: Action,
): Model {
  switch (action.type) {
    case 'FEATURES_LIST/UPDATE_FEATURES_LIST':
      return action.featuresList;
    default:
      return state;
  }
}
