import type { Consent, ConsentModel, ConsentAction } from './types';

const initialState = (): ConsentModel => ({
  consents: [],
  isFetching: false,
  shouldResumePostLoginFlow: false,
  redirectionState: null,
  lastUpdated: null,
});

export const consentReducer = (
  state: ConsentModel = initialState(),
  action: ConsentAction,
): ConsentModel => {
  switch (action.type) {
    case 'CONSENT/RESET':
      return {
        ...initialState(),
      };
    case 'CONSENT/GET_ALL_CONSENTS':
      return {
        ...state,
        consents: action.payload,
        lastUpdated: new Date().getTime(),
      };
    case 'CONSENT/SET_IS_FETCHING_STATUS':
      return { ...state, isFetching: action.payload };
    case 'CONSENT/SET_SHOULD_RESUME_POST_LOGIN_FLOW':
      return { ...state, shouldResumePostLoginFlow: action.payload };
    case 'CONSENT/SET_REDIRECTION_MODEL':
      return { ...state, redirectionState: action.payload };
    case 'CONSENT/UPDATE_CONSENT':
      const newState = state;
      newState.consents = state.consents.map((consent: Consent) => {
        if (consent['consent_type'] === action.payload['consent_type']) {
          return action.payload;
        }

        return consent;
      });

      return { ...newState };

    default:
      return state;
  }
};
