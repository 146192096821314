import { Paging } from 'globals/types';
import fetch from 'globals/wa-fetch';
import clone from 'lodash/clone';

export function get(
  type: string | null,
  paging: Partial<Paging>,
  groupId?: string,
) {
  const params = {
    limit: paging.limit,
    show_limited_comments: true,
    max_target_user: 4,
    offset_ref: '',
  };

  if (paging.offsetRef) {
    params.offset_ref = paging.offsetRef;
  }

  if (groupId) {
    params['group_ids[]'] = groupId;
    params['include_me'] = false;
  }

  if (type && type !== 'everything') {
    params['type_user'] = type;
  }

  return fetch('/feed/post', {
    urlParameters: params,
    version: 1.3,
    token: true,
  });
}

export function post(postMsg) {
  return fetch('/feed/post', {
    method: 'post',
    version: 1.3,
    token: true,
    body: postMsg,
  });
}

export function postImage(postId, file) {
  const url = '/feed/post/:post_id/image'.replace(':post_id', postId);
  const fd = new FormData();
  fd.append('image', file);

  return fetch(url, {
    method: 'post',
    token: true,
    version: 1.3,
    doNotStringify: true,
    body: fd,
  });
}

// NEEDED Because the API is not sending back the same post format
function reBuildRecognitionObject(object) {
  const newObject = clone(object);

  newObject.body['created_on'] = newObject.body['feed_post']['created_on'];
  newObject.body['post_id'] = newObject.body['feed_post']['post_id'];
  newObject.body['type'] = newObject.body['feed_post']['type'];
  newObject.body['data'] = newObject.body['feed_post']['data'];
  newObject.body['groups'] = newObject.body['feed_post']['groups'];

  return newObject;
}

export function postRecognition(recognition, params?) {
  const urlParams = {
    max_target_user: 4,
  };

  return fetch('/recognize', {
    body: recognition,
    urlParameters: { ...urlParams, ...params },
    method: 'post',
    version: 1.3,
    token: true,
  }).then(res => reBuildRecognitionObject(res));
}

export function registerImage(recognitionId, file) {
  const fd = new FormData();
  fd.append('image', file);

  return fetch('/recognize/' + recognitionId + '/image', {
    method: 'post',
    version: 1.3,
    body: fd,
    doNotStringify: true,
    token: true,
  }).then(res => reBuildRecognitionObject(res));
}

export function getRecognition(recognitionId, limit) {
  return fetch(`/recognize/${recognitionId}/target?limit=${limit}`, {
    version: 1.3,
    token: true,
  }).then(res => res.body);
}

export function getPost(id: string, params?) {
  const urlParams = {
    max_target_user: 4,
  };

  return fetch(`/feed/post/${id}`, {
    urlParameters: { ...urlParams, ...params },
    version: 1.3,
    token: true,
  });
}

const commentAction = (method, postId, params, body?) =>
  fetch(`/feed/post/${postId}/comment`, {
    version: 1.3,
    method,
    urlParameters: params,
    body,
    token: true,
  });

export function fetchComments(postId, limit, order) {
  const params = {
    limit,
    sort: `created_on.${order}`,
  };

  return commentAction('get', postId, params);
}

export function postComment(postId, commentMsg) {
  return commentAction('post', postId, null, commentMsg);
}

const feedPostAction = (method, postId, params) => {
  const urlParams = {
    max_target_user: 4,
  };

  return fetch(`/feed/post/${postId}/action`, {
    method,
    version: 1.3,
    urlParameters: { ...urlParams, ...params },
    token: true,
  });
};

export function getActionsList(id: string, params?) {
  return feedPostAction('get', id, params);
}

export function createAction(id: string, params?) {
  return feedPostAction('post', id, params);
}

export function removeAction(id: string, params?) {
  return feedPostAction('delete', id, params);
}

export function search(query, paging) {
  const params: any = {
    limit: paging.limit,
    show_limited_comments: true,
    search_term: query,
  };

  if (paging.offsetRef) {
    params.offset_ref = paging.offsetRef;
  }
  return fetch('/feed/post/search', {
    urlParameters: params,
    version: 1.3,
    token: true,
  });
}

export function markPostAsSeen(userId, postId) {
  return fetch(`/users/${userId}/dismiss-posts`, {
    method: 'post',
    token: true,
    body: { post_ids: [postId] },
    version: 1.4,
  });
}
