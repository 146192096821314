import { isSharedAccount } from 'stores/user-store';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "notifications" */ './view/notifications-comp'),
    import(/* webpackChunkName: "notifications" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('notifications', reducer.notificationsReducer);
    return component;
  });
}

const notificationsModule = {
  name: 'notifications',
  url: '/notifications',
  shouldGrantAccess() {
    return Promise.resolve(!isSharedAccount());
  },
  resolver,
  data: {
    title: 'components.main_menu.notifications',
    section: 'myAccount',
    authorizedRoles: [USER_ROLES.employee],
    shortcutKeyCode: 54,
  },
};

export default notificationsModule;
