import type { WellbeingFeed } from './types';

import { generateActions } from 'redux-dryer';
import { MODULE_NAME } from './constants';

const Actions = {
  fetchWellbeingFeed: () => {},

  setWellbeingFeed: (wellbeingFeed: WellbeingFeed) => ({ wellbeingFeed }),
  setIsFetchingWellbeingFeed: (isFetchingWellbeingFeed: boolean) => ({
    isFetchingWellbeingFeed,
  }),
} as const;

export default generateActions(Actions, MODULE_NAME);
