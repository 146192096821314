import styles from './permanent-toast.module.scss';

type Props = {
  children: any;
  onClick: () => void;
};

const PermanentToast = (props: Props) => {
  const { onClick, children } = props;
  return (
    <button className={styles.toast} onClick={onClick}>
      <div className={styles['toast-children']}>{children}</div>
    </button>
  );
};

PermanentToast.displayName = 'PermanentToast';
export default PermanentToast;
