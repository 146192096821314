// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** function to get dimensions
  usage example to get \`tablet\` value from \`\$page-max-widths\`
  .class {
    max-width: dim(\$page-max-widths, tablet);
  }

 */
.error-page-module__container___xHh8I {
  padding-top: 100px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./node_modules/wa-storybook/styles/tools/functions/_widths.scss","webpack://./client/app/pages/gift-cards/shared/error-page.module.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;ACEA;EACE,kBAAA;EACA,kBAAA;AAMF","sourcesContent":["/** function to get dimensions\n  usage example to get `tablet` value from `$page-max-widths`\n  .class {\n    max-width: dim($page-max-widths, tablet);\n  }\n\n */\n\n@function dim($prop, $key) {\n  @return map-get($prop, $key);\n}\n","@import \"~wa-storybook/styles/utils.scss\";\n\n.container {\n  padding-top: $spacing * 5;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `error-page-module__container___xHh8I`
};
export default ___CSS_LOADER_EXPORT___;
