import styles from './pagination.module.scss';
import classNames from 'classnames';

import ReactPaginate from 'react-paginate';

type Props = {
  total: number;
  prevLabel: string;
  nextLabel: string;
  range: number;
  margins: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  fetching: boolean;
  forceSelected?: number;
};

const arrow = isLeft => (
  <div
    className={classNames(
      styles['icon-container'],
      styles[`no-margin-${isLeft ? 'left' : 'right'}`],
    )}
  >
    <i
      className={classNames(
        `icon-web_arrow_${isLeft ? 'left' : 'right'}`,
        styles.icon,
      )}
    />
  </div>
);

const getLabel = (isPrev: boolean, label: string) => (
  <div className={styles.label}>
    {isPrev && arrow(true)}
    {label}
    {!isPrev && arrow(false)}
  </div>
);

const Pagination = (props: Props) => {
  if (props.total <= 1) return null;

  return (
    <div
      className={classNames(
        styles.container,
        'pagination-container',
        props.fetching && 'hidden',
      )}
    >
      <ReactPaginate
        previousLabel={getLabel(true, props.prevLabel)}
        nextLabel={getLabel(false, props.nextLabel)}
        breakLabel={<div>...</div>}
        pageCount={props.total}
        marginPagesDisplayed={props.margins}
        pageRangeDisplayed={props.range}
        onPageChange={props.onPageChange}
        forcePage={props.forceSelected}
        containerClassName='pagination'
        activeClassName='active'
      />
    </div>
  );
};

Pagination.displayName = 'Pagination';

export default Pagination;
