import { isFeatureEnabled } from 'stores/features-list-store';

/*
  Mapping between feed post and actions

  action: action to trigger on click on button
  text_action: text to display under post
  text_reverse_action: text to display to reverse the action
  text_past_action: same text in preterit
  show: show or not the section
  icon_action: icon in the box
  icon_action_active: icon active in the box
*/

export const getStaticMapping = () => ({
  NewRecognition: {
    action: 're-recognise',
    text_action: polyglot.t('feed.action.re_recognise'),
    text_reverse_action: polyglot.t('feed.action.recognised'),
    text_past_action: polyglot.t('feed.action.recognised'),
    show: true,
    icon_action: 'recognitions',
    modal_title: polyglot.t('feed.action.modal.title.recognised'),
    modal_title_mobile: polyglot.t('feed.action.modal.title_mobile.recognised'),
  },
  MostRecognized: {
    action: 'congratulate',
    text_action: polyglot.t('feed.action.congratulate'),
    text_reverse_action: polyglot.t('feed.action.congratulated'),
    text_past_action: polyglot.t('feed.action.congratulated'),
    show: true,
    icon_action: 'congratulate',
    modal_title: polyglot.t('feed.action.modal.title.congratulated'),
    modal_title_mobile: polyglot.t(
      'feed.action.modal.title_mobile.congratulated',
    ),
  },
  UserAdHoc: {
    action: 'like',
    text_action: polyglot.t('feed.action.like'),
    text_reverse_action: polyglot.t('feed.action.liked'),
    text_past_action: polyglot.t('feed.action.liked'),
    show: true,
    icon_action: 'favourite',
    modal_title: polyglot.t('feed.action.modal.title.liked'),
    modal_title_mobile: polyglot.t('feed.action.modal.title_mobile.liked'),
  },
  CompanyNews: {
    action: 'like',
    text_action: polyglot.t('feed.action.like'),
    text_reverse_action: polyglot.t('feed.action.liked'),
    text_past_action: polyglot.t('feed.action.liked'),
    show: true,
    icon_action: 'favourite',
    modal_title: polyglot.t('feed.action.modal.title.liked'),
    modal_title_mobile: polyglot.t('feed.action.modal.title_mobile.liked'),
  },
  AdminAdHoc: {
    action: 'like',
    text_action: polyglot.t('feed.action.like'),
    text_reverse_action: polyglot.t('feed.action.liked'),
    text_past_action: polyglot.t('feed.action.liked'),
    show: true,
    icon_action: 'favourite',
    modal_title: polyglot.t('feed.action.modal.title.liked'),
    modal_title_mobile: polyglot.t('feed.action.modal.title_mobile.liked'),
  },
  Originator: {
    action: 'like',
    text_action: polyglot.t('feed.action.like'),
    text_reverse_action: polyglot.t('feed.action.liked'),
    text_past_action: polyglot.t('feed.action.liked'),
    show: true,
    icon_action: 'favourite',
    modal_title: polyglot.t('feed.action.modal.title.liked'),
    modal_title_mobile: polyglot.t('feed.action.modal.title_mobile.liked'),
  },
  Anniversary: {
    action: 'congratulate',
    text_action: polyglot.t('feed.action.congratulate'),
    text_reverse_action: polyglot.t('feed.action.congratulated'),
    text_past_action: polyglot.t('feed.action.congratulated'),
    show: true,
    icon_action: 'congratulate',
    modal_title: polyglot.t('feed.action.modal.title.congratulated'),
    modal_title_mobile: polyglot.t(
      'feed.action.modal.title_mobile.congratulated',
    ),
  },
  Eap: {
    action: 'congratulate',
    text_action: polyglot.t('feed.action.congratulate'),
    text_reverse_action: polyglot.t('feed.action.congratulated'),
    text_past_action: polyglot.t('feed.action.congratulated'),
    show: false,
    icon_action: 'congratulate',
    modal_title: polyglot.t('feed.action.modal.title.congratulated'),
    modal_title_mobile: polyglot.t(
      'feed.action.modal.title_mobile.congratulated',
    ),
  },
  Perk: {
    action: 'congratulate',
    text_action: polyglot.t('feed.action.congratulate'),
    text_reverse_action: polyglot.t('feed.action.congratulated'),
    text_past_action: polyglot.t('feed.action.congratulated'),
    show: false,
    icon_action: 'congratulate',
    modal_title: polyglot.t('feed.action.modal.title.congratulated'),
    modal_title_mobile: polyglot.t(
      'feed.action.modal.title_mobile.congratulated',
    ),
  },
  MilestoneUser: {
    action: 'congratulate',
    text_action: polyglot.t('feed.action.congratulate'),
    text_reverse_action: polyglot.t('feed.action.congratulated'),
    text_past_action: polyglot.t('feed.action.congratulated'),
    show: true,
    icon_action: 'congratulate',
    modal_title: polyglot.t('feed.action.modal.title.congratulated'),
    modal_title_mobile: polyglot.t(
      'feed.action.modal.title_mobile.congratulated',
    ),
  },
  NewUser: {
    action: 'welcome',
    text_action: polyglot.t('feed.action.welcome'),
    text_reverse_action: polyglot.t('feed.action.welcomed'),
    text_past_action: polyglot.t('feed.action.welcomed'),
    show: isFeatureEnabled('social_colleague_directory'),
    icon_action: 'welcome',
    modal_title: polyglot.t('feed.action.modal.title.welcomed'),
    modal_title_mobile: polyglot.t('feed.action.modal.title_mobile.welcomed'),
  },
  BirthdayCompany: {
    action: 'congratulate',
    text_action: polyglot.t('feed.action.congratulate'),
    text_reverse_action: polyglot.t('feed.action.congratulated'),
    text_past_action: polyglot.t('feed.action.congratulated'),
    show: true,
    icon_action: 'congratulate',
    modal_title: polyglot.t('feed.action.modal.title.congratulated'),
    modal_title_mobile: polyglot.t(
      'feed.action.modal.title_mobile.congratulated',
    ),
  },
  BirthdayUser: {
    action: 'congratulate',
    text_action: polyglot.t('feed.action.congratulate'),
    text_reverse_action: polyglot.t('feed.action.congratulated'),
    text_past_action: polyglot.t('feed.action.congratulated'),
    show: true,
    icon_action: 'congratulate',
    modal_title: polyglot.t('feed.action.modal.title.congratulated'),
    modal_title_mobile: polyglot.t(
      'feed.action.modal.title_mobile.congratulated',
    ),
  },
  CompanyEstablished: {
    action: 'like',
    text_action: polyglot.t('feed.action.like'),
    text_reverse_action: polyglot.t('feed.action.liked'),
    text_past_action: polyglot.t('feed.action.liked'),
    show: true,
    icon_action: 'favourite',
    modal_title: polyglot.t('feed.action.modal.title.liked'),
    modal_title_mobile: polyglot.t('feed.action.modal.title_mobile.liked'),
  },
  CompanyStartUsingWam: {
    action: 'like',
    text_action: polyglot.t('feed.action.like'),
    text_reverse_action: polyglot.t('feed.action.liked'),
    text_past_action: polyglot.t('feed.action.liked'),
    show: true,
    icon_action: 'favourite',
    modal_title: polyglot.t('feed.action.modal.title.liked'),
    modal_title_mobile: polyglot.t('feed.action.modal.title_mobile.liked'),
  },
  Twitter: {
    action: 'like',
    text_action: polyglot.t('feed.action.like'),
    text_reverse_action: polyglot.t('feed.action.liked'),
    text_past_action: polyglot.t('feed.action.liked'),
    show: true,
    icon_action: 'favourite',
    modal_title: polyglot.t('feed.action.modal.title.liked'),
    modal_title_mobile: polyglot.t('feed.action.modal.title_mobile.liked'),
  },
  PromotedWellbeing: {
    action: 'like',
    text_action: polyglot.t('feed.action.like'),
    text_reverse_action: polyglot.t('feed.action.liked'),
    text_past_action: polyglot.t('feed.action.liked'),
    show: true,
    icon_action: 'favourite',
    modal_title: polyglot.t('feed.action.modal.title.liked'),
    modal_title_mobile: polyglot.t('feed.action.modal.title_mobile.liked'),
  },
});

export const POST_TYPES = {
  NEW_RECOGNITION: 'NewRecognition',
  MOST_RECOGNIZED: 'MostRecognized',
  ADMIN_AD_HOC: 'AdminAdHoc',
  COMPANY_NEWS: 'CompanyNews',
  ORIGINATOR: 'Originator',
  TWITTER: 'Twitter',
  BIRTHDAY_USER: 'BirthdayUser',
  BIRTHDAY_COMPANY: 'BirthdayCompany',
  ANNIVERSARY: 'Anniversary',
  NEW_USER: 'NewUser',
  MILESTONE_USER: 'MilestoneUser',
  EAP: 'Eap',
  PERK: 'Perk',
  PROMOTED_WELLBEING: 'PromotedWellbeing',
  USER_AD_HOC: 'UserAdHoc',
};

export const STATIC_POST = {
  TYPE: {
    REGULAR: 0,
    STICKY: 1,
    PINNED: 2,
  },
};

export const DynamicMapping = {
  Wellness: {},
};

export const POSSIBLE_FILTERS = [
  {
    name: 'feed.filter.company_posts',
    feature: 'ap_feed_post',
    value: 'CompanyNews',
    dataHook: 'organisational-posts',
  },
  {
    name: 'feed.filter.recognitions',
    feature: 'social_recognition',
    value: 'Recognitions',
    dataHook: 'recognitions',
  },
  {
    name: 'feed.filter.posts',
    feature: 'social_feed_post',
    value: 'Posts',
    dataHook: 'posts',
  },
  {
    name: 'feed.filter.top_performers',
    feature: 'social_feed_most_recognized',
    value: 'Achievements',
    dataHook: 'top-performers',
  },
];

export const initPostsLimit = 5;
