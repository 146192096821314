import type { Dispatch, ThunkedAction, AppState } from 'globals/types';
import actions from './actions';
import * as api from './api';
import type { Fund } from './types';

export const fetchSpotRewards =
  (): ThunkedAction => async (dispatch: Dispatch, getState: () => AppState) => {
    const userId = ((getState().session || {}).user || {})['user_id'];

    try {
      const rewards = await api.fetchSpotRewards(userId ?? '');
      // we fetch a list of funds, but only 1 fund will be 'spot_rewards'
      let result: Record<string, any> = {};
      if (Array.isArray(rewards.body) && rewards.body.length > 0) {
        result = rewards.body.filter(
          (fund: Fund) => fund.type === 'spot_rewards',
        )[0];
      }
      dispatch(actions.fetchSpotRewardSuccess(result));
    } catch (error: any) {
      dispatch(actions.fetchSpotRewardFailure());
    }
  };

export const fetchFundTransactions =
  (): ThunkedAction => async (dispatch: Dispatch, getState: () => AppState) => {
    const fund = getState().spotRewards.fund;
    if (fund.id) {
      const transactions = await api.fetchFundTransactions(fund.id);

      dispatch(actions.fetchFundTransactionsSuccess(transactions.body));
    }
  };

export const fetchYourRewards =
  (): ThunkedAction => async (dispatch: Dispatch, getState: () => AppState) => {
    const codes = await api.fetchYourRewards(
      // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      ((getState().session || {}).user || {})['user_id'],
    );

    dispatch(actions.fetchYourRewardsSuccess(codes.body));
  };
