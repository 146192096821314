import { sha256 } from 'hash.js';
import { get, set, getKeys } from 'globals/storage';
import fetch from 'globals/wa-fetch';
import { isValidUrl, isUrlHttps } from 'wa-storybook/helpers/url-utils';
import { DisasterRecoveryService } from './context/disaster-recovery-context';
export const lookupKeys = {
  domain: 'domain',
  email: 'email',
  inviteCode: 'invite_code',
  keyword: 'keyword',
  tenancyHash: 'tenancyHash',
} as const;

export type LookupKeyType = (typeof lookupKeys)[keyof typeof lookupKeys];

export type LookupResponse = {
  body: {
    sso?: {
      sub_domain?: string;
      authentication_exit_point_url?: string;
      authentication_entry_point_url?: string;
      login_notice_company_name?: string;
      login_notice_image_url?: string;
      login_system_display_name?: string;
      translated_login_urls?: [string];
      dependant_accounts_enabled: boolean;
      translated_logout_urls?: [string];
      status: number;
    };
    branding?: {
      company_id?: string;
      nickname?: string;
      image_logo?: {
        url: string;
        width: number;
        height: number;
        predominant_color?: string;
        is_default: boolean;
      };
      image_square_logo: {
        url: string;
        width: number;
        height: number;
        predominant_color?: string;
        is_default: boolean;
      };
      image_cover: {
        url: string;
        width: number;
        height: number;
        predominant_color?: string;
        is_default: boolean;
      };
      locale?: string;
      name?: string;
      status: number;
    };
    api: RegionalApiEndpoints;
  };
  status: number;
};

export const getDefaultEndpoints = () =>
  ({
    waapi_root: window.WAM.ENV.apiRoot,
    wellbeing_root: window.WAM.ENV.apiLambda,
    services: window.WAM.ENV.apiServices,
    sso: window.WAM.ENV.ssoUrl,
  }) as const;

export type RegionalApiEndpoints = {
  waapi_root: string;
  wellbeing_root: string;
  services: string;
  sso: string;
};

export const setRegionalApiEndpoints = (
  regionalApiEndpoints: RegionalApiEndpoints,
): void => {
  set(
    getKeys().regionalApiEndpoints,
    JSON.stringify(regionalApiEndpoints),
    true,
  );
};

const validateEndpoints = (
  regionalApiEndpoints: RegionalApiEndpoints,
): boolean => {
  const endpoints = Object.keys(regionalApiEndpoints).filter(
    key => key !== 'status',
  );

  if (!endpoints.length) {
    return false;
  }

  for (const endpoint of endpoints) {
    const url = regionalApiEndpoints[endpoint];

    if (!isValidUrl(url)) {
      return false;
    }

    if (!isUrlHttps(url)) {
      return false;
    }
  }
  return true;
};

const hashLookupValue = (lookupValue: string) =>
  sha256().update(lookupValue.toLowerCase()).digest('hex');

export const getRegionalApiEndpoints = (): RegionalApiEndpoints => {
  const regionalEndpoints = get<string>(getKeys().regionalApiEndpoints, true);

  if (!regionalEndpoints) {
    setRegionalApiEndpoints(getDefaultEndpoints());
    return getDefaultEndpoints();
  }

  return JSON.parse(regionalEndpoints);
};

export const performTenancyLookup = (
  lookupKey: LookupKeyType,
  lookupValue: string,
  locale?: string,
): Promise<any> => {
  const keyValues: string[] = [];

  switch (lookupKey) {
    case lookupKeys.inviteCode:
    case lookupKeys.keyword:
      keyValues.push(hashLookupValue(lookupValue));
      break;

    case lookupKeys.email:
      const domain = lookupValue.split('@')[1];
      keyValues.push(hashLookupValue(lookupValue));
      keyValues.push(hashLookupValue(domain || ''));
      break;
    case lookupKeys.tenancyHash:
      const tenancyHashValues: string[] = JSON.parse(lookupValue);
      if (Array.isArray(tenancyHashValues)) {
        keyValues.push(...tenancyHashValues);
      }
      break;
    case lookupKeys.domain:
    default:
      keyValues.push(lookupValue);
      break;
  }

  let languageHeader: Record<string, any> = {};

  if (locale) {
    languageHeader = {
      headers: {
        'Lw-language': locale,
      },
    };
  }

  return fetch(
    `${
      window.WAM.ENV.apiLambda // Make sure we use the default one for the tenant lookup.
    }/tenancy-lookup-api/tenant`,
    {
      method: 'post',
      version: 1.3,
      body: { keys: keyValues },
      ...languageHeader,
    },
    null,
    true, // Use custom url to stop wa-fetch trying to send us to a regional endpoint.
    false,
    false,
  )
    .then((lookupResponse: LookupResponse) => {
      if (lookupResponse.status === 500) {
        DisasterRecoveryService.setIsInDisasterRecoveryMode(true);
      }
      const endpoints = lookupResponse.body.api;

      if (!validateEndpoints(endpoints)) {
        // Revert to default endpoints if validation failed.
        setRegionalApiEndpoints(getDefaultEndpoints());
        return Promise.resolve({
          body: { ...lookupResponse.body, api: getDefaultEndpoints() },
        });
      }

      setRegionalApiEndpoints(endpoints);
      return Promise.resolve(lookupResponse);
    })
    .catch(error => {
      if (error?.status === 500) {
        DisasterRecoveryService.setIsInDisasterRecoveryMode(true);
      }

      // Revert to default endpoints and allow the app to continue. This is a last resort effort
      // to keep the app alive for the EU shared tenant users in case of complete tenancy
      // lookup failure.
      setRegionalApiEndpoints(getDefaultEndpoints());

      return Promise.resolve({
        body: {
          branding: { status: 404 },
          sso: { status: 404 },
          api: getDefaultEndpoints(),
        },
      });
    });
};
