import { ApolloClient, StoreObject } from '@apollo/client';
import { getRelativeTime } from 'wa-storybook/global/date-utils';
import isEmpty from 'lodash/isEmpty';
import Language from '../../../../language';
import { locales } from 'globals/date-helpers';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import isFuture from 'date-fns/isFuture';
import isToday from 'date-fns/isToday';
import { getLongFormatDateWithoutYear } from 'globals/date-utils';

import {
  AssessmentAnswerFragment,
  AssessmentHistoryFragment,
  AssessmentHistoryQuery,
  AssessmentResultFragment,
  AssessmentResultQuery,
  AssessmentTierStateFragmentDoc,
  AssessmentTierStateFragment as AssessmentTierStateFragmentType,
  FinalAnswerMutationResponseFragment,
  MeasurementResultFragment,
  NextAssessmentFragment,
  TierRegressionTimePeriodInfo,
  TieringHubAllTiersFragment,
  TieringHubCurrentTierFragment,
  TieringHubNextTierFragment,
  TieringHubTierPointsFragment,
} from 'graphql-types';
import type { OptionWithIdWithoutHighlighted } from 'wa-storybook/components/inputs/accessible-dropdown/historic-select/historic-select';

import {
  formatPoints,
  getAnimatedProgressBarProps,
  getTierProgressPercentage,
} from '../../../tiering/components/custom-activity-display/custom-activity-display.helpers';
import { MeasurementResultCardProps } from 'pages/self-assessment/view/results/components/measurement-result-card/measurement-result-card';
import { stripNullsFromArray } from '../assessment/helpers/data.helpers';

import styles from './results-page.module.scss';

export type BaseTierState = {
  nextTier: TieringHubNextTierFragment;
  currentTier: TieringHubCurrentTierFragment;
  tierPoints: TieringHubTierPointsFragment;
};

export type NewTierState = BaseTierState & {
  nextTier: TieringHubNextTierFragment | null | undefined;
};

export type PointInfo = {
  pointsEarned: number;
  bonusPointsEarned: number;
};

export type TieringProgressionDescription = {
  oldTierState: BaseTierState;
  newTierState: NewTierState;
  allTiers: Array<TieringHubAllTiersFragment>;
} & PointInfo;

export type ResultsPageModel = {
  uuid?: string | null | undefined;
  title?: string;
  answers?:
    | Array<AssessmentAnswerFragment | null | undefined>
    | null
    | undefined;
  history?:
    | Array<AssessmentHistoryFragment | null | undefined>
    | null
    | undefined;
  result?: AssessmentResultFragment | null | undefined;
  nextAssessment?: NextAssessmentFragment | null | undefined;
  tieringProgressionDescription?:
    | TieringProgressionDescription
    | null
    | undefined;
  iconUrl?: string | null | undefined;
  canBeRetaken?: boolean | null | undefined;
  earnPointsStatus: EarnPointsStatus | null;
};

export const getOriginalTierStateCacheKey = (
  tierStateFragment: Readonly<StoreObject>,
): string | undefined => {
  // StoreObject doesn't understand that StoreValue can be a nested Object of another StoreValue (i.e. only thinks 1 level deep)
  // suppressing the errors in here until we can better understand if we've missed some kind of typing or if this is just simply
  // an oversight of the current type available from Apollo.

  // @ts-ignore
  const nextTier = tierStateFragment?.tierSummary?.nextTier;
  const isOriginalNextTier = !nextTier || nextTier.id === originalId;

  const isOriginalTierStateData =
    isOriginalNextTier &&
    // @ts-ignore
    tierStateFragment?.tierSummary?.currentTier?.id === originalId &&
    // @ts-ignore
    tierStateFragment?.tierSummary?.points?.id === originalId;

  if (isOriginalTierStateData) {
    return `${tierStateFragment.__typename}:${originalId}`;
  }

  return undefined;
};

export const getTieringProgressbarProps = (
  tieringProgress: TieringProgressionDescription,
  initialAnimationComplete: boolean,
  getAnimatedProgressBarPropsFunc: typeof getAnimatedProgressBarProps = getAnimatedProgressBarProps,
  getTierProgressPercentageFunc: typeof getTierProgressPercentage = getTierProgressPercentage,
  getTooltipTextFunc: typeof getTooltipText = getTooltipText,
  formatPointsFunc: typeof formatPoints = formatPoints,
) => {
  const { oldTierState, newTierState } = tieringProgress;

  if (!oldTierState.nextTier.rank) {
    return null;
  }

  const points = initialAnimationComplete
    ? newTierState.tierPoints.earned.total
    : oldTierState.tierPoints.earned.total;

  const remainingPoints = initialAnimationComplete
    ? newTierState.tierPoints.remainingToReachNextTier
    : oldTierState.tierPoints.remainingToReachNextTier;

  const activity = {
    tierPointAward: {
      points: tieringProgress.pointsEarned,
    },
  } as const;

  let willReachNextTier = false;

  if (
    activity &&
    activity.tierPointAward &&
    activity.tierPointAward.points &&
    oldTierState.tierPoints &&
    oldTierState.tierPoints.remainingToReachNextTier
  ) {
    willReachNextTier =
      activity.tierPointAward.points >=
      oldTierState.tierPoints.remainingToReachNextTier;
  }

  const currentRank = oldTierState.currentTier.rank - 1;
  const nextRank = oldTierState.nextTier.rank - 1;

  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  const currentTierBaseValue = tieringProgress.allTiers[currentRank].points;
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  const nextTierBaseValue = tieringProgress.allTiers[nextRank].points;

  const didEarnPoints = tieringProgress.pointsEarned > 0;

  if (!didEarnPoints) {
    return null;
  }

  return {
    ...getAnimatedProgressBarPropsFunc(
      initialAnimationComplete,
      formatPointsFunc(points),
      activity,
      oldTierState.currentTier,
      oldTierState.nextTier,
      oldTierState.tierPoints,
      tieringProgress.allTiers,
    ),
    progressValue: getTierProgressPercentageFunc(
      currentTierBaseValue,
      remainingPoints || 0,
      nextTierBaseValue,
    ),
    willReachNextTier,
    tooltipText: didEarnPoints
      ? getTooltipTextFunc({
          bonusPointsEarned: tieringProgress.bonusPointsEarned,
          pointsEarned: tieringProgress.pointsEarned,
        })
      : '',
  };
};

export const getDescription = (
  tierProgress?: TieringProgressionDescription | null,
  assessmentName?: string | null,
  returnInAriaFormat = false,
): string => {
  if (!tierProgress || !assessmentName) {
    return '';
  }

  const hasBonus = !!tierProgress.bonusPointsEarned;
  const oldTier = tierProgress.oldTierState.currentTier.rank;
  const newTier = tierProgress.newTierState.currentTier.rank;
  const didReachNextTier = oldTier !== newTier;

  if (hasBonus && didReachNextTier) {
    return polyglot.t(
      `${
        returnInAriaFormat
          ? 'self_assessment_2.aria.completed_bonus_reach_tier_aria_label'
          : 'self_assessment_2.completion_descriptions.completed_bonus_reach_tier'
      }`,
      {
        name: assessmentName,
        tier: tierProgress.newTierState.currentTier.name,
        smart_count: tierProgress.bonusPointsEarned,
      },
    );
  }

  if (hasBonus) {
    return polyglot.t(
      `${
        returnInAriaFormat
          ? 'self_assessment_2.aria.completed_bonus_aria_label'
          : 'self_assessment_2.completion_descriptions.completed_bonus'
      }`,
      {
        name: assessmentName,
        smart_count: tierProgress.bonusPointsEarned,
      },
    );
  }

  if (didReachNextTier) {
    return polyglot.t(
      `${
        returnInAriaFormat
          ? 'self_assessment_2.aria.completed_reach_tier_aria_label'
          : 'self_assessment_2.completion_descriptions.completed_reach_tier'
      }`,
      {
        name: assessmentName,
        tier: tierProgress.newTierState.currentTier.name,
      },
    );
  }

  return polyglot.t(
    `${
      returnInAriaFormat
        ? 'self_assessment_2.aria.completed_aria_label'
        : 'self_assessment_2.completion_descriptions.completed'
    }`,
    {
      name: assessmentName,
    },
  );
};

export const getTooltipText = (points: PointInfo) => {
  if (points.bonusPointsEarned) {
    return polyglot.t('self_assessment_2.points.with_bonus', points);
  }
  return polyglot.t('self_assessment_2.points.no_bonus', points);
};

export const getPointsEarned = (
  previousPoints: number,
  nextPoints: number,
  pointsForAssessment: number,
): PointInfo => {
  const diff = nextPoints - previousPoints;

  if (diff < pointsForAssessment) {
    return {
      bonusPointsEarned: 0,
      pointsEarned: 0,
    };
  }

  return {
    bonusPointsEarned: Math.max(0, diff - pointsForAssessment),
    pointsEarned: pointsForAssessment,
  };
};

export const getTierProgressionModel = (
  result: FinalAnswerMutationResponseFragment,
  originalTierState?: BaseTierState | null,
  allTiers?: Array<TieringHubAllTiersFragment> | null,
): TieringProgressionDescription | null => {
  if (
    !originalTierState ||
    !allTiers ||
    !result.tierProgress ||
    !result.tierProgress.tierSummary ||
    !result.tierProgress.tierSummary.currentTier ||
    !result.tierProgress.tierSummary.nextTier ||
    !result.tierProgress.tierSummary.points ||
    result.tierProgress?.tierSummary?.points?.earned.total == null
  ) {
    return null;
  }
  return {
    newTierState: {
      currentTier: result.tierProgress.tierSummary.currentTier,
      nextTier: result.tierProgress.tierSummary.nextTier,
      tierPoints: result.tierProgress.tierSummary.points,
    },
    oldTierState: {
      currentTier: originalTierState.currentTier,
      nextTier: originalTierState.nextTier,
      tierPoints: originalTierState.tierPoints,
    },
    allTiers,
    ...getPointsEarned(
      originalTierState.tierPoints.earned.total,
      result.tierProgress.tierSummary.points.earned.total,
      result.tierProgress.pointsForCompletingAssessment || 0,
    ),
  };
};

const originalId = 'original';

export const removePreviousTierDataFromCache = (client: ApolloClient<any>) => {
  client.writeFragment<AssessmentTierStateFragmentType>({
    id: `AssessmentItemCurrentTierProgress:${originalId}`,
    fragment: AssessmentTierStateFragmentDoc,
    fragmentName: 'AssessmentTierState',
    data: {
      __typename: 'AssessmentItemCurrentTierProgress',
      pointsForCompletingAssessment: null,
      bonusPointsForCompletingAllAssessments: null,
      tierSummary: null,
    },
  });
};

export const readPreviousTierDataFromCache = (client: ApolloClient<any>) => {
  const { tierSummary } =
    client.readFragment<AssessmentTierStateFragmentType>({
      id: `AssessmentItemCurrentTierProgress:${originalId}`,
      fragment: AssessmentTierStateFragmentDoc,
      fragmentName: 'AssessmentTierState',
    }) || {};
  if (!tierSummary) {
    return null;
  }

  const { nextTier, currentTier, points: tierPoints } = tierSummary;

  if (isEmpty(currentTier) || isEmpty(tierPoints) || isEmpty(nextTier)) {
    return null;
  }

  return {
    nextTier,
    currentTier,
    tierPoints,
  };
};

export const getResultsPageModel = (
  {
    resultData,
    commonAssessmentProperties,
  }: AssessmentHistoryQuery | AssessmentResultQuery,
  history?: Array<AssessmentHistoryFragment | null | undefined> | null,
  originalTierState?: BaseTierState | null,
  allTiers?: Array<TieringHubAllTiersFragment> | null,
): ResultsPageModel | null => {
  if (!resultData || !commonAssessmentProperties) {
    return null;
  }

  switch (resultData.__typename) {
    case 'HistoricalAssessment':
      return {
        result: resultData.result,
        earnPointsStatus: getEarnPointsStatus(
          history?.[0]?.cooldownPeriodExpirationDate,
        ),
        uuid: resultData.assessmentUuid,
        nextAssessment: null,
        answers: null,
        title: commonAssessmentProperties.title,
        history,
        iconUrl: commonAssessmentProperties.icon?.url,
        canBeRetaken: commonAssessmentProperties.canBeRetaken,
      };
    case 'AssessmentListItem':
      const hasTierDeltaInfo = !!originalTierState && !!allTiers;

      return {
        result: resultData.result,
        earnPointsStatus: getEarnPointsStatus(
          resultData.cooldownPeriodExpirationDate,
        ),
        uuid: resultData.uuid,
        title: commonAssessmentProperties.title,
        history,
        nextAssessment: resultData.nextAssessment,
        answers: resultData.answers,
        iconUrl: commonAssessmentProperties.icon?.url,
        canBeRetaken: commonAssessmentProperties.canBeRetaken,
        tieringProgressionDescription: hasTierDeltaInfo
          ? getTierProgressionModel(resultData, originalTierState, allTiers)
          : null,
      };
    default:
      return null;
  }
};

export const getHistoryDate = (
  completedAt: string | null | undefined,
  locale: string,
  relativeTime: typeof getRelativeTime = getRelativeTime,
): string => {
  if (completedAt && isToday(new Date(completedAt))) {
    return polyglot.t('self_assessment.results.today');
  }

  if (completedAt) {
    return relativeTime(new Date(completedAt).getTime() / 1000, locale);
  }

  return '';
};

export const getHistoricDropdownOptions = (
  history?: Array<AssessmentHistoryFragment | null | undefined> | null,
  historyDate: typeof getHistoryDate = getHistoryDate,
): Array<OptionWithIdWithoutHighlighted> | null => {
  if (!history) {
    return null;
  }

  const withoutNulls = stripNullsFromArray<AssessmentHistoryFragment>(history);

  return withoutNulls.map<OptionWithIdWithoutHighlighted>(historyItem => ({
    id: historyItem.archiveId,
    title: historyItem.result?.digestTitle || '',
    subtitle: historyDate(historyItem.completedAt, Language.getLocale(true)),
    progressPercentage: historyItem.result?.percentageScore || 0,
  }));
};

export const isBMIResult = (result: AssessmentResultFragment): boolean => {
  return !!(result.measurementResult && result.gender);
};

export const getBMIIcon = (gender?: string | null) => {
  switch (gender) {
    case 'male':
      return require('./assets/bmi-male.svg');
    case 'female':
      return require('./assets/bmi-female.svg');
    default:
      return require('./assets/bmi-other.svg');
  }
};

export const getBMICardProps = (
  measurementResult: MeasurementResultFragment,
  gender: string | null | undefined,
  isBMI: boolean,
): MeasurementResultCardProps | null => {
  if (!measurementResult.feedback?.title || !measurementResult.feedback?.body) {
    return null;
  }

  return {
    subtitle: isBMI ? polyglot.t('self_assessment.results.bmi_calc') : '',
    title: measurementResult.feedback?.title || '',
    description: measurementResult.feedback?.body || '',
    children: (
      <div className={styles['measurement-number']}>
        {measurementResult.score}
      </div>
    ),
    renderMetaContent: isBMI
      ? () => <img className={styles['bmi-image']} src={getBMIIcon(gender)} />
      : undefined,
  };
};

export const isLatestTakenAssessment = (
  history:
    | Array<AssessmentHistoryFragment | null | undefined>
    | null
    | undefined,
  archiveId: string,
): boolean => {
  return history?.[0]?.archiveId === archiveId;
};

export const hasCompletedRegression = (
  currentMonthInfo?: TierRegressionTimePeriodInfo | null,
) =>
  currentMonthInfo &&
  (currentMonthInfo.pointsEarned || currentMonthInfo.pointsEarned === 0) &&
  (currentMonthInfo.pointGoal || currentMonthInfo.pointGoal === 0)
    ? currentMonthInfo.pointsEarned >= currentMonthInfo.pointGoal
    : true;

export type EarnPointsStatus = {
  canEarnPointsNow: boolean;
  canEarnPointsFooter: string;
  modalDescription: string;
};

// we want to tell the user when they can earn tiering points by retaking the Assessment
export const getEarnPointsStatus = cooldownPeriodExpirationDate => {
  let canEarnPointsNow = true;
  let canEarnPointsFooter = polyglot.t(
    'self_assessment.results.cooldown.get_points_now',
  );
  let modalDescription = '';
  // you can earn points if cooldownPeriodExpirationDate is null or is in the past

  if (cooldownPeriodExpirationDate) {
    const cooldownPeriodExpirationDateJS = parseISO(
      cooldownPeriodExpirationDate,
    );

    if (!isNaN(cooldownPeriodExpirationDateJS.getTime())) {
      // just in case of invalid date string

      const formattedDate = getLongFormatDateWithoutYear(
        cooldownPeriodExpirationDate,
      );
      const formattedTime = format(cooldownPeriodExpirationDateJS, 'p', {
        locale: locales[Language.getLocale(true)],
      });

      if (isFuture(cooldownPeriodExpirationDateJS)) {
        canEarnPointsNow = false;

        if (isToday(cooldownPeriodExpirationDateJS)) {
          // you can earn points later today
          canEarnPointsFooter = polyglot.t(
            'self_assessment.results.cooldown.get_points_today',
            {
              time: formattedTime,
            },
          );
          modalDescription = polyglot.t(
            'self_assessment.results.retake_modal.description_today',
            {
              time: formattedTime,
            },
          );
        } else {
          // you can earn points some day in the future
          canEarnPointsFooter = polyglot.t(
            'self_assessment.results.cooldown.get_points_future',
            {
              date: formattedDate,
              time: formattedTime,
            },
          );
          modalDescription = polyglot.t(
            'self_assessment.results.retake_modal.description_future',
            {
              date: formattedDate,
              time: formattedTime,
            },
          );
        }
      }
    }
  }

  return { canEarnPointsNow, canEarnPointsFooter, modalDescription };
};
