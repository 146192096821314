import { testableFeaturesEnabled } from 'globals/testable';

/*
 When adding a new language, please make sure you add it in all required places
 And perform the necessary checks
 See the Confluence page for more information about changes to Storybook, Hermes and Zeus
 https://workivate.atlassian.net/wiki/spaces/WT/pages/4121755701
 */

// Add locales here that should appear on test only.
const testableLanguages: ReadonlyArray<string> = [];

export const hippoLanguageMap = [
  { received: 'bn_WW', mapsTo: 'bn_BD' },
  { received: 'es_WW', mapsTo: 'es_ES' },
  { received: 'fr_WW', mapsTo: 'fr_FR' },
  { received: 'hr_WW', mapsTo: 'hr_HR' },
  { received: 'lv_WW', mapsTo: 'lv_LV' },
  { received: 'mn_WW', mapsTo: 'mn_MN' },
  { received: 'pt_419', mapsTo: 'pt_BR' },
  { received: 'pt_WW', mapsTo: 'pt_PT' },
  { received: 'sl_WW', mapsTo: 'sl_SI' },
  { received: 'sr_WW', mapsTo: 'sr_RS' },
  { received: 'ta_WW', mapsTo: 'ta_IN' },
  { received: 'ur_WW', mapsTo: 'ur_PK' },
  { received: 'zh_WW', mapsTo: 'zh_CN' },
  { received: 'zh_142', mapsTo: 'zh_HK' },
  { received: 'zu_WW', mapsTo: 'zu_ZA' },
] as const;

export const supportedLanguages: ReadonlyArray<string> = [
  'en_GB',
  'en_US',
  'en_CA',
  'en_AU',
  'es_US',
  'fr_CA',
  'af_ZA',
  'am_ET',
  'ar_001',
  'az_AZ',
  'bn_BD',
  'bs_BA',
  'bg_BG',
  'zh_CN',
  'zh_HK',
  'hr_HR',
  'cs_CZ',
  'da_DK',
  'nl_NL',
  'et_EE',
  'fi_FI',
  'fr_FR',
  'de_DE',
  'el_GR',
  'he_IL',
  'hi_IN',
  'hu_HU',
  'id_ID',
  'it_IT',
  'ja_JP',
  'ko_KR',
  'lv_LV',
  'lt_LT',
  'ms_MY',
  'mn_MN',
  'ne_NP',
  'nb_NO',
  'pl_PL',
  'pt_BR',
  'pt_PT',
  'ro_RO',
  'ru_RU',
  'sr_RS',
  'sk_SK',
  'sl_SI',
  'so_SO',
  'es_ES',
  'es_419',
  'sw_KE',
  'sv_SE',
  'tl_PH',
  'ta_IN',
  'th_TH',
  'tr_TR',
  'uk_UA',
  'ur_PK',
  'vi_VN',
  'xh_ZA',
  'zu_ZA',
  ...(testableFeaturesEnabled() ? testableLanguages : []),
];

export const coreCountryCodes: ReadonlyArray<string> = ['GB', 'US', 'CA', 'AU'];

export const rtlLanguages: ReadonlyArray<string> = ['ar_001', 'he_IL', 'ur_PK'];
