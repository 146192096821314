import { client } from 'graphql/client';
import format from 'date-fns/format';
import { formatTimeRange, getISOInterval } from 'globals/date-utils';
import { getStore } from 'app/hermes-redux';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

import {
  AppointmentStatus,
  AppointmentType,
  AssessedAsSuicideRiskDocument,
  AssessedAsSuicideRiskQuery,
  CounsellorProfileFragment,
  FetchUpcomingAppointmentsDocument,
  FetchUpcomingAppointmentsQuery,
  FetchYourCounsellorDocument,
  FetchYourCounsellorQuery,
  TimeSlotFragment,
} from 'graphql-types';
import type { SortedDatesArray } from '../containers/timeslot-selection/state/hooks';

import { hasCarePlanAssigned } from '../../care-plan/care-plan.helpers';
import { MyCareRoutes } from '../../my-care/my-care.constants';
import { showToast } from 'toaster-comp/state/actions';
import { ToastType } from 'toaster-comp/state/types';

type CounsellorNameProps = {
  firstName?: CounsellorProfileFragment['firstName'];
  lastName?: CounsellorProfileFragment['lastName'];
};

type AppointmentTimeDisplayPropsFromData = {
  // https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
  isoInterval: string;
  formattedTime: string;
};

export const getCounsellorName = ({
  firstName,
  lastName,
}: CounsellorNameProps): string | null =>
  firstName && lastName
    ? `${firstName || ''} ${lastName || ''}`
    : firstName || lastName || null;

export const getCounsellorShortName = ({
  firstName,
  lastName,
}: CounsellorNameProps): string => {
  const firstLetterFirstName = (firstName || '').charAt(0);
  const firstLetterLastName = (lastName || '').charAt(0);
  const joinAble = [firstLetterFirstName, firstLetterLastName];
  return joinAble.join('').toUpperCase();
};

export const getJoinedJobTitles = (
  jobTitles?: CounsellorProfileFragment['jobTitles'],
): string => jobTitles?.filter(Boolean).join(', ') ?? '';

export const formatAppointmentTime = (
  timeSlot: TimeSlotFragment,
): AppointmentTimeDisplayPropsFromData => {
  const start = new Date(timeSlot.startDateTime);
  const end = new Date(timeSlot.endDateTime);

  return {
    formattedTime: formatTimeRange(start, end),
    isoInterval: getISOInterval(start, end),
  };
};

const isBeforeMidday = (date: Date) => date.getHours() < 12;

const isAfterMiddayButIsNotEvening = (date: Date) =>
  !isBeforeMidday(date) && date.getHours() < 18;

const isAfterMiddayAndIsEvening = (date: Date) => date.getHours() >= 18;

export const groupByStartDate = (
  timeslots: Array<TimeSlotFragment>,
): SortedDatesArray => {
  if (!timeslots?.length) {
    return [];
  }

  const formattedStartDate = date => format(new Date(date), 'yyyy-MM-dd');

  const groupMap = groupBy(timeslots, t => formattedStartDate(t.startDateTime));

  const orderByStartDateTimeFunc = (map, key) =>
    orderBy(map[key], t => t.startDateTime, 'asc');

  const groupArray: Array<any> = [];
  for (const groupKey of Object.keys(groupMap)) {
    const morning = orderByStartDateTimeFunc(groupMap, groupKey).filter(d =>
      isBeforeMidday(new Date(d.startDateTime)),
    );
    const afternoon = orderByStartDateTimeFunc(groupMap, groupKey).filter(d =>
      isAfterMiddayButIsNotEvening(new Date(d.startDateTime)),
    );
    const evening = orderByStartDateTimeFunc(groupMap, groupKey).filter(d =>
      isAfterMiddayAndIsEvening(new Date(d.startDateTime)),
    );

    const firstAvailableTimeslot =
      morning[0] || afternoon[0] || evening[0] || null;

    groupArray.push({
      date: groupKey,
      firstAvailableTimeslot,
      timeslots: {
        morning,
        afternoon,
        evening,
      },
    });
  }

  return orderBy(groupArray, g => g.date, 'asc');
};

export const getHasAssignedCounsellor = async () => {
  const { data: counsellorData } = await client.query<FetchYourCounsellorQuery>(
    {
      query: FetchYourCounsellorDocument,
    },
  );

  return {
    hasAssignedCounsellor: !!counsellorData?.fetchCounsellor?.counsellor,
    appointmentTypes:
      counsellorData?.fetchCounsellor?.counsellor?.appointmentTypes,
    isBookable: counsellorData?.fetchCounsellor?.counsellor?.allowsBooking,
    canViewHistory: counsellorData?.fetchCounsellor?.counsellor?.displayHistory,
  };
};

export const getHasUpcomingAppointments = async () => {
  const { data: appointmentsData } =
    await client.query<FetchUpcomingAppointmentsQuery>({
      query: FetchUpcomingAppointmentsDocument,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          first: 1,
          where: {
            statuses: [AppointmentStatus.Pending, AppointmentStatus.Booked],
          },
        },
      },
    });

  // in the BETA a user should only ever be able to book 1 appointment - this will eventually change to 3 or more.
  return (
    (appointmentsData?.fetchAppointments?.appointments?.data?.length ?? 0) >= 1
  );
};

export const getUserIsAtRisk = async () => {
  const { data } = await client.query<AssessedAsSuicideRiskQuery>({
    query: AssessedAsSuicideRiskDocument,
  });

  return {
    userIsAtRisk: !!data?.assessedAsSuicideRisk?.ccsTeamTelephoneNumber,
  };
};

export const showRedirectionToast = (message: string, type = ToastType.INFO) =>
  getStore().dispatch(
    showToast({
      message,
      type,
    }),
  );

export const shouldGrantAccessToCounsellorSelectionFlow = async () => {
  try {
    const hasCarePlan = await hasCarePlanAssigned();
    const { hasAssignedCounsellor, appointmentTypes } =
      await getHasAssignedCounsellor();
    const hasUpcomingAppointments = await getHasUpcomingAppointments();
    const { userIsAtRisk } = await getUserIsAtRisk();

    if (!hasCarePlan) {
      // if the user has no care plan assigned and tries to access any part of appointments
      // they should be redirected to the care hub

      showRedirectionToast(
        polyglot.t('appointments.toaster.choose_a_careplan'),
      );

      return {
        provideAccess: false,
        redirectTo: MyCareRoutes.MY_CARE_HUB,
      };
    } else if (hasAssignedCounsellor) {
      // if the user has an assigned counsellor while trying to access the counsellor selection
      // we need to check if selected counsellor can be changed to a new one

      const isInPersonCounsellor = appointmentTypes?.includes(
        AppointmentType.InPerson,
      );

      if (userIsAtRisk || isInPersonCounsellor || hasUpcomingAppointments) {
        return {
          provideAccess: false,
          redirectTo: MyCareRoutes.MY_CARE_HUB,
        };
      }
    }

    return {
      provideAccess: true,
      redirectTo: '',
    };
  } catch {
    // if any error is caught send them to the MyCare Hub

    showRedirectionToast(
      polyglot.t('api_errors.something_wrong'),
      ToastType.ERROR,
    );

    return {
      provideAccess: false,
      redirectTo: MyCareRoutes.MY_CARE_HUB,
    };
  }
};

export const shouldGrantAccessToViewAppointments = async () => {
  try {
    const { appointmentTypes, canViewHistory } =
      await getHasAssignedCounsellor();

    if (
      appointmentTypes?.includes(AppointmentType.InPerson) ||
      !canViewHistory
    ) {
      // if the user is trying to view their appointments list we want to redirect
      // them to the My Care Hub because they can only book appointments through the chat
      // and its not guaranteed that any other appointments they book with their counsellor
      // in person are returned via Dynamics or Solace

      showRedirectionToast(
        polyglot.t('appointments.toaster.in_person_counsellor'),
      );

      return {
        provideAccess: false,
        redirectTo: MyCareRoutes.MY_CARE_HUB,
      };
    }

    return {
      provideAccess: true,
      redirectTo: '',
    };
  } catch {
    // if any error is caught send them to the MyCare Hub

    showRedirectionToast(
      polyglot.t('api_errors.something_wrong'),
      ToastType.ERROR,
    );

    return {
      provideAccess: false,
      redirectTo: MyCareRoutes.MY_CARE_HUB,
    };
  }
};

export const shouldGrantAccessToAppointmentsSelectionFlow = async () => {
  try {
    const hasCarePlan = await hasCarePlanAssigned();
    const { hasAssignedCounsellor, appointmentTypes, isBookable } =
      await getHasAssignedCounsellor();

    const hasUpcomingAppointments = await getHasUpcomingAppointments();
    const isInPersonCounsellor = appointmentTypes?.includes(
      AppointmentType.InPerson,
    );

    if (!hasCarePlan) {
      // if the user has no care plan assigned and tries to access any part of appointments
      // they should be redirected to the care hub

      showRedirectionToast(
        polyglot.t('appointments.toaster.choose_a_careplan'),
      );

      return {
        provideAccess: false,
        redirectTo: MyCareRoutes.MY_CARE_HUB,
      };
    } else if (hasAssignedCounsellor) {
      if (isInPersonCounsellor || !isBookable) {
        // if the user has an assigned counsellor while trying to access the book appointment flow
        // and the counsellor has IN_PERSON appointment types we should send them back to the hub
        // and have them book utilize the chat instead

        showRedirectionToast(
          polyglot.t('appointments.toaster.in_person_counsellor'),
        );

        return {
          provideAccess: false,
          redirectTo: MyCareRoutes.MY_CARE_HUB,
        };
      } else if (hasUpcomingAppointments) {
        // if the user has an assigned counsellor and upcoming appointments we should send
        // them back to My Care Hub as currently they cannot book more than 1 appointment
        // through the Web App

        showRedirectionToast(
          polyglot.t('appointments.toaster.already_have_appointment'),
        );

        return {
          provideAccess: false,
          redirectTo: MyCareRoutes.MY_CARE_HUB,
        };
      }

      return {
        provideAccess: true,
        redirectTo: '',
      };
    }

    return {
      provideAccess: true,
      redirectTo: '',
    };
  } catch {
    // if any error is caught send them to the MyCare Hub

    showRedirectionToast(
      polyglot.t('api_errors.something_wrong'),
      ToastType.ERROR,
    );

    return {
      provideAccess: false,
      redirectTo: MyCareRoutes.MY_CARE_HUB,
    };
  }
};
