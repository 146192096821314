import * as Comlink from 'comlink';

export async function runOnWebWorker(cb) {
  let worker: Worker;

  if (IS_DEVELOPMENT) {
    worker = new Worker(new URL('web.worker', import.meta.url));
  } else {
    worker = new Worker(
      `https://${window.location.hostname}/worker/my-worker.js`,
    );
  }

  const remoteFunction = Comlink.wrap(worker);

  // @ts-ignore
  await remoteFunction(Comlink.proxy(cb));

  // terminate the worker
  worker.terminate();
}
