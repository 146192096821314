import { Workbox } from 'workbox-window';

export function registerServiceWorker() {
  if (IS_DEVELOPMENT) return;

  if ('serviceWorker' in navigator) {
    const wb = new Workbox(
      `https://${window.location.hostname}/service-worker.js`,
    );

    wb.register();
  }
}
