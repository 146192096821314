import React, { useEffect } from 'react';
import queryString from 'query-string';

let isInDisasterRecoveryMode = false;

const DisasterRecoveryService = {
  setIsInDisasterRecoveryMode: (value: boolean) => {
    isInDisasterRecoveryMode = value;
  },
  getIsInDisasterRecoveryMode: () => {
    return isInDisasterRecoveryMode;
  },
};

export const DisasterRecoveryContext = React.createContext<boolean>(false);

interface DisasterRecoveryProviderProps {
  children: React.ReactNode;
}

export const DisasterRecoveryProvider: React.FC<
  DisasterRecoveryProviderProps
> = ({ children }) => {
  // This can be the case when the user is redirected from USL after a 500 error
  useEffect(() => {
    const query = queryString.parse(location.search);
    const { error: queryStringError } = query;

    if (queryStringError === 'server_error') {
      DisasterRecoveryService.setIsInDisasterRecoveryMode(true);
    }
  }, []);

  return (
    <DisasterRecoveryContext.Provider value={isInDisasterRecoveryMode}>
      {children}
    </DisasterRecoveryContext.Provider>
  );
};

export { DisasterRecoveryService };
