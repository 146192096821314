import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';
import featureKeys from '../../constants/feature-keys';
import { routes } from './tiering.constants';

function resolver() {
  return Promise.all([
    import(
      /* webpackChunkName: "tiering" */ './containers/tiering-page/tiering-page'
    ),
    import(/* webpackChunkName: "tiering" */ './state/reducers/index'),
  ]).then(([component, reducers]) => {
    injectAsyncReducer('tiering', reducers.default);
    return component;
  });
}

export default {
  hub: {
    name: routes.TIERING_HUB.name,
    url: routes.TIERING_HUB.path,
    resolver,
    restrictions: {
      user: [featureKeys.WELLBEING_TIERING],
    },
    data: {
      section: 'wellBeing',
      mainMenu: 'tiering',
      includeInMenu: true,
      title: 'tiering.nav_title',
      infoIcon: true,
      order: 4,
      authorizedRoles: [USER_ROLES.employee],
      hideFooter: true,
    },
  },
  hubWithActivityModalOpen: {
    name: routes.TIERING_HUB_ACTIVITY_MODAL.name,
    url: routes.TIERING_HUB_ACTIVITY_MODAL.path,
    resolver,
    restrictions: {
      user: [featureKeys.WELLBEING_TIERING],
    },
    data: {
      section: 'wellBeing',
      mainMenu: 'tiering',
      authorizedRoles: [USER_ROLES.employee],
      hideFooter: true,
    },
  },
  rewards: {
    name: routes.TIER_REWARDS.name,
    url: routes.TIER_REWARDS.path,
    resolver: () =>
      Promise.all([
        import(
          /* webpackChunkName: "tiering" */ './containers/tier-rewards-page/tier-rewards-page'
        ),
        import(/* webpackChunkName: "tiering" */ './state/reducers/index'),
      ]).then(([component, reducers]) => {
        injectAsyncReducer('tiering', reducers.default);
        return component;
      }),
    restrictions: {
      user: [featureKeys.WELLBEING_REWARDS],
    },
    data: {
      mainMenu: 'tierRewards',
      section: 'wellBeing',
      includeInMenu: true,
      order: 5,
      title: 'components.main_menu.rewards',
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};
