import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';
import { restrictions } from 'state/features-list/constants';
import { consentSettings } from './settings.constants';

function settingsResolver() {
  return Promise.all([
    import(/* webpackChunkName: "settings" */ './view/settings'),
    import(/* webpackChunkName: "settings" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('settings', reducer.settingsReducer);
    return component;
  });
}

function passwordChangeResolver() {
  return Promise.all([
    import('./view/password-change/password-change'),
    import('./state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('settings', reducer.settingsReducer);
    return component;
  });
}

const settingsModule = {
  settings: {
    name: 'settings',
    url: '/settings',
    resolver: settingsResolver,
    data: {
      title: 'components.main_menu.settings',
      section: 'myAccount',
      mainMenu: 'settings',
      includeInMenu: true,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
      shortcutKeyCode: 56,
    },
  },
  passwordChange: {
    name: 'passwordChange',
    url: '/settings/change-password',
    data: {
      title: 'settings.password.btn_label',
      authorizedRoles: [USER_ROLES.employee],
    },
    restrictions: {
      user: [restrictions['password_reset']],
    },
    resolver: passwordChangeResolver,
  },
  consentSettings: {
    name: consentSettings,
    url: '/consent-settings',
    resolver: () => import('./view/consent-settings/consent-settings'),
    data: {
      title: 'settings.manage_consent',
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default settingsModule;
