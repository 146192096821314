import { runOnWebWorker } from 'app/run-on-web-worker';
import { getStore } from 'app/hermes-redux';
import PageTitle from 'head/state/actions';
import { getRouterState } from './router-state-provider';
import {
  handleRestrictions,
  handleComplexRestrictions,
} from 'globals/restrictions-handler';
import type { Module, Restrictions, RestrictionAction } from 'router/types';
import { goTo } from 'router/navigation';
import {
  performConsentRedirectionFlow,
  backgroundRefreshConsentsPageBlacklist,
} from 'pages/consent/helpers';
import type { AppRole } from 'app/state/session/types';
import USER_ROLES from 'constants/user-roles.constant';
import { AppointmentsRoutes } from 'pages/appointments/state/constants';

export const updatePageTitle = (currentRoute: Module) => {
  if (currentRoute?.data?.title) {
    getStore().dispatch(
      PageTitle.setCurrentSection(polyglot.t(currentRoute.data.title)),
    );
  }
};

/*
  Check if user has the permission the move to a route
*/
export function isUserAuthorized(
  route: Module | undefined | null,
  authorizedRoles?: AppRole[] | null,
) {
  const userRole = getStore().getState().session.role;

  return (
    route &&
    (!authorizedRoles ||
      authorizedRoles.some(
        (r: AppRole) => r === userRole || r === USER_ROLES.all,
      ))
  );
}

export const onVisibilityChange = () => {
  const routerState = getRouterState();

  if (
    document.visibilityState === 'visible' &&
    !backgroundRefreshConsentsPageBlacklist.includes(
      routerState.currentRoute?.name,
    )
  ) {
    runOnWebWorker(() => {
      performConsentRedirectionFlow(routerState.currentRoute, null, true);
    });
  }
};

export const applyRestrictions = (
  { user, company, operator, minimum }: Restrictions,
  shouldRedirect = true,
) => {
  const restrictionAction: RestrictionAction =
    company && user
      ? handleComplexRestrictions(user, company, operator, minimum)
      : handleRestrictions(user, operator, minimum);

  if (!restrictionAction.result) {
    if (shouldRedirect && 'redirectTo' in restrictionAction) {
      goTo(restrictionAction.redirectTo, undefined, {
        shouldNotPushToHistory: true,
      });
    }
    return false;
  }

  return true;
};

export const checkRestrictions = async (
  curentRoute: Module,
  previousRoute: Module,
) => {
  if (!curentRoute?.url) {
    return;
  }

  if (
    curentRoute?.restrictions &&
    !applyRestrictions(curentRoute.restrictions)
  ) {
    return;
  }

  const shouldGrantAccess = await (curentRoute.shouldGrantAccess
    ? curentRoute.shouldGrantAccess()
    : Promise.resolve(true));

  if (typeof shouldGrantAccess === 'object') {
    const params = shouldGrantAccess.params || false;
    if (!shouldGrantAccess.provideAccess) {
      goTo(shouldGrantAccess.redirectTo, params, {
        shouldNotPushToHistory: true,
      });
      return;
    }
  }

  if (!shouldGrantAccess) {
    return goTo('/404', undefined, { shouldNotPushToHistory: true });
  }

  const isAppointmentConfirmationPage =
    curentRoute.name === AppointmentsRoutes.APPOINTMENT_CONFIRMATION;

  if (isAppointmentConfirmationPage) {
    const willRedirectToConsents = await performConsentRedirectionFlow(
      curentRoute,
      previousRoute,
    );

    if (willRedirectToConsents) {
      return;
    }
  } else {
    runOnWebWorker(() => {
      performConsentRedirectionFlow(curentRoute, previousRoute);
    });
  }
};

// location.search does not pick up the router params
// so we pass the search params to this function
export const checkZendeskRedirect = searchParams => {
  const returnTo = searchParams?.['return_to'];
  const hasUnaddressedZendeskRedirect =
    returnTo && location.pathname !== '/redirecting';

  if (hasUnaddressedZendeskRedirect) {
    window.open(
      `${location.protocol}//${location.host}/redirecting?message=employee_assistance.external_links.zendesk_redirecting_message&return_to=${returnTo}`,
      '_self',
    );
  }
};
