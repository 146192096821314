import { getStore } from 'app/hermes-redux';
import { logout } from 'state/session/actions';

import USER_ROLES from 'constants/user-roles.constant';

const confirmEmailModule = {
  name: 'confirEmail',
  url: '/confirm-email',
  resolver: () => {
    // force logout
    return getStore()
      .dispatch(logout(true))
      .then(
        () =>
          import(
            /* webpackChunkName: "confirm-email" */ './confirm-email-comp'
          ),
      );
  },
  data: {
    title: 'components.main_menu.confirm_email',
    authorizedRoles: [USER_ROLES.all],
  },
};

export default confirmEmailModule;
