import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "shop-online" */ './view/shop-online-comp'),
    import(/* webpackChunkName: "shop-online" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('shopOnline', reducer.shopOnlineReducer);
    return component;
  });
}

const shopOnlineModule = {
  shop: {
    name: 'shop',
    url: '/shop',
    resolver,
    restrictions: {
      user: [restrictions['benefit_online_shop']],
    },
    data: {
      title: 'components.main_menu.shop_online',
      section: 'deals',
      mainMenu: 'shopOnline',
      includeInMenu: true,
      showSearch: false,
      order: 3,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  detailsCashback: {
    name: 'shopDetailsCashback',
    url: '/shop/retailers/:benefit_id',
    resolver,
    restrictions: {
      user: [restrictions['benefit_online_shop']],
    },
    data: {
      title: 'components.main_menu.shop_online',
      section: 'deals',
      mainMenu: 'shopOnline',
      includeInMenu: false,
      showSearch: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  detailsOffer: {
    url: '/shop/offer/:benefit_id/:offer_id',
    name: 'shopDetailsOffer',
    resolver,
    restrictions: {
      user: [restrictions['benefit_online_shop']],
    },
    data: {
      title: 'components.main_menu.shop_online',
      section: 'deals',
      mainMenu: 'shopOnline',
      includeInMenu: false,
      showSearch: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  category: {
    url: '/shop/retailers/category/:category_id',
    name: 'shopCategory',
    resolver,
    restrictions: {
      user: [restrictions['benefit_online_shop']],
    },
    data: {
      title: 'components.main_menu.shop_online',
      section: 'deals',
      mainMenu: 'shopOnline',
      includeInMenu: false,
      showSearch: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default shopOnlineModule;
