import { StorageKey, get, set } from '../storage';
import { AppLogger } from '@workivate/tho-web-shared';
import { useState } from 'react';

type SetterFunction<T> = (val: T | StateSetterFunction<T>) => void;
type StateSetterFunction<T> = (oldVal: T | null) => T;

type UseStorageReturn<T> = [T | null, SetterFunction<T>];

/**
 * we need this lengthy union for T in order for flow to infer inside the definition
 * that T cannot be a function, which allows for proper type refinement in the
 * setValue function body.
 */
export const useStorage = <T extends any>(
  key: StorageKey,
  initialValue: T | null,
  persist: boolean,
): UseStorageReturn<T> => {
  const [storedValue, setStoredValue] = useState<T | null>((): T | null => {
    try {
      const item: string | null = get(key, persist);
      return item ? JSON.parse(item) : initialValue;
    } catch {
      return initialValue;
    }
  });

  const setValue = (value: T | StateSetterFunction<T>) => {
    try {
      // Allow value to be a function so we have same API as useState
      let valueToStore: T;
      if (typeof value === 'function') {
        valueToStore = (value as StateSetterFunction<T>)(storedValue);
      } else {
        valueToStore = value;
      }
      setStoredValue(valueToStore);
      set(key, JSON.stringify(valueToStore), persist);
    } catch {
      AppLogger.error('Could not set a value of undefined in storage');
    }
  };

  return [storedValue, setValue];
};
