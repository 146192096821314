// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** function to get dimensions
  usage example to get \`tablet\` value from \`\$page-max-widths\`
  .class {
    max-width: dim(\$page-max-widths, tablet);
  }

 */
.permanent-toast-module__toast___a8t3w {
  color: var(--surface1, #fff);
  text-align: center;
  height: 50px;
  width: 100%;
  padding: 0;
  background-color: var(--green600, #2b8000);
  border: none;
}
.permanent-toast-module__toast___a8t3w:focus {
  outline: var(--green300, #2b8000) solid 2px;
  outline-offset: 3px;
}
.permanent-toast-module__toast___a8t3w:focus {
  outline-offset: -4px;
}
.permanent-toast-module__toast___a8t3w:hover {
  text-decoration: underline;
}

.permanent-toast-module__toast-children___S3Y5D {
  height: 50px;
  line-height: 50px;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./node_modules/wa-storybook/styles/tools/functions/_widths.scss","webpack://./client/app/react-components/permanent-toast/permanent-toast.module.scss","webpack://./node_modules/wa-storybook/styles/tools/mixins/_focus-outline.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;ACEA;EACE,4BAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,0CAAA;EACA,YAAA;AAMF;ACRI;EACE,2CAAA;EACA,mBAAA;ADUN;AAPE;EACE,oBAAA;AASJ;AANE;EACE,0BAAA;AAQJ;;AAJA;EACE,YAAA;EACA,iBAAA;EACA,eAAA;AAOF","sourcesContent":["/** function to get dimensions\n  usage example to get `tablet` value from `$page-max-widths`\n  .class {\n    max-width: dim($page-max-widths, tablet);\n  }\n\n */\n\n@function dim($prop, $key) {\n  @return map-get($prop, $key);\n}\n","@import '~wa-storybook/styles/utils.scss';\n\n.toast {\n  color: color(surface1);\n  text-align: center;\n  height: 50px;\n  width: 100%;\n  padding: 0;\n  background-color: color(green600);\n  border: none;\n  @include focus-outline($color: color(green300));\n\n  &:focus {\n    outline-offset: -4px;\n  }\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n.toast-children {\n  height: 50px;\n  line-height: 50px;\n  font-size: rem(16);\n}\n","@import '../functions/color';\n\n@mixin focus-outline($color: color(focus), $applyDirectly: false) {\n  @if $applyDirectly {\n    outline: $color solid 2px;\n    outline-offset: 3px;\n  } @else {\n    &:focus {\n      outline: $color solid 2px;\n      outline-offset: 3px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": `permanent-toast-module__toast___a8t3w`,
	"toast-children": `permanent-toast-module__toast-children___S3Y5D`
};
export default ___CSS_LOADER_EXPORT___;
