export const storageKeys = Object.freeze({
  // Mandatory keys
  lwLanguage: 'lw-language',
  sharedAccountUsers: 'sharedAccountUsers',
  cinemasOrder: 'cinemasOrder',
  giftCardsOrder: 'giftCardsOrder',
  giftCardsSelectedDiningCard: 'giftCardsSelectedDiningCard',
  giftCardsSelectedGiftCard: 'giftCardsSelectedGiftCard',
  mfaSetup: 'mfaSetup',
  msTeamsReturnUrl: 'msTeamsReturnUrl',
  isFromTeams: 'isFromTeams',
  persistence: 'persistence',
  regionalApiEndpoints: 'regionalApiEndpoints',
  retailerRedirectPayload: 'retailerRedirectPayload',
  shopOnlineBoostedCashback: 'shoponline-boosted-cashback',
  signupCompany: 'signupCompany',
  signupResponse: 'signupResponse',
  signupSession: 'signupSession',
  signupReturnUrl: 'signupReturnUrl',
  SSOToken: 'SSOToken',
  token: 'wamToken',
  tokenExpiryTime: 'tokenExpiryTime',
  useGlobalContent: 'useGlobalContent',
  usePerksGlobalContent: 'usePerksGlobalContent',
  useAssessmentsGlobalContent: 'useAssessmentsGlobalContent',
  isCreatingLimitedAccount: 'isCreatingLimitedAccount',
  darkMode: 'darkMode',
  tenancyHash: 'tenancyHash',
  temporaryOneTimeWamToken: 'temporaryOneTimeWamToken',
  temporaryGhostWamToken: 'temporaryGhostWamToken',
  authenticationMethod: 'authenticationMethod',

  // Functional keys
  isNativeStoreBannerDismissed: 'isNativeStoreBannerDismissed',
  isProfileEditDisclaimerTooltipDismissed:
    'isProfileEditDisclaimerTooltipDismissed',
  pensionBenefitsDontShowProceedMessage: 'pbMessage',
  recentPerksSearch: 'recentPerksSearch',
  recentSearches: 'recentSearches',
  shouldPersistLoginPageLanguage: 'shouldPersistLoginPageLanguage',
  walkthroughExcludeList: 'walkthroughExcludeList',
  hasDismissedRegressionPopUp: 'hasDismissedRegressionPopUp',
  appointmentContactDetails: 'apptCoDet',
  nextCareNowModule: 'nextCareNowModule',
  nextCarePlanModule: 'nextCarePlanModule',

  // Non-production keys
  test: 'test',
  testableFeaturesEnabled: 'testable',
  setEnvironment: 'setEnvironment',
  debugLogsOn: 'debugLogsOn',
  dataDogEnabled: 'dataDogEnabled',
  isUnifiedLoginEnabled: 'isUnifiedLoginEnabled',
});

export type StorageKey = (typeof storageKeys)[keyof typeof storageKeys];

export function getKeys() {
  return storageKeys;
}

export function setPersistence(persist: boolean) {
  set('persistence', persist, true);
}

export function getPersistence() {
  return get('persistence', true) === true;
}

export function set<ValueType>(
  key: StorageKey,
  value: ValueType,
  persist?: boolean,
) {
  let persistent;

  if (typeof persist === 'boolean') {
    persistent = persist;
  } else {
    persistent = getPersistence();
  }

  const valueToStore = JSON.stringify(value);

  if (!valueToStore) {
    return;
  }

  if (persistent) {
    localStorage.setItem(key, valueToStore);
  } else {
    sessionStorage.setItem(key, valueToStore);
  }
}

export function get<ValueType>(
  key: StorageKey,
  persist?: boolean,
): ValueType | null {
  let persistent;

  if (typeof persist === 'boolean') {
    persistent = persist;
  } else {
    persistent = getPersistence();
  }

  try {
    const value = persistent
      ? localStorage.getItem(key)
      : sessionStorage.getItem(key);

    return value ? JSON.parse(value) : null;
  } catch {
    return null;
  }
}

export function remove(key: StorageKey) {
  sessionStorage.removeItem(key);
  localStorage.removeItem(key);
}

export function isLocalStorageSupported() {
  try {
    set(storageKeys.test, 1, false);
    remove(storageKeys.test);

    return true;
  } catch (error: any) {
    return false;
  }
}
