import classNames from 'classnames';
import debounce from 'lodash/debounce';
import ChevronNext from 'wa-storybook/components/general/icon/components/chevron-next';
import ChevronBack from 'wa-storybook/components/general/icon/components/chevron-left';
import styles from './carousel-arrow.module.scss';

type Props = {
  isPrev?: boolean;
  onClick?: () => void;
  isStaticallyVisible: boolean;
};

const CarouselArrow = ({ isPrev, onClick, isStaticallyVisible }: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // needed otherwise react clears the event https://facebook.github.io/react/docs/events.html#event-pooling
    e.persist();
    debouncedClick();
  };

  const debouncedClick = debounce(
    function () {
      if (onClick) {
        onClick();
      }
    },
    700,
    {
      leading: true,
    },
  );

  const Chevron = isPrev ? ChevronBack : ChevronNext;

  if (context.viewport.isMobile || context.viewport.isTablet) {
    return null;
  }

  return (
    <button
      data-hook='arrowButton'
      onClick={handleClick}
      onMouseDown={e => e.preventDefault()}
      className={classNames(
        'shop-online-slick-arrow',
        `shop-online-slick-arrow-${isPrev ? 'prev' : 'next'}`,
        isPrev ? styles['prev-arrow'] : styles['next-arrow'],
        !isStaticallyVisible &&
          styles[`${isPrev ? 'prev-arrow' : 'next-arrow'}--non-static`],
      )}
      aria-label={
        isPrev
          ? polyglot.t('components.carousel.prev_arrow')
          : polyglot.t('components.carousel.next_arrow')
      }
    >
      <div className={styles['arrow__icon']}>
        <Chevron />
      </div>
    </button>
  );
};

CarouselArrow.displayName = 'CarouselArrow';
export default CarouselArrow;
