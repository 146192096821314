import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "offers" */ './offers-comp'),
    import(/* webpackChunkName: "offers" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('offers', reducer.offerReducer);
    return component;
  });
}

const offersModule = {
  colleagueOffers: {
    name: 'colleagueOffers',
    url: '/offers/colleague',
    resolver,
    restrictions: {
      user: [restrictions['benefit_colleague_offer']],
    },
    data: {
      title: 'components.main_menu.colleague_offers',
      mainMenu: 'colleagueOffers',
      section: 'deals',
      includeInMenu: true,
      order: 2,
      showSearch: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  colleagueOffersDetails: {
    name: 'colleagueOffersDetails',
    url: '/offers/colleague/:offer_id',
    resolver,
    restrictions: {
      user: [restrictions['benefit_colleague_offer']],
    },
    data: {
      title: 'components.main_menu.colleague_offers',
      mainMenu: 'colleagueOffers',
      section: 'deals',
      includeInMenu: false,
      showSearch: false,
      authorizedRoles: [USER_ROLES.employee],
      hideFooter: true,
    },
  },
  instoreOffers: {
    name: 'instoreOffers',
    url: '/offers/in-store',
    resolver,
    restrictions: {
      user: [restrictions['benefit_in_store_offer']],
    },
    data: {
      title: 'components.main_menu.instore_offers',
      mainMenu: 'instoreOffers',
      section: 'deals',
      includeInMenu: true,
      order: 1,
      showSearch: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  instoreOffersDetails: {
    name: 'instoreOffersDetails',
    url: '/offers/in-store/:offer_id',
    resolver,
    restrictions: {
      user: [restrictions['benefit_in_store_offer']],
    },
    data: {
      title: 'components.main_menu.instore_offers',
      mainMenu: 'instoreOffers',
      section: 'deals',
      includeInMenu: false,
      showSearch: false,
      authorizedRoles: [USER_ROLES.employee],
      hideFooter: true,
    },
  },
};

export default offersModule;
