import {
  Dispatch,
  ThunkedAction,
  ImageUpload,
  Paging,
  Group,
} from 'globals/types';

import { Model, FeaturedArticleAPIResponse, PostRecognition } from './types';
import { EVENT } from './reducer';
import { User } from 'state/session/types';
import { OffersResponse } from '../../offers/state/types';
import { showToast } from 'toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';
import { buildDropdownFilter, getGroupList } from './helpers';
import { POSSIBLE_FILTERS, initPostsLimit } from './constants';
import { getSubscribedGroups } from 'stores/user-store';
import { getGroup } from 'stores/groups-store';

import { isFeatureEnabled } from 'stores/features-list-store';
import {
  get as getFeedPosts,
  getPost,
  createAction,
  removeAction,
  post as postFeed,
  postImage as postImageFeed,
  postRecognition,
  search as searchFeed,
  registerImage,
} from 'stores/feed-store';
import { imageLessThan } from '../../../globals/helpers';

import fetch from 'globals/lambda-fetch';
import waFetch from 'globals/wa-fetch';
import {
  ContentBannerSections,
  getGlobalContentPreferenceHeaders,
} from 'globals/global-content';
import { fetchPerksHomePageInfo } from 'pages/perks-home-page/state/actions';
import { runOnWebWorker } from 'app/run-on-web-worker';

import { AnalyticsEvent } from 'services/google-analytics/analytics-events';
import { fetchHippoResource, fetchSkeleton } from 'stores/eap-store';
import { CONTENT_SECTION } from 'pages/employee-assistance/helpers/constants';
import { restrictions } from 'state/features-list/constants';

const maxDimensionImage = 8000000;

export function getPostsData(
  filter: string,
  paging: Paging,
  groupId: string,
  query?: string,
) {
  if (query) {
    AnalyticsEvent.Work.Feed.Feed.Search.searched(query);

    return searchFeed(query, paging);
  }

  return getFeedPosts(filter, paging, groupId);
}

function showErrorToast(err: any) {
  return function (dispatch: Dispatch) {
    dispatch(
      showToast({ type: ToastType.ERROR, message: (err.error || {}).message }),
    );
  };
}

export function initFeed(groupId?: string) {
  return function (dispatch: Dispatch) {
    const dropdownFilters = buildDropdownFilter(
      POSSIBLE_FILTERS,
      isFeatureEnabled,
    );

    const initData = {
      dropdownFilters,
      defaultFilter: dropdownFilters[0],
    } as const;

    if (groupId) {
      getGroup(groupId).then((group: Group) =>
        dispatch({ type: EVENT.UPDATE_ACTIVE_GROUP, group }),
      );
    }

    if (
      isFeatureEnabled('eap_assistance') &&
      isFeatureEnabled('feed_perk_post') &&
      isFeatureEnabled('benefit_online_shop') &&
      isFeatureEnabled('benefit_colleague_offer')
    ) {
      dispatch(fetchFeaturedPerksArticles());
    }

    if (
      isFeatureEnabled('feed_perk_post') &&
      isFeatureEnabled('benefit_in_store_offer')
    ) {
      dispatch(fetchOffer());
    }

    dispatch({ type: EVENT.INIT_FEED, initData });

    /* We are prefetching and caching the Perks and S&R home page data,
     * as to improve the user experience when navigating to Perks/S&R.
     * The serverless perks-api/ S&R-apis takes long time to resolve, the data
     * the data is not constantly updated,  GA stats show users
     * tend to visit Perks/S&R from the News Feed.
     */

    runOnWebWorker(() => {
      dispatch(fetchPerksHomePageInfo());
    });

    runOnWebWorker(() => {
      dispatch(
        fetchSupportResources(CONTENT_SECTION.eap, 'employeeAssistance'),
      );
    });
    runOnWebWorker(() => {
      dispatch(fetchSupportResources(CONTENT_SECTION.hwr, 'hwr'));
    });
  };
}

export function loadPosts(
  filter?: string | null,
  isInitial?: boolean,
  updateCache = false,
  params?: Record<string, string | undefined>,
): ThunkedAction {
  return function (
    dispatch: Dispatch,
    getState: () => {
      feed: Model;
    },
  ) {
    const { paging } = getState().feed;
    const paramFilter = params?.['filter'];
    const groupId = params?.['groupId'] ?? '';
    const query = params?.['query'];

    const newFilter = filter || paramFilter;

    // make a temporary paging for updating cache
    const dynamicPaging = {
      ...paging,
      limit: isInitial ? initPostsLimit : paging.limit,
      offsetRef: updateCache ? '' : paging.offsetRef,
    };

    return getPostsData(newFilter ?? '', dynamicPaging, groupId, query)
      .then((res: any) => {
        return dispatch({
          type: EVENT.UPDATE_FEED,
          data: res,
          filter: newFilter,
          updateCache,
        });
      })
      .catch((err: any) => dispatch(showErrorToast(err)));
  };
}

export const resetUpdateCache = (): ThunkedAction => {
  return function (dispatch: Dispatch) {
    dispatch({
      type: EVENT.RESET_UPDATE_CACHE,
    });
  };
};

export function postElement(
  message: string,
  image: ImageUpload,
  groupId: string,
  currentGroupId: string,
): ThunkedAction {
  return async function (dispatch: Dispatch) {
    if (!imageLessThan(maxDimensionImage, image)) {
      dispatch(
        showErrorToast({
          error: { message: polyglot.t('feed.posted.image_too_big') },
        }),
      );
      return Promise.reject(new Error('fail'));
    }
    try {
      await postFeed({ message, group_ids: [groupId] })
        .then((response: any) =>
          image
            ? postImageFeed(response.body['post_id'], image.file)
            : response,
        )
        .then((response: any) =>
          dispatch(
            updateFeed('Post', response.body, groupId !== currentGroupId),
          ),
        );
      return Promise.resolve();
    } catch (err: any) {
      dispatch(showErrorToast(err));
      return Promise.reject(err);
    }
  };
}

export function postNewRecognition(
  recognition: PostRecognition,
  image: ImageUpload | null,
): ThunkedAction {
  return function (dispatch: Dispatch) {
    if (!imageLessThan(maxDimensionImage, image)) {
      return Promise.reject(new Error('fail')).catch(() =>
        dispatch(
          showErrorToast({
            error: { message: polyglot.t('feed.posted.image_too_big') },
          }),
        ),
      );
    }
    return postRecognition(recognition)
      .then((response: any) =>
        image
          ? registerImage(response.body['recognition_id'], image.file)
          : response,
      )
      .then((response: any) =>
        dispatch(updateFeed('Recognition', response.body)),
      )
      .catch((err: any) => dispatch(showErrorToast(err)));
  };
}

export function updatePostDetail(
  post: Model['postDetails'],
  isFeed?: boolean,
): ThunkedAction {
  return function (dispatch: Dispatch) {
    const { post_id } = post;

    (!post.data['activity_triggered']
      ? createAction(post_id)
      : removeAction(post_id)
    ).then((res: any) => {
      return isFeed
        ? dispatch({ type: EVENT.UPDATE_POST, post: res.body })
        : dispatch({ type: EVENT.UPDATE_DETAILED_POST, post: res.body });
    });
  };
}

export function updateFeed(
  type: string,
  post: any,
  differentFeed?: boolean,
): ThunkedAction {
  return function (dispatch: Dispatch) {
    if (!differentFeed) {
      dispatch({ type: EVENT.ADD_NEW_POST, post });
    }

    if (type === 'Recognition') {
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          message: `${polyglot.t('feed.post.recognition_success')}`,
        }),
      );
    }
    if (type === 'Post') {
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          message: `${polyglot.t('feed.post.post_success')}`,
        }),
      );
    }
  };
}

export function getDetailedPost(postId: string): ThunkedAction {
  return function (dispatch: Dispatch) {
    return getPost(postId).then((res: any) =>
      dispatch({ type: EVENT.UPDATE_DETAILED_POST, post: res.body }),
    );
  };
}

export function updateSubscribedGroups(isSubscribed: boolean): ThunkedAction {
  return function (
    dispatch: Dispatch,
    getState: () => {
      feed: Model;
    },
  ) {
    const { group, subscribedGroups } = getState().feed;
    const groups = getGroupList(isSubscribed, subscribedGroups || [], group);

    dispatch({ type: EVENT.UPDATE_SUBSCRIBED_GROUPS, groups });
  };
}

export function initSubscribedGroups(user?: User | null): ThunkedAction {
  return function (dispatch: Dispatch) {
    if (isFeatureEnabled('grouping') && (user || {})['user_id']) {
      return getSubscribedGroups().then((res: any) =>
        dispatch({ type: EVENT.UPDATE_SUBSCRIBED_GROUPS, groups: res }),
      );
    }
  };
}

export function resetFeed(filter?: string | null): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: EVENT.RESET_FEED, filter });
  };
}

export function resetPostDetail(): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: EVENT.RESET_POST_DETAIL });
  };
}

export function reset(): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: EVENT.RESET });
  };
}

export function setPagePosition(pagePosition: number): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: EVENT.SET_PAGE_POSITION, pagePosition });
  };
}
export const getFeaturedPerksArticle =
  (): Promise<FeaturedArticleAPIResponse> =>
    fetch(
      `/cms-api-proxy/proxy/eap/api/eap/articles/perk`,
      {
        token: true,
        headers: {
          ...getGlobalContentPreferenceHeaders(ContentBannerSections.EAP),
        },
      },
      null,
      true,
    );

export const fetchFeaturedPerksArticles =
  (): ThunkedAction => async (dispatch: Dispatch) => {
    const success = (response: FeaturedArticleAPIResponse) => {
      dispatch({
        type: EVENT.FETCH_FEATURED_PERKS_ARTICLE_SUCCESS,
        payload: {
          fetching: false,
          resources: response.body,
        },
      });
    };

    const fail = (response: FeaturedArticleAPIResponse) => {
      dispatch({
        type: EVENT.FETCH_FEATURED_PERKS_ARTICLE_FAILURE,
        payload: {
          error: response.error,
          fetching: false,
        },
      });
    };

    dispatch({
      type: EVENT.FETCH_FEATURED_PERKS_ARTICLE,
    });

    try {
      const featuredPerksArticlesResponse = await getFeaturedPerksArticle();
      success(featuredPerksArticlesResponse);
    } catch (e: any) {
      fail(e);
    }
  };

// api call to retrieve top 10 offer
export const getOffer = (): Promise<OffersResponse> => {
  return waFetch('/instore-offers', {
    urlParameters: {
      workangel_only: false,
      limit: 1,
    },
    version: 1.3,
    token: true,
  });
};

// action creator to fetch offers
export const fetchOffer = (): ThunkedAction => async (dispatch: Dispatch) => {
  const success = (response: OffersResponse) => {
    const offers = response.body;
    const successAction = {
      type: EVENT.FETCH_INSTORE_OFFER_SUCCESS,
      payload: {
        fetching: false,
        instoreOffers: offers,
        totalCount: offers.length,
      },
    } as const;
    dispatch(successAction);
  };

  const fail = (response: OffersResponse) => {
    dispatch({
      type: EVENT.FETCH_INSTORE_OFFER_FAILURE,
      payload: {
        error: response.error,
        fetching: false,
      },
    });
  };

  // loading
  dispatch({
    type: EVENT.FETCH_INSTORE_OFFER,
    payload: {
      fetching: true,
    },
  });

  try {
    const offers = await getOffer();
    success(offers);
  } catch (e: any) {
    fail(e);
  }
};

export const fetchSupportResources = (
  hippoContentSection: CONTENT_SECTION,
  contentSection: string,
): ThunkedAction => {
  const getRestriction = () => {
    if (contentSection === 'employeeAssistance') {
      return restrictions['eap_assistance'];
    }
    if (contentSection === 'hwr') {
      return restrictions['eap_hwr_toolkits'];
    }

    return null;
  };

  const hasPermission = isFeatureEnabled(getRestriction());

  return dispatch => {
    if (!hasPermission) return;

    fetchSkeleton(hippoContentSection).then((val: unknown) => {
      dispatch({
        type: 'EMPLOYEE_ASSISTANCE/LANDING/FETCHED_RESOURCES',
        resources: val,
        contentSection,
      });
    });

    Promise.allSettled([
      fetchHippoResource('/site/home', {}, hippoContentSection),
      fetchHippoResource('/site/partners', {}, hippoContentSection),
    ]).then((responses: any) => {
      let resources, headers;

      responses.forEach(response => {
        const { status, value } = response;

        if (status === 'fulfilled' && value.body) {
          resources = { ...resources, ...value.body };
          headers = { ...headers, ...value.headers };
        }
      });

      if (resources) {
        dispatch({
          type: 'EMPLOYEE_ASSISTANCE/HOME/FETCHED_RESOURCES',
          resources,
          headers,
          contentSection,
        });
      }
    });
  };
};
