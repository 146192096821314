import Slider from 'react-slick';
import classNames from 'classnames';

import styles from './carousel.module.scss';
import CarouselArrow from './carousel-arrow';
import CarouselArrowPensionBenefits from './carousel-arrow-pension-benefits';
import Language from 'language';
import { isEqual, cloneDeep } from 'globals/helpers';

type Props = {
  arrowClassName?: string;
  arrows?: boolean;
  infinite?: boolean;
  slidesToShow: number;
  slidesToScroll?: number;
  afterChange?: () => void;
  type?: string;
  dots?: boolean;
  slickClassName?: string | null | undefined;
  isFeedFeaturedArticles?: boolean;
  children?: Array<React.ReactNode>;
};

type State = {
  slides: Props['children'];
};

const sliderScrollLeft = (sliderWrapper: HTMLDivElement | null) => {
  const slickList = sliderWrapper?.querySelector('.slick-list');

  if (slickList) {
    slickList.scrollLeft = 0;
  }
};

class Carousel extends React.Component<Props, State> {
  static displayName = 'Carousel';

  slider = Slider;
  sliderWrapper: {
    current: null | HTMLDivElement;
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      slides: [],
    };

    this.sliderWrapper = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    const slickList = this.sliderWrapper.current
      ? this.sliderWrapper.current.querySelector('.slick-list')
      : null;

    if (
      slickList &&
      !slickList.classList.contains(styles['carousel-item--left'])
    ) {
      slickList.classList.add(styles['carousel-item--left']);
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (!isEqual(props.children, state.slides)) {
      if (Language.isRTL()) {
        // If Language is RTL we need to reverse the slides to be in the right order.
        return {
          slides: cloneDeep(props.children).reverse(),
        };
      }

      return {
        slides: props.children || [],
      };
    }

    return null;
  }

  handleFocus = (index: number) => {
    if (this.slider) {
      this.slider.slickGoTo(index);
    }

    sliderScrollLeft(this.sliderWrapper?.current);
  };

  getArrow = (
    isPrev: boolean,
    arrowClassName?: string,
    isFeedFeaturedArticles?: boolean,
  ) => {
    if (this.props.type) {
      if (isPrev) {
        return (
          <CarouselArrowPensionBenefits
            isPrev={true}
            arrowClassName={arrowClassName}
          />
        );
      }

      return (
        <CarouselArrowPensionBenefits
          isPrev={false}
          arrowClassName={arrowClassName}
        />
      );
    }

    if (isPrev) {
      return (
        <CarouselArrow
          isPrev={true}
          arrowClassName={arrowClassName}
          isFeedFeaturedArticles={isFeedFeaturedArticles}
        />
      );
    }

    return <CarouselArrow isPrev={false} arrowClassName={arrowClassName} />;
  };

  getProps = (props: Props) => ({
    accessibility: false,
    adaptiveHeight: false,
    arrows: 'arrows' in props ? props.arrows : true,
    focusOnSelect: false,
    autoplay: false,
    className: props.slickClassName || styles['slick-slider'],
    dots: props.dots,
    dotsClass: 'slick-indicators',
    customPaging: () => <div className='slick-indicators__dot' />,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: props.slidesToShow || 1,
    lazyLoad: false,
    speed: 200,
    initialSlide: 0,
    afterChange: props.afterChange,
    nextArrow: this.getArrow(false, props.arrowClassName),
    prevArrow: this.getArrow(
      true,
      props.arrowClassName,
      props.isFeedFeaturedArticles,
    ),
    rtl: false,
  });

  render() {
    const { type } = this.props;
    const { slides } = this.state;
    const sliderSettings = this.getProps(this.props);

    if (Language.isRTL()) {
      sliderSettings.rtl = true;
    }

    return (
      <div
        className={classNames(
          'slick-slider-eap',
          type && `slick-slider-eap--${type}`,
        )}
        ref={this.sliderWrapper}
        data-hook='sliderWrapper'
      >
        <Slider
          {...sliderSettings}
          ref={(slider: Slider) => slider && (this.slider = slider)}
        >
          {slides &&
            slides.length &&
            slides.map((item: React.ReactNode, index: number) => (
              <div
                key={index}
                onFocus={() => this.handleFocus(index)}
                className={styles['card-container']}
              >
                {item}
              </div>
            ))}
        </Slider>
      </div>
    );
  }
}

export default Carousel;
