import { lazy, Suspense } from 'react';
import { connectWithViewport } from 'app/hermes-redux';
import type { AppState, Dispatch } from 'globals/types';
import Spinner from 'wa-storybook/components/general/spinner/spinner';
import type { Model as GiftCardsHubModel } from './hub/state/types';
import type { Model as GiftCardsDetailsModel } from './details/state/types';
import { useParams } from 'react-router-dom';
import { useRouterState } from 'router/router-state-provider';

const GiftCardsHubPage = lazy(() => import('./hub/view/hub-page'));
const GiftCardsDetailsPage = lazy(() => import('./details/view/details-page'));
const GiftCardsErrorPage = lazy(() => import('./shared/error-page'));

type Props = {
  dispatch: Dispatch;
  hub: GiftCardsHubModel;
  details: GiftCardsDetailsModel;
};

export const referrer = 'giftcards';

const GiftCards = (props: Props) => {
  const { categoryId } = useParams();
  const { currentRoute } = useRouterState();

  const renderGiftCards = (name: string) => {
    const giftCards = {
      giftcardsCodes: (
        <GiftCardsHubPage
          {...props}
          categoryId={'popular'}
          showCodes
          referrer={referrer}
        />
      ),
      giftcardsPopular: (
        <GiftCardsHubPage
          {...props}
          categoryId={categoryId}
          referrer={referrer}
        />
      ),
      giftcardsCategory: (
        <GiftCardsHubPage
          {...props}
          categoryId={categoryId}
          referrer={referrer}
        />
      ),
      giftcardsDetails: <GiftCardsDetailsPage {...props} referrer={referrer} />,
      giftcards: <GiftCardsHubPage {...props} referrer={referrer} />,
      giftcardsError: <GiftCardsErrorPage referrer={referrer} />,
    };

    return giftCards[name] || null;
  };

  return (
    <Suspense fallback={<Spinner top='10%' />}>
      {renderGiftCards(currentRoute?.name)}
    </Suspense>
  );
};

GiftCards.displayName = 'GiftCards';

export default connectWithViewport(GiftCards, (state: AppState) => {
  const {
    giftcards: { hub, details },
  } = state;

  return { hub, details };
});
