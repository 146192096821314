export const WITHDRAWAL_TYPES = {
  BANK_ACCOUNT: 0,
  PAYPAL: 1,
  EMPTY: 2,
} as const;

export const WITHDRAWAL_STEPS = {
  ENTER_DETAILS: 0,
  CONFIRM_PASSWORD: 1,
  SUCCESS: 2,
} as const;
