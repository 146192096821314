import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'globals/types';
import type { Model } from '../state/types';
import type { Store } from 'globals/perks.types';
import { openZendeskLinkWithToken } from 'app/state/global/actions';

import { goTo } from 'router/navigation';
import {
  POPULAR_CATEGORY,
  GIFT_CARD_HELP_LINK,
} from 'pages/gift-cards/shared/constants';

import CategoriesBanner from 'perks/components/categories-banner';

import PerksPage from 'perks/layouts/full-width';
import GiftCardsList from 'pages/gift-cards/shared/gift-cards-list';
import ModalPortalComp from 'wa-storybook/components/general/modal-portal-comp/modal-portal-comp';
import CodesModal from 'react-components/modal-templates/templates/codes-modal/codes-modal';

import PermanentToast from 'permanent-toast/permanent-toast';

import TopMenu from 'perks/components/top-menu';

import LeftMenu from 'perks/components/left-menu';

import NeedHelp from 'perks/components/need-help/need-help';
import CarouselComp from 'react-components/carousel-comp/carousel-comp';

import PerksSearchBar from 'react-components/perks-search-bar/perks-search-bar';
import perksSearchResultsModule from 'pages/perks-search-results/perks-search-results.module';

import styles from './hub-page.module.scss';
import classNames from 'classnames';

import {
  fetchCarousel as fetchGiftCardsCarousel,
  reset as resetGiftCards,
  resetPaging as resetGiftCardsPaging,
  fetchGiftCardsOffers,
  fetchGiftCardsCategories,
  fetchGiftCardsOrders,
} from 'pages/gift-cards/hub/state/actions';
import { useRouterState } from 'router/router-state-provider';
import { useParams } from 'react-router-dom';

type Props = {
  hub: Model;
  dispatch: Dispatch;
  showCodes?: boolean;
  categoryId?: string;
  referrer: string;
  resetGiftCardsPaging: () => void;
};

export const handleSelectedCategoryChange = (
  categoryId: string,
  props: Props,
  currentState?: string,
  categoryIdParam?: string,
) => {
  const { referrer, dispatch } = props;

  switch (categoryId) {
    case '0':
      if (
        currentState === 'giftcards' ||
        currentState === 'spotRewardsGiftcards'
      ) {
        return;
      }
      dispatch(resetGiftCards());
      return goTo(
        referrer === 'spotRewards' ? 'spotRewardsGiftcards' : 'giftcards',
      );
    case 'popular':
      if (
        currentState === `${referrer}Popular` ||
        currentState === `${referrer}Codes`
      ) {
        return;
      }
      dispatch(resetGiftCards());
      return goTo(`${referrer}Popular`);
    default:
      if (categoryIdParam === categoryId) return;
      dispatch(resetGiftCards());
      return goTo(`${referrer}Category`, { categoryId });
  }
};

export const GiftCardsHubPageUnconnected = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentRoute } = useRouterState();
  const params = useParams();

  useEffect(() => {
    const { referrer, showCodes } = props;

    props.dispatch(fetchGiftCardsOrders(referrer));

    if (showCodes) {
      openModal();
    }

    props.dispatch(fetchGiftCardsCarousel());

    return () => props.resetGiftCardsPaging();
  }, []);

  useEffect(() => {
    props.dispatch(fetchGiftCardsCategories(categoryId, referrer));
  }, [props.categoryId]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const categoryId = params['categoryId'];
  const currentState = currentRoute.name;

  const {
    hub: {
      paging,
      fetchingOffers,
      fetchingCategories,
      fetchingOrders,
      ordersPaging,
      offers,
      categories,
      selectedCategory,
      orders,
    },
    referrer,
    dispatch,
  } = props;
  const isOrderedByPopularity =
    currentState === `${referrer}Popular` ||
    currentState === `${referrer}Codes`;
  const isRewards = referrer === 'spotRewards';
  const topCategories = [
    {
      action: () =>
        handleSelectedCategoryChange('0', props, currentState, categoryId),
      active: !isOrderedByPopularity,
      name: polyglot.t('constants.categories.all'),
    },
    {
      action: () =>
        handleSelectedCategoryChange(
          POPULAR_CATEGORY,
          props,
          currentState,
          categoryId,
        ),
      active: isOrderedByPopularity,
      name: polyglot.t('constants.categories.popular'),
    },
  ];
  const activeCategory = params && categoryId;
  const isMobileOrTablet =
    !context.viewport.isDesktop && !context.viewport.isDesktopXl;

  const isSpotRewardsRoute = [
    'spotRewardsGiftcards',
    'spotRewardsPopular',
    'spotRewardsCategory',
  ].includes(currentState);

  const displayPerksSearchBar =
    [
      'giftcards',
      'giftcardsCodes',
      'giftcardsPopular',
      'giftcardsCategory',
    ].includes(currentState) || isSpotRewardsRoute;

  const handleSearch = (searchTerm: string) => {
    goTo(
      `${
        isSpotRewardsRoute
          ? perksSearchResultsModule.spotRewardsSearch.url
          : perksSearchResultsModule.giftCardsSearch.url
      }?query=${encodeURIComponent(searchTerm)}`,
    );
  };

  const giftcardsSlides = props.hub.carousel?.slides.filter(
    slide =>
      slide.perks_feature === 'benefit_global_gift_cards' ||
      slide.perks_feature === 'any',
  );

  return (
    <div>
      {orders && !!orders.length && !isRewards && (
        <PermanentToast onClick={openModal}>
          <a className={styles['view-codes-link']}>{`${polyglot.t(
            'giftcards.view',
          )} ${polyglot.t('giftcards.giftcard_codes')}`}</a>
        </PermanentToast>
      )}

      {displayPerksSearchBar && (
        <PerksSearchBar
          containerClassName='container--no-padding-bottom'
          onSubmit={handleSearch}
        />
      )}

      {!isRewards && !!giftcardsSlides?.length && (
        <CarouselComp slides={giftcardsSlides} />
      )}
      <nav className={styles['top-container']}>
        <PerksPage className={styles['perks-page']}>
          <CategoriesBanner
            title={
              isRewards
                ? polyglot.t('components.main_menu.rewards')
                : polyglot.t('components.main_menu.gift_cards')
            }
            categories={topCategories}
          />
        </PerksPage>
      </nav>
      <PerksPage>
        <Fragment>
          {fetchingCategories ||
          (categories &&
            (categories.length === 0 ? null : isMobileOrTablet)) ? (
            <TopMenu
              idField='id'
              placeholder={polyglot.t('giftcards.select_category')}
              categories={categories}
              selectedItem={
                categories
                  ? categories.reduce(
                      (prev?: Store | null, item?: Store | null) =>
                        item && item['id'] === activeCategory ? item : prev,
                      null,
                    )
                  : undefined
              }
              onSelectChange={category =>
                handleSelectedCategoryChange(category, props)
              }
            />
          ) : (
            <LeftMenu
              categories={categories}
              idField='id'
              active={activeCategory}
              fetching={fetchingCategories}
              onListItemClick={category =>
                handleSelectedCategoryChange(category, props)
              }
              onClickHome={() => handleSelectedCategoryChange('0', props)}
              title={polyglot.t('giftcards.categories')}
            >
              <NeedHelp
                isOnHub={false}
                action={() =>
                  props.dispatch(openZendeskLinkWithToken(GIFT_CARD_HELP_LINK))
                }
                label={polyglot.t('giftcards.ask_question')}
                title={`${polyglot.t('giftcards.need_help')} ${polyglot.t(
                  'giftcards.cant_find',
                )}`}
              />
            </LeftMenu>
          )}
        </Fragment>
        <GiftCardsList
          fetching={fetchingOffers}
          giftCards={offers}
          paging={paging}
          loadNext={(limit: number, offset: number) =>
            props.dispatch(
              fetchGiftCardsOffers(
                limit,
                offset,
                isOrderedByPopularity
                  ? POPULAR_CATEGORY
                  : (categoryId as string),
                referrer,
              ),
            )
          }
          categoryId={
            isOrderedByPopularity ? POPULAR_CATEGORY : (categoryId as string)
          }
          categoryTitle={
            props.categoryId && fetchingCategories
              ? ''
              : selectedCategory
                ? selectedCategory.name
                : isOrderedByPopularity
                  ? polyglot.t('constants.categories.popular')
                  : polyglot.t('giftcards.home')
          }
          referrer={referrer}
        />
        {isMobileOrTablet && (
          <NeedHelp
            dataHook={'top-menu-need-help'}
            isOnHub
            className={classNames(styles['need-help'], 'need-help--hub-page')}
            action={() =>
              props.dispatch(openZendeskLinkWithToken(GIFT_CARD_HELP_LINK))
            }
            label={polyglot.t('giftcards.ask_question')}
            title={polyglot.t('giftcards.need_help')}
          />
        )}
      </PerksPage>
      {isModalOpen && (
        <ModalPortalComp
          isOpen={isModalOpen}
          showCloseButton={true}
          onRequestClose={closeModal}
          shouldCloseOnEsc={true}
          shouldCloseOnOuterClick={true}
        >
          <CodesModal
            closeModal={closeModal}
            dispatch={dispatch}
            fetchingOrders={fetchingOrders}
            gcOrder={orders}
            ordersPaging={ordersPaging}
            referrer={referrer}
          />
        </ModalPortalComp>
      )}
    </div>
  );
};

GiftCardsHubPageUnconnected.displayName = 'GiftCardsHubPage';

export default connect(null, {
  resetGiftCardsPaging,
})(GiftCardsHubPageUnconnected);
