/**
 *  @module Spot Rewards No Transactions
 *  @overview Displays content for when there are no Spot Reward transactions
 */

import ImageSubtitle from 'wa-storybook/components/well-being/hero-card/image-subtitle';

import styles from './no-transactions.module.scss';

const SpotRewardsNoTransactions = () => {
  return (
    <div className={styles.container}>
      <ImageSubtitle
        src={require('./../../assets/illu-rewards-coachmark-1.svg')}
        subtitle={polyglot.t('rewards.available_reward.label_2')}
      />
      <ImageSubtitle
        src={require('./../../assets/illu-rewards-coachmark-2.svg')}
        subtitle={polyglot.t('rewards.available_reward.label_3')}
      />
      <ImageSubtitle
        src={require('./../../assets/illu-rewards-coachmark-3.svg')}
        subtitle={polyglot.t('rewards.available_reward.label_4')}
      />
    </div>
  );
};

SpotRewardsNoTransactions.displayName = 'SpotRewardsNoTransactions';

export default SpotRewardsNoTransactions;
