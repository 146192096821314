import {
  PENSION_FEATURE_VALUE,
  BENEFIT_FEATURE_VALUE,
  BOTH_FEATURE_VALUE,
} from './constants';
import { getFeature } from 'app/stores/features-list-store';
import { client } from 'graphql/client';
import {
  PensionBenefitsTitleAndNotificationCountQuery,
  PensionAndBenefitsPortalUrlQuery,
  PensionAndBenefitsPortalUrlDocument,
  PensionBenefitsTitleAndNotificationCountDocument,
} from 'graphql-types';

import { getStore } from 'app/hermes-redux';
import { delay } from 'globals/helpers';
import { showToast } from 'toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';

export const getFeatureValue = () => getFeature('pension_and_benefits')?.value;

export const isPensionEnabled = () => {
  const featureValue = getFeatureValue();

  return (
    featureValue === PENSION_FEATURE_VALUE ||
    featureValue === BOTH_FEATURE_VALUE
  );
};

export const isBenefitsEnabled = () => {
  const featureValue = getFeatureValue();

  return (
    featureValue === BENEFIT_FEATURE_VALUE ||
    featureValue === BOTH_FEATURE_VALUE
  );
};

export const redirectToMsPortal = async (portalUrlId: string) => {
  const featureValue = getFeatureValue();

  let message = 'message';

  if (featureValue === PENSION_FEATURE_VALUE) {
    message = 'savings';
  } else if (featureValue === BENEFIT_FEATURE_VALUE) {
    message = 'benefits';
  }

  // We send polyglot key of the message to redirecting page.
  const redirection = window.open(
    `${window.location.protocol}//${window.location.host}/redirecting?message=pension_and_benefits.redirecting.${message}`,
    '_blank',
  );

  try {
    const result = await Promise.all([
      client.query<PensionAndBenefitsPortalUrlQuery>({
        query: PensionAndBenefitsPortalUrlDocument,
        variables: { portalUrlId: portalUrlId },
        fetchPolicy: 'network-only',
      }),
      delay(2000),
    ]);

    if (redirection) {
      redirection.location =
        result[0]?.data?.pensionAndBenefitsPortalUrl?.url || '';
    }
  } catch {
    if (redirection) {
      redirection.close();
    }

    getStore().dispatch(
      showToast({
        type: ToastType.ERROR,
        message: polyglot.t('global.error'),
      }),
    );
  }
};

export const checkPensionBenefitDataAvailability = (
  featureValue: number,
  data?: PensionBenefitsTitleAndNotificationCountQuery | null,
) => {
  const hasSummaryData = !!data?.pensionAndBenefitsSummary;
  const isPensionAvailable =
    (featureValue === PENSION_FEATURE_VALUE ||
      featureValue === BOTH_FEATURE_VALUE) &&
    !!data?.pensionAndBenefitsSummary?.pension;
  const isBenefitsAvailable =
    (featureValue === BENEFIT_FEATURE_VALUE ||
      featureValue === BOTH_FEATURE_VALUE) &&
    !!data?.pensionAndBenefitsSummary?.benefits;

  return hasSummaryData && (isPensionAvailable || isBenefitsAvailable);
};

export const hasRightForPensionBenefits = async () => {
  try {
    const { data } =
      await client.query<PensionBenefitsTitleAndNotificationCountQuery>({
        query: PensionBenefitsTitleAndNotificationCountDocument,
        fetchPolicy: 'network-only',
      });

    const featureValue = getFeatureValue();

    return checkPensionBenefitDataAvailability(Number(featureValue), data);
  } catch {
    return false;
  }
};
