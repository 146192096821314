import classNames from 'classnames';
import styles from './full-width.module.scss';

type Props = {
  children?: any;
  style?: any;
  className?: string;
  classString?: string;
  ariaLabel?: string;
};

const FullWidthPage = (props: Props) => {
  return (
    <section
      aria-label={props.ariaLabel}
      className={classNames(
        styles.page,
        props.className,
        props.classString && styles[props.classString],
      )}
      style={props.style}
    >
      {props.children}
    </section>
  );
};

FullWidthPage.displayName = 'FullWidthPage';
export default FullWidthPage;
