import { client } from 'graphql/client';

import isEmpty from 'lodash/isEmpty';
import type {
  User,
  UserAction,
  UserResponse,
  UserMFAResponse,
  Interest,
  AuthMobileResponse,
  TokenExpiryTime,
  TokenExpiryTimeParams,
} from './types';
import type { Dispatch, ThunkedAction, AppState, Action } from 'globals/types';
import { showToast, resetToast } from 'toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';
import fetch from 'globals/wa-fetch';
import getErrorMessage from 'constants/error-messages';
import {
  getSubdomainFromHost,
  goTo,
  getUrlParameter,
  getCurrentState,
} from 'router/navigation';
import {
  setCompany,
  fetchCompany,
  setPreLoginCompanyDetails,
} from 'stores/company-store';
import { getUser, isSharedAccount } from 'stores/user-store';
import { startPostLoginFlow } from 'state/post-login/actions';
import {
  handleLoginRedirect,
  setSubDomainCookie,
  isSSO,
  getSSOLogoutLink,
  clearLogoutStorage,
  getToken,
} from './helpers';
import { get as storageGet } from 'globals/storage';
import { updateFeaturesList } from 'state/features-list/actions';
import Language from 'language';
import { updateMenu } from 'state/menu/actions';
import { initialiseCompany } from 'state/global/actions';
import extend from 'lodash/extend';
import { signupStates } from 'router/constants';
import { getStore } from 'app/hermes-redux';
import { sessionInvalidationTypes } from 'wa-storybook/components/modal/session-invalidation-alert-modal/constants';
import type { SessionInvalidationTypes } from 'wa-storybook/components/modal/session-invalidation-alert-modal/types';
import { SIGNUP_CODES } from '../../pages/signup/constants';
import { setWonderingLocale } from 'globals/wondering/helpers';
import { setExperimentAttributes } from 'globals/experiments/experiments';
import {
  getStoredSelectedEnvironment,
  removeStoredSelectedEnvironment,
} from 'react-components/env-controller/env-controller-context';
import { isUnifiedLoginEnabled } from 'globals/testable';
import {
  AppLogger,
  AuthClient,
  AuthConstants,
} from '@workivate/tho-web-shared';
import { runOnWebWorker } from 'app/run-on-web-worker';

export const setUser = (user: User): UserAction => {
  setWonderingLocale(user.locale);
  return { type: 'SESSION/SET_USER', newUser: user };
};

export const resetUser = (): UserAction => {
  return { type: 'SESSION/RESET_USER' };
};

export function updateUserSuccess(user: User): UserAction {
  setWonderingLocale(user.locale);
  return { type: 'SESSION/UPDATE_USER_SUCCESS', newUser: user };
}

export function updateUser(
  newUser: any,
  signup?: boolean | null,
  shouldShowSuccessToast = true,
): ThunkedAction {
  return function (dispatch: Dispatch): Promise<any> {
    dispatch({ type: 'SESSION/UPDATE_USER_REQUEST' });
    const req = {
      body: undefined,
      method: isSharedAccount() ? 'get' : 'put', // never update shared account !
      version: 1.3,
      [signup ? 'signupToken' : 'token']: true,
    };

    // internet explorer edge doesnt allow empty body on get request
    if (!isSharedAccount()) {
      // never update shared account !
      req.body = newUser;
    }

    return fetch('/user/me?exclude_field=badges', req).then(
      (response: UserResponse & UserMFAResponse) => {
        if (response.body.signup_state === SIGNUP_CODES.MFA_SETUP_REQUIRED) {
          return response;
        }

        if (shouldShowSuccessToast && !signup) {
          dispatch(
            showToast({
              type: ToastType.SUCCESS,
              message: polyglot.t('profile_edit_page.controller.toaster'),
            }),
          );
        }
        return dispatch(updateUserSuccess(response.body));
      },
      (response: UserResponse) => {
        dispatch(
          showToast({
            type: ToastType.ERROR,
            message: window.polyglot.t('sign_up.error'),
          }),
        );
        dispatch({ type: 'SESSION/UPDATE_USER_ERROR', response });

        return Promise.reject(response);
      },
    );
  };
}

export function changeInterest(interest: Interest): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'SESSION/CHANGE_INTEREST', interest: interest });
  };
}

const updateLanguagePending = (): Action => ({
  type: 'SESSION/LOADING_STARTED',
});

const updateLanguageCompleted = (): Action => ({
  type: 'SESSION/LOADING_COMPLETED',
});

export const updateLanguage =
  (newLocale: string, isUserLoggedIn = true) =>
  async (dispatch: Dispatch) => {
    dispatch(updateLanguagePending());
    await client.clearStore();
    try {
      await Language.set({
        locale: newLocale,
        shouldLoadAllTranslations: true,
        resetGlobalContent: true,
      });

      if (isUserLoggedIn) {
        // re-rendering menu (AFTER Language.set) to update
        // 1. Pension and Benefits
        // 2. "Language: .... (..)"
        // profile menu items to the new language
        dispatch(updateMenu(getUser().features_list));
      } else if (getCurrentState().name !== signupStates.signup) {
        await setPreLoginCompanyDetails(newLocale);
      }

      dispatch(
        showToast({
          message: polyglot.t('settings.translations.success'),
          type: ToastType.SUCCESS,
        }),
      );
    } catch {
      dispatch(
        showToast({
          message: polyglot.t('settings.translations.error'),
          type: ToastType.ERROR,
        }),
      );
    } finally {
      dispatch(updateLanguageCompleted());
    }
  };

export const resetInterestList = (): ThunkedAction => {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'SESSION/RESET_INTERESTS' });
  };
};

export function saveInterests(selectedInterests: Array<string>): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch(updateUser({ interest_list: selectedInterests }))
      .then(() => {
        return dispatch(
          showToast({
            type: ToastType.SUCCESS,
            message: polyglot.t('profile.interests.saved'),
          }),
        );
      })
      .catch((res: any) =>
        dispatch(
          showToast({
            type: ToastType.ERROR,
            message: getErrorMessage(res.error.code),
          }),
        ),
      );
  };
}

export const login = (response: AuthMobileResponse) => {
  return async (dispatch: Dispatch) => {
    const langQueryString = getUrlParameter('lang');

    if (response === null) {
      return Promise.reject({ error: 'lifeworks-portal' });
    }

    const { user, company } = response;
    const subdomain = getSubdomainFromHost();
    const companySubdomain = company['wa_subdomain'];

    // always set a subdomain cookie, in case in future
    // they visit on a different subdomain
    setSubDomainCookie(companySubdomain);

    // Redirection
    if (!companySubdomain || subdomain !== companySubdomain) {
      setCompany(company);
      handleLoginRedirect(companySubdomain);
      return Promise.reject({ error: 'redirecting' });
    }

    if (
      Language.getLoginLanguagePersistence() &&
      Language.getLocale() !== user.locale
    ) {
      if (!user.is_shared_account) {
        dispatch(updateUser({ locale: Language.getLocale() }, false, false));
      }

      Language.loadTranslations(Language.getLocale());
    }

    dispatch(setUser(user));

    if (user.features_list.length !== 0) {
      getStore().dispatch(updateFeaturesList(user.features_list));
    }

    setCompany(company);

    if (!Language.getLoginLanguagePersistence()) {
      // we need to set the locale before continuing from here
      await Language.set({
        locale: langQueryString || response.user.locale,
        isSharedAccount: response.user.is_shared_account,
        shouldLoadAllTranslations: true,
      });
    }

    runOnWebWorker(async () => {
      // we need a new company with the correct locale assets
      // because before logging in we don't have the user's locale
      const { body: newCompany } = await fetchCompany(
        (response.company || {})['company_id'],
      );

      setCompany(newCompany, true);
    });

    runOnWebWorker(async () => {
      // we need to set up the language first then re fetch the user with correct lw-language header
      const options = {
        token: true,
        version: 1.3,
      } as const;

      const { body: newUser } = await fetch(
        '/user/me?exclude_field=badges',
        options,
      );

      dispatch(setUser(extend({}, newUser)));
    });

    runOnWebWorker(startPostLoginFlow);
  };
};

export const setIsLoggingOut = (isLoggingOut: boolean) => ({
  type: 'SESSION/IS_LOGGING_OUT',
  payload: isLoggingOut,
});

export const logout = (
  isHard: boolean,
  sessionInvalidationType?: SessionInvalidationTypes | null,
  stayOnTheSamePage = false,
): ThunkedAction => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<any> => {
    const setEnvironment = getStoredSelectedEnvironment();

    if (
      isUnifiedLoginEnabled() &&
      AuthConstants.AUTH_METHODS.oauth2 === 'oauth2'
    ) {
      getStore().dispatch(resetToast());
      getStore().dispatch(initialiseCompany(false));

      dispatch({ type: 'SESSION/LOGOUT_USER' });
      setExperimentAttributes({
        id: undefined,
        companyId: undefined,
        companyName: undefined,
      });

      if (setEnvironment) {
        removeStoredSelectedEnvironment();
      }
      AuthClient.signOut();
      // Some BE work is required to call the Oauth2 /logout endpoint instead
      // await Auth.signOut();
    }
    if (!isEmpty(getToken())) {
      try {
        const logoutRedirectUrl = getState().companySettings.logoutUrl;
        await client.clearStore();

        if (!sessionInvalidationType && isHard) {
          await fetch('/auth/mobile', {
            method: 'delete',
            token: true,
            version: 1.3,
          });
        }

        if (isSSO()) {
          const logoutUrl = getSSOLogoutLink(Language.getLocale());

          if (logoutUrl && !stayOnTheSamePage) {
            return (window.location.href = logoutUrl);
          }
        } else if (logoutRedirectUrl && !stayOnTheSamePage) {
          return (window.location.href = logoutRedirectUrl);
        }
      } catch (err: any) {
        AppLogger.error(err);
      } finally {
        const tokenExpiryTime: TokenExpiryTime | null =
          storageGet('tokenExpiryTime');

        clearLogoutStorage();
        // to clean up the state

        let queryParams: Partial<TokenExpiryTimeParams> = {};

        if (
          tokenExpiryTime &&
          sessionInvalidationType === sessionInvalidationTypes.sessionTimeout
        ) {
          queryParams = {
            timeoutAmount: tokenExpiryTime.amount,
            timeoutUnit: tokenExpiryTime.unit,
          };
        } else if (
          sessionInvalidationType ===
          sessionInvalidationTypes.authMethodOrMFAChange
        ) {
          queryParams = {
            isGenericInvalidation: 'true',
          };
        }

        if (!stayOnTheSamePage) {
          goTo('/', queryParams, { stateParams: { isLoggingOut: true } });
        }

        getStore().dispatch(resetToast());
        getStore().dispatch(initialiseCompany(false));

        dispatch({ type: 'SESSION/LOGOUT_USER' });
        setExperimentAttributes({
          id: undefined,
          companyId: undefined,
          companyName: undefined,
        });

        if (setEnvironment) {
          removeStoredSelectedEnvironment();
        }
      }
    }
  };
};
