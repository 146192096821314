import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "spotRewards" */ './view/spot-rewards-comp'),
    import(/* webpackChunkName: "spotRewards" */ './state/reducer'),
    import(
      /* webpackChunkName: "spotRewards" */ '../gift-cards/gift-cards.reducers'
    ),
  ]).then(([component, spotRewardsReducer, giftCardsReducers]) => {
    injectAsyncReducer('giftcards', giftCardsReducers.default);
    injectAsyncReducer('spotRewards', spotRewardsReducer.default);
    return component;
  });
}

const FEATURE_KEY = 'benefit_global_gift_cards';

const spotRewardsModule = {
  main: {
    name: 'spotRewards',
    url: '/spot-rewards',
    resolver,
    restrictions: {
      user: [restrictions['enterprise_spot_rewards']],
    },
    data: {
      title: 'components.main_menu.rewards',
      section: 'myAccount',
      includeInMenu: true,
      mainMenu: 'spotRewards',
      infoIcon: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  hub: {
    name: 'spotRewardsGiftcards',
    url: '/spot-rewards/gift-cards',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.rewards',
      mainMenu: 'spotRewards',
      section: 'myAccount',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  codes: {
    name: 'spotRewardsCodes',
    url: '/spot-rewards/codes',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.rewards',
      mainMenu: 'spotRewards',
      section: 'myAccount',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  popular: {
    name: 'spotRewardsPopular',
    url: '/spot-rewards/gift-cards/popular',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.rewards',
      mainMenu: 'spotRewards',
      section: 'myAccount',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  category: {
    name: 'spotRewardsCategory',
    url: '/spot-rewards/gift-cards/category/:categoryId',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.rewards',
      mainMenu: 'spotRewards',
      section: 'myAccount',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  details: {
    name: 'spotRewardsDetails',
    url: '/spot-rewards/gift-cards/:id',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.rewards',
      mainMenu: 'spotRewards',
      section: 'myAccount',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  error: {
    name: 'spotRewardsError',
    url: '/spot-rewards/gift-cards/error',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.rewards',
      mainMenu: 'spotRewards',
      includeInMenu: false,
      section: 'myAccount',
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default spotRewardsModule;
