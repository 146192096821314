import fetch from 'globals/wa-fetch';
import type { NewPassword, PaymentResponse, WalletResponse } from './types';

export const getWithdrawalDetails = (): Promise<WalletResponse> =>
  fetch('/user/wallet', {
    method: 'get',
    version: 1.3,
    token: true,
  });

export const getPaymentDetails = (userId: string): Promise<PaymentResponse> =>
  fetch(`/users/${userId}/cards`, {
    method: 'get',
    version: 1.3,
    token: true,
  });

export const removePaymentDetails = (userId: string): Promise<void> =>
  fetch(`/user/${userId}/credit-card-details`, {
    method: 'delete',
    version: 1.5,
    token: true,
  });

export const removeWithdrawalDetails = (method: string): Promise<void> =>
  fetch(`/user/wallet/remove-payment-info?type=${method}`, {
    method: 'delete',
    version: 1.5,
    token: true,
  });

export const changePasword = (params: NewPassword) => {
  return fetch('/users/change-password', {
    body: params,
    method: 'post',
    token: true,
    version: 1.4,
  });
};

export const getPasswordRequirements = (companyId: string) => {
  return fetch(`/password-policy/${companyId}`, {
    method: 'get',
    token: true,
    version: 1.3,
  });
};
