import ChevronDown from 'wa-storybook/components/general/icon/components/chevron-down';
import styles from './select-comp.module.scss';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import SelectDropdown from './select-dropdown';
import type { Store } from 'globals/perks.types';
import type { SelectCompValue } from 'globals/types';
import type { CategoryObject } from 'pages/shop-online/state/types';

type Props = {
  activeCategory?: string;
  errorMessage?: string;
  onItemSelected: (item: SelectCompValue) => void;
  id?: string;
  isDisabled?: boolean;
  labelText?: string;
  options: SelectCompValue[] | [];
  placeholder: string;
  selectClassName: string;
  selectedItem: SelectCompValue | Store | CategoryObject | null;
  selectedClassName?: string;
  showError?: boolean;
  ariaLabel: string;
};

type State = {
  isDropdownVisible: boolean;
  keyPressed: number | null;
  currentSelectedItem: SelectCompValue | null;
};

class SelectComp extends React.Component<Props, State> {
  static displayName = 'SelectComp';

  input: {
    current: HTMLDivElement | null;
  };

  state: State = {
    isDropdownVisible: false,
    keyPressed: 0,
    currentSelectedItem: null,
  };

  constructor(props: Props) {
    super(props);
    this.input = React.createRef<HTMLDivElement | null>();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isDropdownVisible } = this.state;

    if (prevState.isDropdownVisible !== isDropdownVisible) {
      this.handleListener(isDropdownVisible);
    }
  }

  onClick = () => {
    if (this.props.isDisabled) {
      return null;
    }

    this.setState({
      isDropdownVisible: !this.state.isDropdownVisible,
    });
  };

  onKeyDown = e => {
    if (e.key === ' ') {
      e.preventDefault();
      this.onClick();
    }
  };

  onBlur = () => {
    // when the user activates the scrollbar, the element is blurred but we still
    // wish for it to be active, so check in the user is hovering

    if (!this.input?.current?.parentElement?.querySelector(':hover')) {
      this.setState({ isDropdownVisible: false });
    } else {
      // if the user drags the scroll bar, it will blur the element, which will mean
      // on blur won't fire when the user genuinely wants to leave the dropdown,
      // so re-focus the dropdown in this instance

      if (this.input.current) this.input.current.focus();
    }
  };

  handleListener = (isActive: boolean) => {
    if (isActive) {
      window.addEventListener('keydown', this.handleKeydown);
    } else {
      window.removeEventListener('keydown', this.handleKeydown);
    }
  };

  handleKeydown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Enter') {
      e.preventDefault();
      this.setState({ keyPressed: e.keyCode });
    } else if (e.key === 'Escape') {
      if (this.input.current) this.input.current.blur();

      this.setState({ isDropdownVisible: false, keyPressed: null });
    }
  };

  selectItem = (item: SelectCompValue) => {
    if (this.input.current) this.input.current.blur();

    if (this.props.activeCategory !== item.value) {
      this.props.onItemSelected(item);
      this.setState({
        isDropdownVisible: false,
        keyPressed: null,
        currentSelectedItem: item,
      });
    }
  };

  render() {
    const {
      activeCategory,
      errorMessage,
      id,
      labelText,
      options,
      placeholder,
      selectedItem,
      selectedClassName,
      selectClassName,
      showError,
    } = this.props;
    const { currentSelectedItem, isDropdownVisible, keyPressed } = this.state;

    const selectedClass = classNames(
      styles['selected-item'],
      isDropdownVisible ? styles['selected-item--focused'] : '',
      showError ? styles['selected-item--error'] : '',
      selectedClassName ? styles[selectedClassName] : '',
    );

    const arrowClassName = classNames(
      styles.arrow,
      isDropdownVisible && styles['arrow--selected'],
    );
    const a11yProps = {
      tabIndex: 0,
      role: 'button',
      'aria-expanded': isDropdownVisible,
      'aria-label': this.props.ariaLabel,
      'aria-haspopup': true,
    };

    return (
      <div
        className={classNames(
          activeCategory === 'onlineshop_favourites' &&
            styles['select-comp--disabled'],
          styles.container,
          styles[`container--${selectClassName}`],
        )}
      >
        {!!labelText && (
          <label htmlFor={id} className={styles.label}>
            {labelText}
          </label>
        )}
        <div
          {...a11yProps}
          id={id}
          ref={this.input}
          className={selectedClass}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
        >
          <div className={styles['selected-item-text']}>
            {!isEmpty(selectedItem) ? (
              selectedItem.name
            ) : !isEmpty(currentSelectedItem) ? (
              currentSelectedItem.name
            ) : (
              <span className={styles.placeholder}>{placeholder}</span>
            )}
            <div className={arrowClassName}>
              <ChevronDown />
            </div>
          </div>
          <SelectDropdown
            keyPressed={keyPressed}
            id={id}
            onItemClick={this.selectItem}
            items={options || []}
            selectedItem={selectedItem}
            showList={isDropdownVisible}
          />
        </div>
        {showError && !isDropdownVisible && (
          <div className={styles['error-message']}>{errorMessage}</div>
        )}
      </div>
    );
  }
}

export default SelectComp;
