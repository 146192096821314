import fetch from 'globals/wa-fetch';
import type { Dispatch } from 'globals/types';
import type { Company } from '../company/types';

export function fetchCompanySettings(company: Company): Promise<any> {
  const id = company.company_id || company.id;

  if (!id) {
    const errorResponse = {
      error: 'company ID is "undefined"',
    } as const;

    return Promise.reject(errorResponse);
  }

  return fetch(`/companies/${id}/settings`, {
    token: true,
    version: 1.4,
  });
}

export function getCompanySettings(company: Company) {
  return function (dispatch: Dispatch): Promise<any> {
    dispatch({ type: 'COMPANY_SETTINGS/GET_SETTINGS' });

    return fetchCompanySettings(company).then(({ body }: any) => {
      return dispatch({
        type: 'COMPANY_SETTINGS/GET_SETTINGS_SUCESS',
        settings: {
          grouping: body.grouping,
          logoutUrl: body['logout_url'],
          globalPhoneNumbers: body['global_phone_numbers'],
        },
      });
    });
  };
}
