import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(
      /* webpackChunkName: "forgotten-password" */ './view/forgotten-password-comp'
    ),
    import(/* webpackChunkName: "forgotten-password" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('forgottenPassword', reducer.forgottenPasswordReducer);
    return component;
  });
}

const forgottenPasswordModule = {
  name: 'forgottenPassword',
  url: '/forgotten-password',
  resolver,
  data: {
    title: 'forgotten_password.title_telus',
    authorizedRoles: [USER_ROLES.all],
  },
};

export default forgottenPasswordModule;
