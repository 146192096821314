import { getRegionalApiEndpoints } from './regional-api';
import Language from 'language';
import { serialize, handle401 } from './helpers';
import * as Session from 'state/session/helpers';
import { DisasterRecoveryService } from 'globals/context/disaster-recovery-context';
import { isUnifiedLoginEnabled } from 'globals/testable';
import { getAuthenticationHeader } from 'globals/fetch.helpers';
import { DatadogClient } from '@workivate/tho-web-shared';

export default async function (
  url,
  params,
  string?, // UI-3277 TODO: Remove unused parameter
  isCustomUrl?,
  isLambdaUrl?,
  shouldSendAuthHeader = true, // Sending the auth header when not needed can cause CORS issues
): Promise<any> {
  let requestUrl = url;

  if (!isCustomUrl) {
    const { waapi_root: apiRoot, wellbeing_root: lambdaRoot } =
      getRegionalApiEndpoints();

    requestUrl = isLambdaUrl
      ? lambdaRoot + url // lambda api
      : apiRoot + url; // default api
  }

  const { urlParameters } = params;

  params.headers = {
    ...params.headers,
    Accept: {
      1.3: 'application/vnd.wam-api-v1.3+json',
      1.4: 'application/vnd.wam-api-v1.4+json',
      1.5: 'application/vnd.wam-api-v1.5+json',
      json: 'application/json',
    }[params.version],
  };

  if (!params.doNotStringify) {
    params.headers['Content-Type'] = 'application/json';
  }

  if (!isCustomUrl && Language) {
    params.headers['Lw-Language'] = Language.getLocale();
  }

  if (params.body && !params.doNotStringify) {
    params.body = JSON.stringify(params.body);
  }
  // UI-2151

  if (shouldSendAuthHeader) {
    if (isUnifiedLoginEnabled()) {
      params.headers = {
        ...params.headers,
        ...(await getAuthenticationHeader()),
      };
    } else {
      if (params.token) {
        params.headers['Wam-Token'] = Session.getToken();
      }

      if (params.signupToken) {
        params.headers['Wam-Token'] = Session.getSignupSession();
      }

      // temporary tech debt to remove once onboarding-api token handling is fixed:
      if (requestUrl.indexOf('onboarding-api') !== -1) {
        if (params.headers['Wam-Token']) {
          params.headers['Authorization'] = 'undefined';
        } else if (params.headers['Authorization']) {
          params.headers['Wam-Token'] = 'undefined';
        }
      }
    }
  }

  if (urlParameters) {
    const parametersString = `${
      requestUrl.indexOf('?') > -1 ? '&' : '?'
    }${serialize(urlParameters)}`;
    requestUrl += parametersString;
  }

  return fetch(requestUrl, params)
    .then(res => status(res, requestUrl, params))
    .then(res => {
      if (res.status === 401) {
        return handle401(res);
      }

      return res;
    })
    .then(res => res.text())
    .then(text => (text ? JSON.parse(text) : {}));
}

async function status(response, requestUrl, params) {
  if (
    (response.status >= 200 && response.status < 300) ||
    response.status === 401
  ) {
    return response;
  }
  if (response.status === 500) {
    DisasterRecoveryService.setIsInDisasterRecoveryMode(true);
  }

  const json = await response.json();

  const errorMsgFromBe =
    json?.errors?.[0]?.message ?? json?.error?.message ?? json.error;

  const error = new DatadogClient.CustomError(
    json?.errors ?? [],
    errorMsgFromBe,
    json?.error?.code,
    response.status,
  );

  // Log the error to Datadog
  DatadogClient.addError(error, {
    httpStatus: response.status,
    responseBody: error,
    requestUrl: requestUrl,
    requestParams: params,
  });

  DatadogClient.logError(`FE Heremes Fetch Error: ${errorMsgFromBe}`);

  return Promise.reject(json);
}
