import PerksBlock from 'perks/components/perks-block/perks-block';
import { sanitizeHtml } from 'wa-storybook/helpers/sanitize';
import styles from './text-block.module.scss';

type Item = {
  limit?: number;
  text: string;
  title: string;
};

type Props = {
  items: Array<Item>;
};

const TextBlock = (props: Props) => (
  <PerksBlock aria-label={polyglot.t('perks.aria.perk_information')}>
    {props.items.map((item: Item, index: number) => (
      <div key={index} className={styles.item}>
        <div className={styles.title}>{item.title}</div>

        <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.text) }} />
      </div>
    ))}
  </PerksBlock>
);

TextBlock.displayName = 'TextBlock';

export default TextBlock;
