import USER_ROLES from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';
import { hasRightForPensionBenefits } from './helpers/utility';

const pensionBenefitsModule = {
  name: 'pension-and-benefits',
  url: '/pension-and-benefits',
  shouldGrantAccess: hasRightForPensionBenefits,
  restrictions: {
    user: [restrictions['pension_and_benefits']],
  },
  data: {
    title: 'components.main_menu.pension_and_benefits',
    authorizedRoles: [USER_ROLES.employee],
  },
  resolver: () => import('./pension-benefits.container'),
};

export default pensionBenefitsModule;
