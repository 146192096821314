import GoogleAnalytics from 'services/google-analytics/analytics';
import { getStore } from 'app/hermes-redux';
import { getCookie } from 'globals/cookieManager';
import { getCurrentState } from 'router/navigation';
import {
  get as getStorage,
  set as setStorage,
  storageKeys,
} from 'globals/storage';
import { openModal, closeModal } from 'app/state/modal/actions';
import { isFeatureEnabled } from 'stores/features-list-store';
import CarouselSlide from './carousel-slide';
import CarouselChild from './carousel-child';
import { getGASectionFromUrl } from 'wa-storybook/helpers/google-analytics/apps/hermes/get-ga-section';
import PerksNewsletterConsent from './perks-newsletter-consent';
import CookieUtils from 'globals/cookie-preferences';
import CarouselModalComp from 'modal-templates/templates/carousel-modal-comp/carousel-modal-comp';
import { restrictions } from 'state/features-list/constants';

const getWalkthroughExcludeList = () => {
  return (
    getStorage<Array<string>>(storageKeys.walkthroughExcludeList, true) || []
  );
};

const _closeWalkThrough = (userId: string) => {
  /**
   * Because we are in the modal without a route,
   * the parent screen name is the current state url
   */
  const parentScreenName = getCurrentState()?.url;

  if (parentScreenName) {
    GoogleAnalytics.trackScreenView(
      parentScreenName,
      getGASectionFromUrl(parentScreenName),
    );
  }

  const list = getWalkthroughExcludeList();
  list.push(userId);

  CookieUtils.checkBrowserPreferencesForCookieGroupHOF('functional')(() =>
    setStorage<Array<string>>(storageKeys.walkthroughExcludeList, list, true),
  );

  getStore().dispatch(closeModal());
};

const _startWalkThrough = (userId: string) => {
  GoogleAnalytics.trackScreenView('/intro', getGASectionFromUrl('/intro'));
  const isDarkMode = document.documentElement.classList.contains('dark-mode');

  const slides = [
    {
      featureKey: [restrictions.eap_assistance],
      imageSource: require(
        `./assets/walkthrough/svg/${isDarkMode ? 'question_dark.svg' : 'question_light.svg'}`,
      ),
      title: polyglot.t(`walk_through.telus_health.title`),
      subtitle: polyglot.t(`walk_through.telus_health.subtitle`),
      hasHtml: true,
    },
    {
      featureKey: [restrictions.eap_assistance],
      imageSource: require(
        `./assets/walkthrough/svg/${isDarkMode ? 'call_dark.svg' : 'call_light.svg'}`,
      ),
      title: polyglot.t(`walk_through.connect.title`),
      subtitle: polyglot.t(`walk_through.connect.subtitle`),
      hasHtml: true,
    },
    {
      featureKey: [restrictions.care_plans, restrictions.eap_self_assessment],
      imageSource: require(
        `./assets/walkthrough/svg/${isDarkMode ? 'smartphone_dark.svg' : 'smartphone_light.svg'}`,
      ),
      title: polyglot.t(`walk_through.how_you_doing.title`),
      subtitle: polyglot.t(`walk_through.how_you_doing.subtitle`),
      hasHtml: true,
    },
    {
      featureKey: [
        restrictions.digital_programs_self_signup,
        restrictions.care_plans,
      ],
      imageSource: require(
        `./assets/walkthrough/svg/${isDarkMode ? 'writing_dark.svg' : 'writing_light.svg'}`,
      ),
      title: polyglot.t(`walk_through.self_care.title`),
      subtitle: polyglot.t(`walk_through.self_care.subtitle`),
      hasHtml: true,
    },
    {
      featureKey: [
        restrictions.counsellor_appointments,
        restrictions.online_scheduling,
      ],
      imageSource: require(
        `./assets/walkthrough/svg/${isDarkMode ? 'mental_health_dark.svg' : 'mental_health_light.svg'}`,
      ),
      title: polyglot.t(`walk_through.speak_to_counsellor.title`),
      subtitle: polyglot.t(`walk_through.speak_to_counsellor.subtitle`),
      hasHtml: true,
    },
    {
      featureKey: [restrictions.virtual_care_integration],
      imageSource: require(
        `./assets/walkthrough/svg/${isDarkMode ? 'heart_dark.svg' : 'heart_light.svg'}`,
      ),
      title: polyglot.t(`walk_through.medical_care.title`),
      subtitle: polyglot.t(`walk_through.medical_care.subtitle`),
      hasHtml: true,
    },
    {
      featureKey: [restrictions.benefit_online_shop],
      imageSource: require(
        `./assets/walkthrough/svg/${isDarkMode ? 'perks_dark.svg' : 'perks_light.svg'}`,
      ),
      title: polyglot.t(`walk_through.perks.title`),
      subtitle: polyglot.t(`walk_through.perks.subtitle`),
      hasHtml: true,
      content: isFeatureEnabled(restrictions.benefit_newsletter) && (
        <PerksNewsletterConsent />
      ),
    },
  ];

  const filteredSlides = slides.filter(slide => {
    if (!slide.featureKey) {
      return true;
    }

    const featureKeys = Array.isArray(slide.featureKey)
      ? slide.featureKey
      : [slide.featureKey];

    return featureKeys.some(restriction => isFeatureEnabled(restriction));
  });

  return getStore().dispatch(
    openModal(
      CarouselModalComp,
      {
        isCrossButton: false,
        slides: filteredSlides,
        slideComp: CarouselSlide,
        className: 'walkthrough',
        noHeader: true,
        children: CarouselChild,
        onCloseHandler: () => _closeWalkThrough(userId),
      },
      null,
      null,
      null,
      true,
    ),
  );
};

// On the signup invitation page, the user is logged out after this function is defined
// To prevent the walkthrough from appearing if a previous user didn't close it, we check the current router state
// This way, if the previous user logs back, he will still be able to follow the walkthrough steps
export const openWalkThrough = (userId: string) => {
  if (getCurrentState().name === 'signupInvitation') return;
  const isOnlyEAP =
    !isFeatureEnabled('social_feed') && !isFeatureEnabled('benefit');
  const isAlreadySeen = getWalkthroughExcludeList().includes(userId);
  const isNotComingFromNative = !getCookie('isComingFromNative');

  if (!isAlreadySeen && !isOnlyEAP && isNotComingFromNative) {
    _startWalkThrough(userId);
  }
};
