import type { Dispatch, ThunkedAction } from 'globals/types';
import type { FeaturesList } from '../features-list/types';
import { createFromList } from './helpers';
import { getPrivateRoutes } from 'router/helpers';

export const updateMenu = (featuresList: FeaturesList): ThunkedAction => {
  return (dispatch: Dispatch) => {
    // @ts-ignore
    createFromList(getPrivateRoutes(), featuresList).then(menu => {
      dispatch({
        type: 'MENU/UPDATE_MENU',
        menu,
      });
    });
  };
};
