import { AnalyticsEventsFactory as analyticsEventsFactory } from 'wa-storybook/helpers/google-analytics/apps/hermes/analytics-events';
import { AnalyticsEvents } from 'services/analytics/analytics-events';
import { testableFeaturesEnabled } from 'globals/testable';

export const AnalyticsEvent = !testableFeaturesEnabled()
  ? analyticsEventsFactory({
      appName: 'Hermes',
      measurementId: window.WAM.ENV.analyticsMeasurementId,
      debug: !IS_PRODUCTION,
      AWSAnalytics: IS_DEVELOPMENT
        ? require('@aws-amplify/analytics').Analytics
        : undefined,
    })
  : AnalyticsEvents;
