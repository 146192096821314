import { takeLatest, put, call } from 'redux-saga/effects';
import actions from './actions';
import * as api from './api';
import { WellbeingFeedResponse } from './types';
import { WellbeingFeedCardTypes } from 'wa-storybook/components/feed/story-module/common/constants';
import { restrictions } from 'state/features-list/constants';
import { isFeatureEnabled } from 'stores/features-list-store';
import WellbeingIcon from '../../react-components/handshake/assets/wellbeing/ic_wellbeing.svg';
import { AppLogger } from '@workivate/tho-web-shared';

export function* fetchWellbeingFeed() {
  try {
    yield put(actions.setIsFetchingWellbeingFeed(true));
    const feed: WellbeingFeedResponse = yield call(api.getWellbeingFeed);

    const thwbFeedCard = {
      cardId: 'open-wellbeing-handshake-modal',
      cardType: WellbeingFeedCardTypes.Integration,
      title: polyglot.t('handshake_integration.wellbeing.title'),
      subtitle: polyglot.t('handshake_integration.wellbeing.subtitle'),
      icon: WellbeingIcon,
    };

    const isTHWBEnabled = isFeatureEnabled(restrictions.wellbeing_integration);

    const wellbeingFeed = isTHWBEnabled
      ? [thwbFeedCard, ...feed.body]
      : feed.body;

    yield put(actions.setWellbeingFeed(wellbeingFeed));
  } catch (e) {
    AppLogger.error(e);
  } finally {
    yield put(actions.setIsFetchingWellbeingFeed(false));
  }
}

export default function* listener() {
  yield takeLatest(actions.fetchWellbeingFeed().type, fetchWellbeingFeed);
}
