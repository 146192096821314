import type { GiftCardOrder } from 'pages/gift-cards/gift-cards.types';
import Order from 'pages/gift-cards/hub/view/components/gift-card-order';

type Props = {
  gcOrder?: Array<GiftCardOrder>;
};

const GiftCardCodes = ({ gcOrder }: Props) => (
  <>
    {gcOrder?.map((giftCardOrder: GiftCardOrder, index: number) => (
      <Order
        isLast={index === gcOrder.length - 1}
        giftCardOrder={giftCardOrder}
        key={index}
      />
    ))}
  </>
);

export default GiftCardCodes;
