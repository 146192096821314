import { WellbeingFeedResponse } from './types';

import fetch from 'globals/lambda-fetch';

const END_POINT = `/wellbeing-feed-api/wellbeing-feed`;

export const getWellbeingFeed = (): Promise<WellbeingFeedResponse> =>
  fetch(END_POINT, {
    method: 'get',
  });
