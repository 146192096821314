import { isWebViewOnMobile } from './user-agent';
import { getUser } from 'app/stores/user-store';
import { getCompany } from 'stores/company-store';
const MMHN_TYPES = ['MMHN', 'Manulife Mental Health Network'];

export const isManulifeOriginator = () => {
  const originator = getUser().originator || '';

  // If originator is not set, we can't determine if it's Manulife
  if (!originator) {
    return undefined;
  }

  return originator.toLowerCase() === 'manulife';
};

export const isManulifeWebView = () => {
  const isManulifeOrig = isManulifeOriginator();

  // If isManulifeOrig is undefined, we return a default value until we can determine if it's Manulife
  const isManulife = isManulifeOrig === undefined ? true : isManulifeOrig;

  return isWebViewOnMobile() && isManulife;
};

export const isManulifeMMHNCompany = () => {
  const companyType = getCompany()['company_type'] || '';

  return isManulifeOriginator() && MMHN_TYPES.includes(companyType);
};
