import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

const resolver = () =>
  Promise.all([
    import(/* webpackChunkName: "giftcards" */ './gift-cards-comp'),
    import(/* webpackChunkName: "giftcards" */ './gift-cards.reducers'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('giftcards', reducer.default);
    return component;
  });

const FEATURE_KEY = 'benefit_global_gift_cards';

const giftCardsModule = {
  hub: {
    name: 'giftcards',
    url: '/gift-cards',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.gift_cards',
      mainMenu: 'giftcards',
      section: 'deals',
      includeInMenu: false,
      showSearch: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  codes: {
    name: 'giftcardsCodes',
    url: '/gift-cards/codes',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.gift_cards',
      mainMenu: 'giftcards',
      section: 'deals',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  popular: {
    name: 'giftcardsPopular',
    url: '/gift-cards/popular',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.gift_cards',
      mainMenu: 'giftcards',
      section: 'deals',
      includeInMenu: true,
      showSearch: false,
      order: 4,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  category: {
    name: 'giftcardsCategory',
    url: '/gift-cards/category/:categoryId',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.gift_cards',
      mainMenu: 'giftcards',
      section: 'deals',
      includeInMenu: false,
      showSearch: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  details: {
    name: 'giftcardsDetails',
    url: '/gift-cards/:id',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.gift_cards',
      mainMenu: 'giftcards',
      section: 'deals',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  error: {
    name: 'giftcardsError',
    url: '/gift-cards/error',
    resolver,
    restrictions: {
      user: [restrictions[FEATURE_KEY]],
    },
    data: {
      title: 'components.main_menu.gift_cards',
      mainMenu: 'giftcards',
      section: 'deals',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default giftCardsModule;
