// Technical debt since the USER FIELDS object
// get a different naming as the USER OBJECT

export interface UserFields {
  about: string;
  date_birth: string;
  date_join: string;
  email: string;
  secondary_email_address: string;
  first_name: string;
  gender: string;
  last_name: string;
  telephone_mobile: string;
  telephone_work: string;
  year_birth: string;
}

const userFields = {
  about: 'note',
  date_birth: 'birthday_date',
  date_join: 'work_start_date',
  email: 'email',
  secondary_email_address: 'secondary_email_address',
  first_name: 'first_name',
  gender: 'gender',
  last_name: 'last_name',
  telephone_mobile: 'tel_mobile',
  telephone_work: 'tel_work',
  year_birth: 'year_birth',
};

export default userFields;
