import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';
import { newsFeed } from './feed.constants';

function resolver(loadComponent) {
  return () =>
    Promise.all([
      loadComponent(),
      import(/* webpackChunkName: "feed" */ './state/reducer'),
      import(
        /* webpackChunkName: "perks" */ '../perks-home-page/perks-home-page.reducers'
      ),
    ]).then(([component, reducer, perksHomePageReducer]) => {
      injectAsyncReducer('feed', reducer.feedReducer);
      injectAsyncReducer('perksHomePage', perksHomePageReducer.default);

      return component;
    });
}

const feedModule = {
  base: {
    name: newsFeed,
    url: '/feed',
    resolver: resolver(
      () => import(/* webpackChunkName: "feed" */ './view/feed'),
    ),
    restrictions: {
      user: [restrictions['social_feed']],
    },
    data: {
      title: 'components.main_menu.news_feed',
      mainMenu: 'feed',
      showSearch: false,
      section: 'feed',
      includeInMenu: true,
      authorizedRoles: [USER_ROLES.employee],
      shortcutKeyCode: 49,
      hideFooter: true,
    },
  },
  detail: {
    name: 'feedDetail',
    url: '/feed/:id', // TODO: change the url when the solution for wa_url will come
    resolver: resolver(
      () => import(/* webpackChunkName: "feedDetails" */ './view/feed-post'),
    ),
    restrictions: {
      user: [restrictions['social_feed']],
    },
    data: {
      title: 'components.main_menu.news_feed',
      mainMenu: 'feed',
      showSearch: false,
      section: 'feed',
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  search: {
    name: 'feedSearch',
    url: '/feed/search',
    resolver: resolver(
      () => import(/* webpackChunkName: "feed" */ './view/feed'),
    ),
    restrictions: {
      user: [restrictions['social_feed']],
    },
    data: {
      title: 'components.main_menu.news_feed',
      mainMenu: 'feed',
      showSearch: false,
      section: 'feed',
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  group: {
    name: 'feedGroup',
    url: '/feed/group/:groupId',
    resolver: resolver(
      () => import(/* webpackChunkName: "feed" */ './view/feed'),
    ),
    restrictions: {
      user: [restrictions['social_feed'], restrictions['grouping']],
    },
    data: {
      title: 'components.main_menu.news_feed',
      mainMenu: 'feed',
      section: 'feed',
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default feedModule;
