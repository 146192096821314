import type { Feature, FeaturesList } from './types';

function computeFeatureEnabled(feature: Feature): boolean {
  if (feature.type === 'boolean') {
    return feature.value === true;
  } else if (feature.type === 'float' || feature.type === 'integer') {
    if (typeof feature.value === 'string') {
      return parseFloat(feature.value) > 0;
    } else if (typeof feature.value === 'number') {
      return feature.value > 0;
    }
  }

  return feature.value !== '';
}

export function findFeature(
  featuresList: FeaturesList | null | undefined = [],
  featureName: string,
): Feature | null | undefined {
  const feature = featuresList?.find(
    (currentFeature: Feature) => currentFeature.name === featureName,
  );
  return featuresList?.length === 0 || !feature ? null : feature;
}

export function isFeatureEnabled(
  featuresList: FeaturesList = [],
  featureName = '',
): boolean {
  const feature = findFeature(featuresList, featureName);

  return !feature ? false : computeFeatureEnabled(feature);
}
