import type { Price } from 'globals/perks.types';
import type {
  GiftCardValue,
  PriceList,
  PriceRange,
} from '../../../gift-cards.types';

import { DISCOUNTED_PRICE_RANGE, DISCOUNTED_PRICE_LIST } from 'perks/constants';

import PriceRangeInput from './price-range-input';
import PriceListSelect from './price-list-select/price-list-select';

import styles from './value-selection.module.scss';

type Props = {
  onValueChange: (value: Price, err: boolean) => void;
  selectedPrice: Price;
  value: GiftCardValue;
  availableBalance: number;
  referrer: string;
};
const ValueSelection = (props: Props) => {
  const { availableBalance, onValueChange, referrer, selectedPrice, value } =
    props;

  if (value.type === DISCOUNTED_PRICE_RANGE) {
    const priceRange: PriceRange = value;
    const currency = priceRange.data.min_price.currency;
    return (
      <div className={styles.container}>
        <PriceRangeInput
          availableBalance={availableBalance}
          onInputChange={(value: number, err: boolean) =>
            onValueChange({ amount: value * 100, currency }, err)
          }
          priceRange={priceRange.data}
        />
      </div>
    );
  }

  if (value.type === DISCOUNTED_PRICE_LIST) {
    const priceList: PriceList = value;
    const currency = priceList.data.prices[0]?.currency || '';

    return (
      <div className={styles.container}>
        <PriceListSelect
          availableBalance={availableBalance}
          onInputChange={(value: number, err: boolean) =>
            onValueChange({ amount: value, currency }, err)
          }
          selectedPrice={selectedPrice}
          priceList={priceList.data.prices}
          referrer={referrer}
        />
      </div>
    );
  }

  return null;
};

ValueSelection.displayName = 'ValueSelection';

export default ValueSelection;
