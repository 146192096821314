import * as Session from 'state/session/helpers';
import { getStore } from 'app/hermes-redux';
import { getUser } from 'stores/user-store';

import { openWalkThrough } from 'walk-through/walk-through';
import { getCompanySettings } from 'stores/company-store';
import { goTo, getCurrentState } from 'router/navigation';

import { shouldShowConsentsToUserOnSignIn } from '../../pages/consent/helpers';
import { consentService } from '../../pages/consent/consent-service';
import {
  setRedirectionModel,
  shouldResumePostLoginFlow,
} from '../consent/actions';
import { get as storageGet, remove as storageRemove } from 'globals/storage';

// Handle the post login flow
export const startPostLoginFlow = async () => {
  const msTeamsUrl = Session.getMsTeamsUrl() || '';
  const userObj = getUser();
  const dispatch = getStore().dispatch;
  const currentState = getCurrentState();

  await consentService.loadConsentsData();

  const { consent } = getStore().getState();

  if (msTeamsUrl) {
    return goTo('/return-to-teams');
  }

  if (storageGet('isCreatingLimitedAccount')) {
    dispatch(shouldResumePostLoginFlow(false));

    dispatch(
      setRedirectionModel({
        type: 'POP_UP',
        onCompleteGoTo: {
          name: 'limitedAccountConfirmation',
          params: {},
        },
      }),
    );

    storageRemove('isCreatingLimitedAccount');

    return;
  }

  if (shouldShowConsentsToUserOnSignIn(consent.consents, currentState)) {
    dispatch(shouldResumePostLoginFlow(true));
    return;
  }
  dispatch(shouldResumePostLoginFlow(false));

  dispatch(await getCompanySettings());

  openWalkThrough(userObj['user_id']);
};
