import type { Dispatch, ThunkedAction } from 'globals/types';
import type {
  ConsentModel,
  ConsentResetAction,
  ConsentSetRedirectionModelAction,
  ConsentShouldResumePostLoginFlowAction,
  ConsentType,
  UpdateConsentPostData,
} from './types';
import { showToast } from 'toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';

import { CONSENT } from './constants';

import fetch from 'globals/wa-fetch';
import { getToken } from '../session/helpers';
import type { ConsentRedirectionModel } from '../../pages/consent/helpers';

export const shouldResumePostLoginFlow = (
  payload: boolean,
): ConsentShouldResumePostLoginFlowAction => {
  return {
    type: 'CONSENT/SET_SHOULD_RESUME_POST_LOGIN_FLOW',
    payload,
  };
};

export const setRedirectionModel = (
  payload: ConsentRedirectionModel | null,
): ConsentSetRedirectionModelAction => ({
  type: 'CONSENT/SET_REDIRECTION_MODEL',
  payload,
});

export const resetConsentState = (): ConsentResetAction => ({
  type: 'CONSENT/RESET',
});

export const getConsents = (userID: string): ThunkedAction => {
  return (
    dispatch: Dispatch,
    getState: () => {
      consent: ConsentModel;
    },
  ) => {
    if (getState().consent.isFetching) {
      return Promise.resolve();
    }

    dispatch({
      type: CONSENT.SET_IS_FETCHING_STATUS,
      payload: true,
    });

    const useSignUpToken = !getToken();

    return fetch(`/users/${userID}/consents`, {
      method: 'get',
      token: !useSignUpToken,
      /**
       * During the sign up process in some cases (for example
       * if a user is signing up using a invitation code with MFA enabled)
       * we are provided a signup token this will tell wa fetch to use this
       * instead of trying to fetch it from the normal location
       * */
      signupToken: useSignUpToken,
    })
      .then(res => {
        return dispatch({
          type: CONSENT.GET_ALL_CONSENTS,
          payload: res.body,
        });
      })
      .catch(() => {
        return dispatch(
          showToast({
            type: ToastType.ERROR,
            message: polyglot.t('consent.error.fetch'),
          }),
        );
      })
      .finally(() => {
        return dispatch({
          type: CONSENT.SET_IS_FETCHING_STATUS,
          payload: false,
        });
      });
  };
};

export const updateConsent = (
  userID: string,
  consentID: ConsentType,
  body: UpdateConsentPostData,
  useSignUpToken: boolean,
  successCallback?: () => void,
): ThunkedAction => {
  return (
    dispatch: Dispatch,
    getState: () => {
      consent: ConsentModel;
    },
  ) => {
    if (!getState().consent.isFetching) {
      dispatch({
        type: CONSENT.SET_IS_FETCHING_STATUS,
        payload: true,
      });

      return fetch(`/users/${userID}/consents/${consentID}`, {
        method: 'post',
        body,
        token: !useSignUpToken,
        signupToken: useSignUpToken,
      })
        .then(res => {
          if (successCallback) {
            successCallback();
          }

          dispatch({
            type: CONSENT.UPDATE_CONSENT,
            payload: res.body,
          });
        })
        .catch(() => {
          dispatch(
            showToast({
              type: ToastType.ERROR,
              message: polyglot.t('consent.error.update'),
            }),
          );
        })
        .finally(() => {
          dispatch({
            type: CONSENT.SET_IS_FETCHING_STATUS,
            payload: false,
          });
        });
    }
  };
};
