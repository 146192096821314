import { isFeatureEnabled } from 'stores/features-list-store';
import { getStore } from 'app/hermes-redux';
import waUrlTransform from 'globals/wa-url';
import { getRouteFromStateName, isPath } from 'router/helpers';
import { injectParamsInUrl } from '../globals/helpers';

import { Module } from 'router/types';
import GoogleAnalytics from 'services/google-analytics/analytics';
import { getGASectionFromUrl } from 'wa-storybook/helpers/google-analytics/apps/hermes/get-ga-section';
import { showWonderingStudy } from 'globals/wondering/helpers';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { getRouterState } from './router-state-provider';

export const history = createBrowserHistory();

export function getCurrentState() {
  return getRouterState().currentRoute;
}

export function getPreviousState() {
  return getRouterState().previousRoute;
}

export const trackRouteChange = (newRoute: Module) => {
  showWonderingStudy(newRoute);

  GoogleAnalytics.trackScreenView(
    newRoute.url,
    getGASectionFromUrl(newRoute.url),
    newRoute.params,
  );
};

export const getQueryParams = (search?: string): Record<string, any> => {
  return queryString.parse(search ?? location.search);
};

export function getLandingStateFromFeaturesList() {
  // order matters here
  switch (true) {
    case isFeatureEnabled('home_page'):
      return 'home';
    case isFeatureEnabled('care_plans'):
    case isFeatureEnabled('counsellor_appointments'):
    case isFeatureEnabled('care_coordination_specialists_messaging'):
      return 'myCareHub';
    case isFeatureEnabled('social_feed'):
      return 'feed';
    case isFeatureEnabled('eap_assistance'):
      return 'employeeAssistanceHome';
    case isFeatureEnabled('benefit_online_shop'):
      return 'shop';
    case isFeatureEnabled('benefit_colleague_offer'):
      return 'colleagueOffers';
    case isFeatureEnabled('benefit_restaurant'):
      return 'restaurants';
    case isFeatureEnabled('benefit_in_store_offer'):
      return 'instoreOffers';
    case isFeatureEnabled('benefit_global_gift_cards'):
      return 'giftcards';
    case isFeatureEnabled('social_colleague_directory'):
      return 'directory';
    case isFeatureEnabled('social_recognition'):
      return 'leaderboard';
    case isFeatureEnabled('eap_hwr_toolkits'):
      return 'hwrHome';
    default:
      return 'profile';
  }
}

// quite dumb for now, but good enough, do we need to take into accounts params?
// TODO: LOGIN REFACTOR
// API should return return the actual route and we should then use it directly.
export function getStateFromUrl(apiState) {
  const stateMapping = {
    'wallet/transactions': 'wallet',
    wallet: 'wallet',
    profile: 'profile',
    directory: 'directory',
    leaderboard: 'leaderboard',
    feed: 'feed',
    'shop/retailers': 'shop',
    'shop/retailers/category': 'shop',
    'shop/products/compare': 'shop',
    shop: 'shop',
    'eap/employee': 'employeeAssistanceHome',
    'life/hwr': 'hwrHome',
    '/employee-assistance': 'employeeAssistanceHome',
  };

  return Object.keys(stateMapping)
    .filter(k => apiState.indexOf(k) > -1)
    .map(k => stateMapping[k])[0];
}

export function getLandingPageUrl(isSessionInvalidating?: boolean) {
  const { user } = getStore().getState().session;
  const previousState = getPreviousState();
  const { isComingFromNative } = getStore().getState().global || {};

  const landingUrl = user ? waUrlTransform(user['landing_page_wa_url']) : null;

  if (
    previousState?.name?.startsWith('employeeAssistance') &&
    isComingFromNative
  ) {
    return getRouteFromStateName('employeeAssistanceHome').url;
  } else if (landingUrl) {
    const stateFromUrl = getStateFromUrl(landingUrl);

    return stateFromUrl ? getRouteFromStateName(stateFromUrl).url : landingUrl;
  } else if (user && !isSessionInvalidating) {
    const landingState = getLandingStateFromFeaturesList();

    return getRouteFromStateName(landingState).url;
  }
}

export function goTo(
  route,
  params?: Record<string, any>,
  {
    reload,
    shouldNotPushToHistory,
    noUpdate,
    stateParams,
  }: { reload?; shouldNotPushToHistory?; noUpdate?; stateParams? } = {},
) {
  const destinationUrl = isPath(route)
    ? injectParamsInUrl(route, params)
    : getUrl(route, params);

  if (reload) {
    window.location.assign(destinationUrl); // hard reload
  } else if (noUpdate) {
    window.history.replaceState({}, '', destinationUrl);
  } else {
    goToUrl(destinationUrl, shouldNotPushToHistory, {
      ...params,
      ...stateParams,
    }); // normal page transition
  }
}

export function goToUrl(
  path: string,
  shouldNotPushToHistory?: boolean,
  params?: Record<string, any>,
) {
  if (shouldNotPushToHistory) {
    history.replace(path, params);
  } else {
    history.push(path, params);
  }

  // reset scroll position
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function getUrlParameter(): Record<string, any>;
export function getUrlParameter(input: string): string;

export function getUrlParameter(param?: string) {
  // Params are set to currentRoute state when navigating to a new route
  const params = getRouterState().currentRoute?.params ?? {};

  const result = param !== undefined ? params[param] : params;

  return result ?? '';
}

export function getUrl(stateName: string, params?: Record<string, any>) {
  const route = getRouteFromStateName(stateName);

  if (route?.name) {
    return history.createHref(injectParamsInUrl(route.url, params));
  }

  return '/404';
}

export const createHref = routePath => history.createHref(routePath);

export function getSubdomainFromHost() {
  const host = window.location.hostname;

  return host.split('.')[0] as string;
}

export const getSuffixFromHref = () => {
  const href = window.location.href;
  return href.substr(href.indexOf('.'));
};

export const getSuffixFromHost = () => {
  const host = window.location.host;
  return host.substr(host.indexOf('.'));
};

export function goBack() {
  history.back();
}
