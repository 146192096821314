import { lazy } from 'react';
import routes from '../routes';
import { matchPath } from 'react-router-dom';
import { Module, Redirect } from '../router/types';

const allRoutes = [...routes.modules, ...routes.publicRoutes];

export const getAllRoutes = () => allRoutes as Module[];
export const getPrivateRoutes = () => routes.modules as Module[];
export const getPublicRoutes = () => routes.publicRoutes as Module[];
export const getRedirectedRoutes = () => routes.redirects as Redirect[];

export function getRouteFromStateName(state: string): Module {
  return allRoutes.find(route => route.name === state);
}

function findInRoutes(routes: Module[], path: string) {
  return (
    (routes || []).find(route => {
      const match =
        matchPath({ path: route.url }, path) &&
        // for cases we have more than one same route url, match the regex if it exists so to map the correct state
        (route.match ? route.match?.test(path) : true);

      return !!match;
    }) || ({} as Module)
  );
}

export function getRouteParams(path: string) {
  const route = findInRoutes(allRoutes as Module[], path);

  return parseRouteParams(route, path);
}

export function parseRouteParams(route: Module, path: string) {
  if (route?.url) {
    const match = matchPath(route.url, path);

    return match?.params;
  }

  return {};
}

export function getRouteFromPath(path: string) {
  return findInRoutes(allRoutes as Module[], path);
}

export const isPath = (str: string): boolean => {
  return str === '.' || str.startsWith('/') || str.includes('/');
};

export const mapRoutesComponent = (routes: Module[]) => {
  return routes.map(route => {
    return {
      ...route,
      // @ts-ignore
      component: route.resolver && lazy(route.resolver),
    };
  });
};
