import { client } from 'graphql/client';
import { MyCareRoutes } from 'pages/my-care/my-care.constants';
import {
  CareIssuesSelectedQuery,
  CareIssue,
  CareIssuesSelectedDocument,
} from 'graphql-types';

type SelectedCareIssue = Omit<
  CareIssue,
  'analyticsId' | 'title' | 'id' | 'default'
>;

export const getDefaultCareIssue = (careIssues: CareIssue[]) => {
  return careIssues.find(item => item.default);
};

export const checkIfCareIssueIsSelected = async () => {
  const { data } = await client.query<CareIssuesSelectedQuery>({
    query: CareIssuesSelectedDocument,
    fetchPolicy: 'network-only',
  });

  const careIssues: SelectedCareIssue[] | null =
    data?.getCareIssues?.careIssues ?? null;

  if (careIssues) {
    return careIssues.some(careIssue => careIssue.selected);
  }

  return false;
};

export const shouldGrantAccessToCareIssues = async () => {
  try {
    const hasCareIssueSelected = await checkIfCareIssueIsSelected();

    if (hasCareIssueSelected) {
      return {
        provideAccess: false,
        redirectTo: MyCareRoutes.MY_CARE_HUB,
      };
    }

    return {
      provideAccess: true,
      redirectTo: '',
    };
  } catch {
    return {
      provideAccess: false,
      redirectTo: MyCareRoutes.MY_CARE_HUB,
    };
  }
};
