import type { Dispatch, ThunkedAction } from 'globals/types';
import type {
  WellnessPostAPI,
  WellnessSessionResponse,
  WellnessCategoriesResponse,
} from 'app/state/wellness/types';
import { findFeature } from 'state/features-list/helpers';

import fetch from 'globals/wa-fetch';
import { getUser, setUser } from 'stores/user-store';
import { get as getFeedPosts } from 'stores/feed-store';
import { showToast } from 'toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';

export const putSession = (body: any) =>
  fetch(
    `/users/${
      getUser().user_id
    }/wellness/categories/active/series/active/sessions/active`,
    {
      method: 'put',
      token: true,
      version: 1.4,
      body,
    },
  );

export const getSession = () =>
  fetch(
    `/users/${
      getUser().user_id
    }/wellness/categories/active/series/active/sessions/active/contents`,
    {
      method: 'get',
      token: true,
      version: 1.4,
    },
  );

export const skipReEngagement = () => ({
  type: 'WELLNESS/NEWSFEED/SKIP_RE_ENGAGEMENT',
});
export const hideCompletionPost = () => ({
  type: 'WELLNESS/NEWSFEED/HIDE_COMPLETION_POST',
});

export const setPost =
  (post: WellnessPostAPI): ThunkedAction =>
  (dispatch: Dispatch) =>
    dispatch({ type: 'WELLNESS/NEWSFEED/SET_POST', post });

export function updatePost(): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'WELLNESS/NEWSFEED/UPDATE_POST' });
    getFeedPosts(null, { limit: 2 })
      .then((res: any) => {
        dispatch({
          type: 'WELLNESS/NEWSFEED/SET_POST',
          post: res.body[0].data,
        });
        dispatch({ type: 'WELLNESS/NEWSFEED/UPDATE_POST_SUCCESS' });
      })
      .catch(() => {
        dispatch(
          showToast({
            type: ToastType.ERROR,
            message: window.polyglot.t('wellness_sessions.error'),
          }),
        );

        dispatch({
          type: 'WELLNESS/NEWSFEED/UPDATE_POST_FAILURE',
          error: 'Something went wrong',
        });
      });
  };
}

export const markSessionInProgress = () => putSession({ progress: 0.5 });

export const skipSession = () => putSession({ skip: true });

export function updateSession(
  progress: number,
  helpful: boolean,
): ThunkedAction {
  return function (dispatch: Dispatch, getState: any) {
    dispatch({ type: 'WELLNESS/SESSION/UPDATE_SESSION' });

    // if the session we are currently looking at is finished (progress === 1)
    // then we look into the newsfeed current post (if we have data) to know if this session was last in series / category
    // if yes; then we want to show the reflection popup
    const currentNewsfeedSession =
      getState().wellness.newsfeed.session.current_post;

    if (progress === 1 && currentNewsfeedSession) {
      if (currentNewsfeedSession.is_last_in_series) {
        dispatch({
          type: 'WELLNESS/NEWSFEED/UPDATE_SERIES_FINISHED',
          status: true,
        });
      }
      if (currentNewsfeedSession.is_last_in_category) {
        dispatch({
          type: 'WELLNESS/NEWSFEED/UPDATE_CATEGORY_FINISHED',
          status: true,
        });
      }
    }

    return putSession({ progress, helpful })
      .then(() => dispatch({ type: 'WELLNESS/SESSION/UPDATE_SESSION_SUCCESS' }))
      .catch(() => {
        dispatch(
          showToast({
            type: ToastType.ERROR,
            message: window.polyglot.t('wellness_sessions.error'),
          }),
        );
        dispatch({
          type: 'WELLNESS/SESSION/UPDATE_SESSION_FAILURE',
          error: 'Something went wrong',
        });
      });
  };
}

export function fetchSession(): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'WELLNESS/SESSION/FETCH_SESSION' });

    return getSession()
      .then((response: WellnessSessionResponse) => {
        dispatch({
          type: 'WELLNESS/SESSION/FETCH_SESSION_SUCCESS',
          currentSession: response.body,
        });
      })
      .catch(() => {
        dispatch(
          showToast({
            type: ToastType.ERROR,
            message: window.polyglot.t('wellness_sessions.error'),
          }),
        );
        dispatch({
          type: 'WELLNESS/SESSION/FETCH_SESSION_FAILURE',
          error: 'Something went wrong',
        });
      });
  };
}

function getCategories(): Promise<WellnessCategoriesResponse> {
  return fetch(`/wellness/categories`, {
    method: 'get',
    token: true,
    version: 1.4,
  });
}

export const CATEGORY_VIEW = 0;
export const SUBCATEGORY_VIEW = 1;
export const SUMMARY_VIEW = 2;

export function fetchCategories(): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'WELLNESS/CATEGORIES/FETCH_CATEGORIES' });

    return getCategories()
      .then((response: WellnessCategoriesResponse) => {
        dispatch({
          type: 'WELLNESS/CATEGORIES/FETCH_CATEGORIES_SUCCESS',
          categories: response.body,
        });
      })
      .catch(() => {
        dispatch(
          showToast({
            type: ToastType.ERROR,
            message: window.polyglot.t('wellness_categories.fetch_error'),
          }),
        );
        dispatch({
          type: 'WELLNESS/CATEGORIES/FETCH_CATEGORIES_FAILURE',
          error: 'Something went wrong',
        });
      });
  };
}

export function setActiveCategory(
  categoryId: string,
): Promise<WellnessCategoriesResponse> {
  // we also need to update the active category in the user just in case we need it before
  // we re fetch the user
  const newCategories = getUser().wellness_categories.map((category: any) => {
    return Object.assign({}, category, {
      active: category.id === categoryId ? true : false,
    });
  });

  setUser(
    Object.assign({}, getUser(), {
      wellness_categories: newCategories,
    }),
  );

  return fetch(`/users/${getUser().user_id}/wellness/categories/active`, {
    method: 'post',
    token: true,
    version: 1.4,
    body: { categoryId },
  });
}

export function resetCategories(): ThunkedAction {
  return function (dispatch: Dispatch) {
    resetFinishedFlags();
    dispatch({ type: 'WELLNESS/CATEGORIES/RESET' });
  };
}

export function resetSelection(isCategories: boolean): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({
      type: 'WELLNESS/CATEGORIES/RESET_SELECTION',
      isCategories: isCategories,
    });
  };
}

export function changeStep(step: number): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'WELLNESS/CATEGORIES/CHANGE_STEP', step: step });
  };
}

export function touchSubcategory(
  id: string,
  isSelected: boolean,
): ThunkedAction {
  return function (dispatch: Dispatch, getState: any) {
    const { selectedSubcategories } = getState().wellness.categories;
    const categoryLimit = findFeature(
      getState().featuresList,
      'eap_wellness_category_limit',
    );
    const limit = categoryLimit ? parseInt(categoryLimit.value.toString()) : 0;

    if (isSelected && limit > 1 && selectedSubcategories.length > limit - 1) {
      dispatch(
        showToast({
          type: ToastType.ERROR,
          message: window.polyglot.t(
            'wellness_categories.category_selection_limit',
            { limit: limit },
          ),
        }),
      );
    } else if (isSelected) {
      if (limit === 1) {
        // if the user can only select 1 subcategory
        // deselect all other subcategories
        selectedSubcategories.forEach(id =>
          dispatch({
            type: 'WELLNESS/CATEGORIES/DESELECT_SUBCATEGORY',
            id,
          }),
        );
      }
      dispatch({ type: 'WELLNESS/CATEGORIES/SELECT_SUBCATEGORY', id });
    } else {
      dispatch({ type: 'WELLNESS/CATEGORIES/DESELECT_SUBCATEGORY', id });
    }
  };
}

export function touchCategory(id: string, isSelected: boolean): ThunkedAction {
  return function (dispatch: Dispatch, getState: any) {
    const { selectedCategories } = getState().wellness.categories;
    const categoryLimit = findFeature(
      getState().featuresList,
      'eap_wellness_category_limit',
    );
    const limit = categoryLimit ? parseInt(categoryLimit.value.toString()) : 0;

    // if the user can select more than 1 category, notify them if they exceed the limit
    if (isSelected && limit > 1 && selectedCategories.length > limit - 1) {
      dispatch(
        showToast({
          type: ToastType.ERROR,
          message: window.polyglot.t(
            'wellness_categories.category_selection_limit',
            { limit: limit },
          ),
        }),
      );
    } else if (isSelected) {
      if (limit === 1) {
        // if the user can only select 1 category
        // deselect all other categories
        selectedCategories.forEach(id =>
          dispatch({
            type: 'WELLNESS/CATEGORIES/DESELECT_CATEGORY',
            id,
          }),
        );
      }
      dispatch({ type: 'WELLNESS/CATEGORIES/SELECT_CATEGORY', id });
    } else {
      dispatch({ type: 'WELLNESS/CATEGORIES/DESELECT_CATEGORY', id });
    }
  };
}

export function resetFinishedFlags(): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({
      type: 'WELLNESS/NEWSFEED/UPDATE_SERIES_FINISHED',
      status: false,
    });
    dispatch({
      type: 'WELLNESS/NEWSFEED/UPDATE_CATEGORY_FINISHED',
      status: false,
    });
  };
}
