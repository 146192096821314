export type Toast = {
  id?: number;
  message: string;
  type?: ToastType;
};

export enum ToastType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export type Model = {
  toasts: Array<Toast>;
};

export type ToasterAction =
  | {
      type: 'TOASTER/RESET_TOAST';
    }
  | {
      type: 'TOASTER/HIDE_TOAST';
      toast: Toast;
    }
  | {
      type: 'TOASTER/SHOW_TOAST';
      toast: Toast;
    };
