import classNames from 'classnames';
import styles from './inner-card.module.scss';
import Dotdotdot from 'react-dotdotdot';

type Props = {
  description?: string | null;
  extra: string | null | undefined;
  isSmallerCard?: boolean;
  isTieringRewardCard?: boolean;
  showDivider?: boolean;
  provider: string | null | undefined;
  subtitle?: string;
  title: string;
  type: string | null | undefined;
};

const InnerCard = ({
  description,
  extra,
  isSmallerCard,
  isTieringRewardCard,
  showDivider,
  provider,
  subtitle,
  title,
  type,
}: Props) => {
  const isMobileTieringRewardCard =
    isTieringRewardCard && context.viewport.isMobile;
  return (
    <div
      className={classNames(
        styles.container,
        isSmallerCard && styles['container--shorter'],
        isTieringRewardCard && styles['container--tiering-reward'],
        showDivider && styles['container--divider'],
      )}
    >
      <div
        className={classNames(
          styles['content-wrap'],
          isMobileTieringRewardCard &&
            styles['content-wrap--tiering-reward-mobile'],
        )}
      >
        <Dotdotdot clamp={2} useNativeClamp>
          <h3 className={styles.title}>{title}</h3>
        </Dotdotdot>
        {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
        <div className={styles.provider}>
          {!isMobileTieringRewardCard ? provider : null}
          {description && (
            <span className={styles.description}>{`${
              !isMobileTieringRewardCard && !!provider ? ' - ' : ''
            }${description}`}</span>
          )}
        </div>
        <div
          className={classNames(
            styles.footer,
            isMobileTieringRewardCard &&
              styles['footer--mobile-tiering-reward'],
          )}
        >
          {!isMobileTieringRewardCard && (
            <div className={styles['footer__type']}>{type}</div>
          )}
          {isMobileTieringRewardCard && (
            <div className={styles['footer__provider']}>{provider}</div>
          )}
          {extra && <div className={styles['footer__extra']}>{extra}</div>}
        </div>
      </div>
    </div>
  );
};

InnerCard.displayName = 'InnerCard';

export default InnerCard;
