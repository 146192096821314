export const AppointmentsRoutes = Object.freeze({
  APPOINTMENT_CANCEL: 'appointmentsAppointmentCancel',
  APPOINTMENT_CONFIRMATION: 'appointmentsAppointmentConfirmation',
  APPOINTMENT_DETAILS: 'appointmentsAppointmentDetails',
  APPOINTMENT_INTRO: 'appointmentsAppointmentIntro',
  APPOINTMENT_TIMESLOT: 'appointmentsTimeslotSelection',
  APPOINTMENT_TYPE: 'appointmentsAppointmentType',
  CONTACT_DETAILS: 'appointmentsContactDetails',
  COUNSELLOR_PROFILE: 'appointmentsCounsellorProfile',
  COUNSELLOR_SELECTION: 'appointmentsCounsellorSelection',
  USER_LOCATION: 'appointmentsUserLocation',
  VIEW_APPOINTMENTS: 'appointmentsViewAppointments',
  PAYMENT_DETAILS: 'appointmentsPaymentDetails',
  TEAMS_PAYMENT_REDIRECT: 'appointmentsTeamsPaymentRedirect',
});
