import { Dispatch } from '../../../../../globals/types';
import { AssessmentAnswerFragment } from 'graphql-types';

import PageTitle from '../../../../../react-components/head/state/actions';

/**
 * The backend doesn't (at the moment) provide a localised string for the
 * unitOfMeasurement, so we have to do some footwork.
 */
export const toTitleCase = (str: string, delimiter = '-'): string => {
  return str
    .split(delimiter)
    .map(
      (val: string) =>
        `${val.charAt(0).toUpperCase()}${val.substr(1).toLowerCase()}`,
    )
    .join(' ');
};

export const stripTypename = myObject => {
  const { __typename: _, ...newObject } = myObject;
  return newObject;
};

type MeasurementArrayType = NonNullable<
  AssessmentAnswerFragment['measurements']
>;
type MeasurementType = NonNullable<MeasurementArrayType[number]>;

type MeasurementTypeWithoutTypeName = Omit<MeasurementType, '__typename:'>;

export const stripTypenameFromMeasurements = answersArray => {
  if (!answersArray || answersArray.length <= 0) return null;

  return answersArray.map(answer => {
    if (!answer) return null;

    const newAnswer: Omit<AssessmentAnswerFragment, '__typename'> =
      stripTypename(answer);

    const newMeasurements = (newAnswer.measurements || []).map<
      MeasurementTypeWithoutTypeName | null | undefined
    >(measurement => {
      if (!measurement) return;

      return stripTypename(measurement);
    });

    const { ...answerProps } = newAnswer;

    return {
      ...answerProps,
      measurements: newMeasurements,
    };
  });
};

export const stripNullsFromArray = <ArrayItemType,>(
  arr: Array<ArrayItemType | null | undefined>,
): Array<ArrayItemType> => {
  return arr.reduce<Array<ArrayItemType>>((resArr, item) => {
    if (item) {
      return [...resArr, item];
    }

    return resArr;
  }, []);
};

export const insertAndTrimArray = <ItemType,>(
  arr: ReadonlyArray<ItemType>,
  item: ItemType,
  itemEqualityComparator: (arg1: ItemType, arg2: ItemType) => boolean,
): Array<ItemType> => {
  const indexOfNewItem = arr.findIndex(existingItem =>
    itemEqualityComparator(item, existingItem),
  );

  if (indexOfNewItem === -1) {
    return [...arr, item];
  }

  return [...arr.slice(0, indexOfNewItem), item];
};

export const updatePageTitleAndReference = (
  title: string | null | undefined,
  dispatch: Dispatch,
  reference = '0',
) => {
  const titlePostFix = title ? ` - ${title}` : '';
  const pageTitle = `${reference}${titlePostFix}`;

  dispatch(PageTitle.setCurrentSection(pageTitle));
};
