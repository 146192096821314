import { format, isSameDay } from 'date-fns';
import padStart from 'lodash/padStart';
import { locales } from 'wa-storybook/global/date-utils';

export { locales };

export const isLeapYear = year => (!(year % 4) && year % 100) || !(year % 400);

export const escapeRelativeLocaleString = str => str.replace(/'/g, "''");

export function toDate(concatDate: number, format = 'YYYY-MM-DD') {
  const num = (concatDate || '').toString();

  if (num.length < 8) return null;

  const dateString = format
    .replace('YYYY', num.substring(0, 4))
    .replace('MM', num.substring(4, 6))
    .replace('DD', num.substring(6, 8));

  return new Date(dateString);
}

export function toConcatFormat(date) {
  if (!date) return null;

  const concatFormatDate = format(date, 'yyyyMMdd');

  return parseInt(concatFormatDate);
}

export function toConcatFormatFromSeconds(seconds) {
  if (!seconds) return null;

  return toConcatFormat(new Date(seconds * 1000));
}

export const isToday = date => isSameDay(new Date(date), new Date());

export const getDurationComponentsFromMs = durationInMs => {
  const hours = Math.floor((durationInMs / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((durationInMs / (1000 * 60)) % 60);

  return {
    hours,
    minutes,
  };
};

export const toDurationFromMs = durationInMs => {
  if (!durationInMs) return;

  const { hours, minutes } = getDurationComponentsFromMs(durationInMs);

  if (hours > 1) {
    return `${hours}:${padStart(`${minutes}`, 2, '0')} ${polyglot.t(
      'global.hrs',
    )}`;
  }
  return `${minutes} ${polyglot.t('global.min')}`;
};
