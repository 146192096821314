// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** function to get dimensions
  usage example to get \`tablet\` value from \`\$page-max-widths\`
  .class {
    max-width: dim(\$page-max-widths, tablet);
  }

 */
.discounted-price-module__prices___svByj {
  vertical-align: middle;
  width: calc(100% - 125px);
}

.discounted-price-module__discounted-price___gLsqN,
.discounted-price-module__price___A1Rv6 {
  font-weight: 600;
  font-size: 1.25rem;
  display: inline-block;
}

.discounted-price-module__discounted-price___gLsqN {
  color: var(--green600, #2b8000);
  margin-right: 5px;
}

.discounted-price-module__price___A1Rv6 {
  color: var(--neutral60, #686e71);
  text-decoration: line-through;
}

.discounted-price-module__vat___DCs70 {
  font-size: 0.875rem;
  color: var(--neutral75, #424547);
}`, "",{"version":3,"sources":["webpack://./node_modules/wa-storybook/styles/tools/functions/_widths.scss","webpack://./client/app/pages/gift-cards/shared/discounted-price.module.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;ACEA;EACE,sBAAA;EACA,yBAAA;AAMF;;AAHA;;EAEE,gBAAA;EACA,kBAAA;EACA,qBAAA;AAMF;;AAHA;EACE,+BAAA;EACA,iBAAA;AAMF;;AAHA;EACE,gCAAA;EACA,6BAAA;AAMF;;AAHA;EACE,mBAAA;EACA,gCAAA;AAMF","sourcesContent":["/** function to get dimensions\n  usage example to get `tablet` value from `$page-max-widths`\n  .class {\n    max-width: dim($page-max-widths, tablet);\n  }\n\n */\n\n@function dim($prop, $key) {\n  @return map-get($prop, $key);\n}\n","@import '~wa-storybook/styles/utils';\n\n.prices {\n  vertical-align: middle;\n  width: calc(100% - 125px);\n}\n\n.discounted-price,\n.price {\n  font-weight: 600;\n  font-size: rem(20);\n  display: inline-block;\n}\n\n.discounted-price {\n  color: color(green600);\n  margin-right: 5px;\n}\n\n.price {\n  color: color(neutral60);\n  text-decoration: line-through;\n}\n\n.vat {\n  font-size: rem(14);\n  color: color(neutral75);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prices": `discounted-price-module__prices___svByj`,
	"discounted-price": `discounted-price-module__discounted-price___gLsqN`,
	"price": `discounted-price-module__price___A1Rv6`,
	"vat": `discounted-price-module__vat___DCs70`
};
export default ___CSS_LOADER_EXPORT___;
