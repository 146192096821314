import ReactDOM from 'react-dom/client';
import queryString from 'query-string';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

// Shared utilities
import { AppLogger, AuthClient, AuthEvents } from '@workivate/tho-web-shared';
import GoogleAnalytics from 'services/google-analytics/analytics';

// Globals
import * as cookieManager from 'globals/cookieManager';
import { getRegionalApiEndpoints, lookupKeys } from 'globals/regional-api';
import { testableFeaturesEnabled, setTestableFlag } from 'globals/testable';
import waFetch from 'globals/wa-fetch';
import * as Storage from 'globals/storage';
import { isLocalStorageSupported } from 'globals/storage';
import { initiliaseWondering } from 'globals/wondering/helpers';
import { calculateResponsiveState } from 'redux-responsive';
import { ExperimentsProvider } from 'globals/experiments/experiments';
import Language from 'language';
import { initialiseLogin } from 'globals/fetch.helpers';

// App-specific utilities
import {
  getMsTeamsUrl,
  setSubDomainCookie,
  handleSubdomainRedirect,
  setJwtCookie,
  getSubdomainCookie,
} from 'app/state/session/helpers';
import { runOnWebWorker } from 'app/run-on-web-worker';
import { getStore, initializeStore } from 'app/hermes-redux';

// Components
import Spinner from 'wa-storybook/components/general/spinner/spinner';
import { showToast } from 'react-components/toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';
import { getStoredSelectedEnvironment } from 'react-components/env-controller/env-controller-context';

// State management
import { createSubscription } from 'hermes-redux.helpers';
import rootSagas from './state/sagas';
import {
  getSubdomainFromHost,
  history,
  getSuffixFromHost,
  getSuffixFromHref,
} from 'router/navigation';
import { RouterStateProvider } from 'router/router-state-provider';
import * as CompanyActions from 'stores/company-store';
import { appLogger } from '@workivate/tho-web-shared/src/appLogger/appLogger';
import isEmpty from 'lodash/isEmpty';

const container = document.getElementById('react-view');

if (!container) {
  throw new Error('Could not find the react container');
}

const renderRoot = ReactDOM.createRoot(container);
const experimentsObject = experiments;

// --------------------------------------------------------------------------------------------
// Read URL parameters
//
const urlParams = queryString.parse(location.search, {
  parseBooleans: true,
});

const isOnLocalhost = window.origin.includes('localhost');

const uslOTT: string = urlParams['x-usl-ott'];
const tenancyHashParam: string = urlParams['tenancy_hash'];

// These parameters are only set when we are running the project locally
// due to restrictions on setting cookies through localhost
// localhost subdomains are treated as different domains and don't allow cookies to be shared
const idTokenParam = urlParams['idToken'];
const refreshTokenParam = urlParams['refreshToken'];
const clientIdParam = urlParams['clientId'];

const jwtCookie = JSON.parse(cookieManager.getCookie('JWT_COOKIE') as string);

export const handleRedirect = (
  companySubdomain: string,
  isPostSignup = false,
  path?: string,
  isOauth2 = false,
) => {
  // After signup we want to redirect without any url path otherwise we'll end up back
  // on the signup page rather than logging in.
  const suffix = isPostSignup ? getSuffixFromHost() : getSuffixFromHref();
  const persistence = Storage.getPersistence();

  const msTeamsUrl = getMsTeamsUrl();
  const storedEnvironment = getStoredSelectedEnvironment();

  setSubDomainCookie(companySubdomain);

  const hasQueryParams = /[?&]/.test(suffix);

  const queryParams: string[] = [];

  if (persistence) {
    queryParams.push(`persistence=${persistence}`);
  }

  if (Language.getLoginLanguagePersistence()) {
    queryParams.push(`lang=${Language.getLocale()}`);
  }

  if (msTeamsUrl) {
    queryParams.push(`msTeamsReturnUrl=${msTeamsUrl}`);
  }

  if (storedEnvironment) {
    queryParams.push(`setEnvironment=${storedEnvironment}`);
  }

  if (isOauth2) {
    appLogger.logFeature('oauth2', 'isOauth2 true redirecting to subdomain');
    queryParams.push(`idToken=${AuthClient.idToken.jwtString}`);
    queryParams.push(`refreshToken=${AuthClient.refreshToken}`);
    queryParams.push(`clientId=${AuthClient.clientId}`);
  }

  // Client-side redirect to the correct subdomain.
  const redirectUrl = `//${companySubdomain}${suffix}${path || ''}${
    hasQueryParams ? '&' : '?'
  }${queryParams.join('&')}`;

  appLogger.logFeature('oauth2', 'redirectUrl', redirectUrl);

  if (window.confirm(`Do you want to redirect to ${companySubdomain}`)) {
    // Delete the JWT_COOKIE Cookie as we can login from a generic subdomain and don't need the redirect yet
    // There is no way to delete a cookie, so we set it to expire in the past
    document.cookie =
      'JWT_COOKIE=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    AuthClient.deleteAuthSession();
    // Check behaviour when the client id is still the same in app generic subdomain
    window.location.href = redirectUrl;
  }
};
/**
 * Logs in from an existing JWT token
 * For development purposes we use the query params to set the JWT token
 * othwerwise we use the JWT_COOKIE cookie
 */
function loginFromExistingToken(
  idToken: string,
  refreshToken: string,
  clientId: string,
) {
  AppLogger.log('Setting AuthClient tokens and client id');
  AuthClient.setTokens({ idToken, refreshToken });
  AuthClient.clientId = clientId;
  return AuthClient.checkIfUserIsAuthenticated();
}

// There is an existing idToken
export const loginFromJwt = async () => {
  const isOnGenericSubdomain =
    getSubdomainFromHost() === 'app' || getSubdomainFromHost() === 'login';

  // Determine which tokens we should use
  // If running on localhost we need to check for query params
  // Otherwise we can use the JWT_COOKIE cookie
  const jwtCookie = JSON.parse(cookieManager.getCookie('JWT_COOKIE') as string);
  let effectiveIdToken, effectiveRefreshToken, effectiveClientId;

  if (isOnLocalhost && idTokenParam && refreshTokenParam && clientIdParam) {
    effectiveIdToken = idTokenParam;
    effectiveRefreshToken = refreshTokenParam;
    effectiveClientId = clientIdParam;
  }

  if (jwtCookie?.idToken && jwtCookie?.refreshToken && jwtCookie?.clientId) {
    appLogger.logFeature(
      'oauth2',
      'jwtCookie present, setting idToken, refreshToken and clientId',
    );
    effectiveClientId = jwtCookie.clientId;
    effectiveIdToken = jwtCookie.idToken;
    effectiveRefreshToken = jwtCookie.refreshToken;
  }

  // If we are on a generic subdomain or on the wrong company subdomain
  // we need to make an auth/mobile request to get the correct company subdomain and redirect to it
  if (isOnGenericSubdomain) {
    const headers = {
      Accept: 'application/vnd.wam-api-v1.3+json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${effectiveIdToken}`,
    };

    const authMobileResponse = await waFetch(
      `${getRegionalApiEndpoints().waapi_root}/auth/mobile`,
      {
        method: 'post',
        headers,
      },
      undefined,
      true,
      false,
    );

    const company = authMobileResponse.body?.company;
    const companySubdomain = company?.wa_subdomain;
    const subdomain = getSubdomainFromHost();

    // If we aren't on the right company subdomain we need to redirect
    if (companySubdomain && subdomain !== companySubdomain) {
      // Before we redirect we need to delete the JWT_COOKIE
      setSubDomainCookie(company.wa_subdomain);
      handleRedirect(companySubdomain, false, '', true);
    }
  } else {
    const idToken = await loginFromExistingToken(
      effectiveIdToken,
      effectiveRefreshToken,
      effectiveClientId,
    );
    if (idToken) {
      initialiseHermes();
    }
  }
};

const authenticateWithOTT = async ott => {
  await AuthClient.register('web', true);
  const authResponse = await AuthClient.authorizeWithOTT(ott);

  const { tenancy_hash } = authResponse;
  if (tenancy_hash) {
    localStorage.setItem(lookupKeys.tenancyHash, JSON.stringify(tenancy_hash));
  }

  initialiseHermes();
};

const storeTenancyHash = () => {
  // Attempt to parse the tenancyHash and check that it's an array of strings
  try {
    const tenancyHashArray: string[] = JSON.parse(
      tenancyHashParam.replace(/'/g, '"'),
    );
    if (
      !Array.isArray(tenancyHashArray) ||
      !tenancyHashArray.every(element => typeof element === 'string')
    ) {
      throw new Error('tenancyHash must be an array of strings');
    }
    localStorage.setItem(
      lookupKeys.tenancyHash,
      JSON.stringify(tenancyHashArray),
    );
  } catch (error) {
    AppLogger.error('Failed to parse (incorrect format) tenancyHash:', error);
    throw error;
  }
};
const addListenerForViewportBreakpointsUpdateTrigger = store => {
  let previousFontSize = '16px';

  const viewportCheck = () => {
    const htmlElement = document.getElementsByTagName('html')[0] as HTMLElement;
    const computedFontSize = window
      .getComputedStyle(htmlElement, null)
      .getPropertyValue('font-size');

    if (computedFontSize !== previousFontSize) {
      store.dispatch(calculateResponsiveState(window));
      previousFontSize = computedFontSize;
    }
  };

  setInterval(viewportCheck, 1500);
};

function renderLoadingSpinner() {
  renderRoot.render(<Spinner top='calc(50% - 240px)' />);
}

export async function initPreLoginCompany() {
  // app and login subdomain will never have company details
  // so we don't need to make a request.
  if (getSubdomainFromHost() === 'app' || getSubdomainFromHost() === 'login') {
    return CompanyActions.setCompany({});
  }
  try {
    await CompanyActions.setPreLoginCompanyDetails();
  } catch {
    CompanyActions.setCompany({});
    getStore().dispatch(
      showToast({
        type: ToastType.ERROR,
        message: polyglot.t('api_errors.something_wrong'),
      }),
    );
  }
}

export function renderHermes() {
  import(/* webpackPreload: true */ 'pages/hermes/hermes').then(
    ({ default: Hermes }) => {
      // rendering main component

      renderRoot.render(
        // @ts-ignore
        <HistoryRouter history={history}>
          <RouterStateProvider>
            <ExperimentsProvider experiments={experimentsObject}>
              <Hermes experiments={experimentsObject} />
            </ExperimentsProvider>
          </RouterStateProvider>
        </HistoryRouter>,
      );
    },
  );
}

export async function initialiseHermes() {
  renderLoadingSpinner();
  initiliaseWondering();

  const isOnLocalhost = window.origin === 'https://app.localhost:3000';
  const isOnGenericSubdomain =
    getSubdomainFromHost() === 'app' || getSubdomainFromHost() === 'login';

  if (isOnGenericSubdomain && getSubdomainCookie()) {
    // On Localhost we need to ask if the developer wants to redirect
    if (isOnLocalhost) {
      if (window.confirm('Company subdomain cookie present- Redirect Now?')) {
        await handleSubdomainRedirect();
      }
    } else {
      await handleSubdomainRedirect();
    }
  }

  if (!isLocalStorageSupported()) {
    (window as Window).location = '/private-browsing';
    return;
  }

  if (window.WAM.ENV.maintenance) {
    (window as Window).location = '/maintenance';
    return;
  }

  // Allow testable flag to be disabled by query string.
  const { enableTestableFeatures = testableFeaturesEnabled() } =
    queryString.parse(location.search);

  setTestableFlag(JSON.parse(enableTestableFeatures as string));

  const store = initializeStore();
  store.runSaga(rootSagas);

  // Register state listeners
  createSubscription(
    [
      'session.user.anon_user_id',
      'session.user.birthday_date',
      'session.user.gender',
      'session.user.account_type',
      'session.user.originator',
      'session.user.role',
    ],
    GoogleAnalytics.setUser,
  )(store);

  createSubscription(
    ['company.company_id', 'company.name', 'company.demo', 'company.internal'],
    GoogleAnalytics.setCompanyProperties,
  )(store);

  localStorage.setItem('authenticationMethod', 'oauth2');

  // Listen to all AuthClient events
  AuthEvents.subscribe('*', async (event, data) => {
    AppLogger.log(`[AUTHCLIENT] Received event ${event} with data`, data);
    if (event === 'auth:signIn') {
      // When the signIn event is received, we need to initialise the login process
      try {
        await initialiseLogin();
      } catch (error) {
        AppLogger.error(
          'Error in initialiseLogin from AuthEvents subscription',
          error,
        );
      }
    }
  });

  try {
    // if we have a JWT_COOKIE cookie then attempt to initialise the login process with the stored token
    const jwt_cookie = await JSON.parse(
      cookieManager.getCookie('JWT_COOKIE') as string,
    );

    if (jwt_cookie) {
      const { idToken, refreshToken } = jwt_cookie;

      AuthClient.setTokens({
        idToken: idToken,
        refreshToken: refreshToken,
      });

      AuthClient.clientId = jwt_cookie.clientId;
    }
    const idToken = await AuthClient.checkIfUserIsAuthenticated();
    if (idToken) {
      // If we are on a generic subdomain and the token is valid
      if (isOnGenericSubdomain) {
        await setJwtCookie({
          idToken: AuthClient.idToken.jwtString,
          refreshToken: AuthClient.refreshToken,
          clientId: AuthClient.clientId,
        });

        await loginFromJwt();
      } else {
        // If we are on the right subdomain, we can initialise the login process
        await initialiseLogin();
      }
    }
  } catch (error) {
    AppLogger.error('Error in checkIfUserIsAuthenticated', error);
  }

  await Language.set();
  runOnWebWorker(initPreLoginCompany);
  Language.loadTranslations(Language.getLocale(), true);

  //set interval check for updating viewport break points on text-only zoom
  addListenerForViewportBreakpointsUpdateTrigger(store);
  renderHermes();
}

// Determines which USL login method to use
export const runHermesWithUSL = async () => {
  const hasUrlParams = !isEmpty(urlParams);
  const isJwtAuthPresentLocalHost =
    isOnLocalhost && idTokenParam && refreshTokenParam && clientIdParam;

  const jwtAuthPresent = !!jwtCookie || !!isJwtAuthPresentLocalHost;

  const uslOttPresent = !!uslOTT;
  const auth = JSON.parse(cookieManager.getCookie('WAM_AUTH') as string);

  const userToken =
    (hasUrlParams && urlParams['userToken']) ||
    (!isEmpty(auth) && auth.wamToken);

  if (tenancyHashParam) {
    storeTenancyHash();
  }

  if (jwtAuthPresent) {
    loginFromJwt();
  } else if (uslOttPresent) {
    authenticateWithOTT(uslOTT);
  } else if (userToken && urlParams['ott']) {
    authenticateWithOTT(userToken);
    // This is the legacy login path that checks for an existing wamToken that is fully qualified
    // or an ott as indicated by the ott param
    // for now we only handle the ott param: and we haven't determined how the passing of a fully qualified wamToken should be handled
  } else {
    initialiseHermes();
  }
};
