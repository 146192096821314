import USER_ROLES from 'constants/user-roles.constant';

const searchModule = {
  search: {
    name: 'search',
    url: '/search/:tab',
    resolver: () =>
      import(/* webpackChunkName: "searchResults" */ './search-results'),
    data: {
      authorizedRoles: [USER_ROLES.all],
      title: 'search.results.title',
      hideFooter: true,
    },
  },
};
export default searchModule;
