import type { Company } from './types';
import { getUser } from 'app/stores/user-store';
import { setExperimentAttributes } from 'globals/experiments/experiments';

export const setCompany = (company: Company) => {
  // some calls return company.id, others company.company_id
  // e.g. tenant returns company_id
  // while https://api.test.lifeworks.com/companies/ returns id
  // some functions expect company.id, others company.company_id
  // so make sure we populate company.company_id with company.id if it's missing
  const companyId = company.company_id ?? company.id;

  // enable A/B experiments to target users by company
  setExperimentAttributes({
    id: getUser().anon_user_id,
    companyName: company.name,
    companyId,
  });

  const companyWithCompanyId = {
    ...company,
    company_id: companyId,
  };

  return { type: 'COMPANY/SET_COMPANY', company: companyWithCompanyId };
};
