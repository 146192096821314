import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(
      /* webpackChunkName: "dependant-accounts" */ './view/dependant-accounts-comp'
    ),
    import(/* webpackChunkName: "dependant-accounts" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('dependantAccounts', reducer.dependantAccountsReducer);
    return component;
  });
}

const dependantAccountsModule = {
  name: 'dependantAccounts',
  url: '/dependant-accounts',
  resolver,
  restrictions: {
    user: [restrictions['eap_dependant_accounts']],
  },
  data: {
    title: 'components.main_menu.dependant_accounts',
    mainMenu: 'dependantAccounts',
    section: 'myAccount',
    includeInMenu: false,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default dependantAccountsModule;
