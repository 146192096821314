import type { NetworkStatusModel, NetworkStatusAction } from './types';

export const initialState = {
  isOnline: true,
} as const;

export function networkStatusReducer(
  state: NetworkStatusModel = initialState,
  action: NetworkStatusAction,
): NetworkStatusModel {
  switch (action.type) {
    case 'NETWORK_STATUS/SET_STATUS':
      return {
        isOnline: action.isOnline,
      };
    default:
      return state;
  }
}
