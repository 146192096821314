// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** function to get dimensions
  usage example to get \`tablet\` value from \`\$page-max-widths\`
  .class {
    max-width: dim(\$page-max-widths, tablet);
  }

 */
.code-comp-module__title___yQFvq {
  font-size: 1rem;
  color: var(--neutral75, #424547);
  margin-top: 20px;
}

.code-comp-module__codeContainer___UyBoe {
  margin-top: 5px;
  height: 50px;
  width: 100%;
  text-align: center;
  border: solid 1px var(--neutral75, #424547);
  background-color: var(--base, #f4f4f7);
  border-radius: 5px;
  color: var(--primary600, #4b286d);
  font-size: 26px;
}
.code-comp-module__codeContainer___UyBoe:focus {
  outline: var(--focus, #2681e1) solid 2px;
  outline-offset: 3px;
}

.code-comp-module__code___RpPGU {
  height: 50px;
  line-height: 50px;
}

.code-comp-module__instruction___jfZMQ {
  margin-top: 5px;
  font-size: 1rem;
  color: var(--neutral75, #424547);
}`, "",{"version":3,"sources":["webpack://./node_modules/wa-storybook/styles/tools/functions/_widths.scss","webpack://./client/app/react-components/code-comp/code-comp.module.scss","webpack://./node_modules/wa-storybook/styles/tools/mixins/_focus-outline.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;ACEA;EACE,eAAA;EACA,gCAAA;EACA,gBAAA;AAMF;;AAHA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,2CAAA;EACA,sCAAA;EACA,kBAAA;EACA,iCAAA;EACA,eAAA;AAMF;AChBI;EACE,wCAAA;EACA,mBAAA;ADkBN;;AANA;EACE,YAAA;EACA,iBAAA;AASF;;AANA;EACE,eAAA;EACA,eAAA;EACA,gCAAA;AASF","sourcesContent":["/** function to get dimensions\n  usage example to get `tablet` value from `$page-max-widths`\n  .class {\n    max-width: dim($page-max-widths, tablet);\n  }\n\n */\n\n@function dim($prop, $key) {\n  @return map-get($prop, $key);\n}\n","@import '~wa-storybook/styles/utils.scss';\n\n.title {\n  font-size: rem(16);\n  color: color(neutral75);\n  margin-top: 20px;\n}\n\n.codeContainer {\n  margin-top: 5px;\n  height: 50px;\n  width: 100%;\n  text-align: center;\n  border: solid 1px color(neutral75);\n  background-color: color(base);\n  border-radius: 5px;\n  color: color(primary600);\n  font-size: 26px;\n  @include focus-outline;\n}\n\n.code {\n  height: 50px;\n  line-height: 50px;\n}\n\n.instruction {\n  margin-top: 5px;\n  font-size: rem(16);\n  color: color(neutral75);\n}\n","@import '../functions/color';\n\n@mixin focus-outline($color: color(focus), $applyDirectly: false) {\n  @if $applyDirectly {\n    outline: $color solid 2px;\n    outline-offset: 3px;\n  } @else {\n    &:focus {\n      outline: $color solid 2px;\n      outline-offset: 3px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `code-comp-module__title___yQFvq`,
	"codeContainer": `code-comp-module__codeContainer___UyBoe`,
	"code": `code-comp-module__code___RpPGU`,
	"instruction": `code-comp-module__instruction___jfZMQ`
};
export default ___CSS_LOADER_EXPORT___;
