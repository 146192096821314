import fetch from 'globals/wa-fetch';
import { goTo } from 'router/navigation';
import type { Dispatch } from 'globals/types';
import type { CinemasPayloadResponse } from './types';

function getCinemasPayload(): Promise<CinemasPayloadResponse> {
  return fetch('/primary-features/benefit_cinema', {
    version: 1.5,
    token: true,
  });
}

export function fetchCinemasPayload() {
  return function (
    dispatch: Dispatch,
  ): Promise<CinemasPayloadResponse> | undefined {
    dispatch({ type: 'CINEMAS/FETCH_PAYLOAD' });
    return getCinemasPayload().then(
      (response: CinemasPayloadResponse) =>
        dispatch({ type: 'CINEMAS/FETCH_PAYLOAD_SUCCESS', response }),
      () => goTo('cinemasError'),
    );
  };
}

export const reset = () => ({ type: 'CINEMAS/RESET' });
