import { generateActions } from 'redux-dryer';
import { GiftCardOrder } from '../../gift-cards/gift-cards.types';
import { RewardsTransactions, Fund } from './types';

const Actions = {
  fetchSpotRewardSuccess: (fund: Fund) => ({ fund }),
  fetchSpotRewardFailure: () => ({ fund: {} }),
  fetchFundTransactionsSuccess: (transactions: RewardsTransactions) => ({
    transactions,
  }),
  fetchYourRewardsSuccess: (rewards: GiftCardOrder) => ({ rewards }),
  isFetching: (isFetching: boolean) => ({ isFetching }),
} as const;

export default generateActions(Actions, 'spotRewards');
