import { object, string } from 'yup';

export const stepNumbers = Object.freeze({
  downloadTheApp: 1,
  scanTheQRCode: 2,
  enterVerificationCode: 3,
});

export const firstStepNumber = stepNumbers.downloadTheApp;

export const secondStepNumber = stepNumbers.scanTheQRCode;

export const amountOfSteps = Object.keys(stepNumbers).length;

export const emptyValueLength = 0;

export const verificationCodeLength = 6;

export const verificationCodeFieldName = 'verification_code';

export const numbersOnlyRegex = /^[0-9]+$/;

export const initialVerificationCodeValue = '';

export const initialFormValues = Object.freeze({
  [verificationCodeFieldName]: initialVerificationCodeValue,
});

export const formValidationSchema = Object.freeze(
  object().shape({
    [verificationCodeFieldName]: string()
      .required()
      .matches(numbersOnlyRegex)
      .min(verificationCodeLength)
      .max(verificationCodeLength),
  }),
);

export const mfaChallengeNames = Object.freeze({
  MFA_SETUP: 'MFA_SETUP',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
});
