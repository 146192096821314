// Helper functions and wrapper components for AB testing / experiments
// We are currently using GrowthBook as our experiments platform
// this can be changed here

import { testableFeaturesEnabled } from 'globals/testable';
import {
  GrowthBook,
  GrowthBookProvider,
  useFeatureIsOn,
} from '@growthbook/growthbook-react';
import type { AppFeatures } from './app-features';
import { AnalyticsEvent } from 'services/google-analytics/analytics-events';
import { ReactNode, useEffect } from 'react';

type AppFeatureKeys = keyof AppFeatures;

export const isExperimentsEnabled = () => testableFeaturesEnabled();

// configure AB Testing / Experiments
// clientKey is not secret; it is sent in api calls.
export type Experiments = GrowthBook<AppFeatures>;

export const initialiseExperiments = () =>
  isExperimentsEnabled()
    ? new GrowthBook<AppFeatures>({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: 'sdk-2ir3TSz8XWIwlBf7',
        enableDevMode: true,
        trackingCallback: (experiment, result) => {
          // optional chaining is required because experiment, result are undefined in test,
          console.log(
            '*** Experiment Viewed. experimentId:',
            experiment?.key,
            'variationId:',
            result?.key,
          );

          AnalyticsEvent.Session.Experiment.assigned(
            'experiment',
            experiment?.key,
            result?.key,
          );
        },
      })
    : undefined;

window.experiments = initialiseExperiments();

type SetExperimentAttributes = {
  id?: string;
  companyName?: string;
  companyId?: string;
};

export const setExperimentAttributes = ({
  id,
  companyName,
  companyId,
}: SetExperimentAttributes) => {
  if (isExperimentsEnabled() && window.experiments?.setAttributes) {
    window.experiments.setAttributes({
      id,
      companyName,
      companyId,
    });
  }
};

type ExperimentsProviderProps = {
  children: ReactNode;
  experiments: GrowthBook<AppFeatures> | undefined;
};

export const ExperimentsProvider = ({
  children,
  experiments,
}: ExperimentsProviderProps) => {
  if (isExperimentsEnabled()) {
    return (
      <GrowthBookProvider growthbook={experiments}>
        {children}
      </GrowthBookProvider>
    );
  } else {
    return <>{children}</>;
  }
};

// For use in function components
export const useExperimentsIsFeatureOn = (featureName: AppFeatureKeys) => {
  const featureIsOn = useFeatureIsOn(featureName);

  return isExperimentsEnabled() ? featureIsOn : false;
};

// For use in class components where hooks can't be used
type IsFeatureOnProps = {
  featureName: AppFeatureKeys;
  setIsFeatureOn: (isFeatureOn: boolean) => void;
};

export const ExperimentsIsFeatureOn = ({
  featureName,
  setIsFeatureOn, // callback function to set value in parent component state
}: IsFeatureOnProps) => {
  const isFeatureOn = useExperimentsIsFeatureOn(featureName);

  useEffect(() => {
    setIsFeatureOn(isFeatureOn);
  }, [isFeatureOn]);
  return <></>;
};
