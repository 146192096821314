import { getUser, isSharedAccount } from 'stores/user-store';
import { goTo } from 'router/navigation';

import { restrictions } from 'state/features-list/constants';

import USER_ROLES from 'constants/user-roles.constant';

const redirect = (url, target) =>
  window.open(url, target, 'noopener noreferrer');

const chatUrl = () => getUser()['effective_settings']['urls']['first_chat'];

const chatModule = {
  name: 'chat',
  url: '/chat',
  resolver: () =>
    isSharedAccount() ? goTo('limitedAccount') : redirect(chatUrl(), '_self'),
  restrictions: {
    user: [restrictions['first_chat']],
  },
  type: 'dynamicLink',
  icon: true,
  onClick: () =>
    isSharedAccount() ? goTo('limitedAccount') : redirect(chatUrl(), '_blank'),
  data: {
    title: 'components.main_menu.chat',
    mainMenu: 'chat',
    section: 'life',
    includeInMenu: true,
    authorizedRoles: [USER_ROLES.employee],
  },
} as const;

export default chatModule;
