import { connectWithViewport } from 'app/hermes-redux';
import { getCurrentState, getUrlParameter } from 'router/navigation';
import type { AppState, Dispatch } from 'globals/types';
import type { Model as SessionModel } from 'state/session/types';
import GiftCardsHubPage from './../../gift-cards/hub/view/hub-page';
import GiftCardsDetailsPage from './../../gift-cards/details/view/details-page';
import GiftCardsErrorPage from './../../gift-cards/shared/error-page';
import SpotRewardsHubPage from './spot-rewards';
import type { Model as GiftCardsHubModel } from './../../gift-cards/hub/state/types';
import type { Model as GiftCardsDetailsModel } from './../../gift-cards/details/state/types';

type Props = {
  dispatch: Dispatch;
  hub?: GiftCardsHubModel;
  details?: GiftCardsDetailsModel;
  session?: SessionModel;
};

export const referrer = 'spotRewards';

class SpotRewards extends React.Component<Props> {
  static displayName = 'SpotRewards';

  renderSpotRewards(name: string) {
    const categoryId = getUrlParameter('categoryId');
    const spotRewards = {
      spotRewards: <SpotRewardsHubPage {...this.props} />,
      spotRewardsCodes: (
        <SpotRewardsHubPage {...this.props} showCodes referrer={referrer} />
      ),
      spotRewardsPopular: (
        // @ts-expect-error - TS2322 - Type '{ categoryId: any; referrer: string; dispatch: Dispatch; hub?: Model | undefined; details?: Model | undefined; session?: Model | undefined; }' is not assignable to type 'Omit<Props, "resetGiftCardsPaging" | "resetDiningCardsPaging">'.
        <GiftCardsHubPage
          {...this.props}
          categoryId={categoryId}
          referrer={referrer}
        />
      ),
      spotRewardsCategory: (
        // @ts-expect-error - TS2322 - Type '{ categoryId: any; referrer: string; dispatch: Dispatch; hub?: Model | undefined; details?: Model | undefined; session?: Model | undefined; }' is not assignable to type 'Omit<Props, "resetGiftCardsPaging" | "resetDiningCardsPaging">'.
        <GiftCardsHubPage
          {...this.props}
          categoryId={categoryId}
          referrer={referrer}
        />
      ),
      spotRewardsDetails: (
        // @ts-expect-error - TS2322 - Type '{ referrer: string; dispatch: Dispatch; hub?: Model | undefined; details?: Model | undefined; session?: Model | undefined; }' is not assignable to type 'Readonly<Props>'.
        <GiftCardsDetailsPage {...this.props} referrer={referrer} />
      ),
      spotRewardsGiftcards: (
        // @ts-expect-error - TS2322 - Type '{ referrer: string; dispatch: Dispatch; hub?: Model | undefined; details?: Model | undefined; session?: Model | undefined; }' is not assignable to type 'Omit<Props, "resetGiftCardsPaging" | "resetDiningCardsPaging">'.
        <GiftCardsHubPage {...this.props} referrer={referrer} />
      ),
    } as const;

    return spotRewards[name] || <GiftCardsErrorPage referrer={referrer} />;
  }

  render() {
    return this.renderSpotRewards(getCurrentState().name);
  }
}

export default connectWithViewport(SpotRewards, (state: AppState) => {
  const {
    giftcards: { hub, details },
    spotRewards,
    // get amount as 'balance' so it make sense in our components
    spotRewards: {
      fund: {
        balance: { amount },
      },
    },
  } = state;

  const name = getCurrentState().name;
  switch (name) {
    case 'spotRewardsError':
      return {};
    case 'spotRewards':
      return { spotRewards };
    case 'spotRewardsDetails':
      return { details, balance: amount / 100 };
    default:
      return { hub };
  }
});
