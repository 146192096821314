import USER_ROLES from 'constants/user-roles.constant';

import { restrictions } from 'state/features-list/constants';

const needHelpModule = {
  name: 'needHelp',
  url: '/need-help',
  resolver: () => import(/* webpackChunkName: "need-help" */ './need-help'),
  restrictions: {
    user: [restrictions['eap_general_enquiry']],
  },
  data: {
    title: 'components.main_menu.need_help',
    mainMenu: 'needHelp',
    section: 'life',
    includeInMenu: true,
    order: 2,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default needHelpModule;
