export const SIGNUP_CODES = Object.freeze({
  INDIVIDUAL_INVITATION_BY_ADMIN_PANEL: 1,
  INDIVIDUAL_INVITATION_BY_ADMIN_PANEL_COMPLETE: 2,
  INDIVIDUAL_INVITATION_BY_USER: 3,
  INDIVIDUAL_INVITATION_BY_USER_COMPLETE: 4,
  INVITATION_CODE: 20,
  INVITATION_CODE_COMPLETE: 21,
  INDIVIDUAL_INVITATION_DETAILS: 22,
  INDIVIDUAL_INVITATION_DETAILS_COMPLETE: 23,
  GENERIC_INVITATION_DETAILS: 24,
  GENERIC_INVITATION_DETAILS_COMPLETE: 25,
  INDIVIDUAL_INVITATION_DETAILS_WEB_COMPLETE: 30,
  GENERIC_INVITATION_DETAILS_WEB_COMPLETE: 31,
  EXTENDED_DETAILS_WEB_APP: 32,
  EXTENDED_DETAILS_WEB_APP_COMPLETE: 33,
  ARCH_FIRST_SUPER_ADMIN_USER_COMPLETE: 40,
  SSO_SIGNUP_PROVISIONED: 50,
  INTERESTS: 101,
  INTERESTS_COMPLETE: 102,
  ADMIN_CONFIRMATION_REQUIRED: 103,
  ADMIN_CONFIRMATION_COMPLETE: 104,
  MFA_SETUP_REQUIRED: 111,
  SIGNUP_COMPLETE: 200,
  INDIVIDUAL_INVITATION_FROM_CSV_EMPLOYEE_ID: 1001,
  INDIVIDUAL_INVITATION_FROM_CSV_EMPLOYEE_ID_COMPLETE: 1002,
  SYSTEM_INVITATION_FROM_UUID: 1003,
  SYSTEM_INVITATION_FROM_UUID_COMPLETE: 1004,
});

export const INVITE_TYPES = {
  PERSONAL_INVITATION_CODE: 1,
  GENERIC_INVITATION_CODE: 2,
};

export const SIGN_UP_MODULE_NAME = 'signup';
