import USER_ROLES from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';
import { injectAsyncReducer } from 'app/hermes-redux';

const resolver = () =>
  Promise.all([
    import(/* webpackChunkName: "cinemas" */ './cinemas-comp'),
    import(/* webpackChunkName: "cinemas" */ './cinemas.reducers'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('cinemas', reducer.default);
    return component;
  });

const cinemasModule = {
  hub: {
    name: 'cinemas',
    url: '/cinemas',
    resolver,
    restrictions: {
      user: [restrictions['benefit_cinema']],
    },
    data: {
      title: 'components.main_menu.cinemas',
      mainMenu: 'cinemas',
      section: 'deals',
      includeInMenu: true,
      order: 5,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  details: {
    name: 'cinemasDetails',
    url: '/cinemas/:id',
    resolver,
    restrictions: {
      user: [restrictions['benefit_cinema']],
    },
    data: {
      title: 'components.main_menu.cinemas',
      mainMenu: 'cinemas',
      section: 'deals',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  codes: {
    name: 'cinemasCodes',
    url: '/cinemas/codes',
    resolver,
    restrictions: {
      user: [restrictions['benefit_cinema']],
    },
    data: {
      title: 'components.main_menu.cinemas',
      mainMenu: 'cinemas',
      section: 'deals',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  error: {
    name: 'cinemasError',
    url: '/cinemas/error',
    resolver,
    restrictions: {
      user: [restrictions['benefit_cinema']],
    },
    data: {
      title: 'components.main_menu.cinemas',
      mainMenu: 'cinemas',
      section: 'deals',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default cinemasModule;
