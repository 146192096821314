import type { Model, WellnessActions } from './types';
import { CATEGORY_VIEW } from './actions';

export const initialState = {
  newsfeed: {
    fetching: false,
    seriesFinished: false,
    categoryFinished: false,
    hideCompletionPost: false,
    skipReEngagement: false,
    cardType: 0,
    session: {
      current_post: null,
      re_engagement: null,
    },
    sessionCompletion: {
      category_finished: false,
      daily_limit_reached: false,
      completed: [],
      series_finished: false,
    },
  },
  session: {
    currentSession: null,
    fetching: false,
  },
  categories: {
    fetching: false,
    categories: [],
    step: CATEGORY_VIEW,
    selectedCategories: [],
    selectedSubcategories: [],
  },
};

const _removeFromList = (list: Array<string>, id: string): Array<string> =>
  list.filter((item: string) => item !== id);

export function wellnessReducer(
  state: Model = initialState,
  action: WellnessActions,
): Model {
  switch (action.type) {
    case 'WELLNESS/NEWSFEED/HIDE_COMPLETION_POST':
      return {
        ...state,
        newsfeed: Object.assign({}, state.newsfeed, {
          hideCompletionPost: true,
        }),
      };

    case 'WELLNESS/NEWSFEED/SKIP_RE_ENGAGEMENT':
      return {
        ...state,
        newsfeed: Object.assign({}, state.newsfeed, {
          skipReEngagement: true,
        }),
      };

    case 'WELLNESS/NEWSFEED/UPDATE_POST':
      return {
        ...state,
        newsfeed: Object.assign({}, state.newsfeed, {
          fetching: true,
        }),
      };

    case 'WELLNESS/NEWSFEED/UPDATE_POST_SUCCESS':
    case 'WELLNESS/NEWSFEED/UPDATE_POST_FAILURE':
      return {
        ...state,
        newsfeed: Object.assign({}, state.newsfeed, {
          fetching: false,
        }),
      };

    case 'WELLNESS/NEWSFEED/UPDATE_SERIES_FINISHED':
      return {
        ...state,
        newsfeed: Object.assign({}, state.newsfeed, {
          seriesFinished: action.status,
        }),
      };

    case 'WELLNESS/NEWSFEED/UPDATE_CATEGORY_FINISHED':
      return {
        ...state,
        newsfeed: Object.assign({}, state.newsfeed, {
          categoryFinished: action.status,
        }),
      };

    case 'WELLNESS/NEWSFEED/SET_POST':
      return {
        ...state,
        newsfeed: Object.assign({}, state.newsfeed, {
          cardType: action.post.card_type,
          session: action.post.wellness.session,
          sessionCompletion: action.post.wellness.session_completion,
        }),
      };

    case 'WELLNESS/SESSION/FETCH_SESSION':
      return {
        ...state,
        session: Object.assign({}, state.session, {
          fetching: true,
        }),
      };

    case 'WELLNESS/SESSION/FETCH_SESSION_SUCCESS':
      return {
        ...state,
        session: Object.assign({}, state.session, {
          fetching: false,
          currentSession: action.currentSession,
        }),
        newsfeed: Object.assign({}, state.newsfeed, {
          hideCompletionPost: false,
          skipReEngagement: false,
        }),
      };

    case 'WELLNESS/SESSION/FETCH_SESSION_FAILURE':
      return {
        ...state,
        session: Object.assign({}, state.session, {
          fetching: false,
        }),
      };

    case 'WELLNESS/CATEGORIES/SELECT_CATEGORY':
      return {
        ...state,
        categories: Object.assign({}, state.categories, {
          selectedCategories: state.categories.selectedCategories.concat(
            action.id,
          ),
        }),
      };

    case 'WELLNESS/CATEGORIES/DESELECT_CATEGORY':
      return {
        ...state,
        categories: Object.assign({}, state.categories, {
          selectedCategories: _removeFromList(
            state.categories.selectedCategories,
            action.id,
          ),
        }),
      };

    case 'WELLNESS/CATEGORIES/SELECT_SUBCATEGORY':
      return {
        ...state,
        categories: Object.assign({}, state.categories, {
          selectedSubcategories: state.categories.selectedSubcategories.concat(
            action.id,
          ),
        }),
      };

    case 'WELLNESS/CATEGORIES/DESELECT_SUBCATEGORY':
      return {
        ...state,
        categories: Object.assign({}, state.categories, {
          selectedSubcategories: _removeFromList(
            state.categories.selectedSubcategories,
            action.id,
          ),
        }),
      };

    case 'WELLNESS/CATEGORIES/FETCH_CATEGORIES':
      return {
        ...state,
        categories: Object.assign({}, state.categories, {
          fetching: true,
        }),
      };

    case 'WELLNESS/CATEGORIES/FETCH_CATEGORIES_SUCCESS':
      return {
        ...state,
        categories: Object.assign({}, state.categories, {
          fetching: false,
          categories: action.categories,
        }),
      };

    case 'WELLNESS/CATEGORIES/FETCH_CATEGORIES_FAILURE':
      return {
        ...state,
        categories: Object.assign({}, state.categories, {
          fetching: false,
        }),
      };

    case 'WELLNESS/CATEGORIES/CHANGE_STEP':
      return {
        ...state,
        categories: Object.assign({}, state.categories, {
          step: action.step,
        }),
      };

    case 'WELLNESS/CATEGORIES/RESET_SELECTION':
      return {
        ...state,
        categories: Object.assign({}, state.categories, {
          step: CATEGORY_VIEW,
          selectedCategories: action.isCategories
            ? []
            : state.categories.selectedCategories,
          selectedSubcategories: [],
        }),
      };

    case 'WELLNESS/CATEGORIES/RESET':
      return {
        ...state,
        categories: initialState.categories,
      };

    default:
      return state;
  }
}
