import type {
  TrustedAppsAndWebsitesState,
  TrustedAppsAndWebsitesActions,
} from './types';

const initialTrustedAppsAndWebsitesState = {
  isFetching: true,
  resources: {
    partners: [],
  },
};

export const trustedAppsAndWebsitesReducer = (
  state: TrustedAppsAndWebsitesState = initialTrustedAppsAndWebsitesState,
  action: TrustedAppsAndWebsitesActions,
) => {
  switch (action.type) {
    case 'TRUSTED_APPS_AND_WEBSITES/GET_PARTNERS':
      return Object.assign({}, state, {
        isFetching: true,
        resources: Object.assign({}, state.resources, action.resources),
      });
    case 'TRUSTED_APPS_AND_WEBSITES/GOT_PARTNERS':
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
};
