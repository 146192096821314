import type {
  Consent,
  ConsentCheckbox,
  ConsentList,
  ConsentType,
  FooterItem,
} from '../../state/consent/types';
import { getStore } from '../../hermes-redux';
import { isLoggedIn } from '../../state/session/helpers';
import { isSharedAccount } from '../../stores/user-store';
import { SIGN_UP_MODULE_NAME } from '../signup/constants';
import type { CheckConsentReturnObject, Module } from '../../router/types';
import { getLandingStateFromFeaturesList, goTo } from '../../router/navigation';
import { consentService } from './consent-service';
import {
  JIT_MANDATORY_MODULE_NAME,
  JIT_MODULE_NAME,
  SIGN_UP_CONSENT_MODULE_NAME,
} from './constants';
import { setRedirectionModel } from '../../state/consent/actions';
import { consentSettings } from '../settings/settings.constants';

const signUpConsentsModalPageBlacklist: Array<string> = [SIGN_UP_MODULE_NAME];
export const backgroundRefreshConsentsPageBlacklist: Array<string> = [
  SIGN_UP_CONSENT_MODULE_NAME,
  JIT_MODULE_NAME,
  consentSettings,
];

export type Destination = {
  name: string;
  params: {
    [key: string]: string;
  };
};

type JustInTimeRedirectionModel = {
  type: 'JUST_IN_TIME' | 'JUST_IN_TIME_MANDATORY';
  consentType: ConsentType;
  onCompleteGoTo: Destination;
  onExitGoTo: string | Destination | null;
};

type PopUpRedirectionModel = {
  type: 'POP_UP';
  onCompleteGoTo: Destination;
};

export type ConsentRedirectionModel =
  | JustInTimeRedirectionModel
  | PopUpRedirectionModel;

export const getConsentRedirectionModel = (
  newRoute: Module,
  previousRoute: Module | null,
  consents: ConsentList,
): ConsentRedirectionModel | null => {
  const showPopUpConsents = shouldShowConsentsToUserOnSignIn(
    consents,
    newRoute,
  );
  const originalDestination = {
    name: newRoute.name,
    params: newRoute.params,
  } as const;

  if (showPopUpConsents) {
    return {
      type: 'POP_UP',
      onCompleteGoTo: originalDestination,
    };
  }

  if (!newRoute.checkConsent || typeof newRoute.checkConsent !== 'function') {
    return null;
  }

  const JITConsentCheckResult = newRoute.checkConsent();

  if (!JITConsentCheckResult) {
    return null;
  }

  const consentToCheck = getConsentWithType(
    consents,
    JITConsentCheckResult.consentType,
  );

  if (!consentToCheck) {
    return null;
  }

  const consentHasFooters =
    consentToCheck.footers && consentToCheck.footers.length;

  if (
    consentHasFooters &&
    !consentAccepted(
      consentToCheck.footers || [],
      consentToCheck.mandatory || false,
    )
  ) {
    return {
      type: consentToCheck.mandatory
        ? 'JUST_IN_TIME_MANDATORY'
        : 'JUST_IN_TIME',
      consentType: JITConsentCheckResult.consentType,
      onCompleteGoTo: originalDestination,
      // If null the consent page will send the user to the landing page
      onExitGoTo: getJustInTimeConsentBackDestination(
        JITConsentCheckResult,
        previousRoute,
      ),
    };
  }

  return null;
};

export const getJustInTimeConsentBackDestination = (
  checkConsentResult: CheckConsentReturnObject,
  previousState: Module | null,
): string | Destination | null => {
  if (checkConsentResult.customBackLink) {
    return checkConsentResult.customBackLink;
  }

  if (previousState) {
    return {
      name: previousState.name,
      params: previousState.params,
    };
  }

  return getLandingStateFromFeaturesList();
};

export const getConsentWithType = (
  consents: ConsentList,
  type: ConsentType,
): Consent | null => {
  return consents.find(item => item['consent_type'] === type) || null;
};

export const shouldShowConsentsToUserOnSignIn = (
  consents: ConsentList,
  newRoute: Module,
): boolean => {
  const haveConsents = getConsentsToShowToUserDuringSignUp(consents).length > 0;

  if (!newRoute.name) {
    return false;
  }

  const routeIsBlockListed = signUpConsentsModalPageBlacklist.includes(
    newRoute.name,
  );

  return !!(
    haveConsents &&
    isLoggedIn() &&
    !isSharedAccount() &&
    !routeIsBlockListed
  );
};

export const getConsentsToShowToUserDuringSignUp = (
  consents: ConsentList,
): ConsentList => {
  return consents.filter(({ footers = [], signup, mandatory = false }) => {
    return !consentAccepted(footers, mandatory) && signup;
  });
};

export const getConsentsToShowToUserInSettings = (
  consents: Array<Consent>,
): Array<Consent> => {
  return consents.filter(consent => {
    const checkboxes = getAllCheckBoxes(consent.footers || []);
    return !consent.mandatory && !!checkboxes.length;
  });
};

export const consentAccepted = (
  footers: Array<FooterItem>,
  mandatory: boolean,
) => {
  for (let i = 0; i < footers.length; i++) {
    const footer = footers[i];

    if (footer?.type === 'checkbox') {
      const valueIsNone = footer.value === 'none';
      const invalidMandatory = mandatory && footer.value !== 'accepted';
      const consentMustStillBeAccepted = valueIsNone || invalidMandatory;

      if (consentMustStillBeAccepted) {
        return false;
      }
    }
  }

  return true;
};
export const getAllCheckBoxes = (
  footers: Array<FooterItem>,
): Array<ConsentCheckbox> => {
  return footers.filter(
    footer => footer.type === 'checkbox',
  ) as Array<ConsentCheckbox>;
};

export const getCheckboxesWithValue = (
  checkboxes: Array<ConsentCheckbox>,
  value: ConsentCheckbox['value'],
): Array<ConsentCheckbox> => {
  return checkboxes.filter(checkbox => checkbox.value === value);
};

export const performConsentRedirectionFlow = async (
  curentRoute: Module,
  previousRouter: Module | null,
  bypassCache = false,
): Promise<boolean> => {
  await consentService.loadConsentsData(bypassCache);

  const consents = getStore().getState().consent.consents;

  const consentRedirection = getConsentRedirectionModel(
    curentRoute,
    previousRouter || null,
    consents,
  );

  if (consentRedirection) {
    switch (consentRedirection.type) {
      case 'JUST_IN_TIME':
        if (curentRoute.name === JIT_MODULE_NAME) {
          break;
        }
        getStore().dispatch(setRedirectionModel(consentRedirection));
        goTo(JIT_MODULE_NAME);
        return true;
      case 'JUST_IN_TIME_MANDATORY':
        if (curentRoute.name === JIT_MANDATORY_MODULE_NAME) {
          break;
        }
        getStore().dispatch(setRedirectionModel(consentRedirection));
        goTo(JIT_MANDATORY_MODULE_NAME);
        return true;
      case 'POP_UP':
        if (curentRoute.name === SIGN_UP_CONSENT_MODULE_NAME) {
          break;
        }
        getStore().dispatch(setRedirectionModel(consentRedirection));
        goTo(SIGN_UP_CONSENT_MODULE_NAME);
        return true;
    }
  }

  return false;
};
