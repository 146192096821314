import { getCompany } from 'stores/company-store';
import fetch from 'globals/wa-fetch';
import filter from 'lodash/filter';

let activeGroups;

export function getActiveGroups(forceUpdate?: boolean) {
  if (activeGroups && !forceUpdate) {
    return Promise.resolve(activeGroups);
  }

  return fetch(`/companies/${getCompany()['id']}/groups?limit=10000`, {
    method: 'get',
    version: 1.4,
    token: true,
  }).then(resp => {
    activeGroups = filter(resp.body, group => group.state === 1);

    return activeGroups;
  });
}

export function getGroup(groupId: string) {
  return fetch(`/companies/${getCompany()['id']}/groups/${groupId}`, {
    method: 'get',
    version: 1.4,
    token: true,
  }).then(resp => resp.body);
}
