import { injectAsyncReducer } from 'app/hermes-redux';
import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import {
  selfAssessmentAssessment,
  selfAssessmentHistoricalResult,
  selfAssessmentResults,
  selfAssessmentsHub,
} from './self-assessment.constants';

function resolver() {
  const components = [
    import(/* webpackChunkName: "self-assessment" */ './view/self-assessment'),
    import(
      /* webpackChunkName: "thi-modal-reducer" */ 'react-components/thi-result/state/reducer'
    ),
  ];
  return Promise.all(components).then(([component, thiResult]) => {
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    injectAsyncReducer('thiResult', thiResult.default);
    return component;
  });
}

const selfAssessmentModule = {
  hub: {
    name: selfAssessmentsHub,
    url: '/assessments',
    resolver,
    restrictions: {
      user: [restrictions['eap_self_assessment']],
    },
    data: {
      order: 2,
      title: 'components.main_menu.self_assessment_2',
      mainMenu: 'selfAssessment',
      section: 'wellBeing',
      includeInMenu: true,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  assessment: {
    name: selfAssessmentAssessment,
    url: '/assessments/:assessment_id',
    resolver,
    restrictions: {
      user: [restrictions['eap_self_assessment']],
    },
    checkConsent: () => {
      return {
        consentType: 'assessments',
        customBackLink: selfAssessmentsHub,
      };
    },
    data: {
      mainMenu: 'selfAssessment',
      section: 'wellBeing',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  results: {
    name: selfAssessmentResults,
    url: '/assessments/:assessment_id/details',
    resolver,
    restrictions: {
      user: [restrictions['eap_self_assessment']],
    },
    data: {
      mainMenu: 'selfAssessment',
      section: 'wellBeing',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
      hideNavbar: true,
    },
  },
  historicalResult: {
    name: selfAssessmentHistoricalResult,
    url: '/assessments/:assessment_id/details/:archive_id',
    resolver,
    restrictions: {
      user: [restrictions['eap_self_assessment']],
    },
    data: {
      mainMenu: 'selfAssessment',
      section: 'wellBeing',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
      hideNavbar: true,
    },
  },
};

export default selfAssessmentModule;
