import { restrictions } from 'state/features-list/constants';

import USER_ROLES from 'constants/user-roles.constant';

const legalServicesModule = {
  name: 'legalServices',
  url: '/legal-services',
  resolver: () =>
    import(/* webpackChunkName: "legal-services" */ './legal-services-comp'),
  restrictions: {
    user: [restrictions['eap_legal_services']],
  },
  data: {
    title: 'components.main_menu.legal_services',
    mainMenu: 'legalServices',
    section: 'life',
    includeInMenu: true,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default legalServicesModule;
