import USER_ROLES from 'constants/user-roles.constant';
import { CareIssuesRoutesTypes } from './care-issues.constants';
import { shouldGrantAccessToCareIssues } from './care-issues.helpers';
import { restrictions } from 'state/features-list/constants';

export const createResolver = pageImport =>
  Promise.all([pageImport]).then(([component]) => {
    return component;
  });

const commonOptions = {
  restrictions: {
    user: [restrictions['care_plans']],
  },
  shouldGrantAccess: shouldGrantAccessToCareIssues,
  data: {
    title: 'care_issues.care_issues',
    includeInMenu: false,
    hideFooter: true,
    authorizedRoles: [USER_ROLES.employee],
  },
};

const careIssuesModule = {
  selectCareIssue: {
    name: CareIssuesRoutesTypes.SELECT_ISSUE,
    url: '/care-issues/select',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "care-issues-select-issue" */ './container/select-care-issue/select-care-issue'
        ),
      ),
    ...commonOptions,
  },
};

export default careIssuesModule;
