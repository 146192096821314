import fetch from 'globals/wa-fetch';
import type { BtNonce, OrderProps, PaymentMethodProps } from './types';

// TODO migrate to endpoints once backend completes their mvp

export const requestToken = () =>
  fetch(
    '/commerce/token',
    {
      method: 'GET',
      token: true,
      version: 'json',
    },
    null,
  );

export const submitOrder = (
  nonce: BtNonce,
  orderObj: OrderProps,
  email: string,
) => {
  const order = orderObj.items.map((i: any) => ({
    sku: i.sku,
    quantity: i.quantity,
    original_price: i.customizations.price.originalAmount,
    discounted_price: i.customizations.price.amount,
    currency: i.customizations.price.currency,
  }));
  return fetch(
    '/commerce/orders',
    {
      method: 'POST',
      token: true,
      version: 'json',
      body: {
        email,
        nonce: nonce.nonce,
        items: order,
        deviceData: nonce.deviceData,
        expected_price: {
          total: orderObj.total.amount,
          currency: orderObj.total.currency,
        },
      },
    },
    null,
  );
};

export const submitSpotRewardsOrder = (
  orderObj: OrderProps,
  paymentMethod: PaymentMethodProps,
  email: string,
) => {
  const order = orderObj.items.map((i: any) => ({
    sku: i.sku,
    quantity: i.quantity,
    customizations: {
      face_value: {
        amount: parseInt(i.customizations.price.originalAmount, 10),
        currency: i.customizations.price.currency,
      },
    },
  }));

  return fetch(
    '/orders',
    {
      method: 'POST',
      token: true,
      version: 'json',
      body: {
        email,
        items: order,
        expected_price: {
          amount: orderObj.total.originalAmount,
          currency: orderObj.total.currency,
        },
        payment_method: paymentMethod,
      },
    },
    null,
  );
};
