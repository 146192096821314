import styles from './carousel-arrow.module.scss';
import classNames from 'classnames';
import Chevron from 'wa-storybook/components/general/icon/components/chevron-left';

type Props = {
  isLeft: boolean;
  // All the props below are automatically generated and passed by react-slick
  onClick?: () => void;
  className?: string;
};

const CarouselArrow = (props: Props) => {
  const { isLeft, onClick, className } = props;

  // To not confuse SR users, the prev/next should not be tabbable on the first/last slide
  const isTabbable = className?.match(/slick-disabled/i) ? -1 : 0;

  return (
    <button
      className={classNames(
        styles['button'],
        styles['important'],
        isLeft ? styles['button--left'] : styles['button--right'],
      )}
      tabIndex={isTabbable}
      onClick={onClick}
      aria-label={
        isLeft
          ? polyglot.t('aria.previous_slide')
          : polyglot.t('aria.next_slide')
      }
    >
      <Chevron />
    </button>
  );
};
export default CarouselArrow;

CarouselArrow.displayName = 'CarouselArrow';
