import USER_ROLES from 'constants/user-roles.constant';

import { restrictions } from 'state/features-list/constants';

const generalEnquiryModule = {
  name: 'generalEnquiry',
  url: '/general-enquiry',
  resolver: () => import(/* webpackChunkName: "enquiry" */ './general-enquiry'),
  restrictions: {
    user: [restrictions['eap']],
  },
  data: {
    title: 'components.main_menu.general_enquiry',
    mainMenu: 'generalEnquiry',
    section: 'life',
    includeInMenu: false,
    order: 2,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default generalEnquiryModule;
