import fetch from 'globals/lambda-fetch';
import waFetch from 'globals/wa-fetch';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import { getEffectiveSettingsService } from 'stores/user-store';
import { getCookie, setCookie } from '../globals/cookieManager';
import { getCompany } from 'stores/company-store';
import { getStore } from '../hermes-redux';
import { removeUserCountryName } from '../globals/helpers';
import {
  ContentBannerSections,
  getGlobalContentPreferenceHeaders,
} from '../globals/global-content';
import { CONTENT_SECTION } from 'pages/employee-assistance/helpers/constants';

type ContentSection = keyof typeof CONTENT_SECTION;

export const getInternationalPhoneNumbers = () => {
  return waFetch(`/company/${getCompany().company_id}/eap/phone_numbers`, {
    token: true,
  });
};

export const getCloudfrontCookies = async (contentSection: ContentSection) => {
  const res = await fetchHippoResource(
    '/site/amazon-cookie',
    {},
    contentSection,
  );
  if (res) {
    setCloudfrontCookies(res.body);
  }
};

export const hasCloudfrontCookies = () =>
  // Used for audio/video articles
  getCookie('CloudFront-Key-Pair-Id') !== null &&
  getCookie('CloudFront-Policy') !== null &&
  getCookie('CloudFront-Signature') !== null;

export const setCloudfrontCookies = (cookies: any) =>
  values(cookies).forEach(
    (cookie: { name: string; value: string; domain: string }) => {
      const in2days = 2 * 24 * 60 * 60;
      setCookie(cookie.name, cookie.value, in2days, cookie.domain);
    },
  );

export const fetchHippoResource = (
  url,
  params: Record<string, any> = {},
  hippoContentSection: ContentSection,
) => {
  // We remove the LW-Language header on natives so that the proxy will use the
  // Accept-Language header(which is the device language rather than user language) instead.
  const isComingFromNative = getStore().getState().global.isComingFromNative;

  const options = {
    ...params,
    useLwLanguageHeader: !isComingFromNative,
    version: 'json',
    headers: {
      ...getGlobalContentPreferenceHeaders(ContentBannerSections.EAP),
    },
  };

  return fetch(
    `/cms-api-proxy/proxy/${hippoContentSection}/api/${hippoContentSection}${url}`,
    options,
    null,
    true,
  );
};

export const fetchSkeleton = (contentSection: ContentSection) =>
  fetchHippoResource('/site/skeleton', {}, contentSection)
    .then(res => (isEmpty(res) ? null : res.body))
    .catch(() => null);

export const getPhoneNumbers = (currentSection: string) => {
  const phoneNumbersMap = {
    'employee-assistance': 'eap_phone_numbers',
    hwr: 'hwr_phone_numbers',
  };

  const phoneNumbers = getEffectiveSettingsService(
    phoneNumbersMap[currentSection],
  );
  return phoneNumbers ? removeUserCountryName(phoneNumbers) : [];
};
