export const CINEMA_HELP_LINK = 'perks_need_help';

const banner = require(`../assets/img-cinema-banner.jpg`);
const largeBanner = require(`../assets/img-cinema-banner@2x.jpg`);
const largerBanner = require(`../assets/img-cinema-banner@3x.jpg`);

export const HERO_PROPS = {
  showMobile: true,
  banner: largeBanner,
  retinaBanner: largerBanner,
  tabletBanner: banner,
  retinaTabletBanner: largeBanner,
  mobileBanner: banner,
  retinaMobileBanner: banner,
  containerClassName: ['hero--offset-bg', 'hero--black'],
};
