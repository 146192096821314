import type { ThunkedAction } from 'globals/types';

export const SearchRoutesTypes = Object.freeze({
  TOP10: 'top10OffersSearch',
  EXCLUSIVE_OFFERS: 'exclusiveOffersSearch',
  CASHBACK: 'cashbackSearch',
  GIFT_CARDS: 'giftCardsSearch',
  SPOT_REWARDS: 'spotRewardsSearch',
  CINEMAS: 'cinemasSearch',
});

export type SearchRoutesType =
  (typeof SearchRoutesTypes)[keyof typeof SearchRoutesTypes];

export type PerksSearchTab = {
  type: SearchRoutesType;
  isEnabled: boolean;
};

export type FetchExclusiveOffers = (
  type: string,
  query?: string,
) => ThunkedAction;

export type FetchCashbackOffers = (
  query: string,
  isOffer: boolean,
  limit: number,
  offset: number,
) => ThunkedAction;

export type FetchCinemasMerchants = (
  reset: boolean,
  isSearch?: boolean,
) => ThunkedAction;

export type FilterExclusiveOffers = (searchTerm: string) => ThunkedAction;

export type FilterCinemasMerchants = (searchTerm: string) => ThunkedAction;
