import USER_ROLES from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';
import { injectAsyncReducer } from 'app/hermes-redux';
import { SearchRoutesTypes } from './perks-search-results.constants';
import FeatureKeys from 'app/constants/feature-keys';

export const resolver = () => {
  return Promise.all([
    import(
      /* webpackChunkName: "perksSearchResults" */ './perks-search-results.container'
    ),
    import(/* webpackChunkName: "offersReducer" */ '../offers/state/reducer'),
    import(
      /* webpackChunkName: "cashbackReducer" */ '../shop-online/state/reducer'
    ),
    import(
      /* webpackChunkName: "giftCardsReducer" */ '../gift-cards/search/state/reducer'
    ),
    import(
      /* webpackChunkName: "cinemasReducer" */ 'pages/cinemas/cinemas.reducers'
    ),
  ]).then(
    ([
      component,
      offersReducer,
      cashbackReducer,
      giftCardsReducer,
      cinemasReducer,
    ]) => {
      injectAsyncReducer('offers', offersReducer.offerReducer);
      injectAsyncReducer('shopOnline', cashbackReducer.shopOnlineReducer);
      injectAsyncReducer('giftcards', giftCardsReducer.giftCardsSearchReducer);
      injectAsyncReducer('cinemas', cinemasReducer.default);
      return component;
    },
  );
};

const perksSearchResultsModule = {
  exclusiveOffersSearch: {
    name: SearchRoutesTypes.EXCLUSIVE_OFFERS,
    url: '/offers/colleague/search',
    restrictions: {
      user: [restrictions[FeatureKeys.BENEFIT_COLLEAGUE_OFFER]],
    },
    data: {
      title: 'components.main_menu.colleague_offers',
      section: 'deals',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
    resolver,
  },
  cashbackSearch: {
    name: SearchRoutesTypes.CASHBACK,
    url: '/shop/search',
    restrictions: {
      user: [restrictions[FeatureKeys.BENEFIT_ONLINE_SHOP]],
    },
    data: {
      title: 'components.main_menu.shop_online',
      section: 'deals',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
    resolver,
  },
  giftCardsSearch: {
    name: SearchRoutesTypes.GIFT_CARDS,
    url: '/gift-cards/search',
    restrictions: {
      user: [restrictions[FeatureKeys.BENEFIT_GLOBAL_GIFT_CARDS]],
    },
    data: {
      title: 'components.main_menu.gift_cards',
      section: 'deals',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
    resolver,
  },
  spotRewardsSearch: {
    name: SearchRoutesTypes.SPOT_REWARDS,
    url: '/spot-rewards/gift-cards/search',
    restrictions: {
      user: [restrictions[FeatureKeys.ENTERPRISE_SPOT_REWARDS]],
    },
    data: {
      title: 'components.main_menu.rewards',
      mainMenu: 'spotRewards',
      section: 'myAccount',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
    resolver,
  },
  cinemasSearch: {
    name: SearchRoutesTypes.CINEMAS,
    url: '/cinemas/search',
    restrictions: {
      user: [restrictions[FeatureKeys.BENEFIT_CINEMA]],
    },
    data: {
      title: 'components.main_menu.cinemas',
      section: 'deals',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
    },
    resolver,
  },
};

export default perksSearchResultsModule;
