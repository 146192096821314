/**
 *  @module Spot Rewards Hero
 *  @overview Shows the hero card for Spot Rewards
 */
import { isFeatureEnabled } from 'stores/features-list-store';

import SpotRewardsEmptyCard from 'wa-storybook/components/well-being/hero-card/spot-rewards-empty-card';
import SpotRewardsCard from 'wa-storybook/components/well-being/hero-card/spot-rewards-card';

import styles from './hero.module.scss';
import { getLocalisedCurrencyString } from 'globals/helpers';

type Props = {
  currentBalance: number;
  onClick: () => void;
};

const SpotRewardsHero = ({ currentBalance, onClick }: Props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.label}>{polyglot.t('rewards.components.title')}</h1>
      {currentBalance > 0 ? (
        <SpotRewardsCard
          icon={require('./../../assets/icn-spot-rewards-bank.svg')}
          title={polyglot.t('rewards.available_reward.balance')}
          balance={`${getLocalisedCurrencyString(currentBalance)}`}
          subtitle={polyglot.t('rewards.available_reward.available')}
          cta={{
            label: polyglot.t('rewards.available_reward.choose_reward'),
            onClick,
            isActionEnabled: isFeatureEnabled('benefit_global_gift_cards'),
          }}
        />
      ) : (
        <SpotRewardsEmptyCard
          icon={require('./../../assets/illu-empty-rewards.svg')}
          title={polyglot.t('rewards.available_reward.title')}
          subtitle={polyglot.t('rewards.available_reward.subtitle')}
        />
      )}
    </div>
  );
};

SpotRewardsHero.displayName = 'SpotRewardsHero';

export default SpotRewardsHero;
