import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';
import { restrictions } from 'state/features-list/constants';

const homeModule = {
  home: {
    name: 'home',
    url: '/home',
    resolver: () =>
      import(/* webpackChunkName: "homeFeature" */ './home-feature'),

    data: {
      title: 'components.main_menu.home',
      mainMenu: 'home',
      showSearch: false,
      section: 'home',
      includeInMenu: true,
      authorizedRoles: [USER_ROLES.all],
      shortcutKeyCode: 48,
    },
    restrictions: {
      user: [restrictions['home_page']],
    },
  },
  services: {
    name: 'services',
    url: '/home/services',
    resolver: () =>
      Promise.all([
        import(/* webpackChunkName: "services" */ './services/services-page'),
        import(
          /* webpackChunkName: "eap" */ 'pages/employee-assistance/state/reducer'
        ),
      ]).then(([component, reducer]) => {
        injectAsyncReducer(`life`, reducer.employeeAssistanceReducer);
        return component;
      }),
    restrictions: {
      user: [restrictions['home_page']],
    },
    data: {
      title: 'services.title',
      mainMenu: 'home',
      showSearch: false,
      section: 'home',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.all],
      shortcutKeyCode: 48,
    },
  },
};

export default homeModule;
