import analyticsInstanceFactory from 'wa-storybook/helpers/google-analytics/apps/common/analytics';
import { Analytics } from '@workivate/tho-web-shared';
import { testableFeaturesEnabled } from 'globals/testable';
const instance = !testableFeaturesEnabled()
  ? analyticsInstanceFactory({
      appName: 'Hermes',
      measurementId: window.WAM.ENV.analyticsMeasurementId,
      debug: !IS_PRODUCTION,
      AWSAnalytics: IS_DEVELOPMENT
        ? require('@aws-amplify/analytics').Analytics
        : undefined,
    })
  : Analytics.AnalyticsEngine({
      appName: 'Hermes',
      measurementId: window.WAM.ENV.analyticsMeasurementId || '',
      debug: !IS_PRODUCTION,
      gtag: typeof window !== 'undefined' ? window.gtag : undefined,
    });

export default instance;
