import { connectWithProvider } from 'app/hermes-redux';
import styles from './carousel-child.module.scss';
import { modalExitAction } from '../../state/modal/constants';
import Button from 'wa-storybook/components/general/button/button';
import { AppState } from 'globals/types';
import { CarouselSlideModel } from './carousel-slide';
import { ModalPayload } from 'state/modal/types';
import ComponentAnimation, {
  AnimationTypes,
} from 'wa-storybook/components/general/component-animation/component-animation';

type Props = {
  index: number;
  slides: CarouselSlideModel[] | string[];
  payload: ModalPayload;
};

const CarouselChild = ({ index, slides, payload }: Props) => {
  const isLastSlide = index === slides.length - 1;

  return (
    <div className={styles.container}>
      <ComponentAnimation
        displayContentIf={isLastSlide}
        type={AnimationTypes.FADE_IN}
        id='cta-wrapper-animation'
      >
        <div className={styles['cta-wrapper']}>
          <div className={styles.cta}>
            <Button
              onClick={() => {
                if (payload.onCloseHandler) {
                  payload.onCloseHandler(modalExitAction.walkthroughComplete);
                }
              }}
              text={polyglot.t('walk_through.cta')}
              dataHook='well-being-walk-through-carousel-get-started-button'
            />
          </div>
        </div>
      </ComponentAnimation>
    </div>
  );
};

export default connectWithProvider(
  (state: AppState) => state.modal,
  CarouselChild,
);
