import Slider from 'react-slick';
import styles from './carousel-comp.module.scss';
import { AnalyticsEvent } from 'services/google-analytics/analytics-events';
import { goToUrl, getCurrentState } from 'router/navigation';
import waURL from 'globals/wa-url';
import cloudinary from 'globals/cloudinary';
import CarouselArrow from './carousel-arrow';
import Language from 'language';
import { cloneDeep, invertColor, isEqual } from 'globals/helpers';
import { ReactNode } from 'react';

type Props = {
  slides: Slider[];
  allOffersCount?: string | number;
};

type State = {
  slides: Props['slides'];
  currentSlideIndex?: number | null | undefined;
  arrowColor: string;
  isDragging?: boolean;
};

type Slide = Props['slides'][number];
type Slides = Props['slides'];

type SlideStyle = {
  imgSize: string;
  imgHeight: string;
  imageSrc: string;
  backgroundColor: string;
  backgroundImage: string;
  backgroundRepeat: string;
};

const sliderScrollLeft = (sliderWrapper: HTMLDivElement) => {
  const slickList = sliderWrapper.querySelector('.slick-list');
  if (slickList) {
    slickList.scrollLeft = 0;
  }
};

class Carousel extends React.Component<Props, State> {
  slider: Slider = null;
  sliderWrapper: {
    current: null | HTMLDivElement;
  };
  dragTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      slides: [],
      currentSlideIndex: null,
      arrowColor: '#FFFFFF',
      isDragging: false,
    };

    this.slider = React.createRef();
    this.sliderWrapper = React.createRef<HTMLDivElement | null>();
    this.dragTimer = undefined;
  }

  componentDidMount() {
    const sliderWrapper = this.sliderWrapper.current;

    if (sliderWrapper) {
      const slickList = sliderWrapper.querySelector('.slick-list');

      if (
        slickList &&
        !slickList.classList.contains(styles['carousel-item--left'])
      ) {
        slickList.classList.add(styles['carousel-item--left']);
      }

      // remove pagination tab focus since it is bad for UX
      // users can already tab through the slides
      const dots = sliderWrapper.querySelectorAll(
        '.slick-indicators>li>button',
      );
      dots.forEach(dot => dot.setAttribute('tabIndex', '-1'));

      // fix the duplicate slides bug on tab of the react-slick library when infinite is set to true
      // slick-cloned slides should not be tabbable (tabIndex set to -1 instead of 0)
      const duplicatedSlides = sliderWrapper.querySelectorAll(
        `.slick-cloned .${styles.container}`,
      );
      duplicatedSlides.forEach(slide => {
        slide.setAttribute('tabIndex', '-1');
      });
    }
  }

  static getDerivedStateFromProps(
    props: Props,
    state: State,
  ): State | null | undefined {
    if (
      !Array.isArray(props.slides) ||
      (!props.slides.length && !state.slides.length)
    ) {
      return {
        slides: [],
        arrowColor: state.arrowColor,
      };
    }

    if (!isEqual(props.slides, state.slides)) {
      const slides =
        !props.slides || props.slides.length === 0
          ? []
          : cloneDeep(props.slides);

      const slideStyle = Carousel.getSlideStyle(
        Language.isRTL() ? slides[slides.length - 1] : slides[0],
      );
      const invertedColor = invertColor(slideStyle.backgroundColor, true);
      return {
        slides: Language.isRTL() ? slides.reverse() : slides,
        arrowColor: invertedColor,
      };
    }
    return null;
  }

  static getSlideStyle = (slide: Slide): SlideStyle => {
    const imgSize = context.viewport.isMobile
      ? 'xs'
      : context.viewport.isTablet
        ? 'sm'
        : 'lg';

    const imgHeight =
      context.viewport.isDesktop || context.viewport.isDesktopXl
        ? '337'
        : context.viewport.isTablet
          ? '200'
          : '256';

    const imageSrc = slide.image
      ? slide.image
      : cloudinary(
          slide['image_list'][`webapp_${imgSize}`]['url'],
          `c_fit,h_${imgHeight}`,
        );

    const backgroundColor = slide['bg_color']
      ? slide['bg_color']
      : slide['image_list'][`webapp_${imgSize}`]['predominant_color'];

    const backgroundImage = `url(${imageSrc})`;
    const backgroundRepeat = slide.image ? 'repeat' : 'no-repeat';

    return {
      imgSize,
      imgHeight,
      imageSrc,
      backgroundColor,
      backgroundImage,
      backgroundRepeat,
    };
  };

  setArrowColor = (slide: Slide) => {
    const slideStyle = Carousel.getSlideStyle(slide);
    const invertedColor = invertColor(slideStyle.backgroundColor, true);

    if (this.state.arrowColor !== invertedColor) {
      this.setState({ arrowColor: invertedColor });
    }
  };

  trackPressTimer = () => {
    this.setState({ isDragging: false });
    this.dragTimer = setTimeout(() => {
      this.setState({ isDragging: true });
    }, 150);
  };

  componentWillUnmount() {
    clearTimeout(this.dragTimer);
  }

  renderSlides = (slides: Slides): ReactNode[] | undefined => {
    if (slides) {
      return slides.map((slide, index) => {
        const slideStyle = Carousel.getSlideStyle(slide);

        const containerStyle = {
          backgroundColor: slideStyle.backgroundColor,
          backgroundImage: slideStyle.backgroundImage,
          backgroundRepeat: slideStyle.backgroundRepeat,
        } as const;

        return (
          <div key={index} aria-label={polyglot.t('perks.aria.perks_carousel')}>
            <div
              tabIndex={0}
              role='link'
              onFocus={e => {
                this.handleFocus(e, index);
              }}
              onMouseUp={() => {
                if (this.state.isDragging) return;

                clearTimeout(this.dragTimer);
                return this.handleClick(slide['wa_url'] || '');
              }}
              onMouseDown={this.trackPressTimer}
              onKeyDown={e => {
                this.handleKeyboard(e, slide['wa_url']);
              }}
              className={styles.container}
              style={containerStyle}
              aria-label={
                slide.image_list.aria_label ||
                polyglot.t('perks.aria.perks_carousel_slide')
              }
              aria-description={polyglot.t('perks.aria.slide_count', {
                current_slide: index + 1,
                total_slide: slides.length,
              })}
            >
              {slide['is_banner'] ? (
                <div className={styles.branding}>
                  <div className={styles.title}>
                    {this.props.allOffersCount}{' '}
                    {polyglot.t('online_shop.carousel.title')}
                  </div>
                  <div className={styles.subtitle}>
                    {polyglot.t('online_shop.carousel.subtitle')}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        );
      });
    }
  };

  handleFocus = (e: React.FocusEvent<HTMLDivElement>, index: number) => {
    e.stopPropagation();

    if (this.slider) {
      this.slider.current.slickGoTo(index);
    }

    if (this.sliderWrapper.current) {
      sliderScrollLeft(this.sliderWrapper.current);
    }
  };

  handleKeyboard = (
    event: React.KeyboardEvent<HTMLDivElement>,
    url: string,
  ) => {
    event.stopPropagation();

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();

      this.handleClick(url || '');
    }
  };

  handleClick = (url: string) => {
    AnalyticsEvent.Perks.Carousel.Banner.selected(url, getCurrentState().url);
    goToUrl(waURL(url));
  };

  afterChange = (index: number) => {
    const slide = this.state.slides[index];
    this.setArrowColor(slide);
    this.setState({ currentSlideIndex: index });

    const slickSlide = document.querySelector<HTMLElement>('.slick-slide');

    if (slickSlide) {
      slickSlide.onclick = null;
    }
  };

  render() {
    const NextArrow = <CarouselArrow isStaticallyVisible />;
    const PrevArrow = <CarouselArrow isPrev isStaticallyVisible />;
    const settings = {
      accessibility: false,
      adaptativeHeight: true,
      arrows: true,
      focusOnSelect: false,
      autoplay: true,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      pauseOnFocus: true,
      swipeToSlide: true,
      className: '',
      dots: true,
      dotsClass: 'slick-indicators',
      customPaging: i => {
        const options = {
          label: polyglot.t('aria.carousel_slide'),
          index: i + 1,
          total: this.state.slides.length,
        } as const;
        const ariaLabel =
          i === this.state.currentSlideIndex
            ? polyglot.t('aria.button_selected.true', options)
            : polyglot.t('aria.button_selected.false', options);
        return <button aria-label={ariaLabel}>{i + 1}</button>;
      },
      infinite: this.state.slides.length > 1,
      slidesToScroll: 1,
      slidesToShow: 1,
      initialSlide: 0,
      lazyLoad: false,
      speed: 700,
      nextArrow: NextArrow,
      prevArrow: PrevArrow,
      rtl: Language.isRTL(),
      beforeChange: () => {
        const slide = document.querySelector<HTMLElement>('.slick-slide');

        if (slide) {
          slide.onclick = e => {
            e.stopPropagation();
          };
        }
      },
      afterChange: this.afterChange,
    };

    if (this.state.slides && this.state.slides.length === 1) {
      settings.arrows = false;
      settings.autoplay = false;
    }

    return (
      this.state.slides.length && (
        <div className='slick-slider-online-shop' ref={this.sliderWrapper}>
          <Slider ref={this.slider} {...settings}>
            {this.renderSlides(this.state.slides)}
          </Slider>
        </div>
      )
    );
  }
}

export default Carousel;
