const getLifeworksInChinaModule = {
  name: 'getlifeworksinchina',
  url: '/getlifeworksinchina',
  resolver: () =>
    new Promise(() => {
      window.location.replace(
        'https://help.lifeworks.com/hc/zh-cn/articles/360031725092',
      );

      return null;
    }),
  data: {},
};

export default getLifeworksInChinaModule;
