import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { HealthCoachingRoutes } from './coaching-constants';

const createResolver = pageImport =>
  Promise.all([pageImport]).then(([component]) => {
    return component;
  });

const healthCoachingModule = {
  form: {
    name: HealthCoachingRoutes.INDEX,
    url: '/health-coaching',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "health-coaching" */ './coaching-container'
        ),
      ),
    restrictions: {
      user: [restrictions['eap_health_coaching']],
    },

    data: {
      title: 'components.main_menu.health_coaching',
      mainMenu: 'healthCoaching',
      section: 'life',
      includeInMenu: false,
      order: 2,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default healthCoachingModule;
