import { isEmpty } from 'lodash';
import type { AppState, Dispatch, ThunkedAction } from 'globals/types';
import { EVENT } from './reducer';
import type { Model } from './types';
import fetch from 'globals/wa-fetch';
import { updateUser, logout } from 'state/session/actions';
import type { User } from 'state/session/types';
import { showToast } from 'toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';
import * as Storage from 'globals/storage';
import * as Session from 'state/session/helpers';
import { SIGNUP_CODES, INVITE_TYPES } from '../../constants';
import { goTo } from 'router/navigation';
import getErrorMessage from 'constants/error-messages';
import Language from 'language';
import { handleLoginRedirect } from 'state/session/helpers';
import { getCompany } from 'stores/company-store';
import { SIGN_UP_CONSENT_MODULE_NAME } from '../../../consent/constants';
import { getConsentsToShowToUserDuringSignUp } from '../../../consent/helpers';
import { getStore } from '../../../../hermes-redux';
import { consentService } from '../../../consent/consent-service';
import { resetConsentState } from '../../../../state/consent/actions';
import { getMe, setUser } from '../../../../stores/user-store';
import { AppLogger } from '@workivate/tho-web-shared';
import type { Company } from 'app/state/company/types';

const {
  INTERESTS,
  SSO_SIGNUP_PROVISIONED,
  GENERIC_INVITATION_DETAILS_WEB_COMPLETE,
  INDIVIDUAL_INVITATION_DETAILS_WEB_COMPLETE,
  ADMIN_CONFIRMATION_REQUIRED,
} = SIGNUP_CODES;

export type Response = {
  body: any;
  newUser: User;
  type?: string;
};

export function goToLogin(message: string) {
  return function (dispatch: Dispatch) {
    dispatch(showToast({ type: ToastType.SUCCESS, message }));

    return goTo('/');
  };
}

function createUser(user: User) {
  return fetch('/user', {
    method: 'post',
    version: 1.3,
    body: user,
  });
}

export function submitIndexData(data: any) {
  return function (dispatch: Dispatch) {
    const { signup_state } = data;
    if (
      signup_state === INDIVIDUAL_INVITATION_DETAILS_WEB_COMPLETE ||
      signup_state === SSO_SIGNUP_PROVISIONED
    ) {
      const newUser = { ...data } as const;

      if (newUser.signup_state === INDIVIDUAL_INVITATION_DETAILS_WEB_COMPLETE) {
        delete newUser.signup_state;
      }

      return dispatch(updateUser(newUser, true));
    }

    return createUser(data).then(
      (result: {
        body: {
          token: string;
        };
      }) => {
        Storage.set<string>(
          Storage.storageKeys.signupSession,
          result.body.token,
        );
        return result;
      },
    );
  };
}

export function updateData(key: string, data: any): ThunkedAction {
  return function (dispatch: Dispatch) {
    if (data.token) {
      Storage.set(Storage.getKeys().signupSession, data.token);
    }

    if (data.company) {
      Storage.set(Storage.getKeys().signupCompany, data.company);
    }

    if (key === 'signUpResponse') {
      Storage.set(Storage.getKeys().signupResponse, data);
    }

    dispatch({ type: EVENT.UPDATE_DATA, key, data });
  };
}

export function handleInitData(data: any = {}): ThunkedAction {
  return function (dispatch: Dispatch) {
    if (
      data['signup_state'] !== SSO_SIGNUP_PROVISIONED &&
      !data.code &&
      !data.token
    ) {
      return goTo('signupInvitation');
    }

    const { user } = data;

    if (data['signup_state'] !== INTERESTS) {
      dispatch(
        updateData('submissionModel', {
          company_invitation_code: data.code,
          otp: data.otp,
          signup_state:
            data['invite_type'] === INVITE_TYPES.GENERIC_INVITATION_CODE
              ? GENERIC_INVITATION_DETAILS_WEB_COMPLETE
              : INDIVIDUAL_INVITATION_DETAILS_WEB_COMPLETE,
        }),
      );
    }

    if (user) {
      if (!Language.getLoginLanguagePersistence()) {
        Language.set({
          locale: user.locale,
          isSharedAccount: false,
          shouldLoadAllTranslations: true,
        }).catch(() => {
          dispatch(
            showToast({
              message: polyglot.t('settings.translations.error'),
              type: ToastType.ERROR,
            }),
          );
        });
      }

      dispatch(
        updateData('submissionModel', {
          user_id: user['user_id'],
          first_name: user['first_name'],
          last_name: user['last_name'],
          email: user.email,
          secondary_email_address: user['secondary_email_address'],
          notification_email_field: user['notification_email_field'],
          account_type: user['account_type'],
        }),
      );
    }

    if (data['signup_state'] !== SSO_SIGNUP_PROVISIONED) {
      dispatch({
        type: EVENT.UPDATE_PASSWORD_REQUIREMENTS,
        requirements: data['password_requirements'],
      });
    }
  };
}

export function clearPersistedSignupData(): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch(resetConsentState());
    clearSignupState();
  };
}

export function completeSignUp(): ThunkedAction {
  return async function (
    dispatch: Dispatch,
    getState: () => {
      signup: Model;
    },
  ) {
    const { initData, signUpResponse } = getState().signup;
    const msTeamsUrl = Session.getMsTeamsUrl();

    let signupState = signUpResponse?.signup_state;

    const token =
      initData.token ||
      Storage.get<string>(Storage.storageKeys.signupSession) ||
      signUpResponse?.token;

    /**
     * This function is called after accepting consents
     * accepting lifeworks consents will change the sign
     * up state
     *
     * Here we make sure that we re fetch the user data so
     * that we have the most up to date sign up state. This
     * is important because we use this to determine where
     * we should send the user.
     */
    try {
      if (token) {
        const { body: updatedUser } = await getMe({
          // override the fetch function getting the token from localstorage
          token: false,
          // specify our token from the sign up process
          headers: {
            'Wam-Token': token,
          },
        });

        if (updatedUser && updatedUser.signup_state != null) {
          setUser(updatedUser);
          signupState = updatedUser.signup_state;
        }
      }
    } catch (e: any) {
      AppLogger.error(e);
    }

    if (signupState !== SSO_SIGNUP_PROVISIONED) {
      if (signupState === ADMIN_CONFIRMATION_REQUIRED) {
        dispatch(clearPersistedSignupData());
        dispatch(logout(false)); // the user created during signup needs to be logged out
        return dispatch(
          goToLogin(polyglot.t('sign_up.interests.signup_success_1')),
        );
      } else if (token) {
        const company: Company =
          Storage.get(Storage.storageKeys.signupCompany) || getCompany();

        if (company) {
          // read the original url from session storage, and redirect to it after signup completed
          // for users to be able to continue their journey from where they originally started
          const originalUrl: string =
            Storage.get(Storage.storageKeys.signupReturnUrl, false) || '';
          const goToUrl = originalUrl === '/signup' ? '' : originalUrl; // avoid any loops, just in case
          Storage.remove(Storage.storageKeys.signupReturnUrl);

          handleLoginRedirect(
            company.wa_subdomain,
            true,
            msTeamsUrl ? '/return-to-teams' : goToUrl,
          );
        } else {
          dispatch(clearPersistedSignupData());
          dispatch(goToLogin(polyglot.t('sign_up.interests.signup_success')));
        }
      }
    } else {
      dispatch(clearPersistedSignupData());
      goTo('/');
    }
  };
}

export const clearSignupState = () => {
  Storage.remove(Storage.storageKeys.signupSession);
  Storage.remove(Storage.storageKeys.signupResponse);
  Storage.remove(Storage.storageKeys.signupCompany);
  getStore().dispatch(reset());
};

export function handleSubmit(formValues: any): ThunkedAction {
  return function (dispatch: Dispatch, getState: () => AppState) {
    const { submissionModel } = getState().signup;

    const localStorageLocale = JSON.parse(
      localStorage.getItem('lw-language') || '{}',
    );

    const submitData = {
      ...submissionModel,
      ...formValues,
      // Have to specifically check whether the local storage locale is set here. See GR-521.
      locale: !isEmpty(localStorageLocale) ? Language.getLocale() : undefined,
    } as const;

    return dispatch(submitIndexData(submitData))
      .catch((result: any) => {
        return dispatch(
          showToast({
            type: ToastType.ERROR,
            message: getErrorMessage(result.error?.code),
          }),
        );
      })
      .then((response: Response) => {
        /**
         *  When we do a PUT to /user/me
         *    - we get back response.newUser
         *    - we already have a token from calling /user/signup-invitation-code/<code>
         *    - signup_state is returned on the newUser
         *  When we do a POST to /user
         *    - we get back response.body
         *    - we get the token in the response.body
         *    - we get signup_state as part of response.body
         */
        const user = response.newUser || {
          ...response.body.user,
          signup_state: response.body.signup_state,
          token: response.body.token,
        };
        // Counterintuitively, newUser is actually available here
        // when the user is updated, not when the user is created.
        // For an example, use quick invites or an individual invite code.
        dispatch(updateData('signUpResponse', user));
        return consentService.loadConsentsData();
      })
      .then(() => {
        const { consents } = getState().consent;
        const needsToAcceptConsents =
          getConsentsToShowToUserDuringSignUp(consents || []).length > 0;
        if (needsToAcceptConsents) {
          // The user now needs to accept the consents needed to access the
          // platform so we direct them there. When complete we will
          // complete sign up with the completeSignUp action
          goTo(SIGN_UP_CONSENT_MODULE_NAME);
        } else {
          dispatch(completeSignUp());
        }
      })
      .catch(() => {
        dispatch(
          showToast({
            type: ToastType.ERROR,
            message: polyglot.t('challenges.global.form.generic_error'),
          }),
        );
      });
  };
}

export function reset() {
  return function (dispatch: Dispatch) {
    dispatch({ type: EVENT.RESET });
  };
}
