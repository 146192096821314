import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { MODULE_NAME } from './join.constants';
import { HUB_NAME } from '../hub/constants';

const joinModule = {
  name: MODULE_NAME,
  url: '/challenges/:participationType/:challengeId',
  resolver: () => import(/* webpackChunkName: "challenges-join" */ './join'),
  restrictions: {
    user: [restrictions['eap_challenges']],
  },
  checkConsent: () => ({
    consentType: 'challenges',
    customBackLink: HUB_NAME,
  }),
  data: {
    mainMenu: 'wellBeing',
    section: 'wellBeing',
    includeInMenu: false,
    authorizedRoles: [USER_ROLES.employee],
    hideFooter: true,
  },
} as const;

export default joinModule;
