import Polyglot from 'node-polyglot';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { getUser } from 'app/stores/user-store';
import queryString from 'query-string';
import fetch from 'globals/wa-fetch';
import { getStore } from 'app/hermes-redux';
import { ToastType } from 'react-components/toaster-comp/state/types';
import { showToast } from 'toaster-comp/state/actions';
import {
  hippoLanguageMap,
  rtlLanguages,
  supportedLanguages,
} from './languages.constant';
import { getUserCountryCode } from 'stores/user-store';
import { getCompany } from 'stores/company-store';
import {
  remove as removeFromStorage,
  set as storageSet,
  get as storageGet,
  storageKeys,
} from 'globals/storage';
import * as Session from 'state/session/helpers';
import { consentService } from './pages/consent/consent-service';
import { runOnWebWorker } from 'app/run-on-web-worker';
import { goTo } from 'router/navigation';

const DEFAULT_LOCALE = 'en_GB';

const languageHelpers = (() => {
  const getCompanyLocale = () => {
    const company = getCompany();
    return !isEmpty(company) ? company.locale : '';
  };

  const getQueryStringLocale = () => {
    // For SSO login flow we need to get the lang param from the RelayState.
    const params = queryString.parse(location.search);
    if (params.RelayState) {
      const { lang } = queryString.parse(params.RelayState.split('?')[1]);
      return lang;
    }
    return params.lang;
  };

  const removeQueryStringLocale = () => {
    const urlParams = queryString.parse(window.location.search);
    delete urlParams['lang'];

    goTo(window.location.pathname, urlParams, { shouldNotPushToHistory: true });
  };

  return {
    getCompanyLocale,
    getQueryStringLocale,
    removeQueryStringLocale,
  };
})();

const sharedAccounts = (() => {
  const getAll = () => {
    const users = storageGet(storageKeys.sharedAccountUsers, true);

    return users || {};
  };

  const getAccountLocale = id => {
    const sharedAccountUsers = getAll();
    const { locale } = sharedAccountUsers[id] || {};

    return locale;
  };

  const setAccountLocale = (locale, user) => {
    const { user_id } = user;
    const sharedAccountUsers = getAll();

    // if nothing is set, create the user
    sharedAccountUsers[user_id] = {
      locale,
    };

    storageSet(storageKeys.sharedAccountUsers, sharedAccountUsers, true);
  };

  return {
    getAll,
    getAccountLocale,
    setAccountLocale,
  };
})();

class Language {
  locale: string;

  constructor() {
    window.polyglot = new Polyglot({
      allowMissing: true,
    });

    // when we refresh the page we need to get the localStorage locale before falling back to the default one.
    this.locale = this.getInstance()?.locale || DEFAULT_LOCALE;
  }

  updateLibraries(translations) {
    window.polyglot.replace(merge({}, polyglot.phrases, translations));
  }

  isRTL() {
    return rtlLanguages.includes(this.getLocale());
  }

  getLocale(format?) {
    if (format) {
      return (this.locale || DEFAULT_LOCALE).replace('_', '-');
    }

    return this.locale || DEFAULT_LOCALE;
  }

  getISOLanguage() {
    const locale = this.locale || DEFAULT_LOCALE;

    return (locale.split('_')[0] || 'en').toUpperCase();
  }

  getInstance() {
    const lwLanguage = storageGet(storageKeys.lwLanguage, true);
    return (
      (lwLanguage as { locale: string } | undefined) ||
      ({} as { locale: string } | undefined)
    );
  }

  setInstance(locale) {
    this.locale = locale;
    this.setCSSDirection(this.isRTL());

    storageSet(storageKeys.lwLanguage, this, true);
  }

  setCSSDirection(isRTL = false) {
    document
      .getElementsByTagName('HTML')[0]
      ?.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  }

  loadTranslations(
    locale = this.getLocale(),
    preload = false,
    unsupported = false,
  ) {
    // If user is redirected from a different subdomain at login then at the time this is called the
    // user object is not set resulting in pre-auth translations never being loaded.
    // Their token is in localStorage though so use that instead.
    const userAuthorized = !isEmpty(getUser()) || Session.getToken();
    const bundleType = userAuthorized ? 'auth' : 'pre-auth';
    const preloadBundleType = userAuthorized ? 'pre-auth' : 'auth';

    const translationLocale = this.getTranslationLocale(locale);
    const wrapper = translations => {
      this.updateLibraries(translations);
      if (unsupported) {
        getStore().dispatch(
          showToast({
            message: polyglot.t('settings.translations.error'),
            type: ToastType.ERROR,
          }),
        );
      }
    };
    return fetch(
      `${window.WAM.ENV.translationsUrl}/${translationLocale}-${
        preload ? preloadBundleType : bundleType
      }.json`,
      {},
      false,
      true,
      false,
      false,
    ).then(translations => wrapper(translations));
  }

  getTranslationLocale(locale) {
    // Translation file resolving strategy in priority order
    // 1. Valid translation file for users currently set locale
    // 2. English translation file for users country(if available)
    // 3. en_GB
    const userCountryCode = getUserCountryCode();

    if (supportedLanguages.includes(locale)) {
      return locale;
    }

    if (userCountryCode) {
      const englishUserCountryLocale = supportedLanguages.find(
        locale =>
          locale.startsWith('en') &&
          locale.split('_')[1]?.toLowerCase() === userCountryCode.toLowerCase(),
      );

      if (englishUserCountryLocale) {
        return englishUserCountryLocale;
      }
    }

    return DEFAULT_LOCALE;
  }

  getLowerCaseTranslationLocale() {
    let translationLocale = this.getTranslationLocale(
      this.getLocale(),
    ).toLowerCase();

    if (translationLocale.indexOf('en') === 0) {
      translationLocale = DEFAULT_LOCALE.toLowerCase();
    }

    return translationLocale;
  }

  getLanguageName(locale?: string | null, removeCountryCode?: boolean) {
    if (!locale || typeof locale !== 'string') return '';

    const language = supportedLanguages.find(
      (lang: string) =>
        lang.toLowerCase() === locale.replace('-', '_').toLowerCase(),
    );

    if (language) {
      const getCountryCodeTranslation = () =>
        !removeCountryCode
          ? ` (${polyglot.t(`supported_languages.${language}.country_code`)})`
          : '';

      return `${polyglot.t(
        `supported_languages.${language}.name`,
      )}${getCountryCodeTranslation()}`;
    }

    const hippoLang = hippoLanguageMap.find(
      hippoLang =>
        hippoLang.received.toLowerCase() ===
        locale.replace('-', '_').toLowerCase(),
    );

    if (hippoLang) {
      return this.getLanguageName(hippoLang.mapsTo);
    }

    const supportedLanguage = supportedLanguages.find(language =>
      language.includes(locale.substring(0, 2)),
    );
    if (supportedLanguage) {
      return polyglot.t(`supported_languages.${supportedLanguage}.name`);
    }
    return '';
  }

  getLocaleByAccountType(
    currentUser,
    locale,
    isSharedAccount,
    shouldLoadAllTranslations,
  ) {
    if (isSharedAccount) {
      if (!shouldLoadAllTranslations) {
        return sharedAccounts.getAccountLocale(currentUser.user_id);
      }

      sharedAccounts.setAccountLocale(locale, currentUser);

      return locale;
    }

    if (!shouldLoadAllTranslations) {
      return currentUser.locale;
    }

    return locale;
  }

  set({
    locale,
    isSharedAccount,
    shouldLoadAllTranslations,
    resetGlobalContent,
  }: {
    locale?: string;
    isSharedAccount?: boolean;
    shouldLoadAllTranslations?: boolean;
    resetGlobalContent?: boolean;
  } = {}) {
    const companyLocale = languageHelpers.getCompanyLocale();
    const currentUser = getUser();
    const queryStringLocale = languageHelpers.getQueryStringLocale();
    const isShared = !!(isSharedAccount || currentUser.is_shared_account);
    const hasNotLoggedIn = !Object.keys(currentUser).length;
    // check on local storage if the user has a locale set not supported by the app
    const localStorageLocale = supportedLanguages.includes(
      this.getInstance()?.locale || '',
    )
      ? this.getInstance()?.locale
      : '';

    // the new locale to be set
    let newLocale;

    // if the user has not logged in we need to take the default localStorage language
    if (hasNotLoggedIn) {
      newLocale = locale || localStorageLocale;
    } else {
      newLocale =
        (!isShared && locale) ||
        this.getLocaleByAccountType(
          currentUser,
          locale,
          isShared,
          shouldLoadAllTranslations,
        );
    }

    if (!newLocale) {
      newLocale = locale || localStorageLocale || companyLocale;
    }
    // check to see if query locale string is supported
    let unsupported = false;
    if (queryStringLocale) {
      if (supportedLanguages.includes(queryStringLocale)) {
        newLocale = queryStringLocale;
      } else {
        unsupported = true;
      }
      // Once we've read the query string locale, remove it from the address bar.

      languageHelpers.removeQueryStringLocale();
      this.setLoginLanguagePersistence(true);
    }

    this.setInstance(newLocale || DEFAULT_LOCALE);
    if (!shouldLoadAllTranslations) {
      return this.loadTranslations(newLocale, false, unsupported);
    }

    // Clear flag from local storage so users will be presented with
    // the content banner again if it is relevant to their new language.
    if (resetGlobalContent) {
      removeFromStorage(storageKeys.useGlobalContent);
      removeFromStorage(storageKeys.usePerksGlobalContent);
      removeFromStorage(storageKeys.useAssessmentsGlobalContent);
    }

    // If the language was changed manually(from settings),
    // we have to download the auth & pre-auth files. Otherwise
    // the incorrect language will be shown on logout.

    // consent will be downloaded in the background
    runOnWebWorker(() => {
      consentService.loadConsentsData(true);
    });

    return Promise.all([
      this.loadTranslations(newLocale),
      this.loadTranslations(newLocale, true),
    ]);
  }

  setLoginLanguagePersistence(shouldPersist = false) {
    storageSet(
      storageKeys.shouldPersistLoginPageLanguage,
      shouldPersist,
      false,
    );
  }

  getLoginLanguagePersistence() {
    return (
      storageGet(storageKeys.shouldPersistLoginPageLanguage, false) || false
    );
  }
}

// singleton because we only want ONE language at the time
const instance = new Language();

export { sharedAccounts, languageHelpers, supportedLanguages };

export default instance;
