export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AssessmentHeroCard: [
      'HealthRiskAssessmentCard',
      'SingleAssessmentCard',
      'TotalHealthIndexCard',
    ],
    CareAssessmentQuestion: [
      'CareAssessmentChoiceQuestion',
      'CareAssessmentTextQuestion',
    ],
    CarePlanActivity: ['CarePlanActivityArticle'],
    CashbackRate: ['CashbackRateFixAmount', 'CashbackRatePercentage'],
    Challenge: ['HabitChallenge', 'StepChallenge'],
    DigitalClinicalContentItem: [
      'Article',
      'ChoiceQuestion',
      'FreeTextQuestion',
      'ScaleQuestion',
      'StarRatingQuestion',
    ],
    EndOfChallengeSummaryTotal: [
      'AverageSteps',
      'ClimbedEiffelTower',
      'CompletedMarathonPercentage',
      'HitGoal',
    ],
    Feedback: ['MeasurementResultFeedback', 'ResultFeedback'],
    Offer: ['CashbackOffer'],
    PreAssessmentRecommendation: [
      'PreAssessmentRecommendationBookAppointment',
      'PreAssessmentRecommendationSuicideRiskCallNow',
    ],
    PreAssessmentStatus: ['PreAssessmentNotTaken', 'PreAssessmentResult'],
    WellbeingContentLanguageBanner: [
      'WellbeingContentLanguageMessageChangeLanguage',
      'WellbeingContentLanguageMessageNoChoice',
      'WellbeingContentLanguageMessageNoContent',
    ],
    WellbeingPromotion: [
      'AssessmentPromotion',
      'ChallengePromotion',
      'HraPromotion',
      'TotalHealthIndexPromotion',
    ],
  },
};
export default result;
