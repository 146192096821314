import type { SelectCompValue } from 'globals/types';
import type { Store } from 'globals/perks.types';

import OneQuarterPage from 'perks/layouts/one-quarter';

import SelectComp from 'inputs-comp/select-comp/select-comp';

type Props = {
  categories?: Store[];
  onSelectChange: any;
  placeholder: string;
  idField: string;
  activeCategory?: any;
  selectedItem?: Store | null;
};

export const buildOptions = (
  categories: Array<Store>,
  idField: string,
): Array<{
  value: string;
  name: string;
}> =>
  categories.map((category: Store) => ({
    value: category[idField],
    name: category.name,
  }));

const TopMenu = (props: Props) => {
  const { categories } = props;

  return (
    <OneQuarterPage wrapperClassName='one-quarter-page--top-menu'>
      <SelectComp
        selectClassName='top-menu'
        selectedClassName='selected-item--top-menu'
        placeholder={props.placeholder}
        selectedItem={props.selectedItem || null}
        activeCategory={props.activeCategory}
        options={categories ? buildOptions(categories, props.idField) : []}
        onItemSelected={(selectedItem: SelectCompValue) =>
          props.onSelectChange(selectedItem.value)
        }
        ariaLabel={polyglot.t('perks.category_selection_dropdown_aria_label')}
      />
    </OneQuarterPage>
  );
};

TopMenu.displayName = 'TopMenu';

export default TopMenu;
