import type { NextTierProgressBarProps } from 'wa-storybook/components/well-being/tiering/next-tier-progress-bar/next-tier-progress-bar';
import type {
  TieringHubCurrentTierFragment,
  TieringHubNextTierFragment,
  TieringHubTierPointsFragment,
  TieringHubAllTiersFragment,
} from 'graphql-types';
import { ProgressStop } from 'wa-storybook/components/well-being/tiering/next-tier-progress-bar/parts/progress-stop/progress-stop';
import { TrophyFilled } from 'wa-storybook/components/general/icon/components/trophy-filled';
import Language from 'language';
import { CustomTieringProgressBarProps } from 'wa-storybook/components/well-being/tiering/next-tier-progress-bar/custom-tiering-progress-bar';
import { tierNames } from '../../tiering.constants';
import styles from './custom-activity-display.module.scss';
import { sanitizeHtml } from 'wa-storybook/helpers/sanitize';

export const progressBarMinimumDisplayedPercentage = 1;

export const getTierProgressPercentage = (
  currentTierBaseValue: number,
  pointsToNextTier: number,
  nextTierBaseValue: number,
  minimum: number = progressBarMinimumDisplayedPercentage,
): number => {
  const pointsInCurrentTier = nextTierBaseValue - currentTierBaseValue;
  const pointsEarnedInTier = pointsInCurrentTier - pointsToNextTier;
  const percentage = (pointsEarnedInTier / pointsInCurrentTier) * 100;
  return Math.max(minimum, percentage);
};

export const formatPoints = (value?: number | null): string => {
  if (!value) {
    return '0';
  }
  return new Intl.NumberFormat(Language.getLocale(true)).format(value);
};

type ActivityPoints =
  | {
      tierPointAward:
        | {
            points: number;
          }
        | null
        | undefined;
    }
  | null
  | undefined;

export const getProgressBarProps = (
  currentTier: TieringHubCurrentTierFragment | null | undefined,
  nextTier: TieringHubNextTierFragment | null | undefined,
  tierPoints: TieringHubTierPointsFragment | null | undefined,
  allTiers: Array<TieringHubAllTiersFragment> | null | undefined,
  customActivity?: ActivityPoints,
): NextTierProgressBarProps | null => {
  if (
    !currentTier ||
    !currentTier.rank ||
    !nextTier ||
    !nextTier.rank ||
    !tierPoints ||
    !tierPoints.remainingToReachNextTier ||
    !allTiers ||
    !allTiers.length
  ) {
    return null;
  }

  const currentRank = currentTier.rank - 1;
  const nextRank = nextTier.rank - 1;

  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  const currentTierBaseValue = allTiers[currentRank].points;
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  const nextTierBaseValue = allTiers[nextRank].points;

  const currentTierName = tierNames[currentRank];
  const nextTierName = tierNames[nextRank];

  const currentStatusLabel = polyglot.t(
    'tiering.custom_tiering.progress_label_your_current_tier',
    {
      current: currentTierName,
    },
  );
  const progressLabel = polyglot.t('tiering.custom_tiering.progress_label', {
    smart_count: tierPoints.remainingToReachNextTier,
  });

  const tierPointsLabel = customActivity
    ? polyglot.t('tiering.custom_tiering.progress_label_points', {
        smart_count: customActivity.tierPointAward?.points,
      })
    : '';

  const ariaLabel = `${currentStatusLabel} ${progressLabel} ${tierPointsLabel}`;

  return {
    ariaLabel: ariaLabel,
    ariaValueText: polyglot.t('tiering.custom_tiering.progress_value_text'),
    id: 'custom-activity-progress',
    progressMax: 100,
    progressClassName: `progress-bar--${currentTierName}`,
    label: polyglot.t('tiering.custom_tiering.next_tier_progress_label', {
      current: currentTier?.name,
      next: nextTier?.name,
      points_needed: tierPoints?.remainingToReachNextTier,
    }),
    startContent: (
      <ProgressStop
        iconContent={<TrophyFilled />}
        value={formatPoints(currentTierBaseValue)}
        iconClassName={[
          `progress-stop__icon--${currentTierName}`,
          'progress-stop__icon--align-left',
        ]}
        valueClassName='progress-stop__value--left-side'
      />
    ),
    endContent: (
      <ProgressStop
        iconContent={<TrophyFilled />}
        value={formatPoints(nextTierBaseValue)}
        iconClassName={[
          `progress-stop__icon--${nextTierName}`,
          'progress-stop__icon--align-right',
        ]}
        valueClassName='progress-stop__value--right-side'
      />
    ),
    children: (
      <p
        className={styles['current-points-text']}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(
            polyglot.t('tiering.custom_tiering.current_points', {
              userPoints: formatPoints(tierPoints?.earned?.total),
            }),
          ),
        }}
      />
    ),
    progressValue: getTierProgressPercentage(
      currentTierBaseValue,
      tierPoints?.remainingToReachNextTier,
      nextTierBaseValue,
    ),
  };
};

export type ActivityTierPoints = {
  tierPointAward: {
    points: number;
  };
};

export const getAnimatedProgressBarProps = (
  completed: boolean,
  userPoints: string,
  customActivity: ActivityTierPoints,
  currentTier?: TieringHubCurrentTierFragment | null,
  nextTier?: TieringHubNextTierFragment | null,
  tierPoints?: TieringHubTierPointsFragment | null,
  allTiers?: Array<TieringHubAllTiersFragment> | null,
): CustomTieringProgressBarProps | null => {
  if (
    !currentTier ||
    !currentTier.rank ||
    !nextTier ||
    !nextTier.rank ||
    !tierPoints ||
    !tierPoints.remainingToReachNextTier ||
    !allTiers ||
    !allTiers.length
  ) {
    return null;
  }

  const currentRank = currentTier.rank - 1;
  const nextRank = nextTier.rank - 1;

  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  const currentTierBaseValue = allTiers[currentRank].points;
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  const nextTierBaseValue = allTiers[nextRank].points;

  const currentTierName = tierNames[currentRank];
  const nextTierName = tierNames[nextRank];

  const awardForCurrentActivity = customActivity.tierPointAward?.points || 0;

  const potentialProgressValue = getTierProgressPercentage(
    currentTierBaseValue,
    tierPoints?.remainingToReachNextTier - awardForCurrentActivity,
    nextTierBaseValue,
  );

  return {
    ...getProgressBarProps(currentTier, nextTier, tierPoints, allTiers, {
      tierPointAward: {
        ...customActivity.tierPointAward,
      },
    }),
    completed,
    potentialProgressValue,
    fromTier: currentTierName,
    fromPoints: formatPoints(currentTierBaseValue),
    toTier: nextTierName,
    toPoints: formatPoints(nextTierBaseValue),
    userPoints,
    userPointsText: polyglot.t('tiering.custom_tiering.current_points', {
      userPoints,
    }),
    tooltipText: polyglot.t('tiering.custom_tiering.potential_points', {
      points: awardForCurrentActivity,
    }),
  };
};
