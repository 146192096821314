import { injectAsyncReducer } from '../../hermes-redux';
import USER_ROLES from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';
import { MyCareRoutes } from './my-care.constants';

const createResolverWithMoodTrackingReducer = pageImport =>
  Promise.all([
    pageImport,
    import(
      /* webpackChunkName: "mood-tracking-reducer" */ '../mood-tracking/state/reducer'
    ),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('moodTracking', reducer.default);
    return component;
  });

const createResolver = pageImport =>
  Promise.all([pageImport]).then(([component]) => {
    return component;
  });

const myCareModule = {
  hub: {
    name: MyCareRoutes.MY_CARE_HUB,
    url: '/my-care',
    resolver: () =>
      createResolverWithMoodTrackingReducer(
        import(
          /* webpackChunkName: "my-care-hub" */ '../my-care/container/my-care-hub/my-care-hub'
        ),
      ),
    restrictions: {
      user: [
        restrictions['care_plans'],
        restrictions['counsellor_appointments'],
        restrictions['care_coordination_specialists_messaging'],
      ],
      operator: 'OR',
    },
    data: {
      title: 'components.main_menu.care_plan',
      section: 'myCare',
      mainMenu: 'myCare',
      includeInMenu: true,
      hideFooter: true,
      order: 0,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  otherServices: {
    name: MyCareRoutes.MY_CARE_OTHER_SERVICES,
    url: '/my-care/other-services',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "my-care-other-services" */ '../my-care/container/other-services/other-services-container'
        ),
      ),
    restrictions: {
      user: [
        restrictions['care_plans'],
        restrictions['counsellor_appointments'],
        restrictions['care_coordination_specialists_messaging'],
      ],
      operator: 'OR',
    },
    data: {
      title: 'care_plan.work_life_services.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  assessmentResults: {
    name: MyCareRoutes.MY_CARE_ASSESMENT_RESULTS,
    url: '/my-care/assessments',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "my-care-assessment-results" */ '../my-care/container/assessment-results/assessment-results'
        ),
      ),
    restrictions: {
      user: [
        restrictions['care_plans'],
        restrictions['counsellor_appointments'],
        restrictions['care_coordination_specialists_messaging'],
      ],
      operator: 'OR',
    },
    data: {
      title: 'my_care.assessment_results.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  bookAppointment: {
    name: MyCareRoutes.BOOK_APPOINTMENT,
    url: '/tmh/book-appointment',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "my-care-book-appointment" */ '../my-care/container/book-appointment/book-appointment'
        ),
      ),
    restrictions: {
      user: [
        restrictions['care_plans'],
        restrictions['counsellor_access'],
        restrictions['counsellor_appointments'],
      ],
      operator: 'OR',
    },
    data: {
      title: 'appointments.your_counsellor.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  tmhChat: {
    name: MyCareRoutes.CHAT,
    url: '/tmh/chat',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "my-care-chat" */ '../chat/ccs/mobile/components/ccs-chat-mobile'
        ),
      ),
    restrictions: {
      user: [restrictions['care_coordination_specialists_messaging']],
    },
    data: {
      title: 'CCS Chat',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default myCareModule;
