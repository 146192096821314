export default function (url, config, checkRetina = false) {
  if (!url) {
    return '';
  }

  if (typeof url !== 'string') {
    url = url.url || '';
  }

  const retina = checkRetina && devicePixelRatio > 1;

  return url
    .replace(
      'h_%height%,w_%width%,%wam_img_params%',
      config + `,f_auto,q_auto,fl_lossy${retina ? ',dpr_2.0' : ''}`,
    )
    .replace('.%format%', '')
    .replace('http:', '');
}
