import type { Menu } from './types';
import Language from 'language';

import { MyCareRoutes } from 'pages/my-care/my-care.constants';
import { CarePlanRoutes } from 'pages/care-plan/care-plan.constants';
import { ModalTypesMap } from 'react-components/handshake/state/constants';
import { getStore } from 'app/hermes-redux';
import { openZendeskLinkWithToken } from 'state/global/actions';

export const MENU_STATES_MAPPING = {
  // work
  home: ['home'],
  feed: ['feed', 'feedSearch', 'feedDetail'],
  directory: ['directory', 'directorySearch'],
  groups: ['groups'],
  leaderboard: ['leaderboard'],
  profile: ['profile', 'profileEdit'],
  settings: ['settings'],

  // perks
  perksHomePage: [
    'perksHomePage',

    'shop',
    'shopCategory',
    'shopSearch',
    'shopDetailsCashback',
    'shopDetailsOffer',
    'cashbackSearch',

    'shopOnline',
    'shopOnlineBase',
    'shopOnlineSearch',
    'shopOnlineDetailsCashback',
    'shopOnlineDetailsOffer',

    'instoreOffers',
    'instoreOffersDetails',
    'top10OffersSearch',

    'colleagueOffers',
    'colleagueOffersDetails',
    'exclusiveOffersSearch',

    'restaurants',
    'restaurantsDetails',
    'restaurantsError',

    'giftcards',
    'giftcardsDetails',
    'giftcardsPopular',
    'giftcardsCategory',
    'giftcardsPayment',
    'giftcardsCodes',
    'giftcardsConfirmation',
    'giftcardsError',
    'giftCardsSearch',

    'cinemas',
    'cinemasDetails',
    'cinemasCodes',
    'cinemasPayment',
    'cinemasConfirmation',
  ],
  wallet: ['wallet'],
  spotRewards: [
    'spotRewards',
    'spotRewardsDetails',
    'spotRewardsPopular',
    'spotRewardsCategory',
    'spotRewardsPayment',
    'spotRewardsCodes',
    'spotRewardsConfirmation',
    'spotRewardsError',
    'spotRewardsSearch',
  ],

  // life
  employeeAssistance: [
    'employeeAssistance',
    'employeeAssistanceHome',
    'employeeAssistanceCategoriesListing',
    'employeeAssistanceCategories',
    'employeeAssistanceSearch',
    'employeeAssistanceArticles',
    'employeeAssistanceError',
  ],
  hwrHome: [
    'hwrHome',
    'employeeAssistance',
    'employeeAssistanceCategoriesListing',
    'employeeAssistanceCategories',
    'employeeAssistanceSearch',
    'employeeAssistanceArticles',
    'employeeAssistanceError',
  ],
  healthLibrary: ['healthLibrary'],
  wellnessTools: ['wellnessTools'],
  legalServices: ['legalServices'],
  chat: ['chat'],
  needHelp: ['needHelp'],
  generalEnquiry: ['generalEnquiry'],
  childCare: ['childCare'],
  counsellorLocator: ['counsellorLocator'],
  wellBeingHub: [
    'wellBeingHub',

    'programsAll',
    'programDetail',
    'moduleDetail',
    'programDetailOverview',

    'tiering',
    'tierRewards',

    'challengesHub',
    'challengeActivity',
    'challengeDetails',
    'challengesJoinHabit',
    'challengesHabitActivity',

    'selfAssessmentHub',
    'selfAssessmentAssessment',
    'selfAssessmentResults',
    'selfAssessmentHistoricalResult',
  ],
  myCare: [
    MyCareRoutes.MY_CARE_HUB,
    CarePlanRoutes.OVERVIEW,
    CarePlanRoutes.ACTIVITY,
  ],
};

export const KeyboardShortcutsMenuId = 'keyboard-shortcuts';
export const THVCMenuId = ModalTypesMap.VirtualCare;
export const THWBMenuId = ModalTypesMap.Wellbeing;

export const getInitialModel = (): Menu => ({
  home: {
    title: 'components.main_menu.home',
    order: -1,
    id: 'home',
    sections: [],
  },
  myCare: {
    title: 'components.main_menu.care_plan',
    order: 0,
    id: 'myCare',
    sections: [],
  },
  feed: {
    title: 'components.main_menu.news_feed',
    order: 1,
    id: 'feed',
    sections: [],
  },
  wellBeing: {
    title: 'components.main_menu.well_being_hub',
    order: 2,
    id: 'wellBeing',
    sections: [],
  },
  deals: {
    title: 'components.main_menu.deals',
    order: 3,
    id: 'deals',
    sections: [],
  },
  life: {
    title: 'components.main_menu.life.title',
    order: 4,
    id: 'life',
    sections: [],
  },
  company: {
    title: 'components.main_menu.company.title',
    order: 5,
    id: 'company',
    sections: [],
  },
  myAccount: {
    title: 'components.main_menu.myAccount',
    order: 6,
    id: 'myAccount',
    sections: [],
  },
});

export const getStaticSections = () => {
  const getLanguageLabel = item =>
    `${polyglot.t(`components.main_menu.language`)} ${polyglot.t(
      `supported_languages.${item}.name`,
    )} (${polyglot.t(`supported_languages.${item}.country_code`)})`;

  const selectedLanguage = getLanguageLabel(Language.getLocale());

  return {
    language: {
      data: { title: selectedLanguage },
      icon: false,
      name: 'settings',
      id: 'language-settings',
      group: 0,
    },
    logout: {
      data: { title: 'components.main_menu.logout' },
      type: 'link',
      icon: false,
      state: 'logout',
      id: 'logout',
      group: 0,
    },
    keyboardShortcuts: {
      type: 'menuButtonContextHandled',
      name: 'keyboard-shortcuts',
      data: {
        title: 'components.main_menu.keyboard_shortcuts',
      },
      id: KeyboardShortcutsMenuId,
      icon: false,
      group: 0,
    },
    accessibilityTips: {
      type: 'dynamicLink',
      name: 'accessibility-tips',
      data: {
        title: 'components.main_menu.accessibility_tips',
      },
      id: 'accessibility-tips',
      icon: true,
      group: 0,
      onClick: () => {
        getStore().dispatch(openZendeskLinkWithToken('accessibility_tips'));
      },
    },
  };
};
