import { getUrl } from 'router/navigation';
import { Group } from 'globals/types';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import { STATIC_POST, DynamicMapping, initPostsLimit } from './constants';
import { getBrandedData } from 'stores/company-store';
import { Filter } from './types';
import { PostItem } from 'wa-storybook/components/feed/posts/post.type';
import { EncryptUserHelpers } from '@workivate/tho-web-shared';

export function getPostImageAndLink(item: PostItem) {
  switch (item.type) {
    case 'NewRecognition':
      return item.data?.['limited_target_list']?.length === 1
        ? {
            imageUrl: item.data['limited_target_list'][0]['image_profile']?.url,
            url: getUrl('profile', {
              userId: EncryptUserHelpers.encryptUserId(
                item.data['limited_target_list'][0]['user_id'],
              ),
            }),
          }
        : {
            imageUrl: item.data.badge?.image.url,
            url: null,
          };

    case 'NewUser':
    case 'UserAdHoc':
    case 'CompanyNews':
    case 'Anniversary':
    case 'BirthdayUser':
    case 'MilestoneUser':
      return {
        imageUrl: item.data?.['source_user']?.['image_profile']?.url,
        url: getUrl('profile', {
          userId: EncryptUserHelpers.encryptUserId(
            item.data?.['source_user']?.['user_id'],
          ),
        }),
      };

    case 'BirthdayCompany':
    case 'AdminAdHoc':
    case 'MostRecognized':
    case 'CompanyEstablished':
    case 'Twitter':
    case 'CompanyStartUsingWam':
      return {
        imageUrl: getBrandedData('image_square_logo').url,
        url: null,
      };
  }
}

export function concatPosts(
  oldList: PostItem[],
  newList: PostItem[],
  updateCache: boolean,
) {
  const isAllPostsNew = getIsAllPostsNew(oldList, newList);

  if (updateCache && isAllPostsNew) {
    return newList;
  }

  const filteredList = (newList || []).filter(
    item => !oldList.find(elem => elem['post_id'] === item['post_id']),
  );

  const updatedList = updateCache
    ? insertPosts(oldList, filteredList)
    : [...oldList, ...filteredList];

  return updatedList;
}

export const getIsAllPostsNew = (oldList: PostItem[], newList: PostItem[]) => {
  if (!newList) return false;

  const offset = getPostsOffset(newList);

  // filter out the new posts
  const filteredList = (newList || []).filter(
    newPost =>
      !oldList.find(oldPost => oldPost['post_id'] === newPost['post_id']),
  );

  const isAllPostNew = filteredList.length === initPostsLimit - offset;

  return isAllPostNew;
};

export const getPostsOffset = (posts: PostItem[]) => {
  // calculate the number of fixed posts (i.e. wellness or promotion post)

  const firstPost = posts[0];

  if (!isWellness(firstPost) && !isPinned(firstPost)) {
    return 0;
  } else if (isWellness(firstPost) && isPinned(posts[1])) {
    return 2;
  }

  return 1;
};

export function timestampIsToday(timestamp: number) {
  const today = new Date();
  return new Date(timestamp * 1000).toDateString() === today.toDateString();
}

export function insertPosts(posts: PostItem[], newPosts: PostItem[]) {
  const offset = getPostsOffset(posts);
  const offsetPosts = posts.slice(0, offset);
  const restOfPosts = posts.slice(offset);

  return [...offsetPosts, ...newPosts, ...restOfPosts];
}

export function buildDropdownFilter(
  possibleFilters: Filter[],
  filterFunc: (feature: string | null) => void,
) {
  const defaultFilters = [
    {
      name: 'feed.filter.everything',
      value: 'everything',
      dataHook: 'all-posts',
    },
  ];
  const featureDependsFilters = possibleFilters.filter(item =>
    filterFunc(item.feature),
  );

  return [...defaultFilters, ...featureDependsFilters];
}

export function isPostInFilter(
  postType: string,
  currentFilter: string,
): boolean {
  return (
    !currentFilter ||
    currentFilter.toLowerCase() === 'everything' ||
    (currentFilter.toLowerCase() === 'posts' &&
      postType.toLowerCase() === 'post') ||
    (currentFilter.toLowerCase() === 'recognitions' &&
      postType.toLowerCase() === 'recognition')
  );
}

export function includesGroup(groups: Group[] = [], groupId: string) {
  return some(groups, group => group.id === groupId);
}

export function getGroupList(
  isSubscribed: boolean,
  subscribedGroups: Group[],
  group: Group,
) {
  return isSubscribed
    ? [group, ...subscribedGroups]
    : subscribedGroups.filter(item => item.id !== group.id);
}

export function findPostIndexWithId(posts: PostItem[], postId: string) {
  return posts.findIndex(post => post['post_id'] === postId);
}

export function isPostInSearch(post: PostItem, query: string) {
  // Check does the new post/recognition message contains the query string
  // Search done similar to the back-end, only when the whole word is the post pass search query
  return !isEmpty(
    intersection(
      flatten(
        map(post.data['message_component_list'], item =>
          item.display.toLowerCase().split(/[^\w]+/),
        ),
      ),
      query.toLowerCase().split(/[^\w]+/),
    ),
  );
}

export function isWellness(post?: PostItem) {
  return post?.type && post.type.toLowerCase() === 'wellness';
}

export function isPinned(item?: PostItem) {
  return (
    item?.data &&
    item.data['promotion_type'] === STATIC_POST.TYPE.PINNED &&
    !item.data['promoted_post_seen']
  );
}

export function isSticked(item?: PostItem) {
  return (
    item?.data &&
    item.data['promotion_type'] === STATIC_POST.TYPE.STICKY &&
    !item.data['promoted_post_seen']
  );
}

export function isDynamic(item: PostItem) {
  return Object.keys(DynamicMapping).includes(item.type);
}

export function isPromoted(item?: PostItem) {
  return !!item?.data['promotion_type'];
}

export function findFilterName(filters: [Filter], value: string) {
  return filters.filter((filter: Filter) => filter.value === value)[0]?.name;
}
