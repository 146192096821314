import type { Action } from 'globals/types';
import type { ModalPayload } from './types';

export const openModal = (
  template: any,
  payload?: ModalPayload | null | Record<string, unknown>,
  style?: any | null,
  isClosable?: boolean | null,
  className?: string | null,
  fitContents?: boolean,
  shouldCloseOnOverlayClick?: boolean,
): Action => {
  return {
    type: 'MODAL/OPEN',
    template: template,
    payload: payload,
    style: style,
    isClosable: isClosable,
    className: className,
    fitContents,
    shouldCloseOnOverlayClick,
  };
};

export const closeModal = (): Action => {
  return { type: 'MODAL/CLOSE' };
};

export const changeSlide = (index: number): Action => {
  return { type: 'MODAL/CHANGE_SLIDE', index };
};
