import {
  ApolloClient,
  from,
  HttpLink,
  split,
  HttpOptions,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { default as hermesVersion } from 'version';
import { getToken } from '../state/session/helpers';
import { format } from 'date-fns';
import Language from 'language';

import apolloCache from './apolloCache';
import errorLink from './errorLink';
import { getRegionalApiEndpoints } from '../globals/regional-api';
import { isUnifiedLoginEnabled } from 'globals/testable';
import { getAuthenticationHeader } from 'globals/fetch.helpers';

const getWellBeingGraphQLMockURI = () =>
  `${
    getRegionalApiEndpoints().wellbeing_root
  }/wellbeing-graphql-api/mock/graphql`;

const getWellBeingGraphQLURI = () =>
  `${getRegionalApiEndpoints().wellbeing_root}/wellbeing-graphql-api/graphql`;

const createClient = (isMock: boolean) => {
  const name = 'lifeworks-web-app';
  const version = `hermes@v${hermesVersion.version}`;

  const linkBaseConfig: HttpOptions = {
    fetch: async (_, options) => {
      const graphQLURI = isMock
        ? getWellBeingGraphQLMockURI()
        : getWellBeingGraphQLURI();

      let effectiveHeaders = {};
      if (isUnifiedLoginEnabled()) {
        effectiveHeaders = {
          ...(await getAuthenticationHeader()),
        };
      } else {
        effectiveHeaders = {
          'wam-token': getToken() || '',
        };
      }

      return fetch(graphQLURI, {
        ...options,
        headers: {
          ...options?.headers,
          ...effectiveHeaders,
          'lw-language': Language.getLocale(),
          'Client-Date': format(new Date(), `yyyy-MM-dd'T'HH:mm:ssxxx`),
          'Client-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
    },
  } as const;

  const cache = apolloCache();

  const httpLink = new HttpLink(linkBaseConfig);

  const batchHttpLink = new BatchHttpLink({
    ...linkBaseConfig,
    batchMax: 20,
    batchInterval: 100,
  });

  /*
  Batching 0f queries is on by default but can be disabled by setting doNotBatch
  For example:

  fetchFeaturedChallengesQuery,
    {
      variables: {
        from: getFromDate(new Date()),
      },
      fetchPolicy: 'network-only',
      context: {
        doNotBatch: true,
      },
    },
   */
  return new ApolloClient({
    link: from([
      errorLink(),
      split(
        operation => operation.getContext()['doNotBatch'] === true,
        httpLink, // if the test is true -- debatch
        batchHttpLink, // otherwise, batching is fine
      ),
    ]),
    cache,
    name,
    version,
  });
};

export const client = createClient(false);
export const mockClient = createClient(true);
