import USER_ROLES from 'constants/user-roles.constant';

const userNotFoundModule = {
  name: 'userNotFound',
  url: '/user-not-found',
  resolver: () =>
    import(/* webpackChunkName: "user-not-found" */ './user-not-found-comp'),
  data: {
    title: 'components.main_menu.no_user',
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default userNotFoundModule;
