// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** function to get dimensions
  usage example to get \`tablet\` value from \`\$page-max-widths\`
  .class {
    max-width: dim(\$page-max-widths, tablet);
  }

 */
.need-help-module__need-help___XoB2o {
  color: var(--primary600, #4b286d);
  cursor: pointer;
  font-weight: 600;
}
.need-help-module__need-help___XoB2o:focus {
  outline: var(--focus, #2681e1) solid 2px;
  outline-offset: 3px;
}
.need-help-module__need-help___XoB2o:hover {
  color: var(--primary600, #4b286d);
}`, "",{"version":3,"sources":["webpack://./node_modules/wa-storybook/styles/tools/functions/_widths.scss","webpack://./client/app/react-components/modal-templates/templates/codes-modal/components/need-help/need-help.module.scss","webpack://./node_modules/wa-storybook/styles/tools/mixins/_focus-outline.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;ACEA;EACE,iCAAA;EACA,eAAA;EACA,gBAAA;AAMF;ACJI;EACE,wCAAA;EACA,mBAAA;ADMN;AAPE;EACE,iCAAA;AASJ","sourcesContent":["/** function to get dimensions\n  usage example to get `tablet` value from `$page-max-widths`\n  .class {\n    max-width: dim($page-max-widths, tablet);\n  }\n\n */\n\n@function dim($prop, $key) {\n  @return map-get($prop, $key);\n}\n","@import '~wa-storybook/styles/utils';\n\n.need-help {\n  color: color(primary600);\n  cursor: pointer;\n  font-weight: 600;\n  @include focus-outline;\n\n  &:hover {\n    color: color(primary600);\n  }\n}\n","@import '../functions/color';\n\n@mixin focus-outline($color: color(focus), $applyDirectly: false) {\n  @if $applyDirectly {\n    outline: $color solid 2px;\n    outline-offset: 3px;\n  } @else {\n    &:focus {\n      outline: $color solid 2px;\n      outline-offset: 3px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"need-help": `need-help-module__need-help___XoB2o`
};
export default ___CSS_LOADER_EXPORT___;
