// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** function to get dimensions
  usage example to get \`tablet\` value from \`\$page-max-widths\`
  .class {
    max-width: dim(\$page-max-widths, tablet);
  }

 */
.codes-module__modal___Hr6aP {
  background-color: var(--base, #f4f4f7);
  overflow-y: scroll;
  max-height: 70vh;
}

.codes-module__loading___yvgl8 {
  height: 70px;
}

.codes-module__not-found___ISVd7 {
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./node_modules/wa-storybook/styles/tools/functions/_widths.scss","webpack://./client/app/pages/spot-rewards/view/components/codes/codes.module.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;ACEA;EACE,sCAAA;EACA,kBAAA;EACA,gBAAA;AAMF;;AAHA;EACE,YAAA;AAMF;;AAHA;EACE,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AAMF","sourcesContent":["/** function to get dimensions\n  usage example to get `tablet` value from `$page-max-widths`\n  .class {\n    max-width: dim($page-max-widths, tablet);\n  }\n\n */\n\n@function dim($prop, $key) {\n  @return map-get($prop, $key);\n}\n","@import '~wa-storybook/styles/utils.scss';\n\n.modal {\n  background-color: color(base);\n  overflow-y: scroll;\n  max-height: 70vh;\n}\n\n.loading {\n  height: 70px;\n}\n\n.not-found {\n  height: 50px;\n  line-height: 50px;\n  text-align: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `codes-module__modal___Hr6aP`,
	"loading": `codes-module__loading___yvgl8`,
	"not-found": `codes-module__not-found___ISVd7`
};
export default ___CSS_LOADER_EXPORT___;
