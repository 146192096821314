import { getStore } from '../hermes-redux';
import { logout } from '../state/session/actions';
import { onError } from '@apollo/client/link/error';
import { ServerParseError } from '@apollo/client';
import { AppLogger, DatadogClient } from '@workivate/tho-web-shared';

import {
  sessionInvalidationTypes,
  sessionInvalidationHTTPStatus,
} from 'wa-storybook/components/modal/session-invalidation-alert-modal/constants';

const errorLink = () =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(error => {
        DatadogClient.addError(error);
        DatadogClient.logError(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
        );
      });
    }
    if (networkError) {
      DatadogClient.addError(networkError);
      DatadogClient.logError(
        `[GraphQL error][Network error]: ${JSON.stringify(networkError)}`,
      );
      if (
        (networkError as ServerParseError).statusCode ===
        sessionInvalidationHTTPStatus
      ) {
        getStore().dispatch(
          logout(true, sessionInvalidationTypes.sessionTimeout),
        );

        AppLogger.log('Logging out');
      }
    }
  });

export default errorLink;
