import Page from 'wa-storybook/components/layouts/page/page';
import Spinner from 'wa-storybook/components/general/spinner/spinner';
import actions from './../state/actions';
import * as thunks from './../state/thunks';
import { openModal } from '../../../state/modal/actions';
import type { AppState, Dispatch } from 'globals/types';
import { connectWithViewport } from './../../../hermes-redux';
import { AppLogger } from '@workivate/tho-web-shared';
import Card from './components/card/card';

import Carousel from 'carousel/carousel';
import Codes from './components/codes/codes';
import Hero from './components/hero/hero';
import NoTransactions from './components/no-transactions/no-transactions';

import PermanentToast from 'permanent-toast/permanent-toast';
import TotalBanner from './components/total-banner/total-banner';

import { goTo } from 'router/navigation';
import type { Fund, Reward, RewardsTransactions } from './../state/types';
import NeedHelp from '../../../react-components/modal-templates/templates/codes-modal/components/need-help/need-help';

type Props = {
  dispatch: Dispatch;
  fetchSpotRewards: () => void;
  fetchFundTransactions: () => void;
  fetchYourRewards: () => void;
  openModal: (
    template: any,
    payload?: any | null | undefined,
    style?: any | null | undefined,
    isClosable?: boolean | null | undefined,
  ) => void;
  spotRewards: {
    fund: Fund;
    rewards: Array<Reward>;
    transactions: Array<RewardsTransactions>;
  };
  showCodes?: boolean;
};

class SpotRewardsHub extends React.Component<Props> {
  handleChooseReward() {
    goTo('spotRewardsGiftcards');
  }

  viewRewards() {
    const {
      dispatch,
      openModal,
      spotRewards: { rewards },
    } = this.props;
    openModal(
      Codes,
      {
        noHeader: false,
        noFooter: false,
        label: polyglot.t('giftcards.giftcard_codes'),
        footerMessage: (
          <NeedHelp referrer={'spotRewards'} dispatch={dispatch} />
        ),
        orders: rewards,
      },
      { width: 800 },
      true,
    );
  }

  async componentDidMount() {
    const { fetchSpotRewards, fetchFundTransactions, fetchYourRewards } =
      this.props;

    try {
      await fetchSpotRewards();
      await Promise.all([fetchFundTransactions(), fetchYourRewards()]);
    } catch (e: any) {
      AppLogger.error(e);
    }
  }

  render() {
    const {
      showCodes,
      spotRewards: { fund, transactions },
    } = this.props;

    const cardsToShow = context.viewport.isMobile ? 1 : 3;

    const hasFunds = !!(fund && fund.id);
    const currentBalance = (hasFunds && fund.balance.amount / 100) || 0;
    const lifetimeBalance = (hasFunds && fund.total_received.amount / 100) || 0;

    if (showCodes) {
      this.viewRewards();
    }

    if (!hasFunds) {
      return <Spinner />;
    }

    return (
      <div>
        <PermanentToast onClick={() => this.viewRewards()}>
          <span>{polyglot.t('rewards.banner.view')}</span>
        </PermanentToast>
        <Page>
          <Page.Hero>
            <Hero
              currentBalance={currentBalance}
              onClick={this.handleChooseReward}
            />
          </Page.Hero>
          <div>
            <TotalBanner amount={lifetimeBalance} />
            <Page.Content noPadding>
              {transactions.length > 0 ? (
                <Carousel
                  arrowClassName='arrow-container--spot-rewards'
                  arrows={true}
                  infinite={false}
                  slidesToShow={cardsToShow}
                  // @ts-expect-error
                  template={Card}
                >
                  {transactions.map((item, key) => (
                    <span key={key}>
                      <Card article={item} />
                    </span>
                  ))}
                </Carousel>
              ) : (
                <NoTransactions />
              )}
            </Page.Content>
          </div>
        </Page>
      </div>
    );
  }
}

const mapStateToProps = ({ spotRewards }: AppState) => ({ spotRewards });

export default connectWithViewport(SpotRewardsHub, mapStateToProps, {
  ...actions,
  openModal,
  ...thunks,
});
