import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useCombobox, UseComboboxStateChange } from 'downshift';

import MagGlass from 'wa-storybook/components/general/icon/components/mag-glass';
import Button from 'wa-storybook/components/general/button/button';
import { Arrow } from 'wa-storybook/components/lists/activity-list-item/parts/arrow/arrow';

import fullPageStyles from 'perks/layouts/full-width.module.scss';
import styles from './perks-search-bar.module.scss';

type Props = {
  backdrop?: boolean;
  containerClassName?: string;
  inputClassName?: string;
  onSearch?: (searchTerm: string) => undefined | Promise<any>;
  onSubmit?: (searchTerm: string) => void;
  query?: string;
  searchTerm?: string;
  recentSearch?: Array<string>;
};

const PerksSearchBar = ({
  backdrop = true,
  containerClassName,
  inputClassName,
  onSearch,
  onSubmit,
  searchTerm,
  recentSearch = [],
}: Props) => {
  const [value, setValue] = useState<string>(searchTerm || '');
  const [isOnFocus, setIsOnFocus] = useState<boolean>(false);
  const { isMobile, isTablet } = context.viewport;

  const shouldDisplayButton = !isMobile && !isTablet;

  useEffect(() => {
    if (searchTerm) {
      setValue(searchTerm);
    }
  }, [searchTerm]);

  const onClick = event => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(value);
    }
    if (!onSubmit && onSearch) {
      onSearch(value);
    }
  };

  const onInputValueChange = ({
    inputValue = '',
  }: UseComboboxStateChange<string>) => {
    const isSearchTermOnlyMadeOfSpaces = /^\s+$/.test(inputValue);

    if (!isSearchTermOnlyMadeOfSpaces) {
      setValue(inputValue);
      if (onSearch) {
        onSearch(inputValue);
      }
    }
  };

  const keyDownHandler = e => {
    // By default Downshift closes the dropdown with the first press of enter,
    // we don't want this to be the case if we don't have any items to display in the dropdown to begin with
    if (e.key === 'Enter' && !recentSearch.length && isOpen) {
      return (e.nativeEvent.preventDownshiftDefault = true);
    }
  };

  const {
    isOpen,
    getInputProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    inputValue,
  } = useCombobox({
    items: recentSearch,
    onInputValueChange,
    onSelectedItemChange: ({ selectedItem }) => {
      if (onSubmit && selectedItem) {
        onSubmit(selectedItem);
      }
    },
  });

  return (
    <div
      className={classNames(
        fullPageStyles['page'],
        styles.container,
        containerClassName && styles[containerClassName],
      )}
    >
      {backdrop && (
        <div
          className={classNames(
            styles.backdrop,
            !!isOnFocus && styles['backdrop--focused'],
          )}
        />
      )}

      <form
        id='search-container'
        onSubmit={onClick}
        className={classNames(
          styles['input-area-container'],
          isOnFocus && styles['input-focused'],
          inputClassName && styles[inputClassName],
        )}
        role='search'
        aria-label={polyglot.t('components.perks_search_bar.aria_label')}
        aria-controls='perks-search-results-page-status'
      >
        <label
          className={classNames(styles['input-container'])}
          aria-labelledby='search-container'
          {...getLabelProps({
            onFocus: () => {
              setIsOnFocus(true);
              openMenu();
            },
            onBlur: () => {
              setIsOnFocus(false);
            },
          })}
        >
          <div className={classNames(styles['search-icon'])} aria-hidden>
            <MagGlass />
          </div>

          <input
            {...getInputProps(
              {
                type: 'text',
                placeholder: polyglot.t(
                  'components.perks_search_bar.placeholder',
                ),
                'aria-placeholder': polyglot.t(
                  'components.perks_search_bar.placeholder',
                ),
                className: classNames(
                  styles['input-search'],
                  !backdrop && styles['input-search--no-backdrop'],
                  'no-focus',
                ),
                value: searchTerm ? searchTerm : inputValue,
                onKeyDown: keyDownHandler,
              },
              { suppressRefError: true },
            )}
            {...{ 'data-hook': 'perks-search-bar-input' }}
          />

          <ul {...getMenuProps(undefined, { suppressRefError: true })}>
            {isOpen && recentSearch.length > 0 && (
              <div className={styles['recent-search']}>
                <div className={styles['recent-search--title']}>
                  {polyglot.t('components.perks_search_bar.recent')}
                </div>
                {recentSearch.map((item, index) => (
                  <li
                    className={classNames(
                      styles['recent-search--item'],
                      highlightedIndex === index &&
                        styles['recent-search--item-focused'],
                    )}
                    key={`${item}${index}`}
                    {...getItemProps({ item, index })}
                  >
                    {item}
                    <Arrow />
                  </li>
                ))}
              </div>
            )}
          </ul>
        </label>

        {shouldDisplayButton && (
          <div
            className={classNames(
              styles['button-wrapper'],
              !backdrop && styles['button-wrapper--no-backdrop'],
              isOnFocus && styles['button-wrapper-focused'],
            )}
          >
            <Button
              type='submit'
              dataHook='perks-search-bar-button'
              text={polyglot.t('components.perks_search_bar.search')}
              theme='contained--on-surface'
              icon={MagGlass}
              iconLocation='left'
              isDisabled={!value}
              size='x-large'
            />
          </div>
        )}
      </form>
    </div>
  );
};

PerksSearchBar.displayName = 'PerksSearchBar';

export default PerksSearchBar;
