import { useMemo } from 'react';

import VirtualCare from '../assets/virtual-care/ic_virtual_care.svg';
import Wellbeing from '../assets/wellbeing/ic_wellbeing.svg';

export type ModalTypes = 'virtual_care' | 'wellbeing';

export enum ModalTypesMap {
  VirtualCare = 'virtual_care',
  Wellbeing = 'wellbeing',
}

export enum ModalSlideMap {
  Account = 'account-slide',
  Information = 'information-slide',
}

export enum ModalSlideKeyMap {
  Account = 'handshake-modal-account-slide',
  Information = 'handshake-modal-information-slide',
}

export const useModalHeader = (type: ModalTypes) =>
  useMemo(
    () =>
      ({
        virtual_care: {
          text: polyglot.t('handshake_integration.virtual_care.title'),
          icon: VirtualCare,
        },
        wellbeing: {
          text: polyglot.t('handshake_integration.wellbeing.title'),
          icon: Wellbeing,
        },
      })[type],
    [type],
  );
