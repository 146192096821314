import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  GraphQLDate: { input: string; output: string };
  GraphQLDateTime: { input: string; output: string };
};

export enum ActivityDeviceType {
  AppleWatch = 'APPLE_WATCH',
  Phone = 'PHONE',
  Watch = 'WATCH',
}

export enum ActivitySourceDevice {
  Fitbit = 'FITBIT',
  Googlefit = 'GOOGLEFIT',
  Healthconnect = 'HEALTHCONNECT',
  Healthkit = 'HEALTHKIT',
  Manual = 'MANUAL',
  None = 'NONE',
}

/** Legacy type from ChallengeListItem, deprecated */
export type ActivityTrackingDailyTotal = {
  __typename: 'ActivityTrackingDailyTotal';
  date?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  tracked: Scalars['Int']['output'];
};

export type AnswerAssessmentQuestionsInput = {
  answers?: InputMaybe<Array<InputMaybe<AssessmentAnswersInput>>>;
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
  uuid: Scalars['ID']['input'];
};

export type AnswerAssessmentQuestionsResponse = {
  __typename: 'AnswerAssessmentQuestionsResponse';
  assessment?: Maybe<AssessmentListItem>;
  assessmentHeroCard?: Maybe<AssessmentHeroCard>;
  originalTierState?: Maybe<AssessmentItemCurrentTierProgress>;
  tierProgressPointsDifference?: Maybe<Scalars['Int']['output']>;
};

/** This can be extended in future to support additional question types */
export type AnswerPreAssessmentInput = {
  careAssessmentId: Scalars['ID']['input'];
  choiceAnswers?: InputMaybe<Array<CareAssessmentChoiceAnswer>>;
  freeTextAnswers?: InputMaybe<Array<CareAssessmentFreeTextAnswer>>;
  /** Optional, used when responses are part of a retake session */
  sessionId?: InputMaybe<Scalars['String']['input']>;
};

export type AnswerPreAssessmentResponse = {
  __typename: 'AnswerPreAssessmentResponse';
  /** The next assessment in the pre assessment sequence. Null if there are no more asseessments remaining for pre assessment. */
  nextAssessment?: Maybe<CareAssessment>;
  /** The recommended action the user should take at this point in the pre assessment process. Based on the users current pre assessment progress. */
  recommendation?: Maybe<PreAssessmentRecommendation>;
  /** Score results. Non-null when the score screen needs to be displayed at the end. Is expected that `nextAssessment` is null at this point. */
  results?: Maybe<PreAssessmentResults>;
};

export type Appointment = {
  __typename: 'Appointment';
  allowsCancellation: Scalars['Boolean']['output'];
  counsellor: Counsellor;
  endDateTime: Scalars['GraphQLDateTime']['output'];
  id: Scalars['ID']['output'];
  isVisibleToUser: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  startDateTime: Scalars['GraphQLDateTime']['output'];
  status: AppointmentStatus;
  typeDescription: AppointmentType;
};

export type AppointmentPaymentSetupIntentResponse = {
  __typename: 'AppointmentPaymentSetupIntentResponse';
  stripeIntent: StripeSetupIntent;
};

export enum AppointmentStatus {
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
}

export enum AppointmentType {
  InPerson = 'IN_PERSON',
  PhoneCall = 'PHONE_CALL',
  VideoCall = 'VIDEO_CALL',
}

export type AppointmentsDateTimeFilter = {
  from?: InputMaybe<Scalars['GraphQLDateTime']['input']>;
  to?: InputMaybe<Scalars['GraphQLDateTime']['input']>;
};

/**
 * Article is a shared type used by both Assessments and Digital Clinical Programs (CareNow)
 * It contains a superset of the fields that may be present in each usage of the type
 * in the schema. However, only contentItemId, title and body will be populated for Assessments.
 * It may be better to split these types at some point.
 */
export type Article = {
  __typename: 'Article';
  audios?: Maybe<Array<ArticleAudio>>;
  author?: Maybe<ArticleAuthor>;
  body: Scalars['String']['output'];
  categoryTitle?: Maybe<Scalars['String']['output']>;
  contentItemId: Scalars['ID']['output'];
  heroImage?: Maybe<Image>;
  lastModificationDate?: Maybe<Scalars['GraphQLDateTime']['output']>;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  userInteraction?: Maybe<ArticleInteraction>;
  videos?: Maybe<Array<ArticleVideo>>;
};

export type ArticleAudio = {
  __typename: 'ArticleAudio';
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type ArticleAuthor = {
  __typename: 'ArticleAuthor';
  image?: Maybe<Image>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  surname: Scalars['String']['output'];
};

export type ArticleDocument = {
  __typename: 'ArticleDocument';
  copyright?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  format: Scalars['String']['output'];
  pages?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  size: Scalars['String']['output'];
  thumbnail?: Maybe<Image>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ArticleInteraction = {
  __typename: 'ArticleInteraction';
  seenByUser: Scalars['Boolean']['output'];
};

export type ArticleInteractionInput = {
  contentItemId: Scalars['ID']['input'];
  seenByUser: Scalars['Boolean']['input'];
};

export enum ArticleType {
  Audio = 'AUDIO',
  Journal = 'JOURNAL',
  Text = 'TEXT',
  Video = 'VIDEO',
}

export type ArticleVideo = {
  __typename: 'ArticleVideo';
  /** If hlsUrl is not provided the client should fall back to url */
  hlsUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type AskForImmediateHelpResponse = {
  __typename: 'AskForImmediateHelpResponse';
  ccsTeamTelephoneNumber: Scalars['String']['output'];
};

export type AssessedAsSuicideRiskResponse = {
  __typename: 'AssessedAsSuicideRiskResponse';
  ccsTeamTelephoneNumber: Scalars['String']['output'];
};

export type AssessmentAnswersInput = {
  measurements?: InputMaybe<
    Array<InputMaybe<AssessmentAnswersMeasurementsInput>>
  >;
  optionUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionUuid: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AssessmentAnswersMeasurementsInput = {
  calculationType?: InputMaybe<Scalars['String']['input']>;
  measurementType?: InputMaybe<Scalars['String']['input']>;
  measurementUuid: Scalars['ID']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum AssessmentCategory {
  Biometric = 'BIOMETRIC',
  Financial = 'FINANCIAL',
  Health = 'HEALTH',
  Lifestyle = 'LIFESTYLE',
  Other = 'OTHER',
  Thi = 'THI',
}

export type AssessmentContentCategory = {
  __typename: 'AssessmentContentCategory';
  title: Scalars['String']['output'];
};

export type AssessmentContentMeasurementResult = {
  __typename: 'AssessmentContentMeasurementResult';
  feedback?: Maybe<MeasurementResultFeedback>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type AssessmentContentStats = {
  __typename: 'AssessmentContentStats';
  ranking?: Maybe<Scalars['Int']['output']>;
};

export type AssessmentContentType = {
  __typename: 'AssessmentContentType';
  description?: Maybe<Scalars['String']['output']>;
  handleUuid?: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  typeGroup?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export enum AssessmentGroupType {
  Hra = 'HRA',
  Thi = 'THI',
}

export type AssessmentHeroCard =
  | HealthRiskAssessmentCard
  | SingleAssessmentCard
  | TotalHealthIndexCard;

export type AssessmentItemAnswers = {
  __typename: 'AssessmentItemAnswers';
  measurements?: Maybe<Array<Maybe<AssessmentItemAnswersMeasurements>>>;
  optionUuids?: Maybe<Array<Scalars['ID']['output']>>;
  questionUuid: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type AssessmentItemAnswersMeasurements = {
  __typename: 'AssessmentItemAnswersMeasurements';
  calculationType?: Maybe<Scalars['String']['output']>;
  measurementType?: Maybe<Scalars['String']['output']>;
  measurementUuid: Scalars['ID']['output'];
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type AssessmentItemContentAuthor = {
  __typename: 'AssessmentItemContentAuthor';
  bio?: Maybe<Scalars['String']['output']>;
  images?: Maybe<AssessmentItemContentImages>;
  name?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
};

export type AssessmentItemContentImages = {
  __typename: 'AssessmentItemContentImages';
  articleinline?: Maybe<Scalars['String']['output']>;
  articlethumbnail?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  documentlandscape?: Maybe<Scalars['String']['output']>;
  documentportrait?: Maybe<Scalars['String']['output']>;
  original?: Maybe<Scalars['String']['output']>;
};

export type AssessmentItemCurrentTierProgress = {
  __typename: 'AssessmentItemCurrentTierProgress';
  bonusPointsForCompletingAllAssessments?: Maybe<Scalars['Int']['output']>;
  pointsForCompletingAssessment?: Maybe<Scalars['Int']['output']>;
  tierSummary?: Maybe<CurrentTierProgressSummary>;
};

export type AssessmentItemHistory = {
  __typename: 'AssessmentItemHistory';
  archiveId: Scalars['ID']['output'];
  assessmentUuid: Scalars['ID']['output'];
  completedAt?: Maybe<Scalars['GraphQLDateTime']['output']>;
  cooldownPeriodExpirationDate?: Maybe<Scalars['GraphQLDateTime']['output']>;
  introduction: Scalars['String']['output'];
  numberOfQuestions: Scalars['Int']['output'];
  result?: Maybe<AssessmentItemHistoryResult>;
  title: Scalars['String']['output'];
};

export type AssessmentItemHistoryResult = {
  __typename: 'AssessmentItemHistoryResult';
  digest?: Maybe<Scalars['String']['output']>;
  digestTitle: Scalars['String']['output'];
  maxScaleLabel?: Maybe<Scalars['String']['output']>;
  minScaleLabel?: Maybe<Scalars['String']['output']>;
  percentageScore?: Maybe<Scalars['Float']['output']>;
  shouldDisplayPercentage?: Maybe<Scalars['Boolean']['output']>;
};

export type AssessmentItemQuestionnaire = {
  __typename: 'AssessmentItemQuestionnaire';
  introduction: Scalars['String']['output'];
  measurements?: Maybe<Array<Maybe<AssessmentItemQuestionnaireMeasurements>>>;
  multipleChoice?: Maybe<Scalars['Boolean']['output']>;
  options?: Maybe<Array<Maybe<AssessmentItemQuestionnaireOptionsList>>>;
  questionType?: Maybe<QuestionnaireQuestionType>;
  reference?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type AssessmentItemQuestionnaireMeasurementRanges = {
  __typename: 'AssessmentItemQuestionnaireMeasurementRanges';
  label?: Maybe<Scalars['String']['output']>;
  ranges?: Maybe<Array<MeasurementRanges>>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type AssessmentItemQuestionnaireMeasurements = {
  __typename: 'AssessmentItemQuestionnaireMeasurements';
  calculationType?: Maybe<Scalars['String']['output']>;
  measurementRanges?: Maybe<
    Array<Maybe<AssessmentItemQuestionnaireMeasurementRanges>>
  >;
  unitOfMeasurement?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type AssessmentItemQuestionnaireOptionAlert = {
  __typename: 'AssessmentItemQuestionnaireOptionAlert';
  message?: Maybe<Scalars['String']['output']>;
};

export type AssessmentItemQuestionnaireOptionsList = {
  __typename: 'AssessmentItemQuestionnaireOptionsList';
  alert?: Maybe<AssessmentItemQuestionnaireOptionAlert>;
  jumpTo?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  uuid: Scalars['ID']['output'];
};

export type AssessmentListItem = {
  __typename: 'AssessmentListItem';
  answers?: Maybe<Array<Maybe<AssessmentItemAnswers>>>;
  canBeRetaken?: Maybe<Scalars['Boolean']['output']>;
  cooldownPeriodExpirationDate?: Maybe<Scalars['GraphQLDateTime']['output']>;
  history?: Maybe<Array<Maybe<AssessmentItemHistory>>>;
  icon?: Maybe<Image>;
  introduction: Scalars['String']['output'];
  lastUpdatedAt?: Maybe<Scalars['GraphQLDateTime']['output']>;
  localisedUuid: Scalars['ID']['output'];
  nextAssessment?: Maybe<AssessmentListItem>;
  nextQuestionReference?: Maybe<Scalars['String']['output']>;
  numberOfQuestions: Scalars['Int']['output'];
  percentageComplete: Scalars['Float']['output'];
  questionnaire?: Maybe<Array<Maybe<AssessmentItemQuestionnaire>>>;
  remainingAssessmentsCompletionPoints?: Maybe<RemainingAssessmentsCompletionPoints>;
  result?: Maybe<AssessmentListItemResult>;
  status: AssessmentStatus;
  tierPoints?: Maybe<Scalars['Int']['output']>;
  tierProgress?: Maybe<AssessmentItemCurrentTierProgress>;
  title: Scalars['String']['output'];
  type: AssessmentType;
  uuid: Scalars['ID']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type AssessmentListItemResult = {
  __typename: 'AssessmentListItemResult';
  content?: Maybe<Array<Maybe<AssessmentListItemResultContentList>>>;
  digest?: Maybe<Scalars['String']['output']>;
  digestTitle: Scalars['String']['output'];
  feedback?: Maybe<Array<Maybe<ResultFeedback>>>;
  gender?: Maybe<Scalars['String']['output']>;
  maxScaleLabel: Scalars['String']['output'];
  maxScore?: Maybe<Scalars['Int']['output']>;
  measurementResult?: Maybe<AssessmentContentMeasurementResult>;
  minScaleLabel: Scalars['String']['output'];
  minScore?: Maybe<Scalars['Int']['output']>;
  percentageScore: Scalars['Float']['output'];
  score: Scalars['Int']['output'];
  shouldDisplayPercentage?: Maybe<Scalars['Boolean']['output']>;
};

export type AssessmentListItemResultContentList = {
  __typename: 'AssessmentListItemResultContentList';
  author?: Maybe<AssessmentItemContentAuthor>;
  category?: Maybe<AssessmentContentCategory>;
  handleUuid?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<AssessmentItemContentImages>;
  lastModificationDate?: Maybe<Scalars['GraphQLDateTime']['output']>;
  resourcePath?: Maybe<Scalars['String']['output']>;
  sanitizedBody?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<AssessmentContentStats>;
  thumbnail?: Maybe<Image>;
  title: Scalars['String']['output'];
  translationLocale?: Maybe<Scalars['String']['output']>;
  translationUuid?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AssessmentContentType>;
  uri?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type AssessmentPromotion = {
  __typename: 'AssessmentPromotion';
  assessment?: Maybe<AssessmentListItem>;
  externalId: Scalars['ID']['output'];
  icon?: Maybe<Image>;
  image?: Maybe<Image>;
  message?: Maybe<Scalars['String']['output']>;
  promotionType?: Maybe<PromotionType>;
  schedule?: Maybe<WellbeingPromotionSchedule>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export enum AssessmentStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export type AssessmentTierPoints = {
  __typename: 'AssessmentTierPoints';
  assessmentCooldownFrequency?: Maybe<Scalars['String']['output']>;
  pointsForAnAssessment?: Maybe<Scalars['Int']['output']>;
  pointsForHRA?: Maybe<Scalars['Int']['output']>;
  pointsForTHI?: Maybe<Scalars['Int']['output']>;
  userHasTieringEnabled: Scalars['Boolean']['output'];
};

export type AssessmentType = {
  __typename: 'AssessmentType';
  description?: Maybe<Scalars['String']['output']>;
  filter?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AssessmentTypeFilter = {
  __typename: 'AssessmentTypeFilter';
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  typeId: Scalars['String']['output'];
};

export type AssignCarePlanInput = {
  carePlanId: Scalars['ID']['input'];
};

export type AssignCarePlanResponse = {
  __typename: 'AssignCarePlanResponse';
  received: Scalars['Boolean']['output'];
};

export type AvailableCarePlansInput = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type AvailableCarePlansResponse = {
  __typename: 'AvailableCarePlansResponse';
  carePlans: PaginatedCarePlans;
};

export type AvailableMoodFactorsResponse = {
  __typename: 'AvailableMoodFactorsResponse';
  factors: Array<MoodFactor>;
};

export type AvailableTimeSlot = {
  __typename: 'AvailableTimeSlot';
  counsellor: Counsellor;
  endDateTime: Scalars['GraphQLDateTime']['output'];
  id: Scalars['ID']['output'];
  startDateTime: Scalars['GraphQLDateTime']['output'];
  types: Array<AppointmentType>;
};

export type AverageDailyMoodInsightsInput = {
  from?: InputMaybe<Scalars['GraphQLDateTime']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['GraphQLDateTime']['input']>;
};

export type AverageDailyMoodInsightsResponse = {
  __typename: 'AverageDailyMoodInsightsResponse';
  data: Array<DailyMoodDataPoint>;
  message: Scalars['String']['output'];
  /** Number of entries taken into account to compute the data points. */
  sampleSize: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type AverageSteps = EndOfChallengeSummaryTotal & {
  __typename: 'AverageSteps';
  total: Scalars['Int']['output'];
};

export type Banner = {
  __typename: 'Banner';
  dismissed: Scalars['Boolean']['output'];
  id: BannerId;
};

export enum BannerId {
  WelcomeToMyCare = 'WELCOME_TO_MY_CARE',
}

export type BannerInput = {
  id: BannerId;
};

export type BannerResponse = {
  __typename: 'BannerResponse';
  banner: Banner;
};

export type BenefitPlan = {
  __typename: 'BenefitPlan';
  choices?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type BenefitsSummary = {
  __typename: 'BenefitsSummary';
  plans?: Maybe<Array<BenefitPlan>>;
  portalUrlId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  totals?: Maybe<Array<PensionAndBenefitsTotal>>;
};

export type BookAppointmentInput = {
  /** User's date of birth, optional for backwards compatibility */
  dateOfBirth?: InputMaybe<Scalars['GraphQLDate']['input']>;
  email: Scalars['String']['input'];
  isVoicemailPreferred: Scalars['Boolean']['input'];
  phoneNumber: Scalars['String']['input'];
  timeSlotId: Scalars['ID']['input'];
  /** `IN_PERSON` will fail */
  type: AppointmentType;
};

export type BookAppointmentResponse = {
  __typename: 'BookAppointmentResponse';
  appointment: Appointment;
};

export type CancelAppointmentInput = {
  appointmentId: Scalars['ID']['input'];
};

export type CancelAppointmentResponse = {
  __typename: 'CancelAppointmentResponse';
  appointment: Appointment;
};

/**
 * This enum should be mapped to the card brand fetch from Stripe and should be always maintained up to date
 * with all the supported brands
 */
export enum CardBrand {
  Amex = 'AMEX',
  CartesBancaires = 'CARTES_BANCAIRES',
  DinersClub = 'DINERS_CLUB',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  UnionPay = 'UNION_PAY',
  Visa = 'VISA',
}

export type CareAssessment = {
  __typename: 'CareAssessment';
  /** A unique identifier for the assessment. Is also used for tracking in google analytics. */
  id: Scalars['ID']['output'];
  /**
   * The list of questions that make up the assessment.
   * The first question in the array is the first question to be asked.
   * Subsequent questions are asked based on the option the user selects.
   */
  questions: Array<CareAssessmentQuestion>;
  /** A unique value that is used to track and group responses when Care Assessments are retaken */
  sessionId?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type CareAssessmentChoiceAnswer = {
  optionId: Scalars['ID']['input'];
};

export type CareAssessmentChoiceQuestion = {
  __typename: 'CareAssessmentChoiceQuestion';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  options: Array<CareAssessmentChoiceQuestionOption>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type CareAssessmentChoiceQuestionOption = {
  __typename: 'CareAssessmentChoiceQuestionOption';
  id: Scalars['ID']['output'];
  /**
   * If the user answers with this option then skip to the question with the specified id.
   * If null then there are no more questions for the user to answer.
   */
  nextQuestionId?: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
};

export type CareAssessmentFreeTextAnswer = {
  id: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

/** This union is to support multiple Question types. */
export type CareAssessmentQuestion =
  | CareAssessmentChoiceQuestion
  | CareAssessmentTextQuestion;

export type CareAssessmentTextQuestion = {
  __typename: 'CareAssessmentTextQuestion';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  maxLength: Scalars['Int']['output'];
  /**
   * The question that user should do next.
   * If null, then there are no more questions for the user to answer.
   */
  nextQuestionId?: Maybe<Scalars['ID']['output']>;
  /** Localised text that prompts user on what data to input */
  placeholder?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/**
 * CareIssue is a type used to represent a health & wellbeing issue the user might want to focus on.
 * This will influence their care plan and pre assessment.
 */
export type CareIssue = {
  __typename: 'CareIssue';
  analyticsId: Scalars['String']['output'];
  /** The CareIssue that will be recommended to users that do not yet know what to work on */
  default?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  selected?: Maybe<Scalars['Boolean']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type CareIssuesResponse = {
  __typename: 'CareIssuesResponse';
  careIssues: Array<CareIssue>;
};

export type CarePlan = {
  __typename: 'CarePlan';
  currentActivity?: Maybe<CarePlanActivity>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sections: Array<CarePlanSection>;
};

export type CarePlanActivity = {
  completed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  module: CarePlanModule;
  nextActivity?: Maybe<CarePlanActivity>;
  previousActivity?: Maybe<CarePlanActivity>;
  title: Scalars['String']['output'];
};

export type CarePlanActivityArticle = CarePlanActivity & {
  __typename: 'CarePlanActivityArticle';
  audios: Array<ArticleAudio>;
  body: Scalars['String']['output'];
  completed: Scalars['Boolean']['output'];
  copyright: Scalars['String']['output'];
  documents: Array<ArticleDocument>;
  heroImage?: Maybe<Image>;
  id: Scalars['ID']['output'];
  module: CarePlanModule;
  nextActivity?: Maybe<CarePlanActivity>;
  previousActivity?: Maybe<CarePlanActivity>;
  title: Scalars['String']['output'];
  type?: Maybe<ArticleType>;
  url: Scalars['String']['output'];
  videos: Array<ArticleVideo>;
};

export type CarePlanActivityInput = {
  id: Scalars['ID']['input'];
};

export type CarePlanActivityResponse = {
  __typename: 'CarePlanActivityResponse';
  carePlanActivity?: Maybe<CarePlanActivity>;
};

export type CarePlanModule = {
  __typename: 'CarePlanModule';
  activities: Array<CarePlanActivity>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CarePlanResponse = {
  __typename: 'CarePlanResponse';
  carePlan?: Maybe<CarePlan>;
};

export type CarePlanSection = {
  __typename: 'CarePlanSection';
  id: Scalars['ID']['output'];
  modules: Array<CarePlanModule>;
};

/** Defines the available options to get Care Service for a user during onboarding. */
export enum CareServiceOption {
  /** User preference to use Counselling support. */
  CounsellorSupport = 'COUNSELLOR_SUPPORT',
  /** User preference to use self care via Care Plans. */
  SelfCare = 'SELF_CARE',
}

export type CashbackOffer = Offer & {
  __typename: 'CashbackOffer';
  /** Only relevant for "coupons" */
  code?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['GraphQLDateTime']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  merchant: Merchant;
  rate?: Maybe<CashbackRate>;
  /** Only relevant for "rates" */
  rateType?: Maybe<CashbackOfferRateType>;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export enum CashbackOfferRateType {
  Special = 'SPECIAL',
  Standard = 'STANDARD',
}

export type CashbackRate = CashbackRateFixAmount | CashbackRatePercentage;

export type CashbackRateFixAmount = {
  __typename: 'CashbackRateFixAmount';
  amount?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
};

export type CashbackRatePercentage = {
  __typename: 'CashbackRatePercentage';
  amount?: Maybe<Scalars['Float']['output']>;
};

/**
 * A union to support different challenge
 * types based on the challengeType field.
 * Source: challenge-api
 */
export type Challenge = HabitChallenge | StepChallenge;

export type ChallengeActivityTracking = {
  __typename: 'ChallengeActivityTracking';
  category: ChallengeActivityTrackingCategory;
  permissions?: Maybe<ChallengeActivityTrackingPermissions>;
  startTime: Scalars['GraphQLDateTime']['output'];
  type: ChallengeType;
};

export enum ChallengeActivityTrackingCategory {
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export type ChallengeActivityTrackingPermission = {
  __typename: 'ChallengeActivityTrackingPermission';
  isMandatory: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type ChallengeActivityTrackingPermissions = {
  __typename: 'ChallengeActivityTrackingPermissions';
  askUser?: Maybe<ChallengeActivityTrackingPermissionsAskUser>;
  required?: Maybe<Array<Maybe<ChallengeActivityTrackingPermission>>>;
};

export type ChallengeActivityTrackingPermissionsAskUser = {
  __typename: 'ChallengeActivityTrackingPermissionsAskUser';
  info: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum ChallengeCategory {
  HabitSwitching = 'HabitSwitching',
  Mental = 'Mental',
  Physical = 'Physical',
}

export type ChallengeDailyReward = {
  __typename: 'ChallengeDailyReward';
  pointsEarned: Scalars['Int']['output'];
  pointsPotential: Scalars['Int']['output'];
};

export type ChallengeHabits = {
  __typename: 'ChallengeHabits';
  editSelectedHabits: EditSelectedHabitsCaption;
  habits: Array<Habit>;
  selectHabit: SelectHabitCaption;
};

export type ChallengeIntroduction = {
  __typename: 'ChallengeIntroduction';
  id: Scalars['String']['output'];
  info: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ChallengeParticipant = {
  __typename: 'ChallengeParticipant';
  fullName: Scalars['String']['output'];
  image?: Maybe<Image>;
  userId: Scalars['ID']['output'];
};

export type ChallengeParticipantLeaderboardEntry = {
  __typename: 'ChallengeParticipantLeaderboardEntry';
  participant?: Maybe<ChallengeParticipant>;
  position?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['String']['output']>;
};

export enum ChallengeParticipationType {
  Corporate = 'Corporate',
  Individual = 'Individual',
}

export type ChallengePromotion = {
  __typename: 'ChallengePromotion';
  challengeUnion?: Maybe<Challenge>;
  externalId: Scalars['ID']['output'];
  icon?: Maybe<Image>;
  image?: Maybe<Image>;
  message?: Maybe<Scalars['String']['output']>;
  promotionType?: Maybe<PromotionType>;
  schedule?: Maybe<WellbeingPromotionSchedule>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ChallengeResult = {
  __typename: 'ChallengeResult';
  newTier?: Maybe<Tier>;
  pointsAwarded?: Maybe<Scalars['Int']['output']>;
  tieringStatus: ChallengeResultTieringStatus;
};

export enum ChallengeResultTieringStatus {
  NotEnabled = 'NOT_ENABLED',
  NoPointsAwarded = 'NO_POINTS_AWARDED',
  PointsAwarded = 'POINTS_AWARDED',
  Promoted = 'PROMOTED',
}

export enum ChallengeStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
  Succeeded = 'Succeeded',
}

export type ChallengeTeamsLeaderboardEntry = {
  __typename: 'ChallengeTeamsLeaderboardEntry';
  position?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  team: Team;
};

export enum ChallengeType {
  Habit = 'Habit',
  Sleep = 'Sleep',
  Step = 'Step',
}

export type ChoiceQuestion = {
  __typename: 'ChoiceQuestion';
  contentItemId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  options: Array<ChoiceQuestionOption>;
  responseType: ChoiceQuestionResponseType;
  title: Scalars['String']['output'];
  userInteraction?: Maybe<ChoiceQuestionInteraction>;
};

export type ChoiceQuestionInteraction = {
  __typename: 'ChoiceQuestionInteraction';
  choices?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  seenByUser: Scalars['Boolean']['output'];
};

export type ChoiceQuestionInteractionInput = {
  choices?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contentItemId: Scalars['ID']['input'];
  seenByUser: Scalars['Boolean']['input'];
};

export type ChoiceQuestionOption = {
  __typename: 'ChoiceQuestionOption';
  label: Scalars['String']['output'];
  optionId: Scalars['ID']['output'];
};

export enum ChoiceQuestionResponseType {
  MultipleChoice = 'MultipleChoice',
  SingleChoice = 'SingleChoice',
}

export type ClimbedEiffelTower = EndOfChallengeSummaryTotal & {
  __typename: 'ClimbedEiffelTower';
  total: Scalars['Int']['output'];
};

export type Color = {
  __typename: 'Color';
  hexCode: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type CompleteCarePlanActivityInput = {
  id: Scalars['ID']['input'];
};

export type CompleteCarePlanActivityResponse = {
  __typename: 'CompleteCarePlanActivityResponse';
  carePlanActivity?: Maybe<CarePlanActivity>;
};

export type CompletedCustomTierActivity = {
  __typename: 'CompletedCustomTierActivity';
  currentTier?: Maybe<CurrentTier>;
  customTierActivity: CustomTierActivity;
  nextTier?: Maybe<NextTier>;
  tierPoints?: Maybe<TierPoints>;
};

export type CompletedMarathonPercentage = EndOfChallengeSummaryTotal & {
  __typename: 'CompletedMarathonPercentage';
  total: Scalars['Int']['output'];
};

export type ContentReportResponse = {
  __typename: 'ContentReportResponse';
  /** The same `ReportTargetKind` that was sent on the Mutation. */
  kind: ReportTargetKind;
};

export type Counsellor = {
  __typename: 'Counsellor';
  allowsBooking: Scalars['Boolean']['output'];
  appointmentTypes: Array<AppointmentType>;
  availableInNearFuture: Scalars['Boolean']['output'];
  avatarImage?: Maybe<Image>;
  displayHistory: Scalars['Boolean']['output'];
  /** @deprecated Use new flags defined here https://workivate.atlassian.net/wiki/spaces/MNT/pages/4642734121/Symphony+appointments+support */
  external: Scalars['Boolean']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  info?: Maybe<CounsellorInfo>;
  /** @deprecated Use `jobTitles`. */
  jobTitle?: Maybe<Scalars['String']['output']>;
  jobTitles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lastName?: Maybe<Scalars['String']['output']>;
  pronoun?: Maybe<Scalars['String']['output']>;
  spokenLanguages?: Maybe<Array<Maybe<Language>>>;
  timeSlots?: Maybe<Array<AvailableTimeSlot>>;
};

export type CounsellorTimeSlotsArgs = {
  input: FetchCounsellorTimeSlotInput;
};

export type CounsellorEducation = {
  __typename: 'CounsellorEducation';
  institution?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CounsellorFilter = {
  __typename: 'CounsellorFilter';
  id: Scalars['ID']['output'];
  options: Array<CounsellorFilterOption>;
  title: Scalars['String']['output'];
};

export type CounsellorFilterOption = {
  __typename: 'CounsellorFilterOption';
  counsellorIds: Array<Scalars['ID']['output']>;
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type CounsellorInfo = {
  __typename: 'CounsellorInfo';
  bio?: Maybe<Scalars['String']['output']>;
  disability?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer required in UI */
  educations?: Maybe<Array<Maybe<CounsellorEducation>>>;
  ethnicBackground?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  licenses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  religion?: Maybe<Scalars['String']['output']>;
  sexualOrientation?: Maybe<Scalars['String']['output']>;
  specializations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CreateChallengeTeamInput = {
  challengeId: Scalars['ID']['input'];
  teamName: Scalars['String']['input'];
};

export type CreateChallengeTeamResponse = {
  __typename: 'CreateChallengeTeamResponse';
  team: Team;
};

export type CreateCustomMoodFactorInput = {
  /** User entered title for the custom mood factor. */
  title: Scalars['String']['input'];
};

export type CreateCustomMoodFactorResponse = {
  __typename: 'CreateCustomMoodFactorResponse';
  /** Newly created custom mood factor. */
  moodFactor: MoodFactor;
};

export type CreateJournalEntryInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['GraphQLDateTime']['input'];
  title: Scalars['String']['input'];
};

export type CreateJournalEntryResponse = {
  __typename: 'CreateJournalEntryResponse';
  entry: JournalEntry;
};

export type CreateMoodEntryInput = {
  createdAt: Scalars['GraphQLDateTime']['input'];
  factors: Array<MoodFactorInput>;
  note?: InputMaybe<Scalars['String']['input']>;
  value: MoodValue;
};

export type CreateMoodEntryResponse = {
  __typename: 'CreateMoodEntryResponse';
  entry: MoodEntry;
};

export type CurrentTier = {
  __typename: 'CurrentTier';
  description: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  motivationalMessage: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rank: Scalars['Int']['output'];
};

export type CurrentTierProgressSummary = {
  __typename: 'CurrentTierProgressSummary';
  currentTier?: Maybe<CurrentTier>;
  nextTier?: Maybe<NextTier>;
  points?: Maybe<TierPoints>;
};

export type CustomTierActivity = {
  __typename: 'CustomTierActivity';
  activityId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  instructions?: Maybe<Scalars['String']['output']>;
  status: CustomTierActivityStatus;
  tierPointAward?: Maybe<TierPointAward>;
  title: Scalars['String']['output'];
};

export enum CustomTierActivityStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export type DailyMoodDataPoint = {
  __typename: 'DailyMoodDataPoint';
  date: Scalars['GraphQLDate']['output'];
  /** Average mood for this day. In the range: 0 - 4 */
  value?: Maybe<Scalars['Float']['output']>;
};

export type DateTimeInterval = {
  end: Scalars['GraphQLDateTime']['input'];
  start: Scalars['GraphQLDateTime']['input'];
};

export type DeleteCustomMoodFactorsInput = {
  /** Custom mood factors to delete. */
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteCustomMoodFactorsResponse = {
  __typename: 'DeleteCustomMoodFactorsResponse';
  received?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteJournalEntryInput = {
  id: Scalars['ID']['input'];
};

export type DeleteJournalEntryResponse = {
  __typename: 'DeleteJournalEntryResponse';
  received?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteMoodEntryInput = {
  id: Scalars['ID']['input'];
};

export type DeleteMoodEntryResponse = {
  __typename: 'DeleteMoodEntryResponse';
  received?: Maybe<Scalars['Boolean']['output']>;
};

export type DigitalClinicalContentItem =
  | Article
  | ChoiceQuestion
  | FreeTextQuestion
  | ScaleQuestion
  | StarRatingQuestion;

export type DigitalClinicalModule = {
  __typename: 'DigitalClinicalModule';
  backgroundImage?: Maybe<Image>;
  heroImage?: Maybe<Image>;
  introduction: Scalars['String']['output'];
  moduleId: Scalars['ID']['output'];
  progress: DigitalClinicalModuleProgress;
  sections?: Maybe<Array<DigitalClinicalModuleSection>>;
  status: DigitalClinicalModuleStatus;
  timeEstimate?: Maybe<TimeSpan>;
  title: Scalars['String']['output'];
};

export type DigitalClinicalModuleListItem = {
  __typename: 'DigitalClinicalModuleListItem';
  backgroundImage?: Maybe<Image>;
  heroImage?: Maybe<Image>;
  introduction: Scalars['String']['output'];
  moduleId: Scalars['ID']['output'];
  progress: DigitalClinicalModuleProgress;
  status: DigitalClinicalModuleStatus;
  timeEstimate?: Maybe<TimeSpan>;
  title: Scalars['String']['output'];
};

export type DigitalClinicalModuleProgress = {
  __typename: 'DigitalClinicalModuleProgress';
  completedContentItems: Scalars['Int']['output'];
  percentageComplete: Scalars['Float']['output'];
  remainingContentItems: Scalars['Int']['output'];
  totalContentItems: Scalars['Int']['output'];
};

export type DigitalClinicalModuleSection = {
  __typename: 'DigitalClinicalModuleSection';
  contentItems?: Maybe<Array<DigitalClinicalContentItem>>;
  description?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export enum DigitalClinicalModuleStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export type DigitalClinicalProgram = {
  __typename: 'DigitalClinicalProgram';
  completedAt?: Maybe<Scalars['GraphQLDateTime']['output']>;
  description: Scalars['String']['output'];
  icon?: Maybe<Image>;
  modules?: Maybe<Array<DigitalClinicalModuleListItem>>;
  programId: Scalars['ID']['output'];
  programType: DigitalClinicalProgramType;
  progress: DigitalClinicalProgramProgress;
  status: DigitalClinicalProgramStatus;
  title: Scalars['String']['output'];
  wasJoined: Scalars['Boolean']['output'];
};

export type DigitalClinicalProgramCategory = {
  __typename: 'DigitalClinicalProgramCategory';
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Image>;
  iconBackground?: Maybe<Image>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DigitalClinicalProgramInteractionInput = {
  articleInteractions?: InputMaybe<Array<ArticleInteractionInput>>;
  choiceQuestionInteractions?: InputMaybe<
    Array<ChoiceQuestionInteractionInput>
  >;
  freeTextQuestionInteractions?: InputMaybe<
    Array<FreeTextQuestionInteractionInput>
  >;
  moduleId: Scalars['ID']['input'];
  programId: Scalars['ID']['input'];
  scaleQuestionInteractions?: InputMaybe<Array<ScaleQuestionInteractionInput>>;
  starRatingQuestionInteractions?: InputMaybe<
    Array<StarRatingQuestionInteractionInput>
  >;
};

export type DigitalClinicalProgramListItem = {
  __typename: 'DigitalClinicalProgramListItem';
  completedAt?: Maybe<Scalars['GraphQLDateTime']['output']>;
  description: Scalars['String']['output'];
  icon?: Maybe<Image>;
  isLite?: Maybe<Scalars['Boolean']['output']>;
  nextModule?: Maybe<DigitalClinicalModuleListItem>;
  programId: Scalars['ID']['output'];
  programType: DigitalClinicalProgramType;
  progress: DigitalClinicalProgramProgress;
  status: DigitalClinicalProgramStatus;
  title: Scalars['String']['output'];
  wasJoined: Scalars['Boolean']['output'];
};

export type DigitalClinicalProgramProgress = {
  __typename: 'DigitalClinicalProgramProgress';
  completedModules: Scalars['Int']['output'];
  percentageComplete: Scalars['Float']['output'];
  remainingModules: Scalars['Int']['output'];
  totalModules: Scalars['Int']['output'];
};

export enum DigitalClinicalProgramStatus {
  Aborted = 'Aborted',
  Completed = 'Completed',
  Expired = 'Expired',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export enum DigitalClinicalProgramType {
  Anxiety = 'Anxiety',
  Communication = 'Communication',
  Coping = 'Coping',
  Depression = 'Depression',
  Grief = 'Grief',
  Health = 'Health',
  Money = 'Money',
  Pain = 'Pain',
  Relationships = 'Relationships',
  Separation = 'Separation',
  Sleep = 'Sleep',
  Stress = 'Stress',
  SubstanceAbuse = 'SubstanceAbuse',
  Trauma = 'Trauma',
  Work = 'Work',
}

export type EditJournalEntryInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['GraphQLDateTime']['input'];
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type EditJournalEntryResponse = {
  __typename: 'EditJournalEntryResponse';
  entry: JournalEntry;
};

export type EditMoodEntryInput = {
  createdAt: Scalars['GraphQLDateTime']['input'];
  factors: Array<MoodFactorInput>;
  id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  value: MoodValue;
};

export type EditMoodEntryResponse = {
  __typename: 'EditMoodEntryResponse';
  entry: MoodEntry;
};

export type EditSelectedHabitsCaption = {
  __typename: 'EditSelectedHabitsCaption';
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type EndOfChallengeSummaryTotal = {
  total: Scalars['Int']['output'];
};

export enum ExerciseActivityType {
  Running = 'RUNNING',
  Travel = 'TRAVEL',
  Unknown = 'UNKNOWN',
  Walking = 'WALKING',
}

export type ExerciseEvent = {
  __typename: 'ExerciseEvent';
  activityType: ExerciseActivityType;
  endedAt: Scalars['GraphQLDateTime']['output'];
  quantity: Scalars['Int']['output'];
  startedAt: Scalars['GraphQLDateTime']['output'];
};

export type ExerciseEventInput = {
  activityDeviceType?: InputMaybe<ActivityDeviceType>;
  /** Enumerated activity type */
  activityType: ExerciseActivityType;
  /** Raw activity type value as sent by the activity data source */
  activityTypeRaw?: InputMaybe<Scalars['String']['input']>;
  appSource?: InputMaybe<Scalars['String']['input']>;
  /** Date/Time provided in UTC e.g 2021-02-23T10:15:30Z */
  endedAt: Scalars['GraphQLDateTime']['input'];
  quantity: Scalars['Int']['input'];
  /** Date/Time provided in UTC e.g 2021-02-23T10:15:30Z */
  startedAt: Scalars['GraphQLDateTime']['input'];
};

export type FailedToAddTeamMember = {
  __typename: 'FailedToAddTeamMember';
  id: Scalars['ID']['output'];
};

export type FavoriteMerchantInput = {
  id: Scalars['ID']['input'];
};

export type FavoriteMerchantResponse = {
  __typename: 'FavoriteMerchantResponse';
  merchant: Merchant;
};

export type FeaturedAssessment = {
  __typename: 'FeaturedAssessment';
  assessment: AssessmentListItem;
  assessmentsRemaining?: Maybe<Scalars['Int']['output']>;
  completedAt?: Maybe<Scalars['GraphQLDateTime']['output']>;
  icon?: Maybe<Image>;
  introduction: Scalars['String']['output'];
  numberOfAssessments?: Maybe<Scalars['Int']['output']>;
  status: AssessmentStatus;
  tierPoints?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type Feedback = {
  body: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum FeedbackId {
  Onboarding = 'ONBOARDING',
}

export type FeedbackRequest = {
  __typename: 'FeedbackRequest';
  /**
   * The text that prompts for free input after the user has selected a negative rating.
   * Localized string.
   */
  negativeFreeTextInputPrompt: Scalars['String']['output'];
  /**
   * The text that prompts for free input after the user has selected a positive rating.
   * Localized string.
   */
  positiveFreeTextInputPrompt: Scalars['String']['output'];
  /**
   * The main question for the feedback request.
   * Localized string.
   */
  question: Scalars['String']['output'];
  /** Max length for the free-text input. */
  textInputMaxLength: Scalars['Int']['output'];
};

export type FeedbackRequestInput = {
  id: FeedbackId;
};

export type FeedbackRequestResponse = {
  __typename: 'FeedbackRequestResponse';
  /** If the user shouldn't see this feedback request at this time, this field should be null so FE won't prompt for it. */
  feedbackRequest?: Maybe<FeedbackRequest>;
};

export type FetchAppointmentByIdInput = {
  appointmentId: Scalars['ID']['input'];
};

export type FetchAppointmentResponse = {
  __typename: 'FetchAppointmentResponse';
  appointment: Appointment;
};

export type FetchAppointmentsInput = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FetchAppointmentsOrder>;
  where?: InputMaybe<FetchAppointmentsWhere>;
};

export type FetchAppointmentsOrder = {
  startDateTime?: InputMaybe<Order>;
};

export type FetchAppointmentsResponse = {
  __typename: 'FetchAppointmentsResponse';
  appointments: PaginatedAppointments;
};

export type FetchAppointmentsWhere = {
  endDateTime?: InputMaybe<AppointmentsDateTimeFilter>;
  startDateTime?: InputMaybe<AppointmentsDateTimeFilter>;
  statuses?: InputMaybe<Array<AppointmentStatus>>;
};

export type FetchAssessmentsResponse = {
  __typename: 'FetchAssessmentsResponse';
  assessments?: Maybe<Array<AssessmentListItem>>;
  contentLanguage?: Maybe<Scalars['String']['output']>;
  translatedGlobalContentExists: Scalars['Boolean']['output'];
};

export type FetchCounsellorByIdInput = {
  counsellorId: Scalars['ID']['input'];
};

export type FetchCounsellorFiltersInput = {
  where?: InputMaybe<FetchCounsellorFiltersInputWhere>;
};

export type FetchCounsellorFiltersInputWhere = {
  appointmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  disability?: InputMaybe<Array<Scalars['String']['input']>>;
  ethnicBackground?: InputMaybe<Array<Scalars['String']['input']>>;
  gender?: InputMaybe<Array<Scalars['String']['input']>>;
  locationId: Scalars['ID']['input'];
  religion?: InputMaybe<Array<Scalars['String']['input']>>;
  sexualOrientation?: InputMaybe<Array<Scalars['String']['input']>>;
  specializations?: InputMaybe<Array<Scalars['String']['input']>>;
  spokenLanguages?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FetchCounsellorFiltersResponse = {
  __typename: 'FetchCounsellorFiltersResponse';
  filters: Array<CounsellorFilter>;
};

export type FetchCounsellorResponse = {
  __typename: 'FetchCounsellorResponse';
  counsellor: Counsellor;
};

export type FetchCounsellorTimeSlotInput = {
  where?: InputMaybe<FetchCounsellorTimeSlotWhere>;
};

export type FetchCounsellorTimeSlotWhere = {
  interval?: InputMaybe<DateTimeInterval>;
};

export type FetchCounsellorsInput = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FetchCounsellorsWhere>;
};

export type FetchCounsellorsResponse = {
  __typename: 'FetchCounsellorsResponse';
  counsellors: PaginatedCounsellors;
};

export type FetchCounsellorsWhere = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};

export type FetchFirstOfferedAppointmentInput = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
};

export type FetchFirstOfferedAvailableTimeSlotResponse = {
  __typename: 'FetchFirstOfferedAvailableTimeSlotResponse';
  availableTimeSlot: AvailableTimeSlot;
};

export type FetchMerchantsOrder = {
  order?: InputMaybe<MerchantsSort>;
};

export type FetchMerchantsResponse = {
  __typename: 'FetchMerchantsResponse';
  merchants: MerchantsPaginatedResponse;
};

export type FetchMerchantsWhere = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  onlyFavorites?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PerksType>;
};

export type FetchOffersOrder = {
  order?: InputMaybe<OffersSort>;
};

export type FetchOffersResponse = {
  __typename: 'FetchOffersResponse';
  offers: OffersPaginatedResponse;
};

export type FetchOffersWhere = {
  /** Use this to filter only for Cashback standard rates or special offers. */
  cashbackOfferRateType?: InputMaybe<CashbackOfferRateType>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PerksType>;
};

export type FetchTeamResponse = {
  __typename: 'FetchTeamResponse';
  /**
   * Returns a paginated team members leaderboard showing
   * who is winning the corporate challenge within a team.
   * If the corporate challenge has not started the users
   * will be returned in alphabetical order without a score.
   * Once the corporate challenge is in progress the list will
   * be a leaderboard showing which user is currently winning.
   * The leaderboard is ordered by each team member's score.
   */
  leaderboardTeamMembers: LeaderboardTeamMembersResponse;
  team?: Maybe<Team>;
};

export type FetchTeamResponseLeaderboardTeamMembersArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  challengeId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type FetchTransactionResult = {
  __typename: 'FetchTransactionResult';
  data?: Maybe<Transaction>;
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type FetchUserSubdivisionsResponse = {
  __typename: 'FetchUserSubdivisionsResponse';
  subdivisions: Array<Subdivision>;
};

export type FilterAssessmentsInput = {
  category?: InputMaybe<Array<InputMaybe<AssessmentCategory>>>;
  status: Array<InputMaybe<AssessmentStatus>>;
};

export type FilterChallengesInput = {
  participationType?: InputMaybe<ChallengeParticipationType>;
  status?: InputMaybe<Array<InputMaybe<ChallengeStatus>>>;
  /** Indicates if the current user is actively in the challenge */
  wasJoined?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FilterDigitalClinicalProgramsInput = {
  category?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<InputMaybe<DigitalClinicalProgramStatus>>>;
  wasJoined?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FilterPreAssessmentHistoryInput = {
  /** Specify the id of the assessment history to retrieve. */
  careAssessmentId?: InputMaybe<Scalars['ID']['input']>;
  pagination?: InputMaybe<PreAssessmentHistoryPaginationInput>;
};

export type FilterPreAssessmentInput = {
  /** Optionally specify the id of the next assessment to retrieve. */
  careAssessmentId?: InputMaybe<Scalars['ID']['input']>;
  /** Start the retake flow. If missing, assume we are not performing a retake. */
  retake?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * The current retake session in progress. Passed to subsequent requests once a user starts a retake
   * session by using 'retake: true'. Assessments will be served in order within the same session.
   */
  sessionId?: InputMaybe<Scalars['String']['input']>;
};

export type FindAssessmentHistoryInput = {
  uuid: Scalars['ID']['input'];
};

export type FindAssessmentInput = {
  uuid: Scalars['ID']['input'];
};

export type FindChallengeTeamsLeaderboardInput = {
  challengeId: Scalars['ID']['input'];
  date: Scalars['String']['input'];
};

export type FindCustomTierActivityInput = {
  activityId: Scalars['ID']['input'];
};

export type FindDigitalClinicalCategoryInput = {
  categoryId: Scalars['ID']['input'];
};

export type FindDigitalClinicalModuleInput = {
  moduleId: Scalars['ID']['input'];
  programId: Scalars['ID']['input'];
};

export type FindDigitalClinicalProgramInput = {
  programId: Scalars['ID']['input'];
};

export type FindHistoricalAssessmentInput = {
  archiveId: Scalars['ID']['input'];
};

export type FindTooltipInput = {
  tooltipId: TooltipId;
};

export type Font = {
  __typename: 'Font';
  color?: Maybe<Color>;
};

export type FormattedText = {
  __typename: 'FormattedText';
  body: Scalars['String']['output'];
  format: TextFormat;
};

export type FreeTextQuestion = {
  __typename: 'FreeTextQuestion';
  contentItemId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hint?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  userInteraction?: Maybe<FreeTextQuestionInteraction>;
};

export type FreeTextQuestionInteraction = {
  __typename: 'FreeTextQuestionInteraction';
  seenByUser: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type FreeTextQuestionInteractionInput = {
  contentItemId: Scalars['ID']['input'];
  seenByUser: Scalars['Boolean']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type GetActivitySourceResponse = {
  __typename: 'GetActivitySourceResponse';
  source: ActivitySourceDevice;
};

export type GetCareIssuesInput = {
  selected?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetChallengeTeamsLeaderboardResponse = {
  __typename: 'GetChallengeTeamsLeaderboardResponse';
  challengeTeamsLeaderboard: Array<Maybe<ChallengeTeamsLeaderboardEntry>>;
  paging?: Maybe<Pagination>;
};

/**
 * Goal is a type used to represent the goal of a user. This will drive the entire
 * user experience.
 */
export type Goal = {
  __typename: 'Goal';
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type GoalsResponse = {
  __typename: 'GoalsResponse';
  goals: Array<Goal>;
};

export type Habit = {
  __typename: 'Habit';
  description?: Maybe<Scalars['String']['output']>;
  habitId: Scalars['ID']['output'];
  options?: Maybe<Array<HabitOption>>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  subtitle: Scalars['String']['output'];
  thumbnailImage?: Maybe<Image>;
  title: Scalars['String']['output'];
  tracked?: Maybe<Scalars['Int']['output']>;
  tradeCaptions: HabitTradeCaption;
};

export type HabitActivity = {
  __typename: 'HabitActivity';
  habit?: Maybe<Habit>;
  optionActivity: Array<Maybe<HabitOptionActivity>>;
};

export type HabitActivityResponse = {
  __typename: 'HabitActivityResponse';
  activity?: Maybe<Array<HabitDailyActivity>>;
  pagination?: Maybe<Pagination>;
};

export type HabitChallenge = {
  __typename: 'HabitChallenge';
  activity: HabitActivityResponse;
  activityTracking?: Maybe<ChallengeActivityTracking>;
  category: ChallengeCategory;
  categoryDisplayText: Scalars['String']['output'];
  challengeHabits?: Maybe<ChallengeHabits>;
  challengeId: Scalars['ID']['output'];
  challengeType?: Maybe<ChallengeType>;
  completionReward?: Maybe<TierReward>;
  defaultPromotionalPost?: Maybe<Scalars['String']['output']>;
  durationInDays?: Maybe<Scalars['Int']['output']>;
  endDateTimeISO?: Maybe<Scalars['GraphQLDateTime']['output']>;
  goal: Scalars['Int']['output'];
  image?: Maybe<Image>;
  introduction?: Maybe<Array<Maybe<ChallengeIntroduction>>>;
  /**
   * Returns a paginated individuals leaderboard for a specific
   * date. The leaderboard includes everyone in the company
   * who is participating in the challenge.
   * If the corporate challenge has not started the users
   * will be returned in alphabetical order without a score.
   * Once the corporate challenge is in progress the list will
   * be a leaderboard showing which user is currently winning.
   * The leaderboard is ordered by every user's score.
   */
  leaderboardIndividuals: LeaderboardIndividualResponse;
  /**
   * Returns a paginated teams leaderboard for a specific date
   * If the corporate challenge has not started the teams will
   * be returned in alphabetical order without a score.
   * Once the corporate challenge is in progress the list will
   * be a leaderboard showing which team is currently winning.
   * The leaderboard is ordered by the team's score.
   * The team's score is an average of active team members' scores.
   * If the user's team is on the first page then we prepend
   * the team to the top of the list
   */
  leaderboardTeams: LeaderboardTeamResponse;
  participants?: Maybe<Scalars['Int']['output']>;
  participationType: ChallengeParticipationType;
  result?: Maybe<ChallengeResult>;
  /** @deprecated Use completionReward */
  reward?: Maybe<TierReward>;
  startDateTimeISO?: Maybe<Scalars['GraphQLDateTime']['output']>;
  status: ChallengeStatus;
  subtitle?: Maybe<Scalars['String']['output']>;
  teams?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use completionReward */
  tierPoints?: Maybe<Scalars['Int']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  userTeamMembership: UserTeamMembershipResponse;
  /** Indicates if the current user is actively in the challenge */
  wasJoined: Scalars['Boolean']['output'];
};

export type HabitChallengeActivityArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['GraphQLDate']['input']>;
};

export type HabitChallengeLeaderboardIndividualsArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type HabitChallengeLeaderboardTeamsArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type HabitDailyActivity = {
  __typename: 'HabitDailyActivity';
  activity?: Maybe<Array<HabitActivity>>;
  date: Scalars['GraphQLDate']['output'];
  id: Scalars['ID']['output'];
  subtitle: Scalars['String']['output'];
  tracked: Scalars['Int']['output'];
  tradeLimit: Scalars['Int']['output'];
};

export type HabitOption = {
  __typename: 'HabitOption';
  optionId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type HabitOptionActivity = {
  __typename: 'HabitOptionActivity';
  option?: Maybe<HabitOption>;
  tracked: Scalars['Int']['output'];
};

export type HabitTradeCaption = {
  __typename: 'HabitTradeCaption';
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type HealthRiskAssessmentCard = {
  __typename: 'HealthRiskAssessmentCard';
  assessmentsRemaining: Scalars['Int']['output'];
  completedAt?: Maybe<Scalars['GraphQLDateTime']['output']>;
  heroCardId: Scalars['String']['output'];
  icon?: Maybe<Image>;
  introduction: Scalars['String']['output'];
  nextAssessment: AssessmentListItem;
  numberOfAssessments: Scalars['Int']['output'];
  status: AssessmentStatus;
  tierPoints?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type HistoricalAssessment = {
  __typename: 'HistoricalAssessment';
  archiveId: Scalars['ID']['output'];
  assessmentUuid: Scalars['ID']['output'];
  result?: Maybe<AssessmentListItemResult>;
};

export type HitGoal = EndOfChallengeSummaryTotal & {
  __typename: 'HitGoal';
  total: Scalars['Int']['output'];
};

export type HowDoesPersonalisationWorkResponse = {
  __typename: 'HowDoesPersonalisationWorkResponse';
  body: Scalars['String']['output'];
  button: Scalars['String']['output'];
  icon?: Maybe<Image>;
  /** only returned if the user has the enhanced_personalisation fk */
  permission?: Maybe<HowPersonalisationWorksPermission>;
  title: Scalars['String']['output'];
};

export type HowPersonalisationWorksPermission = {
  __typename: 'HowPersonalisationWorksPermission';
  allowPersonalisation?: Maybe<Scalars['Boolean']['output']>;
  body: Scalars['String']['output'];
  footer?: Maybe<Scalars['String']['output']>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type HowToEarnTierPoints = {
  __typename: 'HowToEarnTierPoints';
  image: Image;
  route: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type HraPromotion = {
  __typename: 'HraPromotion';
  assessment?: Maybe<FeaturedAssessment>;
  externalId: Scalars['ID']['output'];
  icon?: Maybe<Image>;
  image?: Maybe<Image>;
  message?: Maybe<Scalars['String']['output']>;
  promotionType?: Maybe<PromotionType>;
  schedule?: Maybe<WellbeingPromotionSchedule>;
  subtitle?: Maybe<Scalars['String']['output']>;
  tierPoints?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type Image = {
  __typename: 'Image';
  font?: Maybe<Font>;
  url: Scalars['String']['output'];
};

export type IndividualsLeaderboardEntry = {
  __typename: 'IndividualsLeaderboardEntry';
  hasTrackedManual?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  /** Extended from team-api */
  user: User;
};

export type InfluencesMoodDataPoint = {
  __typename: 'InfluencesMoodDataPoint';
  factor: Scalars['String']['output'];
  valueMax: Scalars['Int']['output'];
  valueMin: Scalars['Int']['output'];
};

export type InfluencesMoodInsightsInput = {
  from?: InputMaybe<Scalars['GraphQLDateTime']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['GraphQLDateTime']['input']>;
};

export type InfluencesMoodInsightsResponse = {
  __typename: 'InfluencesMoodInsightsResponse';
  data: Array<InfluencesMoodDataPoint>;
  message: Scalars['String']['output'];
  /** Number of entries taken into account to compute the data points. */
  sampleSize: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type JoinChallengeResponse = {
  __typename: 'JoinChallengeResponse';
  challenge?: Maybe<Challenge>;
};

export type JoinChallengeTeamInput = {
  challengeId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type JoinChallengeTeamResponse = {
  __typename: 'JoinChallengeTeamResponse';
  joinedTeam: Scalars['Boolean']['output'];
  /** Users cannot join a team if they are in an existing team */
  reasonNotJoined?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Team>;
};

export type JoinDigitalClinicalProgramInput = {
  programId: Scalars['ID']['input'];
};

export type JournalEntriesInput = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type JournalEntriesResponse = {
  __typename: 'JournalEntriesResponse';
  entries: PaginatedJournalEntries;
};

/** Journal entry for the Journaling feature. */
export type JournalEntry = {
  __typename: 'JournalEntry';
  /** Journal body added by the user. */
  body?: Maybe<Scalars['String']['output']>;
  /** Date time when the user created the journal entry. */
  date: Scalars['GraphQLDateTime']['output'];
  id: Scalars['ID']['output'];
  /** Title for the entry added by the user. */
  title: Scalars['String']['output'];
};

export type Language = {
  __typename: 'Language';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type LeaderboardIndividualResponse = {
  __typename: 'LeaderboardIndividualResponse';
  leaderboard?: Maybe<Array<IndividualsLeaderboardEntry>>;
  pagination?: Maybe<Pagination>;
};

export type LeaderboardTeamMembersResponse = {
  __typename: 'LeaderboardTeamMembersResponse';
  leaderboard?: Maybe<Array<IndividualsLeaderboardEntry>>;
  pagination?: Maybe<Pagination>;
};

export type LeaderboardTeamResponse = {
  __typename: 'LeaderboardTeamResponse';
  leaderboard?: Maybe<Array<TeamsLeaderboardEntry>>;
  pagination?: Maybe<Pagination>;
};

export type LeaveChallengeResponse = {
  __typename: 'LeaveChallengeResponse';
  challenge?: Maybe<Challenge>;
};

export type LeaveChallengeTeamInput = {
  challengeId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type LeaveChallengeTeamResponse = {
  __typename: 'LeaveChallengeTeamResponse';
  departedTeam: Scalars['Boolean']['output'];
  /** Users cannot leave a team if the challenge has started */
  reasonNotDeparted?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Team>;
  /** If they are the last team member, the team is deleted */
  teamDeleted: Scalars['Boolean']['output'];
};

export type LeaveDigitalClinicalProgramInput = {
  programId: Scalars['ID']['input'];
};

export type MandatoryTierActivities = {
  __typename: 'MandatoryTierActivities';
  completed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  route: Scalars['String']['output'];
};

export type MeasurementRanges = {
  __typename: 'MeasurementRanges';
  jumpTo?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  range?: Maybe<Scalars['String']['output']>;
};

export type MeasurementResultFeedback = Feedback & {
  __typename: 'MeasurementResultFeedback';
  body: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MembersPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type MembersResponse = {
  __typename: 'MembersResponse';
  members?: Maybe<Array<User>>;
  pagination?: Maybe<Pagination>;
};

export type Merchant = {
  __typename: 'Merchant';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  infoPayment?: Maybe<PerksFormattedTextSection>;
  infoReturn?: Maybe<PerksFormattedTextSection>;
  infoShipping?: Maybe<PerksFormattedTextSection>;
  infoSpecialTerms?: Maybe<PerksFormattedTextSection>;
  infoTax?: Maybe<PerksFormattedTextSection>;
  isFavorite: Scalars['Boolean']['output'];
  logo?: Maybe<Image>;
  name: Scalars['String']['output'];
  /**
   * Offers of this Merchant.
   * Use this with a limit of 1 and sort of `highestCashback` to retrieve the highest cashback value of this Merchant.
   */
  offers?: Maybe<FetchOffersResponse>;
  termsAndConditions: Array<PerksFormattedTextSection>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MerchantOffersArgs = {
  input: OffersInput;
};

export type MerchantInput = {
  id: Scalars['ID']['input'];
};

export type MerchantResponse = {
  __typename: 'MerchantResponse';
  merchant: Merchant;
};

export type MerchantsInput = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FetchOffersOrder>;
  where?: InputMaybe<FetchOffersWhere>;
};

export type MerchantsPaginatedResponse = {
  __typename: 'MerchantsPaginatedResponse';
  data: Array<Merchant>;
  paging?: Maybe<Pagination>;
};

export enum MerchantsSort {
  Alphabetical = 'alphabetical',
  EndingSoon = 'endingSoon',
  HighestCashback = 'highestCashback',
  MostPopular = 'mostPopular',
  MostSpecialOffers = 'mostSpecialOffers',
  SelectedByWa = 'selectedByWA',
}

export type MockMutationInput = {
  id: Scalars['ID']['input'];
};

export type MockMutationResponse = {
  __typename: 'MockMutationResponse';
  received: Scalars['Boolean']['output'];
};

export type MoodEntriesDateTimeFilter = {
  from?: InputMaybe<Scalars['GraphQLDateTime']['input']>;
  to?: InputMaybe<Scalars['GraphQLDateTime']['input']>;
};

export type MoodEntriesInput = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MoodEntriesWhere>;
};

export type MoodEntriesResponse = {
  __typename: 'MoodEntriesResponse';
  entries: PaginatedMoodEntries;
};

export type MoodEntriesWhere = {
  createdAt?: InputMaybe<MoodEntriesDateTimeFilter>;
};

/** Mood entry for the Mood Tracking feature. */
export type MoodEntry = {
  __typename: 'MoodEntry';
  /** Date time when the user created the entry. */
  createdAt: Scalars['GraphQLDateTime']['output'];
  /** Factors that contributed to the user's mood. */
  factors: Array<MoodFactor>;
  id: Scalars['ID']['output'];
  /** Free text entry note added by the user. */
  note?: Maybe<Scalars['String']['output']>;
  /** Value for the mood feling. */
  value: MoodValue;
};

/** Factor that contributes to the user's mood feeling. */
export type MoodFactor = {
  __typename: 'MoodFactor';
  id: Scalars['ID']['output'];
  /** Custom Mood Factor created by the user. */
  isCustom: Scalars['Boolean']['output'];
  /** Localized string. */
  title: Scalars['String']['output'];
};

export type MoodFactorInput = {
  id: Scalars['ID']['input'];
};

/**
 * Value for the mood feling.
 * FE matches this value to the UI for each feeling.
 */
export enum MoodValue {
  Amazing = 'AMAZING',
  Awful = 'AWFUL',
  Bad = 'BAD',
  Good = 'GOOD',
  Ok = 'OK',
}

export type Mutation = {
  __typename: 'Mutation';
  answerAssessmentQuestions?: Maybe<AnswerAssessmentQuestionsResponse>;
  answerPreAssessment?: Maybe<AnswerPreAssessmentResponse>;
  /**
   * Allows a user to call for immediate help at the start of their care journey.
   * We will also inform the care provider that the user has identified themselves as at risk.
   */
  askForImmediateHelp?: Maybe<AskForImmediateHelpResponse>;
  /**
   * Due to the async nature of Careplan assignment to a user, this mutation will not return the Care Plan and instead return a received flag.
   * This mutation is idempotent.
   */
  assignCarePlan?: Maybe<AssignCarePlanResponse>;
  bookAppointment?: Maybe<BookAppointmentResponse>;
  cancelAppointment?: Maybe<CancelAppointmentResponse>;
  changeGender?: Maybe<ChangeGenderResponse>;
  completeCarePlanActivity?: Maybe<CompleteCarePlanActivityResponse>;
  completeCustomTierActivity?: Maybe<CompletedCustomTierActivity>;
  completeWalkthrough?: Maybe<Scalars['Boolean']['output']>;
  createChallengeTeam?: Maybe<CreateChallengeTeamResponse>;
  /** Create a new user created custom mood factor. */
  createCustomMoodFactor?: Maybe<CreateCustomMoodFactorResponse>;
  /**
   * Creates a new journal entry for the current user with the given input.
   * It returns the newly created journal entry.
   */
  createJournalEntry?: Maybe<CreateJournalEntryResponse>;
  /**
   * Creates a new mood entry for the current user with the given input.
   * It returns the newly created mood entry.
   */
  createMoodEntry?: Maybe<CreateMoodEntryResponse>;
  /** Delete user created custom mood factors. */
  deleteCustomMoodFactors?: Maybe<DeleteCustomMoodFactorsResponse>;
  /**
   * Deletes the journal entry of the current user with the given id.
   * Note: This is assumed to be a synchronous operation
   */
  deleteJournalEntry?: Maybe<DeleteJournalEntryResponse>;
  /** Deletes the mood entry of the current user with the given id. */
  deleteMoodEntry?: Maybe<DeleteMoodEntryResponse>;
  dismissTooltip?: Maybe<Tooltip>;
  /**
   * Edits an existing journal entry for the current user with the given input.
   * It returns the edited journal entry.
   */
  editJournalEntry?: Maybe<EditJournalEntryResponse>;
  /**
   * Edits an existing mood entry for the current user with the given input.
   * It returns the edited mood entry.
   */
  editMoodEntry?: Maybe<EditMoodEntryResponse>;
  favoriteMerchant?: Maybe<FavoriteMerchantResponse>;
  joinChallenge: JoinChallengeResponse;
  joinChallengeTeam?: Maybe<JoinChallengeTeamResponse>;
  joinDigitalClinicalProgram?: Maybe<Scalars['Boolean']['output']>;
  leaveChallenge: LeaveChallengeResponse;
  leaveChallengeTeam?: Maybe<LeaveChallengeTeamResponse>;
  leaveDigitalClinicalProgram?: Maybe<Scalars['Boolean']['output']>;
  markAsDismissed?: Maybe<BannerResponse>;
  mockMutation?: Maybe<MockMutationResponse>;
  report?: Maybe<ContentReportResponse>;
  /** Allows a user who was flagged as at risk during the care journey to be contacted later with the provided contact details. */
  requestCallbackForAtRiskMember?: Maybe<RequestCallbackForAtRiskMemberResponse>;
  retakeAssessment?: Maybe<RetakeAssessmentResponse>;
  /** Retake an individual challenge once it has ended */
  retakeIndividualChallenge: RetakeIndividualChallengeResponse;
  /** Set care service option for a user. */
  selectCareServiceOption?: Maybe<SelectCareServiceOptionResponse>;
  selectCounsellor?: Maybe<SelectCounsellorResponse>;
  selectHabits?: Maybe<SelectedHabitsResponse>;
  /** Set care issue for a user */
  setCareIssue?: Maybe<SetCareIssueResponse>;
  /** Set goal for a user */
  setGoal?: Maybe<SetGoalResponse>;
  submitFeedback?: Maybe<SubmitFeedbackResponse>;
  trackDigitalClinicalProgramProgress?: Maybe<DigitalClinicalProgram>;
  unFavoriteMerchant?: Maybe<UnFavoriteMerchantResponse>;
  updateActivitySource?: Maybe<UpdateActivitySourceResponse>;
  /**
   * Only team admins are allowed to update the team details.
   * Currently the user who created the team is the only admin
   */
  updateChallengeTeamName?: Maybe<UpdateChallengeTeamResponse>;
  updateHabitActivity?: Maybe<UpdateHabitActivityResponse>;
  updatePersonalisationPermission?: Maybe<UpdatePersonalisationPermissionResponse>;
  uploadExerciseActivity?: Maybe<UploadExerciseEventsResponse>;
};

export type MutationAnswerAssessmentQuestionsArgs = {
  request?: InputMaybe<AnswerAssessmentQuestionsInput>;
};

export type MutationAnswerPreAssessmentArgs = {
  request: AnswerPreAssessmentInput;
};

export type MutationAssignCarePlanArgs = {
  input?: InputMaybe<AssignCarePlanInput>;
};

export type MutationBookAppointmentArgs = {
  input: BookAppointmentInput;
};

export type MutationCancelAppointmentArgs = {
  input: CancelAppointmentInput;
};

export type MutationChangeGenderArgs = {
  request?: InputMaybe<ChangeGenderInput>;
};

export type MutationCompleteCarePlanActivityArgs = {
  input?: InputMaybe<CompleteCarePlanActivityInput>;
};

export type MutationCompleteCustomTierActivityArgs = {
  request?: InputMaybe<FindCustomTierActivityInput>;
};

export type MutationCompleteWalkthroughArgs = {
  request: WalkthroughTypeInput;
};

export type MutationCreateChallengeTeamArgs = {
  request?: InputMaybe<CreateChallengeTeamInput>;
};

export type MutationCreateCustomMoodFactorArgs = {
  input: CreateCustomMoodFactorInput;
};

export type MutationCreateJournalEntryArgs = {
  input: CreateJournalEntryInput;
};

export type MutationCreateMoodEntryArgs = {
  input: CreateMoodEntryInput;
};

export type MutationDeleteCustomMoodFactorsArgs = {
  input: DeleteCustomMoodFactorsInput;
};

export type MutationDeleteJournalEntryArgs = {
  input: DeleteJournalEntryInput;
};

export type MutationDeleteMoodEntryArgs = {
  input: DeleteMoodEntryInput;
};

export type MutationDismissTooltipArgs = {
  request: FindTooltipInput;
};

export type MutationEditJournalEntryArgs = {
  input: EditJournalEntryInput;
};

export type MutationEditMoodEntryArgs = {
  input: EditMoodEntryInput;
};

export type MutationFavoriteMerchantArgs = {
  input?: InputMaybe<FavoriteMerchantInput>;
};

export type MutationJoinChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationJoinChallengeTeamArgs = {
  request?: InputMaybe<JoinChallengeTeamInput>;
};

export type MutationJoinDigitalClinicalProgramArgs = {
  request: JoinDigitalClinicalProgramInput;
};

export type MutationLeaveChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationLeaveChallengeTeamArgs = {
  request?: InputMaybe<LeaveChallengeTeamInput>;
};

export type MutationLeaveDigitalClinicalProgramArgs = {
  request: LeaveDigitalClinicalProgramInput;
};

export type MutationMarkAsDismissedArgs = {
  input: BannerInput;
};

export type MutationMockMutationArgs = {
  input: MockMutationInput;
};

export type MutationReportArgs = {
  input: ReportInput;
};

export type MutationRequestCallbackForAtRiskMemberArgs = {
  input: RequestCallbackForAtRiskMemberInput;
};

export type MutationRetakeAssessmentArgs = {
  request?: InputMaybe<RetakeAssessmentInput>;
};

export type MutationRetakeIndividualChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSelectCareServiceOptionArgs = {
  request: SelectCareServiceOptionInput;
};

export type MutationSelectCounsellorArgs = {
  input: SelectCounsellorInput;
};

export type MutationSelectHabitsArgs = {
  request?: InputMaybe<SelectHabitsInput>;
};

export type MutationSetCareIssueArgs = {
  request: SetCareIssueInput;
};

export type MutationSetGoalArgs = {
  request: SetGoalInput;
};

export type MutationSubmitFeedbackArgs = {
  input: SubmitFeedbackInput;
};

export type MutationTrackDigitalClinicalProgramProgressArgs = {
  request: DigitalClinicalProgramInteractionInput;
};

export type MutationUnFavoriteMerchantArgs = {
  input?: InputMaybe<UnFavoriteMerchantInput>;
};

export type MutationUpdateActivitySourceArgs = {
  input: UpdateActivitySourceInput;
};

export type MutationUpdateChallengeTeamNameArgs = {
  request?: InputMaybe<UpdateChallengeTeamNameInput>;
};

export type MutationUpdateHabitActivityArgs = {
  request?: InputMaybe<UpdateHabitActivityInput>;
};

export type MutationUpdatePersonalisationPermissionArgs = {
  request?: InputMaybe<UpdatePersonalisationPermissionInput>;
};

export type MutationUploadExerciseActivityArgs = {
  request: UploadExerciseEventsInput;
};

export type NextTier = {
  __typename: 'NextTier';
  id?: Maybe<Scalars['ID']['output']>;
  mandatoryActivities?: Maybe<Array<Maybe<MandatoryTierActivities>>>;
  meetsRequirementsForTier?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  rank?: Maybe<Scalars['Int']['output']>;
};

export type Offer = {
  id: Scalars['ID']['output'];
  /**
   * You can use this to get information about the merchant on this offer.
   * This is often used to get the name of the merchant.
   */
  merchant: Merchant;
  title: Scalars['String']['output'];
};

export type OffersInput = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FetchOffersOrder>;
  where?: InputMaybe<FetchOffersWhere>;
};

export type OffersPaginatedResponse = {
  __typename: 'OffersPaginatedResponse';
  data: Array<Offer>;
  paging?: Maybe<Pagination>;
};

export enum OffersSort {
  Alphabetical = 'alphabetical',
  EndingSoon = 'endingSoon',
  HighestCashback = 'highestCashback',
  MostPopular = 'mostPopular',
  MostSpecialOffers = 'mostSpecialOffers',
  SelectedByWa = 'selectedByWA',
}

export enum Order {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export type PageChallengeTeamsLeaderboardInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type PageTierActivitiesInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginatedAppointments = {
  __typename: 'PaginatedAppointments';
  data: Array<Appointment>;
  paging: Pagination;
};

export type PaginatedCarePlans = {
  __typename: 'PaginatedCarePlans';
  data: Array<CarePlan>;
  paging: Pagination;
};

export type PaginatedCounsellors = {
  __typename: 'PaginatedCounsellors';
  data?: Maybe<Array<Counsellor>>;
  paging?: Maybe<Pagination>;
};

export type PaginatedJournalEntries = {
  __typename: 'PaginatedJournalEntries';
  data: Array<JournalEntry>;
  paging: Pagination;
};

export type PaginatedMoodEntries = {
  __typename: 'PaginatedMoodEntries';
  data: Array<MoodEntry>;
  paging: Pagination;
};

export type PaginatedTierActivities = {
  __typename: 'PaginatedTierActivities';
  data?: Maybe<Array<TierActivity>>;
  paging: Pagination;
};

export type PaginatedWorkLifeServices = {
  __typename: 'PaginatedWorkLifeServices';
  data: Array<WorkLifeService>;
  paging: Pagination;
};

export type Pagination = {
  __typename: 'Pagination';
  count: Scalars['Int']['output'];
  cursors: PaginationCursors;
  isLastPage: Scalars['Boolean']['output'];
  limit?: Maybe<Scalars['Int']['output']>;
};

export type PaginationCursors = {
  __typename: 'PaginationCursors';
  after?: Maybe<Scalars['String']['output']>;
  before?: Maybe<Scalars['String']['output']>;
};

export type PaymentRequirementsResponse = {
  __typename: 'PaymentRequirementsResponse';
  isPaymentRequired: Scalars['Boolean']['output'];
};

export type PensionAndBenefitsSummary = {
  __typename: 'PensionAndBenefitsSummary';
  benefits?: Maybe<BenefitsSummary>;
  notifications?: Maybe<PensionsAndBenefitsNotificationSummary>;
  pension?: Maybe<PensionSummary>;
  title: Scalars['String']['output'];
};

export type PensionAndBenefitsTemporaryUrl = {
  __typename: 'PensionAndBenefitsTemporaryUrl';
  expiry: Scalars['GraphQLDateTime']['output'];
  url: Scalars['String']['output'];
};

export type PensionAndBenefitsTotal = {
  __typename: 'PensionAndBenefitsTotal';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PensionSummary = {
  __typename: 'PensionSummary';
  portalUrlId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  totals?: Maybe<Array<PensionAndBenefitsTotal>>;
};

export type PensionsAndBenefitsNotification = {
  __typename: 'PensionsAndBenefitsNotification';
  details: Scalars['String']['output'];
  expiryDate?: Maybe<Scalars['GraphQLDateTime']['output']>;
  portalUrlId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PensionsAndBenefitsNotificationSummary = {
  __typename: 'PensionsAndBenefitsNotificationSummary';
  count: Scalars['Int']['output'];
  items?: Maybe<Array<PensionsAndBenefitsNotification>>;
};

export type PerksCategoriesInput = {
  includeFavorites?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PerksType>;
};

export type PerksCategoriesResponse = {
  __typename: 'PerksCategoriesResponse';
  categories: Array<PerksCategory>;
};

export type PerksCategory = {
  __typename: 'PerksCategory';
  icon?: Maybe<Image>;
  id: Scalars['ID']['output'];
  /** Localized name of the category. */
  name: Scalars['String']['output'];
};

/** A section combining a title and a detail of rich text. */
export type PerksFormattedTextSection = {
  __typename: 'PerksFormattedTextSection';
  detail: FormattedText;
  id: Scalars['ID']['output'];
  title: FormattedText;
};

export enum PerksType {
  Cashback = 'cashback',
}

export type PreAssessmentHistoryPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type PreAssessmentNotTaken = {
  __typename: 'PreAssessmentNotTaken';
  /** A unique identifier for the assessment. Is also used for tracking in google analytics. */
  id: Scalars['ID']['output'];
  /**
   * A unique value that is used to track responses when this assessment is retaken on its own
   * This sessionId is then used in calls to the preAssessment query and answerPreAssessment mutation
   */
  sessionId: Scalars['String']['output'];
  /** Localized title of the result. */
  title: Scalars['String']['output'];
};

export type PreAssessmentRecommendation =
  | PreAssessmentRecommendationBookAppointment
  | PreAssessmentRecommendationSuicideRiskCallNow;

export type PreAssessmentRecommendationBookAppointment = {
  __typename: 'PreAssessmentRecommendationBookAppointment';
  id: Scalars['ID']['output'];
};

export type PreAssessmentRecommendationSuicideRiskCallNow = {
  __typename: 'PreAssessmentRecommendationSuicideRiskCallNow';
  ccsTeamTelephoneNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type PreAssessmentResponse = {
  __typename: 'PreAssessmentResponse';
  /** The next assessment in the pre assessment sequence. Null if there are no more asseessments remaining for pre assessment. */
  nextAssessment?: Maybe<CareAssessment>;
};

export type PreAssessmentResult = {
  __typename: 'PreAssessmentResult';
  /** The UTC Datetime that the PreAssessment was completed. */
  completedAt?: Maybe<Scalars['GraphQLDateTime']['output']>;
  /** Localized description of the result. */
  description: Scalars['String']['output'];
  /**
   * The lower bound of the last range in list of severities
   * This FEs uses this to aid in creating bar graphs
   */
  displayMaxScore: Scalars['Int']['output'];
  /** A unique identifier for the assessment. Is also used for tracking in google analytics. */
  id: Scalars['ID']['output'];
  /** Maximum possible score a user can have. */
  maxScore: Scalars['Int']['output'];
  /** Contains information related to when the user should retake the assessment based on clinical recommendation. */
  retakeData: RetakeData;
  /** Score. */
  score: Scalars['Int']['output'];
  /**
   * A unique value that is used to track responses when this assessment is retaken on its own
   * This sessionId is then used in calls to the preAssessment query and answerPreAssessment mutation
   */
  sessionId: Scalars['String']['output'];
  /**
   * Localized list of possible severities for this result.
   * Ordered from low to high.
   * NOTE: The list of severities is not consistent across all assessments.
   */
  severities: Array<Scalars['String']['output']>;
  /** Localized title of the result. */
  title: Scalars['String']['output'];
};

export type PreAssessmentResults = {
  __typename: 'PreAssessmentResults';
  /** Returns the assessments that the user has completed or not taken yet. */
  assessments: Array<PreAssessmentStatus>;
  /**
   * If true FE must display the "Speak to counsellor"
   * @deprecated Use shouldSpeakToCounsellor
   */
  displaySpeakToCounsellorMessage: Scalars['Boolean']['output'];
  /** Indicates if the user should speak to a counsellor based on their score. */
  shouldSpeakToCounsellor: Scalars['Boolean']['output'];
  /**
   * Returns the assessments that have been completed.
   * @deprecated We now want to include assessments the user has not taken yet. This will always return completed assessment to keep backwards compatability
   */
  summary: Array<PreAssessmentResult>;
};

export type PreAssessmentResultsHistoryResponse = {
  __typename: 'PreAssessmentResultsHistoryResponse';
  history?: Maybe<Array<Maybe<PreAssessmentResult>>>;
  pagination: Pagination;
};

export type PreAssessmentResultsResponse = {
  __typename: 'PreAssessmentResultsResponse';
  /**
   * When `true` indicates the user has one or more assessments for which time to retake has come.
   * This is related to `RetakeData.earlyRetakeWarningMessage`.
   */
  isTimeToRetakeAssessments: Scalars['Boolean']['output'];
  results: PreAssessmentResults;
};

/** This union represents assessments that the user has completed (has a result) or assessments the user has not taken yet. */
export type PreAssessmentStatus = PreAssessmentNotTaken | PreAssessmentResult;

export enum PromotionType {
  Assessment = 'Assessment',
  CorporateChallenge = 'CorporateChallenge',
  Hra = 'HRA',
  TotalHealthIndex = 'TotalHealthIndex',
}

export type Query = {
  __typename: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `fetchAssessments`. */
  allAssessments?: Maybe<Array<AssessmentListItem>>;
  /** @deprecated Use `digitalClinicalPrograms`. */
  allDigitalClinicalPrograms: Array<DigitalClinicalProgram>;
  allTierActivities?: Maybe<PaginatedTierActivities>;
  allTiers?: Maybe<Array<TierDefinition>>;
  /**
   * Obtain the payment setup intent so the user can proceed with the payment flow on the FE.
   * Expected for BE to generate the Stripe payment intent and associate it with the current logged in user.
   */
  appointmentPaymentSetupIntent?: Maybe<AppointmentPaymentSetupIntentResponse>;
  /**
   * If the user was assessed as at suicide risk, the ccsTelephoneNumber is returned so the at risk card can be displayed
   * Otherwise null is returned.
   */
  assessedAsSuicideRisk?: Maybe<AssessedAsSuicideRiskResponse>;
  assessment?: Maybe<AssessmentListItem>;
  assessmentHeroCards?: Maybe<Array<AssessmentHeroCard>>;
  assessmentHistory?: Maybe<Array<Maybe<AssessmentItemHistory>>>;
  /** @deprecated Use `wellbeingPromotions`. */
  assessmentPromotions?: Maybe<Array<AssessmentPromotion>>;
  assessmentTierPoints: AssessmentTierPoints;
  assessmentTypeFilters?: Maybe<Array<AssessmentTypeFilter>>;
  /** The care plans available for the user to select. */
  availableCarePlans?: Maybe<AvailableCarePlansResponse>;
  /** Retrieves the available mood factors that the user can select. */
  availableMoodFactors?: Maybe<AvailableMoodFactorsResponse>;
  averageDailyMoodInsights?: Maybe<AverageDailyMoodInsightsResponse>;
  banner?: Maybe<BannerResponse>;
  /** The care plan currently assigned to the user. */
  carePlan?: Maybe<CarePlanResponse>;
  /** Whenever an activity is retrieved from the server, it will be set as the current activity. */
  carePlanActivity?: Maybe<CarePlanActivityResponse>;
  /** @deprecated Use `wellbeingPromotions`. */
  challengePromotions?: Maybe<Array<ChallengePromotion>>;
  currentTier?: Maybe<CurrentTier>;
  customTierActivities?: Maybe<Array<CustomTierActivity>>;
  customTierActivity?: Maybe<CustomTierActivity>;
  /**
   * We have to rename this query to fix a duplicate error before just removing the subgraph
   * and schema from offers API entirely
   */
  deprecatedPerksCategories?: Maybe<PerksCategoriesResponse>;
  digitalClinicalModule?: Maybe<DigitalClinicalModule>;
  digitalClinicalProgram?: Maybe<DigitalClinicalProgram>;
  digitalClinicalProgramCategories?: Maybe<
    Array<Maybe<DigitalClinicalProgramCategory>>
  >;
  digitalClinicalProgramCategory?: Maybe<DigitalClinicalProgramCategory>;
  digitalClinicalPrograms?: Maybe<Array<DigitalClinicalProgramListItem>>;
  encourageEarningTierPoints?: Maybe<TierEncouragement>;
  /** @deprecated Use `assessmentHeroCards`. */
  featuredAssessments?: Maybe<Array<FeaturedAssessment>>;
  /** Asks for the feedback request for a given feedback id. */
  feedbackRequest?: Maybe<FeedbackRequestResponse>;
  fetchAppointmentById?: Maybe<FetchAppointmentResponse>;
  fetchAppointments?: Maybe<FetchAppointmentsResponse>;
  fetchAssessments?: Maybe<FetchAssessmentsResponse>;
  fetchChallenge: FetchChallengeResponse;
  fetchChallengeTeam?: Maybe<FetchTeamResponse>;
  fetchChallenges?: Maybe<FetchChallengesResponse>;
  fetchCounsellor?: Maybe<FetchCounsellorResponse>;
  fetchCounsellorById?: Maybe<FetchCounsellorResponse>;
  fetchCounsellorFilters?: Maybe<FetchCounsellorFiltersResponse>;
  fetchCounsellors?: Maybe<FetchCounsellorsResponse>;
  fetchFeaturedChallenges?: Maybe<FetchFeaturedChallengesResponse>;
  fetchFirstOfferedAvailableTimeSlot?: Maybe<FetchFirstOfferedAvailableTimeSlotResponse>;
  fetchTransaction: FetchTransactionResult;
  fetchUserSubdivisions?: Maybe<FetchUserSubdivisionsResponse>;
  getActivitySource?: Maybe<GetActivitySourceResponse>;
  getAssessment?: Maybe<AssessmentListItem>;
  /** Get the list of all the available CareIssues */
  getCareIssues?: Maybe<CareIssuesResponse>;
  /**
   * Returns a paginated teams leaderboard for a specific date
   * If the corporate challenge has not started the teams will
   * be returned in alphabetical order without a score.
   * Once the corporate challenge is in progress the list will
   * be a leaderboard showing which team is currently winning.
   * The leaderboard is ordered by the team's score.
   * The team's score is a median of each team member's score.
   * If the user's team is on the first page then we prepend
   * the team to the top of the list
   * @deprecated Use `leaderboardTeams` field on `StepChallenge` or `HabitChallenge`.
   */
  getChallengeTeamsLeaderboard?: Maybe<GetChallengeTeamsLeaderboardResponse>;
  getHra?: Maybe<FeaturedAssessment>;
  getWalkthrough: Walkthrough;
  /** Get the list of all the available Goals */
  goals?: Maybe<GoalsResponse>;
  historicalAssessment?: Maybe<HistoricalAssessment>;
  /** Get content and settings that describe personalisation and the users current privacy settings */
  howDoesPersonalisationWork?: Maybe<HowDoesPersonalisationWorkResponse>;
  howToEarnTierPoints?: Maybe<Array<HowToEarnTierPoints>>;
  /** @deprecated Use `wellbeingPromotions`. */
  hraPromotion?: Maybe<HraPromotion>;
  influencesMoodInsights?: Maybe<InfluencesMoodInsightsResponse>;
  /**
   * Retrieves the journal entries for the current user.
   * Note: Ordered from most recent to oldest.
   */
  journalEntries?: Maybe<JournalEntriesResponse>;
  /** A specific Perks Merchant */
  merchant?: Maybe<MerchantResponse>;
  /** Perks Merchants */
  merchants?: Maybe<FetchMerchantsResponse>;
  /**
   * Retrieves the mood entries for the current user.
   * Note: Ordered from most recent to oldest.
   */
  moodEntries?: Maybe<MoodEntriesResponse>;
  nextTier?: Maybe<NextTier>;
  offers?: Maybe<FetchOffersResponse>;
  /** Contains flag specifying whether current user needs to input card information */
  paymentRequirements?: Maybe<PaymentRequirementsResponse>;
  pensionAndBenefitsPortalUrl?: Maybe<PensionAndBenefitsTemporaryUrl>;
  pensionAndBenefitsSummary?: Maybe<PensionAndBenefitsSummary>;
  perksCategories?: Maybe<PerksCategoriesResponse>;
  /**
   * Returns the next PreAssessment based on any that may have been previously answered
   *
   * A specific assessment may be fetched by supplying the FilterPreAssessmentInput parameter
   *
   * The `retake` field in FilterPreAssessmentInput allows the user to retake assessments by
   * restarting the assessment flow
   */
  preAssessment?: Maybe<PreAssessmentResponse>;
  /**
   * Returns the user's most recent pre-assessment results.
   * If the user has not completed the pre-assessments as yet, then an empty response is returned
   */
  preAssessmentResults?: Maybe<PreAssessmentResultsResponse>;
  /**
   * Returns the user's recent pre-assessment results for a specific assessment.
   * The results are ordered from most recent to least recent
   */
  preAssessmentResultsHistory?: Maybe<PreAssessmentResultsHistoryResponse>;
  /**
   * Get recommended articles for the user. ML recommendations may be used depending on company fks
   * and the users personalisation preferences.
   */
  recommendedArticles?: Maybe<RecommendedArticlesResponse>;
  /** Get the card previously selected by the user */
  selectedCard?: Maybe<SelectedCardResponse>;
  /** Get the user selected care service option. */
  selectedCareServiceOption?: Maybe<SelectedCareServiceOptionResponse>;
  /** Get the credentials needed to initialize Stripe FE SDKs. */
  stripeInitialization?: Maybe<StripeInitializationResponse>;
  tierPoints?: Maybe<TierPoints>;
  tierRegressionInfo?: Maybe<TierRegressionInfo>;
  tooltip?: Maybe<Tooltip>;
  totalHealthIndexResult: TotalHealthIndexResult;
  wellbeingContentLanguageMessage?: Maybe<WellbeingContentLanguageMessageResponse>;
  wellbeingPromotions?: Maybe<Array<WellbeingPromotion>>;
  /** The Work Life services that the logged in user is entitled to access. */
  workLifeServices?: Maybe<WorkLifeServicesResponse>;
};

export type QueryAllAssessmentsArgs = {
  where?: InputMaybe<FilterAssessmentsInput>;
};

export type QueryAllTierActivitiesArgs = {
  select?: InputMaybe<PageTierActivitiesInput>;
};

export type QueryAssessmentArgs = {
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FindAssessmentInput>;
};

export type QueryAssessmentHeroCardsArgs = {
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FilterAssessmentsInput>;
};

export type QueryAssessmentHistoryArgs = {
  where?: InputMaybe<FindAssessmentHistoryInput>;
};

export type QueryAvailableCarePlansArgs = {
  input?: InputMaybe<AvailableCarePlansInput>;
};

export type QueryAverageDailyMoodInsightsArgs = {
  input?: InputMaybe<AverageDailyMoodInsightsInput>;
};

export type QueryBannerArgs = {
  input: BannerInput;
};

export type QueryCarePlanActivityArgs = {
  where?: InputMaybe<CarePlanActivityInput>;
};

export type QueryCustomTierActivityArgs = {
  where?: InputMaybe<FindCustomTierActivityInput>;
};

export type QueryDeprecatedPerksCategoriesArgs = {
  input: PerksCategoriesInput;
};

export type QueryDigitalClinicalModuleArgs = {
  where: FindDigitalClinicalModuleInput;
};

export type QueryDigitalClinicalProgramArgs = {
  where: FindDigitalClinicalProgramInput;
};

export type QueryDigitalClinicalProgramCategoryArgs = {
  where: FindDigitalClinicalCategoryInput;
};

export type QueryDigitalClinicalProgramsArgs = {
  select?: InputMaybe<SliceDigitalClinicalProgramsInput>;
  where?: InputMaybe<FilterDigitalClinicalProgramsInput>;
};

export type QueryFeaturedAssessmentsArgs = {
  where?: InputMaybe<FilterAssessmentsInput>;
};

export type QueryFeedbackRequestArgs = {
  input: FeedbackRequestInput;
};

export type QueryFetchAppointmentByIdArgs = {
  input: FetchAppointmentByIdInput;
};

export type QueryFetchAppointmentsArgs = {
  input?: InputMaybe<FetchAppointmentsInput>;
};

export type QueryFetchAssessmentsArgs = {
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FilterAssessmentsInput>;
};

export type QueryFetchChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFetchChallengeTeamArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFetchChallengesArgs = {
  select?: InputMaybe<SliceChallengesInput>;
  where?: InputMaybe<FilterChallengesInput>;
};

export type QueryFetchCounsellorByIdArgs = {
  input: FetchCounsellorByIdInput;
};

export type QueryFetchCounsellorFiltersArgs = {
  input?: InputMaybe<FetchCounsellorFiltersInput>;
};

export type QueryFetchCounsellorsArgs = {
  input: FetchCounsellorsInput;
};

export type QueryFetchFeaturedChallengesArgs = {
  where?: InputMaybe<FilterChallengesInput>;
};

export type QueryFetchFirstOfferedAvailableTimeSlotArgs = {
  input?: InputMaybe<FetchFirstOfferedAppointmentInput>;
};

export type QueryFetchTransactionArgs = {
  transaction_id: Scalars['ID']['input'];
};

export type QueryGetAssessmentArgs = {
  assessmentId: Scalars['ID']['input'];
};

export type QueryGetCareIssuesArgs = {
  where?: InputMaybe<GetCareIssuesInput>;
};

export type QueryGetChallengeTeamsLeaderboardArgs = {
  select?: InputMaybe<PageChallengeTeamsLeaderboardInput>;
  where?: InputMaybe<FindChallengeTeamsLeaderboardInput>;
};

export type QueryGetWalkthroughArgs = {
  where?: InputMaybe<WalkthroughTypeInput>;
};

export type QueryHistoricalAssessmentArgs = {
  where?: InputMaybe<FindHistoricalAssessmentInput>;
};

export type QueryInfluencesMoodInsightsArgs = {
  input?: InputMaybe<InfluencesMoodInsightsInput>;
};

export type QueryJournalEntriesArgs = {
  input: JournalEntriesInput;
};

export type QueryMerchantArgs = {
  input: MerchantInput;
};

export type QueryMerchantsArgs = {
  input: MerchantsInput;
};

export type QueryMoodEntriesArgs = {
  input: MoodEntriesInput;
};

export type QueryOffersArgs = {
  input: OffersInput;
};

export type QueryPensionAndBenefitsPortalUrlArgs = {
  id: Scalars['String']['input'];
};

export type QueryPerksCategoriesArgs = {
  input: PerksCategoriesInput;
};

export type QueryPreAssessmentArgs = {
  where?: InputMaybe<FilterPreAssessmentInput>;
};

export type QueryPreAssessmentResultsHistoryArgs = {
  where?: InputMaybe<FilterPreAssessmentHistoryInput>;
};

export type QueryRecommendedArticlesArgs = {
  select?: InputMaybe<SliceRecommendedArticlesInput>;
};

export type QueryTooltipArgs = {
  where: FindTooltipInput;
};

export type QueryWellbeingContentLanguageMessageArgs = {
  section: WellbeingContentLanguageMessageSection;
};

export type QueryWorkLifeServicesArgs = {
  input: WorkLifeServicesInput;
};

export enum QuestionnaireQuestionType {
  MultipleChoice = 'MultipleChoice',
  SingleChoice = 'SingleChoice',
  UnitsOfMeasurements = 'UnitsOfMeasurements',
}

export type RecommendedArticlesResponse = {
  __typename: 'RecommendedArticlesResponse';
  /** only returned if the user has eap_self_assessment, eap_assistance, enhanced_personalisation, assessments_article_recommendations_carousel fks */
  recommendations?: Maybe<Array<Article>>;
};

export type RemainingAssessmentsCompletionPoints = {
  __typename: 'RemainingAssessmentsCompletionPoints';
  bonusPoints?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  resetDate?: Maybe<Scalars['GraphQLDateTime']['output']>;
  type?: Maybe<AssessmentGroupType>;
};

export type ReportInput = {
  /**
   * Identifier of the target object target of the report
   * (ex: post id, comment id or profile id)
   */
  targetId: Scalars['ID']['input'];
  /** The kind of target for the report (post, comment...) */
  targetKind: ReportTargetKind;
};

export enum ReportTargetKind {
  FeedComment = 'FEED_COMMENT',
  FeedPost = 'FEED_POST',
  ProfileBio = 'PROFILE_BIO',
  ProfileImage = 'PROFILE_IMAGE',
}

export type RequestCallbackForAtRiskMemberInput = {
  /** User's preferred telephone number. */
  telephoneNumber: Scalars['String']['input'];
};

export type RequestCallbackForAtRiskMemberResponse = {
  __typename: 'RequestCallbackForAtRiskMemberResponse';
  telephoneNumber?: Maybe<Scalars['String']['output']>;
};

export type ResultFeedback = Feedback & {
  __typename: 'ResultFeedback';
  body: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type RetakeData = {
  __typename: 'RetakeData';
  /**
   * Localized text to inform the user when they shouldn't retake the assessment since not enough time has passed.
   * The BE will do the check and it will only populate this field with the message only when not enough time has passed otherwise it will be null.
   */
  earlyRetakeWarningMessage?: Maybe<Scalars['String']['output']>;
};

export type RetakeIndividualChallengeResponse = {
  __typename: 'RetakeIndividualChallengeResponse';
  challenge?: Maybe<Challenge>;
};

export type ScalePoint = {
  __typename: 'ScalePoint';
  isDefault: Scalars['Boolean']['output'];
  label?: Maybe<Scalars['String']['output']>;
  scalePointId: Scalars['ID']['output'];
};

export type ScaleQuestion = {
  __typename: 'ScaleQuestion';
  contentItemId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  scale: Array<ScalePoint>;
  title: Scalars['String']['output'];
  userInteraction?: Maybe<ScaleQuestionInteraction>;
};

export type ScaleQuestionInteraction = {
  __typename: 'ScaleQuestionInteraction';
  scalePointId?: Maybe<Scalars['ID']['output']>;
  seenByUser: Scalars['Boolean']['output'];
};

export type ScaleQuestionInteractionInput = {
  contentItemId: Scalars['ID']['input'];
  scalePointId?: InputMaybe<Scalars['ID']['input']>;
  seenByUser: Scalars['Boolean']['input'];
};

export type SelectCareServiceOptionInput = {
  option: CareServiceOption;
};

export type SelectCareServiceOptionResponse = {
  __typename: 'SelectCareServiceOptionResponse';
  option: CareServiceOption;
};

export type SelectCounsellorInput = {
  counsellorId: Scalars['ID']['input'];
};

export type SelectCounsellorResponse = {
  __typename: 'SelectCounsellorResponse';
  counsellor: Counsellor;
};

export type SelectHabitCaption = {
  __typename: 'SelectHabitCaption';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SelectHabitsData = {
  habitId: Scalars['String']['input'];
  selected: Scalars['Boolean']['input'];
};

export type SelectHabitsInput = {
  challengeId: Scalars['String']['input'];
  habits: Array<SelectHabitsData>;
};

export type SelectedCard = {
  __typename: 'SelectedCard';
  cardBrand: CardBrand;
  /** Stripe returns the expiration month as a Integer, just forward that to FEs */
  expirationMonth: Scalars['Int']['output'];
  /** Stripe returns the expiration year as a Integer, just forward that to FEs */
  expirationYear: Scalars['Int']['output'];
  /** If expiration date is before today return true, false otherwise. Card is valid until last day of the month */
  isExpired: Scalars['Boolean']['output'];
  last4Digits: Scalars['String']['output'];
};

export type SelectedCardResponse = {
  __typename: 'SelectedCardResponse';
  /** This should return the last selected user's card, or null if no card was added yet */
  selectedCard?: Maybe<SelectedCard>;
};

export type SelectedCareServiceOptionResponse = {
  __typename: 'SelectedCareServiceOptionResponse';
  option: CareServiceOption;
};

export type SelectedHabitsResponse = {
  __typename: 'SelectedHabitsResponse';
  habits: Array<Habit>;
};

export type SetCareIssueInput = {
  /** The id of the CareIssue to set for the the user. */
  id: Scalars['ID']['input'];
};

export type SetCareIssueResponse = {
  __typename: 'SetCareIssueResponse';
  careIssue: CareIssue;
};

export type SetGoalInput = {
  /** The id of the goal to set as the user's goal. */
  id: Scalars['ID']['input'];
};

export type SetGoalResponse = {
  __typename: 'SetGoalResponse';
  goal: Goal;
};

export type SingleAssessmentCard = {
  __typename: 'SingleAssessmentCard';
  assessment: AssessmentListItem;
  completedAt?: Maybe<Scalars['GraphQLDateTime']['output']>;
  heroCardId: Scalars['String']['output'];
  icon?: Maybe<Image>;
  introduction: Scalars['String']['output'];
  status: AssessmentStatus;
  tierPoints?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type SliceChallengesInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type SliceDigitalClinicalProgramsInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type SliceRecommendedArticlesInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
};

export enum SourceDevice {
  Fitbit = 'FITBIT',
  Googlefit = 'GOOGLEFIT',
  Healthconnect = 'HEALTHCONNECT',
  Healthkit = 'HEALTHKIT',
  Manual = 'MANUAL',
  None = 'NONE',
}

export type StarRating = {
  __typename: 'StarRating';
  isDefault: Scalars['Boolean']['output'];
  label?: Maybe<Scalars['String']['output']>;
  starRatingId: Scalars['ID']['output'];
};

export type StarRatingQuestion = {
  __typename: 'StarRatingQuestion';
  contentItemId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  starRatings: Array<StarRating>;
  title: Scalars['String']['output'];
  userInteraction?: Maybe<StarRatingQuestionInteraction>;
};

export type StarRatingQuestionInteraction = {
  __typename: 'StarRatingQuestionInteraction';
  seenByUser: Scalars['Boolean']['output'];
  starRatingId?: Maybe<Scalars['ID']['output']>;
};

export type StarRatingQuestionInteractionInput = {
  contentItemId: Scalars['ID']['input'];
  seenByUser: Scalars['Boolean']['input'];
  starRatingId?: InputMaybe<Scalars['ID']['input']>;
};

export type StepActivity = {
  __typename: 'StepActivity';
  durationInMs?: Maybe<Scalars['Int']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  endedAt?: Maybe<Scalars['String']['output']>;
  /** @deprecated Location tracking has been removed. */
  location?: Maybe<StepActivityLocation>;
  startedAt?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ExerciseActivityType>;
};

export type StepActivityLocation = {
  __typename: 'StepActivityLocation';
  name?: Maybe<Scalars['String']['output']>;
};

export type StepActivityResponse = {
  __typename: 'StepActivityResponse';
  activity?: Maybe<Array<StepDailyActivity>>;
  pagination?: Maybe<Pagination>;
  summary?: Maybe<Array<EndOfChallengeSummaryTotal>>;
};

export enum StepActivityType {
  DailyTotal = 'DailyTotal',
  EndOfChallengeTotal = 'EndOfChallengeTotal',
}

export type StepChallenge = {
  __typename: 'StepChallenge';
  activityTracking?: Maybe<ChallengeActivityTracking>;
  category: ChallengeCategory;
  categoryDisplayText: Scalars['String']['output'];
  challengeId: Scalars['ID']['output'];
  challengeType?: Maybe<ChallengeType>;
  completionReward?: Maybe<TierReward>;
  defaultPromotionalPost?: Maybe<Scalars['String']['output']>;
  durationInDays?: Maybe<Scalars['Int']['output']>;
  endDateTimeISO?: Maybe<Scalars['GraphQLDateTime']['output']>;
  goal: Scalars['Int']['output'];
  image?: Maybe<Image>;
  introduction?: Maybe<Array<Maybe<ChallengeIntroduction>>>;
  lastUploaded?: Maybe<Scalars['GraphQLDateTime']['output']>;
  /**
   * Returns a paginated individuals leaderboard for a specific
   * date. The leaderboard includes everyone in the company
   * who is participating in the challenge.
   * If the corporate challenge has not started the users
   * will be returned in alphabetical order without a score.
   * Once the corporate challenge is in progress the list will
   * be a leaderboard showing which user is currently winning.
   * The leaderboard is ordered by every user's score.
   */
  leaderboardIndividuals: LeaderboardIndividualResponse;
  /**
   * Returns a paginated teams leaderboard for a specific date
   * If the corporate challenge has not started the teams will
   * be returned in alphabetical order without a score.
   * Once the corporate challenge is in progress the list will
   * be a leaderboard showing which team is currently winning.
   * The leaderboard is ordered by the team's score.
   * The team's score is an average of active team members' scores.
   * If the user's team is on the first page then we prepend
   * the team to the top of the list
   */
  leaderboardTeams: LeaderboardTeamResponse;
  participants?: Maybe<Scalars['Int']['output']>;
  participationType: ChallengeParticipationType;
  result?: Maybe<ChallengeResult>;
  startDateTimeISO?: Maybe<Scalars['GraphQLDateTime']['output']>;
  status: ChallengeStatus;
  stepActivity: StepActivityResponse;
  subtitle?: Maybe<Scalars['String']['output']>;
  teams?: Maybe<Scalars['Int']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  userTeamMembership: UserTeamMembershipResponse;
  /** Indicates if the current user is actively in the challenge */
  wasJoined: Scalars['Boolean']['output'];
};

export type StepChallengeLeaderboardIndividualsArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type StepChallengeLeaderboardTeamsArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type StepChallengeStepActivityArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['GraphQLDate']['input']>;
};

export type StepDailyActivity = {
  __typename: 'StepDailyActivity';
  activity?: Maybe<Array<StepActivity>>;
  date: Scalars['GraphQLDate']['output'];
  id: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  tierReward?: Maybe<ChallengeDailyReward>;
  tracked: Scalars['Int']['output'];
  type: StepActivityType;
};

export type StripeInitialization = {
  __typename: 'StripeInitialization';
  publishableKey: Scalars['String']['output'];
};

/** Generic Initialization for Stripe SDKs */
export type StripeInitializationResponse = {
  __typename: 'StripeInitializationResponse';
  stripeInitialization: StripeInitialization;
};

/** Stripe Setup Intent used to start the Checkout form. */
export type StripeSetupIntent = {
  __typename: 'StripeSetupIntent';
  customerEphemeralKey: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  customerSessionClientSecret: Scalars['String']['output'];
  intentClientSecret: Scalars['String']['output'];
};

export type Subdivision = {
  __typename: 'Subdivision';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SubmitFeedbackInput = {
  id: FeedbackId;
  /** Number of stars selected by the user. */
  rating: Scalars['Int']['input'];
  /** Content of the free text input entered by the user. */
  text?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitFeedbackResponse = {
  __typename: 'SubmitFeedbackResponse';
  /** Just some property to make the response compliant. */
  id: FeedbackId;
};

export type Team = {
  __typename: 'Team';
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  memberCount?: Maybe<Scalars['Int']['output']>;
  /**
   * Returns a paginated leaderboard of members for a team
   * If the corporate challenge has not started the members
   * will be returned in alphabetical order without a score.
   * Once the corporate challenge is in progress the list will
   * become a leaderboard showing which members are winning.
   * The leaderboard is ordered by each member's score.
   */
  members: MembersResponse;
  name: Scalars['String']['output'];
  /** Current user's state within the team */
  userPermissions?: Maybe<TeamPermissions>;
};

export type TeamMembersArgs = {
  pagination: MembersPaginationInput;
};

export enum TeamMembershipPrompt {
  JoinTeam = 'JOIN_TEAM',
  LeaveTeam = 'LEAVE_TEAM',
  None = 'NONE',
}

export type TeamPermissions = {
  __typename: 'TeamPermissions';
  canCreateATeam?: Maybe<Scalars['Boolean']['output']>;
  isTeamAdmin?: Maybe<Scalars['Boolean']['output']>;
  membershipPrompt: TeamMembershipPrompt;
};

export type TeamsLeaderboardEntry = {
  __typename: 'TeamsLeaderboardEntry';
  position?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  /** Extended from team-api */
  team: Team;
};

export enum TextFormat {
  Html = 'HTML',
  Plain = 'PLAIN',
}

export type Tier = {
  __typename: 'Tier';
  name: Scalars['String']['output'];
  rank: Scalars['Int']['output'];
  tierModel?: Maybe<Scalars['String']['output']>;
};

export type TierActivity = {
  __typename: 'TierActivity';
  additionalInformation?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  completedAt: Scalars['GraphQLDateTime']['output'];
  description: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  pointsEarned?: Maybe<Scalars['Int']['output']>;
};

export type TierDefinition = {
  __typename: 'TierDefinition';
  name: Scalars['String']['output'];
  points: Scalars['Int']['output'];
  rank: Scalars['Int']['output'];
};

export type TierEncouragement = {
  __typename: 'TierEncouragement';
  iconType?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  route?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type TierPointAward = {
  __typename: 'TierPointAward';
  frequency: TierPointFrequencyType;
  points: Scalars['Int']['output'];
};

export enum TierPointFrequencyType {
  Daily = 'Daily',
  Monthly = 'Monthly',
  OneOff = 'OneOff',
  Weekly = 'Weekly',
}

export type TierPoints = {
  __typename: 'TierPoints';
  earned: TierPointsEarned;
  id?: Maybe<Scalars['ID']['output']>;
  remainingToReachNextTier?: Maybe<Scalars['Int']['output']>;
  requiredToNotRegress?: Maybe<TierPointsRequired>;
};

export type TierPointsEarned = {
  __typename: 'TierPointsEarned';
  thisMonth: TierPointsEarnedInTimePeriod;
  total: Scalars['Int']['output'];
};

export type TierPointsEarnedInTimePeriod = {
  __typename: 'TierPointsEarnedInTimePeriod';
  since?: Maybe<Scalars['GraphQLDate']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type TierPointsRequired = {
  __typename: 'TierPointsRequired';
  remaining: Scalars['Int']['output'];
  thisMonth: TierPointsRequiredInTimePeriod;
  total: Scalars['Int']['output'];
};

export type TierPointsRequiredInTimePeriod = {
  __typename: 'TierPointsRequiredInTimePeriod';
  daysRemaining: Scalars['Int']['output'];
  remaining: Scalars['Int']['output'];
  until: Scalars['GraphQLDate']['output'];
  value: Scalars['Int']['output'];
};

export type TierRegressionInfo = {
  __typename: 'TierRegressionInfo';
  currentTierDefinition: TierDefinition;
  image?: Maybe<Image>;
  timePeriods: Array<TierRegressionTimePeriodInfo>;
};

export type TierRegressionTimePeriodInfo = {
  __typename: 'TierRegressionTimePeriodInfo';
  isCurrent?: Maybe<Scalars['Boolean']['output']>;
  pointGoal?: Maybe<Scalars['Int']['output']>;
  pointsEarned?: Maybe<Scalars['Int']['output']>;
  timePeriod?: Maybe<TimePeriod>;
};

export type TierReward = {
  __typename: 'TierReward';
  info: Scalars['String']['output'];
  points: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type TimePeriod = {
  __typename: 'TimePeriod';
  from: Scalars['GraphQLDateTime']['output'];
  to: Scalars['GraphQLDateTime']['output'];
};

export type TimeSpan = {
  __typename: 'TimeSpan';
  minutes?: Maybe<Scalars['Int']['output']>;
};

export type Tooltip = {
  __typename: 'Tooltip';
  body?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Image>;
  title: Scalars['String']['output'];
  tooltipId: TooltipId;
};

export enum TooltipId {
  EarnPointsPerAssessment = 'EarnPointsPerAssessment',
  HowCanIEarnAssessmentTierPoints = 'HowCanIEarnAssessmentTierPoints',
  JoinCareNow = 'JoinCareNow',
}

export type TotalHealthIndexBracket = {
  __typename: 'TotalHealthIndexBracket';
  bracketId: Scalars['ID']['output'];
  color: Color;
  description?: Maybe<Scalars['String']['output']>;
};

export type TotalHealthIndexCard = {
  __typename: 'TotalHealthIndexCard';
  heroCardId: Scalars['String']['output'];
  nextPillar?: Maybe<AssessmentListItem>;
  score: TotalHealthIndexScore;
  status: AssessmentStatus;
  subtitle: Scalars['String']['output'];
  tierPoints?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type TotalHealthIndexPromotion = {
  __typename: 'TotalHealthIndexPromotion';
  externalId: Scalars['ID']['output'];
  icon?: Maybe<Image>;
  image?: Maybe<Image>;
  message?: Maybe<Scalars['String']['output']>;
  promotionType?: Maybe<PromotionType>;
  schedule?: Maybe<WellbeingPromotionSchedule>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type TotalHealthIndexResult = {
  __typename: 'TotalHealthIndexResult';
  articles?: Maybe<Array<Article>>;
  score: TotalHealthIndexScore;
  subtitle: Scalars['String']['output'];
  summary: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type TotalHealthIndexScore = {
  __typename: 'TotalHealthIndexScore';
  bracket: TotalHealthIndexBracket;
  percentage: Scalars['Float']['output'];
};

export type Transaction = {
  __typename: 'Transaction';
  cancellation_reason?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  source_amount: Scalars['String']['output'];
  source_currency: Scalars['String']['output'];
  source_wallet_id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  target_amount: Scalars['String']['output'];
  target_currency: Scalars['String']['output'];
  target_wallet_id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type UnFavoriteMerchantInput = {
  id: Scalars['ID']['input'];
};

export type UnFavoriteMerchantResponse = {
  __typename: 'UnFavoriteMerchantResponse';
  merchant: Merchant;
};

export type UpdateActivitySourceInput = {
  authorizationCode?: InputMaybe<Scalars['String']['input']>;
  redirectUri?: InputMaybe<Scalars['String']['input']>;
  source: ActivitySourceDevice;
};

export type UpdateActivitySourceResponse = {
  __typename: 'UpdateActivitySourceResponse';
  source: ActivitySourceDevice;
};

export type UpdateChallengeTeamNameInput = {
  challengeId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
  /** Team name is mandatory when editing team details */
  teamName: Scalars['String']['input'];
};

export type UpdateChallengeTeamResponse = {
  __typename: 'UpdateChallengeTeamResponse';
  /** The updated Team details from the database */
  team: Team;
};

export type UpdateHabitActivityInput = {
  challengeId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  habitId: Scalars['String']['input'];
  habitOptions: Array<UpdateHabitOptionActivityInput>;
};

export type UpdateHabitActivityResponse = {
  __typename: 'UpdateHabitActivityResponse';
  habitDailyActivity?: Maybe<HabitDailyActivity>;
  updatedHabitActivityTotals?: Maybe<UpdatedHabitActivityTotals>;
};

export type UpdateHabitOptionActivityInput = {
  optionId: Scalars['String']['input'];
  tracked: Scalars['Int']['input'];
};

export type UpdatePersonalisationPermissionInput = {
  allowPersonalisation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePersonalisationPermissionResponse = {
  __typename: 'UpdatePersonalisationPermissionResponse';
  permission?: Maybe<HowPersonalisationWorksPermission>;
};

export type UpdatedHabitActivityTotals = {
  __typename: 'UpdatedHabitActivityTotals';
  challengeTrackedActivityTotal: Scalars['Int']['output'];
  habitTrackedActivityTotal: Scalars['Int']['output'];
};

export type UploadExerciseEventsInput = {
  events: Array<InputMaybe<ExerciseEventInput>>;
  source?: InputMaybe<SourceDevice>;
};

export type UploadExerciseEventsResponse = {
  __typename: 'UploadExerciseEventsResponse';
  events: Array<Maybe<ExerciseEvent>>;
};

/**
 * User should ideally live in a more appropriate service
 * and be extended into Challenge Score API and Team API
 */
export type User = {
  __typename: 'User';
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
};

export type UserTeamMembershipResponse = {
  __typename: 'UserTeamMembershipResponse';
  /**
   * If the user is a member of any team in the challenge
   * they cannot create a new team within this challenge
   */
  canCreateATeam: Scalars['Boolean']['output'];
};

export type Walkthrough = {
  __typename: 'Walkthrough';
  steps: Array<WalkthroughStep>;
  wasCompleted: Scalars['Boolean']['output'];
};

export type WalkthroughStep = {
  __typename: 'WalkthroughStep';
  image?: Maybe<Image>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum WalkthroughType {
  CareNow = 'CareNow',
  Wellbeing = 'Wellbeing',
}

export type WalkthroughTypeInput = {
  walkthroughType?: InputMaybe<WalkthroughType>;
};

export type WellbeingContentLanguageBanner =
  | WellbeingContentLanguageMessageChangeLanguage
  | WellbeingContentLanguageMessageNoChoice
  | WellbeingContentLanguageMessageNoContent;

export type WellbeingContentLanguageMessageChangeLanguage = {
  __typename: 'WellbeingContentLanguageMessageChangeLanguage';
  bannerText: Scalars['String']['output'];
  bannerTextPopUp: Scalars['String']['output'];
  section: WellbeingContentLanguageMessageSection;
};

export type WellbeingContentLanguageMessageNoChoice = {
  __typename: 'WellbeingContentLanguageMessageNoChoice';
  bannerText: Scalars['String']['output'];
  bannerTextPopUp: Scalars['String']['output'];
  section: WellbeingContentLanguageMessageSection;
};

export type WellbeingContentLanguageMessageNoContent = {
  __typename: 'WellbeingContentLanguageMessageNoContent';
  bannerText: Scalars['String']['output'];
  section: WellbeingContentLanguageMessageSection;
};

export type WellbeingContentLanguageMessageResponse = {
  __typename: 'WellbeingContentLanguageMessageResponse';
  banner?: Maybe<WellbeingContentLanguageBanner>;
};

export enum WellbeingContentLanguageMessageSection {
  Assessments = 'ASSESSMENTS',
  Programs = 'PROGRAMS',
}

export type WellbeingPromotion =
  | AssessmentPromotion
  | ChallengePromotion
  | HraPromotion
  | TotalHealthIndexPromotion;

export type WellbeingPromotionSchedule = {
  __typename: 'WellbeingPromotionSchedule';
  /** @deprecated Use endDateTime */
  endDate?: Maybe<Scalars['GraphQLDate']['output']>;
  endDateTime?: Maybe<Scalars['GraphQLDateTime']['output']>;
  /** @deprecated Use startDateTime */
  startDate?: Maybe<Scalars['GraphQLDate']['output']>;
  startDateTime?: Maybe<Scalars['GraphQLDateTime']['output']>;
};

export type WorkLifeService = {
  __typename: 'WorkLifeService';
  /** Localized description of the service as a plain text String. */
  description: Scalars['String']['output'];
  /** Image resource for the icon of the service. */
  icon?: Maybe<Image>;
  /** Identifier for the service. */
  id: Scalars['ID']['output'];
  /** Localized name of the service. */
  name: Scalars['String']['output'];
};

export type WorkLifeServicesInput = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkLifeServicesResponse = {
  __typename: 'WorkLifeServicesResponse';
  services: PaginatedWorkLifeServices;
};

export type ChangeGenderInput = {
  answers: Array<AssessmentAnswersInput>;
  gender: Scalars['String']['input'];
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
  uuid: Scalars['ID']['input'];
};

export type ChangeGenderResponse = {
  __typename: 'changeGenderResponse';
  assessment?: Maybe<AssessmentListItem>;
};

export type FetchChallengeResponse = {
  __typename: 'fetchChallengeResponse';
  challenge?: Maybe<Challenge>;
};

export type FetchChallengesResponse = {
  __typename: 'fetchChallengesResponse';
  challenges?: Maybe<Array<Challenge>>;
};

export type FetchFeaturedChallengesResponse = {
  __typename: 'fetchFeaturedChallengesResponse';
  challenges?: Maybe<Array<Challenge>>;
};

export type RetakeAssessmentInput = {
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
  uuid: Scalars['ID']['input'];
};

export type RetakeAssessmentResponse = {
  __typename: 'retakeAssessmentResponse';
  assessment?: Maybe<AssessmentListItem>;
};

export type ChangeCounsellorDataQueryVariables = Exact<{
  input: FetchAppointmentsInput;
}>;

export type ChangeCounsellorDataQuery = {
  __typename: 'Query';
  fetchAppointments?: {
    __typename: 'FetchAppointmentsResponse';
    appointments: {
      __typename: 'PaginatedAppointments';
      data: Array<{
        __typename: 'Appointment';
        status: AppointmentStatus;
        allowsCancellation: boolean;
      }>;
    };
  } | null;
  fetchCounsellor?: {
    __typename: 'FetchCounsellorResponse';
    counsellor: {
      __typename: 'Counsellor';
      id: string;
      appointmentTypes: Array<AppointmentType>;
    };
  } | null;
  assessedAsSuicideRisk?: {
    __typename: 'AssessedAsSuicideRiskResponse';
    ccsTeamTelephoneNumber: string;
  } | null;
};

export type BookedCounsellorFragment = {
  __typename: 'Counsellor';
  id: string;
  appointmentTypes: Array<AppointmentType>;
  firstName?: string | null;
  lastName?: string | null;
  jobTitles?: Array<string | null> | null;
  avatarImage?: { __typename: 'Image'; url: string } | null;
};

export type BookedAppointmentFragment = {
  __typename: 'Appointment';
  id: string;
  status: AppointmentStatus;
  startDateTime: string;
  endDateTime: string;
  typeDescription: AppointmentType;
  allowsCancellation: boolean;
  counsellor: {
    __typename: 'Counsellor';
    id: string;
    appointmentTypes: Array<AppointmentType>;
    firstName?: string | null;
    lastName?: string | null;
    jobTitles?: Array<string | null> | null;
    avatarImage?: { __typename: 'Image'; url: string } | null;
  };
};

export type BookAppointmentMutationVariables = Exact<{
  input: BookAppointmentInput;
}>;

export type BookAppointmentMutation = {
  __typename: 'Mutation';
  bookAppointment?: {
    __typename: 'BookAppointmentResponse';
    appointment: {
      __typename: 'Appointment';
      id: string;
      status: AppointmentStatus;
      startDateTime: string;
      endDateTime: string;
      typeDescription: AppointmentType;
      allowsCancellation: boolean;
      counsellor: {
        __typename: 'Counsellor';
        id: string;
        appointmentTypes: Array<AppointmentType>;
        firstName?: string | null;
        lastName?: string | null;
        jobTitles?: Array<string | null> | null;
        avatarImage?: { __typename: 'Image'; url: string } | null;
      };
    };
  } | null;
};

export type AppointmentDetailsCounsellorFragment = {
  __typename: 'Counsellor';
  id: string;
  appointmentTypes: Array<AppointmentType>;
  firstName?: string | null;
  lastName?: string | null;
  jobTitles?: Array<string | null> | null;
  avatarImage?: { __typename: 'Image'; url: string } | null;
};

export type FetchAppointmentByIdQueryVariables = Exact<{
  appointmentId: Scalars['ID']['input'];
}>;

export type FetchAppointmentByIdQuery = {
  __typename: 'Query';
  fetchAppointmentById?: {
    __typename: 'FetchAppointmentResponse';
    appointment: {
      __typename: 'Appointment';
      id: string;
      status: AppointmentStatus;
      startDateTime: string;
      endDateTime: string;
      typeDescription: AppointmentType;
      allowsCancellation: boolean;
      isVisibleToUser: boolean;
      counsellor: {
        __typename: 'Counsellor';
        id: string;
        appointmentTypes: Array<AppointmentType>;
        firstName?: string | null;
        lastName?: string | null;
        jobTitles?: Array<string | null> | null;
        avatarImage?: { __typename: 'Image'; url: string } | null;
      };
    };
  } | null;
};

export type CancelledAppointmentFragment = {
  __typename: 'Appointment';
  id: string;
  startDateTime: string;
  endDateTime: string;
  typeDescription: AppointmentType;
  counsellor: {
    __typename: 'Counsellor';
    id: string;
    appointmentTypes: Array<AppointmentType>;
    firstName?: string | null;
    lastName?: string | null;
    jobTitles?: Array<string | null> | null;
    avatarImage?: { __typename: 'Image'; url: string } | null;
  };
};

export type CancelAppointmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CancelAppointmentMutation = {
  __typename: 'Mutation';
  cancelAppointment?: {
    __typename: 'CancelAppointmentResponse';
    appointment: {
      __typename: 'Appointment';
      id: string;
      startDateTime: string;
      endDateTime: string;
      typeDescription: AppointmentType;
      counsellor: {
        __typename: 'Counsellor';
        id: string;
        appointmentTypes: Array<AppointmentType>;
        firstName?: string | null;
        lastName?: string | null;
        jobTitles?: Array<string | null> | null;
        avatarImage?: { __typename: 'Image'; url: string } | null;
      };
    };
  } | null;
};

export type CounsellorLanguagesFragment = {
  __typename: 'Language';
  code?: string | null;
  name?: string | null;
};

export type CounsellorEducationsFragment = {
  __typename: 'CounsellorEducation';
  institution?: string | null;
  name?: string | null;
};

export type CounsellorInfoFragment = {
  __typename: 'CounsellorInfo';
  disability?: string | null;
  licenses?: Array<string | null> | null;
  ethnicBackground?: string | null;
  gender?: string | null;
  religion?: string | null;
  sexualOrientation?: string | null;
  specializations?: Array<string | null> | null;
};

export type CounsellorProfileFragment = {
  __typename: 'Counsellor';
  firstName?: string | null;
  id: string;
  jobTitles?: Array<string | null> | null;
  lastName?: string | null;
  appointmentTypes: Array<AppointmentType>;
  availableInNearFuture: boolean;
  allowsBooking: boolean;
  avatarImage?: { __typename: 'Image'; url: string } | null;
  spokenLanguages?: Array<{
    __typename: 'Language';
    code?: string | null;
    name?: string | null;
  } | null> | null;
  info?: {
    __typename: 'CounsellorInfo';
    disability?: string | null;
    licenses?: Array<string | null> | null;
    ethnicBackground?: string | null;
    gender?: string | null;
    religion?: string | null;
    sexualOrientation?: string | null;
    specializations?: Array<string | null> | null;
  } | null;
};

export type FetchCounsellorByIdQueryVariables = Exact<{
  counsellorId: Scalars['ID']['input'];
}>;

export type FetchCounsellorByIdQuery = {
  __typename: 'Query';
  fetchCounsellorById?: {
    __typename: 'FetchCounsellorResponse';
    counsellor: {
      __typename: 'Counsellor';
      firstName?: string | null;
      id: string;
      jobTitles?: Array<string | null> | null;
      lastName?: string | null;
      appointmentTypes: Array<AppointmentType>;
      availableInNearFuture: boolean;
      allowsBooking: boolean;
      avatarImage?: { __typename: 'Image'; url: string } | null;
      spokenLanguages?: Array<{
        __typename: 'Language';
        code?: string | null;
        name?: string | null;
      } | null> | null;
      info?: {
        __typename: 'CounsellorInfo';
        disability?: string | null;
        licenses?: Array<string | null> | null;
        ethnicBackground?: string | null;
        gender?: string | null;
        religion?: string | null;
        sexualOrientation?: string | null;
        specializations?: Array<string | null> | null;
      } | null;
    };
  } | null;
};

export type FetchCounsellorForChatQueryVariables = Exact<{
  counsellorId: Scalars['ID']['input'];
}>;

export type FetchCounsellorForChatQuery = {
  __typename: 'Query';
  fetchCounsellorById?: {
    __typename: 'FetchCounsellorResponse';
    counsellor: {
      __typename: 'Counsellor';
      firstName?: string | null;
      lastName?: string | null;
    };
  } | null;
};

export type CounsellorSelectionLanguagesFragment = {
  __typename: 'Language';
  code?: string | null;
  name?: string | null;
};

export type CounsellorSelectionFragment = {
  __typename: 'Counsellor';
  firstName?: string | null;
  id: string;
  jobTitles?: Array<string | null> | null;
  lastName?: string | null;
  appointmentTypes: Array<AppointmentType>;
  availableInNearFuture: boolean;
  avatarImage?: { __typename: 'Image'; url: string } | null;
  spokenLanguages?: Array<{
    __typename: 'Language';
    code?: string | null;
    name?: string | null;
  } | null> | null;
};

export type FirstOfferedCounsellorFragment = {
  __typename: 'Counsellor';
  allowsBooking: boolean;
  firstName?: string | null;
  lastName?: string | null;
  id: string;
};

export type FirstOfferedTimeslotFragment = {
  __typename: 'AvailableTimeSlot';
  id: string;
  startDateTime: string;
  endDateTime: string;
  types: Array<AppointmentType>;
  counsellor: {
    __typename: 'Counsellor';
    allowsBooking: boolean;
    firstName?: string | null;
    lastName?: string | null;
    id: string;
  };
};

export type CounsellorSelectionPaginationFragment = {
  __typename: 'Pagination';
  isLastPage: boolean;
  cursors: { __typename: 'PaginationCursors'; after?: string | null };
};

export type AvailableFilterOptionsFragment = {
  __typename: 'CounsellorFilterOption';
  id: string;
  counsellorIds: Array<string>;
  title: string;
};

export type FetchCounsellorQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['ID']['input'];
  counsellorIds:
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>;
}>;

export type FetchCounsellorQuery = {
  __typename: 'Query';
  fetchCounsellors?: {
    __typename: 'FetchCounsellorsResponse';
    counsellors: {
      __typename: 'PaginatedCounsellors';
      data?: Array<{
        __typename: 'Counsellor';
        firstName?: string | null;
        id: string;
        jobTitles?: Array<string | null> | null;
        lastName?: string | null;
        appointmentTypes: Array<AppointmentType>;
        availableInNearFuture: boolean;
        avatarImage?: { __typename: 'Image'; url: string } | null;
        spokenLanguages?: Array<{
          __typename: 'Language';
          code?: string | null;
          name?: string | null;
        } | null> | null;
      }> | null;
      paging?: {
        __typename: 'Pagination';
        isLastPage: boolean;
        cursors: { __typename: 'PaginationCursors'; after?: string | null };
      } | null;
    };
  } | null;
};

export type FetchCounsellorFiltersQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;

export type FetchCounsellorFiltersQuery = {
  __typename: 'Query';
  fetchCounsellorFilters?: {
    __typename: 'FetchCounsellorFiltersResponse';
    filters: Array<{
      __typename: 'CounsellorFilter';
      id: string;
      title: string;
      options: Array<{
        __typename: 'CounsellorFilterOption';
        id: string;
        counsellorIds: Array<string>;
        title: string;
      }>;
    }>;
  } | null;
};

export type FetchFirstOfferedAppointmentQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;

export type FetchFirstOfferedAppointmentQuery = {
  __typename: 'Query';
  fetchFirstOfferedAvailableTimeSlot?: {
    __typename: 'FetchFirstOfferedAvailableTimeSlotResponse';
    availableTimeSlot: {
      __typename: 'AvailableTimeSlot';
      id: string;
      startDateTime: string;
      endDateTime: string;
      types: Array<AppointmentType>;
      counsellor: {
        __typename: 'Counsellor';
        allowsBooking: boolean;
        firstName?: string | null;
        lastName?: string | null;
        id: string;
      };
    };
  } | null;
};

export type StripeInitializationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type StripeInitializationQuery = {
  __typename: 'Query';
  stripeInitialization?: {
    __typename: 'StripeInitializationResponse';
    stripeInitialization: {
      __typename: 'StripeInitialization';
      publishableKey: string;
    };
  } | null;
  appointmentPaymentSetupIntent?: {
    __typename: 'AppointmentPaymentSetupIntentResponse';
    stripeIntent: {
      __typename: 'StripeSetupIntent';
      customerId: string;
      intentClientSecret: string;
      customerSessionClientSecret: string;
    };
  } | null;
};

export type SelectedCardQueryVariables = Exact<{ [key: string]: never }>;

export type SelectedCardQuery = {
  __typename: 'Query';
  selectedCard?: {
    __typename: 'SelectedCardResponse';
    selectedCard?: {
      __typename: 'SelectedCard';
      last4Digits: string;
      cardBrand: CardBrand;
      expirationMonth: number;
      expirationYear: number;
      isExpired: boolean;
    } | null;
  } | null;
};

export type TimeslotCounsellorFragment = {
  __typename: 'Counsellor';
  firstName?: string | null;
  id: string;
  jobTitles?: Array<string | null> | null;
  lastName?: string | null;
  avatarImage?: { __typename: 'Image'; url: string } | null;
};

export type TimeSlotFragment = {
  __typename: 'AvailableTimeSlot';
  endDateTime: string;
  id: string;
  startDateTime: string;
  types: Array<AppointmentType>;
};

export type CounsellorWithTimeslotsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CounsellorWithTimeslotsQuery = {
  __typename: 'Query';
  fetchCounsellor?: {
    __typename: 'FetchCounsellorResponse';
    counsellor: {
      __typename: 'Counsellor';
      firstName?: string | null;
      id: string;
      jobTitles?: Array<string | null> | null;
      lastName?: string | null;
      timeSlots?: Array<{
        __typename: 'AvailableTimeSlot';
        endDateTime: string;
        id: string;
        startDateTime: string;
        types: Array<AppointmentType>;
      }> | null;
      avatarImage?: { __typename: 'Image'; url: string } | null;
    };
  } | null;
};

export type CounsellorByIdWithTimeslotsQueryVariables = Exact<{
  counsellorId: Scalars['ID']['input'];
}>;

export type CounsellorByIdWithTimeslotsQuery = {
  __typename: 'Query';
  fetchCounsellorById?: {
    __typename: 'FetchCounsellorResponse';
    counsellor: {
      __typename: 'Counsellor';
      firstName?: string | null;
      id: string;
      jobTitles?: Array<string | null> | null;
      lastName?: string | null;
      timeSlots?: Array<{
        __typename: 'AvailableTimeSlot';
        endDateTime: string;
        id: string;
        startDateTime: string;
        types: Array<AppointmentType>;
      }> | null;
      avatarImage?: { __typename: 'Image'; url: string } | null;
    };
  } | null;
};

export type SubdivisionQueryVariables = Exact<{ [key: string]: never }>;

export type SubdivisionQuery = {
  __typename: 'Query';
  fetchUserSubdivisions?: {
    __typename: 'FetchUserSubdivisionsResponse';
    subdivisions: Array<{
      __typename: 'Subdivision';
      id: string;
      name: string;
    }>;
  } | null;
};

export type AppointmentsFragment = {
  __typename: 'Appointment';
  id: string;
  status: AppointmentStatus;
  startDateTime: string;
  endDateTime: string;
  typeDescription: AppointmentType;
  counsellor: {
    __typename: 'Counsellor';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    jobTitles?: Array<string | null> | null;
    allowsBooking: boolean;
    avatarImage?: { __typename: 'Image'; url: string } | null;
  };
};

export type AppointmentsPaginationFragment = {
  __typename: 'Pagination';
  isLastPage: boolean;
  cursors: { __typename: 'PaginationCursors'; after?: string | null };
};

export type FetchUpcomingAppointmentsQueryVariables = Exact<{
  input: FetchAppointmentsInput;
}>;

export type FetchUpcomingAppointmentsQuery = {
  __typename: 'Query';
  fetchAppointments?: {
    __typename: 'FetchAppointmentsResponse';
    appointments: {
      __typename: 'PaginatedAppointments';
      data: Array<{
        __typename: 'Appointment';
        id: string;
        status: AppointmentStatus;
        startDateTime: string;
        endDateTime: string;
        typeDescription: AppointmentType;
        counsellor: {
          __typename: 'Counsellor';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          jobTitles?: Array<string | null> | null;
          allowsBooking: boolean;
          avatarImage?: { __typename: 'Image'; url: string } | null;
        };
      }>;
    };
  } | null;
};

export type FetchPastAppointmentsQueryVariables = Exact<{
  input: FetchAppointmentsInput;
}>;

export type FetchPastAppointmentsQuery = {
  __typename: 'Query';
  fetchAppointments?: {
    __typename: 'FetchAppointmentsResponse';
    appointments: {
      __typename: 'PaginatedAppointments';
      data: Array<{
        __typename: 'Appointment';
        id: string;
        status: AppointmentStatus;
        startDateTime: string;
        endDateTime: string;
        typeDescription: AppointmentType;
        counsellor: {
          __typename: 'Counsellor';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          jobTitles?: Array<string | null> | null;
          allowsBooking: boolean;
          avatarImage?: { __typename: 'Image'; url: string } | null;
        };
      }>;
      paging: {
        __typename: 'Pagination';
        isLastPage: boolean;
        cursors: { __typename: 'PaginationCursors'; after?: string | null };
      };
    };
  } | null;
};

export type YourCounsellorFragment = {
  __typename: 'Counsellor';
  appointmentTypes: Array<AppointmentType>;
  firstName?: string | null;
  id: string;
  jobTitles?: Array<string | null> | null;
  lastName?: string | null;
  allowsBooking: boolean;
  displayHistory: boolean;
  avatarImage?: { __typename: 'Image'; url: string } | null;
};

export type FetchYourCounsellorQueryVariables = Exact<{ [key: string]: never }>;

export type FetchYourCounsellorQuery = {
  __typename: 'Query';
  fetchCounsellor?: {
    __typename: 'FetchCounsellorResponse';
    counsellor: {
      __typename: 'Counsellor';
      appointmentTypes: Array<AppointmentType>;
      firstName?: string | null;
      id: string;
      jobTitles?: Array<string | null> | null;
      lastName?: string | null;
      allowsBooking: boolean;
      displayHistory: boolean;
      avatarImage?: { __typename: 'Image'; url: string } | null;
    };
  } | null;
};

export type SelectCounsellorMutationVariables = Exact<{
  input: SelectCounsellorInput;
}>;

export type SelectCounsellorMutation = {
  __typename: 'Mutation';
  selectCounsellor?: {
    __typename: 'SelectCounsellorResponse';
    counsellor: {
      __typename: 'Counsellor';
      appointmentTypes: Array<AppointmentType>;
      firstName?: string | null;
      id: string;
      jobTitles?: Array<string | null> | null;
      lastName?: string | null;
      allowsBooking: boolean;
      displayHistory: boolean;
      avatarImage?: { __typename: 'Image'; url: string } | null;
    };
  } | null;
};

export type WellbeingHubAssessmentListItemFragment = {
  __typename: 'AssessmentListItem';
  uuid: string;
  status: AssessmentStatus;
  title: string;
  numberOfQuestions: number;
  percentageComplete: number;
  tierPoints?: number | null;
  icon?: { __typename: 'Image'; url: string } | null;
};

export type WellbeingHubAssessmentPromotionFragment = {
  __typename: 'AssessmentPromotion';
  title: string;
  assessment?: {
    __typename: 'AssessmentListItem';
    uuid: string;
    status: AssessmentStatus;
    title: string;
    numberOfQuestions: number;
    percentageComplete: number;
    tierPoints?: number | null;
    icon?: { __typename: 'Image'; url: string } | null;
  } | null;
};

export type WellbeingHubHraPromotionFragment = {
  __typename: 'HraPromotion';
  title: string;
  image?: { __typename: 'Image'; url: string } | null;
  icon?: { __typename: 'Image'; url: string } | null;
  assessment?: {
    __typename: 'FeaturedAssessment';
    completedAt?: string | null;
    status: AssessmentStatus;
    title: string;
    tierPoints?: number | null;
    numberOfAssessments?: number | null;
    assessmentsRemaining?: number | null;
    icon?: { __typename: 'Image'; url: string } | null;
    assessment: {
      __typename: 'AssessmentListItem';
      title: string;
      status: AssessmentStatus;
      numberOfQuestions: number;
      percentageComplete: number;
      uuid: string;
      result?: {
        __typename: 'AssessmentListItemResult';
        percentageScore: number;
      } | null;
    };
  } | null;
};

export type PreAssessmentResultHistoryFragment = {
  __typename: 'PreAssessmentResult';
  completedAt?: string | null;
  description: string;
  displayMaxScore: number;
  id: string;
  maxScore: number;
  score: number;
  sessionId: string;
  severities: Array<string>;
  title: string;
};

export type PreAssessmentResultsHistoryQueryVariables = Exact<{
  where?: InputMaybe<FilterPreAssessmentHistoryInput>;
}>;

export type PreAssessmentResultsHistoryQuery = {
  __typename: 'Query';
  preAssessmentResultsHistory?: {
    __typename: 'PreAssessmentResultsHistoryResponse';
    history?: Array<{
      __typename: 'PreAssessmentResult';
      completedAt?: string | null;
      description: string;
      displayMaxScore: number;
      id: string;
      maxScore: number;
      score: number;
      sessionId: string;
      severities: Array<string>;
      title: string;
    } | null> | null;
    pagination: {
      __typename: 'Pagination';
      isLastPage: boolean;
      cursors: { __typename: 'PaginationCursors'; after?: string | null };
    };
  } | null;
};

export type CareAssessmentChoiceQuestionOptionFragment = {
  __typename: 'CareAssessmentChoiceQuestionOption';
  id: string;
  title: string;
  nextQuestionId?: string | null;
};

export type CareAssessmentsChoiceQuestionFragment = {
  __typename: 'CareAssessmentChoiceQuestion';
  id: string;
  title: string;
  subtitle?: string | null;
  description?: string | null;
  options: Array<{
    __typename: 'CareAssessmentChoiceQuestionOption';
    id: string;
    title: string;
    nextQuestionId?: string | null;
  }>;
};

export type CareAssessmentsTextQuestionFragment = {
  __typename: 'CareAssessmentTextQuestion';
  id: string;
  title: string;
  subtitle?: string | null;
  description?: string | null;
  placeholder?: string | null;
  maxLength: number;
  nextQuestionId?: string | null;
};

export type CareAssessmentFragment = {
  __typename: 'CareAssessment';
  id: string;
  title: string;
  subtitle?: string | null;
  sessionId?: string | null;
  questions: Array<
    | {
        __typename: 'CareAssessmentChoiceQuestion';
        id: string;
        title: string;
        subtitle?: string | null;
        description?: string | null;
        options: Array<{
          __typename: 'CareAssessmentChoiceQuestionOption';
          id: string;
          title: string;
          nextQuestionId?: string | null;
        }>;
      }
    | {
        __typename: 'CareAssessmentTextQuestion';
        id: string;
        title: string;
        subtitle?: string | null;
        description?: string | null;
        placeholder?: string | null;
        maxLength: number;
        nextQuestionId?: string | null;
      }
  >;
};

export type PreAssessmentResultFragment = {
  __typename: 'PreAssessmentResult';
  title: string;
  severities: Array<string>;
  score: number;
  maxScore: number;
  displayMaxScore: number;
  description: string;
  completedAt?: string | null;
  sessionId: string;
  id: string;
  retakeData: {
    __typename: 'RetakeData';
    earlyRetakeWarningMessage?: string | null;
  };
};

export type PreAssessmentNotTakenFragment = {
  __typename: 'PreAssessmentNotTaken';
  id: string;
  sessionId: string;
  title: string;
};

export type PreAssessmentResultsFragment = {
  __typename: 'PreAssessmentResults';
  shouldSpeakToCounsellor: boolean;
  assessments: Array<
    | {
        __typename: 'PreAssessmentNotTaken';
        id: string;
        sessionId: string;
        title: string;
      }
    | {
        __typename: 'PreAssessmentResult';
        title: string;
        severities: Array<string>;
        score: number;
        maxScore: number;
        displayMaxScore: number;
        description: string;
        completedAt?: string | null;
        sessionId: string;
        id: string;
        retakeData: {
          __typename: 'RetakeData';
          earlyRetakeWarningMessage?: string | null;
        };
      }
  >;
};

export type RecommendationFragment = {
  __typename: 'PreAssessmentRecommendationSuicideRiskCallNow';
  id: string;
  ccsTeamTelephoneNumber: string;
};

export type PreAssessmentQueryVariables = Exact<{
  where?: InputMaybe<FilterPreAssessmentInput>;
}>;

export type PreAssessmentQuery = {
  __typename: 'Query';
  preAssessment?: {
    __typename: 'PreAssessmentResponse';
    nextAssessment?: {
      __typename: 'CareAssessment';
      id: string;
      title: string;
      subtitle?: string | null;
      sessionId?: string | null;
      questions: Array<
        | {
            __typename: 'CareAssessmentChoiceQuestion';
            id: string;
            title: string;
            subtitle?: string | null;
            description?: string | null;
            options: Array<{
              __typename: 'CareAssessmentChoiceQuestionOption';
              id: string;
              title: string;
              nextQuestionId?: string | null;
            }>;
          }
        | {
            __typename: 'CareAssessmentTextQuestion';
            id: string;
            title: string;
            subtitle?: string | null;
            description?: string | null;
            placeholder?: string | null;
            maxLength: number;
            nextQuestionId?: string | null;
          }
      >;
    } | null;
  } | null;
};

export type AnswerPreAssessmentMutationVariables = Exact<{
  request: AnswerPreAssessmentInput;
}>;

export type AnswerPreAssessmentMutation = {
  __typename: 'Mutation';
  answerPreAssessment?: {
    __typename: 'AnswerPreAssessmentResponse';
    nextAssessment?: {
      __typename: 'CareAssessment';
      id: string;
      title: string;
      subtitle?: string | null;
      sessionId?: string | null;
      questions: Array<
        | {
            __typename: 'CareAssessmentChoiceQuestion';
            id: string;
            title: string;
            subtitle?: string | null;
            description?: string | null;
            options: Array<{
              __typename: 'CareAssessmentChoiceQuestionOption';
              id: string;
              title: string;
              nextQuestionId?: string | null;
            }>;
          }
        | {
            __typename: 'CareAssessmentTextQuestion';
            id: string;
            title: string;
            subtitle?: string | null;
            description?: string | null;
            placeholder?: string | null;
            maxLength: number;
            nextQuestionId?: string | null;
          }
      >;
    } | null;
    recommendation?:
      | { __typename: 'PreAssessmentRecommendationBookAppointment' }
      | {
          __typename: 'PreAssessmentRecommendationSuicideRiskCallNow';
          id: string;
          ccsTeamTelephoneNumber: string;
        }
      | null;
    results?: {
      __typename: 'PreAssessmentResults';
      shouldSpeakToCounsellor: boolean;
      assessments: Array<
        | {
            __typename: 'PreAssessmentNotTaken';
            id: string;
            sessionId: string;
            title: string;
          }
        | {
            __typename: 'PreAssessmentResult';
            title: string;
            severities: Array<string>;
            score: number;
            maxScore: number;
            displayMaxScore: number;
            description: string;
            completedAt?: string | null;
            sessionId: string;
            id: string;
            retakeData: {
              __typename: 'RetakeData';
              earlyRetakeWarningMessage?: string | null;
            };
          }
      >;
    } | null;
  } | null;
};

export type RequestCallbackForAtRiskMemberMutationVariables = Exact<{
  input: RequestCallbackForAtRiskMemberInput;
}>;

export type RequestCallbackForAtRiskMemberMutation = {
  __typename: 'Mutation';
  requestCallbackForAtRiskMember?: {
    __typename: 'RequestCallbackForAtRiskMemberResponse';
    telephoneNumber?: string | null;
  } | null;
};

export type SelectedCareServiceOptionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SelectedCareServiceOptionQuery = {
  __typename: 'Query';
  selectedCareServiceOption?: {
    __typename: 'SelectedCareServiceOptionResponse';
    option: CareServiceOption;
  } | null;
};

export type CareIssueFragment = {
  __typename: 'CareIssue';
  id: string;
  title: string;
  subtitle?: string | null;
  analyticsId: string;
  default?: boolean | null;
};

export type CareIssuesQueryVariables = Exact<{ [key: string]: never }>;

export type CareIssuesQuery = {
  __typename: 'Query';
  getCareIssues?: {
    __typename: 'CareIssuesResponse';
    careIssues: Array<{
      __typename: 'CareIssue';
      id: string;
      title: string;
      subtitle?: string | null;
      analyticsId: string;
      default?: boolean | null;
    }>;
  } | null;
};

export type SetCareIssueMutationVariables = Exact<{
  request: SetCareIssueInput;
}>;

export type SetCareIssueMutation = {
  __typename: 'Mutation';
  setCareIssue?: {
    __typename: 'SetCareIssueResponse';
    careIssue: {
      __typename: 'CareIssue';
      id: string;
      title: string;
      subtitle?: string | null;
      analyticsId: string;
      default?: boolean | null;
    };
  } | null;
};

export type AskForImmediateHelpMutationVariables = Exact<{
  [key: string]: never;
}>;

export type AskForImmediateHelpMutation = {
  __typename: 'Mutation';
  askForImmediateHelp?: {
    __typename: 'AskForImmediateHelpResponse';
    ccsTeamTelephoneNumber: string;
  } | null;
};

export type SelectCareServiceOptionMutationVariables = Exact<{
  request: SelectCareServiceOptionInput;
}>;

export type SelectCareServiceOptionMutation = {
  __typename: 'Mutation';
  selectCareServiceOption?: {
    __typename: 'SelectCareServiceOptionResponse';
    option: CareServiceOption;
  } | null;
};

export type CarePlanActivityWithHippoFragment = {
  __typename: 'CarePlanActivityArticle';
  type?: ArticleType | null;
  body: string;
  copyright: string;
  audios: Array<{
    __typename: 'ArticleAudio';
    title?: string | null;
    url: string;
  }>;
  heroImage?: { __typename: 'Image'; url: string } | null;
  videos: Array<{
    __typename: 'ArticleVideo';
    hlsUrl?: string | null;
    url: string;
  }>;
  documents: Array<{
    __typename: 'ArticleDocument';
    format: string;
    size: string;
    title: string;
    url: string;
    path: string;
  }>;
};

export type ModuleWithoutActivitiesFragment = {
  __typename: 'CarePlanModule';
  id: string;
  name: string;
};

export type ActivityFragment = {
  __typename: 'CarePlanActivityArticle';
  type?: ArticleType | null;
  id: string;
  title: string;
  completed: boolean;
  module: { __typename: 'CarePlanModule'; id: string; name: string };
};

export type CarePlanActivityFragment = {
  __typename: 'CarePlanActivityArticle';
  type?: ArticleType | null;
  id: string;
  title: string;
  completed: boolean;
  body: string;
  copyright: string;
  nextActivity?: {
    __typename: 'CarePlanActivityArticle';
    type?: ArticleType | null;
    id: string;
    title: string;
    completed: boolean;
    module: { __typename: 'CarePlanModule'; id: string; name: string };
  } | null;
  previousActivity?: {
    __typename: 'CarePlanActivityArticle';
    type?: ArticleType | null;
    id: string;
    title: string;
    completed: boolean;
    module: { __typename: 'CarePlanModule'; id: string; name: string };
  } | null;
  module: { __typename: 'CarePlanModule'; id: string; name: string };
  audios: Array<{
    __typename: 'ArticleAudio';
    title?: string | null;
    url: string;
  }>;
  heroImage?: { __typename: 'Image'; url: string } | null;
  videos: Array<{
    __typename: 'ArticleVideo';
    hlsUrl?: string | null;
    url: string;
  }>;
  documents: Array<{
    __typename: 'ArticleDocument';
    format: string;
    size: string;
    title: string;
    url: string;
    path: string;
  }>;
};

export type CarePlanActivityQueryVariables = Exact<{
  where?: InputMaybe<CarePlanActivityInput>;
}>;

export type CarePlanActivityQuery = {
  __typename: 'Query';
  carePlanActivity?: {
    __typename: 'CarePlanActivityResponse';
    carePlanActivity?: {
      __typename: 'CarePlanActivityArticle';
      type?: ArticleType | null;
      id: string;
      title: string;
      completed: boolean;
      body: string;
      copyright: string;
      nextActivity?: {
        __typename: 'CarePlanActivityArticle';
        type?: ArticleType | null;
        id: string;
        title: string;
        completed: boolean;
        module: { __typename: 'CarePlanModule'; id: string; name: string };
      } | null;
      previousActivity?: {
        __typename: 'CarePlanActivityArticle';
        type?: ArticleType | null;
        id: string;
        title: string;
        completed: boolean;
        module: { __typename: 'CarePlanModule'; id: string; name: string };
      } | null;
      module: { __typename: 'CarePlanModule'; id: string; name: string };
      audios: Array<{
        __typename: 'ArticleAudio';
        title?: string | null;
        url: string;
      }>;
      heroImage?: { __typename: 'Image'; url: string } | null;
      videos: Array<{
        __typename: 'ArticleVideo';
        hlsUrl?: string | null;
        url: string;
      }>;
      documents: Array<{
        __typename: 'ArticleDocument';
        format: string;
        size: string;
        title: string;
        url: string;
        path: string;
      }>;
    } | null;
  } | null;
};

export type CompleteCarePlanActivityMutationVariables = Exact<{
  input?: InputMaybe<CompleteCarePlanActivityInput>;
}>;

export type CompleteCarePlanActivityMutation = {
  __typename: 'Mutation';
  completeCarePlanActivity?: {
    __typename: 'CompleteCarePlanActivityResponse';
    carePlanActivity?: {
      __typename: 'CarePlanActivityArticle';
      id: string;
      completed: boolean;
    } | null;
  } | null;
};

export type CarePlanModulesFragment = {
  __typename: 'CarePlanModule';
  id: string;
  name: string;
  activities: Array<{
    __typename: 'CarePlanActivityArticle';
    type?: ArticleType | null;
    id: string;
    title: string;
    completed: boolean;
    module: { __typename: 'CarePlanModule'; id: string; name: string };
  }>;
};

export type CarePlanSectionsFragment = {
  __typename: 'CarePlanSection';
  id: string;
  modules: Array<{
    __typename: 'CarePlanModule';
    id: string;
    name: string;
    activities: Array<{
      __typename: 'CarePlanActivityArticle';
      type?: ArticleType | null;
      id: string;
      title: string;
      completed: boolean;
      module: { __typename: 'CarePlanModule'; id: string; name: string };
    }>;
  }>;
};

export type CarePlanFragment = {
  __typename: 'CarePlan';
  id: string;
  name: string;
  description: string;
  sections: Array<{
    __typename: 'CarePlanSection';
    id: string;
    modules: Array<{
      __typename: 'CarePlanModule';
      id: string;
      name: string;
      activities: Array<{
        __typename: 'CarePlanActivityArticle';
        type?: ArticleType | null;
        id: string;
        title: string;
        completed: boolean;
        module: { __typename: 'CarePlanModule'; id: string; name: string };
      }>;
    }>;
  }>;
  currentActivity?: {
    __typename: 'CarePlanActivityArticle';
    type?: ArticleType | null;
    id: string;
    title: string;
    completed: boolean;
    module: { __typename: 'CarePlanModule'; id: string; name: string };
  } | null;
};

export type CarePlanQueryVariables = Exact<{ [key: string]: never }>;

export type CarePlanQuery = {
  __typename: 'Query';
  carePlan?: {
    __typename: 'CarePlanResponse';
    carePlan?: {
      __typename: 'CarePlan';
      id: string;
      name: string;
      description: string;
      sections: Array<{
        __typename: 'CarePlanSection';
        id: string;
        modules: Array<{
          __typename: 'CarePlanModule';
          id: string;
          name: string;
          activities: Array<{
            __typename: 'CarePlanActivityArticle';
            type?: ArticleType | null;
            id: string;
            title: string;
            completed: boolean;
            module: { __typename: 'CarePlanModule'; id: string; name: string };
          }>;
        }>;
      }>;
      currentActivity?: {
        __typename: 'CarePlanActivityArticle';
        type?: ArticleType | null;
        id: string;
        title: string;
        completed: boolean;
        module: { __typename: 'CarePlanModule'; id: string; name: string };
      } | null;
    } | null;
  } | null;
};

export type AssignCarePlanMutationVariables = Exact<{
  input: AssignCarePlanInput;
}>;

export type AssignCarePlanMutation = {
  __typename: 'Mutation';
  assignCarePlan?: {
    __typename: 'AssignCarePlanResponse';
    received: boolean;
  } | null;
};

export type ActivityQueryVariables = Exact<{
  challengeId: Scalars['ID']['input'];
  from?: InputMaybe<Scalars['GraphQLDate']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ActivityQuery = {
  __typename: 'Query';
  fetchChallenge: {
    __typename: 'fetchChallengeResponse';
    challenge?:
      | {
          __typename: 'HabitChallenge';
          challengeId: string;
          subtitle?: string | null;
          status: ChallengeStatus;
          goal: number;
          activity: {
            __typename: 'HabitActivityResponse';
            activity?: Array<{
              __typename: 'HabitDailyActivity';
              id: string;
              date: string;
              tracked: number;
              subtitle: string;
              tradeLimit: number;
              activity?: Array<{
                __typename: 'HabitActivity';
                habit?: {
                  __typename: 'Habit';
                  habitId: string;
                  tracked?: number | null;
                  title: string;
                  thumbnailImage?: {
                    __typename: 'Image';
                    url: string;
                    font?: {
                      __typename: 'Font';
                      color?: {
                        __typename: 'Color';
                        name?: string | null;
                        hexCode: string;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                optionActivity: Array<{
                  __typename: 'HabitOptionActivity';
                  tracked: number;
                  option?: {
                    __typename: 'HabitOption';
                    optionId: string;
                    title: string;
                  } | null;
                } | null>;
              }> | null;
            }> | null;
            pagination?: {
              __typename: 'Pagination';
              isLastPage: boolean;
              limit?: number | null;
              cursors: {
                __typename: 'PaginationCursors';
                after?: string | null;
                before?: string | null;
              };
            } | null;
          };
          challengeHabits?: {
            __typename: 'ChallengeHabits';
            habits: Array<{
              __typename: 'Habit';
              habitId: string;
              title: string;
              subtitle: string;
              description?: string | null;
              selected?: boolean | null;
              thumbnailImage?: { __typename: 'Image'; url: string } | null;
              options?: Array<{
                __typename: 'HabitOption';
                optionId: string;
                title: string;
              }> | null;
            }>;
          } | null;
        }
      | {
          __typename: 'StepChallenge';
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          activity: {
            __typename: 'StepActivityResponse';
            activity?: Array<{
              __typename: 'StepDailyActivity';
              date: string;
              tracked: number;
              type: StepActivityType;
              subtitle: string;
            }> | null;
            pagination?: {
              __typename: 'Pagination';
              isLastPage: boolean;
              limit?: number | null;
              cursors: {
                __typename: 'PaginationCursors';
                after?: string | null;
                before?: string | null;
              };
            } | null;
          };
        }
      | null;
  };
};

export type HabitDailyActivityInputFragment = {
  __typename: 'HabitDailyActivity';
  id: string;
  tracked: number;
  activity?: Array<{
    __typename: 'HabitActivity';
    habit?: {
      __typename: 'Habit';
      habitId: string;
      tracked?: number | null;
      title: string;
      subtitle: string;
      description?: string | null;
      selected?: boolean | null;
      tradeCaptions: {
        __typename: 'HabitTradeCaption';
        title: string;
        subtitle: string;
      };
      thumbnailImage?: {
        __typename: 'Image';
        url: string;
        font?: {
          __typename: 'Font';
          color?: {
            __typename: 'Color';
            name?: string | null;
            hexCode: string;
          } | null;
        } | null;
      } | null;
    } | null;
    optionActivity: Array<{
      __typename: 'HabitOptionActivity';
      tracked: number;
      option?: {
        __typename: 'HabitOption';
        optionId: string;
        title: string;
      } | null;
    } | null>;
  }> | null;
};

export type FetchActivityHabitInputQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  date: Scalars['GraphQLDate']['input'];
}>;

export type FetchActivityHabitInputQuery = {
  __typename: 'Query';
  fetchChallenge: {
    __typename: 'fetchChallengeResponse';
    challenge?:
      | {
          __typename: 'HabitChallenge';
          challengeId: string;
          activity: {
            __typename: 'HabitActivityResponse';
            activity?: Array<{
              __typename: 'HabitDailyActivity';
              id: string;
              tracked: number;
              activity?: Array<{
                __typename: 'HabitActivity';
                habit?: {
                  __typename: 'Habit';
                  habitId: string;
                  tracked?: number | null;
                  title: string;
                  subtitle: string;
                  description?: string | null;
                  selected?: boolean | null;
                  tradeCaptions: {
                    __typename: 'HabitTradeCaption';
                    title: string;
                    subtitle: string;
                  };
                  thumbnailImage?: {
                    __typename: 'Image';
                    url: string;
                    font?: {
                      __typename: 'Font';
                      color?: {
                        __typename: 'Color';
                        name?: string | null;
                        hexCode: string;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                optionActivity: Array<{
                  __typename: 'HabitOptionActivity';
                  tracked: number;
                  option?: {
                    __typename: 'HabitOption';
                    optionId: string;
                    title: string;
                  } | null;
                } | null>;
              }> | null;
            }> | null;
          };
          challengeHabits?: {
            __typename: 'ChallengeHabits';
            habits: Array<{
              __typename: 'Habit';
              habitId: string;
              title: string;
              subtitle: string;
              description?: string | null;
              selected?: boolean | null;
              thumbnailImage?: { __typename: 'Image'; url: string } | null;
              options?: Array<{
                __typename: 'HabitOption';
                optionId: string;
                title: string;
              }> | null;
            }>;
          } | null;
        }
      | { __typename: 'StepChallenge' }
      | null;
  };
};

export type UpdateActivityHabitInputMutationVariables = Exact<{
  id: Scalars['String']['input'];
  habitId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  options:
    | Array<UpdateHabitOptionActivityInput>
    | UpdateHabitOptionActivityInput;
}>;

export type UpdateActivityHabitInputMutation = {
  __typename: 'Mutation';
  updateHabitActivity?: {
    __typename: 'UpdateHabitActivityResponse';
    updatedHabitActivityTotals?: {
      __typename: 'UpdatedHabitActivityTotals';
      challengeTrackedActivityTotal: number;
      habitTrackedActivityTotal: number;
    } | null;
  } | null;
};

export type RetakeIndividualChallengeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RetakeIndividualChallengeMutation = {
  __typename: 'Mutation';
  retakeIndividualChallenge: {
    __typename: 'RetakeIndividualChallengeResponse';
    challenge?:
      | { __typename: 'HabitChallenge'; challengeId: string }
      | { __typename: 'StepChallenge' }
      | null;
  };
};

export type StepActivityFragment = {
  __typename: 'StepActivity';
  startedAt?: string | null;
  emoji?: string | null;
  type?: ExerciseActivityType | null;
  durationInMs?: number | null;
};

export type StepDailyActivityInputFragment = {
  __typename: 'StepDailyActivity';
  id: string;
  tracked: number;
  activity?: Array<{
    __typename: 'StepActivity';
    startedAt?: string | null;
    emoji?: string | null;
    type?: ExerciseActivityType | null;
    durationInMs?: number | null;
  }> | null;
};

export type ActivityStepsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  date: Scalars['GraphQLDate']['input'];
}>;

export type ActivityStepsQuery = {
  __typename: 'Query';
  fetchChallenge: {
    __typename: 'fetchChallengeResponse';
    challenge?:
      | { __typename: 'HabitChallenge' }
      | {
          __typename: 'StepChallenge';
          challengeId: string;
          stepActivity: {
            __typename: 'StepActivityResponse';
            activity?: Array<{
              __typename: 'StepDailyActivity';
              id: string;
              tracked: number;
              activity?: Array<{
                __typename: 'StepActivity';
                startedAt?: string | null;
                emoji?: string | null;
                type?: ExerciseActivityType | null;
                durationInMs?: number | null;
              }> | null;
            }> | null;
          };
        }
      | null;
  };
};

type ActivitySummaryData_AverageSteps_Fragment = {
  __typename: 'AverageSteps';
  total: number;
};

type ActivitySummaryData_ClimbedEiffelTower_Fragment = {
  __typename: 'ClimbedEiffelTower';
  total: number;
};

type ActivitySummaryData_CompletedMarathonPercentage_Fragment = {
  __typename: 'CompletedMarathonPercentage';
  total: number;
};

type ActivitySummaryData_HitGoal_Fragment = {
  __typename: 'HitGoal';
  total: number;
};

export type ActivitySummaryDataFragment =
  | ActivitySummaryData_AverageSteps_Fragment
  | ActivitySummaryData_ClimbedEiffelTower_Fragment
  | ActivitySummaryData_CompletedMarathonPercentage_Fragment
  | ActivitySummaryData_HitGoal_Fragment;

export type ChallengeResultFragment = {
  __typename: 'ChallengeResult';
  pointsAwarded?: number | null;
  tieringStatus: ChallengeResultTieringStatus;
  newTier?: { __typename: 'Tier'; name: string; rank: number } | null;
};

export type ActivityStepsResultQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  date: Scalars['GraphQLDate']['input'];
}>;

export type ActivityStepsResultQuery = {
  __typename: 'Query';
  fetchChallenge: {
    __typename: 'fetchChallengeResponse';
    challenge?:
      | { __typename: 'HabitChallenge' }
      | {
          __typename: 'StepChallenge';
          challengeId: string;
          result?: {
            __typename: 'ChallengeResult';
            pointsAwarded?: number | null;
            tieringStatus: ChallengeResultTieringStatus;
            newTier?: { __typename: 'Tier'; name: string; rank: number } | null;
          } | null;
          stepActivity: {
            __typename: 'StepActivityResponse';
            summary?: Array<
              | { __typename: 'AverageSteps'; total: number }
              | { __typename: 'ClimbedEiffelTower'; total: number }
              | { __typename: 'CompletedMarathonPercentage'; total: number }
              | { __typename: 'HitGoal'; total: number }
            > | null;
          };
        }
      | null;
  };
};

export type TeamUserPermissionsFragment = {
  __typename: 'TeamPermissions';
  canCreateATeam?: boolean | null;
  isTeamAdmin?: boolean | null;
  membershipPrompt: TeamMembershipPrompt;
};

export type ChallengeTeamFragment = {
  __typename: 'Team';
  id: string;
  name: string;
  memberCount?: number | null;
  image?: { __typename: 'Image'; url: string } | null;
  userPermissions?: {
    __typename: 'TeamPermissions';
    canCreateATeam?: boolean | null;
    isTeamAdmin?: boolean | null;
    membershipPrompt: TeamMembershipPrompt;
  } | null;
};

export type PaginationFragment = {
  __typename: 'Pagination';
  isLastPage: boolean;
  limit?: number | null;
  cursors: {
    __typename: 'PaginationCursors';
    after?: string | null;
    before?: string | null;
  };
};

export type HabitSelectionStateFragment = {
  __typename: 'Habit';
  habitId: string;
  selected?: boolean | null;
};

export type ChallengeIntroductionFragment = {
  __typename: 'ChallengeIntroduction';
  id: string;
  info: string;
  title: string;
};

export type StepChallengeDateInfoFragment = {
  __typename: 'StepChallenge';
  startDateTimeISO?: string | null;
  endDateTimeISO?: string | null;
  lastUploaded?: string | null;
};

export type FeaturedStepChallengeFieldFragment = {
  __typename: 'StepChallenge';
  participationType: ChallengeParticipationType;
  challengeId: string;
  category: ChallengeCategory;
  title: string;
  status: ChallengeStatus;
  goal: number;
  wasJoined: boolean;
  endDateTimeISO?: string | null;
  startDateTimeISO?: string | null;
  image?: { __typename: 'Image'; url: string } | null;
  completionReward?: { __typename: 'TierReward'; points: string } | null;
  stepActivity: {
    __typename: 'StepActivityResponse';
    activity?: Array<{
      __typename: 'StepDailyActivity';
      tracked: number;
    }> | null;
  };
};

export type FeaturedHabitChallengeFieldFragment = {
  __typename: 'HabitChallenge';
  participationType: ChallengeParticipationType;
  challengeId: string;
  category: ChallengeCategory;
  title: string;
  status: ChallengeStatus;
  goal: number;
  wasJoined: boolean;
  endDateTimeISO?: string | null;
  startDateTimeISO?: string | null;
  image?: { __typename: 'Image'; url: string } | null;
  completionReward?: { __typename: 'TierReward'; points: string } | null;
  activity: {
    __typename: 'HabitActivityResponse';
    activity?: Array<{
      __typename: 'HabitDailyActivity';
      tracked: number;
    }> | null;
  };
};

export type FeaturedChallengeFieldsFragment = {
  __typename: 'fetchFeaturedChallengesResponse';
  challenges?: Array<
    | {
        __typename: 'HabitChallenge';
        participationType: ChallengeParticipationType;
        challengeId: string;
        category: ChallengeCategory;
        title: string;
        status: ChallengeStatus;
        goal: number;
        wasJoined: boolean;
        endDateTimeISO?: string | null;
        startDateTimeISO?: string | null;
        image?: { __typename: 'Image'; url: string } | null;
        completionReward?: { __typename: 'TierReward'; points: string } | null;
        activity: {
          __typename: 'HabitActivityResponse';
          activity?: Array<{
            __typename: 'HabitDailyActivity';
            tracked: number;
          }> | null;
        };
      }
    | {
        __typename: 'StepChallenge';
        participationType: ChallengeParticipationType;
        challengeId: string;
        category: ChallengeCategory;
        title: string;
        status: ChallengeStatus;
        goal: number;
        wasJoined: boolean;
        endDateTimeISO?: string | null;
        startDateTimeISO?: string | null;
        image?: { __typename: 'Image'; url: string } | null;
        completionReward?: { __typename: 'TierReward'; points: string } | null;
        stepActivity: {
          __typename: 'StepActivityResponse';
          activity?: Array<{
            __typename: 'StepDailyActivity';
            tracked: number;
          }> | null;
        };
      }
  > | null;
};

export type StepChallengeListItemFragment = {
  __typename: 'StepChallenge';
  challengeId: string;
  status: ChallengeStatus;
  title: string;
  category: ChallengeCategory;
  categoryDisplayText: string;
  participationType: ChallengeParticipationType;
  wasJoined: boolean;
  goal: number;
  endDateTimeISO?: string | null;
  startDateTimeISO?: string | null;
  completionReward?: { __typename: 'TierReward'; points: string } | null;
  image?: { __typename: 'Image'; url: string } | null;
  stepActivity: {
    __typename: 'StepActivityResponse';
    activity?: Array<{
      __typename: 'StepDailyActivity';
      tracked: number;
    }> | null;
  };
  leaderboardIndividuals: {
    __typename: 'LeaderboardIndividualResponse';
    leaderboard?: Array<{
      __typename: 'IndividualsLeaderboardEntry';
      position?: number | null;
    }> | null;
  };
};

export type StepChallengeBasicInfoFragment = {
  __typename: 'StepChallenge';
  challengeId: string;
  subtitle?: string | null;
  status: ChallengeStatus;
  title: string;
  category: ChallengeCategory;
  categoryDisplayText: string;
  participationType: ChallengeParticipationType;
  wasJoined: boolean;
  goal: number;
  endDateTimeISO?: string | null;
  startDateTimeISO?: string | null;
  lastUploaded?: string | null;
  introduction?: Array<{
    __typename: 'ChallengeIntroduction';
    id: string;
    info: string;
    title: string;
  } | null> | null;
  completionReward?: { __typename: 'TierReward'; points: string } | null;
  image?: { __typename: 'Image'; url: string } | null;
  stepActivity: {
    __typename: 'StepActivityResponse';
    activity?: Array<{
      __typename: 'StepDailyActivity';
      tracked: number;
    }> | null;
  };
  leaderboardIndividuals: {
    __typename: 'LeaderboardIndividualResponse';
    leaderboard?: Array<{
      __typename: 'IndividualsLeaderboardEntry';
      position?: number | null;
    }> | null;
  };
};

export type HabitBasicInfoFragment = {
  __typename: 'Habit';
  habitId: string;
  title: string;
  subtitle: string;
  description?: string | null;
  selected?: boolean | null;
};

export type HabitChallengeDateInfoFragment = {
  __typename: 'HabitChallenge';
  startDateTimeISO?: string | null;
  endDateTimeISO?: string | null;
};

type ChallengePage_HabitChallenge_Fragment = {
  __typename: 'HabitChallenge';
  title: string;
  subtitle?: string | null;
  challengeId: string;
  status: ChallengeStatus;
  goal: number;
  participationType: ChallengeParticipationType;
  category: ChallengeCategory;
  wasJoined: boolean;
  startDateTimeISO?: string | null;
  endDateTimeISO?: string | null;
  image?: { __typename: 'Image'; url: string } | null;
  introduction?: Array<{
    __typename: 'ChallengeIntroduction';
    id: string;
    info: string;
    title: string;
  } | null> | null;
};

type ChallengePage_StepChallenge_Fragment = {
  __typename: 'StepChallenge';
  title: string;
  subtitle?: string | null;
  challengeId: string;
  status: ChallengeStatus;
  goal: number;
  participationType: ChallengeParticipationType;
  lastUploaded?: string | null;
  category: ChallengeCategory;
  wasJoined: boolean;
  startDateTimeISO?: string | null;
  endDateTimeISO?: string | null;
  image?: { __typename: 'Image'; url: string } | null;
  introduction?: Array<{
    __typename: 'ChallengeIntroduction';
    id: string;
    info: string;
    title: string;
  } | null> | null;
};

export type ChallengePageFragment =
  | ChallengePage_HabitChallenge_Fragment
  | ChallengePage_StepChallenge_Fragment;

export type ChallengePromotionFragment = {
  __typename: 'ChallengePromotion';
  challengeUnion?:
    | {
        __typename: 'HabitChallenge';
        participationType: ChallengeParticipationType;
        challengeId: string;
        category: ChallengeCategory;
        title: string;
        status: ChallengeStatus;
        goal: number;
        wasJoined: boolean;
        endDateTimeISO?: string | null;
        startDateTimeISO?: string | null;
        image?: { __typename: 'Image'; url: string } | null;
        completionReward?: { __typename: 'TierReward'; points: string } | null;
        activity: {
          __typename: 'HabitActivityResponse';
          activity?: Array<{
            __typename: 'HabitDailyActivity';
            tracked: number;
          }> | null;
        };
      }
    | {
        __typename: 'StepChallenge';
        participationType: ChallengeParticipationType;
        challengeId: string;
        category: ChallengeCategory;
        title: string;
        status: ChallengeStatus;
        goal: number;
        wasJoined: boolean;
        endDateTimeISO?: string | null;
        startDateTimeISO?: string | null;
        image?: { __typename: 'Image'; url: string } | null;
        completionReward?: { __typename: 'TierReward'; points: string } | null;
        stepActivity: {
          __typename: 'StepActivityResponse';
          activity?: Array<{
            __typename: 'StepDailyActivity';
            tracked: number;
          }> | null;
        };
      }
    | null;
};

export type FetchChallengesHabitChallengeFragment = {
  __typename: 'HabitChallenge';
  categoryDisplayText: string;
  participationType: ChallengeParticipationType;
  challengeId: string;
  category: ChallengeCategory;
  title: string;
  status: ChallengeStatus;
  goal: number;
  wasJoined: boolean;
  endDateTimeISO?: string | null;
  startDateTimeISO?: string | null;
  image?: { __typename: 'Image'; url: string } | null;
  completionReward?: { __typename: 'TierReward'; points: string } | null;
  activity: {
    __typename: 'HabitActivityResponse';
    activity?: Array<{
      __typename: 'HabitDailyActivity';
      tracked: number;
    }> | null;
  };
};

export type FetchChallengesStepChallengeFragment = {
  __typename: 'StepChallenge';
  categoryDisplayText: string;
  participationType: ChallengeParticipationType;
  challengeId: string;
  category: ChallengeCategory;
  title: string;
  status: ChallengeStatus;
  goal: number;
  wasJoined: boolean;
  endDateTimeISO?: string | null;
  startDateTimeISO?: string | null;
  image?: { __typename: 'Image'; url: string } | null;
  completionReward?: { __typename: 'TierReward'; points: string } | null;
  stepActivity: {
    __typename: 'StepActivityResponse';
    activity?: Array<{
      __typename: 'StepDailyActivity';
      tracked: number;
    }> | null;
  };
};

export type FetchChallengesFragment = {
  __typename: 'fetchChallengesResponse';
  challenges?: Array<
    | {
        __typename: 'HabitChallenge';
        categoryDisplayText: string;
        participationType: ChallengeParticipationType;
        challengeId: string;
        category: ChallengeCategory;
        title: string;
        status: ChallengeStatus;
        goal: number;
        wasJoined: boolean;
        endDateTimeISO?: string | null;
        startDateTimeISO?: string | null;
        image?: { __typename: 'Image'; url: string } | null;
        completionReward?: { __typename: 'TierReward'; points: string } | null;
        activity: {
          __typename: 'HabitActivityResponse';
          activity?: Array<{
            __typename: 'HabitDailyActivity';
            tracked: number;
          }> | null;
        };
      }
    | {
        __typename: 'StepChallenge';
        categoryDisplayText: string;
        participationType: ChallengeParticipationType;
        challengeId: string;
        category: ChallengeCategory;
        title: string;
        status: ChallengeStatus;
        goal: number;
        wasJoined: boolean;
        endDateTimeISO?: string | null;
        startDateTimeISO?: string | null;
        image?: { __typename: 'Image'; url: string } | null;
        completionReward?: { __typename: 'TierReward'; points: string } | null;
        stepActivity: {
          __typename: 'StepActivityResponse';
          activity?: Array<{
            __typename: 'StepDailyActivity';
            tracked: number;
          }> | null;
        };
      }
  > | null;
};

type ChallengeStatus_HabitChallenge_Fragment = {
  __typename: 'HabitChallenge';
  challengeId: string;
  status: ChallengeStatus;
  wasJoined: boolean;
};

type ChallengeStatus_StepChallenge_Fragment = {
  __typename: 'StepChallenge';
  challengeId: string;
  status: ChallengeStatus;
  wasJoined: boolean;
};

export type ChallengeStatusFragment =
  | ChallengeStatus_HabitChallenge_Fragment
  | ChallengeStatus_StepChallenge_Fragment;

export type HabitChallengeListItemFragment = {
  __typename: 'HabitChallenge';
  challengeId: string;
  status: ChallengeStatus;
  title: string;
  category: ChallengeCategory;
  categoryDisplayText: string;
  participationType: ChallengeParticipationType;
  wasJoined: boolean;
  goal: number;
  endDateTimeISO?: string | null;
  startDateTimeISO?: string | null;
  completionReward?: { __typename: 'TierReward'; points: string } | null;
  image?: { __typename: 'Image'; url: string } | null;
  activity: {
    __typename: 'HabitActivityResponse';
    activity?: Array<{
      __typename: 'HabitDailyActivity';
      tracked: number;
    }> | null;
  };
  leaderboardIndividuals: {
    __typename: 'LeaderboardIndividualResponse';
    leaderboard?: Array<{
      __typename: 'IndividualsLeaderboardEntry';
      position?: number | null;
    }> | null;
  };
};

export type HabitChallengeBasicInfoFragment = {
  __typename: 'HabitChallenge';
  challengeId: string;
  subtitle?: string | null;
  status: ChallengeStatus;
  title: string;
  category: ChallengeCategory;
  categoryDisplayText: string;
  participationType: ChallengeParticipationType;
  wasJoined: boolean;
  goal: number;
  endDateTimeISO?: string | null;
  startDateTimeISO?: string | null;
  introduction?: Array<{
    __typename: 'ChallengeIntroduction';
    id: string;
    info: string;
    title: string;
  } | null> | null;
  challengeHabits?: {
    __typename: 'ChallengeHabits';
    editSelectedHabits: {
      __typename: 'EditSelectedHabitsCaption';
      subtitle: string;
      title: string;
    };
    selectHabit: {
      __typename: 'SelectHabitCaption';
      description: string;
      title: string;
    };
    habits: Array<{
      __typename: 'Habit';
      habitId: string;
      title: string;
      subtitle: string;
      description?: string | null;
      selected?: boolean | null;
    }>;
  } | null;
  completionReward?: { __typename: 'TierReward'; points: string } | null;
  image?: { __typename: 'Image'; url: string } | null;
  activity: {
    __typename: 'HabitActivityResponse';
    activity?: Array<{
      __typename: 'HabitDailyActivity';
      tracked: number;
    }> | null;
  };
  leaderboardIndividuals: {
    __typename: 'LeaderboardIndividualResponse';
    leaderboard?: Array<{
      __typename: 'IndividualsLeaderboardEntry';
      position?: number | null;
    }> | null;
  };
};

export type HabitFragment = {
  __typename: 'Habit';
  habitId: string;
  tracked?: number | null;
  title: string;
  subtitle: string;
  description?: string | null;
  selected?: boolean | null;
  tradeCaptions: {
    __typename: 'HabitTradeCaption';
    title: string;
    subtitle: string;
  };
  thumbnailImage?: {
    __typename: 'Image';
    url: string;
    font?: {
      __typename: 'Font';
      color?: {
        __typename: 'Color';
        name?: string | null;
        hexCode: string;
      } | null;
    } | null;
  } | null;
};

export type OptionActivityFragment = {
  __typename: 'HabitOptionActivity';
  tracked: number;
  option?: {
    __typename: 'HabitOption';
    optionId: string;
    title: string;
  } | null;
};

export type HabitActivityFragment = {
  __typename: 'HabitActivity';
  habit?: {
    __typename: 'Habit';
    habitId: string;
    tracked?: number | null;
    title: string;
    subtitle: string;
    description?: string | null;
    selected?: boolean | null;
    tradeCaptions: {
      __typename: 'HabitTradeCaption';
      title: string;
      subtitle: string;
    };
    thumbnailImage?: {
      __typename: 'Image';
      url: string;
      font?: {
        __typename: 'Font';
        color?: {
          __typename: 'Color';
          name?: string | null;
          hexCode: string;
        } | null;
      } | null;
    } | null;
  } | null;
  optionActivity: Array<{
    __typename: 'HabitOptionActivity';
    tracked: number;
    option?: {
      __typename: 'HabitOption';
      optionId: string;
      title: string;
    } | null;
  } | null>;
};

export type FetchChallengePageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type FetchChallengePageQuery = {
  __typename: 'Query';
  fetchChallenge: {
    __typename: 'fetchChallengeResponse';
    challenge?:
      | {
          __typename: 'HabitChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          category: ChallengeCategory;
          wasJoined: boolean;
          startDateTimeISO?: string | null;
          endDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
        }
      | {
          __typename: 'StepChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          lastUploaded?: string | null;
          category: ChallengeCategory;
          wasJoined: boolean;
          startDateTimeISO?: string | null;
          endDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
        }
      | null;
  };
};

export type FetchChallengeQueryVariables = Exact<{
  from?: InputMaybe<Scalars['GraphQLDate']['input']>;
  id: Scalars['ID']['input'];
}>;

export type FetchChallengeQuery = {
  __typename: 'Query';
  fetchChallenge: {
    __typename: 'fetchChallengeResponse';
    challenge?:
      | {
          __typename: 'HabitChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          category: ChallengeCategory;
          wasJoined: boolean;
          startDateTimeISO?: string | null;
          endDateTimeISO?: string | null;
          categoryDisplayText: string;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          challengeHabits?: {
            __typename: 'ChallengeHabits';
            editSelectedHabits: {
              __typename: 'EditSelectedHabitsCaption';
              subtitle: string;
              title: string;
            };
            selectHabit: {
              __typename: 'SelectHabitCaption';
              description: string;
              title: string;
            };
            habits: Array<{
              __typename: 'Habit';
              habitId: string;
              title: string;
              subtitle: string;
              description?: string | null;
              selected?: boolean | null;
            }>;
          } | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          activity: {
            __typename: 'HabitActivityResponse';
            activity?: Array<{
              __typename: 'HabitDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
      | {
          __typename: 'StepChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          lastUploaded?: string | null;
          category: ChallengeCategory;
          wasJoined: boolean;
          startDateTimeISO?: string | null;
          endDateTimeISO?: string | null;
          categoryDisplayText: string;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          stepActivity: {
            __typename: 'StepActivityResponse';
            activity?: Array<{
              __typename: 'StepDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
      | null;
  };
};

export type StepDailyActivityFragment = {
  __typename: 'StepDailyActivity';
  date: string;
  tracked: number;
  type: StepActivityType;
  subtitle: string;
};

export type HabitDailyActivityFragment = {
  __typename: 'HabitDailyActivity';
  id: string;
  date: string;
  tracked: number;
  subtitle: string;
  tradeLimit: number;
  activity?: Array<{
    __typename: 'HabitActivity';
    habit?: {
      __typename: 'Habit';
      habitId: string;
      tracked?: number | null;
      title: string;
      thumbnailImage?: {
        __typename: 'Image';
        url: string;
        font?: {
          __typename: 'Font';
          color?: {
            __typename: 'Color';
            name?: string | null;
            hexCode: string;
          } | null;
        } | null;
      } | null;
    } | null;
    optionActivity: Array<{
      __typename: 'HabitOptionActivity';
      tracked: number;
      option?: {
        __typename: 'HabitOption';
        optionId: string;
        title: string;
      } | null;
    } | null>;
  }> | null;
};

export type JoinTeamMutationVariables = Exact<{
  challengeId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
}>;

export type JoinTeamMutation = {
  __typename: 'Mutation';
  joinChallengeTeam?: {
    __typename: 'JoinChallengeTeamResponse';
    joinedTeam: boolean;
    reasonNotJoined?: string | null;
    team?: {
      __typename: 'Team';
      id: string;
      name: string;
      userPermissions?: {
        __typename: 'TeamPermissions';
        canCreateATeam?: boolean | null;
        isTeamAdmin?: boolean | null;
        membershipPrompt: TeamMembershipPrompt;
      } | null;
    } | null;
  } | null;
};

export type LeaveTeamMutationVariables = Exact<{
  challengeId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
}>;

export type LeaveTeamMutation = {
  __typename: 'Mutation';
  leaveChallengeTeam?: {
    __typename: 'LeaveChallengeTeamResponse';
    departedTeam: boolean;
    reasonNotDeparted?: string | null;
    team?: {
      __typename: 'Team';
      id: string;
      name: string;
      userPermissions?: {
        __typename: 'TeamPermissions';
        canCreateATeam?: boolean | null;
        isTeamAdmin?: boolean | null;
        membershipPrompt: TeamMembershipPrompt;
      } | null;
    } | null;
  } | null;
};

export type ChallengeJoinedFragment = {
  __typename: 'HabitChallenge';
  challengeId: string;
  wasJoined: boolean;
};

export type JoinChallengeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type JoinChallengeMutation = {
  __typename: 'Mutation';
  joinChallenge: {
    __typename: 'JoinChallengeResponse';
    challenge?:
      | {
          __typename: 'HabitChallenge';
          challengeId: string;
          wasJoined: boolean;
        }
      | { __typename: 'StepChallenge' }
      | null;
  };
};

export type SelectHabitsMutationVariables = Exact<{
  challengeId: Scalars['String']['input'];
  habits: Array<SelectHabitsData> | SelectHabitsData;
}>;

export type SelectHabitsMutation = {
  __typename: 'Mutation';
  selectHabits?: {
    __typename: 'SelectedHabitsResponse';
    habits: Array<{
      __typename: 'Habit';
      habitId: string;
      selected?: boolean | null;
    }>;
  } | null;
};

export type HubListFragment = {
  __typename: 'fetchChallengesResponse';
  challenges?: Array<
    | {
        __typename: 'HabitChallenge';
        title: string;
        subtitle?: string | null;
        challengeId: string;
        status: ChallengeStatus;
        goal: number;
        participationType: ChallengeParticipationType;
        category: ChallengeCategory;
        wasJoined: boolean;
        categoryDisplayText: string;
        endDateTimeISO?: string | null;
        startDateTimeISO?: string | null;
        image?: { __typename: 'Image'; url: string } | null;
        introduction?: Array<{
          __typename: 'ChallengeIntroduction';
          id: string;
          info: string;
          title: string;
        } | null> | null;
        completionReward?: { __typename: 'TierReward'; points: string } | null;
        activity: {
          __typename: 'HabitActivityResponse';
          activity?: Array<{
            __typename: 'HabitDailyActivity';
            tracked: number;
          }> | null;
        };
        leaderboardIndividuals: {
          __typename: 'LeaderboardIndividualResponse';
          leaderboard?: Array<{
            __typename: 'IndividualsLeaderboardEntry';
            position?: number | null;
          }> | null;
        };
      }
    | {
        __typename: 'StepChallenge';
        title: string;
        subtitle?: string | null;
        challengeId: string;
        status: ChallengeStatus;
        goal: number;
        participationType: ChallengeParticipationType;
        lastUploaded?: string | null;
        category: ChallengeCategory;
        wasJoined: boolean;
        categoryDisplayText: string;
        endDateTimeISO?: string | null;
        startDateTimeISO?: string | null;
        image?: { __typename: 'Image'; url: string } | null;
        introduction?: Array<{
          __typename: 'ChallengeIntroduction';
          id: string;
          info: string;
          title: string;
        } | null> | null;
        completionReward?: { __typename: 'TierReward'; points: string } | null;
        stepActivity: {
          __typename: 'StepActivityResponse';
          activity?: Array<{
            __typename: 'StepDailyActivity';
            tracked: number;
          }> | null;
        };
        leaderboardIndividuals: {
          __typename: 'LeaderboardIndividualResponse';
          leaderboard?: Array<{
            __typename: 'IndividualsLeaderboardEntry';
            position?: number | null;
          }> | null;
        };
      }
  > | null;
};

export type ChallengesHubPageQueryVariables = Exact<{
  from?: InputMaybe<Scalars['GraphQLDate']['input']>;
}>;

export type ChallengesHubPageQuery = {
  __typename: 'Query';
  challenges?: {
    __typename: 'fetchChallengesResponse';
    challenges?: Array<
      | {
          __typename: 'HabitChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          category: ChallengeCategory;
          wasJoined: boolean;
          categoryDisplayText: string;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          activity: {
            __typename: 'HabitActivityResponse';
            activity?: Array<{
              __typename: 'HabitDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
      | {
          __typename: 'StepChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          lastUploaded?: string | null;
          category: ChallengeCategory;
          wasJoined: boolean;
          categoryDisplayText: string;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          stepActivity: {
            __typename: 'StepActivityResponse';
            activity?: Array<{
              __typename: 'StepDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
    > | null;
  } | null;
};

export type LeaderboardUserFragment = {
  __typename: 'User';
  id: string;
  fullName: string;
  image?: { __typename: 'Image'; url: string } | null;
};

export type LeaderboardIndividualFragment = {
  __typename: 'IndividualsLeaderboardEntry';
  score?: string | null;
  position?: number | null;
  user: {
    __typename: 'User';
    id: string;
    fullName: string;
    image?: { __typename: 'Image'; url: string } | null;
  };
};

export type LeaderboardIndividualQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type LeaderboardIndividualQuery = {
  __typename: 'Query';
  fetchChallenge: {
    __typename: 'fetchChallengeResponse';
    challenge?:
      | {
          __typename: 'HabitChallenge';
          challengeId: string;
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            pagination?: {
              __typename: 'Pagination';
              isLastPage: boolean;
              limit?: number | null;
              cursors: {
                __typename: 'PaginationCursors';
                after?: string | null;
                before?: string | null;
              };
            } | null;
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              score?: string | null;
              position?: number | null;
              user: {
                __typename: 'User';
                id: string;
                fullName: string;
                image?: { __typename: 'Image'; url: string } | null;
              };
            }> | null;
          };
        }
      | {
          __typename: 'StepChallenge';
          challengeId: string;
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            pagination?: {
              __typename: 'Pagination';
              isLastPage: boolean;
              limit?: number | null;
              cursors: {
                __typename: 'PaginationCursors';
                after?: string | null;
                before?: string | null;
              };
            } | null;
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              score?: string | null;
              position?: number | null;
              user: {
                __typename: 'User';
                id: string;
                fullName: string;
                image?: { __typename: 'Image'; url: string } | null;
              };
            }> | null;
          };
        }
      | null;
  };
};

export type TeamsLeaderboardListItemFragment = {
  __typename: 'TeamsLeaderboardEntry';
  position?: number | null;
  score?: string | null;
  team: {
    __typename: 'Team';
    id: string;
    name: string;
    memberCount?: number | null;
    image?: { __typename: 'Image'; url: string } | null;
    userPermissions?: {
      __typename: 'TeamPermissions';
      canCreateATeam?: boolean | null;
      isTeamAdmin?: boolean | null;
      membershipPrompt: TeamMembershipPrompt;
    } | null;
  };
};

export type LeaderboardTeamQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type LeaderboardTeamQuery = {
  __typename: 'Query';
  fetchChallenge: {
    __typename: 'fetchChallengeResponse';
    challenge?:
      | {
          __typename: 'HabitChallenge';
          challengeId: string;
          status: ChallengeStatus;
          leaderboardTeams: {
            __typename: 'LeaderboardTeamResponse';
            pagination?: {
              __typename: 'Pagination';
              isLastPage: boolean;
              limit?: number | null;
              cursors: {
                __typename: 'PaginationCursors';
                after?: string | null;
                before?: string | null;
              };
            } | null;
            leaderboard?: Array<{
              __typename: 'TeamsLeaderboardEntry';
              position?: number | null;
              score?: string | null;
              team: {
                __typename: 'Team';
                id: string;
                name: string;
                memberCount?: number | null;
                image?: { __typename: 'Image'; url: string } | null;
                userPermissions?: {
                  __typename: 'TeamPermissions';
                  canCreateATeam?: boolean | null;
                  isTeamAdmin?: boolean | null;
                  membershipPrompt: TeamMembershipPrompt;
                } | null;
              };
            }> | null;
          };
          userTeamMembership: {
            __typename: 'UserTeamMembershipResponse';
            canCreateATeam: boolean;
          };
        }
      | {
          __typename: 'StepChallenge';
          challengeId: string;
          status: ChallengeStatus;
          leaderboardTeams: {
            __typename: 'LeaderboardTeamResponse';
            pagination?: {
              __typename: 'Pagination';
              isLastPage: boolean;
              limit?: number | null;
              cursors: {
                __typename: 'PaginationCursors';
                after?: string | null;
                before?: string | null;
              };
            } | null;
            leaderboard?: Array<{
              __typename: 'TeamsLeaderboardEntry';
              position?: number | null;
              score?: string | null;
              team: {
                __typename: 'Team';
                id: string;
                name: string;
                memberCount?: number | null;
                image?: { __typename: 'Image'; url: string } | null;
                userPermissions?: {
                  __typename: 'TeamPermissions';
                  canCreateATeam?: boolean | null;
                  isTeamAdmin?: boolean | null;
                  membershipPrompt: TeamMembershipPrompt;
                } | null;
              };
            }> | null;
          };
          userTeamMembership: {
            __typename: 'UserTeamMembershipResponse';
            canCreateATeam: boolean;
          };
        }
      | null;
  };
};

export type LeaveChallengeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type LeaveChallengeMutation = {
  __typename: 'Mutation';
  leaveChallenge: {
    __typename: 'LeaveChallengeResponse';
    challenge?:
      | {
          __typename: 'HabitChallenge';
          challengeId: string;
          status: ChallengeStatus;
          wasJoined: boolean;
        }
      | {
          __typename: 'StepChallenge';
          challengeId: string;
          status: ChallengeStatus;
          wasJoined: boolean;
        }
      | null;
  };
};

export type TeamCreateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;

export type TeamCreateMutation = {
  __typename: 'Mutation';
  createChallengeTeam?: {
    __typename: 'CreateChallengeTeamResponse';
    team: { __typename: 'Team'; id: string };
  } | null;
};

export type TeamMemberFragment = {
  __typename: 'User';
  fullName: string;
  id: string;
  image?: { __typename: 'Image'; url: string } | null;
};

export type TeamLeaderboardFragment = {
  __typename: 'LeaderboardTeamMembersResponse';
  leaderboard?: Array<{
    __typename: 'IndividualsLeaderboardEntry';
    score?: string | null;
    position?: number | null;
    user: {
      __typename: 'User';
      fullName: string;
      id: string;
      image?: { __typename: 'Image'; url: string } | null;
    };
  }> | null;
  pagination?: {
    __typename: 'Pagination';
    isLastPage: boolean;
    limit?: number | null;
    cursors: {
      __typename: 'PaginationCursors';
      after?: string | null;
      before?: string | null;
    };
  } | null;
};

export type TeamMembersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  challengeId: Scalars['ID']['input'];
}>;

export type TeamMembersQuery = {
  __typename: 'Query';
  fetchChallengeTeam?: {
    __typename: 'FetchTeamResponse';
    team?: {
      __typename: 'Team';
      name: string;
      image?: { __typename: 'Image'; url: string } | null;
      userPermissions?: {
        __typename: 'TeamPermissions';
        membershipPrompt: TeamMembershipPrompt;
      } | null;
    } | null;
    leaderboardTeamMembers: {
      __typename: 'LeaderboardTeamMembersResponse';
      leaderboard?: Array<{
        __typename: 'IndividualsLeaderboardEntry';
        score?: string | null;
        position?: number | null;
        user: {
          __typename: 'User';
          fullName: string;
          id: string;
          image?: { __typename: 'Image'; url: string } | null;
        };
      }> | null;
      pagination?: {
        __typename: 'Pagination';
        isLastPage: boolean;
        limit?: number | null;
        cursors: {
          __typename: 'PaginationCursors';
          after?: string | null;
          before?: string | null;
        };
      } | null;
    };
  } | null;
};

export type ReportContentMutationVariables = Exact<{
  input: ReportInput;
}>;

export type ReportContentMutation = {
  __typename: 'Mutation';
  report?: {
    __typename: 'ContentReportResponse';
    kind: ReportTargetKind;
  } | null;
};

export type JournalingEntryFragment = {
  __typename: 'JournalEntry';
  body?: string | null;
  date: string;
  id: string;
  title: string;
};

export type JournalEntriesQueryVariables = Exact<{
  input: JournalEntriesInput;
}>;

export type JournalEntriesQuery = {
  __typename: 'Query';
  journalEntries?: {
    __typename: 'JournalEntriesResponse';
    entries: {
      __typename: 'PaginatedJournalEntries';
      paging: {
        __typename: 'Pagination';
        isLastPage: boolean;
        cursors: { __typename: 'PaginationCursors'; after?: string | null };
      };
      data: Array<{
        __typename: 'JournalEntry';
        body?: string | null;
        date: string;
        id: string;
        title: string;
      }>;
    };
  } | null;
};

export type DeleteJournalEntryMutationVariables = Exact<{
  input: DeleteJournalEntryInput;
}>;

export type DeleteJournalEntryMutation = {
  __typename: 'Mutation';
  deleteJournalEntry?: {
    __typename: 'DeleteJournalEntryResponse';
    received?: boolean | null;
  } | null;
};

export type EditJournalEntryMutationVariables = Exact<{
  input: EditJournalEntryInput;
}>;

export type EditJournalEntryMutation = {
  __typename: 'Mutation';
  editJournalEntry?: {
    __typename: 'EditJournalEntryResponse';
    entry: {
      __typename: 'JournalEntry';
      body?: string | null;
      date: string;
      id: string;
      title: string;
    };
  } | null;
};

export type CreateJournalEntryMutationVariables = Exact<{
  input: CreateJournalEntryInput;
}>;

export type CreateJournalEntryMutation = {
  __typename: 'Mutation';
  createJournalEntry?: {
    __typename: 'CreateJournalEntryResponse';
    entry: {
      __typename: 'JournalEntry';
      body?: string | null;
      date: string;
      id: string;
      title: string;
    };
  } | null;
};

export type AverageDailyMoodInsightsQueryVariables = Exact<{
  input?: InputMaybe<AverageDailyMoodInsightsInput>;
}>;

export type AverageDailyMoodInsightsQuery = {
  __typename: 'Query';
  averageDailyMoodInsights?: {
    __typename: 'AverageDailyMoodInsightsResponse';
    message: string;
    sampleSize: number;
    title: string;
    data: Array<{
      __typename: 'DailyMoodDataPoint';
      date: string;
      value?: number | null;
    }>;
  } | null;
};

export type InfluencesMoodInsightsQueryVariables = Exact<{
  input?: InputMaybe<InfluencesMoodInsightsInput>;
}>;

export type InfluencesMoodInsightsQuery = {
  __typename: 'Query';
  influencesMoodInsights?: {
    __typename: 'InfluencesMoodInsightsResponse';
    message: string;
    sampleSize: number;
    title: string;
    data: Array<{
      __typename: 'InfluencesMoodDataPoint';
      factor: string;
      valueMax: number;
      valueMin: number;
    }>;
  } | null;
};

export type MoodFactorFragment = {
  __typename: 'MoodFactor';
  id: string;
  title: string;
  isCustom: boolean;
};

export type MoodTrackingEntryFragment = {
  __typename: 'MoodEntry';
  createdAt: string;
  id: string;
  note?: string | null;
  value: MoodValue;
  factors: Array<{
    __typename: 'MoodFactor';
    id: string;
    title: string;
    isCustom: boolean;
  }>;
};

export type MoodEntriesQueryVariables = Exact<{
  input: MoodEntriesInput;
}>;

export type MoodEntriesQuery = {
  __typename: 'Query';
  moodEntries?: {
    __typename: 'MoodEntriesResponse';
    entries: {
      __typename: 'PaginatedMoodEntries';
      paging: {
        __typename: 'Pagination';
        isLastPage: boolean;
        cursors: { __typename: 'PaginationCursors'; after?: string | null };
      };
      data: Array<{
        __typename: 'MoodEntry';
        createdAt: string;
        id: string;
        note?: string | null;
        value: MoodValue;
        factors: Array<{
          __typename: 'MoodFactor';
          id: string;
          title: string;
          isCustom: boolean;
        }>;
      }>;
    };
  } | null;
};

export type DeleteMoodEntryMutationVariables = Exact<{
  input: DeleteMoodEntryInput;
}>;

export type DeleteMoodEntryMutation = {
  __typename: 'Mutation';
  deleteMoodEntry?: {
    __typename: 'DeleteMoodEntryResponse';
    received?: boolean | null;
  } | null;
};

export type EditMoodEntryMutationVariables = Exact<{
  input: EditMoodEntryInput;
}>;

export type EditMoodEntryMutation = {
  __typename: 'Mutation';
  editMoodEntry?: {
    __typename: 'EditMoodEntryResponse';
    entry: {
      __typename: 'MoodEntry';
      createdAt: string;
      id: string;
      note?: string | null;
      value: MoodValue;
      factors: Array<{
        __typename: 'MoodFactor';
        id: string;
        title: string;
        isCustom: boolean;
      }>;
    };
  } | null;
};

export type AvailableMoodFactorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AvailableMoodFactorsQuery = {
  __typename: 'Query';
  availableMoodFactors?: {
    __typename: 'AvailableMoodFactorsResponse';
    factors: Array<{
      __typename: 'MoodFactor';
      id: string;
      title: string;
      isCustom: boolean;
    }>;
  } | null;
};

export type CreateMoodEntryMutationVariables = Exact<{
  input: CreateMoodEntryInput;
}>;

export type CreateMoodEntryMutation = {
  __typename: 'Mutation';
  createMoodEntry?: {
    __typename: 'CreateMoodEntryResponse';
    entry: {
      __typename: 'MoodEntry';
      createdAt: string;
      id: string;
      note?: string | null;
      value: MoodValue;
      factors: Array<{
        __typename: 'MoodFactor';
        id: string;
        title: string;
        isCustom: boolean;
      }>;
    };
  } | null;
};

export type CreateCustomMoodFactorMutationVariables = Exact<{
  input: CreateCustomMoodFactorInput;
}>;

export type CreateCustomMoodFactorMutation = {
  __typename: 'Mutation';
  createCustomMoodFactor?: {
    __typename: 'CreateCustomMoodFactorResponse';
    moodFactor: {
      __typename: 'MoodFactor';
      id: string;
      title: string;
      isCustom: boolean;
    };
  } | null;
};

export type DeleteCustomMoodFactorsMutationVariables = Exact<{
  input: DeleteCustomMoodFactorsInput;
}>;

export type DeleteCustomMoodFactorsMutation = {
  __typename: 'Mutation';
  deleteCustomMoodFactors?: {
    __typename: 'DeleteCustomMoodFactorsResponse';
    received?: boolean | null;
  } | null;
};

export type NeedToRetakeAssessmentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type NeedToRetakeAssessmentsQuery = {
  __typename: 'Query';
  preAssessmentResults?: {
    __typename: 'PreAssessmentResultsResponse';
    isTimeToRetakeAssessments: boolean;
  } | null;
};

export type MyCareAppointmentFragment = {
  __typename: 'Appointment';
  id: string;
  startDateTime: string;
  endDateTime: string;
  typeDescription: AppointmentType;
  status: AppointmentStatus;
  isVisibleToUser: boolean;
};

export type MyCareUpcomingAppointmentsQueryVariables = Exact<{
  input: FetchAppointmentsInput;
}>;

export type MyCareUpcomingAppointmentsQuery = {
  __typename: 'Query';
  fetchAppointments?: {
    __typename: 'FetchAppointmentsResponse';
    appointments: {
      __typename: 'PaginatedAppointments';
      data: Array<{
        __typename: 'Appointment';
        id: string;
        startDateTime: string;
        endDateTime: string;
        typeDescription: AppointmentType;
        status: AppointmentStatus;
        isVisibleToUser: boolean;
      }>;
    };
  } | null;
};

export type PaymentRequirementsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentRequirementsQuery = {
  __typename: 'Query';
  paymentRequirements?: {
    __typename: 'PaymentRequirementsResponse';
    isPaymentRequired: boolean;
  } | null;
};

export type FetchPreAssessmentResultsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchPreAssessmentResultsQuery = {
  __typename: 'Query';
  preAssessmentResults?: {
    __typename: 'PreAssessmentResultsResponse';
    results: {
      __typename: 'PreAssessmentResults';
      shouldSpeakToCounsellor: boolean;
      assessments: Array<
        | {
            __typename: 'PreAssessmentNotTaken';
            id: string;
            sessionId: string;
            title: string;
          }
        | {
            __typename: 'PreAssessmentResult';
            title: string;
            severities: Array<string>;
            score: number;
            maxScore: number;
            displayMaxScore: number;
            description: string;
            completedAt?: string | null;
            sessionId: string;
            id: string;
            retakeData: {
              __typename: 'RetakeData';
              earlyRetakeWarningMessage?: string | null;
            };
          }
      >;
    };
  } | null;
};

export type CareIssueSelectedFragment = {
  __typename: 'CareIssue';
  selected?: boolean | null;
};

export type CareIssuesSelectedQueryVariables = Exact<{ [key: string]: never }>;

export type CareIssuesSelectedQuery = {
  __typename: 'Query';
  getCareIssues?: {
    __typename: 'CareIssuesResponse';
    careIssues: Array<{ __typename: 'CareIssue'; selected?: boolean | null }>;
  } | null;
};

export type CarePlansQueryVariables = Exact<{
  input?: InputMaybe<AvailableCarePlansInput>;
}>;

export type CarePlansQuery = {
  __typename: 'Query';
  availableCarePlans?: {
    __typename: 'AvailableCarePlansResponse';
    carePlans: {
      __typename: 'PaginatedCarePlans';
      data: Array<{
        __typename: 'CarePlan';
        id: string;
        name: string;
        description: string;
      }>;
    };
  } | null;
};

export type AssessedAsSuicideRiskQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AssessedAsSuicideRiskQuery = {
  __typename: 'Query';
  assessedAsSuicideRisk?: {
    __typename: 'AssessedAsSuicideRiskResponse';
    ccsTeamTelephoneNumber: string;
  } | null;
};

export type WorkLifeServicesFragment = {
  __typename: 'WorkLifeService';
  id: string;
  name: string;
  description: string;
  icon?: { __typename: 'Image'; url: string } | null;
};

export type WorkLifeServicesPaginationFragment = {
  __typename: 'Pagination';
  isLastPage: boolean;
  cursors: { __typename: 'PaginationCursors'; after?: string | null };
};

export type FetchWorkLifeServicesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type FetchWorkLifeServicesQuery = {
  __typename: 'Query';
  workLifeServices?: {
    __typename: 'WorkLifeServicesResponse';
    services: {
      __typename: 'PaginatedWorkLifeServices';
      data: Array<{
        __typename: 'WorkLifeService';
        id: string;
        name: string;
        description: string;
        icon?: { __typename: 'Image'; url: string } | null;
      }>;
      paging: {
        __typename: 'Pagination';
        isLastPage: boolean;
        cursors: { __typename: 'PaginationCursors'; after?: string | null };
      };
    };
  } | null;
};

export type PensionBenefitsTitleAndNotificationCountFragment = {
  __typename: 'PensionAndBenefitsSummary';
  title: string;
  notifications?: {
    __typename: 'PensionsAndBenefitsNotificationSummary';
    count: number;
  } | null;
  pension?: { __typename: 'PensionSummary'; title: string } | null;
  benefits?: { __typename: 'BenefitsSummary'; title: string } | null;
};

export type PensionBenefitsNotificationsFragment = {
  __typename: 'PensionAndBenefitsSummary';
  notifications?: {
    __typename: 'PensionsAndBenefitsNotificationSummary';
    count: number;
    items?: Array<{
      __typename: 'PensionsAndBenefitsNotification';
      title: string;
      details: string;
      expiryDate?: string | null;
      portalUrlId: string;
    }> | null;
  } | null;
};

export type PensionBenefitsPensionFragment = {
  __typename: 'PensionAndBenefitsSummary';
  pension?: {
    __typename: 'PensionSummary';
    title: string;
    portalUrlId: string;
    totals?: Array<{
      __typename: 'PensionAndBenefitsTotal';
      label: string;
      value: string;
    }> | null;
  } | null;
};

export type PensionBenefitsBenefitFragment = {
  __typename: 'PensionAndBenefitsSummary';
  benefits?: {
    __typename: 'BenefitsSummary';
    title: string;
    portalUrlId: string;
    totals?: Array<{
      __typename: 'PensionAndBenefitsTotal';
      label: string;
      value: string;
    }> | null;
    plans?: Array<{
      __typename: 'BenefitPlan';
      title: string;
      details?: string | null;
      choices?: string | null;
    }> | null;
  } | null;
};

export type PensionBenefitsTitleAndNotificationCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PensionBenefitsTitleAndNotificationCountQuery = {
  __typename: 'Query';
  pensionAndBenefitsSummary?: {
    __typename: 'PensionAndBenefitsSummary';
    title: string;
    notifications?: {
      __typename: 'PensionsAndBenefitsNotificationSummary';
      count: number;
    } | null;
    pension?: { __typename: 'PensionSummary'; title: string } | null;
    benefits?: { __typename: 'BenefitsSummary'; title: string } | null;
  } | null;
};

export type PensionBenefitsSummaryPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PensionBenefitsSummaryPageQuery = {
  __typename: 'Query';
  pensionAndBenefitsSummary?: {
    __typename: 'PensionAndBenefitsSummary';
    title: string;
    notifications?: {
      __typename: 'PensionsAndBenefitsNotificationSummary';
      count: number;
      items?: Array<{
        __typename: 'PensionsAndBenefitsNotification';
        title: string;
        details: string;
        expiryDate?: string | null;
        portalUrlId: string;
      }> | null;
    } | null;
    pension?: {
      __typename: 'PensionSummary';
      title: string;
      portalUrlId: string;
      totals?: Array<{
        __typename: 'PensionAndBenefitsTotal';
        label: string;
        value: string;
      }> | null;
    } | null;
    benefits?: {
      __typename: 'BenefitsSummary';
      title: string;
      portalUrlId: string;
      totals?: Array<{
        __typename: 'PensionAndBenefitsTotal';
        label: string;
        value: string;
      }> | null;
      plans?: Array<{
        __typename: 'BenefitPlan';
        title: string;
        details?: string | null;
        choices?: string | null;
      }> | null;
    } | null;
  } | null;
};

export type PensionAndBenefitsPortalUrlQueryVariables = Exact<{
  portalUrlId: Scalars['String']['input'];
}>;

export type PensionAndBenefitsPortalUrlQuery = {
  __typename: 'Query';
  pensionAndBenefitsPortalUrl?: {
    __typename: 'PensionAndBenefitsTemporaryUrl';
    url: string;
    expiry: string;
  } | null;
};

export type CategoryModalContainerQueryVariables = Exact<{
  categoryId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CategoryModalContainerQuery = {
  __typename: 'Query';
  digitalClinicalPrograms?: Array<{
    __typename: 'DigitalClinicalProgramListItem';
    description: string;
    wasJoined: boolean;
    programId: string;
    title: string;
    status: DigitalClinicalProgramStatus;
    icon?: { __typename: 'Image'; url: string } | null;
    progress: {
      __typename: 'DigitalClinicalProgramProgress';
      percentageComplete: number;
    };
    nextModule?: {
      __typename: 'DigitalClinicalModuleListItem';
      moduleId: string;
      title: string;
    } | null;
  }> | null;
};

export type DigitalClinicalProgramCategoriesFragment = {
  __typename: 'DigitalClinicalProgramCategory';
  description?: string | null;
  id: string;
  name: string;
  icon?: { __typename: 'Image'; url: string } | null;
  iconBackground?: { __typename: 'Image'; url: string } | null;
};

export type AllProgramsQueryVariables = Exact<{ [key: string]: never }>;

export type AllProgramsQuery = {
  __typename: 'Query';
  digitalClinicalPrograms?: Array<{
    __typename: 'DigitalClinicalProgramListItem';
    description: string;
    wasJoined: boolean;
    programId: string;
    title: string;
    status: DigitalClinicalProgramStatus;
    icon?: { __typename: 'Image'; url: string } | null;
    progress: {
      __typename: 'DigitalClinicalProgramProgress';
      percentageComplete: number;
    };
    nextModule?: {
      __typename: 'DigitalClinicalModuleListItem';
      moduleId: string;
      title: string;
    } | null;
  }> | null;
  programsWalkthrough: {
    __typename: 'Walkthrough';
    wasCompleted: boolean;
    steps: Array<{
      __typename: 'WalkthroughStep';
      title: string;
      text: string;
      image?: { __typename: 'Image'; url: string } | null;
    }>;
  };
  digitalClinicalProgramCategories?: Array<{
    __typename: 'DigitalClinicalProgramCategory';
    description?: string | null;
    id: string;
    name: string;
    icon?: { __typename: 'Image'; url: string } | null;
    iconBackground?: { __typename: 'Image'; url: string } | null;
  } | null> | null;
};

export type WalkthroughCacheQueryVariables = Exact<{ [key: string]: never }>;

export type WalkthroughCacheQuery = {
  __typename: 'Query';
  programsWalkthrough: {
    __typename: 'Walkthrough';
    wasCompleted: boolean;
    steps: Array<{
      __typename: 'WalkthroughStep';
      title: string;
      text: string;
      image?: { __typename: 'Image'; url: string } | null;
    }>;
  };
};

export type CompleteWalkthroughMutationVariables = Exact<{
  walkthroughType: WalkthroughTypeInput;
}>;

export type CompleteWalkthroughMutation = {
  __typename: 'Mutation';
  completeWalkthrough?: boolean | null;
};

export type ModuleStatusFieldsFragment = {
  __typename: 'DigitalClinicalModule';
  moduleId: string;
  status: DigitalClinicalModuleStatus;
  progress: {
    __typename: 'DigitalClinicalModuleProgress';
    totalContentItems: number;
    completedContentItems: number;
    remainingContentItems: number;
    percentageComplete: number;
  };
};

export type DigitalClinicalModuleArticleFieldsFragment = {
  __typename: 'Article';
  contentItemId: string;
  body: string;
  title: string;
  author?: {
    __typename: 'ArticleAuthor';
    name: string;
    surname: string;
    jobTitle?: string | null;
    image?: { __typename: 'Image'; url: string } | null;
  } | null;
  heroImage?: { __typename: 'Image'; url: string } | null;
  audios?: Array<{
    __typename: 'ArticleAudio';
    title?: string | null;
    url: string;
  }> | null;
  videos?: Array<{
    __typename: 'ArticleVideo';
    title?: string | null;
    url: string;
    hlsUrl?: string | null;
  }> | null;
  userInteraction?: {
    __typename: 'ArticleInteraction';
    seenByUser: boolean;
  } | null;
};

export type DigitalClinicalModuleChoiceQuestionFieldsFragment = {
  __typename: 'ChoiceQuestion';
  contentItemId: string;
  title: string;
  description?: string | null;
  responseType: ChoiceQuestionResponseType;
  options: Array<{
    __typename: 'ChoiceQuestionOption';
    label: string;
    optionId: string;
  }>;
  userInteraction?: {
    __typename: 'ChoiceQuestionInteraction';
    seenByUser: boolean;
    choices?: Array<string | null> | null;
  } | null;
};

export type DigitalClinicalModuleFreeTextQuestionFieldsFragment = {
  __typename: 'FreeTextQuestion';
  contentItemId: string;
  title: string;
  description?: string | null;
  hint?: string | null;
  userInteraction?: {
    __typename: 'FreeTextQuestionInteraction';
    seenByUser: boolean;
    text?: string | null;
  } | null;
};

export type DigitalClinicalModuleScaleQuestionFieldsFragment = {
  __typename: 'ScaleQuestion';
  contentItemId: string;
  title: string;
  description?: string | null;
  scale: Array<{
    __typename: 'ScalePoint';
    label?: string | null;
    scalePointId: string;
    isDefault: boolean;
  }>;
  userInteraction?: {
    __typename: 'ScaleQuestionInteraction';
    seenByUser: boolean;
    scalePointId?: string | null;
  } | null;
};

export type DigitalClinicalModuleStarRatingQuestionFieldsFragment = {
  __typename: 'StarRatingQuestion';
  contentItemId: string;
  title: string;
  description?: string | null;
  starRatings: Array<{
    __typename: 'StarRating';
    label?: string | null;
    starRatingId: string;
    isDefault: boolean;
  }>;
  userInteraction?: {
    __typename: 'StarRatingQuestionInteraction';
    seenByUser: boolean;
    starRatingId?: string | null;
  } | null;
};

export type DigitalClinicalModuleDetailsFieldsFragment = {
  __typename: 'DigitalClinicalModule';
  moduleId: string;
  title: string;
  introduction: string;
  status: DigitalClinicalModuleStatus;
  heroImage?: { __typename: 'Image'; url: string } | null;
  timeEstimate?: { __typename: 'TimeSpan'; minutes?: number | null } | null;
  progress: {
    __typename: 'DigitalClinicalModuleProgress';
    totalContentItems: number;
    completedContentItems: number;
    remainingContentItems: number;
    percentageComplete: number;
  };
};

export type DigitalClinicalModuleSectionsFieldsFragment = {
  __typename: 'DigitalClinicalModule';
  sections?: Array<{
    __typename: 'DigitalClinicalModuleSection';
    title: string;
    contentItems?: Array<
      | {
          __typename: 'Article';
          contentItemId: string;
          body: string;
          title: string;
          author?: {
            __typename: 'ArticleAuthor';
            name: string;
            surname: string;
            jobTitle?: string | null;
            image?: { __typename: 'Image'; url: string } | null;
          } | null;
          heroImage?: { __typename: 'Image'; url: string } | null;
          audios?: Array<{
            __typename: 'ArticleAudio';
            title?: string | null;
            url: string;
          }> | null;
          videos?: Array<{
            __typename: 'ArticleVideo';
            title?: string | null;
            url: string;
            hlsUrl?: string | null;
          }> | null;
          userInteraction?: {
            __typename: 'ArticleInteraction';
            seenByUser: boolean;
          } | null;
        }
      | {
          __typename: 'ChoiceQuestion';
          contentItemId: string;
          title: string;
          description?: string | null;
          responseType: ChoiceQuestionResponseType;
          options: Array<{
            __typename: 'ChoiceQuestionOption';
            label: string;
            optionId: string;
          }>;
          userInteraction?: {
            __typename: 'ChoiceQuestionInteraction';
            seenByUser: boolean;
            choices?: Array<string | null> | null;
          } | null;
        }
      | {
          __typename: 'FreeTextQuestion';
          contentItemId: string;
          title: string;
          description?: string | null;
          hint?: string | null;
          userInteraction?: {
            __typename: 'FreeTextQuestionInteraction';
            seenByUser: boolean;
            text?: string | null;
          } | null;
        }
      | {
          __typename: 'ScaleQuestion';
          contentItemId: string;
          title: string;
          description?: string | null;
          scale: Array<{
            __typename: 'ScalePoint';
            label?: string | null;
            scalePointId: string;
            isDefault: boolean;
          }>;
          userInteraction?: {
            __typename: 'ScaleQuestionInteraction';
            seenByUser: boolean;
            scalePointId?: string | null;
          } | null;
        }
      | {
          __typename: 'StarRatingQuestion';
          contentItemId: string;
          title: string;
          description?: string | null;
          starRatings: Array<{
            __typename: 'StarRating';
            label?: string | null;
            starRatingId: string;
            isDefault: boolean;
          }>;
          userInteraction?: {
            __typename: 'StarRatingQuestionInteraction';
            seenByUser: boolean;
            starRatingId?: string | null;
          } | null;
        }
    > | null;
  }> | null;
};

export type DigitalClinicalModuleQueryVariables = Exact<{
  where: FindDigitalClinicalModuleInput;
}>;

export type DigitalClinicalModuleQuery = {
  __typename: 'Query';
  digitalClinicalModule?: {
    __typename: 'DigitalClinicalModule';
    moduleId: string;
    title: string;
    introduction: string;
    status: DigitalClinicalModuleStatus;
    heroImage?: { __typename: 'Image'; url: string } | null;
    timeEstimate?: { __typename: 'TimeSpan'; minutes?: number | null } | null;
    sections?: Array<{
      __typename: 'DigitalClinicalModuleSection';
      title: string;
      contentItems?: Array<
        | {
            __typename: 'Article';
            contentItemId: string;
            body: string;
            title: string;
            author?: {
              __typename: 'ArticleAuthor';
              name: string;
              surname: string;
              jobTitle?: string | null;
              image?: { __typename: 'Image'; url: string } | null;
            } | null;
            heroImage?: { __typename: 'Image'; url: string } | null;
            audios?: Array<{
              __typename: 'ArticleAudio';
              title?: string | null;
              url: string;
            }> | null;
            videos?: Array<{
              __typename: 'ArticleVideo';
              title?: string | null;
              url: string;
              hlsUrl?: string | null;
            }> | null;
            userInteraction?: {
              __typename: 'ArticleInteraction';
              seenByUser: boolean;
            } | null;
          }
        | {
            __typename: 'ChoiceQuestion';
            contentItemId: string;
            title: string;
            description?: string | null;
            responseType: ChoiceQuestionResponseType;
            options: Array<{
              __typename: 'ChoiceQuestionOption';
              label: string;
              optionId: string;
            }>;
            userInteraction?: {
              __typename: 'ChoiceQuestionInteraction';
              seenByUser: boolean;
              choices?: Array<string | null> | null;
            } | null;
          }
        | {
            __typename: 'FreeTextQuestion';
            contentItemId: string;
            title: string;
            description?: string | null;
            hint?: string | null;
            userInteraction?: {
              __typename: 'FreeTextQuestionInteraction';
              seenByUser: boolean;
              text?: string | null;
            } | null;
          }
        | {
            __typename: 'ScaleQuestion';
            contentItemId: string;
            title: string;
            description?: string | null;
            scale: Array<{
              __typename: 'ScalePoint';
              label?: string | null;
              scalePointId: string;
              isDefault: boolean;
            }>;
            userInteraction?: {
              __typename: 'ScaleQuestionInteraction';
              seenByUser: boolean;
              scalePointId?: string | null;
            } | null;
          }
        | {
            __typename: 'StarRatingQuestion';
            contentItemId: string;
            title: string;
            description?: string | null;
            starRatings: Array<{
              __typename: 'StarRating';
              label?: string | null;
              starRatingId: string;
              isDefault: boolean;
            }>;
            userInteraction?: {
              __typename: 'StarRatingQuestionInteraction';
              seenByUser: boolean;
              starRatingId?: string | null;
            } | null;
          }
      > | null;
    }> | null;
    progress: {
      __typename: 'DigitalClinicalModuleProgress';
      totalContentItems: number;
      completedContentItems: number;
      remainingContentItems: number;
      percentageComplete: number;
    };
  } | null;
};

export type ModuleOverviewFieldsFragment = {
  __typename: 'DigitalClinicalModule';
  moduleId: string;
  title: string;
  introduction: string;
  status: DigitalClinicalModuleStatus;
  progress: {
    __typename: 'DigitalClinicalModuleProgress';
    totalContentItems: number;
    completedContentItems: number;
    remainingContentItems: number;
    percentageComplete: number;
  };
  timeEstimate?: { __typename: 'TimeSpan'; minutes?: number | null } | null;
  heroImage?: { __typename: 'Image'; url: string } | null;
  backgroundImage?: {
    __typename: 'Image';
    url: string;
    font?: {
      __typename: 'Font';
      color?: { __typename: 'Color'; hexCode: string } | null;
    } | null;
  } | null;
  sections?: Array<{
    __typename: 'DigitalClinicalModuleSection';
    description?: string | null;
    title: string;
  }> | null;
};

export type ModuleOverviewQueryVariables = Exact<{
  where: FindDigitalClinicalModuleInput;
}>;

export type ModuleOverviewQuery = {
  __typename: 'Query';
  digitalClinicalModule?: {
    __typename: 'DigitalClinicalModule';
    moduleId: string;
    title: string;
    introduction: string;
    status: DigitalClinicalModuleStatus;
    progress: {
      __typename: 'DigitalClinicalModuleProgress';
      totalContentItems: number;
      completedContentItems: number;
      remainingContentItems: number;
      percentageComplete: number;
    };
    timeEstimate?: { __typename: 'TimeSpan'; minutes?: number | null } | null;
    heroImage?: { __typename: 'Image'; url: string } | null;
    backgroundImage?: {
      __typename: 'Image';
      url: string;
      font?: {
        __typename: 'Font';
        color?: { __typename: 'Color'; hexCode: string } | null;
      } | null;
    } | null;
    sections?: Array<{
      __typename: 'DigitalClinicalModuleSection';
      description?: string | null;
      title: string;
    }> | null;
  } | null;
};

export type ProgramStatusFieldsFragment = {
  __typename: 'DigitalClinicalProgram';
  programId: string;
  status: DigitalClinicalProgramStatus;
  progress: {
    __typename: 'DigitalClinicalProgramProgress';
    totalModules: number;
    completedModules: number;
    percentageComplete: number;
  };
};

export type ModuleListItemStatusFieldsFragment = {
  __typename: 'DigitalClinicalModuleListItem';
  moduleId: string;
  status: DigitalClinicalModuleStatus;
  progress: {
    __typename: 'DigitalClinicalModuleProgress';
    totalContentItems: number;
    completedContentItems: number;
    remainingContentItems: number;
    percentageComplete: number;
  };
};

export type SingleProgramFieldsFragment = {
  __typename: 'DigitalClinicalProgram';
  programId: string;
  title: string;
  status: DigitalClinicalProgramStatus;
  modules?: Array<{
    __typename: 'DigitalClinicalModuleListItem';
    moduleId: string;
    title: string;
    introduction: string;
    status: DigitalClinicalModuleStatus;
    heroImage?: { __typename: 'Image'; url: string } | null;
    backgroundImage?: {
      __typename: 'Image';
      url: string;
      font?: {
        __typename: 'Font';
        color?: { __typename: 'Color'; hexCode: string } | null;
      } | null;
    } | null;
    timeEstimate?: { __typename: 'TimeSpan'; minutes?: number | null } | null;
    progress: {
      __typename: 'DigitalClinicalModuleProgress';
      totalContentItems: number;
      completedContentItems: number;
      remainingContentItems: number;
      percentageComplete: number;
    };
  }> | null;
  progress: {
    __typename: 'DigitalClinicalProgramProgress';
    totalModules: number;
    completedModules: number;
    percentageComplete: number;
  };
};

export type SingleProgramQueryVariables = Exact<{
  where: FindDigitalClinicalProgramInput;
}>;

export type SingleProgramQuery = {
  __typename: 'Query';
  digitalClinicalProgram?: {
    __typename: 'DigitalClinicalProgram';
    programId: string;
    title: string;
    status: DigitalClinicalProgramStatus;
    modules?: Array<{
      __typename: 'DigitalClinicalModuleListItem';
      moduleId: string;
      title: string;
      introduction: string;
      status: DigitalClinicalModuleStatus;
      heroImage?: { __typename: 'Image'; url: string } | null;
      backgroundImage?: {
        __typename: 'Image';
        url: string;
        font?: {
          __typename: 'Font';
          color?: { __typename: 'Color'; hexCode: string } | null;
        } | null;
      } | null;
      timeEstimate?: { __typename: 'TimeSpan'; minutes?: number | null } | null;
      progress: {
        __typename: 'DigitalClinicalModuleProgress';
        totalContentItems: number;
        completedContentItems: number;
        remainingContentItems: number;
        percentageComplete: number;
      };
    }> | null;
    progress: {
      __typename: 'DigitalClinicalProgramProgress';
      totalModules: number;
      completedModules: number;
      percentageComplete: number;
    };
  } | null;
};

export type ProgramInfoFieldsFragment = {
  __typename: 'DigitalClinicalProgram';
  title: string;
  description: string;
  icon?: { __typename: 'Image'; url: string } | null;
};

export type ProgramMenuQueryVariables = Exact<{
  where: FindDigitalClinicalProgramInput;
}>;

export type ProgramMenuQuery = {
  __typename: 'Query';
  digitalClinicalProgram?: {
    __typename: 'DigitalClinicalProgram';
    programId: string;
    title: string;
    description: string;
    icon?: { __typename: 'Image'; url: string } | null;
  } | null;
};

export type LeaveProgramMenuMutationVariables = Exact<{
  request: LeaveDigitalClinicalProgramInput;
}>;

export type LeaveProgramMenuMutation = {
  __typename: 'Mutation';
  leaveDigitalClinicalProgram?: boolean | null;
};

export type TrackProgramProgressMutationVariables = Exact<{
  trackProgramInput: DigitalClinicalProgramInteractionInput;
}>;

export type TrackProgramProgressMutation = {
  __typename: 'Mutation';
  trackDigitalClinicalProgramProgress?: {
    __typename: 'DigitalClinicalProgram';
    programId: string;
    status: DigitalClinicalProgramStatus;
    modules?: Array<{
      __typename: 'DigitalClinicalModuleListItem';
      moduleId: string;
      status: DigitalClinicalModuleStatus;
      progress: {
        __typename: 'DigitalClinicalModuleProgress';
        totalContentItems: number;
        completedContentItems: number;
        remainingContentItems: number;
        percentageComplete: number;
      };
    }> | null;
    progress: {
      __typename: 'DigitalClinicalProgramProgress';
      totalModules: number;
      completedModules: number;
      percentageComplete: number;
    };
  } | null;
};

export type WellbeingHubProgramFieldsFragment = {
  __typename: 'DigitalClinicalProgramListItem';
  programId: string;
  title: string;
  description: string;
  status: DigitalClinicalProgramStatus;
  wasJoined: boolean;
  icon?: { __typename: 'Image'; url: string } | null;
  progress: {
    __typename: 'DigitalClinicalProgramProgress';
    totalModules: number;
    completedModules: number;
    percentageComplete: number;
  };
};

export type AssessmentTypeFragment = {
  __typename: 'AssessmentType';
  description?: string | null;
  id: string;
  title: string;
};

export type AssessmentQuestionOptionFragment = {
  __typename: 'AssessmentItemQuestionnaireOptionsList';
  label?: string | null;
  priority?: boolean | null;
  jumpTo?: string | null;
  uuid: string;
  alert?: {
    __typename: 'AssessmentItemQuestionnaireOptionAlert';
    message?: string | null;
  } | null;
};

export type AssessmentQuestionMeasurementRangesFragment = {
  __typename: 'MeasurementRanges';
  jumpTo?: string | null;
  range?: string | null;
  number?: string | null;
};

export type AssessmentQuestionMeasurementRangeFragment = {
  __typename: 'AssessmentItemQuestionnaireMeasurementRanges';
  unit?: string | null;
  label?: string | null;
  ranges?: Array<{
    __typename: 'MeasurementRanges';
    jumpTo?: string | null;
    range?: string | null;
    number?: string | null;
  }> | null;
};

export type AssessmentQuestionMeasurementFragment = {
  __typename: 'AssessmentItemQuestionnaireMeasurements';
  uuid?: string | null;
  unitOfMeasurement?: string | null;
  calculationType?: string | null;
  measurementRanges?: Array<{
    __typename: 'AssessmentItemQuestionnaireMeasurementRanges';
    unit?: string | null;
    label?: string | null;
    ranges?: Array<{
      __typename: 'MeasurementRanges';
      jumpTo?: string | null;
      range?: string | null;
      number?: string | null;
    }> | null;
  } | null> | null;
};

export type AssessmentQuestionFragment = {
  __typename: 'AssessmentItemQuestionnaire';
  reference?: string | null;
  uuid: string;
  multipleChoice?: boolean | null;
  questionType?: QuestionnaireQuestionType | null;
  introduction: string;
  options?: Array<{
    __typename: 'AssessmentItemQuestionnaireOptionsList';
    label?: string | null;
    priority?: boolean | null;
    jumpTo?: string | null;
    uuid: string;
    alert?: {
      __typename: 'AssessmentItemQuestionnaireOptionAlert';
      message?: string | null;
    } | null;
  } | null> | null;
  measurements?: Array<{
    __typename: 'AssessmentItemQuestionnaireMeasurements';
    uuid?: string | null;
    unitOfMeasurement?: string | null;
    calculationType?: string | null;
    measurementRanges?: Array<{
      __typename: 'AssessmentItemQuestionnaireMeasurementRanges';
      unit?: string | null;
      label?: string | null;
      ranges?: Array<{
        __typename: 'MeasurementRanges';
        jumpTo?: string | null;
        range?: string | null;
        number?: string | null;
      }> | null;
    } | null> | null;
  } | null> | null;
};

export type AssessmentImageFragment = { __typename: 'Image'; url: string };

export type AssessmentAnswerMeasurementFragment = {
  __typename: 'AssessmentItemAnswersMeasurements';
  measurementUuid: string;
  measurementType?: string | null;
  calculationType?: string | null;
  unit?: string | null;
  value?: string | null;
};

export type AssessmentAnswerFragment = {
  __typename: 'AssessmentItemAnswers';
  optionUuids?: Array<string> | null;
  questionUuid: string;
  type?: string | null;
  measurements?: Array<{
    __typename: 'AssessmentItemAnswersMeasurements';
    measurementUuid: string;
    measurementType?: string | null;
    calculationType?: string | null;
    unit?: string | null;
    value?: string | null;
  } | null> | null;
};

export type AssessmentRemainingFragment = {
  __typename: 'RemainingAssessmentsCompletionPoints';
  type?: AssessmentGroupType | null;
  count?: number | null;
  bonusPoints?: number | null;
  resetDate?: string | null;
};

export type AssessmentStatusFieldsFragment = {
  __typename: 'AssessmentListItem';
  status: AssessmentStatus;
  percentageComplete: number;
  nextQuestionReference?: string | null;
  canBeRetaken?: boolean | null;
  cooldownPeriodExpirationDate?: string | null;
};

export type AssessmentFragment = {
  __typename: 'AssessmentListItem';
  uuid: string;
  localisedUuid: string;
  version?: string | null;
  title: string;
  introduction: string;
  numberOfQuestions: number;
  status: AssessmentStatus;
  percentageComplete: number;
  nextQuestionReference?: string | null;
  canBeRetaken?: boolean | null;
  cooldownPeriodExpirationDate?: string | null;
  type: {
    __typename: 'AssessmentType';
    description?: string | null;
    id: string;
    title: string;
  };
  questionnaire?: Array<{
    __typename: 'AssessmentItemQuestionnaire';
    reference?: string | null;
    uuid: string;
    multipleChoice?: boolean | null;
    questionType?: QuestionnaireQuestionType | null;
    introduction: string;
    options?: Array<{
      __typename: 'AssessmentItemQuestionnaireOptionsList';
      label?: string | null;
      priority?: boolean | null;
      jumpTo?: string | null;
      uuid: string;
      alert?: {
        __typename: 'AssessmentItemQuestionnaireOptionAlert';
        message?: string | null;
      } | null;
    } | null> | null;
    measurements?: Array<{
      __typename: 'AssessmentItemQuestionnaireMeasurements';
      uuid?: string | null;
      unitOfMeasurement?: string | null;
      calculationType?: string | null;
      measurementRanges?: Array<{
        __typename: 'AssessmentItemQuestionnaireMeasurementRanges';
        unit?: string | null;
        label?: string | null;
        ranges?: Array<{
          __typename: 'MeasurementRanges';
          jumpTo?: string | null;
          range?: string | null;
          number?: string | null;
        }> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
  icon?: { __typename: 'Image'; url: string } | null;
  answers?: Array<{
    __typename: 'AssessmentItemAnswers';
    optionUuids?: Array<string> | null;
    questionUuid: string;
    type?: string | null;
    measurements?: Array<{
      __typename: 'AssessmentItemAnswersMeasurements';
      measurementUuid: string;
      measurementType?: string | null;
      calculationType?: string | null;
      unit?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
  remainingAssessmentsCompletionPoints?: {
    __typename: 'RemainingAssessmentsCompletionPoints';
    type?: AssessmentGroupType | null;
    count?: number | null;
    bonusPoints?: number | null;
    resetDate?: string | null;
  } | null;
};

export type AssessmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AssessmentQuery = {
  __typename: 'Query';
  assessment?: {
    __typename: 'AssessmentListItem';
    uuid: string;
    localisedUuid: string;
    version?: string | null;
    title: string;
    introduction: string;
    numberOfQuestions: number;
    status: AssessmentStatus;
    percentageComplete: number;
    nextQuestionReference?: string | null;
    canBeRetaken?: boolean | null;
    cooldownPeriodExpirationDate?: string | null;
    type: {
      __typename: 'AssessmentType';
      description?: string | null;
      id: string;
      title: string;
    };
    questionnaire?: Array<{
      __typename: 'AssessmentItemQuestionnaire';
      reference?: string | null;
      uuid: string;
      multipleChoice?: boolean | null;
      questionType?: QuestionnaireQuestionType | null;
      introduction: string;
      options?: Array<{
        __typename: 'AssessmentItemQuestionnaireOptionsList';
        label?: string | null;
        priority?: boolean | null;
        jumpTo?: string | null;
        uuid: string;
        alert?: {
          __typename: 'AssessmentItemQuestionnaireOptionAlert';
          message?: string | null;
        } | null;
      } | null> | null;
      measurements?: Array<{
        __typename: 'AssessmentItemQuestionnaireMeasurements';
        uuid?: string | null;
        unitOfMeasurement?: string | null;
        calculationType?: string | null;
        measurementRanges?: Array<{
          __typename: 'AssessmentItemQuestionnaireMeasurementRanges';
          unit?: string | null;
          label?: string | null;
          ranges?: Array<{
            __typename: 'MeasurementRanges';
            jumpTo?: string | null;
            range?: string | null;
            number?: string | null;
          }> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    icon?: { __typename: 'Image'; url: string } | null;
    answers?: Array<{
      __typename: 'AssessmentItemAnswers';
      optionUuids?: Array<string> | null;
      questionUuid: string;
      type?: string | null;
      measurements?: Array<{
        __typename: 'AssessmentItemAnswersMeasurements';
        measurementUuid: string;
        measurementType?: string | null;
        calculationType?: string | null;
        unit?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    remainingAssessmentsCompletionPoints?: {
      __typename: 'RemainingAssessmentsCompletionPoints';
      type?: AssessmentGroupType | null;
      count?: number | null;
      bonusPoints?: number | null;
      resetDate?: string | null;
    } | null;
  } | null;
};

export type AssessmentHistoryResultFragment = {
  __typename: 'AssessmentItemHistoryResult';
  digestTitle: string;
  digest?: string | null;
  percentageScore?: number | null;
};

export type AssessmentHistoryFragment = {
  __typename: 'AssessmentItemHistory';
  archiveId: string;
  assessmentUuid: string;
  title: string;
  introduction: string;
  numberOfQuestions: number;
  completedAt?: string | null;
  cooldownPeriodExpirationDate?: string | null;
  result?: {
    __typename: 'AssessmentItemHistoryResult';
    digestTitle: string;
    digest?: string | null;
    percentageScore?: number | null;
  } | null;
};

export type AssessmentResultFeedbackFragment = {
  __typename: 'ResultFeedback';
  title: string;
  body: string;
  type?: string | null;
};

export type AssessmentResultContentCategoryFragment = {
  __typename: 'AssessmentContentCategory';
  title: string;
};

export type AssessmentResultContentTypeFragment = {
  __typename: 'AssessmentContentType';
  title: string;
  typeGroup?: string | null;
  description?: string | null;
};

export type AssessmentResultImageFragment = {
  __typename: 'AssessmentItemContentImages';
  articlethumbnail?: string | null;
};

export type AssessmentResultContentAuthorFragment = {
  __typename: 'AssessmentItemContentAuthor';
  name?: string | null;
  surname?: string | null;
};

export type AssessmentResultContentFragment = {
  __typename: 'AssessmentListItemResultContentList';
  uuid: string;
  url?: string | null;
  sanitizedBody?: string | null;
  lastModificationDate?: string | null;
  title: string;
  resourcePath?: string | null;
  author?: {
    __typename: 'AssessmentItemContentAuthor';
    name?: string | null;
    surname?: string | null;
  } | null;
  category?: { __typename: 'AssessmentContentCategory'; title: string } | null;
  type?: {
    __typename: 'AssessmentContentType';
    title: string;
    typeGroup?: string | null;
    description?: string | null;
  } | null;
  images?: {
    __typename: 'AssessmentItemContentImages';
    articlethumbnail?: string | null;
  } | null;
  thumbnail?: { __typename: 'Image'; url: string } | null;
};

export type MeasurementResultFeedbackFragment = {
  __typename: 'MeasurementResultFeedback';
  title: string;
  body: string;
};

export type MeasurementResultFragment = {
  __typename: 'AssessmentContentMeasurementResult';
  score?: number | null;
  feedback?: {
    __typename: 'MeasurementResultFeedback';
    title: string;
    body: string;
  } | null;
};

export type AssessmentResultFragment = {
  __typename: 'AssessmentListItemResult';
  digestTitle: string;
  digest?: string | null;
  gender?: string | null;
  score: number;
  minScore?: number | null;
  maxScore?: number | null;
  percentageScore: number;
  shouldDisplayPercentage?: boolean | null;
  minScaleLabel: string;
  maxScaleLabel: string;
  feedback?: Array<{
    __typename: 'ResultFeedback';
    title: string;
    body: string;
    type?: string | null;
  } | null> | null;
  content?: Array<{
    __typename: 'AssessmentListItemResultContentList';
    uuid: string;
    url?: string | null;
    sanitizedBody?: string | null;
    lastModificationDate?: string | null;
    title: string;
    resourcePath?: string | null;
    author?: {
      __typename: 'AssessmentItemContentAuthor';
      name?: string | null;
      surname?: string | null;
    } | null;
    category?: {
      __typename: 'AssessmentContentCategory';
      title: string;
    } | null;
    type?: {
      __typename: 'AssessmentContentType';
      title: string;
      typeGroup?: string | null;
      description?: string | null;
    } | null;
    images?: {
      __typename: 'AssessmentItemContentImages';
      articlethumbnail?: string | null;
    } | null;
    thumbnail?: { __typename: 'Image'; url: string } | null;
  } | null> | null;
  measurementResult?: {
    __typename: 'AssessmentContentMeasurementResult';
    score?: number | null;
    feedback?: {
      __typename: 'MeasurementResultFeedback';
      title: string;
      body: string;
    } | null;
  } | null;
};

export type NextAssessmentFragment = {
  __typename: 'AssessmentListItem';
  uuid: string;
  title: string;
};

export type AssessmentTierStateFragment = {
  __typename: 'AssessmentItemCurrentTierProgress';
  pointsForCompletingAssessment?: number | null;
  bonusPointsForCompletingAllAssessments?: number | null;
  tierSummary?: {
    __typename: 'CurrentTierProgressSummary';
    nextTier?: {
      __typename: 'NextTier';
      id?: string | null;
      name: string;
      rank?: number | null;
      meetsRequirementsForTier?: boolean | null;
    } | null;
    currentTier?: {
      __typename: 'CurrentTier';
      id?: string | null;
      name: string;
      rank: number;
      motivationalMessage: string;
    } | null;
    points?: {
      __typename: 'TierPoints';
      id?: string | null;
      remainingToReachNextTier?: number | null;
      earned: {
        __typename: 'TierPointsEarned';
        total: number;
        thisMonth: {
          __typename: 'TierPointsEarnedInTimePeriod';
          value?: number | null;
          since?: string | null;
        };
      };
      requiredToNotRegress?: {
        __typename: 'TierPointsRequired';
        total: number;
        remaining: number;
        thisMonth: {
          __typename: 'TierPointsRequiredInTimePeriod';
          value: number;
          remaining: number;
          daysRemaining: number;
        };
      } | null;
    } | null;
  } | null;
};

export type FinalAnswerMutationResponseFragment = {
  __typename: 'AssessmentListItem';
  uuid: string;
  tierPoints?: number | null;
  status: AssessmentStatus;
  percentageComplete: number;
  nextQuestionReference?: string | null;
  canBeRetaken?: boolean | null;
  cooldownPeriodExpirationDate?: string | null;
  answers?: Array<{
    __typename: 'AssessmentItemAnswers';
    optionUuids?: Array<string> | null;
    questionUuid: string;
    type?: string | null;
    measurements?: Array<{
      __typename: 'AssessmentItemAnswersMeasurements';
      measurementUuid: string;
      measurementType?: string | null;
      calculationType?: string | null;
      unit?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
  result?: {
    __typename: 'AssessmentListItemResult';
    digestTitle: string;
    digest?: string | null;
    gender?: string | null;
    score: number;
    minScore?: number | null;
    maxScore?: number | null;
    percentageScore: number;
    shouldDisplayPercentage?: boolean | null;
    minScaleLabel: string;
    maxScaleLabel: string;
    feedback?: Array<{
      __typename: 'ResultFeedback';
      title: string;
      body: string;
      type?: string | null;
    } | null> | null;
    content?: Array<{
      __typename: 'AssessmentListItemResultContentList';
      uuid: string;
      url?: string | null;
      sanitizedBody?: string | null;
      lastModificationDate?: string | null;
      title: string;
      resourcePath?: string | null;
      author?: {
        __typename: 'AssessmentItemContentAuthor';
        name?: string | null;
        surname?: string | null;
      } | null;
      category?: {
        __typename: 'AssessmentContentCategory';
        title: string;
      } | null;
      type?: {
        __typename: 'AssessmentContentType';
        title: string;
        typeGroup?: string | null;
        description?: string | null;
      } | null;
      images?: {
        __typename: 'AssessmentItemContentImages';
        articlethumbnail?: string | null;
      } | null;
      thumbnail?: { __typename: 'Image'; url: string } | null;
    } | null> | null;
    measurementResult?: {
      __typename: 'AssessmentContentMeasurementResult';
      score?: number | null;
      feedback?: {
        __typename: 'MeasurementResultFeedback';
        title: string;
        body: string;
      } | null;
    } | null;
  } | null;
  nextAssessment?: {
    __typename: 'AssessmentListItem';
    uuid: string;
    title: string;
  } | null;
  tierProgress?: {
    __typename: 'AssessmentItemCurrentTierProgress';
    pointsForCompletingAssessment?: number | null;
    bonusPointsForCompletingAllAssessments?: number | null;
    tierSummary?: {
      __typename: 'CurrentTierProgressSummary';
      nextTier?: {
        __typename: 'NextTier';
        id?: string | null;
        name: string;
        rank?: number | null;
        meetsRequirementsForTier?: boolean | null;
      } | null;
      currentTier?: {
        __typename: 'CurrentTier';
        id?: string | null;
        name: string;
        rank: number;
        motivationalMessage: string;
      } | null;
      points?: {
        __typename: 'TierPoints';
        id?: string | null;
        remainingToReachNextTier?: number | null;
        earned: {
          __typename: 'TierPointsEarned';
          total: number;
          thisMonth: {
            __typename: 'TierPointsEarnedInTimePeriod';
            value?: number | null;
            since?: string | null;
          };
        };
        requiredToNotRegress?: {
          __typename: 'TierPointsRequired';
          total: number;
          remaining: number;
          thisMonth: {
            __typename: 'TierPointsRequiredInTimePeriod';
            value: number;
            remaining: number;
            daysRemaining: number;
          };
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AnswerMutationResponseFragment = {
  __typename: 'AssessmentListItem';
  uuid: string;
  status: AssessmentStatus;
  percentageComplete: number;
  nextQuestionReference?: string | null;
  canBeRetaken?: boolean | null;
  cooldownPeriodExpirationDate?: string | null;
  answers?: Array<{
    __typename: 'AssessmentItemAnswers';
    optionUuids?: Array<string> | null;
    questionUuid: string;
    type?: string | null;
    measurements?: Array<{
      __typename: 'AssessmentItemAnswersMeasurements';
      measurementUuid: string;
      measurementType?: string | null;
      calculationType?: string | null;
      unit?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
};

export type CompletedAssessmentFragment = {
  __typename: 'AssessmentListItem';
  uuid: string;
  localisedUuid: string;
  version?: string | null;
  title: string;
  introduction: string;
  numberOfQuestions: number;
  tierPoints?: number | null;
  status: AssessmentStatus;
  percentageComplete: number;
  nextQuestionReference?: string | null;
  canBeRetaken?: boolean | null;
  cooldownPeriodExpirationDate?: string | null;
  type: {
    __typename: 'AssessmentType';
    description?: string | null;
    id: string;
    title: string;
  };
  questionnaire?: Array<{
    __typename: 'AssessmentItemQuestionnaire';
    reference?: string | null;
    uuid: string;
    multipleChoice?: boolean | null;
    questionType?: QuestionnaireQuestionType | null;
    introduction: string;
    options?: Array<{
      __typename: 'AssessmentItemQuestionnaireOptionsList';
      label?: string | null;
      priority?: boolean | null;
      jumpTo?: string | null;
      uuid: string;
      alert?: {
        __typename: 'AssessmentItemQuestionnaireOptionAlert';
        message?: string | null;
      } | null;
    } | null> | null;
    measurements?: Array<{
      __typename: 'AssessmentItemQuestionnaireMeasurements';
      uuid?: string | null;
      unitOfMeasurement?: string | null;
      calculationType?: string | null;
      measurementRanges?: Array<{
        __typename: 'AssessmentItemQuestionnaireMeasurementRanges';
        unit?: string | null;
        label?: string | null;
        ranges?: Array<{
          __typename: 'MeasurementRanges';
          jumpTo?: string | null;
          range?: string | null;
          number?: string | null;
        }> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
  icon?: { __typename: 'Image'; url: string } | null;
  answers?: Array<{
    __typename: 'AssessmentItemAnswers';
    optionUuids?: Array<string> | null;
    questionUuid: string;
    type?: string | null;
    measurements?: Array<{
      __typename: 'AssessmentItemAnswersMeasurements';
      measurementUuid: string;
      measurementType?: string | null;
      calculationType?: string | null;
      unit?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
  remainingAssessmentsCompletionPoints?: {
    __typename: 'RemainingAssessmentsCompletionPoints';
    type?: AssessmentGroupType | null;
    count?: number | null;
    bonusPoints?: number | null;
    resetDate?: string | null;
  } | null;
  result?: {
    __typename: 'AssessmentListItemResult';
    digestTitle: string;
    digest?: string | null;
    gender?: string | null;
    score: number;
    minScore?: number | null;
    maxScore?: number | null;
    percentageScore: number;
    shouldDisplayPercentage?: boolean | null;
    minScaleLabel: string;
    maxScaleLabel: string;
    feedback?: Array<{
      __typename: 'ResultFeedback';
      title: string;
      body: string;
      type?: string | null;
    } | null> | null;
    content?: Array<{
      __typename: 'AssessmentListItemResultContentList';
      uuid: string;
      url?: string | null;
      sanitizedBody?: string | null;
      lastModificationDate?: string | null;
      title: string;
      resourcePath?: string | null;
      author?: {
        __typename: 'AssessmentItemContentAuthor';
        name?: string | null;
        surname?: string | null;
      } | null;
      category?: {
        __typename: 'AssessmentContentCategory';
        title: string;
      } | null;
      type?: {
        __typename: 'AssessmentContentType';
        title: string;
        typeGroup?: string | null;
        description?: string | null;
      } | null;
      images?: {
        __typename: 'AssessmentItemContentImages';
        articlethumbnail?: string | null;
      } | null;
      thumbnail?: { __typename: 'Image'; url: string } | null;
    } | null> | null;
    measurementResult?: {
      __typename: 'AssessmentContentMeasurementResult';
      score?: number | null;
      feedback?: {
        __typename: 'MeasurementResultFeedback';
        title: string;
        body: string;
      } | null;
    } | null;
  } | null;
  nextAssessment?: {
    __typename: 'AssessmentListItem';
    uuid: string;
    title: string;
  } | null;
  tierProgress?: {
    __typename: 'AssessmentItemCurrentTierProgress';
    pointsForCompletingAssessment?: number | null;
    bonusPointsForCompletingAllAssessments?: number | null;
    tierSummary?: {
      __typename: 'CurrentTierProgressSummary';
      nextTier?: {
        __typename: 'NextTier';
        id?: string | null;
        name: string;
        rank?: number | null;
        meetsRequirementsForTier?: boolean | null;
      } | null;
      currentTier?: {
        __typename: 'CurrentTier';
        id?: string | null;
        name: string;
        rank: number;
        motivationalMessage: string;
      } | null;
      points?: {
        __typename: 'TierPoints';
        id?: string | null;
        remainingToReachNextTier?: number | null;
        earned: {
          __typename: 'TierPointsEarned';
          total: number;
          thisMonth: {
            __typename: 'TierPointsEarnedInTimePeriod';
            value?: number | null;
            since?: string | null;
          };
        };
        requiredToNotRegress?: {
          __typename: 'TierPointsRequired';
          total: number;
          remaining: number;
          thisMonth: {
            __typename: 'TierPointsRequiredInTimePeriod';
            value: number;
            remaining: number;
            daysRemaining: number;
          };
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AnswerAssessmentQuestionsMutationVariables = Exact<{
  assesmentId: Scalars['ID']['input'];
  answers: Array<AssessmentAnswersInput> | AssessmentAnswersInput;
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AnswerAssessmentQuestionsMutation = {
  __typename: 'Mutation';
  answerAssessmentQuestions?: {
    __typename: 'AnswerAssessmentQuestionsResponse';
    assessment?: {
      __typename: 'AssessmentListItem';
      uuid: string;
      status: AssessmentStatus;
      percentageComplete: number;
      nextQuestionReference?: string | null;
      canBeRetaken?: boolean | null;
      cooldownPeriodExpirationDate?: string | null;
      answers?: Array<{
        __typename: 'AssessmentItemAnswers';
        optionUuids?: Array<string> | null;
        questionUuid: string;
        type?: string | null;
        measurements?: Array<{
          __typename: 'AssessmentItemAnswersMeasurements';
          measurementUuid: string;
          measurementType?: string | null;
          calculationType?: string | null;
          unit?: string | null;
          value?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type FinishAnsweringAssessmentQuestionsMutationVariables = Exact<{
  assesmentId: Scalars['ID']['input'];
  answers: Array<AssessmentAnswersInput> | AssessmentAnswersInput;
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FinishAnsweringAssessmentQuestionsMutation = {
  __typename: 'Mutation';
  answerAssessmentQuestions?: {
    __typename: 'AnswerAssessmentQuestionsResponse';
    assessment?: {
      __typename: 'AssessmentListItem';
      uuid: string;
      tierPoints?: number | null;
      status: AssessmentStatus;
      percentageComplete: number;
      nextQuestionReference?: string | null;
      canBeRetaken?: boolean | null;
      cooldownPeriodExpirationDate?: string | null;
      answers?: Array<{
        __typename: 'AssessmentItemAnswers';
        optionUuids?: Array<string> | null;
        questionUuid: string;
        type?: string | null;
        measurements?: Array<{
          __typename: 'AssessmentItemAnswersMeasurements';
          measurementUuid: string;
          measurementType?: string | null;
          calculationType?: string | null;
          unit?: string | null;
          value?: string | null;
        } | null> | null;
      } | null> | null;
      result?: {
        __typename: 'AssessmentListItemResult';
        digestTitle: string;
        digest?: string | null;
        gender?: string | null;
        score: number;
        minScore?: number | null;
        maxScore?: number | null;
        percentageScore: number;
        shouldDisplayPercentage?: boolean | null;
        minScaleLabel: string;
        maxScaleLabel: string;
        feedback?: Array<{
          __typename: 'ResultFeedback';
          title: string;
          body: string;
          type?: string | null;
        } | null> | null;
        content?: Array<{
          __typename: 'AssessmentListItemResultContentList';
          uuid: string;
          url?: string | null;
          sanitizedBody?: string | null;
          lastModificationDate?: string | null;
          title: string;
          resourcePath?: string | null;
          author?: {
            __typename: 'AssessmentItemContentAuthor';
            name?: string | null;
            surname?: string | null;
          } | null;
          category?: {
            __typename: 'AssessmentContentCategory';
            title: string;
          } | null;
          type?: {
            __typename: 'AssessmentContentType';
            title: string;
            typeGroup?: string | null;
            description?: string | null;
          } | null;
          images?: {
            __typename: 'AssessmentItemContentImages';
            articlethumbnail?: string | null;
          } | null;
          thumbnail?: { __typename: 'Image'; url: string } | null;
        } | null> | null;
        measurementResult?: {
          __typename: 'AssessmentContentMeasurementResult';
          score?: number | null;
          feedback?: {
            __typename: 'MeasurementResultFeedback';
            title: string;
            body: string;
          } | null;
        } | null;
      } | null;
      nextAssessment?: {
        __typename: 'AssessmentListItem';
        uuid: string;
        title: string;
      } | null;
      tierProgress?: {
        __typename: 'AssessmentItemCurrentTierProgress';
        pointsForCompletingAssessment?: number | null;
        bonusPointsForCompletingAllAssessments?: number | null;
        tierSummary?: {
          __typename: 'CurrentTierProgressSummary';
          nextTier?: {
            __typename: 'NextTier';
            id?: string | null;
            name: string;
            rank?: number | null;
            meetsRequirementsForTier?: boolean | null;
          } | null;
          currentTier?: {
            __typename: 'CurrentTier';
            id?: string | null;
            name: string;
            rank: number;
            motivationalMessage: string;
          } | null;
          points?: {
            __typename: 'TierPoints';
            id?: string | null;
            remainingToReachNextTier?: number | null;
            earned: {
              __typename: 'TierPointsEarned';
              total: number;
              thisMonth: {
                __typename: 'TierPointsEarnedInTimePeriod';
                value?: number | null;
                since?: string | null;
              };
            };
            requiredToNotRegress?: {
              __typename: 'TierPointsRequired';
              total: number;
              remaining: number;
              thisMonth: {
                __typename: 'TierPointsRequiredInTimePeriod';
                value: number;
                remaining: number;
                daysRemaining: number;
              };
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    originalTierState?: {
      __typename: 'AssessmentItemCurrentTierProgress';
      pointsForCompletingAssessment?: number | null;
      bonusPointsForCompletingAllAssessments?: number | null;
      tierSummary?: {
        __typename: 'CurrentTierProgressSummary';
        nextTier?: {
          __typename: 'NextTier';
          id?: string | null;
          name: string;
          rank?: number | null;
          meetsRequirementsForTier?: boolean | null;
        } | null;
        currentTier?: {
          __typename: 'CurrentTier';
          id?: string | null;
          name: string;
          rank: number;
          motivationalMessage: string;
        } | null;
        points?: {
          __typename: 'TierPoints';
          id?: string | null;
          remainingToReachNextTier?: number | null;
          earned: {
            __typename: 'TierPointsEarned';
            total: number;
            thisMonth: {
              __typename: 'TierPointsEarnedInTimePeriod';
              value?: number | null;
              since?: string | null;
            };
          };
          requiredToNotRegress?: {
            __typename: 'TierPointsRequired';
            total: number;
            remaining: number;
            thisMonth: {
              __typename: 'TierPointsRequiredInTimePeriod';
              value: number;
              remaining: number;
              daysRemaining: number;
            };
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AllTiersQueryVariables = Exact<{ [key: string]: never }>;

export type AllTiersQuery = {
  __typename: 'Query';
  allTiers?: Array<{
    __typename: 'TierDefinition';
    name: string;
    rank: number;
    points: number;
  }> | null;
};

export type HistoricalAssessmentFragment = {
  __typename: 'HistoricalAssessment';
  assessmentUuid: string;
  archiveId: string;
  result?: {
    __typename: 'AssessmentListItemResult';
    digestTitle: string;
    digest?: string | null;
    gender?: string | null;
    score: number;
    minScore?: number | null;
    maxScore?: number | null;
    percentageScore: number;
    shouldDisplayPercentage?: boolean | null;
    minScaleLabel: string;
    maxScaleLabel: string;
    feedback?: Array<{
      __typename: 'ResultFeedback';
      title: string;
      body: string;
      type?: string | null;
    } | null> | null;
    content?: Array<{
      __typename: 'AssessmentListItemResultContentList';
      uuid: string;
      url?: string | null;
      sanitizedBody?: string | null;
      lastModificationDate?: string | null;
      title: string;
      resourcePath?: string | null;
      author?: {
        __typename: 'AssessmentItemContentAuthor';
        name?: string | null;
        surname?: string | null;
      } | null;
      category?: {
        __typename: 'AssessmentContentCategory';
        title: string;
      } | null;
      type?: {
        __typename: 'AssessmentContentType';
        title: string;
        typeGroup?: string | null;
        description?: string | null;
      } | null;
      images?: {
        __typename: 'AssessmentItemContentImages';
        articlethumbnail?: string | null;
      } | null;
      thumbnail?: { __typename: 'Image'; url: string } | null;
    } | null> | null;
    measurementResult?: {
      __typename: 'AssessmentContentMeasurementResult';
      score?: number | null;
      feedback?: {
        __typename: 'MeasurementResultFeedback';
        title: string;
        body: string;
      } | null;
    } | null;
  } | null;
};

export type AssessmentResultCommonFieldsFragment = {
  __typename: 'AssessmentListItem';
  canBeRetaken?: boolean | null;
  uuid: string;
  title: string;
  icon?: { __typename: 'Image'; url: string } | null;
};

export type RegressionInformationFragment = {
  __typename: 'TierRegressionInfo';
  image?: {
    __typename: 'Image';
    url: string;
    font?: {
      __typename: 'Font';
      color?: { __typename: 'Color'; hexCode: string } | null;
    } | null;
  } | null;
  timePeriods: Array<{
    __typename: 'TierRegressionTimePeriodInfo';
    isCurrent?: boolean | null;
    pointsEarned?: number | null;
    pointGoal?: number | null;
    timePeriod?: { __typename: 'TimePeriod'; from: string; to: string } | null;
  }>;
  currentTierDefinition: {
    __typename: 'TierDefinition';
    name: string;
    rank: number;
    points: number;
  };
};

export type AssessmentHistoryQueryVariables = Exact<{
  archiveId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AssessmentHistoryQuery = {
  __typename: 'Query';
  resultData?: {
    __typename: 'HistoricalAssessment';
    assessmentUuid: string;
    archiveId: string;
    result?: {
      __typename: 'AssessmentListItemResult';
      digestTitle: string;
      digest?: string | null;
      gender?: string | null;
      score: number;
      minScore?: number | null;
      maxScore?: number | null;
      percentageScore: number;
      shouldDisplayPercentage?: boolean | null;
      minScaleLabel: string;
      maxScaleLabel: string;
      feedback?: Array<{
        __typename: 'ResultFeedback';
        title: string;
        body: string;
        type?: string | null;
      } | null> | null;
      content?: Array<{
        __typename: 'AssessmentListItemResultContentList';
        uuid: string;
        url?: string | null;
        sanitizedBody?: string | null;
        lastModificationDate?: string | null;
        title: string;
        resourcePath?: string | null;
        author?: {
          __typename: 'AssessmentItemContentAuthor';
          name?: string | null;
          surname?: string | null;
        } | null;
        category?: {
          __typename: 'AssessmentContentCategory';
          title: string;
        } | null;
        type?: {
          __typename: 'AssessmentContentType';
          title: string;
          typeGroup?: string | null;
          description?: string | null;
        } | null;
        images?: {
          __typename: 'AssessmentItemContentImages';
          articlethumbnail?: string | null;
        } | null;
        thumbnail?: { __typename: 'Image'; url: string } | null;
      } | null> | null;
      measurementResult?: {
        __typename: 'AssessmentContentMeasurementResult';
        score?: number | null;
        feedback?: {
          __typename: 'MeasurementResultFeedback';
          title: string;
          body: string;
        } | null;
      } | null;
    } | null;
  } | null;
  commonAssessmentProperties?: {
    __typename: 'AssessmentListItem';
    canBeRetaken?: boolean | null;
    uuid: string;
    title: string;
    icon?: { __typename: 'Image'; url: string } | null;
  } | null;
};

export type AssessmentFullHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AssessmentFullHistoryQuery = {
  __typename: 'Query';
  assessmentHistory?: Array<{
    __typename: 'AssessmentItemHistory';
    archiveId: string;
    assessmentUuid: string;
    title: string;
    introduction: string;
    numberOfQuestions: number;
    completedAt?: string | null;
    cooldownPeriodExpirationDate?: string | null;
    result?: {
      __typename: 'AssessmentItemHistoryResult';
      digestTitle: string;
      digest?: string | null;
      percentageScore?: number | null;
    } | null;
  } | null> | null;
};

export type AssessmentResultQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AssessmentResultQuery = {
  __typename: 'Query';
  resultData?: {
    __typename: 'AssessmentListItem';
    uuid: string;
    tierPoints?: number | null;
    status: AssessmentStatus;
    percentageComplete: number;
    nextQuestionReference?: string | null;
    canBeRetaken?: boolean | null;
    cooldownPeriodExpirationDate?: string | null;
    answers?: Array<{
      __typename: 'AssessmentItemAnswers';
      optionUuids?: Array<string> | null;
      questionUuid: string;
      type?: string | null;
      measurements?: Array<{
        __typename: 'AssessmentItemAnswersMeasurements';
        measurementUuid: string;
        measurementType?: string | null;
        calculationType?: string | null;
        unit?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    result?: {
      __typename: 'AssessmentListItemResult';
      digestTitle: string;
      digest?: string | null;
      gender?: string | null;
      score: number;
      minScore?: number | null;
      maxScore?: number | null;
      percentageScore: number;
      shouldDisplayPercentage?: boolean | null;
      minScaleLabel: string;
      maxScaleLabel: string;
      feedback?: Array<{
        __typename: 'ResultFeedback';
        title: string;
        body: string;
        type?: string | null;
      } | null> | null;
      content?: Array<{
        __typename: 'AssessmentListItemResultContentList';
        uuid: string;
        url?: string | null;
        sanitizedBody?: string | null;
        lastModificationDate?: string | null;
        title: string;
        resourcePath?: string | null;
        author?: {
          __typename: 'AssessmentItemContentAuthor';
          name?: string | null;
          surname?: string | null;
        } | null;
        category?: {
          __typename: 'AssessmentContentCategory';
          title: string;
        } | null;
        type?: {
          __typename: 'AssessmentContentType';
          title: string;
          typeGroup?: string | null;
          description?: string | null;
        } | null;
        images?: {
          __typename: 'AssessmentItemContentImages';
          articlethumbnail?: string | null;
        } | null;
        thumbnail?: { __typename: 'Image'; url: string } | null;
      } | null> | null;
      measurementResult?: {
        __typename: 'AssessmentContentMeasurementResult';
        score?: number | null;
        feedback?: {
          __typename: 'MeasurementResultFeedback';
          title: string;
          body: string;
        } | null;
      } | null;
    } | null;
    nextAssessment?: {
      __typename: 'AssessmentListItem';
      uuid: string;
      title: string;
    } | null;
    tierProgress?: {
      __typename: 'AssessmentItemCurrentTierProgress';
      pointsForCompletingAssessment?: number | null;
      bonusPointsForCompletingAllAssessments?: number | null;
      tierSummary?: {
        __typename: 'CurrentTierProgressSummary';
        nextTier?: {
          __typename: 'NextTier';
          id?: string | null;
          name: string;
          rank?: number | null;
          meetsRequirementsForTier?: boolean | null;
        } | null;
        currentTier?: {
          __typename: 'CurrentTier';
          id?: string | null;
          name: string;
          rank: number;
          motivationalMessage: string;
        } | null;
        points?: {
          __typename: 'TierPoints';
          id?: string | null;
          remainingToReachNextTier?: number | null;
          earned: {
            __typename: 'TierPointsEarned';
            total: number;
            thisMonth: {
              __typename: 'TierPointsEarnedInTimePeriod';
              value?: number | null;
              since?: string | null;
            };
          };
          requiredToNotRegress?: {
            __typename: 'TierPointsRequired';
            total: number;
            remaining: number;
            thisMonth: {
              __typename: 'TierPointsRequiredInTimePeriod';
              value: number;
              remaining: number;
              daysRemaining: number;
            };
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  commonAssessmentProperties?: {
    __typename: 'AssessmentListItem';
    canBeRetaken?: boolean | null;
    uuid: string;
    title: string;
    icon?: { __typename: 'Image'; url: string } | null;
  } | null;
};

export type RetakeAssessmentMutationVariables = Exact<{
  uuid: Scalars['ID']['input'];
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type RetakeAssessmentMutation = {
  __typename: 'Mutation';
  retakeAssessment?: {
    __typename: 'retakeAssessmentResponse';
    assessment?: {
      __typename: 'AssessmentListItem';
      uuid: string;
      localisedUuid: string;
      version?: string | null;
      title: string;
      introduction: string;
      numberOfQuestions: number;
      status: AssessmentStatus;
      percentageComplete: number;
      nextQuestionReference?: string | null;
      canBeRetaken?: boolean | null;
      cooldownPeriodExpirationDate?: string | null;
      type: {
        __typename: 'AssessmentType';
        description?: string | null;
        id: string;
        title: string;
      };
      questionnaire?: Array<{
        __typename: 'AssessmentItemQuestionnaire';
        reference?: string | null;
        uuid: string;
        multipleChoice?: boolean | null;
        questionType?: QuestionnaireQuestionType | null;
        introduction: string;
        options?: Array<{
          __typename: 'AssessmentItemQuestionnaireOptionsList';
          label?: string | null;
          priority?: boolean | null;
          jumpTo?: string | null;
          uuid: string;
          alert?: {
            __typename: 'AssessmentItemQuestionnaireOptionAlert';
            message?: string | null;
          } | null;
        } | null> | null;
        measurements?: Array<{
          __typename: 'AssessmentItemQuestionnaireMeasurements';
          uuid?: string | null;
          unitOfMeasurement?: string | null;
          calculationType?: string | null;
          measurementRanges?: Array<{
            __typename: 'AssessmentItemQuestionnaireMeasurementRanges';
            unit?: string | null;
            label?: string | null;
            ranges?: Array<{
              __typename: 'MeasurementRanges';
              jumpTo?: string | null;
              range?: string | null;
              number?: string | null;
            }> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
      icon?: { __typename: 'Image'; url: string } | null;
      answers?: Array<{
        __typename: 'AssessmentItemAnswers';
        optionUuids?: Array<string> | null;
        questionUuid: string;
        type?: string | null;
        measurements?: Array<{
          __typename: 'AssessmentItemAnswersMeasurements';
          measurementUuid: string;
          measurementType?: string | null;
          calculationType?: string | null;
          unit?: string | null;
          value?: string | null;
        } | null> | null;
      } | null> | null;
      remainingAssessmentsCompletionPoints?: {
        __typename: 'RemainingAssessmentsCompletionPoints';
        type?: AssessmentGroupType | null;
        count?: number | null;
        bonusPoints?: number | null;
        resetDate?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ChangeGenderMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  gender: Scalars['String']['input'];
  answers: Array<AssessmentAnswersInput> | AssessmentAnswersInput;
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ChangeGenderMutation = {
  __typename: 'Mutation';
  changeGender?: {
    __typename: 'changeGenderResponse';
    assessment?: {
      __typename: 'AssessmentListItem';
      uuid: string;
      result?: {
        __typename: 'AssessmentListItemResult';
        digestTitle: string;
        digest?: string | null;
        gender?: string | null;
        score: number;
        minScore?: number | null;
        maxScore?: number | null;
        percentageScore: number;
        shouldDisplayPercentage?: boolean | null;
        minScaleLabel: string;
        maxScaleLabel: string;
        feedback?: Array<{
          __typename: 'ResultFeedback';
          title: string;
          body: string;
          type?: string | null;
        } | null> | null;
        content?: Array<{
          __typename: 'AssessmentListItemResultContentList';
          uuid: string;
          url?: string | null;
          sanitizedBody?: string | null;
          lastModificationDate?: string | null;
          title: string;
          resourcePath?: string | null;
          author?: {
            __typename: 'AssessmentItemContentAuthor';
            name?: string | null;
            surname?: string | null;
          } | null;
          category?: {
            __typename: 'AssessmentContentCategory';
            title: string;
          } | null;
          type?: {
            __typename: 'AssessmentContentType';
            title: string;
            typeGroup?: string | null;
            description?: string | null;
          } | null;
          images?: {
            __typename: 'AssessmentItemContentImages';
            articlethumbnail?: string | null;
          } | null;
          thumbnail?: { __typename: 'Image'; url: string } | null;
        } | null> | null;
        measurementResult?: {
          __typename: 'AssessmentContentMeasurementResult';
          score?: number | null;
          feedback?: {
            __typename: 'MeasurementResultFeedback';
            title: string;
            body: string;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type RegressionInfoQueryVariables = Exact<{ [key: string]: never }>;

export type RegressionInfoQuery = {
  __typename: 'Query';
  tierRegressionInfo?: {
    __typename: 'TierRegressionInfo';
    image?: {
      __typename: 'Image';
      url: string;
      font?: {
        __typename: 'Font';
        color?: { __typename: 'Color'; hexCode: string } | null;
      } | null;
    } | null;
    timePeriods: Array<{
      __typename: 'TierRegressionTimePeriodInfo';
      isCurrent?: boolean | null;
      pointsEarned?: number | null;
      pointGoal?: number | null;
      timePeriod?: {
        __typename: 'TimePeriod';
        from: string;
        to: string;
      } | null;
    }>;
    currentTierDefinition: {
      __typename: 'TierDefinition';
      name: string;
      rank: number;
      points: number;
    };
  } | null;
  currentTier?: {
    __typename: 'CurrentTier';
    id?: string | null;
    name: string;
    rank: number;
    motivationalMessage: string;
  } | null;
};

export type AssessmentsHubAssessmentResultFragment = {
  __typename: 'AssessmentListItemResult';
  digestTitle: string;
  digest?: string | null;
  minScaleLabel: string;
  maxScaleLabel: string;
  percentageScore: number;
  shouldDisplayPercentage?: boolean | null;
  score: number;
};

export type AssessmentListItemFragment = {
  __typename: 'AssessmentListItem';
  tierPoints?: number | null;
  uuid: string;
  localisedUuid: string;
  version?: string | null;
  title: string;
  introduction: string;
  numberOfQuestions: number;
  status: AssessmentStatus;
  percentageComplete: number;
  nextQuestionReference?: string | null;
  canBeRetaken?: boolean | null;
  cooldownPeriodExpirationDate?: string | null;
  type: {
    __typename: 'AssessmentType';
    description?: string | null;
    id: string;
    title: string;
  };
  questionnaire?: Array<{
    __typename: 'AssessmentItemQuestionnaire';
    reference?: string | null;
    uuid: string;
    multipleChoice?: boolean | null;
    questionType?: QuestionnaireQuestionType | null;
    introduction: string;
    options?: Array<{
      __typename: 'AssessmentItemQuestionnaireOptionsList';
      label?: string | null;
      priority?: boolean | null;
      jumpTo?: string | null;
      uuid: string;
      alert?: {
        __typename: 'AssessmentItemQuestionnaireOptionAlert';
        message?: string | null;
      } | null;
    } | null> | null;
    measurements?: Array<{
      __typename: 'AssessmentItemQuestionnaireMeasurements';
      uuid?: string | null;
      unitOfMeasurement?: string | null;
      calculationType?: string | null;
      measurementRanges?: Array<{
        __typename: 'AssessmentItemQuestionnaireMeasurementRanges';
        unit?: string | null;
        label?: string | null;
        ranges?: Array<{
          __typename: 'MeasurementRanges';
          jumpTo?: string | null;
          range?: string | null;
          number?: string | null;
        }> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
  icon?: { __typename: 'Image'; url: string } | null;
  answers?: Array<{
    __typename: 'AssessmentItemAnswers';
    optionUuids?: Array<string> | null;
    questionUuid: string;
    type?: string | null;
    measurements?: Array<{
      __typename: 'AssessmentItemAnswersMeasurements';
      measurementUuid: string;
      measurementType?: string | null;
      calculationType?: string | null;
      unit?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
  remainingAssessmentsCompletionPoints?: {
    __typename: 'RemainingAssessmentsCompletionPoints';
    type?: AssessmentGroupType | null;
    count?: number | null;
    bonusPoints?: number | null;
    resetDate?: string | null;
  } | null;
};

export type SingleAssessmentHeroCardFragment = {
  __typename: 'SingleAssessmentCard';
  completedAt?: string | null;
  status: AssessmentStatus;
  title: string;
  tierPoints?: number | null;
  icon?: { __typename: 'Image'; url: string } | null;
  assessment: {
    __typename: 'AssessmentListItem';
    title: string;
    status: AssessmentStatus;
    numberOfQuestions: number;
    percentageComplete: number;
    uuid: string;
    result?: {
      __typename: 'AssessmentListItemResult';
      percentageScore: number;
    } | null;
    type: {
      __typename: 'AssessmentType';
      id: string;
      title: string;
      description?: string | null;
      filter?: string | null;
    };
  };
};

export type HealthRiskAssessmentHeroCardFragment = {
  __typename: 'HealthRiskAssessmentCard';
  completedAt?: string | null;
  status: AssessmentStatus;
  title: string;
  tierPoints?: number | null;
  numberOfAssessments: number;
  assessmentsRemaining: number;
  icon?: { __typename: 'Image'; url: string } | null;
  nextAssessment: {
    __typename: 'AssessmentListItem';
    title: string;
    status: AssessmentStatus;
    numberOfQuestions: number;
    percentageComplete: number;
    uuid: string;
    result?: {
      __typename: 'AssessmentListItemResult';
      percentageScore: number;
    } | null;
    type: {
      __typename: 'AssessmentType';
      id: string;
      title: string;
      description?: string | null;
      filter?: string | null;
    };
  };
};

export type AssessmentTypeFiltersFragment = {
  __typename: 'AssessmentTypeFilter';
  typeId: string;
  name: string;
  count: number;
};

export type AssessmentHubListItemFragment = {
  __typename: 'AssessmentListItem';
  tierPoints?: number | null;
  uuid: string;
  localisedUuid: string;
  version?: string | null;
  title: string;
  introduction: string;
  numberOfQuestions: number;
  status: AssessmentStatus;
  percentageComplete: number;
  nextQuestionReference?: string | null;
  canBeRetaken?: boolean | null;
  cooldownPeriodExpirationDate?: string | null;
  result?: {
    __typename: 'AssessmentListItemResult';
    digestTitle: string;
    digest?: string | null;
    minScaleLabel: string;
    maxScaleLabel: string;
    percentageScore: number;
    shouldDisplayPercentage?: boolean | null;
    score: number;
  } | null;
  type: {
    __typename: 'AssessmentType';
    description?: string | null;
    id: string;
    title: string;
  };
  questionnaire?: Array<{
    __typename: 'AssessmentItemQuestionnaire';
    reference?: string | null;
    uuid: string;
    multipleChoice?: boolean | null;
    questionType?: QuestionnaireQuestionType | null;
    introduction: string;
    options?: Array<{
      __typename: 'AssessmentItemQuestionnaireOptionsList';
      label?: string | null;
      priority?: boolean | null;
      jumpTo?: string | null;
      uuid: string;
      alert?: {
        __typename: 'AssessmentItemQuestionnaireOptionAlert';
        message?: string | null;
      } | null;
    } | null> | null;
    measurements?: Array<{
      __typename: 'AssessmentItemQuestionnaireMeasurements';
      uuid?: string | null;
      unitOfMeasurement?: string | null;
      calculationType?: string | null;
      measurementRanges?: Array<{
        __typename: 'AssessmentItemQuestionnaireMeasurementRanges';
        unit?: string | null;
        label?: string | null;
        ranges?: Array<{
          __typename: 'MeasurementRanges';
          jumpTo?: string | null;
          range?: string | null;
          number?: string | null;
        }> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
  icon?: { __typename: 'Image'; url: string } | null;
  answers?: Array<{
    __typename: 'AssessmentItemAnswers';
    optionUuids?: Array<string> | null;
    questionUuid: string;
    type?: string | null;
    measurements?: Array<{
      __typename: 'AssessmentItemAnswersMeasurements';
      measurementUuid: string;
      measurementType?: string | null;
      calculationType?: string | null;
      unit?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
  remainingAssessmentsCompletionPoints?: {
    __typename: 'RemainingAssessmentsCompletionPoints';
    type?: AssessmentGroupType | null;
    count?: number | null;
    bonusPoints?: number | null;
    resetDate?: string | null;
  } | null;
};

export type AssessmentHeroCardsQueryVariables = Exact<{ [key: string]: never }>;

export type AssessmentHeroCardsQuery = {
  __typename: 'Query';
  assessmentHeroCards?: Array<
    | {
        __typename: 'HealthRiskAssessmentCard';
        completedAt?: string | null;
        status: AssessmentStatus;
        title: string;
        tierPoints?: number | null;
        numberOfAssessments: number;
        assessmentsRemaining: number;
        icon?: { __typename: 'Image'; url: string } | null;
        nextAssessment: {
          __typename: 'AssessmentListItem';
          title: string;
          status: AssessmentStatus;
          numberOfQuestions: number;
          percentageComplete: number;
          uuid: string;
          result?: {
            __typename: 'AssessmentListItemResult';
            percentageScore: number;
          } | null;
          type: {
            __typename: 'AssessmentType';
            id: string;
            title: string;
            description?: string | null;
            filter?: string | null;
          };
        };
      }
    | {
        __typename: 'SingleAssessmentCard';
        completedAt?: string | null;
        status: AssessmentStatus;
        title: string;
        tierPoints?: number | null;
        icon?: { __typename: 'Image'; url: string } | null;
        assessment: {
          __typename: 'AssessmentListItem';
          title: string;
          status: AssessmentStatus;
          numberOfQuestions: number;
          percentageComplete: number;
          uuid: string;
          result?: {
            __typename: 'AssessmentListItemResult';
            percentageScore: number;
          } | null;
          type: {
            __typename: 'AssessmentType';
            id: string;
            title: string;
            description?: string | null;
            filter?: string | null;
          };
        };
      }
    | {
        __typename: 'TotalHealthIndexCard';
        title: string;
        subtitle: string;
        status: AssessmentStatus;
        tierPoints?: number | null;
        nextPillar?: {
          __typename: 'AssessmentListItem';
          uuid: string;
          title: string;
        } | null;
        score: {
          __typename: 'TotalHealthIndexScore';
          percentage: number;
          bracket: {
            __typename: 'TotalHealthIndexBracket';
            bracketId: string;
            description?: string | null;
            color: { __typename: 'Color'; hexCode: string };
          };
        };
      }
  > | null;
};

export type AssessmentContentLanguageMessageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AssessmentContentLanguageMessageQuery = {
  __typename: 'Query';
  assessmentContentLanguageMessage?: {
    __typename: 'WellbeingContentLanguageMessageResponse';
    banner?:
      | {
          __typename: 'WellbeingContentLanguageMessageChangeLanguage';
          section: WellbeingContentLanguageMessageSection;
          bannerText: string;
          bannerTextPopUp: string;
        }
      | {
          __typename: 'WellbeingContentLanguageMessageNoChoice';
          section: WellbeingContentLanguageMessageSection;
          bannerText: string;
          bannerTextPopUp: string;
        }
      | {
          __typename: 'WellbeingContentLanguageMessageNoContent';
          section: WellbeingContentLanguageMessageSection;
          bannerText: string;
        }
      | null;
  } | null;
};

export type AssessmentsHubQueryVariables = Exact<{
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AssessmentsHubQuery = {
  __typename: 'Query';
  allAssessments?: {
    __typename: 'FetchAssessmentsResponse';
    contentLanguage?: string | null;
    translatedGlobalContentExists: boolean;
    assessments?: Array<{
      __typename: 'AssessmentListItem';
      tierPoints?: number | null;
      uuid: string;
      localisedUuid: string;
      version?: string | null;
      title: string;
      introduction: string;
      numberOfQuestions: number;
      status: AssessmentStatus;
      percentageComplete: number;
      nextQuestionReference?: string | null;
      canBeRetaken?: boolean | null;
      cooldownPeriodExpirationDate?: string | null;
      result?: {
        __typename: 'AssessmentListItemResult';
        digestTitle: string;
        digest?: string | null;
        minScaleLabel: string;
        maxScaleLabel: string;
        percentageScore: number;
        shouldDisplayPercentage?: boolean | null;
        score: number;
      } | null;
      type: {
        __typename: 'AssessmentType';
        description?: string | null;
        id: string;
        title: string;
      };
      questionnaire?: Array<{
        __typename: 'AssessmentItemQuestionnaire';
        reference?: string | null;
        uuid: string;
        multipleChoice?: boolean | null;
        questionType?: QuestionnaireQuestionType | null;
        introduction: string;
        options?: Array<{
          __typename: 'AssessmentItemQuestionnaireOptionsList';
          label?: string | null;
          priority?: boolean | null;
          jumpTo?: string | null;
          uuid: string;
          alert?: {
            __typename: 'AssessmentItemQuestionnaireOptionAlert';
            message?: string | null;
          } | null;
        } | null> | null;
        measurements?: Array<{
          __typename: 'AssessmentItemQuestionnaireMeasurements';
          uuid?: string | null;
          unitOfMeasurement?: string | null;
          calculationType?: string | null;
          measurementRanges?: Array<{
            __typename: 'AssessmentItemQuestionnaireMeasurementRanges';
            unit?: string | null;
            label?: string | null;
            ranges?: Array<{
              __typename: 'MeasurementRanges';
              jumpTo?: string | null;
              range?: string | null;
              number?: string | null;
            }> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
      icon?: { __typename: 'Image'; url: string } | null;
      answers?: Array<{
        __typename: 'AssessmentItemAnswers';
        optionUuids?: Array<string> | null;
        questionUuid: string;
        type?: string | null;
        measurements?: Array<{
          __typename: 'AssessmentItemAnswersMeasurements';
          measurementUuid: string;
          measurementType?: string | null;
          calculationType?: string | null;
          unit?: string | null;
          value?: string | null;
        } | null> | null;
      } | null> | null;
      remainingAssessmentsCompletionPoints?: {
        __typename: 'RemainingAssessmentsCompletionPoints';
        type?: AssessmentGroupType | null;
        count?: number | null;
        bonusPoints?: number | null;
        resetDate?: string | null;
      } | null;
    }> | null;
  } | null;
  assessmentTypeFilters?: Array<{
    __typename: 'AssessmentTypeFilter';
    typeId: string;
    name: string;
    count: number;
  }> | null;
};

export type PointsModalQueryVariables = Exact<{ [key: string]: never }>;

export type PointsModalQuery = {
  __typename: 'Query';
  assessmentTierPoints: {
    __typename: 'AssessmentTierPoints';
    assessmentCooldownFrequency?: string | null;
    pointsForAnAssessment?: number | null;
    pointsForHRA?: number | null;
    pointsForTHI?: number | null;
  };
};

export type GetPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPermissionsQuery = {
  __typename: 'Query';
  howDoesPersonalisationWork?: {
    __typename: 'HowDoesPersonalisationWorkResponse';
    permission?: {
      __typename: 'HowPersonalisationWorksPermission';
      allowPersonalisation?: boolean | null;
      body: string;
      footer?: string | null;
      subtitle: string;
      title: string;
    } | null;
  } | null;
};

export type CompleteCustomTierActivityMutationVariables = Exact<{
  request?: InputMaybe<FindCustomTierActivityInput>;
}>;

export type CompleteCustomTierActivityMutation = {
  __typename: 'Mutation';
  completeCustomTierActivity?: {
    __typename: 'CompletedCustomTierActivity';
    customTierActivity: {
      __typename: 'CustomTierActivity';
      activityId: string;
      title: string;
      description?: string | null;
      status: CustomTierActivityStatus;
      tierPointAward?: {
        __typename: 'TierPointAward';
        frequency: TierPointFrequencyType;
        points: number;
      } | null;
      image?: { __typename: 'Image'; url: string } | null;
    };
    currentTier?: {
      __typename: 'CurrentTier';
      id?: string | null;
      name: string;
      rank: number;
      motivationalMessage: string;
    } | null;
    nextTier?: {
      __typename: 'NextTier';
      id?: string | null;
      name: string;
      rank?: number | null;
      meetsRequirementsForTier?: boolean | null;
    } | null;
    tierPoints?: {
      __typename: 'TierPoints';
      id?: string | null;
      remainingToReachNextTier?: number | null;
      earned: {
        __typename: 'TierPointsEarned';
        total: number;
        thisMonth: {
          __typename: 'TierPointsEarnedInTimePeriod';
          value?: number | null;
          since?: string | null;
        };
      };
      requiredToNotRegress?: {
        __typename: 'TierPointsRequired';
        total: number;
        remaining: number;
        thisMonth: {
          __typename: 'TierPointsRequiredInTimePeriod';
          value: number;
          remaining: number;
          daysRemaining: number;
        };
      } | null;
    } | null;
  } | null;
};

export type ActivityModalRegressionInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ActivityModalRegressionInfoQuery = {
  __typename: 'Query';
  tierRegressionInfo?: {
    __typename: 'TierRegressionInfo';
    image?: {
      __typename: 'Image';
      url: string;
      font?: {
        __typename: 'Font';
        color?: { __typename: 'Color'; hexCode: string } | null;
      } | null;
    } | null;
    timePeriods: Array<{
      __typename: 'TierRegressionTimePeriodInfo';
      isCurrent?: boolean | null;
      pointsEarned?: number | null;
      pointGoal?: number | null;
      timePeriod?: {
        __typename: 'TimePeriod';
        from: string;
        to: string;
      } | null;
    }>;
  } | null;
};

export type CustomActivityModalQueryVariables = Exact<{ [key: string]: never }>;

export type CustomActivityModalQuery = {
  __typename: 'Query';
  currentTier?: {
    __typename: 'CurrentTier';
    id?: string | null;
    name: string;
    rank: number;
    motivationalMessage: string;
  } | null;
  nextTier?: {
    __typename: 'NextTier';
    id?: string | null;
    name: string;
    rank?: number | null;
    meetsRequirementsForTier?: boolean | null;
  } | null;
  allTiers?: Array<{
    __typename: 'TierDefinition';
    name: string;
    rank: number;
    points: number;
  }> | null;
  tierPoints?: {
    __typename: 'TierPoints';
    id?: string | null;
    remainingToReachNextTier?: number | null;
    earned: {
      __typename: 'TierPointsEarned';
      total: number;
      thisMonth: {
        __typename: 'TierPointsEarnedInTimePeriod';
        value?: number | null;
        since?: string | null;
      };
    };
    requiredToNotRegress?: {
      __typename: 'TierPointsRequired';
      total: number;
      remaining: number;
      thisMonth: {
        __typename: 'TierPointsRequiredInTimePeriod';
        value: number;
        remaining: number;
        daysRemaining: number;
      };
    } | null;
  } | null;
};

export type FeedbackScreenQueryVariables = Exact<{ [key: string]: never }>;

export type FeedbackScreenQuery = {
  __typename: 'Query';
  customTierActivities?: Array<{
    __typename: 'CustomTierActivity';
    activityId: string;
    title: string;
    description?: string | null;
    status: CustomTierActivityStatus;
    tierPointAward?: {
      __typename: 'TierPointAward';
      frequency: TierPointFrequencyType;
      points: number;
    } | null;
    image?: { __typename: 'Image'; url: string } | null;
  }> | null;
  tierPoints?: {
    __typename: 'TierPoints';
    id?: string | null;
    remainingToReachNextTier?: number | null;
    earned: {
      __typename: 'TierPointsEarned';
      total: number;
      thisMonth: {
        __typename: 'TierPointsEarnedInTimePeriod';
        value?: number | null;
        since?: string | null;
      };
    };
    requiredToNotRegress?: {
      __typename: 'TierPointsRequired';
      total: number;
      remaining: number;
      thisMonth: {
        __typename: 'TierPointsRequiredInTimePeriod';
        value: number;
        remaining: number;
        daysRemaining: number;
      };
    } | null;
  } | null;
  nextTier?: {
    __typename: 'NextTier';
    id?: string | null;
    name: string;
    rank?: number | null;
    meetsRequirementsForTier?: boolean | null;
  } | null;
  currentTier?: {
    __typename: 'CurrentTier';
    id?: string | null;
    name: string;
    rank: number;
    motivationalMessage: string;
  } | null;
  allTiers?: Array<{
    __typename: 'TierDefinition';
    name: string;
    rank: number;
    points: number;
  }> | null;
};

export type TierRewardsPageQueryVariables = Exact<{ [key: string]: never }>;

export type TierRewardsPageQuery = {
  __typename: 'Query';
  allTiers?: Array<{
    __typename: 'TierDefinition';
    name: string;
    rank: number;
    points: number;
  }> | null;
};

export type PaginatedTieringActivitiesQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type PaginatedTieringActivitiesQuery = {
  __typename: 'Query';
  allTierActivities?: {
    __typename: 'PaginatedTierActivities';
    data?: Array<{
      __typename: 'TierActivity';
      icon: string;
      description: string;
      pointsEarned?: number | null;
      category?: string | null;
      completedAt: string;
      additionalInformation?: string | null;
    }> | null;
    paging: {
      __typename: 'Pagination';
      isLastPage: boolean;
      cursors: { __typename: 'PaginationCursors'; after?: string | null };
    };
  } | null;
};

export type CustomTieringActivityListItemFragment = {
  __typename: 'CustomTierActivity';
  activityId: string;
  title: string;
  description?: string | null;
  status: CustomTierActivityStatus;
  tierPointAward?: {
    __typename: 'TierPointAward';
    frequency: TierPointFrequencyType;
    points: number;
  } | null;
  image?: { __typename: 'Image'; url: string } | null;
};

export type TieringHubTierPointsFragment = {
  __typename: 'TierPoints';
  id?: string | null;
  remainingToReachNextTier?: number | null;
  earned: {
    __typename: 'TierPointsEarned';
    total: number;
    thisMonth: {
      __typename: 'TierPointsEarnedInTimePeriod';
      value?: number | null;
      since?: string | null;
    };
  };
  requiredToNotRegress?: {
    __typename: 'TierPointsRequired';
    total: number;
    remaining: number;
    thisMonth: {
      __typename: 'TierPointsRequiredInTimePeriod';
      value: number;
      remaining: number;
      daysRemaining: number;
    };
  } | null;
};

export type TieringHubNextTierFragment = {
  __typename: 'NextTier';
  id?: string | null;
  name: string;
  rank?: number | null;
  meetsRequirementsForTier?: boolean | null;
};

export type TieringHubCurrentTierFragment = {
  __typename: 'CurrentTier';
  id?: string | null;
  name: string;
  rank: number;
  motivationalMessage: string;
};

export type TieringHubAllTiersFragment = {
  __typename: 'TierDefinition';
  name: string;
  rank: number;
  points: number;
};

export type TierPointRelatedDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TierPointRelatedDataQuery = {
  __typename: 'Query';
  tierPoints?: {
    __typename: 'TierPoints';
    id?: string | null;
    remainingToReachNextTier?: number | null;
    earned: {
      __typename: 'TierPointsEarned';
      total: number;
      thisMonth: {
        __typename: 'TierPointsEarnedInTimePeriod';
        value?: number | null;
        since?: string | null;
      };
    };
    requiredToNotRegress?: {
      __typename: 'TierPointsRequired';
      total: number;
      remaining: number;
      thisMonth: {
        __typename: 'TierPointsRequiredInTimePeriod';
        value: number;
        remaining: number;
        daysRemaining: number;
      };
    } | null;
  } | null;
  nextTier?: {
    __typename: 'NextTier';
    id?: string | null;
    name: string;
    rank?: number | null;
    meetsRequirementsForTier?: boolean | null;
  } | null;
  currentTier?: {
    __typename: 'CurrentTier';
    id?: string | null;
    name: string;
    rank: number;
    motivationalMessage: string;
  } | null;
};

export type TierRegressionInfoFragment = {
  __typename: 'TierRegressionInfo';
  image?: {
    __typename: 'Image';
    url: string;
    font?: {
      __typename: 'Font';
      color?: { __typename: 'Color'; hexCode: string } | null;
    } | null;
  } | null;
  timePeriods: Array<{
    __typename: 'TierRegressionTimePeriodInfo';
    isCurrent?: boolean | null;
    pointsEarned?: number | null;
    pointGoal?: number | null;
    timePeriod?: { __typename: 'TimePeriod'; from: string; to: string } | null;
  }>;
};

export type TieringHubQueryVariables = Exact<{ [key: string]: never }>;

export type TieringHubQuery = {
  __typename: 'Query';
  customTierActivities?: Array<{
    __typename: 'CustomTierActivity';
    activityId: string;
    title: string;
    description?: string | null;
    status: CustomTierActivityStatus;
    tierPointAward?: {
      __typename: 'TierPointAward';
      frequency: TierPointFrequencyType;
      points: number;
    } | null;
    image?: { __typename: 'Image'; url: string } | null;
  }> | null;
  tierPoints?: {
    __typename: 'TierPoints';
    id?: string | null;
    remainingToReachNextTier?: number | null;
    earned: {
      __typename: 'TierPointsEarned';
      total: number;
      thisMonth: {
        __typename: 'TierPointsEarnedInTimePeriod';
        value?: number | null;
        since?: string | null;
      };
    };
    requiredToNotRegress?: {
      __typename: 'TierPointsRequired';
      total: number;
      remaining: number;
      thisMonth: {
        __typename: 'TierPointsRequiredInTimePeriod';
        value: number;
        remaining: number;
        daysRemaining: number;
      };
    } | null;
  } | null;
  nextTier?: {
    __typename: 'NextTier';
    id?: string | null;
    name: string;
    rank?: number | null;
    meetsRequirementsForTier?: boolean | null;
  } | null;
  currentTier?: {
    __typename: 'CurrentTier';
    id?: string | null;
    name: string;
    rank: number;
    motivationalMessage: string;
  } | null;
  allTiers?: Array<{
    __typename: 'TierDefinition';
    name: string;
    rank: number;
    points: number;
  }> | null;
  tierRegressionInfo?: {
    __typename: 'TierRegressionInfo';
    image?: {
      __typename: 'Image';
      url: string;
      font?: {
        __typename: 'Font';
        color?: { __typename: 'Color'; hexCode: string } | null;
      } | null;
    } | null;
    timePeriods: Array<{
      __typename: 'TierRegressionTimePeriodInfo';
      isCurrent?: boolean | null;
      pointsEarned?: number | null;
      pointGoal?: number | null;
      timePeriod?: {
        __typename: 'TimePeriod';
        from: string;
        to: string;
      } | null;
    }>;
  } | null;
};

export type UpdatePersonalisationPermissionMutationVariables = Exact<{
  allowPersonalisation: Scalars['Boolean']['input'];
}>;

export type UpdatePersonalisationPermissionMutation = {
  __typename: 'Mutation';
  updatePersonalisationPermission?: {
    __typename: 'UpdatePersonalisationPermissionResponse';
    permission?: {
      __typename: 'HowPersonalisationWorksPermission';
      allowPersonalisation?: boolean | null;
      body: string;
      footer?: string | null;
      subtitle: string;
      title: string;
    } | null;
  } | null;
};

export type JoinDigitalClinicalProgramMutationVariables = Exact<{
  joinProgramInput: JoinDigitalClinicalProgramInput;
}>;

export type JoinDigitalClinicalProgramMutation = {
  __typename: 'Mutation';
  joinDigitalClinicalProgram?: boolean | null;
};

export type RecommendedArticlesQueryVariables = Exact<{ [key: string]: never }>;

export type RecommendedArticlesQuery = {
  __typename: 'Query';
  recommendedArticles?: {
    __typename: 'RecommendedArticlesResponse';
    recommendations?: Array<{
      __typename: 'Article';
      body: string;
      categoryTitle?: string | null;
      contentItemId: string;
      lastModificationDate?: string | null;
      title: string;
      url?: string | null;
      author?: { __typename: 'ArticleAuthor'; name: string } | null;
      heroImage?: { __typename: 'Image'; url: string } | null;
    }> | null;
  } | null;
  howDoesPersonalisationWork?: {
    __typename: 'HowDoesPersonalisationWorkResponse';
    body: string;
    button: string;
    title: string;
    icon?: { __typename: 'Image'; url: string } | null;
    permission?: {
      __typename: 'HowPersonalisationWorksPermission';
      allowPersonalisation?: boolean | null;
      body: string;
      footer?: string | null;
      subtitle: string;
      title: string;
    } | null;
  } | null;
};

export type FetchFeaturedChallengesQueryVariables = Exact<{
  from?: InputMaybe<Scalars['GraphQLDate']['input']>;
}>;

export type FetchFeaturedChallengesQuery = {
  __typename: 'Query';
  featuredChallenges?: {
    __typename: 'fetchFeaturedChallengesResponse';
    challenges?: Array<
      | {
          __typename: 'HabitChallenge';
          participationType: ChallengeParticipationType;
          challengeId: string;
          category: ChallengeCategory;
          title: string;
          status: ChallengeStatus;
          goal: number;
          wasJoined: boolean;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          activity: {
            __typename: 'HabitActivityResponse';
            activity?: Array<{
              __typename: 'HabitDailyActivity';
              tracked: number;
            }> | null;
          };
        }
      | {
          __typename: 'StepChallenge';
          participationType: ChallengeParticipationType;
          challengeId: string;
          category: ChallengeCategory;
          title: string;
          status: ChallengeStatus;
          goal: number;
          wasJoined: boolean;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          stepActivity: {
            __typename: 'StepActivityResponse';
            activity?: Array<{
              __typename: 'StepDailyActivity';
              tracked: number;
            }> | null;
          };
        }
    > | null;
  } | null;
};

export type WalkthroughFieldsFragment = {
  __typename: 'Walkthrough';
  wasCompleted: boolean;
  steps: Array<{
    __typename: 'WalkthroughStep';
    title: string;
    text: string;
    image?: { __typename: 'Image'; url: string } | null;
  }>;
};

export type ProgramListFieldsFragment = {
  __typename: 'DigitalClinicalProgramListItem';
  description: string;
  wasJoined: boolean;
  programId: string;
  title: string;
  status: DigitalClinicalProgramStatus;
  icon?: { __typename: 'Image'; url: string } | null;
  progress: {
    __typename: 'DigitalClinicalProgramProgress';
    percentageComplete: number;
  };
  nextModule?: {
    __typename: 'DigitalClinicalModuleListItem';
    moduleId: string;
    title: string;
  } | null;
};

export type ProgramFieldsFragment = {
  __typename: 'DigitalClinicalProgramListItem';
  programId: string;
  wasJoined: boolean;
  title: string;
  status: DigitalClinicalProgramStatus;
  completedAt?: string | null;
  icon?: { __typename: 'Image'; url: string } | null;
  progress: {
    __typename: 'DigitalClinicalProgramProgress';
    totalModules: number;
    completedModules: number;
    percentageComplete: number;
  };
  nextModule?: {
    __typename: 'DigitalClinicalModuleListItem';
    moduleId: string;
    title: string;
  } | null;
};

export type SingleAssessmentsCardFieldsFragment = {
  __typename: 'SingleAssessmentCard';
  completedAt?: string | null;
  status: AssessmentStatus;
  title: string;
  tierPoints?: number | null;
  icon?: { __typename: 'Image'; url: string } | null;
  assessment: {
    __typename: 'AssessmentListItem';
    title: string;
    status: AssessmentStatus;
    numberOfQuestions: number;
    percentageComplete: number;
    uuid: string;
    tierPoints?: number | null;
    result?: {
      __typename: 'AssessmentListItemResult';
      percentageScore: number;
    } | null;
    icon?: { __typename: 'Image'; url: string } | null;
  };
};

export type ThiCardFieldsFragment = {
  __typename: 'TotalHealthIndexCard';
  title: string;
  subtitle: string;
  status: AssessmentStatus;
  tierPoints?: number | null;
  nextPillar?: {
    __typename: 'AssessmentListItem';
    uuid: string;
    title: string;
  } | null;
  score: {
    __typename: 'TotalHealthIndexScore';
    percentage: number;
    bracket: {
      __typename: 'TotalHealthIndexBracket';
      bracketId: string;
      description?: string | null;
      color: { __typename: 'Color'; hexCode: string };
    };
  };
};

export type HraCardFieldsFragment = {
  __typename: 'HealthRiskAssessmentCard';
  completedAt?: string | null;
  status: AssessmentStatus;
  title: string;
  tierPoints?: number | null;
  numberOfAssessments: number;
  assessmentsRemaining: number;
  icon?: { __typename: 'Image'; url: string } | null;
  nextAssessment: {
    __typename: 'AssessmentListItem';
    title: string;
    status: AssessmentStatus;
    numberOfQuestions: number;
    percentageComplete: number;
    uuid: string;
    result?: {
      __typename: 'AssessmentListItemResult';
      percentageScore: number;
    } | null;
  };
};

export type FetchAssessmentHeroCardsQueryVariables = Exact<{
  requestGlobalContent?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FetchAssessmentHeroCardsQuery = {
  __typename: 'Query';
  assessmentHeroCards?: Array<
    | {
        __typename: 'HealthRiskAssessmentCard';
        completedAt?: string | null;
        status: AssessmentStatus;
        title: string;
        tierPoints?: number | null;
        numberOfAssessments: number;
        assessmentsRemaining: number;
        icon?: { __typename: 'Image'; url: string } | null;
        nextAssessment: {
          __typename: 'AssessmentListItem';
          title: string;
          status: AssessmentStatus;
          numberOfQuestions: number;
          percentageComplete: number;
          uuid: string;
          result?: {
            __typename: 'AssessmentListItemResult';
            percentageScore: number;
          } | null;
        };
      }
    | {
        __typename: 'SingleAssessmentCard';
        completedAt?: string | null;
        status: AssessmentStatus;
        title: string;
        tierPoints?: number | null;
        icon?: { __typename: 'Image'; url: string } | null;
        assessment: {
          __typename: 'AssessmentListItem';
          title: string;
          status: AssessmentStatus;
          numberOfQuestions: number;
          percentageComplete: number;
          uuid: string;
          tierPoints?: number | null;
          result?: {
            __typename: 'AssessmentListItemResult';
            percentageScore: number;
          } | null;
          icon?: { __typename: 'Image'; url: string } | null;
        };
      }
    | {
        __typename: 'TotalHealthIndexCard';
        title: string;
        subtitle: string;
        status: AssessmentStatus;
        tierPoints?: number | null;
        nextPillar?: {
          __typename: 'AssessmentListItem';
          uuid: string;
          title: string;
        } | null;
        score: {
          __typename: 'TotalHealthIndexScore';
          percentage: number;
          bracket: {
            __typename: 'TotalHealthIndexBracket';
            bracketId: string;
            description?: string | null;
            color: { __typename: 'Color'; hexCode: string };
          };
        };
      }
  > | null;
};

export type HraPromotionFieldsFragment = {
  __typename: 'HraPromotion';
  title: string;
  image?: { __typename: 'Image'; url: string } | null;
  icon?: { __typename: 'Image'; url: string } | null;
  assessment?: {
    __typename: 'FeaturedAssessment';
    completedAt?: string | null;
    status: AssessmentStatus;
    title: string;
    tierPoints?: number | null;
    numberOfAssessments?: number | null;
    assessmentsRemaining?: number | null;
    icon?: { __typename: 'Image'; url: string } | null;
    assessment: {
      __typename: 'AssessmentListItem';
      title: string;
      status: AssessmentStatus;
      numberOfQuestions: number;
      percentageComplete: number;
      uuid: string;
      result?: {
        __typename: 'AssessmentListItemResult';
        percentageScore: number;
      } | null;
    };
  } | null;
};

export type FetchHraPromotionQueryVariables = Exact<{ [key: string]: never }>;

export type FetchHraPromotionQuery = {
  __typename: 'Query';
  hraPromotion?: {
    __typename: 'HraPromotion';
    title: string;
    image?: { __typename: 'Image'; url: string } | null;
    icon?: { __typename: 'Image'; url: string } | null;
    assessment?: {
      __typename: 'FeaturedAssessment';
      completedAt?: string | null;
      status: AssessmentStatus;
      title: string;
      tierPoints?: number | null;
      numberOfAssessments?: number | null;
      assessmentsRemaining?: number | null;
      icon?: { __typename: 'Image'; url: string } | null;
      assessment: {
        __typename: 'AssessmentListItem';
        title: string;
        status: AssessmentStatus;
        numberOfQuestions: number;
        percentageComplete: number;
        uuid: string;
        result?: {
          __typename: 'AssessmentListItemResult';
          percentageScore: number;
        } | null;
      };
    } | null;
  } | null;
};

export type FetchWellbeingPromotionsQueryVariables = Exact<{
  from?: InputMaybe<Scalars['GraphQLDate']['input']>;
}>;

export type FetchWellbeingPromotionsQuery = {
  __typename: 'Query';
  wellbeingPromotions?: Array<
    | { __typename: 'AssessmentPromotion' }
    | {
        __typename: 'ChallengePromotion';
        challengeUnion?:
          | {
              __typename: 'HabitChallenge';
              participationType: ChallengeParticipationType;
              challengeId: string;
              category: ChallengeCategory;
              title: string;
              status: ChallengeStatus;
              goal: number;
              wasJoined: boolean;
              endDateTimeISO?: string | null;
              startDateTimeISO?: string | null;
              image?: { __typename: 'Image'; url: string } | null;
              completionReward?: {
                __typename: 'TierReward';
                points: string;
              } | null;
              activity: {
                __typename: 'HabitActivityResponse';
                activity?: Array<{
                  __typename: 'HabitDailyActivity';
                  tracked: number;
                }> | null;
              };
            }
          | {
              __typename: 'StepChallenge';
              participationType: ChallengeParticipationType;
              challengeId: string;
              category: ChallengeCategory;
              title: string;
              status: ChallengeStatus;
              goal: number;
              wasJoined: boolean;
              endDateTimeISO?: string | null;
              startDateTimeISO?: string | null;
              image?: { __typename: 'Image'; url: string } | null;
              completionReward?: {
                __typename: 'TierReward';
                points: string;
              } | null;
              stepActivity: {
                __typename: 'StepActivityResponse';
                activity?: Array<{
                  __typename: 'StepDailyActivity';
                  tracked: number;
                }> | null;
              };
            }
          | null;
      }
    | { __typename: 'HraPromotion' }
    | { __typename: 'TotalHealthIndexPromotion' }
  > | null;
};

export type FetchWalkthroughQueryVariables = Exact<{ [key: string]: never }>;

export type FetchWalkthroughQuery = {
  __typename: 'Query';
  walkthrough: {
    __typename: 'Walkthrough';
    wasCompleted: boolean;
    steps: Array<{
      __typename: 'WalkthroughStep';
      title: string;
      text: string;
      image?: { __typename: 'Image'; url: string } | null;
    }>;
  };
};

export type FetchFeaturedProgramsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchFeaturedProgramsQuery = {
  __typename: 'Query';
  featuredPrograms?: Array<{
    __typename: 'DigitalClinicalProgramListItem';
    programId: string;
    wasJoined: boolean;
    title: string;
    status: DigitalClinicalProgramStatus;
    completedAt?: string | null;
    icon?: { __typename: 'Image'; url: string } | null;
    progress: {
      __typename: 'DigitalClinicalProgramProgress';
      totalModules: number;
      completedModules: number;
      percentageComplete: number;
    };
    nextModule?: {
      __typename: 'DigitalClinicalModuleListItem';
      moduleId: string;
      title: string;
    } | null;
  }> | null;
};

export type RecommendationFieldsFragment = {
  __typename: 'Article';
  body: string;
  categoryTitle?: string | null;
  contentItemId: string;
  lastModificationDate?: string | null;
  title: string;
  url?: string | null;
  author?: { __typename: 'ArticleAuthor'; name: string } | null;
  heroImage?: { __typename: 'Image'; url: string } | null;
};

export type PersonalisationPermissionFragment = {
  __typename: 'HowPersonalisationWorksPermission';
  allowPersonalisation?: boolean | null;
  body: string;
  footer?: string | null;
  subtitle: string;
  title: string;
};

export type HowDoesPersonalisationWorkFragment = {
  __typename: 'HowDoesPersonalisationWorkResponse';
  body: string;
  button: string;
  title: string;
  icon?: { __typename: 'Image'; url: string } | null;
  permission?: {
    __typename: 'HowPersonalisationWorksPermission';
    allowPersonalisation?: boolean | null;
    body: string;
    footer?: string | null;
    subtitle: string;
    title: string;
  } | null;
};

export type WellbeingContentLanguageMessageFragment = {
  __typename: 'WellbeingContentLanguageMessageResponse';
  banner?:
    | {
        __typename: 'WellbeingContentLanguageMessageChangeLanguage';
        section: WellbeingContentLanguageMessageSection;
        bannerText: string;
        bannerTextPopUp: string;
      }
    | {
        __typename: 'WellbeingContentLanguageMessageNoChoice';
        section: WellbeingContentLanguageMessageSection;
        bannerText: string;
        bannerTextPopUp: string;
      }
    | {
        __typename: 'WellbeingContentLanguageMessageNoContent';
        section: WellbeingContentLanguageMessageSection;
        bannerText: string;
      }
    | null;
};

export type FetchWellbeingContentLanguageMessageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchWellbeingContentLanguageMessageQuery = {
  __typename: 'Query';
  assessmentContentLanguageMessage?: {
    __typename: 'WellbeingContentLanguageMessageResponse';
    banner?:
      | {
          __typename: 'WellbeingContentLanguageMessageChangeLanguage';
          section: WellbeingContentLanguageMessageSection;
          bannerText: string;
          bannerTextPopUp: string;
        }
      | {
          __typename: 'WellbeingContentLanguageMessageNoChoice';
          section: WellbeingContentLanguageMessageSection;
          bannerText: string;
          bannerTextPopUp: string;
        }
      | {
          __typename: 'WellbeingContentLanguageMessageNoContent';
          section: WellbeingContentLanguageMessageSection;
          bannerText: string;
        }
      | null;
  } | null;
};

export type FetchRecommendedArticlesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchRecommendedArticlesQuery = {
  __typename: 'Query';
  howDoesPersonalisationWork?: {
    __typename: 'HowDoesPersonalisationWorkResponse';
    body: string;
    button: string;
    title: string;
    icon?: { __typename: 'Image'; url: string } | null;
    permission?: {
      __typename: 'HowPersonalisationWorksPermission';
      allowPersonalisation?: boolean | null;
      body: string;
      footer?: string | null;
      subtitle: string;
      title: string;
    } | null;
  } | null;
  recommendedArticles?: {
    __typename: 'RecommendedArticlesResponse';
    recommendations?: Array<{
      __typename: 'Article';
      body: string;
      categoryTitle?: string | null;
      contentItemId: string;
      lastModificationDate?: string | null;
      title: string;
      url?: string | null;
      author?: { __typename: 'ArticleAuthor'; name: string } | null;
      heroImage?: { __typename: 'Image'; url: string } | null;
    }> | null;
  } | null;
};

export type WellBeingHubQueryVariables = Exact<{
  from?: InputMaybe<Scalars['GraphQLDate']['input']>;
}>;

export type WellBeingHubQuery = {
  __typename: 'Query';
  currentTier?: {
    __typename: 'CurrentTier';
    rank: number;
    name: string;
  } | null;
  tierPoints?: {
    __typename: 'TierPoints';
    remainingToReachNextTier?: number | null;
    earned: { __typename: 'TierPointsEarned'; total: number };
  } | null;
  nextTier?: { __typename: 'NextTier'; name: string } | null;
  allChallenges?: {
    __typename: 'fetchChallengesResponse';
    challenges?: Array<
      | {
          __typename: 'HabitChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          category: ChallengeCategory;
          wasJoined: boolean;
          categoryDisplayText: string;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          activity: {
            __typename: 'HabitActivityResponse';
            activity?: Array<{
              __typename: 'HabitDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
      | {
          __typename: 'StepChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          lastUploaded?: string | null;
          category: ChallengeCategory;
          wasJoined: boolean;
          categoryDisplayText: string;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          stepActivity: {
            __typename: 'StepActivityResponse';
            activity?: Array<{
              __typename: 'StepDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
    > | null;
  } | null;
  programsList?: Array<{
    __typename: 'DigitalClinicalProgramListItem';
    description: string;
    wasJoined: boolean;
    programId: string;
    title: string;
    status: DigitalClinicalProgramStatus;
    icon?: { __typename: 'Image'; url: string } | null;
    progress: {
      __typename: 'DigitalClinicalProgramProgress';
      percentageComplete: number;
    };
    nextModule?: {
      __typename: 'DigitalClinicalModuleListItem';
      moduleId: string;
      title: string;
    } | null;
  }> | null;
};

export type TieringQueryVariables = Exact<{ [key: string]: never }>;

export type TieringQuery = {
  __typename: 'Query';
  currentTier?: {
    __typename: 'CurrentTier';
    rank: number;
    name: string;
  } | null;
  tierPoints?: {
    __typename: 'TierPoints';
    earned: { __typename: 'TierPointsEarned'; total: number };
  } | null;
  allTiers?: Array<{
    __typename: 'TierDefinition';
    name: string;
    points: number;
    rank: number;
  }> | null;
};

export type RecommendedResourcesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RecommendedResourcesQuery = {
  __typename: 'Query';
  recommendedArticles?: {
    __typename: 'RecommendedArticlesResponse';
    recommendations?: Array<{
      __typename: 'Article';
      title: string;
      lastModificationDate?: string | null;
      url?: string | null;
      categoryTitle?: string | null;
      author?: {
        __typename: 'ArticleAuthor';
        name: string;
        surname: string;
      } | null;
      heroImage?: { __typename: 'Image'; url: string } | null;
      audios?: Array<{ __typename: 'ArticleAudio' }> | null;
      videos?: Array<{ __typename: 'ArticleVideo' }> | null;
    }> | null;
  } | null;
  howDoesPersonalisationWork?: {
    __typename: 'HowDoesPersonalisationWorkResponse';
    body: string;
    button: string;
    title: string;
    icon?: { __typename: 'Image'; url: string } | null;
    permission?: {
      __typename: 'HowPersonalisationWorksPermission';
      allowPersonalisation?: boolean | null;
      body: string;
      footer?: string | null;
      subtitle: string;
      title: string;
    } | null;
  } | null;
};

export type ChallengesOverviewQueryVariables = Exact<{
  from?: InputMaybe<Scalars['GraphQLDate']['input']>;
}>;

export type ChallengesOverviewQuery = {
  __typename: 'Query';
  joinedChallenges?: {
    __typename: 'fetchChallengesResponse';
    challenges?: Array<
      | {
          __typename: 'HabitChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          category: ChallengeCategory;
          wasJoined: boolean;
          categoryDisplayText: string;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          activity: {
            __typename: 'HabitActivityResponse';
            activity?: Array<{
              __typename: 'HabitDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
      | {
          __typename: 'StepChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          lastUploaded?: string | null;
          category: ChallengeCategory;
          wasJoined: boolean;
          categoryDisplayText: string;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          stepActivity: {
            __typename: 'StepActivityResponse';
            activity?: Array<{
              __typename: 'StepDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
    > | null;
  } | null;
  notJoinedChallenges?: {
    __typename: 'fetchChallengesResponse';
    challenges?: Array<
      | {
          __typename: 'HabitChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          category: ChallengeCategory;
          wasJoined: boolean;
          categoryDisplayText: string;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          activity: {
            __typename: 'HabitActivityResponse';
            activity?: Array<{
              __typename: 'HabitDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
      | {
          __typename: 'StepChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          lastUploaded?: string | null;
          category: ChallengeCategory;
          wasJoined: boolean;
          categoryDisplayText: string;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          stepActivity: {
            __typename: 'StepActivityResponse';
            activity?: Array<{
              __typename: 'StepDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
    > | null;
  } | null;
  completedChallenges?: {
    __typename: 'fetchChallengesResponse';
    challenges?: Array<
      | {
          __typename: 'HabitChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          category: ChallengeCategory;
          wasJoined: boolean;
          categoryDisplayText: string;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          activity: {
            __typename: 'HabitActivityResponse';
            activity?: Array<{
              __typename: 'HabitDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
      | {
          __typename: 'StepChallenge';
          title: string;
          subtitle?: string | null;
          challengeId: string;
          status: ChallengeStatus;
          goal: number;
          participationType: ChallengeParticipationType;
          lastUploaded?: string | null;
          category: ChallengeCategory;
          wasJoined: boolean;
          categoryDisplayText: string;
          endDateTimeISO?: string | null;
          startDateTimeISO?: string | null;
          image?: { __typename: 'Image'; url: string } | null;
          introduction?: Array<{
            __typename: 'ChallengeIntroduction';
            id: string;
            info: string;
            title: string;
          } | null> | null;
          completionReward?: {
            __typename: 'TierReward';
            points: string;
          } | null;
          stepActivity: {
            __typename: 'StepActivityResponse';
            activity?: Array<{
              __typename: 'StepDailyActivity';
              tracked: number;
            }> | null;
          };
          leaderboardIndividuals: {
            __typename: 'LeaderboardIndividualResponse';
            leaderboard?: Array<{
              __typename: 'IndividualsLeaderboardEntry';
              position?: number | null;
            }> | null;
          };
        }
    > | null;
  } | null;
};

export type WellbeingPromotionsQueryVariables = Exact<{
  from?: InputMaybe<Scalars['GraphQLDate']['input']>;
}>;

export type WellbeingPromotionsQuery = {
  __typename: 'Query';
  wellbeingPromotions?: Array<
    | {
        __typename: 'AssessmentPromotion';
        title: string;
        assessment?: {
          __typename: 'AssessmentListItem';
          uuid: string;
          status: AssessmentStatus;
          title: string;
          numberOfQuestions: number;
          percentageComplete: number;
          tierPoints?: number | null;
          icon?: { __typename: 'Image'; url: string } | null;
        } | null;
      }
    | {
        __typename: 'ChallengePromotion';
        challengeUnion?:
          | {
              __typename: 'HabitChallenge';
              participationType: ChallengeParticipationType;
              challengeId: string;
              category: ChallengeCategory;
              title: string;
              status: ChallengeStatus;
              goal: number;
              wasJoined: boolean;
              endDateTimeISO?: string | null;
              startDateTimeISO?: string | null;
              image?: { __typename: 'Image'; url: string } | null;
              completionReward?: {
                __typename: 'TierReward';
                points: string;
              } | null;
              activity: {
                __typename: 'HabitActivityResponse';
                activity?: Array<{
                  __typename: 'HabitDailyActivity';
                  tracked: number;
                }> | null;
              };
            }
          | {
              __typename: 'StepChallenge';
              participationType: ChallengeParticipationType;
              challengeId: string;
              category: ChallengeCategory;
              title: string;
              status: ChallengeStatus;
              goal: number;
              wasJoined: boolean;
              endDateTimeISO?: string | null;
              startDateTimeISO?: string | null;
              image?: { __typename: 'Image'; url: string } | null;
              completionReward?: {
                __typename: 'TierReward';
                points: string;
              } | null;
              stepActivity: {
                __typename: 'StepActivityResponse';
                activity?: Array<{
                  __typename: 'StepDailyActivity';
                  tracked: number;
                }> | null;
              };
            }
          | null;
      }
    | {
        __typename: 'HraPromotion';
        title: string;
        image?: { __typename: 'Image'; url: string } | null;
        icon?: { __typename: 'Image'; url: string } | null;
        assessment?: {
          __typename: 'FeaturedAssessment';
          completedAt?: string | null;
          status: AssessmentStatus;
          title: string;
          tierPoints?: number | null;
          numberOfAssessments?: number | null;
          assessmentsRemaining?: number | null;
          icon?: { __typename: 'Image'; url: string } | null;
          assessment: {
            __typename: 'AssessmentListItem';
            title: string;
            status: AssessmentStatus;
            numberOfQuestions: number;
            percentageComplete: number;
            uuid: string;
            result?: {
              __typename: 'AssessmentListItemResult';
              percentageScore: number;
            } | null;
          };
        } | null;
      }
    | { __typename: 'TotalHealthIndexPromotion' }
  > | null;
};

export type CareNowProgramsQueryVariables = Exact<{ [key: string]: never }>;

export type CareNowProgramsQuery = {
  __typename: 'Query';
  notJoinedPrograms?: Array<{
    __typename: 'DigitalClinicalProgramListItem';
    programId: string;
    title: string;
    description: string;
    status: DigitalClinicalProgramStatus;
    wasJoined: boolean;
    icon?: { __typename: 'Image'; url: string } | null;
    progress: {
      __typename: 'DigitalClinicalProgramProgress';
      totalModules: number;
      completedModules: number;
      percentageComplete: number;
    };
  }> | null;
  joinedPrograms?: Array<{
    __typename: 'DigitalClinicalProgramListItem';
    programId: string;
    title: string;
    description: string;
    status: DigitalClinicalProgramStatus;
    wasJoined: boolean;
    icon?: { __typename: 'Image'; url: string } | null;
    progress: {
      __typename: 'DigitalClinicalProgramProgress';
      totalModules: number;
      completedModules: number;
      percentageComplete: number;
    };
  }> | null;
  completedPrograms?: Array<{
    __typename: 'DigitalClinicalProgramListItem';
    programId: string;
    title: string;
    description: string;
    status: DigitalClinicalProgramStatus;
    wasJoined: boolean;
    icon?: { __typename: 'Image'; url: string } | null;
    progress: {
      __typename: 'DigitalClinicalProgramProgress';
      totalModules: number;
      completedModules: number;
      percentageComplete: number;
    };
  }> | null;
};

export type BannerFragment = {
  __typename: 'BannerResponse';
  banner: { __typename: 'Banner'; dismissed: boolean; id: BannerId };
};

export type FetchBannerQueryVariables = Exact<{
  bannerId: BannerId;
}>;

export type FetchBannerQuery = {
  __typename: 'Query';
  banner?: {
    __typename: 'BannerResponse';
    banner: { __typename: 'Banner'; dismissed: boolean; id: BannerId };
  } | null;
};

export type DismissBannerMutationVariables = Exact<{
  bannerId: BannerId;
}>;

export type DismissBannerMutation = {
  __typename: 'Mutation';
  markAsDismissed?: {
    __typename: 'BannerResponse';
    banner: { __typename: 'Banner'; dismissed: boolean; id: BannerId };
  } | null;
};

export type TooltipFieldsFragment = {
  __typename: 'Tooltip';
  tooltipId: TooltipId;
  title: string;
  icon?: { __typename: 'Image'; url: string } | null;
};

export type TooltipQueryVariables = Exact<{
  findTooltip: FindTooltipInput;
}>;

export type TooltipQuery = {
  __typename: 'Query';
  tooltip?: {
    __typename: 'Tooltip';
    tooltipId: TooltipId;
    title: string;
    icon?: { __typename: 'Image'; url: string } | null;
  } | null;
};

export type DismissTooltipMutationVariables = Exact<{
  findTooltip: FindTooltipInput;
}>;

export type DismissTooltipMutation = {
  __typename: 'Mutation';
  dismissTooltip?: { __typename: 'Tooltip'; tooltipId: TooltipId } | null;
};

export type FeedbackRequestQueryVariables = Exact<{
  input: FeedbackRequestInput;
}>;

export type FeedbackRequestQuery = {
  __typename: 'Query';
  feedbackRequest?: {
    __typename: 'FeedbackRequestResponse';
    feedbackRequest?: {
      __typename: 'FeedbackRequest';
      negativeFreeTextInputPrompt: string;
      positiveFreeTextInputPrompt: string;
      question: string;
      textInputMaxLength: number;
    } | null;
  } | null;
};

export type SubmitFeedbackMutationVariables = Exact<{
  submitFeedbackInput: SubmitFeedbackInput;
}>;

export type SubmitFeedbackMutation = {
  __typename: 'Mutation';
  submitFeedback?: {
    __typename: 'SubmitFeedbackResponse';
    id: FeedbackId;
  } | null;
};

export type WellBeingButtonQueryVariables = Exact<{
  walkthroughType?: InputMaybe<WalkthroughType>;
}>;

export type WellBeingButtonQuery = {
  __typename: 'Query';
  walkthrough: {
    __typename: 'Walkthrough';
    wasCompleted: boolean;
    steps: Array<{
      __typename: 'WalkthroughStep';
      title: string;
      text: string;
      image?: { __typename: 'Image'; url: string } | null;
    }>;
  };
};

export type ThiResultModalFieldsFragment = {
  __typename: 'TotalHealthIndexResult';
  title: string;
  subtitle: string;
  summary: string;
  score: {
    __typename: 'TotalHealthIndexScore';
    percentage: number;
    bracket: {
      __typename: 'TotalHealthIndexBracket';
      color: { __typename: 'Color'; hexCode: string };
    };
  };
  articles?: Array<{
    __typename: 'Article';
    title: string;
    body: string;
  }> | null;
};

export type ThiResultQueryVariables = Exact<{ [key: string]: never }>;

export type ThiResultQuery = {
  __typename: 'Query';
  totalHealthIndexResult: {
    __typename: 'TotalHealthIndexResult';
    title: string;
    subtitle: string;
    summary: string;
    score: {
      __typename: 'TotalHealthIndexScore';
      percentage: number;
      bracket: {
        __typename: 'TotalHealthIndexBracket';
        color: { __typename: 'Color'; hexCode: string };
      };
    };
    articles?: Array<{
      __typename: 'Article';
      title: string;
      body: string;
    }> | null;
  };
};

export const BookedCounsellorFragmentDoc = gql`
  fragment BookedCounsellor on Counsellor {
    id
    appointmentTypes
    avatarImage {
      url
    }
    firstName
    lastName
    jobTitles
  }
`;
export const BookedAppointmentFragmentDoc = gql`
  fragment BookedAppointment on Appointment {
    id
    status
    counsellor {
      ...BookedCounsellor
    }
    startDateTime
    endDateTime
    typeDescription
    allowsCancellation
  }
  ${BookedCounsellorFragmentDoc}
`;
export const AppointmentDetailsCounsellorFragmentDoc = gql`
  fragment AppointmentDetailsCounsellor on Counsellor {
    id
    appointmentTypes
    avatarImage {
      url
    }
    firstName
    lastName
    jobTitles
  }
`;
export const CancelledAppointmentFragmentDoc = gql`
  fragment CancelledAppointment on Appointment {
    id
    counsellor {
      id
      appointmentTypes
      firstName
      lastName
      jobTitles
      avatarImage {
        url
      }
    }
    startDateTime
    endDateTime
    typeDescription
  }
`;
export const CounsellorEducationsFragmentDoc = gql`
  fragment CounsellorEducations on CounsellorEducation {
    institution
    name
  }
`;
export const CounsellorLanguagesFragmentDoc = gql`
  fragment CounsellorLanguages on Language {
    code
    name
  }
`;
export const CounsellorInfoFragmentDoc = gql`
  fragment CounsellorInfo on CounsellorInfo {
    disability
    licenses
    ethnicBackground
    gender
    religion
    sexualOrientation
    specializations
  }
`;
export const CounsellorProfileFragmentDoc = gql`
  fragment CounsellorProfile on Counsellor {
    avatarImage {
      url
    }
    firstName
    id
    jobTitles
    lastName
    appointmentTypes
    availableInNearFuture
    spokenLanguages {
      ...CounsellorLanguages
    }
    info {
      ...CounsellorInfo
    }
    allowsBooking
  }
  ${CounsellorLanguagesFragmentDoc}
  ${CounsellorInfoFragmentDoc}
`;
export const CounsellorSelectionLanguagesFragmentDoc = gql`
  fragment CounsellorSelectionLanguages on Language {
    code
    name
  }
`;
export const CounsellorSelectionFragmentDoc = gql`
  fragment CounsellorSelection on Counsellor {
    avatarImage {
      url
    }
    firstName
    id
    jobTitles
    lastName
    appointmentTypes
    availableInNearFuture
    spokenLanguages {
      ...CounsellorSelectionLanguages
    }
  }
  ${CounsellorSelectionLanguagesFragmentDoc}
`;
export const FirstOfferedCounsellorFragmentDoc = gql`
  fragment FirstOfferedCounsellor on Counsellor {
    allowsBooking
    firstName
    lastName
    id
  }
`;
export const FirstOfferedTimeslotFragmentDoc = gql`
  fragment FirstOfferedTimeslot on AvailableTimeSlot {
    id
    startDateTime
    endDateTime
    types
    counsellor {
      ...FirstOfferedCounsellor
    }
  }
  ${FirstOfferedCounsellorFragmentDoc}
`;
export const CounsellorSelectionPaginationFragmentDoc = gql`
  fragment CounsellorSelectionPagination on Pagination {
    isLastPage
    cursors {
      after
    }
  }
`;
export const AvailableFilterOptionsFragmentDoc = gql`
  fragment AvailableFilterOptions on CounsellorFilterOption {
    id
    counsellorIds
    title
  }
`;
export const TimeslotCounsellorFragmentDoc = gql`
  fragment TimeslotCounsellor on Counsellor {
    avatarImage {
      url
    }
    firstName
    id
    jobTitles
    lastName
  }
`;
export const TimeSlotFragmentDoc = gql`
  fragment TimeSlot on AvailableTimeSlot {
    endDateTime
    id
    startDateTime
    types
  }
`;
export const AppointmentsFragmentDoc = gql`
  fragment Appointments on Appointment {
    id
    status
    counsellor {
      avatarImage {
        url
      }
      id
      firstName
      lastName
      jobTitles
      allowsBooking
    }
    startDateTime
    endDateTime
    typeDescription
  }
`;
export const AppointmentsPaginationFragmentDoc = gql`
  fragment AppointmentsPagination on Pagination {
    isLastPage
    cursors {
      after
    }
  }
`;
export const YourCounsellorFragmentDoc = gql`
  fragment YourCounsellor on Counsellor {
    appointmentTypes
    avatarImage {
      url
    }
    firstName
    id
    jobTitles
    lastName
    allowsBooking
    displayHistory
  }
`;
export const WellbeingHubAssessmentListItemFragmentDoc = gql`
  fragment WellbeingHubAssessmentListItem on AssessmentListItem {
    uuid
    status
    title
    icon {
      url
    }
    numberOfQuestions
    percentageComplete
    tierPoints
  }
`;
export const WellbeingHubAssessmentPromotionFragmentDoc = gql`
  fragment WellbeingHubAssessmentPromotion on AssessmentPromotion {
    title
    assessment {
      ...WellbeingHubAssessmentListItem
    }
  }
  ${WellbeingHubAssessmentListItemFragmentDoc}
`;
export const HraPromotionFieldsFragmentDoc = gql`
  fragment HRAPromotionFields on HraPromotion {
    title
    image {
      url
    }
    icon {
      url
    }
    assessment {
      completedAt
      icon {
        url
      }
      status
      title
      tierPoints
      numberOfAssessments
      assessmentsRemaining
      assessment {
        title
        status
        numberOfQuestions
        percentageComplete
        uuid
        result {
          percentageScore
        }
      }
    }
  }
`;
export const WellbeingHubHraPromotionFragmentDoc = gql`
  fragment WellbeingHubHraPromotion on HraPromotion {
    ...HRAPromotionFields
  }
  ${HraPromotionFieldsFragmentDoc}
`;
export const PreAssessmentResultHistoryFragmentDoc = gql`
  fragment PreAssessmentResultHistory on PreAssessmentResult {
    completedAt
    description
    displayMaxScore
    id
    maxScore
    score
    sessionId
    severities
    title
  }
`;
export const CareAssessmentChoiceQuestionOptionFragmentDoc = gql`
  fragment CareAssessmentChoiceQuestionOption on CareAssessmentChoiceQuestionOption {
    id
    title
    nextQuestionId
  }
`;
export const CareAssessmentsChoiceQuestionFragmentDoc = gql`
  fragment CareAssessmentsChoiceQuestion on CareAssessmentChoiceQuestion {
    id
    title
    subtitle
    description
    options {
      ...CareAssessmentChoiceQuestionOption
    }
  }
  ${CareAssessmentChoiceQuestionOptionFragmentDoc}
`;
export const CareAssessmentsTextQuestionFragmentDoc = gql`
  fragment CareAssessmentsTextQuestion on CareAssessmentTextQuestion {
    id
    title
    subtitle
    description
    placeholder
    maxLength
    nextQuestionId
  }
`;
export const CareAssessmentFragmentDoc = gql`
  fragment CareAssessment on CareAssessment {
    id
    title
    subtitle
    sessionId
    questions {
      ...CareAssessmentsChoiceQuestion
      ...CareAssessmentsTextQuestion
    }
  }
  ${CareAssessmentsChoiceQuestionFragmentDoc}
  ${CareAssessmentsTextQuestionFragmentDoc}
`;
export const PreAssessmentResultFragmentDoc = gql`
  fragment PreAssessmentResult on PreAssessmentResult {
    title
    severities
    score
    maxScore
    displayMaxScore
    description
    completedAt
    sessionId
    id
    retakeData {
      earlyRetakeWarningMessage
    }
  }
`;
export const PreAssessmentNotTakenFragmentDoc = gql`
  fragment PreAssessmentNotTaken on PreAssessmentNotTaken {
    id
    sessionId
    title
  }
`;
export const PreAssessmentResultsFragmentDoc = gql`
  fragment PreAssessmentResults on PreAssessmentResults {
    shouldSpeakToCounsellor
    assessments {
      ...PreAssessmentResult
      ...PreAssessmentNotTaken
    }
  }
  ${PreAssessmentResultFragmentDoc}
  ${PreAssessmentNotTakenFragmentDoc}
`;
export const RecommendationFragmentDoc = gql`
  fragment Recommendation on PreAssessmentRecommendationSuicideRiskCallNow {
    id
    ccsTeamTelephoneNumber
  }
`;
export const CareIssueFragmentDoc = gql`
  fragment CareIssue on CareIssue {
    id
    title
    subtitle
    analyticsId
    default
  }
`;
export const ModuleWithoutActivitiesFragmentDoc = gql`
  fragment ModuleWithoutActivities on CarePlanModule {
    id
    name
  }
`;
export const ActivityFragmentDoc = gql`
  fragment Activity on CarePlanActivityArticle {
    id
    title
    completed
    module {
      ...ModuleWithoutActivities
    }
    ... on CarePlanActivityArticle {
      type
    }
  }
  ${ModuleWithoutActivitiesFragmentDoc}
`;
export const CarePlanActivityWithHippoFragmentDoc = gql`
  fragment CarePlanActivityWithHippo on CarePlanActivityArticle {
    type
    audios {
      title
      url
    }
    body
    copyright
    heroImage {
      url
    }
    videos {
      hlsUrl
      url
    }
    documents {
      format
      size
      title
      url
      path
    }
  }
`;
export const CarePlanActivityFragmentDoc = gql`
  fragment CarePlanActivity on CarePlanActivityArticle {
    ...Activity
    ...CarePlanActivityWithHippo
    nextActivity {
      ...Activity
    }
    previousActivity {
      ...Activity
    }
  }
  ${ActivityFragmentDoc}
  ${CarePlanActivityWithHippoFragmentDoc}
`;
export const CarePlanModulesFragmentDoc = gql`
  fragment CarePlanModules on CarePlanModule {
    ...ModuleWithoutActivities
    activities {
      ...Activity
    }
  }
  ${ModuleWithoutActivitiesFragmentDoc}
  ${ActivityFragmentDoc}
`;
export const CarePlanSectionsFragmentDoc = gql`
  fragment CarePlanSections on CarePlanSection {
    id
    modules {
      ...CarePlanModules
    }
  }
  ${CarePlanModulesFragmentDoc}
`;
export const CarePlanFragmentDoc = gql`
  fragment CarePlan on CarePlan {
    id
    name
    description
    sections {
      ...CarePlanSections
    }
    currentActivity {
      ...Activity
    }
  }
  ${CarePlanSectionsFragmentDoc}
  ${ActivityFragmentDoc}
`;
export const HabitBasicInfoFragmentDoc = gql`
  fragment HabitBasicInfo on Habit {
    habitId
    title
    subtitle
    description
    selected
  }
`;
export const HabitSelectionStateFragmentDoc = gql`
  fragment HabitSelectionState on Habit {
    habitId
    selected
  }
`;
export const HabitFragmentDoc = gql`
  fragment Habit on Habit {
    habitId
    tradeCaptions {
      title
      subtitle
    }
    thumbnailImage {
      url
      font {
        color {
          name
          hexCode
        }
      }
    }
    tracked
    ...HabitBasicInfo
    ...HabitSelectionState
  }
  ${HabitBasicInfoFragmentDoc}
  ${HabitSelectionStateFragmentDoc}
`;
export const OptionActivityFragmentDoc = gql`
  fragment OptionActivity on HabitOptionActivity {
    option {
      optionId
      title
    }
    tracked
  }
`;
export const HabitActivityFragmentDoc = gql`
  fragment HabitActivity on HabitActivity {
    habit {
      ...Habit
    }
    optionActivity {
      ...OptionActivity
    }
  }
  ${HabitFragmentDoc}
  ${OptionActivityFragmentDoc}
`;
export const HabitDailyActivityInputFragmentDoc = gql`
  fragment HabitDailyActivityInput on HabitDailyActivity {
    id
    tracked
    activity {
      ...HabitActivity
    }
  }
  ${HabitActivityFragmentDoc}
`;
export const StepActivityFragmentDoc = gql`
  fragment StepActivity on StepActivity {
    startedAt
    emoji
    type
    durationInMs
  }
`;
export const StepDailyActivityInputFragmentDoc = gql`
  fragment StepDailyActivityInput on StepDailyActivity {
    id
    tracked
    activity {
      ...StepActivity
    }
  }
  ${StepActivityFragmentDoc}
`;
export const ActivitySummaryDataFragmentDoc = gql`
  fragment ActivitySummaryData on EndOfChallengeSummaryTotal {
    total
  }
`;
export const ChallengeResultFragmentDoc = gql`
  fragment ChallengeResult on ChallengeResult {
    pointsAwarded
    newTier {
      name
      rank
    }
    tieringStatus
  }
`;
export const FeaturedStepChallengeFieldFragmentDoc = gql`
  fragment FeaturedStepChallengeField on StepChallenge {
    participationType
    challengeId
    category
    title
    image {
      url
    }
    status
    completionReward {
      points
    }
    goal
    stepActivity(first: 1) {
      activity {
        tracked
      }
    }
    wasJoined
    endDateTimeISO
    startDateTimeISO
  }
`;
export const FeaturedHabitChallengeFieldFragmentDoc = gql`
  fragment FeaturedHabitChallengeField on HabitChallenge {
    participationType
    challengeId
    category
    title
    image {
      url
    }
    status
    completionReward {
      points
    }
    goal
    activity(from: $from, first: 1) {
      activity {
        tracked
      }
    }
    wasJoined
    endDateTimeISO
    startDateTimeISO
  }
`;
export const FeaturedChallengeFieldsFragmentDoc = gql`
  fragment FeaturedChallengeFields on fetchFeaturedChallengesResponse {
    challenges {
      ...FeaturedStepChallengeField
      ...FeaturedHabitChallengeField
    }
  }
  ${FeaturedStepChallengeFieldFragmentDoc}
  ${FeaturedHabitChallengeFieldFragmentDoc}
`;
export const StepChallengeListItemFragmentDoc = gql`
  fragment StepChallengeListItem on StepChallenge {
    challengeId
    status
    title
    category
    categoryDisplayText
    completionReward {
      points
    }
    participationType
    wasJoined
    image {
      url
    }
    goal
    stepActivity(first: 1) {
      activity {
        tracked
      }
    }
    endDateTimeISO
    startDateTimeISO
    leaderboardIndividuals(first: 1) {
      leaderboard {
        position
      }
    }
  }
`;
export const ChallengeIntroductionFragmentDoc = gql`
  fragment ChallengeIntroduction on ChallengeIntroduction {
    id
    info
    title
  }
`;
export const StepChallengeDateInfoFragmentDoc = gql`
  fragment StepChallengeDateInfo on StepChallenge {
    __typename
    startDateTimeISO
    endDateTimeISO
    lastUploaded
  }
`;
export const StepChallengeBasicInfoFragmentDoc = gql`
  fragment StepChallengeBasicInfo on StepChallenge {
    challengeId
    ...StepChallengeListItem
    introduction {
      ...ChallengeIntroduction
    }
    subtitle
    ...StepChallengeDateInfo
  }
  ${StepChallengeListItemFragmentDoc}
  ${ChallengeIntroductionFragmentDoc}
  ${StepChallengeDateInfoFragmentDoc}
`;
export const ChallengePromotionFragmentDoc = gql`
  fragment ChallengePromotion on ChallengePromotion {
    challengeUnion {
      ...FeaturedHabitChallengeField
      ...FeaturedStepChallengeField
    }
  }
  ${FeaturedHabitChallengeFieldFragmentDoc}
  ${FeaturedStepChallengeFieldFragmentDoc}
`;
export const FetchChallengesHabitChallengeFragmentDoc = gql`
  fragment FetchChallengesHabitChallenge on HabitChallenge {
    ...FeaturedHabitChallengeField
    categoryDisplayText
  }
  ${FeaturedHabitChallengeFieldFragmentDoc}
`;
export const FetchChallengesStepChallengeFragmentDoc = gql`
  fragment FetchChallengesStepChallenge on StepChallenge {
    ...FeaturedStepChallengeField
    categoryDisplayText
  }
  ${FeaturedStepChallengeFieldFragmentDoc}
`;
export const FetchChallengesFragmentDoc = gql`
  fragment FetchChallenges on fetchChallengesResponse {
    challenges {
      ... on HabitChallenge {
        ...FetchChallengesHabitChallenge
      }
      ... on StepChallenge {
        ...FetchChallengesStepChallenge
      }
    }
  }
  ${FetchChallengesHabitChallengeFragmentDoc}
  ${FetchChallengesStepChallengeFragmentDoc}
`;
export const ChallengeStatusFragmentDoc = gql`
  fragment ChallengeStatus on Challenge {
    ... on HabitChallenge {
      challengeId
      status
      wasJoined
    }
    ... on StepChallenge {
      challengeId
      status
      wasJoined
    }
  }
`;
export const HabitChallengeListItemFragmentDoc = gql`
  fragment HabitChallengeListItem on HabitChallenge {
    challengeId
    status
    title
    category
    categoryDisplayText
    completionReward {
      points
    }
    participationType
    wasJoined
    image {
      url
    }
    goal
    activity(from: $from, first: 1) {
      activity {
        tracked
      }
    }
    endDateTimeISO
    startDateTimeISO
    leaderboardIndividuals(first: 1) {
      leaderboard {
        position
      }
    }
  }
`;
export const HabitChallengeDateInfoFragmentDoc = gql`
  fragment HabitChallengeDateInfo on HabitChallenge {
    __typename
    startDateTimeISO
    endDateTimeISO
  }
`;
export const HabitChallengeBasicInfoFragmentDoc = gql`
  fragment HabitChallengeBasicInfo on HabitChallenge {
    challengeId
    ...HabitChallengeListItem
    introduction {
      ...ChallengeIntroduction
    }
    subtitle
    challengeHabits {
      editSelectedHabits {
        subtitle
        title
      }
      selectHabit {
        description
        title
      }
      habits {
        ...HabitBasicInfo
      }
    }
    ...HabitChallengeDateInfo
  }
  ${HabitChallengeListItemFragmentDoc}
  ${ChallengeIntroductionFragmentDoc}
  ${HabitBasicInfoFragmentDoc}
  ${HabitChallengeDateInfoFragmentDoc}
`;
export const StepDailyActivityFragmentDoc = gql`
  fragment StepDailyActivity on StepDailyActivity {
    date
    tracked
    type
    subtitle
  }
`;
export const HabitDailyActivityFragmentDoc = gql`
  fragment HabitDailyActivity on HabitDailyActivity {
    id
    date
    tracked
    subtitle
    tradeLimit
    activity {
      habit {
        habitId
        tracked
        title
        thumbnailImage {
          url
          font {
            color {
              name
              hexCode
            }
          }
        }
      }
      optionActivity {
        option {
          optionId
          title
        }
        tracked
      }
    }
  }
`;
export const ChallengeJoinedFragmentDoc = gql`
  fragment ChallengeJoined on HabitChallenge {
    challengeId
    wasJoined
  }
`;
export const ChallengePageFragmentDoc = gql`
  fragment ChallengePage on Challenge {
    ... on HabitChallenge {
      title
      subtitle
      challengeId
      status
      goal
      participationType
      category
      wasJoined
      image {
        url
      }
      introduction {
        ...ChallengeIntroduction
      }
      ...HabitChallengeDateInfo
    }
    ... on StepChallenge {
      title
      subtitle
      challengeId
      status
      goal
      participationType
      lastUploaded
      category
      wasJoined
      image {
        url
      }
      introduction {
        ...ChallengeIntroduction
      }
      ...StepChallengeDateInfo
    }
  }
  ${ChallengeIntroductionFragmentDoc}
  ${HabitChallengeDateInfoFragmentDoc}
  ${StepChallengeDateInfoFragmentDoc}
`;
export const HubListFragmentDoc = gql`
  fragment HubList on fetchChallengesResponse {
    challenges {
      ...ChallengePage
      ...StepChallengeListItem
      ...HabitChallengeListItem
    }
  }
  ${ChallengePageFragmentDoc}
  ${StepChallengeListItemFragmentDoc}
  ${HabitChallengeListItemFragmentDoc}
`;
export const LeaderboardUserFragmentDoc = gql`
  fragment LeaderboardUser on User {
    id
    fullName
    image {
      url
    }
  }
`;
export const LeaderboardIndividualFragmentDoc = gql`
  fragment LeaderboardIndividual on IndividualsLeaderboardEntry {
    user {
      ...LeaderboardUser
    }
    score
    position
  }
  ${LeaderboardUserFragmentDoc}
`;
export const TeamUserPermissionsFragmentDoc = gql`
  fragment TeamUserPermissions on TeamPermissions {
    canCreateATeam
    isTeamAdmin
    membershipPrompt
  }
`;
export const ChallengeTeamFragmentDoc = gql`
  fragment ChallengeTeam on Team {
    id
    image {
      url
    }
    name
    memberCount
    userPermissions {
      ...TeamUserPermissions
    }
  }
  ${TeamUserPermissionsFragmentDoc}
`;
export const TeamsLeaderboardListItemFragmentDoc = gql`
  fragment TeamsLeaderboardListItem on TeamsLeaderboardEntry {
    position
    score
    team {
      ...ChallengeTeam
    }
  }
  ${ChallengeTeamFragmentDoc}
`;
export const TeamMemberFragmentDoc = gql`
  fragment TeamMember on User {
    fullName
    id
    image {
      url
    }
  }
`;
export const PaginationFragmentDoc = gql`
  fragment Pagination on Pagination {
    isLastPage
    limit
    cursors {
      after
      before
    }
  }
`;
export const TeamLeaderboardFragmentDoc = gql`
  fragment TeamLeaderboard on LeaderboardTeamMembersResponse {
    leaderboard {
      user {
        ...TeamMember
      }
      score
      position
    }
    pagination {
      ...Pagination
    }
  }
  ${TeamMemberFragmentDoc}
  ${PaginationFragmentDoc}
`;
export const JournalingEntryFragmentDoc = gql`
  fragment JournalingEntry on JournalEntry {
    body
    date
    id
    title
  }
`;
export const MoodFactorFragmentDoc = gql`
  fragment MoodFactor on MoodFactor {
    id
    title
    isCustom
  }
`;
export const MoodTrackingEntryFragmentDoc = gql`
  fragment MoodTrackingEntry on MoodEntry {
    createdAt
    factors {
      ...MoodFactor
    }
    id
    note
    value
  }
  ${MoodFactorFragmentDoc}
`;
export const MyCareAppointmentFragmentDoc = gql`
  fragment MyCareAppointment on Appointment {
    id
    startDateTime
    endDateTime
    typeDescription
    status
    isVisibleToUser
  }
`;
export const CareIssueSelectedFragmentDoc = gql`
  fragment CareIssueSelected on CareIssue {
    selected
  }
`;
export const WorkLifeServicesFragmentDoc = gql`
  fragment WorkLifeServices on WorkLifeService {
    id
    name
    description
    icon {
      url
    }
  }
`;
export const WorkLifeServicesPaginationFragmentDoc = gql`
  fragment WorkLifeServicesPagination on Pagination {
    isLastPage
    cursors {
      after
    }
  }
`;
export const PensionBenefitsTitleAndNotificationCountFragmentDoc = gql`
  fragment PensionBenefitsTitleAndNotificationCount on PensionAndBenefitsSummary {
    title
    notifications {
      count
    }
    pension {
      title
    }
    benefits {
      title
    }
  }
`;
export const PensionBenefitsNotificationsFragmentDoc = gql`
  fragment PensionBenefitsNotifications on PensionAndBenefitsSummary {
    notifications {
      count
      items {
        title
        details
        expiryDate
        portalUrlId
      }
    }
  }
`;
export const PensionBenefitsPensionFragmentDoc = gql`
  fragment PensionBenefitsPension on PensionAndBenefitsSummary {
    pension {
      title
      totals {
        label
        value
      }
      portalUrlId
    }
  }
`;
export const PensionBenefitsBenefitFragmentDoc = gql`
  fragment PensionBenefitsBenefit on PensionAndBenefitsSummary {
    benefits {
      title
      totals {
        label
        value
      }
      plans {
        title
        details
        choices
      }
      portalUrlId
    }
  }
`;
export const DigitalClinicalProgramCategoriesFragmentDoc = gql`
  fragment DigitalClinicalProgramCategories on DigitalClinicalProgramCategory {
    description
    id
    name
    icon {
      url
    }
    iconBackground {
      url
    }
  }
`;
export const ModuleStatusFieldsFragmentDoc = gql`
  fragment ModuleStatusFields on DigitalClinicalModule {
    moduleId
    status
    progress {
      totalContentItems
      completedContentItems
      remainingContentItems
      percentageComplete
    }
  }
`;
export const DigitalClinicalModuleDetailsFieldsFragmentDoc = gql`
  fragment DigitalClinicalModuleDetailsFields on DigitalClinicalModule {
    ...ModuleStatusFields
    moduleId
    title
    introduction
    heroImage {
      url
    }
    timeEstimate {
      minutes
    }
  }
  ${ModuleStatusFieldsFragmentDoc}
`;
export const DigitalClinicalModuleArticleFieldsFragmentDoc = gql`
  fragment DigitalClinicalModuleArticleFields on Article {
    contentItemId
    body
    title
    author {
      name
      surname
      jobTitle
      image {
        url
      }
    }
    heroImage {
      url
    }
    audios {
      title
      url
    }
    videos {
      title
      url
      hlsUrl
    }
    userInteraction {
      seenByUser
    }
  }
`;
export const DigitalClinicalModuleChoiceQuestionFieldsFragmentDoc = gql`
  fragment DigitalClinicalModuleChoiceQuestionFields on ChoiceQuestion {
    contentItemId
    title
    description
    options {
      label
      optionId
    }
    responseType
    userInteraction {
      seenByUser
      choices
    }
  }
`;
export const DigitalClinicalModuleFreeTextQuestionFieldsFragmentDoc = gql`
  fragment DigitalClinicalModuleFreeTextQuestionFields on FreeTextQuestion {
    contentItemId
    title
    description
    hint
    userInteraction {
      seenByUser
      text
    }
  }
`;
export const DigitalClinicalModuleScaleQuestionFieldsFragmentDoc = gql`
  fragment DigitalClinicalModuleScaleQuestionFields on ScaleQuestion {
    contentItemId
    title
    description
    scale {
      label
      scalePointId
      isDefault
    }
    userInteraction {
      seenByUser
      scalePointId
    }
  }
`;
export const DigitalClinicalModuleStarRatingQuestionFieldsFragmentDoc = gql`
  fragment DigitalClinicalModuleStarRatingQuestionFields on StarRatingQuestion {
    contentItemId
    title
    description
    starRatings {
      label
      starRatingId
      isDefault
    }
    userInteraction {
      seenByUser
      starRatingId
    }
  }
`;
export const DigitalClinicalModuleSectionsFieldsFragmentDoc = gql`
  fragment DigitalClinicalModuleSectionsFields on DigitalClinicalModule {
    sections {
      title
      contentItems {
        ...DigitalClinicalModuleArticleFields
        ...DigitalClinicalModuleChoiceQuestionFields
        ...DigitalClinicalModuleFreeTextQuestionFields
        ...DigitalClinicalModuleScaleQuestionFields
        ...DigitalClinicalModuleStarRatingQuestionFields
      }
    }
  }
  ${DigitalClinicalModuleArticleFieldsFragmentDoc}
  ${DigitalClinicalModuleChoiceQuestionFieldsFragmentDoc}
  ${DigitalClinicalModuleFreeTextQuestionFieldsFragmentDoc}
  ${DigitalClinicalModuleScaleQuestionFieldsFragmentDoc}
  ${DigitalClinicalModuleStarRatingQuestionFieldsFragmentDoc}
`;
export const ModuleOverviewFieldsFragmentDoc = gql`
  fragment ModuleOverviewFields on DigitalClinicalModule {
    moduleId
    title
    introduction
    status
    progress {
      totalContentItems
      completedContentItems
      remainingContentItems
      percentageComplete
    }
    timeEstimate {
      minutes
    }
    heroImage {
      url
    }
    backgroundImage {
      url
      font {
        color {
          hexCode
        }
      }
    }
    sections {
      description
      title
    }
  }
`;
export const ProgramStatusFieldsFragmentDoc = gql`
  fragment ProgramStatusFields on DigitalClinicalProgram {
    programId
    progress {
      totalModules
      completedModules
      percentageComplete
    }
    status
  }
`;
export const ModuleListItemStatusFieldsFragmentDoc = gql`
  fragment ModuleListItemStatusFields on DigitalClinicalModuleListItem {
    moduleId
    status
    progress {
      totalContentItems
      completedContentItems
      remainingContentItems
      percentageComplete
    }
  }
`;
export const SingleProgramFieldsFragmentDoc = gql`
  fragment SingleProgramFields on DigitalClinicalProgram {
    ...ProgramStatusFields
    programId
    title
    modules {
      ...ModuleListItemStatusFields
      heroImage {
        url
      }
      backgroundImage {
        url
        font {
          color {
            hexCode
          }
        }
      }
      moduleId
      title
      introduction
      timeEstimate {
        minutes
      }
    }
  }
  ${ProgramStatusFieldsFragmentDoc}
  ${ModuleListItemStatusFieldsFragmentDoc}
`;
export const ProgramInfoFieldsFragmentDoc = gql`
  fragment ProgramInfoFields on DigitalClinicalProgram {
    title
    description
    icon {
      url
    }
  }
`;
export const WellbeingHubProgramFieldsFragmentDoc = gql`
  fragment WellbeingHubProgramFields on DigitalClinicalProgramListItem {
    programId
    title
    description
    status
    wasJoined
    icon {
      url
    }
    progress {
      totalModules
      completedModules
      percentageComplete
    }
  }
`;
export const AssessmentHistoryResultFragmentDoc = gql`
  fragment AssessmentHistoryResult on AssessmentItemHistoryResult {
    digestTitle
    digest
    percentageScore
  }
`;
export const AssessmentHistoryFragmentDoc = gql`
  fragment AssessmentHistory on AssessmentItemHistory {
    archiveId
    assessmentUuid
    title
    introduction
    numberOfQuestions
    completedAt
    cooldownPeriodExpirationDate
    result {
      ...AssessmentHistoryResult
    }
  }
  ${AssessmentHistoryResultFragmentDoc}
`;
export const AssessmentStatusFieldsFragmentDoc = gql`
  fragment AssessmentStatusFields on AssessmentListItem {
    status
    percentageComplete
    nextQuestionReference
    canBeRetaken
    cooldownPeriodExpirationDate
  }
`;
export const AssessmentAnswerMeasurementFragmentDoc = gql`
  fragment AssessmentAnswerMeasurement on AssessmentItemAnswersMeasurements {
    measurementUuid
    measurementType
    calculationType
    unit
    value
  }
`;
export const AssessmentAnswerFragmentDoc = gql`
  fragment AssessmentAnswer on AssessmentItemAnswers {
    optionUuids
    questionUuid
    measurements {
      ...AssessmentAnswerMeasurement
    }
    type
  }
  ${AssessmentAnswerMeasurementFragmentDoc}
`;
export const AnswerMutationResponseFragmentDoc = gql`
  fragment AnswerMutationResponse on AssessmentListItem {
    uuid
    ...AssessmentStatusFields
    answers {
      ...AssessmentAnswer
    }
  }
  ${AssessmentStatusFieldsFragmentDoc}
  ${AssessmentAnswerFragmentDoc}
`;
export const AssessmentTypeFragmentDoc = gql`
  fragment AssessmentType on AssessmentType {
    description
    id
    title
  }
`;
export const AssessmentQuestionOptionFragmentDoc = gql`
  fragment AssessmentQuestionOption on AssessmentItemQuestionnaireOptionsList {
    label
    priority
    jumpTo
    uuid
    alert {
      message
    }
  }
`;
export const AssessmentQuestionMeasurementRangesFragmentDoc = gql`
  fragment AssessmentQuestionMeasurementRanges on MeasurementRanges {
    jumpTo
    range
    number
  }
`;
export const AssessmentQuestionMeasurementRangeFragmentDoc = gql`
  fragment AssessmentQuestionMeasurementRange on AssessmentItemQuestionnaireMeasurementRanges {
    unit
    label
    ranges {
      ...AssessmentQuestionMeasurementRanges
    }
  }
  ${AssessmentQuestionMeasurementRangesFragmentDoc}
`;
export const AssessmentQuestionMeasurementFragmentDoc = gql`
  fragment AssessmentQuestionMeasurement on AssessmentItemQuestionnaireMeasurements {
    uuid
    unitOfMeasurement
    calculationType
    measurementRanges {
      ...AssessmentQuestionMeasurementRange
    }
  }
  ${AssessmentQuestionMeasurementRangeFragmentDoc}
`;
export const AssessmentQuestionFragmentDoc = gql`
  fragment AssessmentQuestion on AssessmentItemQuestionnaire {
    reference
    uuid
    multipleChoice
    questionType
    introduction
    options {
      ...AssessmentQuestionOption
    }
    measurements {
      ...AssessmentQuestionMeasurement
    }
  }
  ${AssessmentQuestionOptionFragmentDoc}
  ${AssessmentQuestionMeasurementFragmentDoc}
`;
export const AssessmentImageFragmentDoc = gql`
  fragment AssessmentImage on Image {
    url
  }
`;
export const AssessmentRemainingFragmentDoc = gql`
  fragment AssessmentRemaining on RemainingAssessmentsCompletionPoints {
    type
    count
    bonusPoints
    resetDate
  }
`;
export const AssessmentFragmentDoc = gql`
  fragment Assessment on AssessmentListItem {
    uuid
    localisedUuid
    version
    title
    introduction
    numberOfQuestions
    ...AssessmentStatusFields
    type {
      ...AssessmentType
    }
    questionnaire {
      ...AssessmentQuestion
    }
    icon {
      ...AssessmentImage
    }
    answers {
      ...AssessmentAnswer
    }
    remainingAssessmentsCompletionPoints {
      ...AssessmentRemaining
    }
  }
  ${AssessmentStatusFieldsFragmentDoc}
  ${AssessmentTypeFragmentDoc}
  ${AssessmentQuestionFragmentDoc}
  ${AssessmentImageFragmentDoc}
  ${AssessmentAnswerFragmentDoc}
  ${AssessmentRemainingFragmentDoc}
`;
export const AssessmentResultFeedbackFragmentDoc = gql`
  fragment AssessmentResultFeedback on ResultFeedback {
    title
    body
    type
  }
`;
export const AssessmentResultContentAuthorFragmentDoc = gql`
  fragment AssessmentResultContentAuthor on AssessmentItemContentAuthor {
    name
    surname
  }
`;
export const AssessmentResultContentCategoryFragmentDoc = gql`
  fragment AssessmentResultContentCategory on AssessmentContentCategory {
    title
  }
`;
export const AssessmentResultContentTypeFragmentDoc = gql`
  fragment AssessmentResultContentType on AssessmentContentType {
    title
    typeGroup
    description
  }
`;
export const AssessmentResultImageFragmentDoc = gql`
  fragment AssessmentResultImage on AssessmentItemContentImages {
    articlethumbnail
  }
`;
export const AssessmentResultContentFragmentDoc = gql`
  fragment AssessmentResultContent on AssessmentListItemResultContentList {
    uuid
    url
    sanitizedBody
    lastModificationDate
    title
    resourcePath
    author {
      ...AssessmentResultContentAuthor
    }
    category {
      ...AssessmentResultContentCategory
    }
    type {
      ...AssessmentResultContentType
    }
    images {
      ...AssessmentResultImage
    }
    thumbnail {
      ...AssessmentImage
    }
  }
  ${AssessmentResultContentAuthorFragmentDoc}
  ${AssessmentResultContentCategoryFragmentDoc}
  ${AssessmentResultContentTypeFragmentDoc}
  ${AssessmentResultImageFragmentDoc}
  ${AssessmentImageFragmentDoc}
`;
export const MeasurementResultFeedbackFragmentDoc = gql`
  fragment MeasurementResultFeedback on MeasurementResultFeedback {
    title
    body
  }
`;
export const MeasurementResultFragmentDoc = gql`
  fragment MeasurementResult on AssessmentContentMeasurementResult {
    score
    feedback {
      ...MeasurementResultFeedback
    }
  }
  ${MeasurementResultFeedbackFragmentDoc}
`;
export const AssessmentResultFragmentDoc = gql`
  fragment AssessmentResult on AssessmentListItemResult {
    digestTitle
    digest
    gender
    score
    minScore
    maxScore
    percentageScore
    shouldDisplayPercentage
    minScaleLabel
    maxScaleLabel
    feedback {
      ...AssessmentResultFeedback
    }
    content {
      ...AssessmentResultContent
    }
    measurementResult {
      ...MeasurementResult
    }
  }
  ${AssessmentResultFeedbackFragmentDoc}
  ${AssessmentResultContentFragmentDoc}
  ${MeasurementResultFragmentDoc}
`;
export const NextAssessmentFragmentDoc = gql`
  fragment NextAssessment on AssessmentListItem {
    uuid
    title
  }
`;
export const TieringHubNextTierFragmentDoc = gql`
  fragment TieringHubNextTier on NextTier {
    id
    name
    rank
    meetsRequirementsForTier
  }
`;
export const TieringHubCurrentTierFragmentDoc = gql`
  fragment TieringHubCurrentTier on CurrentTier {
    id
    name
    rank
    motivationalMessage
  }
`;
export const TieringHubTierPointsFragmentDoc = gql`
  fragment TieringHubTierPoints on TierPoints {
    id
    earned {
      total
      thisMonth {
        value
        since
      }
    }
    remainingToReachNextTier
    requiredToNotRegress {
      total
      remaining
      thisMonth {
        value
        remaining
        daysRemaining
      }
    }
  }
`;
export const AssessmentTierStateFragmentDoc = gql`
  fragment AssessmentTierState on AssessmentItemCurrentTierProgress {
    pointsForCompletingAssessment
    bonusPointsForCompletingAllAssessments
    tierSummary {
      nextTier {
        ...TieringHubNextTier
      }
      currentTier {
        ...TieringHubCurrentTier
      }
      points {
        ...TieringHubTierPoints
      }
    }
  }
  ${TieringHubNextTierFragmentDoc}
  ${TieringHubCurrentTierFragmentDoc}
  ${TieringHubTierPointsFragmentDoc}
`;
export const FinalAnswerMutationResponseFragmentDoc = gql`
  fragment FinalAnswerMutationResponse on AssessmentListItem {
    uuid
    ...AssessmentStatusFields
    tierPoints
    answers {
      ...AssessmentAnswer
    }
    result {
      ...AssessmentResult
    }
    nextAssessment {
      ...NextAssessment
    }
    tierProgress {
      ...AssessmentTierState
    }
  }
  ${AssessmentStatusFieldsFragmentDoc}
  ${AssessmentAnswerFragmentDoc}
  ${AssessmentResultFragmentDoc}
  ${NextAssessmentFragmentDoc}
  ${AssessmentTierStateFragmentDoc}
`;
export const CompletedAssessmentFragmentDoc = gql`
  fragment CompletedAssessment on AssessmentListItem {
    ...Assessment
    ...FinalAnswerMutationResponse
  }
  ${AssessmentFragmentDoc}
  ${FinalAnswerMutationResponseFragmentDoc}
`;
export const HistoricalAssessmentFragmentDoc = gql`
  fragment HistoricalAssessment on HistoricalAssessment {
    assessmentUuid
    archiveId
    result {
      ...AssessmentResult
    }
  }
  ${AssessmentResultFragmentDoc}
`;
export const AssessmentResultCommonFieldsFragmentDoc = gql`
  fragment AssessmentResultCommonFields on AssessmentListItem {
    canBeRetaken
    uuid
    title
    icon {
      ...AssessmentImage
    }
  }
  ${AssessmentImageFragmentDoc}
`;
export const RegressionInformationFragmentDoc = gql`
  fragment RegressionInformation on TierRegressionInfo {
    image {
      url
      font {
        color {
          hexCode
        }
      }
    }
    timePeriods {
      isCurrent
      timePeriod {
        from
        to
      }
      pointsEarned
      pointGoal
    }
    currentTierDefinition {
      name
      rank
      points
    }
  }
`;
export const SingleAssessmentHeroCardFragmentDoc = gql`
  fragment SingleAssessmentHeroCard on SingleAssessmentCard {
    completedAt
    icon {
      url
    }
    status
    title
    tierPoints
    assessment {
      title
      status
      numberOfQuestions
      percentageComplete
      uuid
      result {
        percentageScore
      }
      type {
        id
        title
        description
        filter
      }
    }
  }
`;
export const HealthRiskAssessmentHeroCardFragmentDoc = gql`
  fragment HealthRiskAssessmentHeroCard on HealthRiskAssessmentCard {
    completedAt
    icon {
      url
    }
    status
    title
    tierPoints
    numberOfAssessments
    assessmentsRemaining
    nextAssessment {
      title
      status
      numberOfQuestions
      percentageComplete
      uuid
      result {
        percentageScore
      }
      type {
        id
        title
        description
        filter
      }
    }
  }
`;
export const AssessmentTypeFiltersFragmentDoc = gql`
  fragment AssessmentTypeFilters on AssessmentTypeFilter {
    typeId
    name
    count
  }
`;
export const AssessmentListItemFragmentDoc = gql`
  fragment AssessmentListItem on AssessmentListItem {
    ...Assessment
    tierPoints
  }
  ${AssessmentFragmentDoc}
`;
export const AssessmentsHubAssessmentResultFragmentDoc = gql`
  fragment AssessmentsHubAssessmentResult on AssessmentListItemResult {
    digestTitle
    digest
    minScaleLabel
    maxScaleLabel
    percentageScore
    shouldDisplayPercentage
    score
  }
`;
export const AssessmentHubListItemFragmentDoc = gql`
  fragment AssessmentHubListItem on AssessmentListItem {
    ...AssessmentListItem
    result {
      ...AssessmentsHubAssessmentResult
    }
  }
  ${AssessmentListItemFragmentDoc}
  ${AssessmentsHubAssessmentResultFragmentDoc}
`;
export const CustomTieringActivityListItemFragmentDoc = gql`
  fragment CustomTieringActivityListItem on CustomTierActivity {
    activityId
    title
    description
    tierPointAward {
      frequency
      points
    }
    image {
      url
    }
    status
  }
`;
export const TieringHubAllTiersFragmentDoc = gql`
  fragment TieringHubAllTiers on TierDefinition {
    name
    rank
    points
  }
`;
export const TierRegressionInfoFragmentDoc = gql`
  fragment TierRegressionInfo on TierRegressionInfo {
    image {
      url
      font {
        color {
          hexCode
        }
      }
    }
    timePeriods {
      isCurrent
      timePeriod {
        from
        to
      }
      pointsEarned
      pointGoal
    }
  }
`;
export const WalkthroughFieldsFragmentDoc = gql`
  fragment WalkthroughFields on Walkthrough {
    wasCompleted
    steps {
      title
      text
      image {
        url
      }
    }
  }
`;
export const ProgramListFieldsFragmentDoc = gql`
  fragment ProgramListFields on DigitalClinicalProgramListItem {
    description
    wasJoined
    programId
    title
    status
    icon {
      url
    }
    progress {
      percentageComplete
    }
    nextModule {
      moduleId
      title
    }
  }
`;
export const ProgramFieldsFragmentDoc = gql`
  fragment ProgramFields on DigitalClinicalProgramListItem {
    programId
    wasJoined
    title
    status
    icon {
      url
    }
    progress {
      totalModules
      completedModules
      percentageComplete
    }
    nextModule {
      moduleId
      title
    }
    completedAt
  }
`;
export const SingleAssessmentsCardFieldsFragmentDoc = gql`
  fragment SingleAssessmentsCardFields on SingleAssessmentCard {
    completedAt
    icon {
      url
    }
    status
    title
    tierPoints
    assessment {
      title
      status
      numberOfQuestions
      percentageComplete
      uuid
      result {
        percentageScore
      }
      icon {
        url
      }
      tierPoints
    }
  }
`;
export const ThiCardFieldsFragmentDoc = gql`
  fragment THICardFields on TotalHealthIndexCard {
    title
    subtitle
    status
    nextPillar {
      uuid
      title
    }
    score {
      percentage
      bracket {
        bracketId
        description
        color {
          hexCode
        }
      }
    }
    tierPoints
  }
`;
export const HraCardFieldsFragmentDoc = gql`
  fragment HRACardFields on HealthRiskAssessmentCard {
    completedAt
    icon {
      url
    }
    status
    title
    tierPoints
    numberOfAssessments
    assessmentsRemaining
    nextAssessment {
      title
      status
      numberOfQuestions
      percentageComplete
      uuid
      result {
        percentageScore
      }
    }
  }
`;
export const RecommendationFieldsFragmentDoc = gql`
  fragment RecommendationFields on Article {
    author {
      name
    }
    body
    categoryTitle
    contentItemId
    heroImage {
      url
    }
    lastModificationDate
    title
    url
  }
`;
export const PersonalisationPermissionFragmentDoc = gql`
  fragment PersonalisationPermission on HowPersonalisationWorksPermission {
    allowPersonalisation
    body
    footer
    subtitle
    title
  }
`;
export const HowDoesPersonalisationWorkFragmentDoc = gql`
  fragment HowDoesPersonalisationWork on HowDoesPersonalisationWorkResponse {
    body
    button
    icon {
      url
    }
    permission {
      ...PersonalisationPermission
    }
    title
  }
  ${PersonalisationPermissionFragmentDoc}
`;
export const WellbeingContentLanguageMessageFragmentDoc = gql`
  fragment WellbeingContentLanguageMessage on WellbeingContentLanguageMessageResponse {
    banner {
      ... on WellbeingContentLanguageMessageNoContent {
        section
        bannerText
      }
      ... on WellbeingContentLanguageMessageNoChoice {
        section
        bannerText
        bannerTextPopUp
      }
      ... on WellbeingContentLanguageMessageChangeLanguage {
        section
        bannerText
        bannerTextPopUp
      }
    }
  }
`;
export const BannerFragmentDoc = gql`
  fragment Banner on BannerResponse {
    banner {
      dismissed
      id
    }
  }
`;
export const TooltipFieldsFragmentDoc = gql`
  fragment TooltipFields on Tooltip {
    tooltipId
    title
    icon {
      url
    }
  }
`;
export const ThiResultModalFieldsFragmentDoc = gql`
  fragment THIResultModalFields on TotalHealthIndexResult {
    title
    subtitle
    summary
    score {
      percentage
      bracket {
        color {
          hexCode
        }
      }
    }
    articles {
      title
      body
    }
  }
`;
export const ChangeCounsellorDataDocument = gql`
  query ChangeCounsellorData($input: FetchAppointmentsInput!) {
    fetchAppointments(input: $input) {
      appointments {
        data {
          status
          allowsCancellation
        }
      }
    }
    fetchCounsellor {
      counsellor {
        id
        appointmentTypes
      }
    }
    assessedAsSuicideRisk {
      ccsTeamTelephoneNumber
    }
  }
`;

/**
 * __useChangeCounsellorDataQuery__
 *
 * To run a query within a React component, call `useChangeCounsellorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeCounsellorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeCounsellorDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeCounsellorDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChangeCounsellorDataQuery,
    ChangeCounsellorDataQueryVariables
  > &
    (
      | { variables: ChangeCounsellorDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChangeCounsellorDataQuery,
    ChangeCounsellorDataQueryVariables
  >(ChangeCounsellorDataDocument, options);
}
export function useChangeCounsellorDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChangeCounsellorDataQuery,
    ChangeCounsellorDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChangeCounsellorDataQuery,
    ChangeCounsellorDataQueryVariables
  >(ChangeCounsellorDataDocument, options);
}
export function useChangeCounsellorDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ChangeCounsellorDataQuery,
        ChangeCounsellorDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ChangeCounsellorDataQuery,
    ChangeCounsellorDataQueryVariables
  >(ChangeCounsellorDataDocument, options);
}
export type ChangeCounsellorDataQueryHookResult = ReturnType<
  typeof useChangeCounsellorDataQuery
>;
export type ChangeCounsellorDataLazyQueryHookResult = ReturnType<
  typeof useChangeCounsellorDataLazyQuery
>;
export type ChangeCounsellorDataSuspenseQueryHookResult = ReturnType<
  typeof useChangeCounsellorDataSuspenseQuery
>;
export type ChangeCounsellorDataQueryResult = Apollo.QueryResult<
  ChangeCounsellorDataQuery,
  ChangeCounsellorDataQueryVariables
>;
export const BookAppointmentDocument = gql`
  mutation BookAppointment($input: BookAppointmentInput!) {
    bookAppointment(input: $input) {
      appointment {
        ...BookedAppointment
      }
    }
  }
  ${BookedAppointmentFragmentDoc}
`;
export type BookAppointmentMutationFn = Apollo.MutationFunction<
  BookAppointmentMutation,
  BookAppointmentMutationVariables
>;

/**
 * __useBookAppointmentMutation__
 *
 * To run a mutation, you first call `useBookAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAppointmentMutation, { data, loading, error }] = useBookAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookAppointmentMutation,
    BookAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookAppointmentMutation,
    BookAppointmentMutationVariables
  >(BookAppointmentDocument, options);
}
export type BookAppointmentMutationHookResult = ReturnType<
  typeof useBookAppointmentMutation
>;
export type BookAppointmentMutationResult =
  Apollo.MutationResult<BookAppointmentMutation>;
export type BookAppointmentMutationOptions = Apollo.BaseMutationOptions<
  BookAppointmentMutation,
  BookAppointmentMutationVariables
>;
export const FetchAppointmentByIdDocument = gql`
  query FetchAppointmentById($appointmentId: ID!) {
    fetchAppointmentById(input: { appointmentId: $appointmentId }) {
      appointment {
        id
        status
        counsellor {
          ...AppointmentDetailsCounsellor
        }
        startDateTime
        endDateTime
        typeDescription
        allowsCancellation
        isVisibleToUser
      }
    }
  }
  ${AppointmentDetailsCounsellorFragmentDoc}
`;

/**
 * __useFetchAppointmentByIdQuery__
 *
 * To run a query within a React component, call `useFetchAppointmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAppointmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAppointmentByIdQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useFetchAppointmentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchAppointmentByIdQuery,
    FetchAppointmentByIdQueryVariables
  > &
    (
      | { variables: FetchAppointmentByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchAppointmentByIdQuery,
    FetchAppointmentByIdQueryVariables
  >(FetchAppointmentByIdDocument, options);
}
export function useFetchAppointmentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAppointmentByIdQuery,
    FetchAppointmentByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchAppointmentByIdQuery,
    FetchAppointmentByIdQueryVariables
  >(FetchAppointmentByIdDocument, options);
}
export function useFetchAppointmentByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchAppointmentByIdQuery,
        FetchAppointmentByIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchAppointmentByIdQuery,
    FetchAppointmentByIdQueryVariables
  >(FetchAppointmentByIdDocument, options);
}
export type FetchAppointmentByIdQueryHookResult = ReturnType<
  typeof useFetchAppointmentByIdQuery
>;
export type FetchAppointmentByIdLazyQueryHookResult = ReturnType<
  typeof useFetchAppointmentByIdLazyQuery
>;
export type FetchAppointmentByIdSuspenseQueryHookResult = ReturnType<
  typeof useFetchAppointmentByIdSuspenseQuery
>;
export type FetchAppointmentByIdQueryResult = Apollo.QueryResult<
  FetchAppointmentByIdQuery,
  FetchAppointmentByIdQueryVariables
>;
export const CancelAppointmentDocument = gql`
  mutation CancelAppointment($id: ID!) {
    cancelAppointment(input: { appointmentId: $id }) {
      appointment {
        ...CancelledAppointment
      }
    }
  }
  ${CancelledAppointmentFragmentDoc}
`;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >(CancelAppointmentDocument, options);
}
export type CancelAppointmentMutationHookResult = ReturnType<
  typeof useCancelAppointmentMutation
>;
export type CancelAppointmentMutationResult =
  Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;
export const FetchCounsellorByIdDocument = gql`
  query FetchCounsellorById($counsellorId: ID!) {
    fetchCounsellorById(input: { counsellorId: $counsellorId }) {
      counsellor {
        ...CounsellorProfile
      }
    }
  }
  ${CounsellorProfileFragmentDoc}
`;

/**
 * __useFetchCounsellorByIdQuery__
 *
 * To run a query within a React component, call `useFetchCounsellorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCounsellorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCounsellorByIdQuery({
 *   variables: {
 *      counsellorId: // value for 'counsellorId'
 *   },
 * });
 */
export function useFetchCounsellorByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchCounsellorByIdQuery,
    FetchCounsellorByIdQueryVariables
  > &
    (
      | { variables: FetchCounsellorByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchCounsellorByIdQuery,
    FetchCounsellorByIdQueryVariables
  >(FetchCounsellorByIdDocument, options);
}
export function useFetchCounsellorByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCounsellorByIdQuery,
    FetchCounsellorByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchCounsellorByIdQuery,
    FetchCounsellorByIdQueryVariables
  >(FetchCounsellorByIdDocument, options);
}
export function useFetchCounsellorByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchCounsellorByIdQuery,
        FetchCounsellorByIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchCounsellorByIdQuery,
    FetchCounsellorByIdQueryVariables
  >(FetchCounsellorByIdDocument, options);
}
export type FetchCounsellorByIdQueryHookResult = ReturnType<
  typeof useFetchCounsellorByIdQuery
>;
export type FetchCounsellorByIdLazyQueryHookResult = ReturnType<
  typeof useFetchCounsellorByIdLazyQuery
>;
export type FetchCounsellorByIdSuspenseQueryHookResult = ReturnType<
  typeof useFetchCounsellorByIdSuspenseQuery
>;
export type FetchCounsellorByIdQueryResult = Apollo.QueryResult<
  FetchCounsellorByIdQuery,
  FetchCounsellorByIdQueryVariables
>;
export const FetchCounsellorForChatDocument = gql`
  query FetchCounsellorForChat($counsellorId: ID!) {
    fetchCounsellorById(input: { counsellorId: $counsellorId }) {
      counsellor {
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useFetchCounsellorForChatQuery__
 *
 * To run a query within a React component, call `useFetchCounsellorForChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCounsellorForChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCounsellorForChatQuery({
 *   variables: {
 *      counsellorId: // value for 'counsellorId'
 *   },
 * });
 */
export function useFetchCounsellorForChatQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchCounsellorForChatQuery,
    FetchCounsellorForChatQueryVariables
  > &
    (
      | { variables: FetchCounsellorForChatQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchCounsellorForChatQuery,
    FetchCounsellorForChatQueryVariables
  >(FetchCounsellorForChatDocument, options);
}
export function useFetchCounsellorForChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCounsellorForChatQuery,
    FetchCounsellorForChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchCounsellorForChatQuery,
    FetchCounsellorForChatQueryVariables
  >(FetchCounsellorForChatDocument, options);
}
export function useFetchCounsellorForChatSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchCounsellorForChatQuery,
        FetchCounsellorForChatQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchCounsellorForChatQuery,
    FetchCounsellorForChatQueryVariables
  >(FetchCounsellorForChatDocument, options);
}
export type FetchCounsellorForChatQueryHookResult = ReturnType<
  typeof useFetchCounsellorForChatQuery
>;
export type FetchCounsellorForChatLazyQueryHookResult = ReturnType<
  typeof useFetchCounsellorForChatLazyQuery
>;
export type FetchCounsellorForChatSuspenseQueryHookResult = ReturnType<
  typeof useFetchCounsellorForChatSuspenseQuery
>;
export type FetchCounsellorForChatQueryResult = Apollo.QueryResult<
  FetchCounsellorForChatQuery,
  FetchCounsellorForChatQueryVariables
>;
export const FetchCounsellorDocument = gql`
  query FetchCounsellor(
    $after: String
    $locationId: ID!
    $counsellorIds: [ID]!
  ) {
    fetchCounsellors(
      input: {
        where: { locationId: $locationId, ids: $counsellorIds }
        first: 10
        afterCursor: $after
      }
    ) {
      counsellors {
        data {
          ...CounsellorSelection
        }
        paging {
          ...CounsellorSelectionPagination
        }
      }
    }
  }
  ${CounsellorSelectionFragmentDoc}
  ${CounsellorSelectionPaginationFragmentDoc}
`;

/**
 * __useFetchCounsellorQuery__
 *
 * To run a query within a React component, call `useFetchCounsellorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCounsellorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCounsellorQuery({
 *   variables: {
 *      after: // value for 'after'
 *      locationId: // value for 'locationId'
 *      counsellorIds: // value for 'counsellorIds'
 *   },
 * });
 */
export function useFetchCounsellorQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchCounsellorQuery,
    FetchCounsellorQueryVariables
  > &
    (
      | { variables: FetchCounsellorQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCounsellorQuery, FetchCounsellorQueryVariables>(
    FetchCounsellorDocument,
    options,
  );
}
export function useFetchCounsellorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCounsellorQuery,
    FetchCounsellorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchCounsellorQuery,
    FetchCounsellorQueryVariables
  >(FetchCounsellorDocument, options);
}
export function useFetchCounsellorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchCounsellorQuery,
        FetchCounsellorQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchCounsellorQuery,
    FetchCounsellorQueryVariables
  >(FetchCounsellorDocument, options);
}
export type FetchCounsellorQueryHookResult = ReturnType<
  typeof useFetchCounsellorQuery
>;
export type FetchCounsellorLazyQueryHookResult = ReturnType<
  typeof useFetchCounsellorLazyQuery
>;
export type FetchCounsellorSuspenseQueryHookResult = ReturnType<
  typeof useFetchCounsellorSuspenseQuery
>;
export type FetchCounsellorQueryResult = Apollo.QueryResult<
  FetchCounsellorQuery,
  FetchCounsellorQueryVariables
>;
export const FetchCounsellorFiltersDocument = gql`
  query FetchCounsellorFilters($locationId: ID!) {
    fetchCounsellorFilters(input: { where: { locationId: $locationId } }) {
      filters {
        id
        title
        options {
          ...AvailableFilterOptions
        }
      }
    }
  }
  ${AvailableFilterOptionsFragmentDoc}
`;

/**
 * __useFetchCounsellorFiltersQuery__
 *
 * To run a query within a React component, call `useFetchCounsellorFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCounsellorFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCounsellorFiltersQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useFetchCounsellorFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchCounsellorFiltersQuery,
    FetchCounsellorFiltersQueryVariables
  > &
    (
      | { variables: FetchCounsellorFiltersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchCounsellorFiltersQuery,
    FetchCounsellorFiltersQueryVariables
  >(FetchCounsellorFiltersDocument, options);
}
export function useFetchCounsellorFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCounsellorFiltersQuery,
    FetchCounsellorFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchCounsellorFiltersQuery,
    FetchCounsellorFiltersQueryVariables
  >(FetchCounsellorFiltersDocument, options);
}
export function useFetchCounsellorFiltersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchCounsellorFiltersQuery,
        FetchCounsellorFiltersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchCounsellorFiltersQuery,
    FetchCounsellorFiltersQueryVariables
  >(FetchCounsellorFiltersDocument, options);
}
export type FetchCounsellorFiltersQueryHookResult = ReturnType<
  typeof useFetchCounsellorFiltersQuery
>;
export type FetchCounsellorFiltersLazyQueryHookResult = ReturnType<
  typeof useFetchCounsellorFiltersLazyQuery
>;
export type FetchCounsellorFiltersSuspenseQueryHookResult = ReturnType<
  typeof useFetchCounsellorFiltersSuspenseQuery
>;
export type FetchCounsellorFiltersQueryResult = Apollo.QueryResult<
  FetchCounsellorFiltersQuery,
  FetchCounsellorFiltersQueryVariables
>;
export const FetchFirstOfferedAppointmentDocument = gql`
  query FetchFirstOfferedAppointment($locationId: ID!) {
    fetchFirstOfferedAvailableTimeSlot(input: { locationId: $locationId }) {
      availableTimeSlot {
        ...FirstOfferedTimeslot
      }
    }
  }
  ${FirstOfferedTimeslotFragmentDoc}
`;

/**
 * __useFetchFirstOfferedAppointmentQuery__
 *
 * To run a query within a React component, call `useFetchFirstOfferedAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFirstOfferedAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFirstOfferedAppointmentQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useFetchFirstOfferedAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchFirstOfferedAppointmentQuery,
    FetchFirstOfferedAppointmentQueryVariables
  > &
    (
      | {
          variables: FetchFirstOfferedAppointmentQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchFirstOfferedAppointmentQuery,
    FetchFirstOfferedAppointmentQueryVariables
  >(FetchFirstOfferedAppointmentDocument, options);
}
export function useFetchFirstOfferedAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchFirstOfferedAppointmentQuery,
    FetchFirstOfferedAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchFirstOfferedAppointmentQuery,
    FetchFirstOfferedAppointmentQueryVariables
  >(FetchFirstOfferedAppointmentDocument, options);
}
export function useFetchFirstOfferedAppointmentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchFirstOfferedAppointmentQuery,
        FetchFirstOfferedAppointmentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchFirstOfferedAppointmentQuery,
    FetchFirstOfferedAppointmentQueryVariables
  >(FetchFirstOfferedAppointmentDocument, options);
}
export type FetchFirstOfferedAppointmentQueryHookResult = ReturnType<
  typeof useFetchFirstOfferedAppointmentQuery
>;
export type FetchFirstOfferedAppointmentLazyQueryHookResult = ReturnType<
  typeof useFetchFirstOfferedAppointmentLazyQuery
>;
export type FetchFirstOfferedAppointmentSuspenseQueryHookResult = ReturnType<
  typeof useFetchFirstOfferedAppointmentSuspenseQuery
>;
export type FetchFirstOfferedAppointmentQueryResult = Apollo.QueryResult<
  FetchFirstOfferedAppointmentQuery,
  FetchFirstOfferedAppointmentQueryVariables
>;
export const StripeInitializationDocument = gql`
  query StripeInitialization {
    stripeInitialization {
      stripeInitialization {
        publishableKey
      }
    }
    appointmentPaymentSetupIntent {
      stripeIntent {
        customerId
        intentClientSecret
        customerSessionClientSecret
      }
    }
  }
`;

/**
 * __useStripeInitializationQuery__
 *
 * To run a query within a React component, call `useStripeInitializationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeInitializationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeInitializationQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeInitializationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StripeInitializationQuery,
    StripeInitializationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StripeInitializationQuery,
    StripeInitializationQueryVariables
  >(StripeInitializationDocument, options);
}
export function useStripeInitializationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StripeInitializationQuery,
    StripeInitializationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StripeInitializationQuery,
    StripeInitializationQueryVariables
  >(StripeInitializationDocument, options);
}
export function useStripeInitializationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        StripeInitializationQuery,
        StripeInitializationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StripeInitializationQuery,
    StripeInitializationQueryVariables
  >(StripeInitializationDocument, options);
}
export type StripeInitializationQueryHookResult = ReturnType<
  typeof useStripeInitializationQuery
>;
export type StripeInitializationLazyQueryHookResult = ReturnType<
  typeof useStripeInitializationLazyQuery
>;
export type StripeInitializationSuspenseQueryHookResult = ReturnType<
  typeof useStripeInitializationSuspenseQuery
>;
export type StripeInitializationQueryResult = Apollo.QueryResult<
  StripeInitializationQuery,
  StripeInitializationQueryVariables
>;
export const SelectedCardDocument = gql`
  query SelectedCard {
    selectedCard {
      selectedCard {
        last4Digits
        cardBrand
        expirationMonth
        expirationYear
        isExpired
      }
    }
  }
`;

/**
 * __useSelectedCardQuery__
 *
 * To run a query within a React component, call `useSelectedCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectedCardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SelectedCardQuery,
    SelectedCardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelectedCardQuery, SelectedCardQueryVariables>(
    SelectedCardDocument,
    options,
  );
}
export function useSelectedCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelectedCardQuery,
    SelectedCardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelectedCardQuery, SelectedCardQueryVariables>(
    SelectedCardDocument,
    options,
  );
}
export function useSelectedCardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SelectedCardQuery,
        SelectedCardQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SelectedCardQuery, SelectedCardQueryVariables>(
    SelectedCardDocument,
    options,
  );
}
export type SelectedCardQueryHookResult = ReturnType<
  typeof useSelectedCardQuery
>;
export type SelectedCardLazyQueryHookResult = ReturnType<
  typeof useSelectedCardLazyQuery
>;
export type SelectedCardSuspenseQueryHookResult = ReturnType<
  typeof useSelectedCardSuspenseQuery
>;
export type SelectedCardQueryResult = Apollo.QueryResult<
  SelectedCardQuery,
  SelectedCardQueryVariables
>;
export const CounsellorWithTimeslotsDocument = gql`
  query CounsellorWithTimeslots {
    fetchCounsellor {
      counsellor {
        ...TimeslotCounsellor
        timeSlots(input: {}) {
          ...TimeSlot
        }
      }
    }
  }
  ${TimeslotCounsellorFragmentDoc}
  ${TimeSlotFragmentDoc}
`;

/**
 * __useCounsellorWithTimeslotsQuery__
 *
 * To run a query within a React component, call `useCounsellorWithTimeslotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounsellorWithTimeslotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounsellorWithTimeslotsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCounsellorWithTimeslotsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CounsellorWithTimeslotsQuery,
    CounsellorWithTimeslotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CounsellorWithTimeslotsQuery,
    CounsellorWithTimeslotsQueryVariables
  >(CounsellorWithTimeslotsDocument, options);
}
export function useCounsellorWithTimeslotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CounsellorWithTimeslotsQuery,
    CounsellorWithTimeslotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CounsellorWithTimeslotsQuery,
    CounsellorWithTimeslotsQueryVariables
  >(CounsellorWithTimeslotsDocument, options);
}
export function useCounsellorWithTimeslotsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CounsellorWithTimeslotsQuery,
        CounsellorWithTimeslotsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CounsellorWithTimeslotsQuery,
    CounsellorWithTimeslotsQueryVariables
  >(CounsellorWithTimeslotsDocument, options);
}
export type CounsellorWithTimeslotsQueryHookResult = ReturnType<
  typeof useCounsellorWithTimeslotsQuery
>;
export type CounsellorWithTimeslotsLazyQueryHookResult = ReturnType<
  typeof useCounsellorWithTimeslotsLazyQuery
>;
export type CounsellorWithTimeslotsSuspenseQueryHookResult = ReturnType<
  typeof useCounsellorWithTimeslotsSuspenseQuery
>;
export type CounsellorWithTimeslotsQueryResult = Apollo.QueryResult<
  CounsellorWithTimeslotsQuery,
  CounsellorWithTimeslotsQueryVariables
>;
export const CounsellorByIdWithTimeslotsDocument = gql`
  query CounsellorByIdWithTimeslots($counsellorId: ID!) {
    fetchCounsellorById(input: { counsellorId: $counsellorId }) {
      counsellor {
        ...TimeslotCounsellor
        timeSlots(input: {}) {
          ...TimeSlot
        }
      }
    }
  }
  ${TimeslotCounsellorFragmentDoc}
  ${TimeSlotFragmentDoc}
`;

/**
 * __useCounsellorByIdWithTimeslotsQuery__
 *
 * To run a query within a React component, call `useCounsellorByIdWithTimeslotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounsellorByIdWithTimeslotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounsellorByIdWithTimeslotsQuery({
 *   variables: {
 *      counsellorId: // value for 'counsellorId'
 *   },
 * });
 */
export function useCounsellorByIdWithTimeslotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CounsellorByIdWithTimeslotsQuery,
    CounsellorByIdWithTimeslotsQueryVariables
  > &
    (
      | { variables: CounsellorByIdWithTimeslotsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CounsellorByIdWithTimeslotsQuery,
    CounsellorByIdWithTimeslotsQueryVariables
  >(CounsellorByIdWithTimeslotsDocument, options);
}
export function useCounsellorByIdWithTimeslotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CounsellorByIdWithTimeslotsQuery,
    CounsellorByIdWithTimeslotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CounsellorByIdWithTimeslotsQuery,
    CounsellorByIdWithTimeslotsQueryVariables
  >(CounsellorByIdWithTimeslotsDocument, options);
}
export function useCounsellorByIdWithTimeslotsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CounsellorByIdWithTimeslotsQuery,
        CounsellorByIdWithTimeslotsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CounsellorByIdWithTimeslotsQuery,
    CounsellorByIdWithTimeslotsQueryVariables
  >(CounsellorByIdWithTimeslotsDocument, options);
}
export type CounsellorByIdWithTimeslotsQueryHookResult = ReturnType<
  typeof useCounsellorByIdWithTimeslotsQuery
>;
export type CounsellorByIdWithTimeslotsLazyQueryHookResult = ReturnType<
  typeof useCounsellorByIdWithTimeslotsLazyQuery
>;
export type CounsellorByIdWithTimeslotsSuspenseQueryHookResult = ReturnType<
  typeof useCounsellorByIdWithTimeslotsSuspenseQuery
>;
export type CounsellorByIdWithTimeslotsQueryResult = Apollo.QueryResult<
  CounsellorByIdWithTimeslotsQuery,
  CounsellorByIdWithTimeslotsQueryVariables
>;
export const SubdivisionDocument = gql`
  query Subdivision {
    fetchUserSubdivisions {
      subdivisions {
        id
        name
      }
    }
  }
`;

/**
 * __useSubdivisionQuery__
 *
 * To run a query within a React component, call `useSubdivisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubdivisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubdivisionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubdivisionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubdivisionQuery,
    SubdivisionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubdivisionQuery, SubdivisionQueryVariables>(
    SubdivisionDocument,
    options,
  );
}
export function useSubdivisionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubdivisionQuery,
    SubdivisionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubdivisionQuery, SubdivisionQueryVariables>(
    SubdivisionDocument,
    options,
  );
}
export function useSubdivisionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SubdivisionQuery,
        SubdivisionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubdivisionQuery, SubdivisionQueryVariables>(
    SubdivisionDocument,
    options,
  );
}
export type SubdivisionQueryHookResult = ReturnType<typeof useSubdivisionQuery>;
export type SubdivisionLazyQueryHookResult = ReturnType<
  typeof useSubdivisionLazyQuery
>;
export type SubdivisionSuspenseQueryHookResult = ReturnType<
  typeof useSubdivisionSuspenseQuery
>;
export type SubdivisionQueryResult = Apollo.QueryResult<
  SubdivisionQuery,
  SubdivisionQueryVariables
>;
export const FetchUpcomingAppointmentsDocument = gql`
  query FetchUpcomingAppointments($input: FetchAppointmentsInput!) {
    fetchAppointments(input: $input) {
      appointments {
        data {
          ...Appointments
        }
      }
    }
  }
  ${AppointmentsFragmentDoc}
`;

/**
 * __useFetchUpcomingAppointmentsQuery__
 *
 * To run a query within a React component, call `useFetchUpcomingAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUpcomingAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUpcomingAppointmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchUpcomingAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchUpcomingAppointmentsQuery,
    FetchUpcomingAppointmentsQueryVariables
  > &
    (
      | { variables: FetchUpcomingAppointmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchUpcomingAppointmentsQuery,
    FetchUpcomingAppointmentsQueryVariables
  >(FetchUpcomingAppointmentsDocument, options);
}
export function useFetchUpcomingAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUpcomingAppointmentsQuery,
    FetchUpcomingAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchUpcomingAppointmentsQuery,
    FetchUpcomingAppointmentsQueryVariables
  >(FetchUpcomingAppointmentsDocument, options);
}
export function useFetchUpcomingAppointmentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchUpcomingAppointmentsQuery,
        FetchUpcomingAppointmentsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchUpcomingAppointmentsQuery,
    FetchUpcomingAppointmentsQueryVariables
  >(FetchUpcomingAppointmentsDocument, options);
}
export type FetchUpcomingAppointmentsQueryHookResult = ReturnType<
  typeof useFetchUpcomingAppointmentsQuery
>;
export type FetchUpcomingAppointmentsLazyQueryHookResult = ReturnType<
  typeof useFetchUpcomingAppointmentsLazyQuery
>;
export type FetchUpcomingAppointmentsSuspenseQueryHookResult = ReturnType<
  typeof useFetchUpcomingAppointmentsSuspenseQuery
>;
export type FetchUpcomingAppointmentsQueryResult = Apollo.QueryResult<
  FetchUpcomingAppointmentsQuery,
  FetchUpcomingAppointmentsQueryVariables
>;
export const FetchPastAppointmentsDocument = gql`
  query FetchPastAppointments($input: FetchAppointmentsInput!) {
    fetchAppointments(input: $input) {
      appointments {
        data {
          ...Appointments
        }
        paging {
          ...AppointmentsPagination
        }
      }
    }
  }
  ${AppointmentsFragmentDoc}
  ${AppointmentsPaginationFragmentDoc}
`;

/**
 * __useFetchPastAppointmentsQuery__
 *
 * To run a query within a React component, call `useFetchPastAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPastAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPastAppointmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPastAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchPastAppointmentsQuery,
    FetchPastAppointmentsQueryVariables
  > &
    (
      | { variables: FetchPastAppointmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchPastAppointmentsQuery,
    FetchPastAppointmentsQueryVariables
  >(FetchPastAppointmentsDocument, options);
}
export function useFetchPastAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPastAppointmentsQuery,
    FetchPastAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchPastAppointmentsQuery,
    FetchPastAppointmentsQueryVariables
  >(FetchPastAppointmentsDocument, options);
}
export function useFetchPastAppointmentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchPastAppointmentsQuery,
        FetchPastAppointmentsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchPastAppointmentsQuery,
    FetchPastAppointmentsQueryVariables
  >(FetchPastAppointmentsDocument, options);
}
export type FetchPastAppointmentsQueryHookResult = ReturnType<
  typeof useFetchPastAppointmentsQuery
>;
export type FetchPastAppointmentsLazyQueryHookResult = ReturnType<
  typeof useFetchPastAppointmentsLazyQuery
>;
export type FetchPastAppointmentsSuspenseQueryHookResult = ReturnType<
  typeof useFetchPastAppointmentsSuspenseQuery
>;
export type FetchPastAppointmentsQueryResult = Apollo.QueryResult<
  FetchPastAppointmentsQuery,
  FetchPastAppointmentsQueryVariables
>;
export const FetchYourCounsellorDocument = gql`
  query FetchYourCounsellor {
    fetchCounsellor {
      counsellor {
        ...YourCounsellor
      }
    }
  }
  ${YourCounsellorFragmentDoc}
`;

/**
 * __useFetchYourCounsellorQuery__
 *
 * To run a query within a React component, call `useFetchYourCounsellorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchYourCounsellorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchYourCounsellorQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchYourCounsellorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchYourCounsellorQuery,
    FetchYourCounsellorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchYourCounsellorQuery,
    FetchYourCounsellorQueryVariables
  >(FetchYourCounsellorDocument, options);
}
export function useFetchYourCounsellorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchYourCounsellorQuery,
    FetchYourCounsellorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchYourCounsellorQuery,
    FetchYourCounsellorQueryVariables
  >(FetchYourCounsellorDocument, options);
}
export function useFetchYourCounsellorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchYourCounsellorQuery,
        FetchYourCounsellorQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchYourCounsellorQuery,
    FetchYourCounsellorQueryVariables
  >(FetchYourCounsellorDocument, options);
}
export type FetchYourCounsellorQueryHookResult = ReturnType<
  typeof useFetchYourCounsellorQuery
>;
export type FetchYourCounsellorLazyQueryHookResult = ReturnType<
  typeof useFetchYourCounsellorLazyQuery
>;
export type FetchYourCounsellorSuspenseQueryHookResult = ReturnType<
  typeof useFetchYourCounsellorSuspenseQuery
>;
export type FetchYourCounsellorQueryResult = Apollo.QueryResult<
  FetchYourCounsellorQuery,
  FetchYourCounsellorQueryVariables
>;
export const SelectCounsellorDocument = gql`
  mutation SelectCounsellor($input: SelectCounsellorInput!) {
    selectCounsellor(input: $input) {
      counsellor {
        ...YourCounsellor
      }
    }
  }
  ${YourCounsellorFragmentDoc}
`;
export type SelectCounsellorMutationFn = Apollo.MutationFunction<
  SelectCounsellorMutation,
  SelectCounsellorMutationVariables
>;

/**
 * __useSelectCounsellorMutation__
 *
 * To run a mutation, you first call `useSelectCounsellorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectCounsellorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectCounsellorMutation, { data, loading, error }] = useSelectCounsellorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectCounsellorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectCounsellorMutation,
    SelectCounsellorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SelectCounsellorMutation,
    SelectCounsellorMutationVariables
  >(SelectCounsellorDocument, options);
}
export type SelectCounsellorMutationHookResult = ReturnType<
  typeof useSelectCounsellorMutation
>;
export type SelectCounsellorMutationResult =
  Apollo.MutationResult<SelectCounsellorMutation>;
export type SelectCounsellorMutationOptions = Apollo.BaseMutationOptions<
  SelectCounsellorMutation,
  SelectCounsellorMutationVariables
>;
export const PreAssessmentResultsHistoryDocument = gql`
  query PreAssessmentResultsHistory($where: FilterPreAssessmentHistoryInput) {
    preAssessmentResultsHistory(where: $where) {
      history {
        ...PreAssessmentResultHistory
      }
      pagination {
        isLastPage
        cursors {
          after
        }
      }
    }
  }
  ${PreAssessmentResultHistoryFragmentDoc}
`;

/**
 * __usePreAssessmentResultsHistoryQuery__
 *
 * To run a query within a React component, call `usePreAssessmentResultsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreAssessmentResultsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreAssessmentResultsHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePreAssessmentResultsHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PreAssessmentResultsHistoryQuery,
    PreAssessmentResultsHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PreAssessmentResultsHistoryQuery,
    PreAssessmentResultsHistoryQueryVariables
  >(PreAssessmentResultsHistoryDocument, options);
}
export function usePreAssessmentResultsHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreAssessmentResultsHistoryQuery,
    PreAssessmentResultsHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PreAssessmentResultsHistoryQuery,
    PreAssessmentResultsHistoryQueryVariables
  >(PreAssessmentResultsHistoryDocument, options);
}
export function usePreAssessmentResultsHistorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PreAssessmentResultsHistoryQuery,
        PreAssessmentResultsHistoryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PreAssessmentResultsHistoryQuery,
    PreAssessmentResultsHistoryQueryVariables
  >(PreAssessmentResultsHistoryDocument, options);
}
export type PreAssessmentResultsHistoryQueryHookResult = ReturnType<
  typeof usePreAssessmentResultsHistoryQuery
>;
export type PreAssessmentResultsHistoryLazyQueryHookResult = ReturnType<
  typeof usePreAssessmentResultsHistoryLazyQuery
>;
export type PreAssessmentResultsHistorySuspenseQueryHookResult = ReturnType<
  typeof usePreAssessmentResultsHistorySuspenseQuery
>;
export type PreAssessmentResultsHistoryQueryResult = Apollo.QueryResult<
  PreAssessmentResultsHistoryQuery,
  PreAssessmentResultsHistoryQueryVariables
>;
export const PreAssessmentDocument = gql`
  query PreAssessment($where: FilterPreAssessmentInput) {
    preAssessment(where: $where) {
      nextAssessment {
        ...CareAssessment
      }
    }
  }
  ${CareAssessmentFragmentDoc}
`;

/**
 * __usePreAssessmentQuery__
 *
 * To run a query within a React component, call `usePreAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreAssessmentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePreAssessmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PreAssessmentQuery,
    PreAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PreAssessmentQuery, PreAssessmentQueryVariables>(
    PreAssessmentDocument,
    options,
  );
}
export function usePreAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreAssessmentQuery,
    PreAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PreAssessmentQuery, PreAssessmentQueryVariables>(
    PreAssessmentDocument,
    options,
  );
}
export function usePreAssessmentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PreAssessmentQuery,
        PreAssessmentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PreAssessmentQuery,
    PreAssessmentQueryVariables
  >(PreAssessmentDocument, options);
}
export type PreAssessmentQueryHookResult = ReturnType<
  typeof usePreAssessmentQuery
>;
export type PreAssessmentLazyQueryHookResult = ReturnType<
  typeof usePreAssessmentLazyQuery
>;
export type PreAssessmentSuspenseQueryHookResult = ReturnType<
  typeof usePreAssessmentSuspenseQuery
>;
export type PreAssessmentQueryResult = Apollo.QueryResult<
  PreAssessmentQuery,
  PreAssessmentQueryVariables
>;
export const AnswerPreAssessmentDocument = gql`
  mutation AnswerPreAssessment($request: AnswerPreAssessmentInput!) {
    answerPreAssessment(request: $request) {
      nextAssessment {
        ...CareAssessment
      }
      recommendation {
        ...Recommendation
      }
      results {
        ...PreAssessmentResults
      }
    }
  }
  ${CareAssessmentFragmentDoc}
  ${RecommendationFragmentDoc}
  ${PreAssessmentResultsFragmentDoc}
`;
export type AnswerPreAssessmentMutationFn = Apollo.MutationFunction<
  AnswerPreAssessmentMutation,
  AnswerPreAssessmentMutationVariables
>;

/**
 * __useAnswerPreAssessmentMutation__
 *
 * To run a mutation, you first call `useAnswerPreAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerPreAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerPreAssessmentMutation, { data, loading, error }] = useAnswerPreAssessmentMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAnswerPreAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnswerPreAssessmentMutation,
    AnswerPreAssessmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnswerPreAssessmentMutation,
    AnswerPreAssessmentMutationVariables
  >(AnswerPreAssessmentDocument, options);
}
export type AnswerPreAssessmentMutationHookResult = ReturnType<
  typeof useAnswerPreAssessmentMutation
>;
export type AnswerPreAssessmentMutationResult =
  Apollo.MutationResult<AnswerPreAssessmentMutation>;
export type AnswerPreAssessmentMutationOptions = Apollo.BaseMutationOptions<
  AnswerPreAssessmentMutation,
  AnswerPreAssessmentMutationVariables
>;
export const RequestCallbackForAtRiskMemberDocument = gql`
  mutation RequestCallbackForAtRiskMember(
    $input: RequestCallbackForAtRiskMemberInput!
  ) {
    requestCallbackForAtRiskMember(input: $input) {
      telephoneNumber
    }
  }
`;
export type RequestCallbackForAtRiskMemberMutationFn = Apollo.MutationFunction<
  RequestCallbackForAtRiskMemberMutation,
  RequestCallbackForAtRiskMemberMutationVariables
>;

/**
 * __useRequestCallbackForAtRiskMemberMutation__
 *
 * To run a mutation, you first call `useRequestCallbackForAtRiskMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCallbackForAtRiskMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCallbackForAtRiskMemberMutation, { data, loading, error }] = useRequestCallbackForAtRiskMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestCallbackForAtRiskMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestCallbackForAtRiskMemberMutation,
    RequestCallbackForAtRiskMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestCallbackForAtRiskMemberMutation,
    RequestCallbackForAtRiskMemberMutationVariables
  >(RequestCallbackForAtRiskMemberDocument, options);
}
export type RequestCallbackForAtRiskMemberMutationHookResult = ReturnType<
  typeof useRequestCallbackForAtRiskMemberMutation
>;
export type RequestCallbackForAtRiskMemberMutationResult =
  Apollo.MutationResult<RequestCallbackForAtRiskMemberMutation>;
export type RequestCallbackForAtRiskMemberMutationOptions =
  Apollo.BaseMutationOptions<
    RequestCallbackForAtRiskMemberMutation,
    RequestCallbackForAtRiskMemberMutationVariables
  >;
export const SelectedCareServiceOptionDocument = gql`
  query SelectedCareServiceOption {
    selectedCareServiceOption {
      option
    }
  }
`;

/**
 * __useSelectedCareServiceOptionQuery__
 *
 * To run a query within a React component, call `useSelectedCareServiceOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedCareServiceOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedCareServiceOptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectedCareServiceOptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SelectedCareServiceOptionQuery,
    SelectedCareServiceOptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SelectedCareServiceOptionQuery,
    SelectedCareServiceOptionQueryVariables
  >(SelectedCareServiceOptionDocument, options);
}
export function useSelectedCareServiceOptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelectedCareServiceOptionQuery,
    SelectedCareServiceOptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SelectedCareServiceOptionQuery,
    SelectedCareServiceOptionQueryVariables
  >(SelectedCareServiceOptionDocument, options);
}
export function useSelectedCareServiceOptionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SelectedCareServiceOptionQuery,
        SelectedCareServiceOptionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SelectedCareServiceOptionQuery,
    SelectedCareServiceOptionQueryVariables
  >(SelectedCareServiceOptionDocument, options);
}
export type SelectedCareServiceOptionQueryHookResult = ReturnType<
  typeof useSelectedCareServiceOptionQuery
>;
export type SelectedCareServiceOptionLazyQueryHookResult = ReturnType<
  typeof useSelectedCareServiceOptionLazyQuery
>;
export type SelectedCareServiceOptionSuspenseQueryHookResult = ReturnType<
  typeof useSelectedCareServiceOptionSuspenseQuery
>;
export type SelectedCareServiceOptionQueryResult = Apollo.QueryResult<
  SelectedCareServiceOptionQuery,
  SelectedCareServiceOptionQueryVariables
>;
export const CareIssuesDocument = gql`
  query CareIssues {
    getCareIssues {
      careIssues {
        ...CareIssue
      }
    }
  }
  ${CareIssueFragmentDoc}
`;

/**
 * __useCareIssuesQuery__
 *
 * To run a query within a React component, call `useCareIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareIssuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareIssuesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CareIssuesQuery,
    CareIssuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareIssuesQuery, CareIssuesQueryVariables>(
    CareIssuesDocument,
    options,
  );
}
export function useCareIssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareIssuesQuery,
    CareIssuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareIssuesQuery, CareIssuesQueryVariables>(
    CareIssuesDocument,
    options,
  );
}
export function useCareIssuesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CareIssuesQuery,
        CareIssuesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CareIssuesQuery, CareIssuesQueryVariables>(
    CareIssuesDocument,
    options,
  );
}
export type CareIssuesQueryHookResult = ReturnType<typeof useCareIssuesQuery>;
export type CareIssuesLazyQueryHookResult = ReturnType<
  typeof useCareIssuesLazyQuery
>;
export type CareIssuesSuspenseQueryHookResult = ReturnType<
  typeof useCareIssuesSuspenseQuery
>;
export type CareIssuesQueryResult = Apollo.QueryResult<
  CareIssuesQuery,
  CareIssuesQueryVariables
>;
export const SetCareIssueDocument = gql`
  mutation SetCareIssue($request: SetCareIssueInput!) {
    setCareIssue(request: $request) {
      careIssue {
        ...CareIssue
      }
    }
  }
  ${CareIssueFragmentDoc}
`;
export type SetCareIssueMutationFn = Apollo.MutationFunction<
  SetCareIssueMutation,
  SetCareIssueMutationVariables
>;

/**
 * __useSetCareIssueMutation__
 *
 * To run a mutation, you first call `useSetCareIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCareIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCareIssueMutation, { data, loading, error }] = useSetCareIssueMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSetCareIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCareIssueMutation,
    SetCareIssueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetCareIssueMutation,
    SetCareIssueMutationVariables
  >(SetCareIssueDocument, options);
}
export type SetCareIssueMutationHookResult = ReturnType<
  typeof useSetCareIssueMutation
>;
export type SetCareIssueMutationResult =
  Apollo.MutationResult<SetCareIssueMutation>;
export type SetCareIssueMutationOptions = Apollo.BaseMutationOptions<
  SetCareIssueMutation,
  SetCareIssueMutationVariables
>;
export const AskForImmediateHelpDocument = gql`
  mutation AskForImmediateHelp {
    askForImmediateHelp {
      ccsTeamTelephoneNumber
    }
  }
`;
export type AskForImmediateHelpMutationFn = Apollo.MutationFunction<
  AskForImmediateHelpMutation,
  AskForImmediateHelpMutationVariables
>;

/**
 * __useAskForImmediateHelpMutation__
 *
 * To run a mutation, you first call `useAskForImmediateHelpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAskForImmediateHelpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [askForImmediateHelpMutation, { data, loading, error }] = useAskForImmediateHelpMutation({
 *   variables: {
 *   },
 * });
 */
export function useAskForImmediateHelpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AskForImmediateHelpMutation,
    AskForImmediateHelpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AskForImmediateHelpMutation,
    AskForImmediateHelpMutationVariables
  >(AskForImmediateHelpDocument, options);
}
export type AskForImmediateHelpMutationHookResult = ReturnType<
  typeof useAskForImmediateHelpMutation
>;
export type AskForImmediateHelpMutationResult =
  Apollo.MutationResult<AskForImmediateHelpMutation>;
export type AskForImmediateHelpMutationOptions = Apollo.BaseMutationOptions<
  AskForImmediateHelpMutation,
  AskForImmediateHelpMutationVariables
>;
export const SelectCareServiceOptionDocument = gql`
  mutation SelectCareServiceOption($request: SelectCareServiceOptionInput!) {
    selectCareServiceOption(request: $request) {
      option
    }
  }
`;
export type SelectCareServiceOptionMutationFn = Apollo.MutationFunction<
  SelectCareServiceOptionMutation,
  SelectCareServiceOptionMutationVariables
>;

/**
 * __useSelectCareServiceOptionMutation__
 *
 * To run a mutation, you first call `useSelectCareServiceOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectCareServiceOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectCareServiceOptionMutation, { data, loading, error }] = useSelectCareServiceOptionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSelectCareServiceOptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectCareServiceOptionMutation,
    SelectCareServiceOptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SelectCareServiceOptionMutation,
    SelectCareServiceOptionMutationVariables
  >(SelectCareServiceOptionDocument, options);
}
export type SelectCareServiceOptionMutationHookResult = ReturnType<
  typeof useSelectCareServiceOptionMutation
>;
export type SelectCareServiceOptionMutationResult =
  Apollo.MutationResult<SelectCareServiceOptionMutation>;
export type SelectCareServiceOptionMutationOptions = Apollo.BaseMutationOptions<
  SelectCareServiceOptionMutation,
  SelectCareServiceOptionMutationVariables
>;
export const CarePlanActivityDocument = gql`
  query CarePlanActivity($where: CarePlanActivityInput) {
    carePlanActivity(where: $where) {
      carePlanActivity {
        ...CarePlanActivity
      }
    }
  }
  ${CarePlanActivityFragmentDoc}
`;

/**
 * __useCarePlanActivityQuery__
 *
 * To run a query within a React component, call `useCarePlanActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarePlanActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarePlanActivityQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCarePlanActivityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CarePlanActivityQuery,
    CarePlanActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarePlanActivityQuery, CarePlanActivityQueryVariables>(
    CarePlanActivityDocument,
    options,
  );
}
export function useCarePlanActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarePlanActivityQuery,
    CarePlanActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CarePlanActivityQuery,
    CarePlanActivityQueryVariables
  >(CarePlanActivityDocument, options);
}
export function useCarePlanActivitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CarePlanActivityQuery,
        CarePlanActivityQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CarePlanActivityQuery,
    CarePlanActivityQueryVariables
  >(CarePlanActivityDocument, options);
}
export type CarePlanActivityQueryHookResult = ReturnType<
  typeof useCarePlanActivityQuery
>;
export type CarePlanActivityLazyQueryHookResult = ReturnType<
  typeof useCarePlanActivityLazyQuery
>;
export type CarePlanActivitySuspenseQueryHookResult = ReturnType<
  typeof useCarePlanActivitySuspenseQuery
>;
export type CarePlanActivityQueryResult = Apollo.QueryResult<
  CarePlanActivityQuery,
  CarePlanActivityQueryVariables
>;
export const CompleteCarePlanActivityDocument = gql`
  mutation CompleteCarePlanActivity($input: CompleteCarePlanActivityInput) {
    completeCarePlanActivity(input: $input) {
      carePlanActivity {
        id
        completed
      }
    }
  }
`;
export type CompleteCarePlanActivityMutationFn = Apollo.MutationFunction<
  CompleteCarePlanActivityMutation,
  CompleteCarePlanActivityMutationVariables
>;

/**
 * __useCompleteCarePlanActivityMutation__
 *
 * To run a mutation, you first call `useCompleteCarePlanActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteCarePlanActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeCarePlanActivityMutation, { data, loading, error }] = useCompleteCarePlanActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteCarePlanActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteCarePlanActivityMutation,
    CompleteCarePlanActivityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteCarePlanActivityMutation,
    CompleteCarePlanActivityMutationVariables
  >(CompleteCarePlanActivityDocument, options);
}
export type CompleteCarePlanActivityMutationHookResult = ReturnType<
  typeof useCompleteCarePlanActivityMutation
>;
export type CompleteCarePlanActivityMutationResult =
  Apollo.MutationResult<CompleteCarePlanActivityMutation>;
export type CompleteCarePlanActivityMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteCarePlanActivityMutation,
    CompleteCarePlanActivityMutationVariables
  >;
export const CarePlanDocument = gql`
  query CarePlan {
    carePlan {
      carePlan {
        ...CarePlan
      }
    }
  }
  ${CarePlanFragmentDoc}
`;

/**
 * __useCarePlanQuery__
 *
 * To run a query within a React component, call `useCarePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarePlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useCarePlanQuery(
  baseOptions?: Apollo.QueryHookOptions<CarePlanQuery, CarePlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarePlanQuery, CarePlanQueryVariables>(
    CarePlanDocument,
    options,
  );
}
export function useCarePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarePlanQuery,
    CarePlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CarePlanQuery, CarePlanQueryVariables>(
    CarePlanDocument,
    options,
  );
}
export function useCarePlanSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CarePlanQuery, CarePlanQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CarePlanQuery, CarePlanQueryVariables>(
    CarePlanDocument,
    options,
  );
}
export type CarePlanQueryHookResult = ReturnType<typeof useCarePlanQuery>;
export type CarePlanLazyQueryHookResult = ReturnType<
  typeof useCarePlanLazyQuery
>;
export type CarePlanSuspenseQueryHookResult = ReturnType<
  typeof useCarePlanSuspenseQuery
>;
export type CarePlanQueryResult = Apollo.QueryResult<
  CarePlanQuery,
  CarePlanQueryVariables
>;
export const AssignCarePlanDocument = gql`
  mutation AssignCarePlan($input: AssignCarePlanInput!) {
    assignCarePlan(input: $input) {
      received
    }
  }
`;
export type AssignCarePlanMutationFn = Apollo.MutationFunction<
  AssignCarePlanMutation,
  AssignCarePlanMutationVariables
>;

/**
 * __useAssignCarePlanMutation__
 *
 * To run a mutation, you first call `useAssignCarePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCarePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCarePlanMutation, { data, loading, error }] = useAssignCarePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignCarePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignCarePlanMutation,
    AssignCarePlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignCarePlanMutation,
    AssignCarePlanMutationVariables
  >(AssignCarePlanDocument, options);
}
export type AssignCarePlanMutationHookResult = ReturnType<
  typeof useAssignCarePlanMutation
>;
export type AssignCarePlanMutationResult =
  Apollo.MutationResult<AssignCarePlanMutation>;
export type AssignCarePlanMutationOptions = Apollo.BaseMutationOptions<
  AssignCarePlanMutation,
  AssignCarePlanMutationVariables
>;
export const ActivityDocument = gql`
  query Activity($challengeId: ID!, $from: GraphQLDate, $after: String) {
    fetchChallenge(id: $challengeId) {
      challenge {
        ... on HabitChallenge {
          challengeId
          activity(from: $from, first: 5, afterCursor: $after) {
            activity {
              ...HabitDailyActivity
            }
            pagination {
              ...Pagination
            }
          }
          challengeHabits {
            habits {
              ...HabitBasicInfo
              thumbnailImage {
                url
              }
              options {
                optionId
                title
              }
            }
          }
          subtitle
          status
          goal
        }
        ... on StepChallenge {
          challengeId
          activity: stepActivity(from: $from, first: 5, afterCursor: $after) {
            activity {
              ...StepDailyActivity
            }
            pagination {
              ...Pagination
            }
          }
          status
          goal
        }
      }
    }
  }
  ${HabitDailyActivityFragmentDoc}
  ${PaginationFragmentDoc}
  ${HabitBasicInfoFragmentDoc}
  ${StepDailyActivityFragmentDoc}
`;

/**
 * __useActivityQuery__
 *
 * To run a query within a React component, call `useActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityQuery({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *      from: // value for 'from'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useActivityQuery(
  baseOptions: Apollo.QueryHookOptions<ActivityQuery, ActivityQueryVariables> &
    ({ variables: ActivityQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityQuery, ActivityQueryVariables>(
    ActivityDocument,
    options,
  );
}
export function useActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityQuery,
    ActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivityQuery, ActivityQueryVariables>(
    ActivityDocument,
    options,
  );
}
export function useActivitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ActivityQuery, ActivityQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ActivityQuery, ActivityQueryVariables>(
    ActivityDocument,
    options,
  );
}
export type ActivityQueryHookResult = ReturnType<typeof useActivityQuery>;
export type ActivityLazyQueryHookResult = ReturnType<
  typeof useActivityLazyQuery
>;
export type ActivitySuspenseQueryHookResult = ReturnType<
  typeof useActivitySuspenseQuery
>;
export type ActivityQueryResult = Apollo.QueryResult<
  ActivityQuery,
  ActivityQueryVariables
>;
export const FetchActivityHabitInputDocument = gql`
  query FetchActivityHabitInput($id: ID!, $date: GraphQLDate!) {
    fetchChallenge(id: $id) {
      challenge {
        ... on HabitChallenge {
          challengeId
          activity(first: 1, from: $date) {
            activity {
              ...HabitDailyActivityInput
            }
          }
          challengeHabits {
            habits {
              ...HabitBasicInfo
              thumbnailImage {
                url
              }
              options {
                optionId
                title
              }
            }
          }
        }
      }
    }
  }
  ${HabitDailyActivityInputFragmentDoc}
  ${HabitBasicInfoFragmentDoc}
`;

/**
 * __useFetchActivityHabitInputQuery__
 *
 * To run a query within a React component, call `useFetchActivityHabitInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchActivityHabitInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchActivityHabitInputQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useFetchActivityHabitInputQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchActivityHabitInputQuery,
    FetchActivityHabitInputQueryVariables
  > &
    (
      | { variables: FetchActivityHabitInputQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchActivityHabitInputQuery,
    FetchActivityHabitInputQueryVariables
  >(FetchActivityHabitInputDocument, options);
}
export function useFetchActivityHabitInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchActivityHabitInputQuery,
    FetchActivityHabitInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchActivityHabitInputQuery,
    FetchActivityHabitInputQueryVariables
  >(FetchActivityHabitInputDocument, options);
}
export function useFetchActivityHabitInputSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchActivityHabitInputQuery,
        FetchActivityHabitInputQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchActivityHabitInputQuery,
    FetchActivityHabitInputQueryVariables
  >(FetchActivityHabitInputDocument, options);
}
export type FetchActivityHabitInputQueryHookResult = ReturnType<
  typeof useFetchActivityHabitInputQuery
>;
export type FetchActivityHabitInputLazyQueryHookResult = ReturnType<
  typeof useFetchActivityHabitInputLazyQuery
>;
export type FetchActivityHabitInputSuspenseQueryHookResult = ReturnType<
  typeof useFetchActivityHabitInputSuspenseQuery
>;
export type FetchActivityHabitInputQueryResult = Apollo.QueryResult<
  FetchActivityHabitInputQuery,
  FetchActivityHabitInputQueryVariables
>;
export const UpdateActivityHabitInputDocument = gql`
  mutation UpdateActivityHabitInput(
    $id: String!
    $habitId: String!
    $date: String!
    $options: [UpdateHabitOptionActivityInput!]!
  ) {
    updateHabitActivity(
      request: {
        challengeId: $id
        habitId: $habitId
        date: $date
        habitOptions: $options
      }
    ) {
      updatedHabitActivityTotals {
        challengeTrackedActivityTotal
        habitTrackedActivityTotal
      }
    }
  }
`;
export type UpdateActivityHabitInputMutationFn = Apollo.MutationFunction<
  UpdateActivityHabitInputMutation,
  UpdateActivityHabitInputMutationVariables
>;

/**
 * __useUpdateActivityHabitInputMutation__
 *
 * To run a mutation, you first call `useUpdateActivityHabitInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityHabitInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityHabitInputMutation, { data, loading, error }] = useUpdateActivityHabitInputMutation({
 *   variables: {
 *      id: // value for 'id'
 *      habitId: // value for 'habitId'
 *      date: // value for 'date'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateActivityHabitInputMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActivityHabitInputMutation,
    UpdateActivityHabitInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateActivityHabitInputMutation,
    UpdateActivityHabitInputMutationVariables
  >(UpdateActivityHabitInputDocument, options);
}
export type UpdateActivityHabitInputMutationHookResult = ReturnType<
  typeof useUpdateActivityHabitInputMutation
>;
export type UpdateActivityHabitInputMutationResult =
  Apollo.MutationResult<UpdateActivityHabitInputMutation>;
export type UpdateActivityHabitInputMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateActivityHabitInputMutation,
    UpdateActivityHabitInputMutationVariables
  >;
export const RetakeIndividualChallengeDocument = gql`
  mutation RetakeIndividualChallenge($id: ID!) {
    retakeIndividualChallenge(id: $id) {
      challenge {
        ... on HabitChallenge {
          challengeId
        }
      }
    }
  }
`;
export type RetakeIndividualChallengeMutationFn = Apollo.MutationFunction<
  RetakeIndividualChallengeMutation,
  RetakeIndividualChallengeMutationVariables
>;

/**
 * __useRetakeIndividualChallengeMutation__
 *
 * To run a mutation, you first call `useRetakeIndividualChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetakeIndividualChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retakeIndividualChallengeMutation, { data, loading, error }] = useRetakeIndividualChallengeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetakeIndividualChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetakeIndividualChallengeMutation,
    RetakeIndividualChallengeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RetakeIndividualChallengeMutation,
    RetakeIndividualChallengeMutationVariables
  >(RetakeIndividualChallengeDocument, options);
}
export type RetakeIndividualChallengeMutationHookResult = ReturnType<
  typeof useRetakeIndividualChallengeMutation
>;
export type RetakeIndividualChallengeMutationResult =
  Apollo.MutationResult<RetakeIndividualChallengeMutation>;
export type RetakeIndividualChallengeMutationOptions =
  Apollo.BaseMutationOptions<
    RetakeIndividualChallengeMutation,
    RetakeIndividualChallengeMutationVariables
  >;
export const ActivityStepsDocument = gql`
  query ActivitySteps($id: ID!, $date: GraphQLDate!) {
    fetchChallenge(id: $id) {
      challenge {
        ... on StepChallenge {
          challengeId
          stepActivity(first: 1, from: $date) {
            activity {
              ...StepDailyActivityInput
            }
          }
        }
      }
    }
  }
  ${StepDailyActivityInputFragmentDoc}
`;

/**
 * __useActivityStepsQuery__
 *
 * To run a query within a React component, call `useActivityStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityStepsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useActivityStepsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivityStepsQuery,
    ActivityStepsQueryVariables
  > &
    (
      | { variables: ActivityStepsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityStepsQuery, ActivityStepsQueryVariables>(
    ActivityStepsDocument,
    options,
  );
}
export function useActivityStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityStepsQuery,
    ActivityStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivityStepsQuery, ActivityStepsQueryVariables>(
    ActivityStepsDocument,
    options,
  );
}
export function useActivityStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ActivityStepsQuery,
        ActivityStepsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ActivityStepsQuery,
    ActivityStepsQueryVariables
  >(ActivityStepsDocument, options);
}
export type ActivityStepsQueryHookResult = ReturnType<
  typeof useActivityStepsQuery
>;
export type ActivityStepsLazyQueryHookResult = ReturnType<
  typeof useActivityStepsLazyQuery
>;
export type ActivityStepsSuspenseQueryHookResult = ReturnType<
  typeof useActivityStepsSuspenseQuery
>;
export type ActivityStepsQueryResult = Apollo.QueryResult<
  ActivityStepsQuery,
  ActivityStepsQueryVariables
>;
export const ActivityStepsResultDocument = gql`
  query ActivityStepsResult($id: ID!, $date: GraphQLDate!) {
    fetchChallenge(id: $id) {
      challenge {
        ... on StepChallenge {
          result {
            ...ChallengeResult
          }
          challengeId
          stepActivity(first: 1, from: $date) {
            summary {
              ...ActivitySummaryData
            }
          }
        }
      }
    }
  }
  ${ChallengeResultFragmentDoc}
  ${ActivitySummaryDataFragmentDoc}
`;

/**
 * __useActivityStepsResultQuery__
 *
 * To run a query within a React component, call `useActivityStepsResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityStepsResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityStepsResultQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useActivityStepsResultQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivityStepsResultQuery,
    ActivityStepsResultQueryVariables
  > &
    (
      | { variables: ActivityStepsResultQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActivityStepsResultQuery,
    ActivityStepsResultQueryVariables
  >(ActivityStepsResultDocument, options);
}
export function useActivityStepsResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityStepsResultQuery,
    ActivityStepsResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActivityStepsResultQuery,
    ActivityStepsResultQueryVariables
  >(ActivityStepsResultDocument, options);
}
export function useActivityStepsResultSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ActivityStepsResultQuery,
        ActivityStepsResultQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ActivityStepsResultQuery,
    ActivityStepsResultQueryVariables
  >(ActivityStepsResultDocument, options);
}
export type ActivityStepsResultQueryHookResult = ReturnType<
  typeof useActivityStepsResultQuery
>;
export type ActivityStepsResultLazyQueryHookResult = ReturnType<
  typeof useActivityStepsResultLazyQuery
>;
export type ActivityStepsResultSuspenseQueryHookResult = ReturnType<
  typeof useActivityStepsResultSuspenseQuery
>;
export type ActivityStepsResultQueryResult = Apollo.QueryResult<
  ActivityStepsResultQuery,
  ActivityStepsResultQueryVariables
>;
export const FetchChallengePageDocument = gql`
  query FetchChallengePage($id: ID!) {
    fetchChallenge(id: $id) {
      challenge {
        ...ChallengePage
      }
    }
  }
  ${ChallengePageFragmentDoc}
`;

/**
 * __useFetchChallengePageQuery__
 *
 * To run a query within a React component, call `useFetchChallengePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChallengePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChallengePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchChallengePageQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchChallengePageQuery,
    FetchChallengePageQueryVariables
  > &
    (
      | { variables: FetchChallengePageQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchChallengePageQuery,
    FetchChallengePageQueryVariables
  >(FetchChallengePageDocument, options);
}
export function useFetchChallengePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchChallengePageQuery,
    FetchChallengePageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchChallengePageQuery,
    FetchChallengePageQueryVariables
  >(FetchChallengePageDocument, options);
}
export function useFetchChallengePageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchChallengePageQuery,
        FetchChallengePageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchChallengePageQuery,
    FetchChallengePageQueryVariables
  >(FetchChallengePageDocument, options);
}
export type FetchChallengePageQueryHookResult = ReturnType<
  typeof useFetchChallengePageQuery
>;
export type FetchChallengePageLazyQueryHookResult = ReturnType<
  typeof useFetchChallengePageLazyQuery
>;
export type FetchChallengePageSuspenseQueryHookResult = ReturnType<
  typeof useFetchChallengePageSuspenseQuery
>;
export type FetchChallengePageQueryResult = Apollo.QueryResult<
  FetchChallengePageQuery,
  FetchChallengePageQueryVariables
>;
export const FetchChallengeDocument = gql`
  query FetchChallenge($from: GraphQLDate, $id: ID!) {
    fetchChallenge(id: $id) {
      challenge {
        ...ChallengePage
        ...HabitChallengeBasicInfo
        ...StepChallengeBasicInfo
      }
    }
  }
  ${ChallengePageFragmentDoc}
  ${HabitChallengeBasicInfoFragmentDoc}
  ${StepChallengeBasicInfoFragmentDoc}
`;

/**
 * __useFetchChallengeQuery__
 *
 * To run a query within a React component, call `useFetchChallengeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChallengeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChallengeQuery({
 *   variables: {
 *      from: // value for 'from'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchChallengeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchChallengeQuery,
    FetchChallengeQueryVariables
  > &
    (
      | { variables: FetchChallengeQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchChallengeQuery, FetchChallengeQueryVariables>(
    FetchChallengeDocument,
    options,
  );
}
export function useFetchChallengeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchChallengeQuery,
    FetchChallengeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchChallengeQuery, FetchChallengeQueryVariables>(
    FetchChallengeDocument,
    options,
  );
}
export function useFetchChallengeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchChallengeQuery,
        FetchChallengeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchChallengeQuery,
    FetchChallengeQueryVariables
  >(FetchChallengeDocument, options);
}
export type FetchChallengeQueryHookResult = ReturnType<
  typeof useFetchChallengeQuery
>;
export type FetchChallengeLazyQueryHookResult = ReturnType<
  typeof useFetchChallengeLazyQuery
>;
export type FetchChallengeSuspenseQueryHookResult = ReturnType<
  typeof useFetchChallengeSuspenseQuery
>;
export type FetchChallengeQueryResult = Apollo.QueryResult<
  FetchChallengeQuery,
  FetchChallengeQueryVariables
>;
export const JoinTeamDocument = gql`
  mutation JoinTeam($challengeId: ID!, $teamId: ID!) {
    joinChallengeTeam(request: { teamId: $teamId, challengeId: $challengeId }) {
      joinedTeam
      reasonNotJoined
      team {
        id
        name
        userPermissions {
          ...TeamUserPermissions
        }
      }
    }
  }
  ${TeamUserPermissionsFragmentDoc}
`;
export type JoinTeamMutationFn = Apollo.MutationFunction<
  JoinTeamMutation,
  JoinTeamMutationVariables
>;

/**
 * __useJoinTeamMutation__
 *
 * To run a mutation, you first call `useJoinTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTeamMutation, { data, loading, error }] = useJoinTeamMutation({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useJoinTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinTeamMutation,
    JoinTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinTeamMutation, JoinTeamMutationVariables>(
    JoinTeamDocument,
    options,
  );
}
export type JoinTeamMutationHookResult = ReturnType<typeof useJoinTeamMutation>;
export type JoinTeamMutationResult = Apollo.MutationResult<JoinTeamMutation>;
export type JoinTeamMutationOptions = Apollo.BaseMutationOptions<
  JoinTeamMutation,
  JoinTeamMutationVariables
>;
export const LeaveTeamDocument = gql`
  mutation LeaveTeam($challengeId: ID!, $teamId: ID!) {
    leaveChallengeTeam(
      request: { challengeId: $challengeId, teamId: $teamId }
    ) {
      team {
        id
        name
        userPermissions {
          ...TeamUserPermissions
        }
      }
      departedTeam
      reasonNotDeparted
    }
  }
  ${TeamUserPermissionsFragmentDoc}
`;
export type LeaveTeamMutationFn = Apollo.MutationFunction<
  LeaveTeamMutation,
  LeaveTeamMutationVariables
>;

/**
 * __useLeaveTeamMutation__
 *
 * To run a mutation, you first call `useLeaveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTeamMutation, { data, loading, error }] = useLeaveTeamMutation({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useLeaveTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveTeamMutation,
    LeaveTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveTeamMutation, LeaveTeamMutationVariables>(
    LeaveTeamDocument,
    options,
  );
}
export type LeaveTeamMutationHookResult = ReturnType<
  typeof useLeaveTeamMutation
>;
export type LeaveTeamMutationResult = Apollo.MutationResult<LeaveTeamMutation>;
export type LeaveTeamMutationOptions = Apollo.BaseMutationOptions<
  LeaveTeamMutation,
  LeaveTeamMutationVariables
>;
export const JoinChallengeDocument = gql`
  mutation JoinChallenge($id: ID!) {
    joinChallenge(id: $id) {
      challenge {
        ...ChallengeJoined
      }
    }
  }
  ${ChallengeJoinedFragmentDoc}
`;
export type JoinChallengeMutationFn = Apollo.MutationFunction<
  JoinChallengeMutation,
  JoinChallengeMutationVariables
>;

/**
 * __useJoinChallengeMutation__
 *
 * To run a mutation, you first call `useJoinChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinChallengeMutation, { data, loading, error }] = useJoinChallengeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJoinChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinChallengeMutation,
    JoinChallengeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinChallengeMutation,
    JoinChallengeMutationVariables
  >(JoinChallengeDocument, options);
}
export type JoinChallengeMutationHookResult = ReturnType<
  typeof useJoinChallengeMutation
>;
export type JoinChallengeMutationResult =
  Apollo.MutationResult<JoinChallengeMutation>;
export type JoinChallengeMutationOptions = Apollo.BaseMutationOptions<
  JoinChallengeMutation,
  JoinChallengeMutationVariables
>;
export const SelectHabitsDocument = gql`
  mutation SelectHabits($challengeId: String!, $habits: [SelectHabitsData!]!) {
    selectHabits(request: { habits: $habits, challengeId: $challengeId }) {
      habits {
        ...HabitSelectionState
      }
    }
  }
  ${HabitSelectionStateFragmentDoc}
`;
export type SelectHabitsMutationFn = Apollo.MutationFunction<
  SelectHabitsMutation,
  SelectHabitsMutationVariables
>;

/**
 * __useSelectHabitsMutation__
 *
 * To run a mutation, you first call `useSelectHabitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectHabitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectHabitsMutation, { data, loading, error }] = useSelectHabitsMutation({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *      habits: // value for 'habits'
 *   },
 * });
 */
export function useSelectHabitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectHabitsMutation,
    SelectHabitsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SelectHabitsMutation,
    SelectHabitsMutationVariables
  >(SelectHabitsDocument, options);
}
export type SelectHabitsMutationHookResult = ReturnType<
  typeof useSelectHabitsMutation
>;
export type SelectHabitsMutationResult =
  Apollo.MutationResult<SelectHabitsMutation>;
export type SelectHabitsMutationOptions = Apollo.BaseMutationOptions<
  SelectHabitsMutation,
  SelectHabitsMutationVariables
>;
export const ChallengesHubPageDocument = gql`
  query ChallengesHubPage($from: GraphQLDate) {
    challenges: fetchChallenges(
      where: { status: [NotStarted, InProgress, Completed] }
    ) {
      ...HubList
    }
  }
  ${HubListFragmentDoc}
`;

/**
 * __useChallengesHubPageQuery__
 *
 * To run a query within a React component, call `useChallengesHubPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useChallengesHubPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChallengesHubPageQuery({
 *   variables: {
 *      from: // value for 'from'
 *   },
 * });
 */
export function useChallengesHubPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChallengesHubPageQuery,
    ChallengesHubPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChallengesHubPageQuery,
    ChallengesHubPageQueryVariables
  >(ChallengesHubPageDocument, options);
}
export function useChallengesHubPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChallengesHubPageQuery,
    ChallengesHubPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChallengesHubPageQuery,
    ChallengesHubPageQueryVariables
  >(ChallengesHubPageDocument, options);
}
export function useChallengesHubPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ChallengesHubPageQuery,
        ChallengesHubPageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ChallengesHubPageQuery,
    ChallengesHubPageQueryVariables
  >(ChallengesHubPageDocument, options);
}
export type ChallengesHubPageQueryHookResult = ReturnType<
  typeof useChallengesHubPageQuery
>;
export type ChallengesHubPageLazyQueryHookResult = ReturnType<
  typeof useChallengesHubPageLazyQuery
>;
export type ChallengesHubPageSuspenseQueryHookResult = ReturnType<
  typeof useChallengesHubPageSuspenseQuery
>;
export type ChallengesHubPageQueryResult = Apollo.QueryResult<
  ChallengesHubPageQuery,
  ChallengesHubPageQueryVariables
>;
export const LeaderboardIndividualDocument = gql`
  query LeaderboardIndividual($id: ID!, $after: String) {
    fetchChallenge(id: $id) {
      challenge {
        ... on StepChallenge {
          challengeId
          leaderboardIndividuals(afterCursor: $after) {
            pagination {
              ...Pagination
            }
            leaderboard {
              ...LeaderboardIndividual
            }
          }
        }
        ... on HabitChallenge {
          challengeId
          leaderboardIndividuals(afterCursor: $after) {
            pagination {
              ...Pagination
            }
            leaderboard {
              ...LeaderboardIndividual
            }
          }
        }
      }
    }
  }
  ${PaginationFragmentDoc}
  ${LeaderboardIndividualFragmentDoc}
`;

/**
 * __useLeaderboardIndividualQuery__
 *
 * To run a query within a React component, call `useLeaderboardIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaderboardIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaderboardIndividualQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useLeaderboardIndividualQuery(
  baseOptions: Apollo.QueryHookOptions<
    LeaderboardIndividualQuery,
    LeaderboardIndividualQueryVariables
  > &
    (
      | { variables: LeaderboardIndividualQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LeaderboardIndividualQuery,
    LeaderboardIndividualQueryVariables
  >(LeaderboardIndividualDocument, options);
}
export function useLeaderboardIndividualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeaderboardIndividualQuery,
    LeaderboardIndividualQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LeaderboardIndividualQuery,
    LeaderboardIndividualQueryVariables
  >(LeaderboardIndividualDocument, options);
}
export function useLeaderboardIndividualSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LeaderboardIndividualQuery,
        LeaderboardIndividualQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LeaderboardIndividualQuery,
    LeaderboardIndividualQueryVariables
  >(LeaderboardIndividualDocument, options);
}
export type LeaderboardIndividualQueryHookResult = ReturnType<
  typeof useLeaderboardIndividualQuery
>;
export type LeaderboardIndividualLazyQueryHookResult = ReturnType<
  typeof useLeaderboardIndividualLazyQuery
>;
export type LeaderboardIndividualSuspenseQueryHookResult = ReturnType<
  typeof useLeaderboardIndividualSuspenseQuery
>;
export type LeaderboardIndividualQueryResult = Apollo.QueryResult<
  LeaderboardIndividualQuery,
  LeaderboardIndividualQueryVariables
>;
export const LeaderboardTeamDocument = gql`
  query LeaderboardTeam($id: ID!, $after: String) {
    fetchChallenge(id: $id) {
      challenge {
        ... on StepChallenge {
          challengeId
          leaderboardTeams(afterCursor: $after) {
            pagination {
              ...Pagination
            }
            leaderboard {
              ...TeamsLeaderboardListItem
            }
          }
          status
          userTeamMembership {
            canCreateATeam
          }
        }
        ... on HabitChallenge {
          challengeId
          leaderboardTeams(afterCursor: $after) {
            pagination {
              ...Pagination
            }
            leaderboard {
              ...TeamsLeaderboardListItem
            }
          }
          status
          userTeamMembership {
            canCreateATeam
          }
        }
      }
    }
  }
  ${PaginationFragmentDoc}
  ${TeamsLeaderboardListItemFragmentDoc}
`;

/**
 * __useLeaderboardTeamQuery__
 *
 * To run a query within a React component, call `useLeaderboardTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaderboardTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaderboardTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useLeaderboardTeamQuery(
  baseOptions: Apollo.QueryHookOptions<
    LeaderboardTeamQuery,
    LeaderboardTeamQueryVariables
  > &
    (
      | { variables: LeaderboardTeamQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeaderboardTeamQuery, LeaderboardTeamQueryVariables>(
    LeaderboardTeamDocument,
    options,
  );
}
export function useLeaderboardTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeaderboardTeamQuery,
    LeaderboardTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LeaderboardTeamQuery,
    LeaderboardTeamQueryVariables
  >(LeaderboardTeamDocument, options);
}
export function useLeaderboardTeamSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LeaderboardTeamQuery,
        LeaderboardTeamQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LeaderboardTeamQuery,
    LeaderboardTeamQueryVariables
  >(LeaderboardTeamDocument, options);
}
export type LeaderboardTeamQueryHookResult = ReturnType<
  typeof useLeaderboardTeamQuery
>;
export type LeaderboardTeamLazyQueryHookResult = ReturnType<
  typeof useLeaderboardTeamLazyQuery
>;
export type LeaderboardTeamSuspenseQueryHookResult = ReturnType<
  typeof useLeaderboardTeamSuspenseQuery
>;
export type LeaderboardTeamQueryResult = Apollo.QueryResult<
  LeaderboardTeamQuery,
  LeaderboardTeamQueryVariables
>;
export const LeaveChallengeDocument = gql`
  mutation LeaveChallenge($id: ID!) {
    leaveChallenge(id: $id) {
      challenge {
        ...ChallengeStatus
      }
    }
  }
  ${ChallengeStatusFragmentDoc}
`;
export type LeaveChallengeMutationFn = Apollo.MutationFunction<
  LeaveChallengeMutation,
  LeaveChallengeMutationVariables
>;

/**
 * __useLeaveChallengeMutation__
 *
 * To run a mutation, you first call `useLeaveChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveChallengeMutation, { data, loading, error }] = useLeaveChallengeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveChallengeMutation,
    LeaveChallengeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveChallengeMutation,
    LeaveChallengeMutationVariables
  >(LeaveChallengeDocument, options);
}
export type LeaveChallengeMutationHookResult = ReturnType<
  typeof useLeaveChallengeMutation
>;
export type LeaveChallengeMutationResult =
  Apollo.MutationResult<LeaveChallengeMutation>;
export type LeaveChallengeMutationOptions = Apollo.BaseMutationOptions<
  LeaveChallengeMutation,
  LeaveChallengeMutationVariables
>;
export const TeamCreateDocument = gql`
  mutation TeamCreate($id: ID!, $name: String!) {
    createChallengeTeam(request: { challengeId: $id, teamName: $name }) {
      team {
        id
      }
    }
  }
`;
export type TeamCreateMutationFn = Apollo.MutationFunction<
  TeamCreateMutation,
  TeamCreateMutationVariables
>;

/**
 * __useTeamCreateMutation__
 *
 * To run a mutation, you first call `useTeamCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamCreateMutation, { data, loading, error }] = useTeamCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTeamCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TeamCreateMutation,
    TeamCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TeamCreateMutation, TeamCreateMutationVariables>(
    TeamCreateDocument,
    options,
  );
}
export type TeamCreateMutationHookResult = ReturnType<
  typeof useTeamCreateMutation
>;
export type TeamCreateMutationResult =
  Apollo.MutationResult<TeamCreateMutation>;
export type TeamCreateMutationOptions = Apollo.BaseMutationOptions<
  TeamCreateMutation,
  TeamCreateMutationVariables
>;
export const TeamMembersDocument = gql`
  query TeamMembers($id: ID!, $afterCursor: String, $challengeId: ID!) {
    fetchChallengeTeam(id: $id) {
      team {
        image {
          url
        }
        name
        userPermissions {
          membershipPrompt
        }
      }
      leaderboardTeamMembers(
        challengeId: $challengeId
        afterCursor: $afterCursor
      ) {
        ...TeamLeaderboard
      }
    }
  }
  ${TeamLeaderboardFragmentDoc}
`;

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      afterCursor: // value for 'afterCursor'
 *      challengeId: // value for 'challengeId'
 *   },
 * });
 */
export function useTeamMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamMembersQuery,
    TeamMembersQueryVariables
  > &
    (
      | { variables: TeamMembersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(
    TeamMembersDocument,
    options,
  );
}
export function useTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamMembersQuery,
    TeamMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(
    TeamMembersDocument,
    options,
  );
}
export function useTeamMembersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TeamMembersQuery,
        TeamMembersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TeamMembersQuery, TeamMembersQueryVariables>(
    TeamMembersDocument,
    options,
  );
}
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<
  typeof useTeamMembersLazyQuery
>;
export type TeamMembersSuspenseQueryHookResult = ReturnType<
  typeof useTeamMembersSuspenseQuery
>;
export type TeamMembersQueryResult = Apollo.QueryResult<
  TeamMembersQuery,
  TeamMembersQueryVariables
>;
export const ReportContentDocument = gql`
  mutation ReportContent($input: ReportInput!) {
    report(input: $input) {
      kind
    }
  }
`;
export type ReportContentMutationFn = Apollo.MutationFunction<
  ReportContentMutation,
  ReportContentMutationVariables
>;

/**
 * __useReportContentMutation__
 *
 * To run a mutation, you first call `useReportContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportContentMutation, { data, loading, error }] = useReportContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportContentMutation,
    ReportContentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReportContentMutation,
    ReportContentMutationVariables
  >(ReportContentDocument, options);
}
export type ReportContentMutationHookResult = ReturnType<
  typeof useReportContentMutation
>;
export type ReportContentMutationResult =
  Apollo.MutationResult<ReportContentMutation>;
export type ReportContentMutationOptions = Apollo.BaseMutationOptions<
  ReportContentMutation,
  ReportContentMutationVariables
>;
export const JournalEntriesDocument = gql`
  query JournalEntries($input: JournalEntriesInput!) {
    journalEntries(input: $input) {
      entries {
        paging {
          isLastPage
          cursors {
            after
          }
        }
        data {
          ...JournalingEntry
        }
      }
    }
  }
  ${JournalingEntryFragmentDoc}
`;

/**
 * __useJournalEntriesQuery__
 *
 * To run a query within a React component, call `useJournalEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalEntriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJournalEntriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    JournalEntriesQuery,
    JournalEntriesQueryVariables
  > &
    (
      | { variables: JournalEntriesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JournalEntriesQuery, JournalEntriesQueryVariables>(
    JournalEntriesDocument,
    options,
  );
}
export function useJournalEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JournalEntriesQuery,
    JournalEntriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JournalEntriesQuery, JournalEntriesQueryVariables>(
    JournalEntriesDocument,
    options,
  );
}
export function useJournalEntriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        JournalEntriesQuery,
        JournalEntriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    JournalEntriesQuery,
    JournalEntriesQueryVariables
  >(JournalEntriesDocument, options);
}
export type JournalEntriesQueryHookResult = ReturnType<
  typeof useJournalEntriesQuery
>;
export type JournalEntriesLazyQueryHookResult = ReturnType<
  typeof useJournalEntriesLazyQuery
>;
export type JournalEntriesSuspenseQueryHookResult = ReturnType<
  typeof useJournalEntriesSuspenseQuery
>;
export type JournalEntriesQueryResult = Apollo.QueryResult<
  JournalEntriesQuery,
  JournalEntriesQueryVariables
>;
export const DeleteJournalEntryDocument = gql`
  mutation DeleteJournalEntry($input: DeleteJournalEntryInput!) {
    deleteJournalEntry(input: $input) {
      received
    }
  }
`;
export type DeleteJournalEntryMutationFn = Apollo.MutationFunction<
  DeleteJournalEntryMutation,
  DeleteJournalEntryMutationVariables
>;

/**
 * __useDeleteJournalEntryMutation__
 *
 * To run a mutation, you first call `useDeleteJournalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJournalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJournalEntryMutation, { data, loading, error }] = useDeleteJournalEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJournalEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteJournalEntryMutation,
    DeleteJournalEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteJournalEntryMutation,
    DeleteJournalEntryMutationVariables
  >(DeleteJournalEntryDocument, options);
}
export type DeleteJournalEntryMutationHookResult = ReturnType<
  typeof useDeleteJournalEntryMutation
>;
export type DeleteJournalEntryMutationResult =
  Apollo.MutationResult<DeleteJournalEntryMutation>;
export type DeleteJournalEntryMutationOptions = Apollo.BaseMutationOptions<
  DeleteJournalEntryMutation,
  DeleteJournalEntryMutationVariables
>;
export const EditJournalEntryDocument = gql`
  mutation EditJournalEntry($input: EditJournalEntryInput!) {
    editJournalEntry(input: $input) {
      entry {
        ...JournalingEntry
      }
    }
  }
  ${JournalingEntryFragmentDoc}
`;
export type EditJournalEntryMutationFn = Apollo.MutationFunction<
  EditJournalEntryMutation,
  EditJournalEntryMutationVariables
>;

/**
 * __useEditJournalEntryMutation__
 *
 * To run a mutation, you first call `useEditJournalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditJournalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editJournalEntryMutation, { data, loading, error }] = useEditJournalEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditJournalEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditJournalEntryMutation,
    EditJournalEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditJournalEntryMutation,
    EditJournalEntryMutationVariables
  >(EditJournalEntryDocument, options);
}
export type EditJournalEntryMutationHookResult = ReturnType<
  typeof useEditJournalEntryMutation
>;
export type EditJournalEntryMutationResult =
  Apollo.MutationResult<EditJournalEntryMutation>;
export type EditJournalEntryMutationOptions = Apollo.BaseMutationOptions<
  EditJournalEntryMutation,
  EditJournalEntryMutationVariables
>;
export const CreateJournalEntryDocument = gql`
  mutation CreateJournalEntry($input: CreateJournalEntryInput!) {
    createJournalEntry(input: $input) {
      entry {
        ...JournalingEntry
      }
    }
  }
  ${JournalingEntryFragmentDoc}
`;
export type CreateJournalEntryMutationFn = Apollo.MutationFunction<
  CreateJournalEntryMutation,
  CreateJournalEntryMutationVariables
>;

/**
 * __useCreateJournalEntryMutation__
 *
 * To run a mutation, you first call `useCreateJournalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJournalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJournalEntryMutation, { data, loading, error }] = useCreateJournalEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJournalEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateJournalEntryMutation,
    CreateJournalEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateJournalEntryMutation,
    CreateJournalEntryMutationVariables
  >(CreateJournalEntryDocument, options);
}
export type CreateJournalEntryMutationHookResult = ReturnType<
  typeof useCreateJournalEntryMutation
>;
export type CreateJournalEntryMutationResult =
  Apollo.MutationResult<CreateJournalEntryMutation>;
export type CreateJournalEntryMutationOptions = Apollo.BaseMutationOptions<
  CreateJournalEntryMutation,
  CreateJournalEntryMutationVariables
>;
export const AverageDailyMoodInsightsDocument = gql`
  query AverageDailyMoodInsights($input: AverageDailyMoodInsightsInput) {
    averageDailyMoodInsights(input: $input) {
      data {
        date
        value
      }
      message
      sampleSize
      title
    }
  }
`;

/**
 * __useAverageDailyMoodInsightsQuery__
 *
 * To run a query within a React component, call `useAverageDailyMoodInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAverageDailyMoodInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAverageDailyMoodInsightsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAverageDailyMoodInsightsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AverageDailyMoodInsightsQuery,
    AverageDailyMoodInsightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AverageDailyMoodInsightsQuery,
    AverageDailyMoodInsightsQueryVariables
  >(AverageDailyMoodInsightsDocument, options);
}
export function useAverageDailyMoodInsightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AverageDailyMoodInsightsQuery,
    AverageDailyMoodInsightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AverageDailyMoodInsightsQuery,
    AverageDailyMoodInsightsQueryVariables
  >(AverageDailyMoodInsightsDocument, options);
}
export function useAverageDailyMoodInsightsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AverageDailyMoodInsightsQuery,
        AverageDailyMoodInsightsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AverageDailyMoodInsightsQuery,
    AverageDailyMoodInsightsQueryVariables
  >(AverageDailyMoodInsightsDocument, options);
}
export type AverageDailyMoodInsightsQueryHookResult = ReturnType<
  typeof useAverageDailyMoodInsightsQuery
>;
export type AverageDailyMoodInsightsLazyQueryHookResult = ReturnType<
  typeof useAverageDailyMoodInsightsLazyQuery
>;
export type AverageDailyMoodInsightsSuspenseQueryHookResult = ReturnType<
  typeof useAverageDailyMoodInsightsSuspenseQuery
>;
export type AverageDailyMoodInsightsQueryResult = Apollo.QueryResult<
  AverageDailyMoodInsightsQuery,
  AverageDailyMoodInsightsQueryVariables
>;
export const InfluencesMoodInsightsDocument = gql`
  query InfluencesMoodInsights($input: InfluencesMoodInsightsInput) {
    influencesMoodInsights(input: $input) {
      data {
        factor
        valueMax
        valueMin
      }
      message
      sampleSize
      title
    }
  }
`;

/**
 * __useInfluencesMoodInsightsQuery__
 *
 * To run a query within a React component, call `useInfluencesMoodInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfluencesMoodInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfluencesMoodInsightsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInfluencesMoodInsightsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InfluencesMoodInsightsQuery,
    InfluencesMoodInsightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InfluencesMoodInsightsQuery,
    InfluencesMoodInsightsQueryVariables
  >(InfluencesMoodInsightsDocument, options);
}
export function useInfluencesMoodInsightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InfluencesMoodInsightsQuery,
    InfluencesMoodInsightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InfluencesMoodInsightsQuery,
    InfluencesMoodInsightsQueryVariables
  >(InfluencesMoodInsightsDocument, options);
}
export function useInfluencesMoodInsightsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InfluencesMoodInsightsQuery,
        InfluencesMoodInsightsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InfluencesMoodInsightsQuery,
    InfluencesMoodInsightsQueryVariables
  >(InfluencesMoodInsightsDocument, options);
}
export type InfluencesMoodInsightsQueryHookResult = ReturnType<
  typeof useInfluencesMoodInsightsQuery
>;
export type InfluencesMoodInsightsLazyQueryHookResult = ReturnType<
  typeof useInfluencesMoodInsightsLazyQuery
>;
export type InfluencesMoodInsightsSuspenseQueryHookResult = ReturnType<
  typeof useInfluencesMoodInsightsSuspenseQuery
>;
export type InfluencesMoodInsightsQueryResult = Apollo.QueryResult<
  InfluencesMoodInsightsQuery,
  InfluencesMoodInsightsQueryVariables
>;
export const MoodEntriesDocument = gql`
  query MoodEntries($input: MoodEntriesInput!) {
    moodEntries(input: $input) {
      entries {
        paging {
          isLastPage
          cursors {
            after
          }
        }
        data {
          ...MoodTrackingEntry
        }
      }
    }
  }
  ${MoodTrackingEntryFragmentDoc}
`;

/**
 * __useMoodEntriesQuery__
 *
 * To run a query within a React component, call `useMoodEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoodEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoodEntriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoodEntriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MoodEntriesQuery,
    MoodEntriesQueryVariables
  > &
    (
      | { variables: MoodEntriesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MoodEntriesQuery, MoodEntriesQueryVariables>(
    MoodEntriesDocument,
    options,
  );
}
export function useMoodEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MoodEntriesQuery,
    MoodEntriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MoodEntriesQuery, MoodEntriesQueryVariables>(
    MoodEntriesDocument,
    options,
  );
}
export function useMoodEntriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MoodEntriesQuery,
        MoodEntriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MoodEntriesQuery, MoodEntriesQueryVariables>(
    MoodEntriesDocument,
    options,
  );
}
export type MoodEntriesQueryHookResult = ReturnType<typeof useMoodEntriesQuery>;
export type MoodEntriesLazyQueryHookResult = ReturnType<
  typeof useMoodEntriesLazyQuery
>;
export type MoodEntriesSuspenseQueryHookResult = ReturnType<
  typeof useMoodEntriesSuspenseQuery
>;
export type MoodEntriesQueryResult = Apollo.QueryResult<
  MoodEntriesQuery,
  MoodEntriesQueryVariables
>;
export const DeleteMoodEntryDocument = gql`
  mutation DeleteMoodEntry($input: DeleteMoodEntryInput!) {
    deleteMoodEntry(input: $input) {
      received
    }
  }
`;
export type DeleteMoodEntryMutationFn = Apollo.MutationFunction<
  DeleteMoodEntryMutation,
  DeleteMoodEntryMutationVariables
>;

/**
 * __useDeleteMoodEntryMutation__
 *
 * To run a mutation, you first call `useDeleteMoodEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMoodEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMoodEntryMutation, { data, loading, error }] = useDeleteMoodEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMoodEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMoodEntryMutation,
    DeleteMoodEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMoodEntryMutation,
    DeleteMoodEntryMutationVariables
  >(DeleteMoodEntryDocument, options);
}
export type DeleteMoodEntryMutationHookResult = ReturnType<
  typeof useDeleteMoodEntryMutation
>;
export type DeleteMoodEntryMutationResult =
  Apollo.MutationResult<DeleteMoodEntryMutation>;
export type DeleteMoodEntryMutationOptions = Apollo.BaseMutationOptions<
  DeleteMoodEntryMutation,
  DeleteMoodEntryMutationVariables
>;
export const EditMoodEntryDocument = gql`
  mutation EditMoodEntry($input: EditMoodEntryInput!) {
    editMoodEntry(input: $input) {
      entry {
        ...MoodTrackingEntry
      }
    }
  }
  ${MoodTrackingEntryFragmentDoc}
`;
export type EditMoodEntryMutationFn = Apollo.MutationFunction<
  EditMoodEntryMutation,
  EditMoodEntryMutationVariables
>;

/**
 * __useEditMoodEntryMutation__
 *
 * To run a mutation, you first call `useEditMoodEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMoodEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMoodEntryMutation, { data, loading, error }] = useEditMoodEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMoodEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditMoodEntryMutation,
    EditMoodEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditMoodEntryMutation,
    EditMoodEntryMutationVariables
  >(EditMoodEntryDocument, options);
}
export type EditMoodEntryMutationHookResult = ReturnType<
  typeof useEditMoodEntryMutation
>;
export type EditMoodEntryMutationResult =
  Apollo.MutationResult<EditMoodEntryMutation>;
export type EditMoodEntryMutationOptions = Apollo.BaseMutationOptions<
  EditMoodEntryMutation,
  EditMoodEntryMutationVariables
>;
export const AvailableMoodFactorsDocument = gql`
  query AvailableMoodFactors {
    availableMoodFactors {
      factors {
        ...MoodFactor
      }
    }
  }
  ${MoodFactorFragmentDoc}
`;

/**
 * __useAvailableMoodFactorsQuery__
 *
 * To run a query within a React component, call `useAvailableMoodFactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableMoodFactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableMoodFactorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableMoodFactorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableMoodFactorsQuery,
    AvailableMoodFactorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableMoodFactorsQuery,
    AvailableMoodFactorsQueryVariables
  >(AvailableMoodFactorsDocument, options);
}
export function useAvailableMoodFactorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableMoodFactorsQuery,
    AvailableMoodFactorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableMoodFactorsQuery,
    AvailableMoodFactorsQueryVariables
  >(AvailableMoodFactorsDocument, options);
}
export function useAvailableMoodFactorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AvailableMoodFactorsQuery,
        AvailableMoodFactorsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AvailableMoodFactorsQuery,
    AvailableMoodFactorsQueryVariables
  >(AvailableMoodFactorsDocument, options);
}
export type AvailableMoodFactorsQueryHookResult = ReturnType<
  typeof useAvailableMoodFactorsQuery
>;
export type AvailableMoodFactorsLazyQueryHookResult = ReturnType<
  typeof useAvailableMoodFactorsLazyQuery
>;
export type AvailableMoodFactorsSuspenseQueryHookResult = ReturnType<
  typeof useAvailableMoodFactorsSuspenseQuery
>;
export type AvailableMoodFactorsQueryResult = Apollo.QueryResult<
  AvailableMoodFactorsQuery,
  AvailableMoodFactorsQueryVariables
>;
export const CreateMoodEntryDocument = gql`
  mutation CreateMoodEntry($input: CreateMoodEntryInput!) {
    createMoodEntry(input: $input) {
      entry {
        ...MoodTrackingEntry
      }
    }
  }
  ${MoodTrackingEntryFragmentDoc}
`;
export type CreateMoodEntryMutationFn = Apollo.MutationFunction<
  CreateMoodEntryMutation,
  CreateMoodEntryMutationVariables
>;

/**
 * __useCreateMoodEntryMutation__
 *
 * To run a mutation, you first call `useCreateMoodEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMoodEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMoodEntryMutation, { data, loading, error }] = useCreateMoodEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMoodEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMoodEntryMutation,
    CreateMoodEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMoodEntryMutation,
    CreateMoodEntryMutationVariables
  >(CreateMoodEntryDocument, options);
}
export type CreateMoodEntryMutationHookResult = ReturnType<
  typeof useCreateMoodEntryMutation
>;
export type CreateMoodEntryMutationResult =
  Apollo.MutationResult<CreateMoodEntryMutation>;
export type CreateMoodEntryMutationOptions = Apollo.BaseMutationOptions<
  CreateMoodEntryMutation,
  CreateMoodEntryMutationVariables
>;
export const CreateCustomMoodFactorDocument = gql`
  mutation CreateCustomMoodFactor($input: CreateCustomMoodFactorInput!) {
    createCustomMoodFactor(input: $input) {
      moodFactor {
        ...MoodFactor
      }
    }
  }
  ${MoodFactorFragmentDoc}
`;
export type CreateCustomMoodFactorMutationFn = Apollo.MutationFunction<
  CreateCustomMoodFactorMutation,
  CreateCustomMoodFactorMutationVariables
>;

/**
 * __useCreateCustomMoodFactorMutation__
 *
 * To run a mutation, you first call `useCreateCustomMoodFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomMoodFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomMoodFactorMutation, { data, loading, error }] = useCreateCustomMoodFactorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomMoodFactorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomMoodFactorMutation,
    CreateCustomMoodFactorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomMoodFactorMutation,
    CreateCustomMoodFactorMutationVariables
  >(CreateCustomMoodFactorDocument, options);
}
export type CreateCustomMoodFactorMutationHookResult = ReturnType<
  typeof useCreateCustomMoodFactorMutation
>;
export type CreateCustomMoodFactorMutationResult =
  Apollo.MutationResult<CreateCustomMoodFactorMutation>;
export type CreateCustomMoodFactorMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomMoodFactorMutation,
  CreateCustomMoodFactorMutationVariables
>;
export const DeleteCustomMoodFactorsDocument = gql`
  mutation DeleteCustomMoodFactors($input: DeleteCustomMoodFactorsInput!) {
    deleteCustomMoodFactors(input: $input) {
      received
    }
  }
`;
export type DeleteCustomMoodFactorsMutationFn = Apollo.MutationFunction<
  DeleteCustomMoodFactorsMutation,
  DeleteCustomMoodFactorsMutationVariables
>;

/**
 * __useDeleteCustomMoodFactorsMutation__
 *
 * To run a mutation, you first call `useDeleteCustomMoodFactorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomMoodFactorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomMoodFactorsMutation, { data, loading, error }] = useDeleteCustomMoodFactorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomMoodFactorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomMoodFactorsMutation,
    DeleteCustomMoodFactorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCustomMoodFactorsMutation,
    DeleteCustomMoodFactorsMutationVariables
  >(DeleteCustomMoodFactorsDocument, options);
}
export type DeleteCustomMoodFactorsMutationHookResult = ReturnType<
  typeof useDeleteCustomMoodFactorsMutation
>;
export type DeleteCustomMoodFactorsMutationResult =
  Apollo.MutationResult<DeleteCustomMoodFactorsMutation>;
export type DeleteCustomMoodFactorsMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomMoodFactorsMutation,
  DeleteCustomMoodFactorsMutationVariables
>;
export const NeedToRetakeAssessmentsDocument = gql`
  query NeedToRetakeAssessments {
    preAssessmentResults {
      isTimeToRetakeAssessments
    }
  }
`;

/**
 * __useNeedToRetakeAssessmentsQuery__
 *
 * To run a query within a React component, call `useNeedToRetakeAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNeedToRetakeAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNeedToRetakeAssessmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNeedToRetakeAssessmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NeedToRetakeAssessmentsQuery,
    NeedToRetakeAssessmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NeedToRetakeAssessmentsQuery,
    NeedToRetakeAssessmentsQueryVariables
  >(NeedToRetakeAssessmentsDocument, options);
}
export function useNeedToRetakeAssessmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NeedToRetakeAssessmentsQuery,
    NeedToRetakeAssessmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NeedToRetakeAssessmentsQuery,
    NeedToRetakeAssessmentsQueryVariables
  >(NeedToRetakeAssessmentsDocument, options);
}
export function useNeedToRetakeAssessmentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        NeedToRetakeAssessmentsQuery,
        NeedToRetakeAssessmentsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    NeedToRetakeAssessmentsQuery,
    NeedToRetakeAssessmentsQueryVariables
  >(NeedToRetakeAssessmentsDocument, options);
}
export type NeedToRetakeAssessmentsQueryHookResult = ReturnType<
  typeof useNeedToRetakeAssessmentsQuery
>;
export type NeedToRetakeAssessmentsLazyQueryHookResult = ReturnType<
  typeof useNeedToRetakeAssessmentsLazyQuery
>;
export type NeedToRetakeAssessmentsSuspenseQueryHookResult = ReturnType<
  typeof useNeedToRetakeAssessmentsSuspenseQuery
>;
export type NeedToRetakeAssessmentsQueryResult = Apollo.QueryResult<
  NeedToRetakeAssessmentsQuery,
  NeedToRetakeAssessmentsQueryVariables
>;
export const MyCareUpcomingAppointmentsDocument = gql`
  query MyCareUpcomingAppointments($input: FetchAppointmentsInput!) {
    fetchAppointments(input: $input) {
      appointments {
        data {
          ...MyCareAppointment
        }
      }
    }
  }
  ${MyCareAppointmentFragmentDoc}
`;

/**
 * __useMyCareUpcomingAppointmentsQuery__
 *
 * To run a query within a React component, call `useMyCareUpcomingAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCareUpcomingAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCareUpcomingAppointmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyCareUpcomingAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyCareUpcomingAppointmentsQuery,
    MyCareUpcomingAppointmentsQueryVariables
  > &
    (
      | { variables: MyCareUpcomingAppointmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyCareUpcomingAppointmentsQuery,
    MyCareUpcomingAppointmentsQueryVariables
  >(MyCareUpcomingAppointmentsDocument, options);
}
export function useMyCareUpcomingAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyCareUpcomingAppointmentsQuery,
    MyCareUpcomingAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyCareUpcomingAppointmentsQuery,
    MyCareUpcomingAppointmentsQueryVariables
  >(MyCareUpcomingAppointmentsDocument, options);
}
export function useMyCareUpcomingAppointmentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyCareUpcomingAppointmentsQuery,
        MyCareUpcomingAppointmentsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyCareUpcomingAppointmentsQuery,
    MyCareUpcomingAppointmentsQueryVariables
  >(MyCareUpcomingAppointmentsDocument, options);
}
export type MyCareUpcomingAppointmentsQueryHookResult = ReturnType<
  typeof useMyCareUpcomingAppointmentsQuery
>;
export type MyCareUpcomingAppointmentsLazyQueryHookResult = ReturnType<
  typeof useMyCareUpcomingAppointmentsLazyQuery
>;
export type MyCareUpcomingAppointmentsSuspenseQueryHookResult = ReturnType<
  typeof useMyCareUpcomingAppointmentsSuspenseQuery
>;
export type MyCareUpcomingAppointmentsQueryResult = Apollo.QueryResult<
  MyCareUpcomingAppointmentsQuery,
  MyCareUpcomingAppointmentsQueryVariables
>;
export const PaymentRequirementsDocument = gql`
  query PaymentRequirements {
    paymentRequirements {
      isPaymentRequired
    }
  }
`;

/**
 * __usePaymentRequirementsQuery__
 *
 * To run a query within a React component, call `usePaymentRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentRequirementsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentRequirementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaymentRequirementsQuery,
    PaymentRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaymentRequirementsQuery,
    PaymentRequirementsQueryVariables
  >(PaymentRequirementsDocument, options);
}
export function usePaymentRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentRequirementsQuery,
    PaymentRequirementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaymentRequirementsQuery,
    PaymentRequirementsQueryVariables
  >(PaymentRequirementsDocument, options);
}
export function usePaymentRequirementsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PaymentRequirementsQuery,
        PaymentRequirementsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PaymentRequirementsQuery,
    PaymentRequirementsQueryVariables
  >(PaymentRequirementsDocument, options);
}
export type PaymentRequirementsQueryHookResult = ReturnType<
  typeof usePaymentRequirementsQuery
>;
export type PaymentRequirementsLazyQueryHookResult = ReturnType<
  typeof usePaymentRequirementsLazyQuery
>;
export type PaymentRequirementsSuspenseQueryHookResult = ReturnType<
  typeof usePaymentRequirementsSuspenseQuery
>;
export type PaymentRequirementsQueryResult = Apollo.QueryResult<
  PaymentRequirementsQuery,
  PaymentRequirementsQueryVariables
>;
export const FetchPreAssessmentResultsDocument = gql`
  query FetchPreAssessmentResults {
    preAssessmentResults {
      results {
        ...PreAssessmentResults
      }
    }
  }
  ${PreAssessmentResultsFragmentDoc}
`;

/**
 * __useFetchPreAssessmentResultsQuery__
 *
 * To run a query within a React component, call `useFetchPreAssessmentResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPreAssessmentResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPreAssessmentResultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPreAssessmentResultsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchPreAssessmentResultsQuery,
    FetchPreAssessmentResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchPreAssessmentResultsQuery,
    FetchPreAssessmentResultsQueryVariables
  >(FetchPreAssessmentResultsDocument, options);
}
export function useFetchPreAssessmentResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPreAssessmentResultsQuery,
    FetchPreAssessmentResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchPreAssessmentResultsQuery,
    FetchPreAssessmentResultsQueryVariables
  >(FetchPreAssessmentResultsDocument, options);
}
export function useFetchPreAssessmentResultsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchPreAssessmentResultsQuery,
        FetchPreAssessmentResultsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchPreAssessmentResultsQuery,
    FetchPreAssessmentResultsQueryVariables
  >(FetchPreAssessmentResultsDocument, options);
}
export type FetchPreAssessmentResultsQueryHookResult = ReturnType<
  typeof useFetchPreAssessmentResultsQuery
>;
export type FetchPreAssessmentResultsLazyQueryHookResult = ReturnType<
  typeof useFetchPreAssessmentResultsLazyQuery
>;
export type FetchPreAssessmentResultsSuspenseQueryHookResult = ReturnType<
  typeof useFetchPreAssessmentResultsSuspenseQuery
>;
export type FetchPreAssessmentResultsQueryResult = Apollo.QueryResult<
  FetchPreAssessmentResultsQuery,
  FetchPreAssessmentResultsQueryVariables
>;
export const CareIssuesSelectedDocument = gql`
  query CareIssuesSelected {
    getCareIssues {
      careIssues {
        ...CareIssueSelected
      }
    }
  }
  ${CareIssueSelectedFragmentDoc}
`;

/**
 * __useCareIssuesSelectedQuery__
 *
 * To run a query within a React component, call `useCareIssuesSelectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareIssuesSelectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareIssuesSelectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareIssuesSelectedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CareIssuesSelectedQuery,
    CareIssuesSelectedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CareIssuesSelectedQuery,
    CareIssuesSelectedQueryVariables
  >(CareIssuesSelectedDocument, options);
}
export function useCareIssuesSelectedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareIssuesSelectedQuery,
    CareIssuesSelectedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CareIssuesSelectedQuery,
    CareIssuesSelectedQueryVariables
  >(CareIssuesSelectedDocument, options);
}
export function useCareIssuesSelectedSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CareIssuesSelectedQuery,
        CareIssuesSelectedQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CareIssuesSelectedQuery,
    CareIssuesSelectedQueryVariables
  >(CareIssuesSelectedDocument, options);
}
export type CareIssuesSelectedQueryHookResult = ReturnType<
  typeof useCareIssuesSelectedQuery
>;
export type CareIssuesSelectedLazyQueryHookResult = ReturnType<
  typeof useCareIssuesSelectedLazyQuery
>;
export type CareIssuesSelectedSuspenseQueryHookResult = ReturnType<
  typeof useCareIssuesSelectedSuspenseQuery
>;
export type CareIssuesSelectedQueryResult = Apollo.QueryResult<
  CareIssuesSelectedQuery,
  CareIssuesSelectedQueryVariables
>;
export const CarePlansDocument = gql`
  query CarePlans($input: AvailableCarePlansInput) {
    availableCarePlans(input: $input) {
      carePlans {
        data {
          id
          name
          description
        }
      }
    }
  }
`;

/**
 * __useCarePlansQuery__
 *
 * To run a query within a React component, call `useCarePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarePlansQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCarePlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CarePlansQuery,
    CarePlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarePlansQuery, CarePlansQueryVariables>(
    CarePlansDocument,
    options,
  );
}
export function useCarePlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarePlansQuery,
    CarePlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CarePlansQuery, CarePlansQueryVariables>(
    CarePlansDocument,
    options,
  );
}
export function useCarePlansSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CarePlansQuery, CarePlansQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CarePlansQuery, CarePlansQueryVariables>(
    CarePlansDocument,
    options,
  );
}
export type CarePlansQueryHookResult = ReturnType<typeof useCarePlansQuery>;
export type CarePlansLazyQueryHookResult = ReturnType<
  typeof useCarePlansLazyQuery
>;
export type CarePlansSuspenseQueryHookResult = ReturnType<
  typeof useCarePlansSuspenseQuery
>;
export type CarePlansQueryResult = Apollo.QueryResult<
  CarePlansQuery,
  CarePlansQueryVariables
>;
export const AssessedAsSuicideRiskDocument = gql`
  query AssessedAsSuicideRisk {
    assessedAsSuicideRisk {
      ccsTeamTelephoneNumber
    }
  }
`;

/**
 * __useAssessedAsSuicideRiskQuery__
 *
 * To run a query within a React component, call `useAssessedAsSuicideRiskQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessedAsSuicideRiskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessedAsSuicideRiskQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssessedAsSuicideRiskQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AssessedAsSuicideRiskQuery,
    AssessedAsSuicideRiskQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssessedAsSuicideRiskQuery,
    AssessedAsSuicideRiskQueryVariables
  >(AssessedAsSuicideRiskDocument, options);
}
export function useAssessedAsSuicideRiskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssessedAsSuicideRiskQuery,
    AssessedAsSuicideRiskQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssessedAsSuicideRiskQuery,
    AssessedAsSuicideRiskQueryVariables
  >(AssessedAsSuicideRiskDocument, options);
}
export function useAssessedAsSuicideRiskSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AssessedAsSuicideRiskQuery,
        AssessedAsSuicideRiskQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AssessedAsSuicideRiskQuery,
    AssessedAsSuicideRiskQueryVariables
  >(AssessedAsSuicideRiskDocument, options);
}
export type AssessedAsSuicideRiskQueryHookResult = ReturnType<
  typeof useAssessedAsSuicideRiskQuery
>;
export type AssessedAsSuicideRiskLazyQueryHookResult = ReturnType<
  typeof useAssessedAsSuicideRiskLazyQuery
>;
export type AssessedAsSuicideRiskSuspenseQueryHookResult = ReturnType<
  typeof useAssessedAsSuicideRiskSuspenseQuery
>;
export type AssessedAsSuicideRiskQueryResult = Apollo.QueryResult<
  AssessedAsSuicideRiskQuery,
  AssessedAsSuicideRiskQueryVariables
>;
export const FetchWorkLifeServicesDocument = gql`
  query FetchWorkLifeServices($after: String) {
    workLifeServices(input: { first: 10, afterCursor: $after }) {
      services {
        data {
          ...WorkLifeServices
        }
        paging {
          ...WorkLifeServicesPagination
        }
      }
    }
  }
  ${WorkLifeServicesFragmentDoc}
  ${WorkLifeServicesPaginationFragmentDoc}
`;

/**
 * __useFetchWorkLifeServicesQuery__
 *
 * To run a query within a React component, call `useFetchWorkLifeServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWorkLifeServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWorkLifeServicesQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchWorkLifeServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchWorkLifeServicesQuery,
    FetchWorkLifeServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchWorkLifeServicesQuery,
    FetchWorkLifeServicesQueryVariables
  >(FetchWorkLifeServicesDocument, options);
}
export function useFetchWorkLifeServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchWorkLifeServicesQuery,
    FetchWorkLifeServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchWorkLifeServicesQuery,
    FetchWorkLifeServicesQueryVariables
  >(FetchWorkLifeServicesDocument, options);
}
export function useFetchWorkLifeServicesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchWorkLifeServicesQuery,
        FetchWorkLifeServicesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchWorkLifeServicesQuery,
    FetchWorkLifeServicesQueryVariables
  >(FetchWorkLifeServicesDocument, options);
}
export type FetchWorkLifeServicesQueryHookResult = ReturnType<
  typeof useFetchWorkLifeServicesQuery
>;
export type FetchWorkLifeServicesLazyQueryHookResult = ReturnType<
  typeof useFetchWorkLifeServicesLazyQuery
>;
export type FetchWorkLifeServicesSuspenseQueryHookResult = ReturnType<
  typeof useFetchWorkLifeServicesSuspenseQuery
>;
export type FetchWorkLifeServicesQueryResult = Apollo.QueryResult<
  FetchWorkLifeServicesQuery,
  FetchWorkLifeServicesQueryVariables
>;
export const PensionBenefitsTitleAndNotificationCountDocument = gql`
  query pensionBenefitsTitleAndNotificationCount {
    pensionAndBenefitsSummary {
      ...PensionBenefitsTitleAndNotificationCount
    }
  }
  ${PensionBenefitsTitleAndNotificationCountFragmentDoc}
`;

/**
 * __usePensionBenefitsTitleAndNotificationCountQuery__
 *
 * To run a query within a React component, call `usePensionBenefitsTitleAndNotificationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePensionBenefitsTitleAndNotificationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePensionBenefitsTitleAndNotificationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function usePensionBenefitsTitleAndNotificationCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PensionBenefitsTitleAndNotificationCountQuery,
    PensionBenefitsTitleAndNotificationCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PensionBenefitsTitleAndNotificationCountQuery,
    PensionBenefitsTitleAndNotificationCountQueryVariables
  >(PensionBenefitsTitleAndNotificationCountDocument, options);
}
export function usePensionBenefitsTitleAndNotificationCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PensionBenefitsTitleAndNotificationCountQuery,
    PensionBenefitsTitleAndNotificationCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PensionBenefitsTitleAndNotificationCountQuery,
    PensionBenefitsTitleAndNotificationCountQueryVariables
  >(PensionBenefitsTitleAndNotificationCountDocument, options);
}
export function usePensionBenefitsTitleAndNotificationCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PensionBenefitsTitleAndNotificationCountQuery,
        PensionBenefitsTitleAndNotificationCountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PensionBenefitsTitleAndNotificationCountQuery,
    PensionBenefitsTitleAndNotificationCountQueryVariables
  >(PensionBenefitsTitleAndNotificationCountDocument, options);
}
export type PensionBenefitsTitleAndNotificationCountQueryHookResult =
  ReturnType<typeof usePensionBenefitsTitleAndNotificationCountQuery>;
export type PensionBenefitsTitleAndNotificationCountLazyQueryHookResult =
  ReturnType<typeof usePensionBenefitsTitleAndNotificationCountLazyQuery>;
export type PensionBenefitsTitleAndNotificationCountSuspenseQueryHookResult =
  ReturnType<typeof usePensionBenefitsTitleAndNotificationCountSuspenseQuery>;
export type PensionBenefitsTitleAndNotificationCountQueryResult =
  Apollo.QueryResult<
    PensionBenefitsTitleAndNotificationCountQuery,
    PensionBenefitsTitleAndNotificationCountQueryVariables
  >;
export const PensionBenefitsSummaryPageDocument = gql`
  query pensionBenefitsSummaryPage {
    pensionAndBenefitsSummary {
      title
      ...PensionBenefitsNotifications
      ...PensionBenefitsPension
      ...PensionBenefitsBenefit
    }
  }
  ${PensionBenefitsNotificationsFragmentDoc}
  ${PensionBenefitsPensionFragmentDoc}
  ${PensionBenefitsBenefitFragmentDoc}
`;

/**
 * __usePensionBenefitsSummaryPageQuery__
 *
 * To run a query within a React component, call `usePensionBenefitsSummaryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePensionBenefitsSummaryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePensionBenefitsSummaryPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePensionBenefitsSummaryPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PensionBenefitsSummaryPageQuery,
    PensionBenefitsSummaryPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PensionBenefitsSummaryPageQuery,
    PensionBenefitsSummaryPageQueryVariables
  >(PensionBenefitsSummaryPageDocument, options);
}
export function usePensionBenefitsSummaryPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PensionBenefitsSummaryPageQuery,
    PensionBenefitsSummaryPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PensionBenefitsSummaryPageQuery,
    PensionBenefitsSummaryPageQueryVariables
  >(PensionBenefitsSummaryPageDocument, options);
}
export function usePensionBenefitsSummaryPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PensionBenefitsSummaryPageQuery,
        PensionBenefitsSummaryPageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PensionBenefitsSummaryPageQuery,
    PensionBenefitsSummaryPageQueryVariables
  >(PensionBenefitsSummaryPageDocument, options);
}
export type PensionBenefitsSummaryPageQueryHookResult = ReturnType<
  typeof usePensionBenefitsSummaryPageQuery
>;
export type PensionBenefitsSummaryPageLazyQueryHookResult = ReturnType<
  typeof usePensionBenefitsSummaryPageLazyQuery
>;
export type PensionBenefitsSummaryPageSuspenseQueryHookResult = ReturnType<
  typeof usePensionBenefitsSummaryPageSuspenseQuery
>;
export type PensionBenefitsSummaryPageQueryResult = Apollo.QueryResult<
  PensionBenefitsSummaryPageQuery,
  PensionBenefitsSummaryPageQueryVariables
>;
export const PensionAndBenefitsPortalUrlDocument = gql`
  query pensionAndBenefitsPortalUrl($portalUrlId: String!) {
    pensionAndBenefitsPortalUrl(id: $portalUrlId) {
      url
      expiry
    }
  }
`;

/**
 * __usePensionAndBenefitsPortalUrlQuery__
 *
 * To run a query within a React component, call `usePensionAndBenefitsPortalUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePensionAndBenefitsPortalUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePensionAndBenefitsPortalUrlQuery({
 *   variables: {
 *      portalUrlId: // value for 'portalUrlId'
 *   },
 * });
 */
export function usePensionAndBenefitsPortalUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    PensionAndBenefitsPortalUrlQuery,
    PensionAndBenefitsPortalUrlQueryVariables
  > &
    (
      | { variables: PensionAndBenefitsPortalUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PensionAndBenefitsPortalUrlQuery,
    PensionAndBenefitsPortalUrlQueryVariables
  >(PensionAndBenefitsPortalUrlDocument, options);
}
export function usePensionAndBenefitsPortalUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PensionAndBenefitsPortalUrlQuery,
    PensionAndBenefitsPortalUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PensionAndBenefitsPortalUrlQuery,
    PensionAndBenefitsPortalUrlQueryVariables
  >(PensionAndBenefitsPortalUrlDocument, options);
}
export function usePensionAndBenefitsPortalUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PensionAndBenefitsPortalUrlQuery,
        PensionAndBenefitsPortalUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PensionAndBenefitsPortalUrlQuery,
    PensionAndBenefitsPortalUrlQueryVariables
  >(PensionAndBenefitsPortalUrlDocument, options);
}
export type PensionAndBenefitsPortalUrlQueryHookResult = ReturnType<
  typeof usePensionAndBenefitsPortalUrlQuery
>;
export type PensionAndBenefitsPortalUrlLazyQueryHookResult = ReturnType<
  typeof usePensionAndBenefitsPortalUrlLazyQuery
>;
export type PensionAndBenefitsPortalUrlSuspenseQueryHookResult = ReturnType<
  typeof usePensionAndBenefitsPortalUrlSuspenseQuery
>;
export type PensionAndBenefitsPortalUrlQueryResult = Apollo.QueryResult<
  PensionAndBenefitsPortalUrlQuery,
  PensionAndBenefitsPortalUrlQueryVariables
>;
export const CategoryModalContainerDocument = gql`
  query CategoryModalContainer($categoryId: ID) {
    digitalClinicalPrograms(where: { category: $categoryId }) {
      ...ProgramListFields
    }
  }
  ${ProgramListFieldsFragmentDoc}
`;

/**
 * __useCategoryModalContainerQuery__
 *
 * To run a query within a React component, call `useCategoryModalContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryModalContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryModalContainerQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useCategoryModalContainerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CategoryModalContainerQuery,
    CategoryModalContainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CategoryModalContainerQuery,
    CategoryModalContainerQueryVariables
  >(CategoryModalContainerDocument, options);
}
export function useCategoryModalContainerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategoryModalContainerQuery,
    CategoryModalContainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CategoryModalContainerQuery,
    CategoryModalContainerQueryVariables
  >(CategoryModalContainerDocument, options);
}
export function useCategoryModalContainerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CategoryModalContainerQuery,
        CategoryModalContainerQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CategoryModalContainerQuery,
    CategoryModalContainerQueryVariables
  >(CategoryModalContainerDocument, options);
}
export type CategoryModalContainerQueryHookResult = ReturnType<
  typeof useCategoryModalContainerQuery
>;
export type CategoryModalContainerLazyQueryHookResult = ReturnType<
  typeof useCategoryModalContainerLazyQuery
>;
export type CategoryModalContainerSuspenseQueryHookResult = ReturnType<
  typeof useCategoryModalContainerSuspenseQuery
>;
export type CategoryModalContainerQueryResult = Apollo.QueryResult<
  CategoryModalContainerQuery,
  CategoryModalContainerQueryVariables
>;
export const AllProgramsDocument = gql`
  query allPrograms {
    digitalClinicalPrograms {
      ...ProgramListFields
    }
    programsWalkthrough: getWalkthrough(where: { walkthroughType: CareNow }) {
      ...WalkthroughFields
    }
    digitalClinicalProgramCategories {
      ...DigitalClinicalProgramCategories
    }
  }
  ${ProgramListFieldsFragmentDoc}
  ${WalkthroughFieldsFragmentDoc}
  ${DigitalClinicalProgramCategoriesFragmentDoc}
`;

/**
 * __useAllProgramsQuery__
 *
 * To run a query within a React component, call `useAllProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProgramsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllProgramsQuery,
    AllProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllProgramsQuery, AllProgramsQueryVariables>(
    AllProgramsDocument,
    options,
  );
}
export function useAllProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllProgramsQuery,
    AllProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllProgramsQuery, AllProgramsQueryVariables>(
    AllProgramsDocument,
    options,
  );
}
export function useAllProgramsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AllProgramsQuery,
        AllProgramsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AllProgramsQuery, AllProgramsQueryVariables>(
    AllProgramsDocument,
    options,
  );
}
export type AllProgramsQueryHookResult = ReturnType<typeof useAllProgramsQuery>;
export type AllProgramsLazyQueryHookResult = ReturnType<
  typeof useAllProgramsLazyQuery
>;
export type AllProgramsSuspenseQueryHookResult = ReturnType<
  typeof useAllProgramsSuspenseQuery
>;
export type AllProgramsQueryResult = Apollo.QueryResult<
  AllProgramsQuery,
  AllProgramsQueryVariables
>;
export const WalkthroughCacheDocument = gql`
  query WalkthroughCache {
    programsWalkthrough: getWalkthrough(where: { walkthroughType: CareNow }) {
      ...WalkthroughFields
    }
  }
  ${WalkthroughFieldsFragmentDoc}
`;

/**
 * __useWalkthroughCacheQuery__
 *
 * To run a query within a React component, call `useWalkthroughCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalkthroughCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalkthroughCacheQuery({
 *   variables: {
 *   },
 * });
 */
export function useWalkthroughCacheQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WalkthroughCacheQuery,
    WalkthroughCacheQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WalkthroughCacheQuery, WalkthroughCacheQueryVariables>(
    WalkthroughCacheDocument,
    options,
  );
}
export function useWalkthroughCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WalkthroughCacheQuery,
    WalkthroughCacheQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WalkthroughCacheQuery,
    WalkthroughCacheQueryVariables
  >(WalkthroughCacheDocument, options);
}
export function useWalkthroughCacheSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WalkthroughCacheQuery,
        WalkthroughCacheQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WalkthroughCacheQuery,
    WalkthroughCacheQueryVariables
  >(WalkthroughCacheDocument, options);
}
export type WalkthroughCacheQueryHookResult = ReturnType<
  typeof useWalkthroughCacheQuery
>;
export type WalkthroughCacheLazyQueryHookResult = ReturnType<
  typeof useWalkthroughCacheLazyQuery
>;
export type WalkthroughCacheSuspenseQueryHookResult = ReturnType<
  typeof useWalkthroughCacheSuspenseQuery
>;
export type WalkthroughCacheQueryResult = Apollo.QueryResult<
  WalkthroughCacheQuery,
  WalkthroughCacheQueryVariables
>;
export const CompleteWalkthroughDocument = gql`
  mutation CompleteWalkthrough($walkthroughType: WalkthroughTypeInput!) {
    completeWalkthrough(request: $walkthroughType)
  }
`;
export type CompleteWalkthroughMutationFn = Apollo.MutationFunction<
  CompleteWalkthroughMutation,
  CompleteWalkthroughMutationVariables
>;

/**
 * __useCompleteWalkthroughMutation__
 *
 * To run a mutation, you first call `useCompleteWalkthroughMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteWalkthroughMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeWalkthroughMutation, { data, loading, error }] = useCompleteWalkthroughMutation({
 *   variables: {
 *      walkthroughType: // value for 'walkthroughType'
 *   },
 * });
 */
export function useCompleteWalkthroughMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteWalkthroughMutation,
    CompleteWalkthroughMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteWalkthroughMutation,
    CompleteWalkthroughMutationVariables
  >(CompleteWalkthroughDocument, options);
}
export type CompleteWalkthroughMutationHookResult = ReturnType<
  typeof useCompleteWalkthroughMutation
>;
export type CompleteWalkthroughMutationResult =
  Apollo.MutationResult<CompleteWalkthroughMutation>;
export type CompleteWalkthroughMutationOptions = Apollo.BaseMutationOptions<
  CompleteWalkthroughMutation,
  CompleteWalkthroughMutationVariables
>;
export const DigitalClinicalModuleDocument = gql`
  query DigitalClinicalModule($where: FindDigitalClinicalModuleInput!) {
    digitalClinicalModule(where: $where) {
      ...DigitalClinicalModuleDetailsFields
      ...DigitalClinicalModuleSectionsFields
    }
  }
  ${DigitalClinicalModuleDetailsFieldsFragmentDoc}
  ${DigitalClinicalModuleSectionsFieldsFragmentDoc}
`;

/**
 * __useDigitalClinicalModuleQuery__
 *
 * To run a query within a React component, call `useDigitalClinicalModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalClinicalModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalClinicalModuleQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDigitalClinicalModuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    DigitalClinicalModuleQuery,
    DigitalClinicalModuleQueryVariables
  > &
    (
      | { variables: DigitalClinicalModuleQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DigitalClinicalModuleQuery,
    DigitalClinicalModuleQueryVariables
  >(DigitalClinicalModuleDocument, options);
}
export function useDigitalClinicalModuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DigitalClinicalModuleQuery,
    DigitalClinicalModuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DigitalClinicalModuleQuery,
    DigitalClinicalModuleQueryVariables
  >(DigitalClinicalModuleDocument, options);
}
export function useDigitalClinicalModuleSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DigitalClinicalModuleQuery,
        DigitalClinicalModuleQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DigitalClinicalModuleQuery,
    DigitalClinicalModuleQueryVariables
  >(DigitalClinicalModuleDocument, options);
}
export type DigitalClinicalModuleQueryHookResult = ReturnType<
  typeof useDigitalClinicalModuleQuery
>;
export type DigitalClinicalModuleLazyQueryHookResult = ReturnType<
  typeof useDigitalClinicalModuleLazyQuery
>;
export type DigitalClinicalModuleSuspenseQueryHookResult = ReturnType<
  typeof useDigitalClinicalModuleSuspenseQuery
>;
export type DigitalClinicalModuleQueryResult = Apollo.QueryResult<
  DigitalClinicalModuleQuery,
  DigitalClinicalModuleQueryVariables
>;
export const ModuleOverviewDocument = gql`
  query ModuleOverview($where: FindDigitalClinicalModuleInput!) {
    digitalClinicalModule(where: $where) {
      ...ModuleOverviewFields
    }
  }
  ${ModuleOverviewFieldsFragmentDoc}
`;

/**
 * __useModuleOverviewQuery__
 *
 * To run a query within a React component, call `useModuleOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleOverviewQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useModuleOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModuleOverviewQuery,
    ModuleOverviewQueryVariables
  > &
    (
      | { variables: ModuleOverviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ModuleOverviewQuery, ModuleOverviewQueryVariables>(
    ModuleOverviewDocument,
    options,
  );
}
export function useModuleOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModuleOverviewQuery,
    ModuleOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ModuleOverviewQuery, ModuleOverviewQueryVariables>(
    ModuleOverviewDocument,
    options,
  );
}
export function useModuleOverviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ModuleOverviewQuery,
        ModuleOverviewQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ModuleOverviewQuery,
    ModuleOverviewQueryVariables
  >(ModuleOverviewDocument, options);
}
export type ModuleOverviewQueryHookResult = ReturnType<
  typeof useModuleOverviewQuery
>;
export type ModuleOverviewLazyQueryHookResult = ReturnType<
  typeof useModuleOverviewLazyQuery
>;
export type ModuleOverviewSuspenseQueryHookResult = ReturnType<
  typeof useModuleOverviewSuspenseQuery
>;
export type ModuleOverviewQueryResult = Apollo.QueryResult<
  ModuleOverviewQuery,
  ModuleOverviewQueryVariables
>;
export const SingleProgramDocument = gql`
  query SingleProgram($where: FindDigitalClinicalProgramInput!) {
    digitalClinicalProgram(where: $where) {
      ...SingleProgramFields
    }
  }
  ${SingleProgramFieldsFragmentDoc}
`;

/**
 * __useSingleProgramQuery__
 *
 * To run a query within a React component, call `useSingleProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleProgramQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSingleProgramQuery(
  baseOptions: Apollo.QueryHookOptions<
    SingleProgramQuery,
    SingleProgramQueryVariables
  > &
    (
      | { variables: SingleProgramQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SingleProgramQuery, SingleProgramQueryVariables>(
    SingleProgramDocument,
    options,
  );
}
export function useSingleProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SingleProgramQuery,
    SingleProgramQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SingleProgramQuery, SingleProgramQueryVariables>(
    SingleProgramDocument,
    options,
  );
}
export function useSingleProgramSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SingleProgramQuery,
        SingleProgramQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SingleProgramQuery,
    SingleProgramQueryVariables
  >(SingleProgramDocument, options);
}
export type SingleProgramQueryHookResult = ReturnType<
  typeof useSingleProgramQuery
>;
export type SingleProgramLazyQueryHookResult = ReturnType<
  typeof useSingleProgramLazyQuery
>;
export type SingleProgramSuspenseQueryHookResult = ReturnType<
  typeof useSingleProgramSuspenseQuery
>;
export type SingleProgramQueryResult = Apollo.QueryResult<
  SingleProgramQuery,
  SingleProgramQueryVariables
>;
export const ProgramMenuDocument = gql`
  query ProgramMenu($where: FindDigitalClinicalProgramInput!) {
    digitalClinicalProgram(where: $where) {
      programId
      ...ProgramInfoFields
    }
  }
  ${ProgramInfoFieldsFragmentDoc}
`;

/**
 * __useProgramMenuQuery__
 *
 * To run a query within a React component, call `useProgramMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramMenuQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProgramMenuQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramMenuQuery,
    ProgramMenuQueryVariables
  > &
    (
      | { variables: ProgramMenuQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProgramMenuQuery, ProgramMenuQueryVariables>(
    ProgramMenuDocument,
    options,
  );
}
export function useProgramMenuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramMenuQuery,
    ProgramMenuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProgramMenuQuery, ProgramMenuQueryVariables>(
    ProgramMenuDocument,
    options,
  );
}
export function useProgramMenuSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramMenuQuery,
        ProgramMenuQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProgramMenuQuery, ProgramMenuQueryVariables>(
    ProgramMenuDocument,
    options,
  );
}
export type ProgramMenuQueryHookResult = ReturnType<typeof useProgramMenuQuery>;
export type ProgramMenuLazyQueryHookResult = ReturnType<
  typeof useProgramMenuLazyQuery
>;
export type ProgramMenuSuspenseQueryHookResult = ReturnType<
  typeof useProgramMenuSuspenseQuery
>;
export type ProgramMenuQueryResult = Apollo.QueryResult<
  ProgramMenuQuery,
  ProgramMenuQueryVariables
>;
export const LeaveProgramMenuDocument = gql`
  mutation LeaveProgramMenu($request: LeaveDigitalClinicalProgramInput!) {
    leaveDigitalClinicalProgram(request: $request)
  }
`;
export type LeaveProgramMenuMutationFn = Apollo.MutationFunction<
  LeaveProgramMenuMutation,
  LeaveProgramMenuMutationVariables
>;

/**
 * __useLeaveProgramMenuMutation__
 *
 * To run a mutation, you first call `useLeaveProgramMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveProgramMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveProgramMenuMutation, { data, loading, error }] = useLeaveProgramMenuMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useLeaveProgramMenuMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveProgramMenuMutation,
    LeaveProgramMenuMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveProgramMenuMutation,
    LeaveProgramMenuMutationVariables
  >(LeaveProgramMenuDocument, options);
}
export type LeaveProgramMenuMutationHookResult = ReturnType<
  typeof useLeaveProgramMenuMutation
>;
export type LeaveProgramMenuMutationResult =
  Apollo.MutationResult<LeaveProgramMenuMutation>;
export type LeaveProgramMenuMutationOptions = Apollo.BaseMutationOptions<
  LeaveProgramMenuMutation,
  LeaveProgramMenuMutationVariables
>;
export const TrackProgramProgressDocument = gql`
  mutation TrackProgramProgress(
    $trackProgramInput: DigitalClinicalProgramInteractionInput!
  ) {
    trackDigitalClinicalProgramProgress(request: $trackProgramInput) {
      ...ProgramStatusFields
      modules {
        ...ModuleListItemStatusFields
      }
    }
  }
  ${ProgramStatusFieldsFragmentDoc}
  ${ModuleListItemStatusFieldsFragmentDoc}
`;
export type TrackProgramProgressMutationFn = Apollo.MutationFunction<
  TrackProgramProgressMutation,
  TrackProgramProgressMutationVariables
>;

/**
 * __useTrackProgramProgressMutation__
 *
 * To run a mutation, you first call `useTrackProgramProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackProgramProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackProgramProgressMutation, { data, loading, error }] = useTrackProgramProgressMutation({
 *   variables: {
 *      trackProgramInput: // value for 'trackProgramInput'
 *   },
 * });
 */
export function useTrackProgramProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackProgramProgressMutation,
    TrackProgramProgressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TrackProgramProgressMutation,
    TrackProgramProgressMutationVariables
  >(TrackProgramProgressDocument, options);
}
export type TrackProgramProgressMutationHookResult = ReturnType<
  typeof useTrackProgramProgressMutation
>;
export type TrackProgramProgressMutationResult =
  Apollo.MutationResult<TrackProgramProgressMutation>;
export type TrackProgramProgressMutationOptions = Apollo.BaseMutationOptions<
  TrackProgramProgressMutation,
  TrackProgramProgressMutationVariables
>;
export const AssessmentDocument = gql`
  query Assessment($id: ID!, $requestGlobalContent: Boolean) {
    assessment(
      where: { uuid: $id }
      requestGlobalContent: $requestGlobalContent
    ) {
      ...Assessment
    }
  }
  ${AssessmentFragmentDoc}
`;

/**
 * __useAssessmentQuery__
 *
 * To run a query within a React component, call `useAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      requestGlobalContent: // value for 'requestGlobalContent'
 *   },
 * });
 */
export function useAssessmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssessmentQuery,
    AssessmentQueryVariables
  > &
    (
      | { variables: AssessmentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssessmentQuery, AssessmentQueryVariables>(
    AssessmentDocument,
    options,
  );
}
export function useAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssessmentQuery,
    AssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssessmentQuery, AssessmentQueryVariables>(
    AssessmentDocument,
    options,
  );
}
export function useAssessmentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AssessmentQuery,
        AssessmentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AssessmentQuery, AssessmentQueryVariables>(
    AssessmentDocument,
    options,
  );
}
export type AssessmentQueryHookResult = ReturnType<typeof useAssessmentQuery>;
export type AssessmentLazyQueryHookResult = ReturnType<
  typeof useAssessmentLazyQuery
>;
export type AssessmentSuspenseQueryHookResult = ReturnType<
  typeof useAssessmentSuspenseQuery
>;
export type AssessmentQueryResult = Apollo.QueryResult<
  AssessmentQuery,
  AssessmentQueryVariables
>;
export const AnswerAssessmentQuestionsDocument = gql`
  mutation AnswerAssessmentQuestions(
    $assesmentId: ID!
    $answers: [AssessmentAnswersInput!]!
    $requestGlobalContent: Boolean
  ) {
    answerAssessmentQuestions(
      request: {
        uuid: $assesmentId
        answers: $answers
        requestGlobalContent: $requestGlobalContent
      }
    ) {
      assessment {
        ...AnswerMutationResponse
      }
    }
  }
  ${AnswerMutationResponseFragmentDoc}
`;
export type AnswerAssessmentQuestionsMutationFn = Apollo.MutationFunction<
  AnswerAssessmentQuestionsMutation,
  AnswerAssessmentQuestionsMutationVariables
>;

/**
 * __useAnswerAssessmentQuestionsMutation__
 *
 * To run a mutation, you first call `useAnswerAssessmentQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerAssessmentQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerAssessmentQuestionsMutation, { data, loading, error }] = useAnswerAssessmentQuestionsMutation({
 *   variables: {
 *      assesmentId: // value for 'assesmentId'
 *      answers: // value for 'answers'
 *      requestGlobalContent: // value for 'requestGlobalContent'
 *   },
 * });
 */
export function useAnswerAssessmentQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnswerAssessmentQuestionsMutation,
    AnswerAssessmentQuestionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnswerAssessmentQuestionsMutation,
    AnswerAssessmentQuestionsMutationVariables
  >(AnswerAssessmentQuestionsDocument, options);
}
export type AnswerAssessmentQuestionsMutationHookResult = ReturnType<
  typeof useAnswerAssessmentQuestionsMutation
>;
export type AnswerAssessmentQuestionsMutationResult =
  Apollo.MutationResult<AnswerAssessmentQuestionsMutation>;
export type AnswerAssessmentQuestionsMutationOptions =
  Apollo.BaseMutationOptions<
    AnswerAssessmentQuestionsMutation,
    AnswerAssessmentQuestionsMutationVariables
  >;
export const FinishAnsweringAssessmentQuestionsDocument = gql`
  mutation FinishAnsweringAssessmentQuestions(
    $assesmentId: ID!
    $answers: [AssessmentAnswersInput!]!
    $requestGlobalContent: Boolean
  ) {
    answerAssessmentQuestions(
      request: {
        uuid: $assesmentId
        answers: $answers
        requestGlobalContent: $requestGlobalContent
      }
    ) {
      assessment {
        ...FinalAnswerMutationResponse
      }
      originalTierState {
        ...AssessmentTierState
      }
    }
  }
  ${FinalAnswerMutationResponseFragmentDoc}
  ${AssessmentTierStateFragmentDoc}
`;
export type FinishAnsweringAssessmentQuestionsMutationFn =
  Apollo.MutationFunction<
    FinishAnsweringAssessmentQuestionsMutation,
    FinishAnsweringAssessmentQuestionsMutationVariables
  >;

/**
 * __useFinishAnsweringAssessmentQuestionsMutation__
 *
 * To run a mutation, you first call `useFinishAnsweringAssessmentQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishAnsweringAssessmentQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishAnsweringAssessmentQuestionsMutation, { data, loading, error }] = useFinishAnsweringAssessmentQuestionsMutation({
 *   variables: {
 *      assesmentId: // value for 'assesmentId'
 *      answers: // value for 'answers'
 *      requestGlobalContent: // value for 'requestGlobalContent'
 *   },
 * });
 */
export function useFinishAnsweringAssessmentQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinishAnsweringAssessmentQuestionsMutation,
    FinishAnsweringAssessmentQuestionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinishAnsweringAssessmentQuestionsMutation,
    FinishAnsweringAssessmentQuestionsMutationVariables
  >(FinishAnsweringAssessmentQuestionsDocument, options);
}
export type FinishAnsweringAssessmentQuestionsMutationHookResult = ReturnType<
  typeof useFinishAnsweringAssessmentQuestionsMutation
>;
export type FinishAnsweringAssessmentQuestionsMutationResult =
  Apollo.MutationResult<FinishAnsweringAssessmentQuestionsMutation>;
export type FinishAnsweringAssessmentQuestionsMutationOptions =
  Apollo.BaseMutationOptions<
    FinishAnsweringAssessmentQuestionsMutation,
    FinishAnsweringAssessmentQuestionsMutationVariables
  >;
export const AllTiersDocument = gql`
  query AllTiers {
    allTiers {
      ...TieringHubAllTiers
    }
  }
  ${TieringHubAllTiersFragmentDoc}
`;

/**
 * __useAllTiersQuery__
 *
 * To run a query within a React component, call `useAllTiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTiersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTiersQuery(
  baseOptions?: Apollo.QueryHookOptions<AllTiersQuery, AllTiersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllTiersQuery, AllTiersQueryVariables>(
    AllTiersDocument,
    options,
  );
}
export function useAllTiersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllTiersQuery,
    AllTiersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllTiersQuery, AllTiersQueryVariables>(
    AllTiersDocument,
    options,
  );
}
export function useAllTiersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AllTiersQuery, AllTiersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AllTiersQuery, AllTiersQueryVariables>(
    AllTiersDocument,
    options,
  );
}
export type AllTiersQueryHookResult = ReturnType<typeof useAllTiersQuery>;
export type AllTiersLazyQueryHookResult = ReturnType<
  typeof useAllTiersLazyQuery
>;
export type AllTiersSuspenseQueryHookResult = ReturnType<
  typeof useAllTiersSuspenseQuery
>;
export type AllTiersQueryResult = Apollo.QueryResult<
  AllTiersQuery,
  AllTiersQueryVariables
>;
export const AssessmentHistoryDocument = gql`
  query AssessmentHistory(
    $archiveId: ID!
    $id: ID!
    $requestGlobalContent: Boolean
  ) {
    resultData: historicalAssessment(where: { archiveId: $archiveId }) {
      ...HistoricalAssessment
    }
    commonAssessmentProperties: assessment(
      where: { uuid: $id }
      requestGlobalContent: $requestGlobalContent
    ) {
      ...AssessmentResultCommonFields
    }
  }
  ${HistoricalAssessmentFragmentDoc}
  ${AssessmentResultCommonFieldsFragmentDoc}
`;

/**
 * __useAssessmentHistoryQuery__
 *
 * To run a query within a React component, call `useAssessmentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentHistoryQuery({
 *   variables: {
 *      archiveId: // value for 'archiveId'
 *      id: // value for 'id'
 *      requestGlobalContent: // value for 'requestGlobalContent'
 *   },
 * });
 */
export function useAssessmentHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssessmentHistoryQuery,
    AssessmentHistoryQueryVariables
  > &
    (
      | { variables: AssessmentHistoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssessmentHistoryQuery,
    AssessmentHistoryQueryVariables
  >(AssessmentHistoryDocument, options);
}
export function useAssessmentHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssessmentHistoryQuery,
    AssessmentHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssessmentHistoryQuery,
    AssessmentHistoryQueryVariables
  >(AssessmentHistoryDocument, options);
}
export function useAssessmentHistorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AssessmentHistoryQuery,
        AssessmentHistoryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AssessmentHistoryQuery,
    AssessmentHistoryQueryVariables
  >(AssessmentHistoryDocument, options);
}
export type AssessmentHistoryQueryHookResult = ReturnType<
  typeof useAssessmentHistoryQuery
>;
export type AssessmentHistoryLazyQueryHookResult = ReturnType<
  typeof useAssessmentHistoryLazyQuery
>;
export type AssessmentHistorySuspenseQueryHookResult = ReturnType<
  typeof useAssessmentHistorySuspenseQuery
>;
export type AssessmentHistoryQueryResult = Apollo.QueryResult<
  AssessmentHistoryQuery,
  AssessmentHistoryQueryVariables
>;
export const AssessmentFullHistoryDocument = gql`
  query AssessmentFullHistory($id: ID!) {
    assessmentHistory(where: { uuid: $id }) {
      ...AssessmentHistory
    }
  }
  ${AssessmentHistoryFragmentDoc}
`;

/**
 * __useAssessmentFullHistoryQuery__
 *
 * To run a query within a React component, call `useAssessmentFullHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentFullHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentFullHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssessmentFullHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssessmentFullHistoryQuery,
    AssessmentFullHistoryQueryVariables
  > &
    (
      | { variables: AssessmentFullHistoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssessmentFullHistoryQuery,
    AssessmentFullHistoryQueryVariables
  >(AssessmentFullHistoryDocument, options);
}
export function useAssessmentFullHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssessmentFullHistoryQuery,
    AssessmentFullHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssessmentFullHistoryQuery,
    AssessmentFullHistoryQueryVariables
  >(AssessmentFullHistoryDocument, options);
}
export function useAssessmentFullHistorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AssessmentFullHistoryQuery,
        AssessmentFullHistoryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AssessmentFullHistoryQuery,
    AssessmentFullHistoryQueryVariables
  >(AssessmentFullHistoryDocument, options);
}
export type AssessmentFullHistoryQueryHookResult = ReturnType<
  typeof useAssessmentFullHistoryQuery
>;
export type AssessmentFullHistoryLazyQueryHookResult = ReturnType<
  typeof useAssessmentFullHistoryLazyQuery
>;
export type AssessmentFullHistorySuspenseQueryHookResult = ReturnType<
  typeof useAssessmentFullHistorySuspenseQuery
>;
export type AssessmentFullHistoryQueryResult = Apollo.QueryResult<
  AssessmentFullHistoryQuery,
  AssessmentFullHistoryQueryVariables
>;
export const AssessmentResultDocument = gql`
  query AssessmentResult($id: ID!, $requestGlobalContent: Boolean) {
    resultData: assessment(
      where: { uuid: $id }
      requestGlobalContent: $requestGlobalContent
    ) {
      ...FinalAnswerMutationResponse
    }
    commonAssessmentProperties: assessment(
      where: { uuid: $id }
      requestGlobalContent: $requestGlobalContent
    ) {
      ...AssessmentResultCommonFields
    }
  }
  ${FinalAnswerMutationResponseFragmentDoc}
  ${AssessmentResultCommonFieldsFragmentDoc}
`;

/**
 * __useAssessmentResultQuery__
 *
 * To run a query within a React component, call `useAssessmentResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentResultQuery({
 *   variables: {
 *      id: // value for 'id'
 *      requestGlobalContent: // value for 'requestGlobalContent'
 *   },
 * });
 */
export function useAssessmentResultQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssessmentResultQuery,
    AssessmentResultQueryVariables
  > &
    (
      | { variables: AssessmentResultQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssessmentResultQuery, AssessmentResultQueryVariables>(
    AssessmentResultDocument,
    options,
  );
}
export function useAssessmentResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssessmentResultQuery,
    AssessmentResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssessmentResultQuery,
    AssessmentResultQueryVariables
  >(AssessmentResultDocument, options);
}
export function useAssessmentResultSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AssessmentResultQuery,
        AssessmentResultQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AssessmentResultQuery,
    AssessmentResultQueryVariables
  >(AssessmentResultDocument, options);
}
export type AssessmentResultQueryHookResult = ReturnType<
  typeof useAssessmentResultQuery
>;
export type AssessmentResultLazyQueryHookResult = ReturnType<
  typeof useAssessmentResultLazyQuery
>;
export type AssessmentResultSuspenseQueryHookResult = ReturnType<
  typeof useAssessmentResultSuspenseQuery
>;
export type AssessmentResultQueryResult = Apollo.QueryResult<
  AssessmentResultQuery,
  AssessmentResultQueryVariables
>;
export const RetakeAssessmentDocument = gql`
  mutation RetakeAssessment($uuid: ID!, $requestGlobalContent: Boolean) {
    retakeAssessment(
      request: { uuid: $uuid, requestGlobalContent: $requestGlobalContent }
    ) {
      assessment {
        ...Assessment
      }
    }
  }
  ${AssessmentFragmentDoc}
`;
export type RetakeAssessmentMutationFn = Apollo.MutationFunction<
  RetakeAssessmentMutation,
  RetakeAssessmentMutationVariables
>;

/**
 * __useRetakeAssessmentMutation__
 *
 * To run a mutation, you first call `useRetakeAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetakeAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retakeAssessmentMutation, { data, loading, error }] = useRetakeAssessmentMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      requestGlobalContent: // value for 'requestGlobalContent'
 *   },
 * });
 */
export function useRetakeAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetakeAssessmentMutation,
    RetakeAssessmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RetakeAssessmentMutation,
    RetakeAssessmentMutationVariables
  >(RetakeAssessmentDocument, options);
}
export type RetakeAssessmentMutationHookResult = ReturnType<
  typeof useRetakeAssessmentMutation
>;
export type RetakeAssessmentMutationResult =
  Apollo.MutationResult<RetakeAssessmentMutation>;
export type RetakeAssessmentMutationOptions = Apollo.BaseMutationOptions<
  RetakeAssessmentMutation,
  RetakeAssessmentMutationVariables
>;
export const ChangeGenderDocument = gql`
  mutation ChangeGender(
    $id: ID!
    $gender: String!
    $answers: [AssessmentAnswersInput!]!
    $requestGlobalContent: Boolean
  ) {
    changeGender(
      request: {
        uuid: $id
        gender: $gender
        answers: $answers
        requestGlobalContent: $requestGlobalContent
      }
    ) {
      assessment {
        uuid
        result {
          ...AssessmentResult
        }
      }
    }
  }
  ${AssessmentResultFragmentDoc}
`;
export type ChangeGenderMutationFn = Apollo.MutationFunction<
  ChangeGenderMutation,
  ChangeGenderMutationVariables
>;

/**
 * __useChangeGenderMutation__
 *
 * To run a mutation, you first call `useChangeGenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeGenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeGenderMutation, { data, loading, error }] = useChangeGenderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      gender: // value for 'gender'
 *      answers: // value for 'answers'
 *      requestGlobalContent: // value for 'requestGlobalContent'
 *   },
 * });
 */
export function useChangeGenderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeGenderMutation,
    ChangeGenderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeGenderMutation,
    ChangeGenderMutationVariables
  >(ChangeGenderDocument, options);
}
export type ChangeGenderMutationHookResult = ReturnType<
  typeof useChangeGenderMutation
>;
export type ChangeGenderMutationResult =
  Apollo.MutationResult<ChangeGenderMutation>;
export type ChangeGenderMutationOptions = Apollo.BaseMutationOptions<
  ChangeGenderMutation,
  ChangeGenderMutationVariables
>;
export const RegressionInfoDocument = gql`
  query RegressionInfo {
    tierRegressionInfo {
      ...RegressionInformation
    }
    currentTier {
      ...TieringHubCurrentTier
    }
  }
  ${RegressionInformationFragmentDoc}
  ${TieringHubCurrentTierFragmentDoc}
`;

/**
 * __useRegressionInfoQuery__
 *
 * To run a query within a React component, call `useRegressionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegressionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegressionInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegressionInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegressionInfoQuery,
    RegressionInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegressionInfoQuery, RegressionInfoQueryVariables>(
    RegressionInfoDocument,
    options,
  );
}
export function useRegressionInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegressionInfoQuery,
    RegressionInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegressionInfoQuery, RegressionInfoQueryVariables>(
    RegressionInfoDocument,
    options,
  );
}
export function useRegressionInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RegressionInfoQuery,
        RegressionInfoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RegressionInfoQuery,
    RegressionInfoQueryVariables
  >(RegressionInfoDocument, options);
}
export type RegressionInfoQueryHookResult = ReturnType<
  typeof useRegressionInfoQuery
>;
export type RegressionInfoLazyQueryHookResult = ReturnType<
  typeof useRegressionInfoLazyQuery
>;
export type RegressionInfoSuspenseQueryHookResult = ReturnType<
  typeof useRegressionInfoSuspenseQuery
>;
export type RegressionInfoQueryResult = Apollo.QueryResult<
  RegressionInfoQuery,
  RegressionInfoQueryVariables
>;
export const AssessmentHeroCardsDocument = gql`
  query AssessmentHeroCards {
    assessmentHeroCards {
      ...SingleAssessmentHeroCard
      ...THICardFields
      ...HealthRiskAssessmentHeroCard
    }
  }
  ${SingleAssessmentHeroCardFragmentDoc}
  ${ThiCardFieldsFragmentDoc}
  ${HealthRiskAssessmentHeroCardFragmentDoc}
`;

/**
 * __useAssessmentHeroCardsQuery__
 *
 * To run a query within a React component, call `useAssessmentHeroCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentHeroCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentHeroCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssessmentHeroCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AssessmentHeroCardsQuery,
    AssessmentHeroCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssessmentHeroCardsQuery,
    AssessmentHeroCardsQueryVariables
  >(AssessmentHeroCardsDocument, options);
}
export function useAssessmentHeroCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssessmentHeroCardsQuery,
    AssessmentHeroCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssessmentHeroCardsQuery,
    AssessmentHeroCardsQueryVariables
  >(AssessmentHeroCardsDocument, options);
}
export function useAssessmentHeroCardsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AssessmentHeroCardsQuery,
        AssessmentHeroCardsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AssessmentHeroCardsQuery,
    AssessmentHeroCardsQueryVariables
  >(AssessmentHeroCardsDocument, options);
}
export type AssessmentHeroCardsQueryHookResult = ReturnType<
  typeof useAssessmentHeroCardsQuery
>;
export type AssessmentHeroCardsLazyQueryHookResult = ReturnType<
  typeof useAssessmentHeroCardsLazyQuery
>;
export type AssessmentHeroCardsSuspenseQueryHookResult = ReturnType<
  typeof useAssessmentHeroCardsSuspenseQuery
>;
export type AssessmentHeroCardsQueryResult = Apollo.QueryResult<
  AssessmentHeroCardsQuery,
  AssessmentHeroCardsQueryVariables
>;
export const AssessmentContentLanguageMessageDocument = gql`
  query AssessmentContentLanguageMessage {
    assessmentContentLanguageMessage: wellbeingContentLanguageMessage(
      section: ASSESSMENTS
    ) {
      ...WellbeingContentLanguageMessage
    }
  }
  ${WellbeingContentLanguageMessageFragmentDoc}
`;

/**
 * __useAssessmentContentLanguageMessageQuery__
 *
 * To run a query within a React component, call `useAssessmentContentLanguageMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentContentLanguageMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentContentLanguageMessageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssessmentContentLanguageMessageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AssessmentContentLanguageMessageQuery,
    AssessmentContentLanguageMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssessmentContentLanguageMessageQuery,
    AssessmentContentLanguageMessageQueryVariables
  >(AssessmentContentLanguageMessageDocument, options);
}
export function useAssessmentContentLanguageMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssessmentContentLanguageMessageQuery,
    AssessmentContentLanguageMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssessmentContentLanguageMessageQuery,
    AssessmentContentLanguageMessageQueryVariables
  >(AssessmentContentLanguageMessageDocument, options);
}
export function useAssessmentContentLanguageMessageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AssessmentContentLanguageMessageQuery,
        AssessmentContentLanguageMessageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AssessmentContentLanguageMessageQuery,
    AssessmentContentLanguageMessageQueryVariables
  >(AssessmentContentLanguageMessageDocument, options);
}
export type AssessmentContentLanguageMessageQueryHookResult = ReturnType<
  typeof useAssessmentContentLanguageMessageQuery
>;
export type AssessmentContentLanguageMessageLazyQueryHookResult = ReturnType<
  typeof useAssessmentContentLanguageMessageLazyQuery
>;
export type AssessmentContentLanguageMessageSuspenseQueryHookResult =
  ReturnType<typeof useAssessmentContentLanguageMessageSuspenseQuery>;
export type AssessmentContentLanguageMessageQueryResult = Apollo.QueryResult<
  AssessmentContentLanguageMessageQuery,
  AssessmentContentLanguageMessageQueryVariables
>;
export const AssessmentsHubDocument = gql`
  query AssessmentsHub($requestGlobalContent: Boolean) {
    allAssessments: fetchAssessments(
      requestGlobalContent: $requestGlobalContent
    ) {
      assessments {
        ...AssessmentHubListItem
      }
      contentLanguage
      translatedGlobalContentExists
    }
    assessmentTypeFilters {
      ...AssessmentTypeFilters
    }
  }
  ${AssessmentHubListItemFragmentDoc}
  ${AssessmentTypeFiltersFragmentDoc}
`;

/**
 * __useAssessmentsHubQuery__
 *
 * To run a query within a React component, call `useAssessmentsHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentsHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentsHubQuery({
 *   variables: {
 *      requestGlobalContent: // value for 'requestGlobalContent'
 *   },
 * });
 */
export function useAssessmentsHubQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AssessmentsHubQuery,
    AssessmentsHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssessmentsHubQuery, AssessmentsHubQueryVariables>(
    AssessmentsHubDocument,
    options,
  );
}
export function useAssessmentsHubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssessmentsHubQuery,
    AssessmentsHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssessmentsHubQuery, AssessmentsHubQueryVariables>(
    AssessmentsHubDocument,
    options,
  );
}
export function useAssessmentsHubSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AssessmentsHubQuery,
        AssessmentsHubQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AssessmentsHubQuery,
    AssessmentsHubQueryVariables
  >(AssessmentsHubDocument, options);
}
export type AssessmentsHubQueryHookResult = ReturnType<
  typeof useAssessmentsHubQuery
>;
export type AssessmentsHubLazyQueryHookResult = ReturnType<
  typeof useAssessmentsHubLazyQuery
>;
export type AssessmentsHubSuspenseQueryHookResult = ReturnType<
  typeof useAssessmentsHubSuspenseQuery
>;
export type AssessmentsHubQueryResult = Apollo.QueryResult<
  AssessmentsHubQuery,
  AssessmentsHubQueryVariables
>;
export const PointsModalDocument = gql`
  query PointsModal {
    assessmentTierPoints {
      assessmentCooldownFrequency
      pointsForAnAssessment
      pointsForHRA
      pointsForTHI
    }
  }
`;

/**
 * __usePointsModalQuery__
 *
 * To run a query within a React component, call `usePointsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointsModalQuery({
 *   variables: {
 *   },
 * });
 */
export function usePointsModalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PointsModalQuery,
    PointsModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PointsModalQuery, PointsModalQueryVariables>(
    PointsModalDocument,
    options,
  );
}
export function usePointsModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PointsModalQuery,
    PointsModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PointsModalQuery, PointsModalQueryVariables>(
    PointsModalDocument,
    options,
  );
}
export function usePointsModalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PointsModalQuery,
        PointsModalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PointsModalQuery, PointsModalQueryVariables>(
    PointsModalDocument,
    options,
  );
}
export type PointsModalQueryHookResult = ReturnType<typeof usePointsModalQuery>;
export type PointsModalLazyQueryHookResult = ReturnType<
  typeof usePointsModalLazyQuery
>;
export type PointsModalSuspenseQueryHookResult = ReturnType<
  typeof usePointsModalSuspenseQuery
>;
export type PointsModalQueryResult = Apollo.QueryResult<
  PointsModalQuery,
  PointsModalQueryVariables
>;
export const GetPermissionsDocument = gql`
  query GetPermissions {
    howDoesPersonalisationWork {
      permission {
        ...PersonalisationPermission
      }
    }
  }
  ${PersonalisationPermissionFragmentDoc}
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options,
  );
}
export function useGetPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options,
  );
}
export function useGetPermissionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPermissionsQuery,
        GetPermissionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >(GetPermissionsDocument, options);
}
export type GetPermissionsQueryHookResult = ReturnType<
  typeof useGetPermissionsQuery
>;
export type GetPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetPermissionsLazyQuery
>;
export type GetPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetPermissionsSuspenseQuery
>;
export type GetPermissionsQueryResult = Apollo.QueryResult<
  GetPermissionsQuery,
  GetPermissionsQueryVariables
>;
export const CompleteCustomTierActivityDocument = gql`
  mutation CompleteCustomTierActivity($request: FindCustomTierActivityInput) {
    completeCustomTierActivity(request: $request) {
      customTierActivity {
        ...CustomTieringActivityListItem
      }
      currentTier {
        ...TieringHubCurrentTier
      }
      nextTier {
        ...TieringHubNextTier
      }
      tierPoints {
        ...TieringHubTierPoints
      }
    }
  }
  ${CustomTieringActivityListItemFragmentDoc}
  ${TieringHubCurrentTierFragmentDoc}
  ${TieringHubNextTierFragmentDoc}
  ${TieringHubTierPointsFragmentDoc}
`;
export type CompleteCustomTierActivityMutationFn = Apollo.MutationFunction<
  CompleteCustomTierActivityMutation,
  CompleteCustomTierActivityMutationVariables
>;

/**
 * __useCompleteCustomTierActivityMutation__
 *
 * To run a mutation, you first call `useCompleteCustomTierActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteCustomTierActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeCustomTierActivityMutation, { data, loading, error }] = useCompleteCustomTierActivityMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCompleteCustomTierActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteCustomTierActivityMutation,
    CompleteCustomTierActivityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteCustomTierActivityMutation,
    CompleteCustomTierActivityMutationVariables
  >(CompleteCustomTierActivityDocument, options);
}
export type CompleteCustomTierActivityMutationHookResult = ReturnType<
  typeof useCompleteCustomTierActivityMutation
>;
export type CompleteCustomTierActivityMutationResult =
  Apollo.MutationResult<CompleteCustomTierActivityMutation>;
export type CompleteCustomTierActivityMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteCustomTierActivityMutation,
    CompleteCustomTierActivityMutationVariables
  >;
export const ActivityModalRegressionInfoDocument = gql`
  query ActivityModalRegressionInfo {
    tierRegressionInfo {
      ...TierRegressionInfo
    }
  }
  ${TierRegressionInfoFragmentDoc}
`;

/**
 * __useActivityModalRegressionInfoQuery__
 *
 * To run a query within a React component, call `useActivityModalRegressionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityModalRegressionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityModalRegressionInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivityModalRegressionInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActivityModalRegressionInfoQuery,
    ActivityModalRegressionInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActivityModalRegressionInfoQuery,
    ActivityModalRegressionInfoQueryVariables
  >(ActivityModalRegressionInfoDocument, options);
}
export function useActivityModalRegressionInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityModalRegressionInfoQuery,
    ActivityModalRegressionInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActivityModalRegressionInfoQuery,
    ActivityModalRegressionInfoQueryVariables
  >(ActivityModalRegressionInfoDocument, options);
}
export function useActivityModalRegressionInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ActivityModalRegressionInfoQuery,
        ActivityModalRegressionInfoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ActivityModalRegressionInfoQuery,
    ActivityModalRegressionInfoQueryVariables
  >(ActivityModalRegressionInfoDocument, options);
}
export type ActivityModalRegressionInfoQueryHookResult = ReturnType<
  typeof useActivityModalRegressionInfoQuery
>;
export type ActivityModalRegressionInfoLazyQueryHookResult = ReturnType<
  typeof useActivityModalRegressionInfoLazyQuery
>;
export type ActivityModalRegressionInfoSuspenseQueryHookResult = ReturnType<
  typeof useActivityModalRegressionInfoSuspenseQuery
>;
export type ActivityModalRegressionInfoQueryResult = Apollo.QueryResult<
  ActivityModalRegressionInfoQuery,
  ActivityModalRegressionInfoQueryVariables
>;
export const CustomActivityModalDocument = gql`
  query CustomActivityModal {
    currentTier {
      ...TieringHubCurrentTier
    }
    nextTier {
      ...TieringHubNextTier
    }
    allTiers {
      ...TieringHubAllTiers
    }
    tierPoints {
      ...TieringHubTierPoints
    }
  }
  ${TieringHubCurrentTierFragmentDoc}
  ${TieringHubNextTierFragmentDoc}
  ${TieringHubAllTiersFragmentDoc}
  ${TieringHubTierPointsFragmentDoc}
`;

/**
 * __useCustomActivityModalQuery__
 *
 * To run a query within a React component, call `useCustomActivityModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomActivityModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomActivityModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomActivityModalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomActivityModalQuery,
    CustomActivityModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomActivityModalQuery,
    CustomActivityModalQueryVariables
  >(CustomActivityModalDocument, options);
}
export function useCustomActivityModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomActivityModalQuery,
    CustomActivityModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomActivityModalQuery,
    CustomActivityModalQueryVariables
  >(CustomActivityModalDocument, options);
}
export function useCustomActivityModalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CustomActivityModalQuery,
        CustomActivityModalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CustomActivityModalQuery,
    CustomActivityModalQueryVariables
  >(CustomActivityModalDocument, options);
}
export type CustomActivityModalQueryHookResult = ReturnType<
  typeof useCustomActivityModalQuery
>;
export type CustomActivityModalLazyQueryHookResult = ReturnType<
  typeof useCustomActivityModalLazyQuery
>;
export type CustomActivityModalSuspenseQueryHookResult = ReturnType<
  typeof useCustomActivityModalSuspenseQuery
>;
export type CustomActivityModalQueryResult = Apollo.QueryResult<
  CustomActivityModalQuery,
  CustomActivityModalQueryVariables
>;
export const FeedbackScreenDocument = gql`
  query FeedbackScreen {
    customTierActivities {
      ...CustomTieringActivityListItem
    }
    tierPoints {
      ...TieringHubTierPoints
    }
    nextTier {
      ...TieringHubNextTier
    }
    currentTier {
      ...TieringHubCurrentTier
    }
    allTiers {
      ...TieringHubAllTiers
    }
  }
  ${CustomTieringActivityListItemFragmentDoc}
  ${TieringHubTierPointsFragmentDoc}
  ${TieringHubNextTierFragmentDoc}
  ${TieringHubCurrentTierFragmentDoc}
  ${TieringHubAllTiersFragmentDoc}
`;

/**
 * __useFeedbackScreenQuery__
 *
 * To run a query within a React component, call `useFeedbackScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeedbackScreenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeedbackScreenQuery,
    FeedbackScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeedbackScreenQuery, FeedbackScreenQueryVariables>(
    FeedbackScreenDocument,
    options,
  );
}
export function useFeedbackScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeedbackScreenQuery,
    FeedbackScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FeedbackScreenQuery, FeedbackScreenQueryVariables>(
    FeedbackScreenDocument,
    options,
  );
}
export function useFeedbackScreenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FeedbackScreenQuery,
        FeedbackScreenQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FeedbackScreenQuery,
    FeedbackScreenQueryVariables
  >(FeedbackScreenDocument, options);
}
export type FeedbackScreenQueryHookResult = ReturnType<
  typeof useFeedbackScreenQuery
>;
export type FeedbackScreenLazyQueryHookResult = ReturnType<
  typeof useFeedbackScreenLazyQuery
>;
export type FeedbackScreenSuspenseQueryHookResult = ReturnType<
  typeof useFeedbackScreenSuspenseQuery
>;
export type FeedbackScreenQueryResult = Apollo.QueryResult<
  FeedbackScreenQuery,
  FeedbackScreenQueryVariables
>;
export const TierRewardsPageDocument = gql`
  query TierRewardsPage {
    allTiers {
      ...TieringHubAllTiers
    }
  }
  ${TieringHubAllTiersFragmentDoc}
`;

/**
 * __useTierRewardsPageQuery__
 *
 * To run a query within a React component, call `useTierRewardsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTierRewardsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTierRewardsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTierRewardsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TierRewardsPageQuery,
    TierRewardsPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TierRewardsPageQuery, TierRewardsPageQueryVariables>(
    TierRewardsPageDocument,
    options,
  );
}
export function useTierRewardsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TierRewardsPageQuery,
    TierRewardsPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TierRewardsPageQuery,
    TierRewardsPageQueryVariables
  >(TierRewardsPageDocument, options);
}
export function useTierRewardsPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TierRewardsPageQuery,
        TierRewardsPageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TierRewardsPageQuery,
    TierRewardsPageQueryVariables
  >(TierRewardsPageDocument, options);
}
export type TierRewardsPageQueryHookResult = ReturnType<
  typeof useTierRewardsPageQuery
>;
export type TierRewardsPageLazyQueryHookResult = ReturnType<
  typeof useTierRewardsPageLazyQuery
>;
export type TierRewardsPageSuspenseQueryHookResult = ReturnType<
  typeof useTierRewardsPageSuspenseQuery
>;
export type TierRewardsPageQueryResult = Apollo.QueryResult<
  TierRewardsPageQuery,
  TierRewardsPageQueryVariables
>;
export const PaginatedTieringActivitiesDocument = gql`
  query PaginatedTieringActivities($cursor: String) {
    allTierActivities(select: { first: 10, after: $cursor }) {
      data {
        icon
        description
        pointsEarned
        category
        completedAt
        additionalInformation
      }
      paging {
        isLastPage
        cursors {
          after
        }
      }
    }
  }
`;

/**
 * __usePaginatedTieringActivitiesQuery__
 *
 * To run a query within a React component, call `usePaginatedTieringActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedTieringActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedTieringActivitiesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function usePaginatedTieringActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaginatedTieringActivitiesQuery,
    PaginatedTieringActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedTieringActivitiesQuery,
    PaginatedTieringActivitiesQueryVariables
  >(PaginatedTieringActivitiesDocument, options);
}
export function usePaginatedTieringActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedTieringActivitiesQuery,
    PaginatedTieringActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedTieringActivitiesQuery,
    PaginatedTieringActivitiesQueryVariables
  >(PaginatedTieringActivitiesDocument, options);
}
export function usePaginatedTieringActivitiesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PaginatedTieringActivitiesQuery,
        PaginatedTieringActivitiesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PaginatedTieringActivitiesQuery,
    PaginatedTieringActivitiesQueryVariables
  >(PaginatedTieringActivitiesDocument, options);
}
export type PaginatedTieringActivitiesQueryHookResult = ReturnType<
  typeof usePaginatedTieringActivitiesQuery
>;
export type PaginatedTieringActivitiesLazyQueryHookResult = ReturnType<
  typeof usePaginatedTieringActivitiesLazyQuery
>;
export type PaginatedTieringActivitiesSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedTieringActivitiesSuspenseQuery
>;
export type PaginatedTieringActivitiesQueryResult = Apollo.QueryResult<
  PaginatedTieringActivitiesQuery,
  PaginatedTieringActivitiesQueryVariables
>;
export const TierPointRelatedDataDocument = gql`
  query TierPointRelatedData {
    tierPoints {
      ...TieringHubTierPoints
    }
    nextTier {
      ...TieringHubNextTier
    }
    currentTier {
      ...TieringHubCurrentTier
    }
  }
  ${TieringHubTierPointsFragmentDoc}
  ${TieringHubNextTierFragmentDoc}
  ${TieringHubCurrentTierFragmentDoc}
`;

/**
 * __useTierPointRelatedDataQuery__
 *
 * To run a query within a React component, call `useTierPointRelatedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTierPointRelatedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTierPointRelatedDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useTierPointRelatedDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TierPointRelatedDataQuery,
    TierPointRelatedDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TierPointRelatedDataQuery,
    TierPointRelatedDataQueryVariables
  >(TierPointRelatedDataDocument, options);
}
export function useTierPointRelatedDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TierPointRelatedDataQuery,
    TierPointRelatedDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TierPointRelatedDataQuery,
    TierPointRelatedDataQueryVariables
  >(TierPointRelatedDataDocument, options);
}
export function useTierPointRelatedDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TierPointRelatedDataQuery,
        TierPointRelatedDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TierPointRelatedDataQuery,
    TierPointRelatedDataQueryVariables
  >(TierPointRelatedDataDocument, options);
}
export type TierPointRelatedDataQueryHookResult = ReturnType<
  typeof useTierPointRelatedDataQuery
>;
export type TierPointRelatedDataLazyQueryHookResult = ReturnType<
  typeof useTierPointRelatedDataLazyQuery
>;
export type TierPointRelatedDataSuspenseQueryHookResult = ReturnType<
  typeof useTierPointRelatedDataSuspenseQuery
>;
export type TierPointRelatedDataQueryResult = Apollo.QueryResult<
  TierPointRelatedDataQuery,
  TierPointRelatedDataQueryVariables
>;
export const TieringHubDocument = gql`
  query TieringHub {
    customTierActivities {
      ...CustomTieringActivityListItem
    }
    tierPoints {
      ...TieringHubTierPoints
    }
    nextTier {
      ...TieringHubNextTier
    }
    currentTier {
      ...TieringHubCurrentTier
    }
    allTiers {
      ...TieringHubAllTiers
    }
    tierRegressionInfo {
      ...TierRegressionInfo
    }
  }
  ${CustomTieringActivityListItemFragmentDoc}
  ${TieringHubTierPointsFragmentDoc}
  ${TieringHubNextTierFragmentDoc}
  ${TieringHubCurrentTierFragmentDoc}
  ${TieringHubAllTiersFragmentDoc}
  ${TierRegressionInfoFragmentDoc}
`;

/**
 * __useTieringHubQuery__
 *
 * To run a query within a React component, call `useTieringHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useTieringHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTieringHubQuery({
 *   variables: {
 *   },
 * });
 */
export function useTieringHubQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TieringHubQuery,
    TieringHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TieringHubQuery, TieringHubQueryVariables>(
    TieringHubDocument,
    options,
  );
}
export function useTieringHubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TieringHubQuery,
    TieringHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TieringHubQuery, TieringHubQueryVariables>(
    TieringHubDocument,
    options,
  );
}
export function useTieringHubSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TieringHubQuery,
        TieringHubQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TieringHubQuery, TieringHubQueryVariables>(
    TieringHubDocument,
    options,
  );
}
export type TieringHubQueryHookResult = ReturnType<typeof useTieringHubQuery>;
export type TieringHubLazyQueryHookResult = ReturnType<
  typeof useTieringHubLazyQuery
>;
export type TieringHubSuspenseQueryHookResult = ReturnType<
  typeof useTieringHubSuspenseQuery
>;
export type TieringHubQueryResult = Apollo.QueryResult<
  TieringHubQuery,
  TieringHubQueryVariables
>;
export const UpdatePersonalisationPermissionDocument = gql`
  mutation UpdatePersonalisationPermission($allowPersonalisation: Boolean!) {
    updatePersonalisationPermission(
      request: { allowPersonalisation: $allowPersonalisation }
    ) {
      permission {
        ...PersonalisationPermission
      }
    }
  }
  ${PersonalisationPermissionFragmentDoc}
`;
export type UpdatePersonalisationPermissionMutationFn = Apollo.MutationFunction<
  UpdatePersonalisationPermissionMutation,
  UpdatePersonalisationPermissionMutationVariables
>;

/**
 * __useUpdatePersonalisationPermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalisationPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalisationPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalisationPermissionMutation, { data, loading, error }] = useUpdatePersonalisationPermissionMutation({
 *   variables: {
 *      allowPersonalisation: // value for 'allowPersonalisation'
 *   },
 * });
 */
export function useUpdatePersonalisationPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonalisationPermissionMutation,
    UpdatePersonalisationPermissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePersonalisationPermissionMutation,
    UpdatePersonalisationPermissionMutationVariables
  >(UpdatePersonalisationPermissionDocument, options);
}
export type UpdatePersonalisationPermissionMutationHookResult = ReturnType<
  typeof useUpdatePersonalisationPermissionMutation
>;
export type UpdatePersonalisationPermissionMutationResult =
  Apollo.MutationResult<UpdatePersonalisationPermissionMutation>;
export type UpdatePersonalisationPermissionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePersonalisationPermissionMutation,
    UpdatePersonalisationPermissionMutationVariables
  >;
export const JoinDigitalClinicalProgramDocument = gql`
  mutation JoinDigitalClinicalProgram(
    $joinProgramInput: JoinDigitalClinicalProgramInput!
  ) {
    joinDigitalClinicalProgram(request: $joinProgramInput)
  }
`;
export type JoinDigitalClinicalProgramMutationFn = Apollo.MutationFunction<
  JoinDigitalClinicalProgramMutation,
  JoinDigitalClinicalProgramMutationVariables
>;

/**
 * __useJoinDigitalClinicalProgramMutation__
 *
 * To run a mutation, you first call `useJoinDigitalClinicalProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinDigitalClinicalProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinDigitalClinicalProgramMutation, { data, loading, error }] = useJoinDigitalClinicalProgramMutation({
 *   variables: {
 *      joinProgramInput: // value for 'joinProgramInput'
 *   },
 * });
 */
export function useJoinDigitalClinicalProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinDigitalClinicalProgramMutation,
    JoinDigitalClinicalProgramMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinDigitalClinicalProgramMutation,
    JoinDigitalClinicalProgramMutationVariables
  >(JoinDigitalClinicalProgramDocument, options);
}
export type JoinDigitalClinicalProgramMutationHookResult = ReturnType<
  typeof useJoinDigitalClinicalProgramMutation
>;
export type JoinDigitalClinicalProgramMutationResult =
  Apollo.MutationResult<JoinDigitalClinicalProgramMutation>;
export type JoinDigitalClinicalProgramMutationOptions =
  Apollo.BaseMutationOptions<
    JoinDigitalClinicalProgramMutation,
    JoinDigitalClinicalProgramMutationVariables
  >;
export const RecommendedArticlesDocument = gql`
  query RecommendedArticles {
    recommendedArticles(select: { first: 12 }) {
      recommendations {
        ...RecommendationFields
      }
    }
    howDoesPersonalisationWork {
      ...HowDoesPersonalisationWork
    }
  }
  ${RecommendationFieldsFragmentDoc}
  ${HowDoesPersonalisationWorkFragmentDoc}
`;

/**
 * __useRecommendedArticlesQuery__
 *
 * To run a query within a React component, call `useRecommendedArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecommendedArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecommendedArticlesQuery,
    RecommendedArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecommendedArticlesQuery,
    RecommendedArticlesQueryVariables
  >(RecommendedArticlesDocument, options);
}
export function useRecommendedArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendedArticlesQuery,
    RecommendedArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecommendedArticlesQuery,
    RecommendedArticlesQueryVariables
  >(RecommendedArticlesDocument, options);
}
export function useRecommendedArticlesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RecommendedArticlesQuery,
        RecommendedArticlesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RecommendedArticlesQuery,
    RecommendedArticlesQueryVariables
  >(RecommendedArticlesDocument, options);
}
export type RecommendedArticlesQueryHookResult = ReturnType<
  typeof useRecommendedArticlesQuery
>;
export type RecommendedArticlesLazyQueryHookResult = ReturnType<
  typeof useRecommendedArticlesLazyQuery
>;
export type RecommendedArticlesSuspenseQueryHookResult = ReturnType<
  typeof useRecommendedArticlesSuspenseQuery
>;
export type RecommendedArticlesQueryResult = Apollo.QueryResult<
  RecommendedArticlesQuery,
  RecommendedArticlesQueryVariables
>;
export const FetchFeaturedChallengesDocument = gql`
  query FetchFeaturedChallenges($from: GraphQLDate) {
    featuredChallenges: fetchFeaturedChallenges {
      ...FeaturedChallengeFields
    }
  }
  ${FeaturedChallengeFieldsFragmentDoc}
`;

/**
 * __useFetchFeaturedChallengesQuery__
 *
 * To run a query within a React component, call `useFetchFeaturedChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFeaturedChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFeaturedChallengesQuery({
 *   variables: {
 *      from: // value for 'from'
 *   },
 * });
 */
export function useFetchFeaturedChallengesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchFeaturedChallengesQuery,
    FetchFeaturedChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchFeaturedChallengesQuery,
    FetchFeaturedChallengesQueryVariables
  >(FetchFeaturedChallengesDocument, options);
}
export function useFetchFeaturedChallengesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchFeaturedChallengesQuery,
    FetchFeaturedChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchFeaturedChallengesQuery,
    FetchFeaturedChallengesQueryVariables
  >(FetchFeaturedChallengesDocument, options);
}
export function useFetchFeaturedChallengesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchFeaturedChallengesQuery,
        FetchFeaturedChallengesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchFeaturedChallengesQuery,
    FetchFeaturedChallengesQueryVariables
  >(FetchFeaturedChallengesDocument, options);
}
export type FetchFeaturedChallengesQueryHookResult = ReturnType<
  typeof useFetchFeaturedChallengesQuery
>;
export type FetchFeaturedChallengesLazyQueryHookResult = ReturnType<
  typeof useFetchFeaturedChallengesLazyQuery
>;
export type FetchFeaturedChallengesSuspenseQueryHookResult = ReturnType<
  typeof useFetchFeaturedChallengesSuspenseQuery
>;
export type FetchFeaturedChallengesQueryResult = Apollo.QueryResult<
  FetchFeaturedChallengesQuery,
  FetchFeaturedChallengesQueryVariables
>;
export const FetchAssessmentHeroCardsDocument = gql`
  query FetchAssessmentHeroCards($requestGlobalContent: Boolean) {
    assessmentHeroCards: assessmentHeroCards(
      requestGlobalContent: $requestGlobalContent
    ) {
      ...SingleAssessmentsCardFields
      ...THICardFields
      ...HRACardFields
    }
  }
  ${SingleAssessmentsCardFieldsFragmentDoc}
  ${ThiCardFieldsFragmentDoc}
  ${HraCardFieldsFragmentDoc}
`;

/**
 * __useFetchAssessmentHeroCardsQuery__
 *
 * To run a query within a React component, call `useFetchAssessmentHeroCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAssessmentHeroCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAssessmentHeroCardsQuery({
 *   variables: {
 *      requestGlobalContent: // value for 'requestGlobalContent'
 *   },
 * });
 */
export function useFetchAssessmentHeroCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAssessmentHeroCardsQuery,
    FetchAssessmentHeroCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchAssessmentHeroCardsQuery,
    FetchAssessmentHeroCardsQueryVariables
  >(FetchAssessmentHeroCardsDocument, options);
}
export function useFetchAssessmentHeroCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAssessmentHeroCardsQuery,
    FetchAssessmentHeroCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchAssessmentHeroCardsQuery,
    FetchAssessmentHeroCardsQueryVariables
  >(FetchAssessmentHeroCardsDocument, options);
}
export function useFetchAssessmentHeroCardsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchAssessmentHeroCardsQuery,
        FetchAssessmentHeroCardsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchAssessmentHeroCardsQuery,
    FetchAssessmentHeroCardsQueryVariables
  >(FetchAssessmentHeroCardsDocument, options);
}
export type FetchAssessmentHeroCardsQueryHookResult = ReturnType<
  typeof useFetchAssessmentHeroCardsQuery
>;
export type FetchAssessmentHeroCardsLazyQueryHookResult = ReturnType<
  typeof useFetchAssessmentHeroCardsLazyQuery
>;
export type FetchAssessmentHeroCardsSuspenseQueryHookResult = ReturnType<
  typeof useFetchAssessmentHeroCardsSuspenseQuery
>;
export type FetchAssessmentHeroCardsQueryResult = Apollo.QueryResult<
  FetchAssessmentHeroCardsQuery,
  FetchAssessmentHeroCardsQueryVariables
>;
export const FetchHraPromotionDocument = gql`
  query FetchHraPromotion {
    hraPromotion: hraPromotion {
      ...HRAPromotionFields
    }
  }
  ${HraPromotionFieldsFragmentDoc}
`;

/**
 * __useFetchHraPromotionQuery__
 *
 * To run a query within a React component, call `useFetchHraPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchHraPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchHraPromotionQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchHraPromotionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchHraPromotionQuery,
    FetchHraPromotionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchHraPromotionQuery,
    FetchHraPromotionQueryVariables
  >(FetchHraPromotionDocument, options);
}
export function useFetchHraPromotionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchHraPromotionQuery,
    FetchHraPromotionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchHraPromotionQuery,
    FetchHraPromotionQueryVariables
  >(FetchHraPromotionDocument, options);
}
export function useFetchHraPromotionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchHraPromotionQuery,
        FetchHraPromotionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchHraPromotionQuery,
    FetchHraPromotionQueryVariables
  >(FetchHraPromotionDocument, options);
}
export type FetchHraPromotionQueryHookResult = ReturnType<
  typeof useFetchHraPromotionQuery
>;
export type FetchHraPromotionLazyQueryHookResult = ReturnType<
  typeof useFetchHraPromotionLazyQuery
>;
export type FetchHraPromotionSuspenseQueryHookResult = ReturnType<
  typeof useFetchHraPromotionSuspenseQuery
>;
export type FetchHraPromotionQueryResult = Apollo.QueryResult<
  FetchHraPromotionQuery,
  FetchHraPromotionQueryVariables
>;
export const FetchWellbeingPromotionsDocument = gql`
  query FetchWellbeingPromotions($from: GraphQLDate) {
    wellbeingPromotions: wellbeingPromotions {
      ...ChallengePromotion
    }
  }
  ${ChallengePromotionFragmentDoc}
`;

/**
 * __useFetchWellbeingPromotionsQuery__
 *
 * To run a query within a React component, call `useFetchWellbeingPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWellbeingPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWellbeingPromotionsQuery({
 *   variables: {
 *      from: // value for 'from'
 *   },
 * });
 */
export function useFetchWellbeingPromotionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchWellbeingPromotionsQuery,
    FetchWellbeingPromotionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchWellbeingPromotionsQuery,
    FetchWellbeingPromotionsQueryVariables
  >(FetchWellbeingPromotionsDocument, options);
}
export function useFetchWellbeingPromotionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchWellbeingPromotionsQuery,
    FetchWellbeingPromotionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchWellbeingPromotionsQuery,
    FetchWellbeingPromotionsQueryVariables
  >(FetchWellbeingPromotionsDocument, options);
}
export function useFetchWellbeingPromotionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchWellbeingPromotionsQuery,
        FetchWellbeingPromotionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchWellbeingPromotionsQuery,
    FetchWellbeingPromotionsQueryVariables
  >(FetchWellbeingPromotionsDocument, options);
}
export type FetchWellbeingPromotionsQueryHookResult = ReturnType<
  typeof useFetchWellbeingPromotionsQuery
>;
export type FetchWellbeingPromotionsLazyQueryHookResult = ReturnType<
  typeof useFetchWellbeingPromotionsLazyQuery
>;
export type FetchWellbeingPromotionsSuspenseQueryHookResult = ReturnType<
  typeof useFetchWellbeingPromotionsSuspenseQuery
>;
export type FetchWellbeingPromotionsQueryResult = Apollo.QueryResult<
  FetchWellbeingPromotionsQuery,
  FetchWellbeingPromotionsQueryVariables
>;
export const FetchWalkthroughDocument = gql`
  query FetchWalkthrough {
    walkthrough: getWalkthrough(where: { walkthroughType: Wellbeing }) {
      ...WalkthroughFields
    }
  }
  ${WalkthroughFieldsFragmentDoc}
`;

/**
 * __useFetchWalkthroughQuery__
 *
 * To run a query within a React component, call `useFetchWalkthroughQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWalkthroughQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWalkthroughQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchWalkthroughQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchWalkthroughQuery,
    FetchWalkthroughQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchWalkthroughQuery, FetchWalkthroughQueryVariables>(
    FetchWalkthroughDocument,
    options,
  );
}
export function useFetchWalkthroughLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchWalkthroughQuery,
    FetchWalkthroughQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchWalkthroughQuery,
    FetchWalkthroughQueryVariables
  >(FetchWalkthroughDocument, options);
}
export function useFetchWalkthroughSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchWalkthroughQuery,
        FetchWalkthroughQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchWalkthroughQuery,
    FetchWalkthroughQueryVariables
  >(FetchWalkthroughDocument, options);
}
export type FetchWalkthroughQueryHookResult = ReturnType<
  typeof useFetchWalkthroughQuery
>;
export type FetchWalkthroughLazyQueryHookResult = ReturnType<
  typeof useFetchWalkthroughLazyQuery
>;
export type FetchWalkthroughSuspenseQueryHookResult = ReturnType<
  typeof useFetchWalkthroughSuspenseQuery
>;
export type FetchWalkthroughQueryResult = Apollo.QueryResult<
  FetchWalkthroughQuery,
  FetchWalkthroughQueryVariables
>;
export const FetchFeaturedProgramsDocument = gql`
  query FetchFeaturedPrograms {
    featuredPrograms: digitalClinicalPrograms(
      select: { first: 3 }
      where: { wasJoined: true, status: [NotStarted, InProgress, Completed] }
    ) {
      ...ProgramFields
    }
  }
  ${ProgramFieldsFragmentDoc}
`;

/**
 * __useFetchFeaturedProgramsQuery__
 *
 * To run a query within a React component, call `useFetchFeaturedProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFeaturedProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFeaturedProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchFeaturedProgramsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchFeaturedProgramsQuery,
    FetchFeaturedProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchFeaturedProgramsQuery,
    FetchFeaturedProgramsQueryVariables
  >(FetchFeaturedProgramsDocument, options);
}
export function useFetchFeaturedProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchFeaturedProgramsQuery,
    FetchFeaturedProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchFeaturedProgramsQuery,
    FetchFeaturedProgramsQueryVariables
  >(FetchFeaturedProgramsDocument, options);
}
export function useFetchFeaturedProgramsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchFeaturedProgramsQuery,
        FetchFeaturedProgramsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchFeaturedProgramsQuery,
    FetchFeaturedProgramsQueryVariables
  >(FetchFeaturedProgramsDocument, options);
}
export type FetchFeaturedProgramsQueryHookResult = ReturnType<
  typeof useFetchFeaturedProgramsQuery
>;
export type FetchFeaturedProgramsLazyQueryHookResult = ReturnType<
  typeof useFetchFeaturedProgramsLazyQuery
>;
export type FetchFeaturedProgramsSuspenseQueryHookResult = ReturnType<
  typeof useFetchFeaturedProgramsSuspenseQuery
>;
export type FetchFeaturedProgramsQueryResult = Apollo.QueryResult<
  FetchFeaturedProgramsQuery,
  FetchFeaturedProgramsQueryVariables
>;
export const FetchWellbeingContentLanguageMessageDocument = gql`
  query FetchWellbeingContentLanguageMessage {
    assessmentContentLanguageMessage: wellbeingContentLanguageMessage(
      section: ASSESSMENTS
    ) {
      ...WellbeingContentLanguageMessage
    }
  }
  ${WellbeingContentLanguageMessageFragmentDoc}
`;

/**
 * __useFetchWellbeingContentLanguageMessageQuery__
 *
 * To run a query within a React component, call `useFetchWellbeingContentLanguageMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWellbeingContentLanguageMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWellbeingContentLanguageMessageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchWellbeingContentLanguageMessageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchWellbeingContentLanguageMessageQuery,
    FetchWellbeingContentLanguageMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchWellbeingContentLanguageMessageQuery,
    FetchWellbeingContentLanguageMessageQueryVariables
  >(FetchWellbeingContentLanguageMessageDocument, options);
}
export function useFetchWellbeingContentLanguageMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchWellbeingContentLanguageMessageQuery,
    FetchWellbeingContentLanguageMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchWellbeingContentLanguageMessageQuery,
    FetchWellbeingContentLanguageMessageQueryVariables
  >(FetchWellbeingContentLanguageMessageDocument, options);
}
export function useFetchWellbeingContentLanguageMessageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchWellbeingContentLanguageMessageQuery,
        FetchWellbeingContentLanguageMessageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchWellbeingContentLanguageMessageQuery,
    FetchWellbeingContentLanguageMessageQueryVariables
  >(FetchWellbeingContentLanguageMessageDocument, options);
}
export type FetchWellbeingContentLanguageMessageQueryHookResult = ReturnType<
  typeof useFetchWellbeingContentLanguageMessageQuery
>;
export type FetchWellbeingContentLanguageMessageLazyQueryHookResult =
  ReturnType<typeof useFetchWellbeingContentLanguageMessageLazyQuery>;
export type FetchWellbeingContentLanguageMessageSuspenseQueryHookResult =
  ReturnType<typeof useFetchWellbeingContentLanguageMessageSuspenseQuery>;
export type FetchWellbeingContentLanguageMessageQueryResult =
  Apollo.QueryResult<
    FetchWellbeingContentLanguageMessageQuery,
    FetchWellbeingContentLanguageMessageQueryVariables
  >;
export const FetchRecommendedArticlesDocument = gql`
  query FetchRecommendedArticles {
    howDoesPersonalisationWork: howDoesPersonalisationWork {
      ...HowDoesPersonalisationWork
    }
    recommendedArticles: recommendedArticles(select: { first: 4 }) {
      recommendations {
        ...RecommendationFields
      }
    }
  }
  ${HowDoesPersonalisationWorkFragmentDoc}
  ${RecommendationFieldsFragmentDoc}
`;

/**
 * __useFetchRecommendedArticlesQuery__
 *
 * To run a query within a React component, call `useFetchRecommendedArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRecommendedArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRecommendedArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchRecommendedArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchRecommendedArticlesQuery,
    FetchRecommendedArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchRecommendedArticlesQuery,
    FetchRecommendedArticlesQueryVariables
  >(FetchRecommendedArticlesDocument, options);
}
export function useFetchRecommendedArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchRecommendedArticlesQuery,
    FetchRecommendedArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchRecommendedArticlesQuery,
    FetchRecommendedArticlesQueryVariables
  >(FetchRecommendedArticlesDocument, options);
}
export function useFetchRecommendedArticlesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchRecommendedArticlesQuery,
        FetchRecommendedArticlesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchRecommendedArticlesQuery,
    FetchRecommendedArticlesQueryVariables
  >(FetchRecommendedArticlesDocument, options);
}
export type FetchRecommendedArticlesQueryHookResult = ReturnType<
  typeof useFetchRecommendedArticlesQuery
>;
export type FetchRecommendedArticlesLazyQueryHookResult = ReturnType<
  typeof useFetchRecommendedArticlesLazyQuery
>;
export type FetchRecommendedArticlesSuspenseQueryHookResult = ReturnType<
  typeof useFetchRecommendedArticlesSuspenseQuery
>;
export type FetchRecommendedArticlesQueryResult = Apollo.QueryResult<
  FetchRecommendedArticlesQuery,
  FetchRecommendedArticlesQueryVariables
>;
export const WellBeingHubDocument = gql`
  query WellBeingHub($from: GraphQLDate) {
    currentTier {
      rank
      name
    }
    tierPoints {
      earned {
        total
      }
      remainingToReachNextTier
    }
    nextTier {
      name
    }
    allChallenges: fetchChallenges(
      select: { first: 3 }
      where: { participationType: Individual, status: [NotStarted, InProgress] }
    ) {
      ...HubList
    }
    programsList: digitalClinicalPrograms(
      select: { first: 3 }
      where: { wasJoined: false, status: [NotStarted] }
    ) {
      ...ProgramListFields
    }
  }
  ${HubListFragmentDoc}
  ${ProgramListFieldsFragmentDoc}
`;

/**
 * __useWellBeingHubQuery__
 *
 * To run a query within a React component, call `useWellBeingHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useWellBeingHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWellBeingHubQuery({
 *   variables: {
 *      from: // value for 'from'
 *   },
 * });
 */
export function useWellBeingHubQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WellBeingHubQuery,
    WellBeingHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WellBeingHubQuery, WellBeingHubQueryVariables>(
    WellBeingHubDocument,
    options,
  );
}
export function useWellBeingHubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WellBeingHubQuery,
    WellBeingHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WellBeingHubQuery, WellBeingHubQueryVariables>(
    WellBeingHubDocument,
    options,
  );
}
export function useWellBeingHubSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WellBeingHubQuery,
        WellBeingHubQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WellBeingHubQuery, WellBeingHubQueryVariables>(
    WellBeingHubDocument,
    options,
  );
}
export type WellBeingHubQueryHookResult = ReturnType<
  typeof useWellBeingHubQuery
>;
export type WellBeingHubLazyQueryHookResult = ReturnType<
  typeof useWellBeingHubLazyQuery
>;
export type WellBeingHubSuspenseQueryHookResult = ReturnType<
  typeof useWellBeingHubSuspenseQuery
>;
export type WellBeingHubQueryResult = Apollo.QueryResult<
  WellBeingHubQuery,
  WellBeingHubQueryVariables
>;
export const TieringDocument = gql`
  query Tiering {
    currentTier {
      rank
      name
    }
    tierPoints {
      earned {
        total
      }
    }
    allTiers {
      name
      points
      rank
    }
  }
`;

/**
 * __useTieringQuery__
 *
 * To run a query within a React component, call `useTieringQuery` and pass it any options that fit your needs.
 * When your component renders, `useTieringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTieringQuery({
 *   variables: {
 *   },
 * });
 */
export function useTieringQuery(
  baseOptions?: Apollo.QueryHookOptions<TieringQuery, TieringQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TieringQuery, TieringQueryVariables>(
    TieringDocument,
    options,
  );
}
export function useTieringLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TieringQuery,
    TieringQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TieringQuery, TieringQueryVariables>(
    TieringDocument,
    options,
  );
}
export function useTieringSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TieringQuery, TieringQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TieringQuery, TieringQueryVariables>(
    TieringDocument,
    options,
  );
}
export type TieringQueryHookResult = ReturnType<typeof useTieringQuery>;
export type TieringLazyQueryHookResult = ReturnType<typeof useTieringLazyQuery>;
export type TieringSuspenseQueryHookResult = ReturnType<
  typeof useTieringSuspenseQuery
>;
export type TieringQueryResult = Apollo.QueryResult<
  TieringQuery,
  TieringQueryVariables
>;
export const RecommendedResourcesDocument = gql`
  query RecommendedResources {
    recommendedArticles(select: { first: 3 }) {
      recommendations {
        title
        lastModificationDate
        url
        categoryTitle
        author {
          name
          surname
        }
        heroImage {
          url
        }
        audios {
          __typename
        }
        videos {
          __typename
        }
      }
    }
    howDoesPersonalisationWork {
      ...HowDoesPersonalisationWork
    }
  }
  ${HowDoesPersonalisationWorkFragmentDoc}
`;

/**
 * __useRecommendedResourcesQuery__
 *
 * To run a query within a React component, call `useRecommendedResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecommendedResourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecommendedResourcesQuery,
    RecommendedResourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecommendedResourcesQuery,
    RecommendedResourcesQueryVariables
  >(RecommendedResourcesDocument, options);
}
export function useRecommendedResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendedResourcesQuery,
    RecommendedResourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecommendedResourcesQuery,
    RecommendedResourcesQueryVariables
  >(RecommendedResourcesDocument, options);
}
export function useRecommendedResourcesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RecommendedResourcesQuery,
        RecommendedResourcesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RecommendedResourcesQuery,
    RecommendedResourcesQueryVariables
  >(RecommendedResourcesDocument, options);
}
export type RecommendedResourcesQueryHookResult = ReturnType<
  typeof useRecommendedResourcesQuery
>;
export type RecommendedResourcesLazyQueryHookResult = ReturnType<
  typeof useRecommendedResourcesLazyQuery
>;
export type RecommendedResourcesSuspenseQueryHookResult = ReturnType<
  typeof useRecommendedResourcesSuspenseQuery
>;
export type RecommendedResourcesQueryResult = Apollo.QueryResult<
  RecommendedResourcesQuery,
  RecommendedResourcesQueryVariables
>;
export const ChallengesOverviewDocument = gql`
  query ChallengesOverview($from: GraphQLDate) {
    joinedChallenges: fetchChallenges(where: { wasJoined: true }) {
      ...HubList
    }
    notJoinedChallenges: fetchChallenges(
      select: { first: 3 }
      where: { wasJoined: false, participationType: Individual }
    ) {
      ...HubList
    }
    completedChallenges: fetchChallenges(
      select: { first: 3 }
      where: { participationType: Individual, status: [Completed] }
    ) {
      ...HubList
    }
  }
  ${HubListFragmentDoc}
`;

/**
 * __useChallengesOverviewQuery__
 *
 * To run a query within a React component, call `useChallengesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useChallengesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChallengesOverviewQuery({
 *   variables: {
 *      from: // value for 'from'
 *   },
 * });
 */
export function useChallengesOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChallengesOverviewQuery,
    ChallengesOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChallengesOverviewQuery,
    ChallengesOverviewQueryVariables
  >(ChallengesOverviewDocument, options);
}
export function useChallengesOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChallengesOverviewQuery,
    ChallengesOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChallengesOverviewQuery,
    ChallengesOverviewQueryVariables
  >(ChallengesOverviewDocument, options);
}
export function useChallengesOverviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ChallengesOverviewQuery,
        ChallengesOverviewQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ChallengesOverviewQuery,
    ChallengesOverviewQueryVariables
  >(ChallengesOverviewDocument, options);
}
export type ChallengesOverviewQueryHookResult = ReturnType<
  typeof useChallengesOverviewQuery
>;
export type ChallengesOverviewLazyQueryHookResult = ReturnType<
  typeof useChallengesOverviewLazyQuery
>;
export type ChallengesOverviewSuspenseQueryHookResult = ReturnType<
  typeof useChallengesOverviewSuspenseQuery
>;
export type ChallengesOverviewQueryResult = Apollo.QueryResult<
  ChallengesOverviewQuery,
  ChallengesOverviewQueryVariables
>;
export const WellbeingPromotionsDocument = gql`
  query WellbeingPromotions($from: GraphQLDate) {
    wellbeingPromotions: wellbeingPromotions {
      ...ChallengePromotion
      ...WellbeingHubAssessmentPromotion
      ...WellbeingHubHraPromotion
    }
  }
  ${ChallengePromotionFragmentDoc}
  ${WellbeingHubAssessmentPromotionFragmentDoc}
  ${WellbeingHubHraPromotionFragmentDoc}
`;

/**
 * __useWellbeingPromotionsQuery__
 *
 * To run a query within a React component, call `useWellbeingPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWellbeingPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWellbeingPromotionsQuery({
 *   variables: {
 *      from: // value for 'from'
 *   },
 * });
 */
export function useWellbeingPromotionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WellbeingPromotionsQuery,
    WellbeingPromotionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WellbeingPromotionsQuery,
    WellbeingPromotionsQueryVariables
  >(WellbeingPromotionsDocument, options);
}
export function useWellbeingPromotionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WellbeingPromotionsQuery,
    WellbeingPromotionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WellbeingPromotionsQuery,
    WellbeingPromotionsQueryVariables
  >(WellbeingPromotionsDocument, options);
}
export function useWellbeingPromotionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WellbeingPromotionsQuery,
        WellbeingPromotionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WellbeingPromotionsQuery,
    WellbeingPromotionsQueryVariables
  >(WellbeingPromotionsDocument, options);
}
export type WellbeingPromotionsQueryHookResult = ReturnType<
  typeof useWellbeingPromotionsQuery
>;
export type WellbeingPromotionsLazyQueryHookResult = ReturnType<
  typeof useWellbeingPromotionsLazyQuery
>;
export type WellbeingPromotionsSuspenseQueryHookResult = ReturnType<
  typeof useWellbeingPromotionsSuspenseQuery
>;
export type WellbeingPromotionsQueryResult = Apollo.QueryResult<
  WellbeingPromotionsQuery,
  WellbeingPromotionsQueryVariables
>;
export const CareNowProgramsDocument = gql`
  query CareNowPrograms {
    notJoinedPrograms: digitalClinicalPrograms(
      select: { first: 3 }
      where: { wasJoined: false }
    ) {
      ...WellbeingHubProgramFields
    }
    joinedPrograms: digitalClinicalPrograms(
      select: { first: 4 }
      where: { wasJoined: true, status: [NotStarted, InProgress] }
    ) {
      ...WellbeingHubProgramFields
    }
    completedPrograms: digitalClinicalPrograms(
      select: { first: 3 }
      where: { status: [Completed] }
    ) {
      ...WellbeingHubProgramFields
    }
  }
  ${WellbeingHubProgramFieldsFragmentDoc}
`;

/**
 * __useCareNowProgramsQuery__
 *
 * To run a query within a React component, call `useCareNowProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareNowProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareNowProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareNowProgramsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CareNowProgramsQuery,
    CareNowProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareNowProgramsQuery, CareNowProgramsQueryVariables>(
    CareNowProgramsDocument,
    options,
  );
}
export function useCareNowProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareNowProgramsQuery,
    CareNowProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CareNowProgramsQuery,
    CareNowProgramsQueryVariables
  >(CareNowProgramsDocument, options);
}
export function useCareNowProgramsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CareNowProgramsQuery,
        CareNowProgramsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CareNowProgramsQuery,
    CareNowProgramsQueryVariables
  >(CareNowProgramsDocument, options);
}
export type CareNowProgramsQueryHookResult = ReturnType<
  typeof useCareNowProgramsQuery
>;
export type CareNowProgramsLazyQueryHookResult = ReturnType<
  typeof useCareNowProgramsLazyQuery
>;
export type CareNowProgramsSuspenseQueryHookResult = ReturnType<
  typeof useCareNowProgramsSuspenseQuery
>;
export type CareNowProgramsQueryResult = Apollo.QueryResult<
  CareNowProgramsQuery,
  CareNowProgramsQueryVariables
>;
export const FetchBannerDocument = gql`
  query FetchBanner($bannerId: BannerId!) {
    banner(input: { id: $bannerId }) {
      ...Banner
    }
  }
  ${BannerFragmentDoc}
`;

/**
 * __useFetchBannerQuery__
 *
 * To run a query within a React component, call `useFetchBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBannerQuery({
 *   variables: {
 *      bannerId: // value for 'bannerId'
 *   },
 * });
 */
export function useFetchBannerQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchBannerQuery,
    FetchBannerQueryVariables
  > &
    (
      | { variables: FetchBannerQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchBannerQuery, FetchBannerQueryVariables>(
    FetchBannerDocument,
    options,
  );
}
export function useFetchBannerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchBannerQuery,
    FetchBannerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchBannerQuery, FetchBannerQueryVariables>(
    FetchBannerDocument,
    options,
  );
}
export function useFetchBannerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchBannerQuery,
        FetchBannerQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FetchBannerQuery, FetchBannerQueryVariables>(
    FetchBannerDocument,
    options,
  );
}
export type FetchBannerQueryHookResult = ReturnType<typeof useFetchBannerQuery>;
export type FetchBannerLazyQueryHookResult = ReturnType<
  typeof useFetchBannerLazyQuery
>;
export type FetchBannerSuspenseQueryHookResult = ReturnType<
  typeof useFetchBannerSuspenseQuery
>;
export type FetchBannerQueryResult = Apollo.QueryResult<
  FetchBannerQuery,
  FetchBannerQueryVariables
>;
export const DismissBannerDocument = gql`
  mutation DismissBanner($bannerId: BannerId!) {
    markAsDismissed(input: { id: $bannerId }) {
      ...Banner
    }
  }
  ${BannerFragmentDoc}
`;
export type DismissBannerMutationFn = Apollo.MutationFunction<
  DismissBannerMutation,
  DismissBannerMutationVariables
>;

/**
 * __useDismissBannerMutation__
 *
 * To run a mutation, you first call `useDismissBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissBannerMutation, { data, loading, error }] = useDismissBannerMutation({
 *   variables: {
 *      bannerId: // value for 'bannerId'
 *   },
 * });
 */
export function useDismissBannerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissBannerMutation,
    DismissBannerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DismissBannerMutation,
    DismissBannerMutationVariables
  >(DismissBannerDocument, options);
}
export type DismissBannerMutationHookResult = ReturnType<
  typeof useDismissBannerMutation
>;
export type DismissBannerMutationResult =
  Apollo.MutationResult<DismissBannerMutation>;
export type DismissBannerMutationOptions = Apollo.BaseMutationOptions<
  DismissBannerMutation,
  DismissBannerMutationVariables
>;
export const TooltipDocument = gql`
  query Tooltip($findTooltip: FindTooltipInput!) {
    tooltip(where: $findTooltip) {
      ...TooltipFields
    }
  }
  ${TooltipFieldsFragmentDoc}
`;

/**
 * __useTooltipQuery__
 *
 * To run a query within a React component, call `useTooltipQuery` and pass it any options that fit your needs.
 * When your component renders, `useTooltipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTooltipQuery({
 *   variables: {
 *      findTooltip: // value for 'findTooltip'
 *   },
 * });
 */
export function useTooltipQuery(
  baseOptions: Apollo.QueryHookOptions<TooltipQuery, TooltipQueryVariables> &
    ({ variables: TooltipQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TooltipQuery, TooltipQueryVariables>(
    TooltipDocument,
    options,
  );
}
export function useTooltipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TooltipQuery,
    TooltipQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TooltipQuery, TooltipQueryVariables>(
    TooltipDocument,
    options,
  );
}
export function useTooltipSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TooltipQuery, TooltipQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TooltipQuery, TooltipQueryVariables>(
    TooltipDocument,
    options,
  );
}
export type TooltipQueryHookResult = ReturnType<typeof useTooltipQuery>;
export type TooltipLazyQueryHookResult = ReturnType<typeof useTooltipLazyQuery>;
export type TooltipSuspenseQueryHookResult = ReturnType<
  typeof useTooltipSuspenseQuery
>;
export type TooltipQueryResult = Apollo.QueryResult<
  TooltipQuery,
  TooltipQueryVariables
>;
export const DismissTooltipDocument = gql`
  mutation DismissTooltip($findTooltip: FindTooltipInput!) {
    dismissTooltip(request: $findTooltip) {
      tooltipId
    }
  }
`;
export type DismissTooltipMutationFn = Apollo.MutationFunction<
  DismissTooltipMutation,
  DismissTooltipMutationVariables
>;

/**
 * __useDismissTooltipMutation__
 *
 * To run a mutation, you first call `useDismissTooltipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissTooltipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissTooltipMutation, { data, loading, error }] = useDismissTooltipMutation({
 *   variables: {
 *      findTooltip: // value for 'findTooltip'
 *   },
 * });
 */
export function useDismissTooltipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissTooltipMutation,
    DismissTooltipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DismissTooltipMutation,
    DismissTooltipMutationVariables
  >(DismissTooltipDocument, options);
}
export type DismissTooltipMutationHookResult = ReturnType<
  typeof useDismissTooltipMutation
>;
export type DismissTooltipMutationResult =
  Apollo.MutationResult<DismissTooltipMutation>;
export type DismissTooltipMutationOptions = Apollo.BaseMutationOptions<
  DismissTooltipMutation,
  DismissTooltipMutationVariables
>;
export const FeedbackRequestDocument = gql`
  query FeedbackRequest($input: FeedbackRequestInput!) {
    feedbackRequest(input: $input) {
      feedbackRequest {
        negativeFreeTextInputPrompt
        positiveFreeTextInputPrompt
        question
        textInputMaxLength
      }
    }
  }
`;

/**
 * __useFeedbackRequestQuery__
 *
 * To run a query within a React component, call `useFeedbackRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackRequestQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFeedbackRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    FeedbackRequestQuery,
    FeedbackRequestQueryVariables
  > &
    (
      | { variables: FeedbackRequestQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeedbackRequestQuery, FeedbackRequestQueryVariables>(
    FeedbackRequestDocument,
    options,
  );
}
export function useFeedbackRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeedbackRequestQuery,
    FeedbackRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeedbackRequestQuery,
    FeedbackRequestQueryVariables
  >(FeedbackRequestDocument, options);
}
export function useFeedbackRequestSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FeedbackRequestQuery,
        FeedbackRequestQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FeedbackRequestQuery,
    FeedbackRequestQueryVariables
  >(FeedbackRequestDocument, options);
}
export type FeedbackRequestQueryHookResult = ReturnType<
  typeof useFeedbackRequestQuery
>;
export type FeedbackRequestLazyQueryHookResult = ReturnType<
  typeof useFeedbackRequestLazyQuery
>;
export type FeedbackRequestSuspenseQueryHookResult = ReturnType<
  typeof useFeedbackRequestSuspenseQuery
>;
export type FeedbackRequestQueryResult = Apollo.QueryResult<
  FeedbackRequestQuery,
  FeedbackRequestQueryVariables
>;
export const SubmitFeedbackDocument = gql`
  mutation SubmitFeedback($submitFeedbackInput: SubmitFeedbackInput!) {
    submitFeedback(input: $submitFeedbackInput) {
      id
    }
  }
`;
export type SubmitFeedbackMutationFn = Apollo.MutationFunction<
  SubmitFeedbackMutation,
  SubmitFeedbackMutationVariables
>;

/**
 * __useSubmitFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFeedbackMutation, { data, loading, error }] = useSubmitFeedbackMutation({
 *   variables: {
 *      submitFeedbackInput: // value for 'submitFeedbackInput'
 *   },
 * });
 */
export function useSubmitFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >(SubmitFeedbackDocument, options);
}
export type SubmitFeedbackMutationHookResult = ReturnType<
  typeof useSubmitFeedbackMutation
>;
export type SubmitFeedbackMutationResult =
  Apollo.MutationResult<SubmitFeedbackMutation>;
export type SubmitFeedbackMutationOptions = Apollo.BaseMutationOptions<
  SubmitFeedbackMutation,
  SubmitFeedbackMutationVariables
>;
export const WellBeingButtonDocument = gql`
  query WellBeingButton($walkthroughType: WalkthroughType) {
    walkthrough: getWalkthrough(where: { walkthroughType: $walkthroughType }) {
      ...WalkthroughFields
    }
  }
  ${WalkthroughFieldsFragmentDoc}
`;

/**
 * __useWellBeingButtonQuery__
 *
 * To run a query within a React component, call `useWellBeingButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useWellBeingButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWellBeingButtonQuery({
 *   variables: {
 *      walkthroughType: // value for 'walkthroughType'
 *   },
 * });
 */
export function useWellBeingButtonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WellBeingButtonQuery,
    WellBeingButtonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WellBeingButtonQuery, WellBeingButtonQueryVariables>(
    WellBeingButtonDocument,
    options,
  );
}
export function useWellBeingButtonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WellBeingButtonQuery,
    WellBeingButtonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WellBeingButtonQuery,
    WellBeingButtonQueryVariables
  >(WellBeingButtonDocument, options);
}
export function useWellBeingButtonSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WellBeingButtonQuery,
        WellBeingButtonQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WellBeingButtonQuery,
    WellBeingButtonQueryVariables
  >(WellBeingButtonDocument, options);
}
export type WellBeingButtonQueryHookResult = ReturnType<
  typeof useWellBeingButtonQuery
>;
export type WellBeingButtonLazyQueryHookResult = ReturnType<
  typeof useWellBeingButtonLazyQuery
>;
export type WellBeingButtonSuspenseQueryHookResult = ReturnType<
  typeof useWellBeingButtonSuspenseQuery
>;
export type WellBeingButtonQueryResult = Apollo.QueryResult<
  WellBeingButtonQuery,
  WellBeingButtonQueryVariables
>;
export const ThiResultDocument = gql`
  query THIResult {
    totalHealthIndexResult {
      ...THIResultModalFields
    }
  }
  ${ThiResultModalFieldsFragmentDoc}
`;

/**
 * __useThiResultQuery__
 *
 * To run a query within a React component, call `useThiResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useThiResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThiResultQuery({
 *   variables: {
 *   },
 * });
 */
export function useThiResultQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ThiResultQuery,
    ThiResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThiResultQuery, ThiResultQueryVariables>(
    ThiResultDocument,
    options,
  );
}
export function useThiResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThiResultQuery,
    ThiResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThiResultQuery, ThiResultQueryVariables>(
    ThiResultDocument,
    options,
  );
}
export function useThiResultSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ThiResultQuery, ThiResultQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ThiResultQuery, ThiResultQueryVariables>(
    ThiResultDocument,
    options,
  );
}
export type ThiResultQueryHookResult = ReturnType<typeof useThiResultQuery>;
export type ThiResultLazyQueryHookResult = ReturnType<
  typeof useThiResultLazyQuery
>;
export type ThiResultSuspenseQueryHookResult = ReturnType<
  typeof useThiResultSuspenseQuery
>;
export type ThiResultQueryResult = Apollo.QueryResult<
  ThiResultQuery,
  ThiResultQueryVariables
>;
