import type { CheckoutState, CheckoutAction } from './types';

import {
  resetAction,
  resetTokenAction,
  updateCheckoutAction,
  tokenReceivedAction,
  requestTokenAction,
  tokenFailedAction,
  orderFulfilledAction,
  orderFailedAction,
  submitOrderAction,
  submitSpotRewardsOrderAction,
} from './constants';

export const initialState: CheckoutState = {
  view: {
    isError: false,
    isFetching: true,
  },
  order: {
    giftCard: {
      id: '',
      description: null,
      provider: '',
      provider_keys: [],
      redemption: null,
      title: '',
      value: {
        type: 'discounted_price_list',
        data: {
          prices: [{ amount: 0, currency: 'GBP' }],
          discount: 0,
        },
      },
      sku: '',
      terms_and_conditions: null,
      price_band: 0,
      photography: [],
      categories: [],
    },
    destinationEmail: '',
    items: [],
    cinemaMerchant: {
      name: '',
    },
    details: {
      things_to_know: '',
      photography: '',
      stores: [],
      terms: {
        title: {
          body: '',
        },
        type: '',
      },
    },
    type: '',
    total: {
      amount: 0,
      originalAmount: 0,
      currency: 'GBP',
    },
  },
};

const checkoutReducer = (
  state: CheckoutState = initialState,
  action: CheckoutAction,
) => {
  switch (action.type) {
    case resetTokenAction:
      return { ...state, btToken: null, view: { isError: false } };
    case resetAction:
      return { ...initialState };
    case updateCheckoutAction:
      return { ...state, order: action.order };
    case requestTokenAction:
      return { ...state, view: { isFetching: true } };
    case tokenReceivedAction:
      return { ...state, btToken: action.token, view: { isFetching: false } };
    case tokenFailedAction:
      return { ...state, view: { isFetching: false } };
    case orderFulfilledAction:
      return {
        ...state,
        orderConfirmation: action.id,
        view: { isFetching: false },
      };
    case submitOrderAction:
    case submitSpotRewardsOrderAction:
      return {
        ...state,
        view: { isFetching: true },
        order: {
          ...state.order,
          destinationEmail: action.email,
        },
      };
    case orderFailedAction:
      return { ...state, view: { isError: true, isFetching: false } };
    default:
      return state;
  }
};

export default checkoutReducer;
