export const updateCheckoutAction = 'CHECKOUT/UPDATE_CHECKOUT';
export const requestTokenAction = 'CHECKOUT/REQUEST_TOKEN';
export const tokenReceivedAction = 'CHECKOUT/TOKEN_RECEIVED';
export const submitOrderAction = 'CHECKOUT/SUBMIT_ORDER';
export const submitSpotRewardsOrderAction = 'CHECKOUT/SUBMIT_SPOT_REWARD_ORDER';
export const tokenFailedAction = 'CHECKOUT/TOKEN_FAILED';
export const orderFulfilledAction = 'CHECKOUT/ORDER_FULFILLED';
export const orderFailedAction = 'CHECKOUT/ORDER_FAILED';
export const resetAction = 'CHECKOUT/RESET';
export const resetTokenAction = 'CHECKOUT/RESET_TOKEN';
