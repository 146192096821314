export const POPULAR_CATEGORY = 'popular';

export const GIFT_CARD_HELP_LINK = 'perks_need_help';

const banner = require(`../hub/view/assets/img-giftcards-banner.jpg`);
const largeBanner = require(`../hub/view/assets/img-giftcards-banner@2x.jpg`);
const largerBanner = require(`../hub/view/assets/img-giftcards-banner@3x.jpg`);

export const spotRewardsReferrer = 'spotRewards';

export const HERO_PROPS = {
  showMobile: false,
  banner: largeBanner,
  retinaBanner: largerBanner,
  tabletBanner: banner,
  retinaTabletBanner: largeBanner,
  containerClassName: 'hero--black',
};
