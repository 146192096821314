import {
  habitModuleName as habitActivity,
  stepsModuleName as challengesActivity,
  challengeDetails,
} from '../activity/activity.module';

export const HUB_NAME = 'challengesHub';

export enum STATUSES {
  COMPLETED = 'Completed',
  NOT_STARTED = 'NotStarted',
  IN_PROGRESS = 'InProgress',
}

export enum PARTICIPATION_TYPE {
  INDIVIDUAL = 'individual',
  CORPORATE = 'corporate',
}

export enum CHALLENGE_TYPE {
  HABIT = 'HabitSwitching',
  STEPS = 'Physical',
  MENTAL = 'Mental', // we do not use mental type challenges but they are in the schema
}

export const INITIAL_STATE = {
  isFetching: false,
  challenges: [],
  featured: [],
} as const;

export const ROUTES = {
  [CHALLENGE_TYPE.STEPS]: {
    activity: challengesActivity,
    details: challengeDetails,
    completed: '',
  },
  [CHALLENGE_TYPE.HABIT]: {
    activity: habitActivity,
    details: challengeDetails,
    completed: '',
  },
  [CHALLENGE_TYPE.MENTAL]: {
    activity: '',
    details: '',
    completed: '',
  },
} as const;
