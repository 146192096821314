import classNames from 'classnames';

import styles from './one-quarter.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  ['aria-label']?: string;
};

const OneQuarterPage = (props: Props) => {
  const { children, className, wrapperClassName } = props;
  return (
    <section
      aria-label={props['aria-label']}
      className={classNames(
        styles['one-quarter-page'],
        className,
        wrapperClassName && styles[wrapperClassName],
      )}
    >
      {children}
    </section>
  );
};

OneQuarterPage.displayName = 'OneQuarterPage';

export default OneQuarterPage;
