import type { Model, WithdrawAction } from './types';
import { WITHDRAWAL_STEPS } from '../view/components/withdraw.contants';

export const initialState: Model = {
  withdrawalType: 1,
  payload: {
    amount: 0,
    minimumAmount: 5,
    step: 1,
    apiError: false,
    errorMessage: null,
    isModalOpen: false,
    onWithdraw: null,
    withdrawalFee: 0,
    withdrawnAmount: 0,
    password: '',
    isPasswordValid: false,
    isWithdrawing: false,
    paypal: {
      paypalEmail: '',
      saveDetails: false,
      isEmailValid: false,
    },
    bankAccount: {
      autoWithdrawEnabled: false,
      bankDetails: {
        accountName: '',
        accountNumber: '',
        accountSortcode: '',
      },
      isFormValid: {
        accountName: false,
        accountNumber: false,
        accountSortcode: false,
      },
      forceValidation: false,
      saveDetails: false,
    },
  },
};

export function withdrawReducer(
  state: Model = initialState,
  action: WithdrawAction,
): Model {
  switch (action.type) {
    case 'WITHDRAW/INIT':
      return Object.assign({}, state, {
        withdrawalType: action.withdrawalType,
        payload: action.payload,
      });

    case 'WITHDRAW/GOTO_STEP':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, { step: action.step }),
      });

    case 'WITHDRAW/SAVE_PAYPAL_DETAILS':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          paypal: Object.assign({}, state.payload.paypal, {
            paypalEmail: action.email,
            saveDetails: action.saveDetailsForNextTime,
          }),
        }),
      });

    case 'WITHDRAW/SAVE_BANK_ACCOUNT_DETAILS':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          bankAccount: Object.assign({}, state.payload.bankAccount, {
            saveDetails: action.saveDetailsForNextTime,
            autoWithdrawEnabled: action.autoWithdrawEnabled,
            bankDetails: Object.assign({}, state.payload.bankAccount, {
              accountName: action.bankDetails.accountName,
              accountNumber: action.bankDetails.accountNumber,
              accountSortcode: action.bankDetails.accountSortcode,
            }),
          }),
        }),
      });

    case 'WITHDRAW/SAVE_PASSWORD':
      const password: string = action.password;
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          password: password,
        }),
      });

    case 'WITHDRAW/WITHDRAW':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          isWithdrawing: true,
        }),
      });

    case 'WITHDRAW/WITHDRAW_SUCCESS':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          withdrawnAmount: action.response.body.amount,
          step: WITHDRAWAL_STEPS.SUCCESS,
          isWithdrawing: false,
          apiError: false,
          errorMessage: null,
        }),
      });

    case 'WITHDRAW/WITHDRAW_FAILURE':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          apiError: true,
          errorMessage: action.errorMessage,
          isWithdrawing: false,
        }),
      });

    case 'WITHDRAW/CHANGE_BANK_ACCOUNT_FIELD':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          bankAccount: Object.assign({}, state.payload.bankAccount, {
            bankDetails: Object.assign(
              {},
              state.payload.bankAccount.bankDetails,
              { [action.fieldName]: action.fieldValue },
            ),
            isFormValid: Object.assign(
              {},
              state.payload.bankAccount.isFormValid,
              { [action.fieldName]: !action.fieldError },
            ),
          }),
        }),
      });

    case 'WITHDRAW/CHANGE_PAYPAL_EMAIL':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          paypal: Object.assign({}, state.payload.paypal, {
            paypalEmail: action.email,
            isEmailValid: !action.error,
          }),
        }),
      });

    case 'WITHDRAW/CHANGE_PAYPAL_SAVE_DETAILS':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          paypal: Object.assign({}, state.payload.paypal, {
            saveDetails: action.saveDetails,
          }),
        }),
      });

    case 'WITHDRAW/CHANGE_AUTO_WITHDRAW':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          bankAccount: Object.assign({}, state.payload.bankAccount, {
            autoWithdrawEnabled: action.autoWithdrawEnabled,
          }),
        }),
      });

    case 'WITHDRAW/CHANGE_BANK_ACCOUNT_SAVE_DETAILS':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          bankAccount: Object.assign({}, state.payload.bankAccount, {
            saveDetails: action.saveDetails,
          }),
        }),
      });

    case 'WITHDRAW/CHANGE_PASSWORD':
      return Object.assign({}, state, {
        payload: Object.assign({}, state.payload, {
          password: action.password,
          isPasswordValid: action.isPasswordValid,
        }),
      });

    default:
      return state;
  }
}
