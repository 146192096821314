import type { Price } from 'globals/perks.types';
import { getFullPrice } from 'globals/helpers';
import { spotRewardsReferrer } from '../../../../shared/constants';
import type { SelectCompValue } from '../../../../../../globals/types';

import SelectComp from 'inputs-comp/select-comp/select-comp';

import styles from './price-list-select.module.scss';

type Props = {
  availableBalance: number;
  onInputChange: (value: number, err: boolean) => void;
  priceList: Array<Price>;
  referrer: string;
  selectedPrice: Price;
};

type State = {
  errorMessage: string;
  isError: boolean;
};

class PriceListSelect extends React.Component<Props, State> {
  static displayName = 'PriceListSelect';

  state = {
    errorMessage: '',
    isError: false,
  };

  checkForError = (value: number, balance: number, referrer?: string) => {
    if (referrer === spotRewardsReferrer && balance < value) {
      return 'giftcards.insufficient_balance';
    } else if (value === 0) {
      return 'giftcards.please_select';
    }

    return '';
  };

  handleInputChange = ({ value }: SelectCompValue) => {
    const { availableBalance, onInputChange, referrer } = this.props;
    const parsedValue = parseInt(value.toString(), 10);

    const errorMessage = this.checkForError(
      parsedValue / 100,
      availableBalance,
      referrer,
    );

    this.setState({
      errorMessage,
      isError: !!errorMessage.length,
    });

    onInputChange(parsedValue, !!errorMessage.length);
  };

  buildDropdownOptions = (pricesList: Array<Price>): Array<SelectCompValue> =>
    (pricesList || []).map((price: Price) => ({
      name: getFullPrice(price, 2, true),
      value: price.amount.toString(),
    }));

  sortPriceList = (priceList: Array<Price>): Array<Price> =>
    priceList && priceList.sort((a: Price, b: Price) => a.amount - b.amount);

  getRangeWithCurrency = (priceList: Array<Price>) => {
    const sortedPriceList = this.sortPriceList(priceList);
    return {
      min: getFullPrice(sortedPriceList[0], 2, true),
      max: getFullPrice(sortedPriceList[sortedPriceList.length - 1], 2, true),
    };
  };

  render() {
    const { priceList, selectedPrice } = this.props;
    const { isError, errorMessage } = this.state;

    const rangeWithCurrency = this.getRangeWithCurrency(priceList);

    const selectedItem = selectedPrice
      ? {
          name: getFullPrice(selectedPrice, 2, true),
          value: selectedPrice.amount.toString(),
        }
      : null;

    return (
      <div>
        <div className={styles.label}>
          {priceList.length > 1
            ? polyglot.t('giftcards.enter_value_range', rangeWithCurrency)
            : polyglot.t('giftcards.enter_value')}
        </div>
        <SelectComp
          errorMessage={polyglot.t(errorMessage)}
          onItemSelected={(value: SelectCompValue) =>
            this.handleInputChange(value)
          }
          options={this.buildDropdownOptions(this.sortPriceList(priceList))}
          placeholder={polyglot.t('giftcards.please_select')}
          selectedItem={selectedItem}
          selectClassName={'gift-cards-price-list'}
          showError={isError}
          ariaLabel={polyglot.t(
            'perks.gift_card_value_selection_dropdown_aria_label',
          )}
        />
      </div>
    );
  }
}

export default PriceListSelect;
