import type { Model, GlobalAction } from './types';
import { getCookie } from 'globals/cookieManager';

export const initialState: Model = {
  isComingFromNative: !!getCookie('isComingFromNative'),
  isSectionsMenuOnScreen: false,
  isCompanyInitialised: false,
};

export function globalReducer(
  state: Model = initialState,
  action: GlobalAction,
): Model {
  switch (action.type) {
    case 'GLOBAL/IS_SECTIONS_MENU_ON_SCREEN':
      return {
        ...state,
        isSectionsMenuOnScreen: action.isSectionsMenuOnScreen,
      };
    case 'GLOBAL/IS_COMPANY_INITIALISED':
      return {
        ...state,
        isCompanyInitialised: action.isCompanyInitialised,
      };
    default:
      return state;
  }
}
