import fetch from 'globals/wa-fetch';
import get from 'lodash/get';
import map from 'lodash/map';
import { getStore } from 'app/hermes-redux';
import {
  setUser as reduxSetUser,
  updateUser as reduxUpdateUser,
  resetUser as reduxResetUser,
} from 'app/state/session/actions';
import USER_ROLES from 'constants/user-roles.constant';
import { roles, allAdminRoles } from 'constants/roles.constant';

// TODO: for now the user store is linked to redux state, should be removed soon

export const getUser = () => {
  if (!getStore()) {
    return {};
  }

  try {
    const { session, signup } = getStore().getState();

    return (
      session.user || signup?.initData?.user || signup?.signUpResponse || {}
    );
  } catch {
    return {};
  }
};

export const setUser = user => {
  return getStore().dispatch(reduxSetUser(user));
};

export function getUserCountryCode() {
  return getUser().country_code;
}

export function isAdmin() {
  return allAdminRoles.includes(getUser().role);
}

export function isVisibleToAdmin() {
  const currentRoles = getUser().current_roles || [];

  return (
    currentRoles.includes(roles.admin) ||
    currentRoles.includes(roles.superAdmin)
  );
}

export function wellnessCategories() {
  return getUser()['wellness_categories'];
}

export function totalWellnessCategories() {
  return wellnessCategories().length;
}

export const resetUser = () => {
  return getStore().dispatch(reduxResetUser());
};

export function updateUser(body) {
  return getStore().dispatch(reduxUpdateUser(body));
}

// TODO: remove depended when API is updated - 2018-02-28
export const isDependantAccount = user =>
  user['account_type'] === 'depended' || user['account_type'] === 'dependant';

export const getEffectiveSettingsUrl = key =>
  get(getUser(), `effective_settings.urls.${key}`);

export const getEffectiveSettingsService = key =>
  getUser()['effective_settings'] &&
  getUser()['effective_settings']['services'] &&
  getUser()['effective_settings']['services'][key];

export const isMe = (user = {}) => user['user_id'] === getUser()['user_id'];

export const isSharedAccount = () =>
  getUser() && getUser()['is_shared_account'];

export const getMe = (params = {}) =>
  fetch('/user/me?exclude_field=badges', {
    token: true,
    version: 1.3,
    ...params,
  });

export function getSpecificUsers(ids) {
  return fetch('/user', {
    version: 1.3,
    urlParameters: {
      user_id_list: ids.join(','),
    },
    token: true,
  });
}

export function requestResetPassword(params) {
  return fetch('/user/reset-password-request', {
    method: 'post',
    version: 1.3,
    body: params,
  });
}

export function confirmResetPassword(params) {
  return fetch('/user/reset-password-confirmed', {
    body: params,
    method: 'post',
    version: 1.3,
  });
}

export function searchUsers(user, exclude, myGroupOnly) {
  return fetch('/user', {
    version: 1.3,
    urlParameters: {
      search_term: user,
      excluding_me: true,
      exclude_user_id_list: exclude,
      my_group_only: myGroupOnly ? myGroupOnly : false,
      offset: 0,
      limit: 200,
    },
    token: true,
  });
}

export function confirmEmail(email) {
  return fetch('/user/signup-email-confirm', {
    method: 'post',
    version: 1.3,
    token: false,
    body: {
      email_confirmation_code: email,
    },
  })
    .then(() => ({ emailConfirmed: true, alreadyVerified: false }))
    .catch(err => ({
      emailConfirmed: false,
      alreadyVerified: err.error.code === -1056,
    }));
}

/*
  Check if user has the permission the move to a route
*/
export function isUserAuthorized(authorizedRoles) {
  const userRole = getStore().getState().session.role;

  if (!Array.isArray(authorizedRoles)) {
    authorizedRoles = [authorizedRoles];
  }

  return (
    authorizedRoles.includes(USER_ROLES.all) ||
    authorizedRoles.indexOf(userRole) !== -1
  );
}

export function firstSSOLoginDone() {
  return fetch(`/users/${getUser()['user_id']}/first-sso-login`, {
    method: 'post',
    token: true,
    version: 1.4,
  }).then(() => Object.assign({}, getUser(), { first_sso_login: false }));
}

/* User grouping stores */

export function getSubscribedGroups() {
  return fetch(`/users/${getUser()['user_id']}/subscribe-groups`, {
    method: 'get',
    version: 1.4,
    token: true,
  }).then(res => res.body);
}

export function getGroups(paging, currentFilter) {
  const params = {
    limit: paging.limit,
    offset: paging.page * paging.limit + paging.offset,
    order: 'alphabetical',
  };

  if (currentFilter) {
    params['first_letter'] = currentFilter;
  }

  return fetch(`/users/${getUser()['user_id']}/groups`, {
    urlParameters: params,
    method: 'get',
    version: 1.4,
    token: true,
  }).then(res => {
    return { response: res.body, paging: res.paging };
  });
}

export function searchGroups(searchTerm) {
  const params = {
    limit: 100,
    offset: 0,
    order: 'alphabetical',
    search_term: searchTerm,
  };

  return fetch(`/users/${getUser()['user_id']}/groups`, {
    urlParameters: params,
    method: 'get',
    version: 1.4,
    token: true,
  }).then(res => map(res.body.groups, item => item.group));
}

export function unsubscribeUserFromGroup(groupId) {
  return fetch(`/users/${getUser()['user_id']}/subscribe-groups/${groupId}`, {
    method: 'delete',
    version: 1.4,
    token: true,
  });
}

export function subscribeUserToGroup(groupId) {
  return fetch(`/users/${getUser()['user_id']}/subscribe-groups`, {
    method: 'post',
    version: 1.4,
    token: true,
    body: {
      group_ids: [groupId],
    },
  });
}

export function getUserLocale() {
  return getUser().locale;
}
