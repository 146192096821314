import classNames from 'classnames';
import styles from './carousel-slide.module.scss';
import { resolveClassNames } from 'wa-storybook/helpers/classes';
import { ModalPayload } from 'state/modal/types';
import { sanitizeHtml } from 'wa-storybook/helpers/sanitize';

export type CarouselSlideModel = {
  className?: string | Array<string>;
  imageSource?: string;
  title: string;
  subtitle?: string;
  subheader?: string;
  featureKey?: string | null | undefined;
  content?: React.ReactNode;
  hasHtml?: boolean;
};

type CarouselSlideProps = {
  slide: CarouselSlideModel;
  payload: ModalPayload;
  activeSlide: boolean;
  hasHtml?: boolean;
};

const CarouselSlide = ({
  payload,
  slide: {
    className,
    imageSource,
    title,
    subtitle,
    subheader,
    content,
    hasHtml,
  },
  activeSlide,
}: CarouselSlideProps) => {
  return (
    <div tabIndex={activeSlide ? 0 : -1}>
      <div
        className={classNames(
          'slide',
          styles['main-container'],
          resolveClassNames(className, styles),
        )}
      >
        <div className={styles[`${payload.className}-img-container`]}>
          {!!imageSource && (
            <img className={styles.illustration} src={imageSource} alt='' />
          )}
        </div>
      </div>
      <div className={styles.separator}>
        <div className={styles.message} role='region' aria-live='assertive'>
          {hasHtml ? (
            <div
              className={classNames(styles[`${payload.className}-title`])}
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }}
            />
          ) : (
            <div className={classNames(styles[`${payload.className}-title`])}>
              {title}
            </div>
          )}

          {subheader && <div className={styles.subheader}>{subheader}</div>}

          {subtitle &&
            (hasHtml ? (
              <div
                className={classNames(styles[`${payload.className}-subtitle`])}
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(subtitle) }}
              />
            ) : (
              <div
                className={classNames(styles[`${payload.className}-subtitle`])}
              >
                {subtitle}
              </div>
            ))}

          {content && <div className={styles.content}>{content}</div>}
        </div>
      </div>
    </div>
  );
};
CarouselSlide.displayName = 'CarouselSlide';

export default CarouselSlide;
