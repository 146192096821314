import { logout } from 'state/session/actions';
import USER_ROLES from 'constants/user-roles.constant';
import { getStore } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import('./resend-invitation-success'),
    getStore().dispatch(logout(true)), // force logout
  ]).then(([component]) => {
    return component;
  });
}

const resendInvitationSuccessModule = {
  name: 'resendInvitationSuccess',
  url: '/signup/invitation/resend-invitation-success',
  resolver,
  data: {
    title: 'components.main_menu.signup',
    authorizedRoles: [USER_ROLES.all],
  },
} as const;

export default resendInvitationSuccessModule;
