import OneQuarterPage from 'perks/layouts/one-quarter';
import isEmpty from 'lodash/isEmpty';

import classNames from 'classnames';

import styles from './left-menu.module.scss';
import type { Store } from 'globals/perks.types';

type Props = {
  categories?: Store[];
  onListItemClick: any;
  onClickHome?: any;
  children?: React.ReactElement<any>;
  title?: string;
  idField: string;
  fetching?: boolean;
  active?: string;
};

export const renderMenuItem = (
  category: Store,
  action: any | null | undefined,
  idField: string,
  active: boolean,
) => (
  <li
    className={styles['list-item']}
    data-id={`item-${category[idField]}`}
    key={category[idField]}
  >
    <button
      className={classNames(
        styles.category,
        active && styles['category--active'],
      )}
      onClick={() => {
        if (active || !action) {
          return null;
        }
        return action(category[idField]);
      }}
    >
      {category.name}
    </button>
  </li>
);

const LeftMenu = (props: Props) => {
  const {
    categories,
    onClickHome,
    fetching,
    title,
    idField,
    onListItemClick,
    children,
    active,
  } = props;
  const activeId = isEmpty(active) ? '' : active;
  return (
    <OneQuarterPage
      aria-label={title}
      className={classNames(
        styles['sidebar-container'],
        active === 'onlineshop_favourites' &&
          styles['sidebar-container--disabled'],
      )}
    >
      <div className={styles['categories-container']}>
        {title && (
          <button
            className={classNames(styles['categories-title'])}
            disabled={!!onClickHome}
            onClick={onClickHome}
          >
            {title}
          </button>
        )}
        {!fetching && (
          <ul className={styles['categories-list']}>
            {categories &&
              categories.length !== 0 &&
              categories.map((item: Store) =>
                renderMenuItem(
                  item,
                  item[idField] ? onListItemClick : onClickHome,
                  idField,
                  item[idField] === activeId,
                ),
              )}
          </ul>
        )}
      </div>
      {children}
    </OneQuarterPage>
  );
};

LeftMenu.displayName = 'LeftMenu';

export default LeftMenu;
