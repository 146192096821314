const userRole = 1;
const adminRole = 2;
const superAdminRole = 4;

export const roles = {
  user: userRole,
  admin: adminRole,
  superAdmin: superAdminRole,
} as const;

export const allAdminRoles = [adminRole, superAdminRole] as const;
