import version from 'version';
import { DatadogClient } from '@workivate/tho-web-shared';
import type {
  RumInitConfiguration,
  LogsInitConfiguration,
} from '@workivate/tho-web-shared';

const commonDatadogConfig = {
  service: 'wa-hermes',
  env: window.WAM.ENV.env === 'live' ? 'prod' : window.WAM.ENV.env,
  version: `hermes@v${version.version.replace('"', '')}`,
  clientToken: window.WAM.ENV.ddClientToken ?? '',
};

const dataDogRUMConfig: RumInitConfiguration = {
  ...commonDatadogConfig,
  applicationId: window.WAM.ENV.ddAppId ?? '',

  allowedTracingUrls: [
    {
      propagatorTypes: ['datadog'],
      match: /https?:\/\/.*\.lifeworks\.com\/tenancy-lookup-api\/tenant$/,
    },
    {
      propagatorTypes: ['datadog'],
      match: /https?:\/\/.*\.lifeworks\.com\/wellbeing-graphql-api\/graphql$/,
    },
  ],
  defaultPrivacyLevel: 'mask',
  sessionReplaySampleRate: 20,
  sessionSampleRate: 100,

  trackLongTasks: true,
  trackUserInteractions: true,
};

const dataDogLogsConfig: LogsInitConfiguration = {
  ...commonDatadogConfig,
};

export const initialiseDataDog = () => {
  DatadogClient.initDataDog(dataDogLogsConfig, dataDogRUMConfig);
};
