import { injectAsyncReducer } from 'app/hermes-redux';
import { restrictions } from 'state/features-list/constants';

import USER_ROLES from 'constants/user-roles.constant';

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "perks" */ './perks-home-page-comp'),
    import(/* webpackChunkName: "perks" */ './perks-home-page.reducers'),
    import(
      /* webpackChunkName: "offersReducer" */ 'pages/offers/state/reducer'
    ),
  ]).then(([component, reducer, offersReducer]) => {
    injectAsyncReducer('perksHomePage', reducer.default);
    injectAsyncReducer('offers', offersReducer.offerReducer);
    return component;
  });
}

const perksHomePageModule = {
  name: 'perksHomePage',
  url: '/perks-home-page',
  restrictions: {
    user: [
      restrictions['benefit_online_shop'],
      restrictions['benefit_restaurant'],
      restrictions['benefit_cinema'],
      restrictions['benefit_colleague_offer'],
      restrictions['benefit_global_gift_cards'],
      restrictions['benefit_in_store_offer'],
    ],
    operator: 'OR',
    minimum: 2,
  },
  data: {
    title: 'components.main_menu.discounts.title',
    mainMenu: 'perksHomePage',
    section: 'deals',
    order: 0,
    includeInMenu: true,
    authorizedRoles: [USER_ROLES.employee],
    shortcutKeyCode: 51,
  },
  resolver,
};

export default perksHomePageModule;
