import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "groups" */ './view/groups-comp'),
    import(/* webpackChunkName: "groups-reducer" */ './state/reducer'),
  ]).then(([component, reducer]: [any, any]) => {
    injectAsyncReducer('groupsFeed', reducer.groupsFeedReducer);
    return component;
  });
}

const groupsModule = {
  name: 'groups',
  url: '/groups',
  restrictions: {
    user: [restrictions['social_feed'], restrictions['grouping']],
  },
  resolver,
  data: {
    title: 'components.main_menu.groups',
    mainMenu: 'groups',
    section: 'company',
    includeInMenu: true,
    order: 2,
    authorizedRoles: [USER_ROLES.employee],
  },
} as const;

export default groupsModule;
