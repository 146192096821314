import USER_ROLES from 'app/constants/user-roles.constant';
import {
  JIT_MANDATORY_MODULE_NAME,
  JIT_MODULE_NAME,
  SIGN_UP_CONSENT_MODULE_NAME,
} from './constants';

const consentModule = {
  jit: {
    name: JIT_MODULE_NAME,
    url: '/consent/just-in-time',
    resolver: () =>
      import(
        /* webpackChunkName: "jit-consent" */ './container/jit-consent-container/jit-consent-container'
      ),
    data: {
      section: 'wellBeing',
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
      hideFooter: true,
    },
  },
  jitMandatory: {
    name: JIT_MANDATORY_MODULE_NAME,
    url: '/consent/just-in-time-mandatory',
    resolver: () =>
      import(
        /* webpackChunkName: "jit-consent-mandatory" */ './container/jit-consent-container/jit-consent-container'
      ),
    data: {
      includeInMenu: false,
      authorizedRoles: [USER_ROLES.employee],
      hideFooter: true,
    },
  },
  popUp: {
    name: SIGN_UP_CONSENT_MODULE_NAME,
    url: '/consent/pop-up',
    resolver: () =>
      import(
        /* webpackChunkName: "pop-up-consent" */ './container/sign-up-consents-container/sign-up-consents-container'
      ),
    data: {
      hideFooter: true,
      authorizedRoles: [USER_ROLES.all],
      showEmptyNavbar: true,
      hideNavbar: true,
    },
  },
} as const;

export default consentModule;
