import classNames from 'classnames';
import styles from './categories-banner.module.scss';
import * as Helpers from 'wa-storybook/global/helpers';

type Category = {
  name: string;
  icon?: string;
  action: any;
  active: boolean;
};

type Props = {
  title: string;
  categories?: Array<Category>;
  rightCTA?: Array<Category>;
  children?: any;
};

const CategoriesBanner = ({ title, categories, rightCTA, children }: Props) => {
  const rightLinkOffset =
    categories && categories.length !== 0 ? categories.length + 1 : 0;
  return (
    <div className={styles['container']}>
      <h2 className={classNames(styles.title, styles.item)}>{title}</h2>
      <div
        className={classNames(
          categories
            ? styles['categories-container']
            : styles['categories-container--no-categories'],
        )}
      >
        {categories ? (
          categories.map((item: Category, index: number) => {
            return (
              <a
                className={classNames(
                  styles.item,
                  styles['item__category'],
                  item.active && styles['item__category--active'],
                )}
                key={index}
                role='link'
                tabIndex={0}
                onClick={item.active ? null : item.action}
                onKeyDown={Helpers.onEnterOrSpace(
                  item.active ? null : item.action,
                )}
                aria-label={polyglot.t('categories_banner.item_aria_label', {
                  title,
                  category: item.name,
                })}
              >
                {item.name}
              </a>
            );
          })
        ) : (
          <div className={styles['inner-children']}>{children}</div>
        )}
      </div>
      {rightCTA &&
        rightCTA.map((item: Category, index: number) => (
          <div className={styles['rightCTA']} key={rightLinkOffset + index}>
            <a
              className={classNames(styles.item, styles['item--right-CTA'])}
              onClick={item.action}
              role='link'
              tabIndex={0}
              onKeyDown={Helpers.onEnterOrSpace(item.action)}
              aria-label={polyglot.t('categories_banner.item_aria_label', {
                title,
                category: item.name,
              })}
            >
              {item.name}
              {item.icon && (
                <span className={styles['icon-container']}>
                  <i className={item.icon} />
                </span>
              )}
            </a>
          </div>
        ))}
    </div>
  );
};

CategoriesBanner.displayName = 'CategoriesBanner';

export default CategoriesBanner;
