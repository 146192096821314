import {
  differenceInHours,
  format,
  formatDistanceStrict,
  isSameDay,
} from 'date-fns';

import { getFormattedDate as getTranslatedFormatedDate } from 'wa-storybook/global/date-utils';
import Language from 'language';
import { locales } from './date-helpers';
import isNumber from 'lodash/isNumber';

const MILLISECONDS = 1000;

function startOfMonth(date: Date) {
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(0);

  return date.setDate(1);
}

function inSeconds(millis: number) {
  return Math.floor(millis / MILLISECONDS);
}

export function startOfThisMonth() {
  return inSeconds(startOfMonth(new Date()));
}

export function startOfLastMonth() {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  return inSeconds(startOfMonth(date));
}

export function endOfLastMonth() {
  return startOfThisMonth();
}

export function minusMonths(months: number) {
  const date = new Date();

  return inSeconds(date.setMonth(date.getMonth() - months));
}

export function getRelativeTime(
  timestamp: Date | number | null,
  isDate = false,
) {
  let date: Date | number;

  if (isDate) {
    date = timestamp ?? new Date();
  } else {
    date = isNumber(timestamp) ? timestamp * MILLISECONDS : new Date();
  }

  const relativeTime = formatDistanceStrict(date, new Date(), {
    addSuffix: true,
    locale: locales[Language.getLocale(true)],
    roundingMethod: 'floor',
  });
  // restore original relativeTimeRounding settings
  return relativeTime;
}

/*
  There are 3 scenarios for date formatting:
  1. The API supplies a date in UTC. We need to convert this to the user's local
     time. (fixedTimeZone = false)
  2. The API supplies a date in local time. We do not need to convert this.
     (fixedTimeZone = true)
  3. The API supplies a date the user has set in UTC (e.g. a birthday). We do
     not need to convert this. (fixedTimeZone = true)
 */

type DateFormat = {
  weekday?: 'narrow' | 'short' | 'long';
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
  day?: 'numeric' | '2-digit';
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
};

export const getTranslatedDate = (
  date: string | number | Date,
  formatString: string,
) =>
  getTranslatedFormatedDate(date, formatString, {}, Language.getLocale(true));

const getFormattedDate = (
  timeStamp: string | number | Date,
  fixedTimeZone: boolean,
  options: DateFormat,
) => {
  const adjustedTimeStamp =
    ('' + timeStamp.toString()).length <= 10 ||
    parseFloat(timeStamp.toString()) < 0
      ? parseFloat(timeStamp.toString()) * MILLISECONDS
      : timeStamp;

  return new Date(adjustedTimeStamp).toLocaleString(Language.getLocale(true), {
    ...options,
    ...(fixedTimeZone ? { timeZone: 'UTC' } : {}),
  });
};

export const getLongFormatDate = (
  timeStamp: string | number,
  fixedTimeZone = false,
) =>
  getFormattedDate(timeStamp, fixedTimeZone, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

export const getLongFormatDateWithoutYear = (
  timeStamp: string | number,
  fixedTimeZone = false,
) =>
  getFormattedDate(timeStamp, fixedTimeZone, {
    month: 'long',
    day: 'numeric',
  });

export const getShortFormatDate = (
  timeStamp?: string | number | null,
  fixedTimeZone = false,
) => {
  if (timeStamp !== undefined && timeStamp !== null) {
    return getFormattedDate(timeStamp, fixedTimeZone, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }

  return undefined;
};

export const getShortenedMonthNumericDate = (
  timeStamp: string | number | Date,
  fixedTimeZone = false,
) => {
  return getFormattedDate(timeStamp, fixedTimeZone, {
    month: 'short',
    day: 'numeric',
  });
};

// return the generic expire label, startTimeStamp is optional
export function getExpiryDate(
  expiryTimestamp: number,
  startTimestamp?: number | null,
) {
  let expireLabel;
  const expiryDate = new Date(expiryTimestamp * MILLISECONDS);
  const diff = differenceInHours(expiryDate, new Date());

  if (diff < 0) {
    expireLabel = window.polyglot.t('globals.date_utils.expired');
  } else if (diff < 12) {
    expireLabel = window.polyglot.t('globals.date_utils.expires_in', {
      hours: diff,
    });
  } else {
    expireLabel = !startTimestamp
      ? window.polyglot.t('globals.date_utils.expires') +
        ' ' +
        Intl.DateTimeFormat(Language.getLocale(true)).format(expiryDate)
      : window.polyglot.t('globals.date_utils.offer_valid', {
          start: Intl.DateTimeFormat(Language.getLocale(true)).format(
            new Date(startTimestamp * MILLISECONDS),
          ),
          end: Intl.DateTimeFormat(Language.getLocale(true)).format(expiryDate),
        });
  }

  return expireLabel;
}

export const isBeforeMidday = (date: Date): boolean => {
  return date.getHours() < 12;
};
// https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
export const getISOInterval = (start: Date, end: Date): string => {
  return `${start.toISOString()}/${end.toISOString()}`;
};

export const formatTimeRange = (
  start: Date,
  end: Date,
  timeFormat?: string,
): string => {
  const AM_PM_FORMAT = timeFormat ? `${timeFormat} aa` : 'hh:mm aa';
  // Creates a string like 09:00 AM - 12:00 PM

  const startBeforeMidday = isBeforeMidday(start);
  const endBeforeMidday = isBeforeMidday(end);
  const bothBeforeMidday = startBeforeMidday && endBeforeMidday;
  const bothAfterMidday = !startBeforeMidday && !endBeforeMidday;

  const locale = {
    ...locales[Language.getLocale(true)],
  } as const;

  if (bothBeforeMidday || bothAfterMidday) {
    return polyglot.t('challenges.global.time_range', {
      from: format(start, timeFormat ? timeFormat : 'hh:mm', { locale }),
      to: format(end, AM_PM_FORMAT, { locale }),
    });
  }

  return polyglot.t('challenges.global.time_range', {
    from: format(start, AM_PM_FORMAT, { locale }),
    to: format(end, AM_PM_FORMAT, { locale }),
  });
};

export const formatDateRange = (
  start: Date,
  end: Date,
  dateFormat?: string,
): string => {
  const locale = {
    ...locales[Language.getLocale(true)],
  } as const;

  const DATE_FORMAT = dateFormat ? dateFormat : 'cccc LLLL d';

  if (isSameDay(start, end)) {
    return polyglot.t('challenges.global.date.single_day', {
      date: format(start, DATE_FORMAT, { locale }),
      timeRange: formatTimeRange(start, end),
    });
  }

  const DATE_FORMAT_LONG = 'cccc LLLL d h:mm aa';

  return polyglot.t('challenges.global.date.multiple_days', {
    from: format(start, DATE_FORMAT_LONG, { locale }),
    to: format(end, DATE_FORMAT_LONG, { locale }),
  });
};
