import cloudinary from 'globals/cloudinary';

import type { CinemaOrder, Code, Ticket } from 'pages/cinemas/hub/state/types';
import { getShortFormatDate } from 'globals/date-utils';

import CodeComp from 'code-comp/code-comp';
import Card from 'wa-storybook/components/general/card/card';

import styles from './cinema-codes.module.scss';

type Props = {
  cinOrder?: Array<CinemaOrder>;
};

const CinemaCodes = ({ cinOrder }: Props) => (
  <>
    {cinOrder?.map((cinemaOrder: CinemaOrder) => {
      const { short_order_id, merchants, created_on } = cinemaOrder;

      if (!merchants[0]) return null;

      const { tickets, name, logo } = merchants[0];

      const cinemaTickets = (ticket: Ticket, index: number) => {
        const { quantity, type, items, closest_expiry_date, sku } = ticket;
        return (
          <CodeComp
            key={`${sku}-${closest_expiry_date}-${index}`}
            title={`${quantity}x ${type} \u2022 ${window.polyglot.t(
              'cinemas.expires',
            )} : ${getShortFormatDate(closest_expiry_date)}`}
            codes={items.map((item: Code) => item.code)}
          />
        );
      };

      return (
        <Card className={styles['wrapper']} key={short_order_id}>
          {logo !== null && (
            <img
              className={styles['cinema-image']}
              src={cloudinary(logo.url, 'w_100,h_100,c_fill')}
              alt=''
            />
          )}
          <div className={styles['cinema-description']}>
            <div className={styles['cinema-name']}>{name}</div>
            <div className={styles['order-id']}>
              {polyglot.t('cinemas.order_id')}
              <span className={styles.id}> {short_order_id}</span>
            </div>
            <div className={styles['order-id']}>
              {polyglot.t('cinemas.purchase_date', {
                purchaseDate: getShortFormatDate(created_on),
              })}
            </div>
          </div>
          <div>{tickets.map(cinemaTickets)}</div>
        </Card>
      );
    })}
  </>
);

export default CinemaCodes;
