import createSagaMiddleware from 'redux-saga';
import { connect, Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise';
import { responsiveStoreEnhancer } from 'redux-responsive';
import thunk from 'redux-thunk';

import { AppState, Dispatch } from 'globals/types';

import createRootReducer from 'state/reducers';

let store;

export function initializeStore() {
  const sagaMiddleware = createSagaMiddleware();

  store = createStore(
    createRootReducer(),
    // @ts-expect-error - TS2769 - No overload matches this call.
    compose(
      responsiveStoreEnhancer,
      applyMiddleware.apply(null, [thunk, promise, sagaMiddleware]),
      window.__REDUX_DEVTOOLS_EXTENSION__ && !IS_PRODUCTION
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f: any) => f,
    ),
  );

  store.asyncReducers = {};

  return {
    ...store,
    runSaga: sagaMiddleware.run,
  };
}

export function getStore(): {
  getState: () => AppState;
  dispatch: Dispatch;
} {
  return store;
}

export function injectAsyncReducer(
  name: string,
  asyncReducer: Record<any, any>,
) {
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer(createRootReducer(store.asyncReducers));
}

export function connectWithViewport(
  comp: any,
  mstp?: any,
  mdtp?: any,
): React.ComponentType<any> {
  const customMstp = (state: AppState) => ({
    viewport: context.viewport,
    browser: state.browser || {},
    ...(mstp ? mstp(state) : {}),
  });
  const connector = connect(customMstp, mdtp)(comp);

  return connector;
}

export function connectWithProvider(
  connectFn: (arg1: AppState) => any,
  Comp: any,
  mdtp?: any,
): React.ComponentType<any> {
  const ConnectedComp = connect(connectFn, mdtp)(Comp);

  return class ProviderWrapper extends React.Component<any> {
    render() {
      return (
        <Provider store={store}>
          <ConnectedComp {...this.props} />
        </Provider>
      );
    }
  };
}

export function createReducer(initialState: any, reducerMap: any): () => void {
  return (state: any = initialState, action: any = {}) => {
    const reducer = reducerMap[action.type];

    if (reducer) {
      return reducer(state, action.payload);
    }
    return state;
  };
}

export function createAction(type: string, payload?: any): any {
  return {
    type,
    payload,
  };
}

export function createConstants(
  prefix: string | null | undefined = '',
  ...constants: Array<string>
): any {
  return constants.reduce<Record<string, any>>((acc: any, constant: string) => {
    acc[constant] = `${prefix}${constant}`;
    return acc;
  }, {});
}
