import { createReducer } from 'app/hermes-redux';
import notificationActionTypes from './action-types';
import { Notification, NotificationsModel } from './types';

export const initialState = {
  notifications: [],
  isFetchPending: false,
  numNewNotifications: 0,
  refetchIntervalSeconds: 60,
};

export default createReducer(initialState, {
  [notificationActionTypes.SET_TOTAL_NOTIFICATIONS_NUMBER]: (
    state: NotificationsModel = initialState,
    numNewNotifications: number,
  ): NotificationsModel => {
    return { ...state, numNewNotifications };
  },

  [notificationActionTypes.FETCH_NOTIFICATIONS_PENDING]: (
    state: NotificationsModel = initialState,
  ): NotificationsModel => {
    return { ...state, isFetchPending: true };
  },
  [notificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS]: (
    state: NotificationsModel = initialState,
    payload: Array<Notification>,
  ): NotificationsModel => {
    return {
      ...state,
      notifications: payload,
      isFetchPending: false,
      lastFetched: Date.now(),
      numNewNotifications: 0,
    };
  },
});
