import Rx from 'rx-lite';
import { getStore } from 'app/hermes-redux';
import { CSSProperties } from 'react';

export const handleStyle = (style: any): CSSProperties | null | undefined => {
  const viewport = getStore().getState().browser.is;
  if (typeof style === 'object') {
    const { mobile, tablet, desktop, desktopXl, ...defaultStyles } = style;
    if (viewport.mobile) {
      return { ...defaultStyles, ...mobile };
    } else if (viewport.tablet) {
      return { ...defaultStyles, ...mobile, ...tablet };
    } else if (viewport.desktop) {
      return { ...defaultStyles, ...mobile, ...tablet, ...desktop };
    }
    return { ...defaultStyles, ...mobile, ...tablet, ...desktop, ...desktopXl };
  }
};

class Viewport {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isDesktopXl: boolean;
  scrollPositionObs: Rx.Observable<any>;

  constructor() {
    this.isMobile = false;
    this.isTablet = false;
    this.isDesktop = false;
    this.isDesktopXl = false;
  }

  setViewport(
    isMobile: boolean,
    isTablet: boolean,
    isDesktop: boolean,
    isDesktopXl: boolean,
    scrollPositionObs: Rx.Observable<any>,
  ) {
    this.isMobile = isMobile;
    this.isTablet = isTablet;
    this.isDesktop = isDesktop;
    this.isDesktopXl = isDesktopXl;
    this.scrollPositionObs = scrollPositionObs;
  }
}

export const viewport = new Viewport();

export const isTouchDevice =
  'ontouchstart' in window || 'onmsgesturechange' in window;

if (isTouchDevice) {
  document.documentElement.className += 'touch';
} else {
  document.documentElement.className += 'no-touch';
}
