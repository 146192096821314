import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { set, get, getKeys, remove } from 'globals/storage';
import { getRegionalApiEndpoints } from 'globals/regional-api';

import { moveChildKeys } from './env-controller.helpers';

// @ts-ignore (ignoring no types/module warning for now)
import environmentVariables from '../../../../environment-variables.yml';

type EnvControllerContextValue = {
  selectedEnvironment: string | null;
  setSelectedEnvironment: (env: string | null) => void;
};

export const EnvControllerContext = createContext<EnvControllerContextValue>({
  selectedEnvironment: null,
  setSelectedEnvironment: () => null,
});

export const useEnvControllerContext = () => useContext(EnvControllerContext);

export const setStoredSelectedEnvironment = (env: string) =>
  set<string>(getKeys().setEnvironment, env, true);

export const getStoredSelectedEnvironment = () =>
  get<string>(getKeys().setEnvironment, true);

export const removeStoredSelectedEnvironment = () =>
  remove(getKeys().setEnvironment);

export const setAPIEndpoints = (env: string | null) => {
  if (!env) return;

  const chosenEnvObject = environmentVariables[env];
  const resultEnvObject = moveChildKeys(chosenEnvObject);

  window.WAM.ENV = {
    ...window.WAM.ENV,
    ...(resultEnvObject as Window['WAM']['ENV']),
    ...getRegionalApiEndpoints(),
  };

  setStoredSelectedEnvironment(env);
};

export const EnvControllerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const inNonViableEnvironment = IS_PRODUCTION || IS_TEST;

  const selectionFromStorage = getStoredSelectedEnvironment();

  const [selectedEnvironment, setSelectedEnvironment] = useState<string | null>(
    selectionFromStorage ?? null,
  );

  useEffect(() => {
    if (!selectedEnvironment || inNonViableEnvironment) return;

    setAPIEndpoints(selectedEnvironment);
  }, [selectedEnvironment]);

  const envContext: EnvControllerContextValue = useMemo(
    () => ({
      selectedEnvironment,
      setSelectedEnvironment,
    }),
    [selectedEnvironment],
  );

  if (inNonViableEnvironment) {
    return children;
  }

  return (
    <EnvControllerContext.Provider value={envContext}>
      {children}
    </EnvControllerContext.Provider>
  );
};
