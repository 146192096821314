import { OrderProps } from 'pages/checkout-braintree/state/types';
import { getUserCountryCode } from 'stores/user-store';
import type { GiftCardsOrder, GiftCard } from '../../gift-cards.types';

export const escapeGiftCardId = (giftCardId: string) =>
  giftCardId.replace('%27', '%2527');

export const includesVAT = () => {
  const noVatCountries = ['US', 'CA'];

  return !noVatCountries.includes(getUserCountryCode());
};

export const convertGiftCardsToItems = (
  order: GiftCardsOrder,
  giftCard: GiftCard,
  referrer: string,
): OrderProps => {
  const items = order.items.map(item => {
    return {
      title: giftCard.title,
      sku: item.sku,
      quantity: item.quantity,
      customizations: {
        price: {
          amount: order.expected_price.amount,
          originalAmount: item.customizations.face_value.amount,
          currency: order.expected_price.currency,
        },
      },
    };
  });

  return {
    type: referrer,
    giftCard,
    details: {
      photography: giftCard.photography?.[0]?.url || '',
      terms: { title: { body: giftCard.terms_and_conditions } },
    },
    items: items,
    total: {
      amount: order.expected_price.amount,
      originalAmount: order.total.amount,
      currency: order.total.currency,
    },
  };
};
