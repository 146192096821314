import isEmpty from 'lodash/isEmpty';

import type { Feature, Operator, List } from '../state/features-list/types';

import { operators, featureActions } from 'state/features-list/constants';

import { isFeatureEnabled, getFeature } from 'stores/features-list-store';

export const isBlocked = (
  list: List,
  notEnabledChecker: (arg1: List) => boolean,
) => (isEmpty(list) ? false : notEnabledChecker(list));

export const oneOfTheFeaturesIsNotEnabledChecker = (list: List) =>
  list.some((featureName: string) => !isFeatureEnabled(featureName));

export const getHasLessThanMinimumNumberOfFeaturesRequiredChecker =
  (minimum: number) => (list: List) => {
    const disabledFeatures = list.filter(
      (featureName: string) => !isFeatureEnabled(featureName),
    );
    const numberOfFeaturesEnabled = list.length - disabledFeatures.length;

    return numberOfFeaturesEnabled < minimum;
  };

export const noneOfTheFeaturesAreEnabledChecker = (list: List) =>
  list.every((featureName: string) => !isFeatureEnabled(featureName));

export const getAppropriateNotEnabledChecker = (
  operator?: Operator | null,
  minimum?: number | null,
) => {
  if (operator === operators.and) {
    return oneOfTheFeaturesIsNotEnabledChecker;
  } else if (minimum) {
    return getHasLessThanMinimumNumberOfFeaturesRequiredChecker(minimum);
  }
  return noneOfTheFeaturesAreEnabledChecker;
};

export const isRestricted = (
  restrictions: List,
  operator?: Operator | null,
  minimum?: number | null,
) =>
  isBlocked(restrictions, getAppropriateNotEnabledChecker(operator, minimum));

export const isAccessibleDegradedFeature = (feature: Feature) =>
  feature && !feature.action && feature.is_degraded;

export const getActionRequired = (restrictions: List) => {
  const featureHasAction = feature => {
    const featureObject = getFeature(feature);
    return featureObject && !!featureObject.action;
  };

  // If all restrictions passed have an action, then return the action.
  // Otherwise allow the page to continue to load. This is used
  // for landing pages such as the perks homepage.
  const restrictionsWithAction = restrictions.filter(featureHasAction);
  if (restrictionsWithAction.length === restrictions.length) {
    return restrictionsWithAction[0];
  }
};

export const handleRestrictions = (
  restrictions?: List | null,
  op?: Operator | null,
  min?: number | null,
) => {
  const operator = op || 'AND';
  const accessibleDegradedFeature = restrictions
    ? restrictions.find(feature => {
        const featureObject = getFeature(feature);
        return featureObject
          ? isAccessibleDegradedFeature(featureObject)
          : false;
      })
    : false;

  if (restrictions) {
    const actionRequired = getActionRequired(restrictions);

    if (isRestricted(restrictions, operator, min)) {
      return { result: false, redirectTo: 'pageNotFound', showInMenu: false };
    } else if (accessibleDegradedFeature) {
      return {
        result: true,
        showInMenu: true,
      };
    } else if (actionRequired) {
      const action = getFeature(actionRequired)?.action || null;
      return {
        result: false,
        redirectTo:
          action && featureActions[action]
            ? featureActions[action]
            : 'pageNotFound',
        showInMenu: true,
      };
    }
  }

  return { result: true, showInMenu: true };
};

export const handleComplexRestrictions = (
  userRestrictions: Array<string>,
  companyRestrictions: Array<string>,
  op?: Operator | null,
  min?: number | null,
) => {
  const operator = op || 'AND';
  if (
    isRestricted([...userRestrictions, ...companyRestrictions], operator, min)
  ) {
    return { result: false, redirectTo: 'pageNotFound', showInMenu: false };
  }
  return { result: true, showInMenu: true };
};
