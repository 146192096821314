import { restrictions } from 'state/features-list/constants';
import { isSharedAccount } from 'stores/user-store';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "wallet" */ './view/wallet-comp'),
    import(/* webpackChunkName: "wallet" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('wallet', reducer.walletReducer);
    return component;
  });
}

function shouldGrantAccess() {
  return Promise.resolve(!isSharedAccount());
}

const walletModule = {
  wallet: {
    name: 'wallet',
    url: '/wallet/:tab?',
    resolver,
    shouldGrantAccess,
    restrictions: {
      user: [restrictions['wallet']],
      operator: 'OR',
    },
    data: {
      title: 'components.main_menu.wallet',
      section: 'myAccount',
      mainMenu: 'wallet',
      includeInMenu: true,
      infoIcon: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default walletModule;
