import type { Toast } from './types';
import type { Dispatch } from 'globals/types';
import { getStore } from 'app/hermes-redux';

export function showToast(toast: Toast) {
  return function (dispatch: Dispatch) {
    const id = new Date().getTime();

    const { isLoggingOut } = getStore().getState().session;

    if (!isLoggingOut) {
      dispatch({ type: 'TOASTER/SHOW_TOAST', toast: { ...toast, id } });
    }
  };
}

export function resetToast() {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'TOASTER/RESET_TOAST' });
  };
}

export const hideToast = (toast: Toast) => {
  return (dispatch: Dispatch) =>
    dispatch({ type: 'TOASTER/HIDE_TOAST', toast });
};
