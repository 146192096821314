export default {
  ADMIN_CUSTOM_TIERING: 'admin_custom_tiering',
  AP_ACCOUNTING: 'ap_accounting',
  AP_DASHBOARD_BENEFITS: 'ap_dashboard_benefits',
  AP_DASHBOARD_EAP: 'ap_dashboard_eap',
  AP_DASHBOARD_SOCIAL: 'ap_dashboard_social',
  AP_FEED_POST: 'ap_feed_post',
  AP_USER_UPLOAD: 'ap_user_upload',
  BENEFIT: 'benefit',
  BENEFIT_CASHBACK_BASE_BOOK_A_TABLE_MULTIPLE_PROC:
    'benefit_cashback_base_book_a_table_multiple_proc',
  BENEFIT_CASHBACK_BASE_BOWNTY_MULTIPLE_PROC:
    'benefit_cashback_base_bownty_multiple_proc',
  BENEFIT_CASHBACK_BASE_INCENTIVE_NETWORKS_MULTIPLE_PROC:
    'benefit_cashback_base_incentive_networks_multiple_proc',
  BENEFIT_CASHBACK_BONUS_BOWNTY_MULTIPLE_PROC:
    'benefit_cashback_bonus_bownty_multiple_proc',
  BENEFIT_CASHBACK_BONUS_INCENTIVE_NETWORKS_MULTIPLE_PROC:
    'benefit_cashback_bonus_incentive_networks_multiple_proc',
  BENEFIT_CINEMA: 'benefit_cinema',
  BENEFIT_CINEMA_PRICING_TIER: 'benefit_cinema_pricing_tier',
  BENEFIT_COLLEAGUE_OFFER: 'benefit_colleague_offer',
  BENEFIT_GLOBAL_GIFT_CARDS: 'benefit_global_gift_cards',
  BENEFIT_IN_STORE_OFFER: 'benefit_in_store_offer',
  BENEFIT_IN_STORE_OFFER_SPEND: 'benefit_in_store_offer_spend',
  BENEFIT_LOCAL_DEAL: 'benefit_local_deal',
  BENEFIT_NEWSLETTER: 'benefit_newsletter',
  BENEFIT_ONLINE_SHOP: 'benefit_online_shop',
  BENEFIT_ONLINE_SHOP_SPECIAL_TERMS: 'benefit_online_shop_special_terms',
  BENEFIT_RESTAURANT: 'benefit_restaurant',
  BENEFIT_RESTAURANT_BAT: 'benefit_restaurant_bat',
  BENEFIT_RESTAURANT_HILIFE: 'benefit_restaurant_hilife',
  BENEFIT_RESTAURANT_SPEND: 'benefit_restaurant_spend',
  CARE_COORDINATION_SPECIALIST_MESSAGING:
    'care_coordination_specialists_messaging',
  DIGITAL_PROGRAMS_SELF_SIGNUP: 'digital_programs_self_signup',
  EAP: 'eap',
  EAP_ASSISTANCE: 'eap_assistance',
  EAP_CERNER: 'eap_cerner',
  EAP_CHALLENGES: 'eap_challenges',
  EAP_CHAT: 'eap_chat',
  EAP_CHILDCARE: 'eap_childcare',
  EAP_CONTACT_PAGE_PHONE_NUMBERS: 'eap_contact_page_phone_numbers',
  EAP_COUNSELOR: 'eap_counselor',
  EAP_DEPENDANT_ACCOUNTS: 'eap_dependant_accounts',
  EAP_GENERAL_ENQUIRY: 'eap_general_enquiry',
  EAP_LACTCORP: 'eap_lactcorp',
  EAP_LEGAL_SERVICES: 'eap_legal_services',
  EAP_SELF_ASSESSMENT: 'eap_self_assessment',
  EAP_WELLNESS: 'eap_wellness',
  EAP_WELLNESS_CATEGORY_LIMIT: 'eap_wellness_category_limit',
  EAP_WELLNESS_CONTENT_CONSUMPTION_LIMIT:
    'eap_wellness_content_consumption_limit',
  EAP_WELLNESS_RE_ENGAGEMENT: 'eap_wellness_re_engagement',
  EMPLOYEE_ID: 'employee_id',
  ENHANCED_PERSONALISATION: 'enhanced_personalisation',
  ENTERPRISE_SPOT_REWARDS: 'enterprise_spot_rewards',
  FEED_ACTIONS_EXCLUDING_RECOGNITION: 'feed_actions_excluding_recognition',
  FEED_EAP_POST: 'feed_eap_post',
  FEED_ORIGINATOR_POST: 'feed_originator_post',
  FEED_PERK_POST: 'feed_perk_post',
  FIRST_CHAT: 'first_chat',
  GROUPING: 'grouping',
  MARKETING_NEWSLETTER: 'marketing_newsletter',
  MULTILINGUAL_EMAILS: 'multilingual_emails',
  MULTI_AUTHENTICATION_METHODS: 'multi_authentication_methods',
  PASSWORD_RESET: 'password_reset',
  SECONDARY_EMAIL_ADDRESS: 'secondary_email_address',
  SOCIAL: 'social',
  SOCIAL_COLLEAGUE_DIRECTORY: 'social_colleague_directory',
  SOCIAL_FEED: 'social_feed',
  SOCIAL_FEED_ANNIVERSARY: 'social_feed_anniversary',
  SOCIAL_FEED_BIRTHDAY_COMPANY: 'social_feed_birthday_company',
  SOCIAL_FEED_BIRTHDAY_USER: 'social_feed_birthday_user',
  SOCIAL_FEED_COMMENT: 'social_feed_comment',
  SOCIAL_FEED_MILESTONE_USER: 'social_feed_milestone_user',
  SOCIAL_FEED_MOST_RECOGNIZED: 'social_feed_most_recognized',
  SOCIAL_FEED_NEW_USER: 'social_feed_new_user',
  SOCIAL_FEED_POST: 'social_feed_post',
  SOCIAL_RECOGNITION: 'social_recognition',
  SOCIAL_RECOGNITION_LEADERBOARD: 'social_recognition_leaderboard',
  SPOT_REWARDING: 'spot_rewarding',
  WALLET: 'wallet',
  WALLET_WITHDRAW_BACS_FEE_AMOUNT: 'wallet_withdraw_bacs_fee_amount',
  WALLET_WITHDRAW_METHOD_BACS: 'wallet_withdraw_method_bacs',
  WALLET_WITHDRAW_METHOD_PAYPAL: 'wallet_withdraw_method_paypal',
  WALLET_WITHDRAW_PAYPAL_FEE_AMOUNT: 'wallet_withdraw_paypal_fee_amount',
  WELLBEING_REWARDS: 'wellbeing_rewards',
  WELLBEING_TIERING: 'wellbeing_tiering',
};
