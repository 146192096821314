import type { Dispatch } from 'globals/types';
import type { Price } from 'globals/perks.types';
import { getUser } from 'stores/user-store';
import type { GiftCard, GiftCardsOrder } from '../../../../gift-cards.types';
import { updateCheckout } from '../../../../../checkout-braintree/state/actions';

import { goTo } from 'router/navigation';

import PerksBlock from 'perks/components/perks-block/perks-block';
import BuyNow from '../buy-now/buy-now';
import { convertGiftCardsToItems } from '../../../state/helpers';

import ValueSelection from '../value-selection';

import { spotRewardsReferrer } from '../../../../shared/constants';
import { updateValue as updateGiftCardValue } from 'pages/gift-cards/details/state/actions';

import { getLocalisedCurrencyString } from '../../../../../../globals/helpers';

import styles from './buy-gift-card.module.scss';

type Props = {
  balance?: number;
  giftCard: GiftCard;
  dispatch: Dispatch;
  order: GiftCardsOrder;
  invalidValue: boolean;
  referrer: string;
};

export const handleValueChange = (value: Price, err: boolean, props: Props) => {
  props.dispatch(updateGiftCardValue(value, err));
};

export const handleBuyNow = (props: Props) => {
  props.dispatch(
    updateCheckout(
      convertGiftCardsToItems(props.order, props.giftCard, props.referrer),
    ),
  );
  goTo('checkout', { step: 'payment' });
};

const BuyGiftCard = (props: Props) => {
  const {
    balance = 0,
    giftCard: { value },
    order,
    invalidValue,
    referrer,
  } = props;
  const faceVal = order ? order.items[0]?.customizations.face_value : null;
  const faceValue = faceVal
    ? Object.assign({}, faceVal, { currency: getUser().wallet.currency })
    : { amount: 0, currency: getUser().wallet.currency };
  const isSpotRewards = referrer === spotRewardsReferrer;

  return (
    <PerksBlock
      aria-label={polyglot.t('giftcards.buy_this_giftcard')}
      title={polyglot.t('giftcards.buy_this_giftcard')}
    >
      {!isSpotRewards && (
        <div className={styles.discount}>
          {polyglot.t('giftcards.discount_percentage', {
            val: value.data.discount,
          })}
        </div>
      )}
      {isSpotRewards && (
        <div>
          <p className={styles.rewards}>
            {polyglot.t('rewards.giftcards.balance')}
          </p>
          <p className={styles.balance}>
            <span
              className={styles['balance__amount']}
            >{`${getLocalisedCurrencyString(balance)}`}</span>{' '}
            {polyglot.t('rewards.available_reward.available')}
          </p>
        </div>
      )}
      <ValueSelection
        value={value}
        onValueChange={(value, err) => handleValueChange(value, err, props)}
        selectedPrice={faceValue}
        availableBalance={balance}
        referrer={referrer}
      />
      <BuyNow
        price={faceValue}
        discount={value.data && value.data.discount}
        onBuyNow={() => handleBuyNow(props)}
        isDisabled={invalidValue}
        referrer={referrer}
        label={
          isSpotRewards
            ? polyglot.t('rewards.available_reward.buy_now')
            : polyglot.t('giftcards.buy_now')
        }
      />
    </PerksBlock>
  );
};

BuyGiftCard.displayName = 'BuyGiftCard';

export default BuyGiftCard;
