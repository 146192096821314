import { client } from 'graphql/client';
import { CarePlanDocument, CarePlanQuery } from 'graphql-types';

export const hasCarePlanAssigned = async () => {
  try {
    const { data } = await client.query<CarePlanQuery>({
      query: CarePlanDocument,
      fetchPolicy: 'cache-first',
    });

    if (data && data.carePlan && data.carePlan.carePlan) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};
