/**
 *  @module Spot Rewards Codes
 *  @overview Display the codes for rewards brought
 */

import type { GiftCardOrder } from 'pages/gift-cards/gift-cards.types';
import Order from 'pages/gift-cards/hub/view/components/gift-card-order';
import Spinner from 'wa-storybook/components/general/spinner/spinner';

import styles from './codes.module.scss';

type Props = {
  payload: {
    orders: Array<GiftCardOrder>;
  };
  fetchingOrders: boolean;
};

const SpotRewardsCodes = (props: Props) => {
  const {
    fetchingOrders,
    payload: { orders },
  } = props;
  return (
    <div className={styles.modal}>
      {fetchingOrders ? (
        <div className={styles.loading}>
          <Spinner top='calc(50% - 20px)' />
        </div>
      ) : orders && orders.length ? (
        orders.map((giftCardOrder: GiftCardOrder, index: number) => (
          <Order
            giftCardOrder={giftCardOrder}
            isLast={index === orders.length - 1}
            key={giftCardOrder.short_order_id}
          />
        ))
      ) : (
        <div className={styles['not-found']}>
          {polyglot.t('giftcards.no_gift_card_code')}
        </div>
      )}
    </div>
  );
};

SpotRewardsCodes.displayName = 'SpotRewardsCodes';

export default SpotRewardsCodes;
