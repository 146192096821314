export const operators = Object.freeze({
  or: 'OR',
  and: 'AND',
});

export const restrictions = Object.freeze({
  benefit: 'benefit',
  benefit_cinema: 'benefit_cinema',
  benefit_discount_store: 'benefit_discount_store',
  benefit_online_shop: 'benefit_online_shop',
  benefit_restaurant: 'benefit_restaurant',
  benefit_in_store_offer: 'benefit_in_store_offer',
  benefit_colleague_offer: 'benefit_colleague_offer',
  benefit_gift_card: 'benefit_gift_card',
  benefit_global_gift_cards: 'benefit_global_gift_cards',
  benefit_newsletter: 'benefit_newsletter',
  care_coordination_specialists_messaging:
    'care_coordination_specialists_messaging',
  care_plans: 'care_plans',
  counsellor_access: 'counsellor_access',
  counsellor_appointments: 'counsellor_appointments',
  counsellor_choice: 'counsellor_choice_consent',
  counsellor_messaging: 'counsellor_messaging',
  home_page: 'home_page',
  eap: 'eap',
  eap_challenges: 'eap_challenges',
  eap_self_assessment: 'eap_self_assessment',
  eap_assistance: 'eap_assistance',
  eap_dependant_accounts: 'eap_dependant_accounts',
  eap_chat: 'eap_chat',
  eap_counselor: 'eap_counselor',
  eap_health_coaching: 'eap_health_coaching',
  eap_hwr_toolkits: 'eap_hwr_toolkits',
  eap_legal_services: 'eap_legal_services',
  eap_lactcorp: 'eap_lactcorp',
  eap_wellness: 'eap_wellness',
  eap_cerner: 'eap_cerner',
  eap_general_enquiry: 'eap_general_enquiry',
  eap_wellness_category_limit: 'eap_wellness_category_limit',
  enterprise_spot_rewards: 'enterprise_spot_rewards',
  first_chat: 'first_chat',
  message_a_counsellor: 'message_a_counsellor',
  spot_rewarding: 'spot_rewarding',
  social: 'social',
  social_feed: 'social_feed',
  social_feed_post: 'social_feed_post',
  social_colleague_directory: 'social_colleague_directory',
  social_recognition_leaderboard: 'social_recognition_leaderboard',
  submit_confidential_request_for_support:
    'submit_confidential_request_for_support',
  total_mental_health: 'total_mental_health',
  grouping: 'grouping',
  feed_actions_excluding_recognition: 'feed_actions_excluding_recognition',
  password_reset: 'password_reset',
  wellbeing_tiering: 'wellbeing_tiering',
  digital_programs_self_signup: 'digital_programs_self_signup',
  pension_and_benefits: 'pension_and_benefits',
  online_scheduling: 'online_scheduling',
  onshore_chat_support: 'onshore_chat_support',
  wallet: 'wallet',
  work_life_services: 'work_life_services',
  virtual_care_integration: 'virtual_care_integration',
  wellbeing_integration: 'th_wellbeing_integration',
});

export const featureActions = Object.freeze({
  create_personal_account: 'limitedAccount',
});
