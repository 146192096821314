import type { Model, Payload } from './types';
import type { Dispatch, ErrorAPI, ThunkedAction } from 'globals/types';

import * as constants from './constants';
import * as api from './api';
import { updateUser, logout } from 'state/session/actions';
import { showToast } from 'toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';

import * as CompanyStore from 'stores/company-store';

import { goTo } from 'router/navigation';

import { openModal, closeModal } from 'app/state/modal/actions';
import PasswordChangeModal from 'modal-templates/templates/password-change/password-change';
import { modalExitAction } from '../../../state/modal/constants';

export function updatePassword(action): ThunkedAction {
  return function (
    dispatch: Dispatch,
    getState: () => {
      settings: Model;
    },
  ) {
    const { settings } = getState();
    const isLogout = action === modalExitAction.logOut;
    const payload = {
      old_password: settings.oldPassword,
      new_password: settings.newPassword,
      logout_devices: isLogout,
    } as const;

    dispatch(closeModal());
    return api
      .changePasword(payload)
      .then(() => {
        if (isLogout) {
          dispatch(logout(true));
        } else {
          goTo('settings');
        }

        dispatch(
          showToast({
            type: ToastType.SUCCESS,
            message: polyglot.t('settings.password.success_change'),
          }),
        );
      })
      .catch((response: any) => {
        const { error = { code: 0 } } = response;

        if (error.code === -8) {
          dispatch(updateField('oldPassword', settings.oldPassword, true));
        }
      });
  };
}

export function openChangePasswordModal(): ThunkedAction {
  return function (dispatch: Dispatch) {
    const payload = {
      onCloseHandler(action) {
        if (action) {
          dispatch(updatePassword(action));
        }
      },
    } as const;

    return dispatch(openModal(PasswordChangeModal, payload));
  };
}

export function getPasswordRequirements(): ThunkedAction {
  return function (dispatch: Dispatch) {
    return api
      .getPasswordRequirements(
        CompanyStore.getCompany()['company_id'] ||
          CompanyStore.getCompany()['id'] ||
          '',
      )
      .then((response: any) => {
        dispatch({
          type: 'SETTINGS/GET_PASSWORD_REQUIREMENTS_SUCCESS',
          response,
        });
      })
      .catch((error: string) => {
        dispatch({ type: 'SETTINGS/GET_PASSWORD_REQUIREMENTS_FAILURE', error });
      });
  };
}

export function updateField(
  key: string,
  value: string,
  error?: boolean,
): ThunkedAction {
  return function (dispatch: Dispatch) {
    return dispatch({
      type: 'SETTINGS/UPDATE_PASSWORD_FIELD',
      key,
      value,
      error: error ?? false,
    });
  };
}

export function updateEmailNotification(key: string, value: boolean) {
  return function (dispatch: Dispatch) {
    return dispatch(updateUser({ notification_email: { [key]: value } }))
      .then(() =>
        dispatch(
          showToast({
            type: ToastType.SUCCESS,
            message: polyglot.t('settings.success_ms'),
          }),
        ),
      )
      .catch(() =>
        dispatch(
          showToast({
            type: ToastType.ERROR,
            message: polyglot.t('settings.error_ms'),
          }),
        ),
      );
  };
}

export function reset(): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'SETTINGS/RESET' });
  };
}

export const getPaymentAndWithdrawalDetails =
  (userId: string) => async (dispatch: Dispatch) => {
    dispatch({ type: constants.GET_PAYMENT_DETAILS_START });

    try {
      const result = await Promise.all([
        api.getWithdrawalDetails(),
        api.getPaymentDetails(userId),
      ]);

      const wallet = result[0] && result[0].body;
      const cards = result[1] && result[1].body;

      const payload: Payload = {
        paymentExists: cards && cards.length > 0,
        withdrawalMethods: [],
        paymentCardNumber:
          (cards && cards[0] && cards[0].partial_number) || '0000',
      };

      if (wallet && wallet.paypal_email !== '') {
        (payload.withdrawalMethods || []).push('paypal');
      }

      if (wallet && wallet.bacs_account_name !== '') {
        (payload.withdrawalMethods || []).push('bacs');
      }

      payload.withdrawalAllowed = (payload.withdrawalMethods || []).length > 0;

      return dispatch({
        type: constants.GET_PAYMENT_DETAILS_SUCCESS,
        payload,
      });
    } catch {
      return dispatch({
        type: constants.GET_PAYMENT_DETAILS_REJECTED,
        payload: {
          paymentAndWithdrawalFetched: true,
        },
      });
    }
  };

/*
 * Remove payment details from a user account
 */
export const removePaymentDetails =
  (userId: string) => (dispatch: Dispatch) => {
    dispatch({ type: constants.REMOVE_PAYMENT });
    return api
      .removePaymentDetails(userId)
      .then(() => dispatch({ type: constants.REMOVE_PAYMENT_SUCCESS }))
      .catch((error: ErrorAPI) =>
        dispatch({ type: constants.REMOVE_PAYMENT_REJECTED, payload: error }),
      );
  };
/*
 * Remove payment details from a user account
 */
export const removeWithdrawalDetails =
  (method: any) =>
  (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: constants.REMOVE_WITHDRAWAL, payload: method });
    return api
      .removeWithdrawalDetails(method)
      .then(() => dispatch({ type: constants.REMOVE_WITHDRAWAL_SUCCESS }))
      .catch((error: ErrorAPI) =>
        dispatch({
          type: constants.REMOVE_WITHDRAWAL_REJECTED,
          payload: error,
        }),
      );
  };
