import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { HUB_NAME } from '../hub/constants';

export const MODULE_NAME = 'challengesJoinHabit';

const habitSelectModule = {
  name: MODULE_NAME,
  url: '/challenges/habit/:habitId/select-habits',
  resolver: () =>
    import(/* webpackChunkName: "challenges-habit-select" */ './habit-select'),
  restrictions: {
    user: [restrictions['eap_challenges']],
  },
  checkConsent: () => ({
    consentType: 'challenges',
    customBackLink: HUB_NAME,
  }),
  data: {
    mainMenu: 'habitChallenge',
    section: 'wellBeing',
    includeInMenu: false,
    authorizedRoles: [USER_ROLES.employee],
  },
} as const;

export default habitSelectModule;
