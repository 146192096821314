import userRoles from 'constants/user-roles.constant';
import {
  HUB_RESTRICTIONS,
  wellBeingHub,
  RECOMMENDATION_RESTRICTIONS,
  wellBeingRecommendations,
} from './well-being-hub.constants';
import { injectAsyncReducer } from '../../hermes-redux';

function resolver() {
  return Promise.all([
    import(
      /* webpackChunkName: "well-being-hub" */ './well-being-hub.container'
    ),
    import(
      /* webpackChunkName: "thi-modal-reducer" */ 'react-components/thi-result/state/reducer'
    ),
  ]).then(([component, thiResult]) => {
    injectAsyncReducer('thiResult', thiResult.default);
    return component;
  });
}

const wellBeingHubModule = {
  hub: {
    name: wellBeingHub,
    url: '/wellbeing',
    resolver,
    restrictions: HUB_RESTRICTIONS,
    data: {
      title: 'components.main_menu.well_being_hub_home',
      order: 0,
      mainMenu: wellBeingHub,
      section: 'wellBeing',
      includeInMenu: true,
      authorizedRoles: [userRoles.employee],
      shortcutKeyCode: 53,
    },
  },
  recommendations: {
    name: wellBeingRecommendations,
    url: '/wellbeing/recommendations',
    resolver,
    restrictions: RECOMMENDATION_RESTRICTIONS,
    data: {
      title: 'components.main_menu.well_being_hub_home',
      mainMenu: wellBeingRecommendations,
      section: 'wellBeing',
      includeInMenu: false,
      authorizedRoles: [userRoles.employee],
    },
  },
};

export default wellBeingHubModule;
