import { restrictions } from 'state/features-list/constants';
import type { Operator, List } from 'state/features-list/types';

export const wellBeingHub = 'wellBeingHub';
export const wellBeingRecommendations = 'wellBeingRecommendations';

export const MAXIMUM_TIER = 4;

type HubRestrictions = {
  user: List;
  operator: Operator;
  minimum: number;
};

export const HUB_RESTRICTIONS: HubRestrictions = {
  user: [
    restrictions['eap_challenges'],
    restrictions['eap_self_assessment'],
    restrictions['wellbeing_tiering'],
    restrictions['digital_programs_self_signup'],
  ],
  operator: 'OR',
  minimum: 2,
};

export const RECOMMENDATION_RESTRICTIONS = {
  user: [restrictions['eap_self_assessment'], restrictions['eap_assistance']],
  operator: 'AND',
} as const;
