import { injectAsyncReducer } from '../../hermes-redux';
import USER_ROLES from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';
import { MoodTrackingRoutes } from './mood-tracking.constants';

const createResolverWithMoodTrackingReducer = pageImport =>
  Promise.all([
    pageImport,
    import(/* webpackChunkName: "mood-tracking-reducer" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('moodTracking', reducer.default);
    return component;
  });

const createResolver = pageImport =>
  Promise.all([pageImport]).then(([component]) => {
    return component;
  });

const moodTrackingModule = {
  moodTrackingHistory: {
    name: MoodTrackingRoutes.HISTORY,
    url: '/mood-tracking/history',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "mood-tracking-history" */ '../mood-tracking/container/mood-history/mood-history'
        ),
      ),
    restrictions: {
      user: [
        restrictions['care_plans'],
        restrictions['counsellor_appointments'],
        restrictions['care_coordination_specialists_messaging'],
      ],
      operator: 'OR',
    },
    data: {
      title: 'components.main_menu.mood_history',
      section: 'myCare',
      mainMenu: 'myCare',
      includeInMenu: false,
      hideFooter: true,
      order: 0,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  moodTrackingNewEntry: {
    name: MoodTrackingRoutes.NEW_ENTRY,
    url: '/mood-tracking/new-entry',
    resolver: () =>
      createResolverWithMoodTrackingReducer(
        import(
          /* webpackChunkName: "mood-tracking-new-entry" */ '../mood-tracking/container/new-entry/new-entry'
        ),
      ),
    restrictions: {
      user: [
        restrictions['care_plans'],
        restrictions['counsellor_appointments'],
        restrictions['care_coordination_specialists_messaging'],
      ],
      operator: 'OR',
    },
    data: {
      title: 'components.main_menu.mood_new_entry',
      section: 'myCare',
      mainMenu: 'myCare',
      includeInMenu: false,
      hideFooter: true,
      order: 0,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default moodTrackingModule;
