import Language from 'language';
import { deepReplaceValues, handle401, serialize } from './helpers';
import { getRegionalApiEndpoints } from './regional-api';
import { format } from 'date-fns';
import { getAuthenticationHeader } from './fetch.helpers';

export default async function (
  url,
  params,
  responseFilterCb?,
  includeResponseHeaders?,
) {
  let requestUrl = getRegionalApiEndpoints().wellbeing_root + url;

  const { urlParameters, useLwLanguageHeader = true } = params;

  params.headers = {
    ...params.headers,
    'Client-Date': format(new Date(), `yyyy-MM-dd'T'H:mm:ssxxx`),
    ...(await getAuthenticationHeader()),
  };

  if (Language && useLwLanguageHeader) {
    params.headers['Lw-Language'] = Language.getLocale();
  }

  if (!params.doNotStringify) {
    params.headers['Content-Type'] = 'application/json';
  }

  if (params.body && !params.doNotStringify) {
    params.body = JSON.stringify(params.body);
  }

  if (urlParameters) {
    const parametersString = `${
      requestUrl.indexOf('?') > -1 ? '&' : '?'
    }${serialize(urlParameters)}`;
    requestUrl += parametersString;
  }

  let response;

  try {
    response = await fetch(requestUrl, params);
    await handleError(response);

    const responseJSON = await response.json();

    if (includeResponseHeaders) {
      const headers = {};
      response.headers.forEach((value, key) => {
        headers[key] = value;
      });

      responseJSON.headers = headers;
    }

    return filterResponse(responseJSON, responseFilterCb);
  } catch (e) {
    return Promise.reject(response);
  }
}

function handleError(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 401) {
    return handle401(response);
  }

  return Promise.reject(response);
}

function filterResponse(response, cb) {
  if (!cb) {
    return Promise.resolve(response);
  }
  return Promise.resolve(cb(response));
}

// --------------------
//   Filter functions
// --------------------

/*
 * It will recursively look for a keys that match the ones
 * provided array and it will convert them to lowercase
 *
 * usage: fetch(url, params, valuesToLowerCaseByKeys(['someKey', 'someOther']))
 */

export const valuesToLowerCaseByKeys = keysArray => response =>
  deepReplaceValues(response, keysArray, val => val.toLowerCase());
