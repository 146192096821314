// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** function to get dimensions
  usage example to get \`tablet\` value from \`\$page-max-widths\`
  .class {
    max-width: dim(\$page-max-widths, tablet);
  }

 */
.cinema-codes-module__wrapper___G7jDU {
  margin-bottom: 20px;
  width: 100%;
}
.cinema-codes-module__wrapper___G7jDU:nth-last-child(1) {
  margin-bottom: 0;
}

.cinema-codes-module__cinema-image___hCs4c {
  border: 1px solid var(--neutral30, #e3e6e8);
  border-radius: 24px;
  display: inline-block;
  height: 100px;
  margin-right: 30px;
  vertical-align: top;
  width: 100px;
}

.cinema-codes-module__cinema-description___iGLTH {
  display: inline-block;
  text-align: left;
  width: calc(100% - 130px);
}

.cinema-codes-module__cinema-name___SKruu {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.cinema-codes-module__id___hhaQ8 {
  text-transform: uppercase;
  word-break: break-all;
}

.cinema-codes-module__order-id___kfyj1 {
  color: var(--neutral100, #2c2e30);
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./node_modules/wa-storybook/styles/tools/functions/_widths.scss","webpack://./client/app/react-components/modal-templates/templates/codes-modal/components/codes/cinema-codes.module.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;ACEA;EACE,mBAAA;EACA,WAAA;AAMF;AALE;EACE,gBAAA;AAOJ;;AAHA;EACE,2CAAA;EACA,mBAAA;EACA,qBAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AAMF;;AAHA;EACE,qBAAA;EACA,gBAAA;EACA,yBAAA;AAMF;;AAHA;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;AAMF;;AAHA;EACE,yBAAA;EACA,qBAAA;AAMF;;AAHA;EACE,iCAAA;EACA,eAAA;AAMF","sourcesContent":["/** function to get dimensions\n  usage example to get `tablet` value from `$page-max-widths`\n  .class {\n    max-width: dim($page-max-widths, tablet);\n  }\n\n */\n\n@function dim($prop, $key) {\n  @return map-get($prop, $key);\n}\n","@import '~wa-storybook/styles/utils';\n\n.wrapper {\n  margin-bottom: ($spacing);\n  width: 100%;\n  &:nth-last-child(1) {\n    margin-bottom: 0;\n  }\n}\n\n.cinema-image {\n  border: 1px solid color(neutral30);\n  border-radius: ($border-radius * 1.5);\n  display: inline-block;\n  height: 100px;\n  margin-right: ($spacing * 1.5);\n  vertical-align: top;\n  width: 100px;\n}\n\n.cinema-description {\n  display: inline-block;\n  text-align: left;\n  width: calc(100% - 130px);\n}\n\n.cinema-name {\n  font-size: rem(24);\n  font-weight: 600;\n  margin-bottom: ($spacing * 0.25);\n}\n\n.id {\n  text-transform: uppercase;\n  word-break: break-all;\n}\n\n.order-id {\n  color: color(neutral100);\n  font-size: rem(16);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `cinema-codes-module__wrapper___G7jDU`,
	"cinema-image": `cinema-codes-module__cinema-image___hCs4c`,
	"cinema-description": `cinema-codes-module__cinema-description___iGLTH`,
	"cinema-name": `cinema-codes-module__cinema-name___SKruu`,
	"id": `cinema-codes-module__id___hhaQ8`,
	"order-id": `cinema-codes-module__order-id___kfyj1`
};
export default ___CSS_LOADER_EXPORT___;
