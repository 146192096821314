import reduce from 'lodash/reduce';
import set from 'lodash/set';
import last from 'lodash/last';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { Store } from 'redux';
import type { AppState } from 'globals/types';

type Callback<T extends any = any> = (arg1: T) => void;

export const createSubscription = <CB extends unknown>(
  paths: Array<string>,
  callback: Callback<CB>,
  flatten = true,
) => {
  let currentValue;
  const handleSubscription = (store: Store<AppState>) => () => {
    const previousValue = currentValue;
    currentValue = flatten
      ? reduce<string, CB>(
          paths,
          (obj, path) =>
            // @ts-expect-error - TS2769 - No overload matches this call. | TS2769 - No overload matches this call.
            set(obj, last(path.split('.')), get(store.getState(), path)),
          {} as CB,
        )
      : pick(store.getState(), paths);

    if (!isEqual(currentValue, previousValue)) {
      callback(currentValue);
    }
  };

  return (store: Store<AppState>) => {
    return store.subscribe(handleSubscription(store));
  };
};
