import fetch from 'globals/wa-fetch';

export const fetchSpotRewards = (userId: string) =>
  fetch(`/funds?user_id=${userId}`, {
    method: 'get',
    version: 1.3,
    token: true,
  });

export const fetchFundTransactions = (fundId: string) =>
  fetch(`/funds/${fundId}/transactions?type=incoming`, {
    method: 'get',
    version: 1.3,
    token: true,
  });

export const fetchYourRewards = (userId: string) =>
  fetch(`/views/users/${userId}/spot-reward-orders`, {
    method: 'get',
    version: 1.3,
    token: true,
  });
