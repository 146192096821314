import type { Feature } from '../state/features-list/types';

import {
  getUser,
  getEffectiveSettingsUrl,
  isSharedAccount,
} from 'stores/user-store';
import { getCompany } from 'stores/company-store';
import {
  isFeatureEnabled as isMyFeatureEnabled,
  findFeature,
} from 'state/features-list/helpers';
import FK from '../constants/feature-keys';
import { coreCountryCodes } from '../languages.constant';

// TODO: for now the features-list store is linked to redux state, should be removed soon

export const getFeaturesList = () =>
  getUser().features_list || getCompany().features_list || [];

export const isFeatureEnabled = (feature?: string | null) =>
  isMyFeatureEnabled(getFeaturesList(), feature || '');

export const getFeature = (feature: string): Feature | null | undefined =>
  findFeature(getFeaturesList(), feature);

export const isShopOnlineDoubleCashback = () => {
  const shopOnlineFeatureKey = findFeature(
    getFeaturesList(),
    'benefit_cashback_bonus_incentive_networks_multiple_proc',
  );
  return !shopOnlineFeatureKey
    ? false
    : Number(shopOnlineFeatureKey.value) !== 0;
};

export const hasDoubleCashback = () => {
  const restaurantFeatureKey = findFeature(
    getFeaturesList(),
    'benefit_cashback_base_book_a_table_multiple_proc',
  );
  const restaurantsIsDouble = !restaurantFeatureKey
    ? false
    : Number(restaurantFeatureKey.value) > 1;

  const dailyDealsFeatureKey = findFeature(
    getFeaturesList(),
    'benefit_cashback_bonus_bownty_multiple_proc',
  );
  const dailyDealsIsDouble = !dailyDealsFeatureKey
    ? false
    : Number(dailyDealsFeatureKey.value) !== 0;

  return (
    isShopOnlineDoubleCashback() || restaurantsIsDouble || dailyDealsIsDouble
  );
};

export const isSnackableOn = () => {
  if (
    (isSharedAccount() && !isFeatureEnabled(FK.EAP_WELLNESS)) ||
    !isCoreCountry()
  ) {
    return false;
  }

  return (
    isFeatureEnabled(FK.EAP_WELLNESS_CATEGORY_LIMIT) &&
    Number(getFeature(FK.EAP_WELLNESS_CATEGORY_LIMIT)?.value) > 0
  );
};

export const isMessageACounsellorOn = () =>
  isMyFeatureEnabled(getFeaturesList(), 'message_a_counsellor') &&
  !!getEffectiveSettingsUrl('message_a_counsellor');

export const isCoreCountry = () => {
  const countryCode = getUser()['country_code'];

  return coreCountryCodes.includes(countryCode);
};
