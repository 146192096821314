import { client } from 'graphql/client';
import { AppLogger } from '@workivate/tho-web-shared';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { isManulifeWebView } from 'globals/manulife';
import {
  MENU_STATES_MAPPING,
  getInitialModel,
  getStaticSections,
  THVCMenuId,
  THWBMenuId,
} from './constants';
import {
  isSharedAccount,
  getUser,
  isAdmin,
  isVisibleToAdmin,
} from 'stores/user-store';
import type { Feature } from '../features-list/types';
import type { Menu, SubSection } from './types';
import {
  handleRestrictions,
  handleComplexRestrictions,
} from 'globals/restrictions-handler';
import { getCurrentState, getUrlParameter } from 'router/navigation';
import { getStore } from 'app/hermes-redux';
import { openZendeskLinkWithToken } from '../global/actions';
import { openAdminPanel } from 'globals/helpers';
import type { Module } from '../../router/types';
import { isFeatureEnabled } from 'stores/features-list-store';
import {
  PensionBenefitsTitleAndNotificationCountDocument,
  PensionBenefitsTitleAndNotificationCountQuery,
} from 'graphql-types';
import {
  getFeatureValue,
  checkPensionBenefitDataAvailability,
} from 'pages/pension-benefits/helpers/utility';
import { restrictions } from 'state/features-list/constants';
import { ModalTypesMap } from 'react-components/handshake/state/constants';
import { EncryptUserHelpers } from '@workivate/tho-web-shared';

const getPensionBenefitItem = async (): Promise<
  Partial<SubSection> | undefined
> => {
  if (!isFeatureEnabled('pension_and_benefits')) {
    return;
  }

  try {
    const { data } =
      await client.query<PensionBenefitsTitleAndNotificationCountQuery>({
        query: PensionBenefitsTitleAndNotificationCountDocument,
        fetchPolicy: 'network-only',
      });

    const featureValue = getFeatureValue();

    if (!checkPensionBenefitDataAvailability(Number(featureValue), data)) {
      return;
    }

    const title = data?.pensionAndBenefitsSummary?.title;
    const count = data?.pensionAndBenefitsSummary?.notifications?.count;

    return {
      id: 'pension-and-benefits',
      url: '/pension-and-benefits',
      name: 'pension-and-benefits',
      state: undefined,
      group: 0,
      data: {
        title: title || '',
        mainMenu: 'pension_and_benefits',
        notification: count,
      },
    };
  } catch (error) {
    AppLogger.error(error);
  }
};

const getOverriddenHelpMenuItemFields = () => {
  const originatorAppSupportFKValue = getStore()
    .getState()
    .session.user?.features_list.find(
      (feature: Feature) => feature.name === 'originator_app_support',
    )?.value;

  if (originatorAppSupportFKValue) {
    return {
      data: { title: 'components.main_menu.app_support', shortcutKeyCode: 57 },
      name: 'app-support',
      type: 'menuButtonContextHandled',
      icon: false,
      onClick: undefined,
      id: 'app-support',
    };
  }

  return {};
};

export const getDynamicSections = () => ({
  help: {
    data: { title: 'components.main_menu.help', shortcutKeyCode: 57 },
    name: 'help',
    type: 'dynamicLink',
    icon: true,
    onClick: () => getStore().dispatch(openZendeskLinkWithToken('help_centre')),
    state: null,
    id: 'help',
    group: 0,
    ...getOverriddenHelpMenuItemFields(),
  },
  admin: {
    data: { title: 'components.main_menu.admin' },
    type: 'dynamicLink',
    icon: true,
    onClick: () => openAdminPanel(),
    state: null,
    id: 'admin',
    group: 0,
  },
});

const getIntegrationSections = () => {
  const isTHVCEnabled = isFeatureEnabled(restrictions.virtual_care_integration);
  const isTHWBEnabled = isFeatureEnabled(restrictions.wellbeing_integration);

  const integrationSections = [
    {
      type: 'integration',
      name: 'thvc-integration',
      data: {
        title: 'handshake_integration.virtual_care.title',
        subtitle: 'handshake_integration.virtual_care.subtitle',
      },
      id: THVCMenuId,
      icon: false,
      group: 3,
    },
    {
      type: 'integration',
      name: 'thwb-integration',
      data: {
        title: 'handshake_integration.wellbeing.title',
        subtitle: 'handshake_integration.wellbeing.subtitle',
      },
      id: THWBMenuId,
      icon: false,
      group: 3,
    },
  ];

  const filteredIntegrationSections = integrationSections.filter(
    section =>
      (section.id === ModalTypesMap.VirtualCare && isTHVCEnabled) ||
      (section.id === ModalTypesMap.Wellbeing && isTHWBEnabled),
  );

  return filteredIntegrationSections;
};

export const buildAccountSection = async (
  isAdmin: boolean,
  isSharedAccount: boolean,
  accountSections: Array<SubSection>,
): Promise<Array<SubSection>> => {
  const staticSections = getStaticSections();
  const dynamicSections = getDynamicSections();
  const integrationSections = getIntegrationSections();
  const pensionBenefitItem = await getPensionBenefitItem();
  const isManulifeView = isManulifeWebView();

  if (isSharedAccount) {
    return Array.prototype.concat
      .apply(
        [dynamicSections.help],
        accountSections.filter((item: SubSection) => item.name !== 'profile'),
      )
      .concat(staticSections.keyboardShortcuts)
      .concat(staticSections.accessibilityTips)
      .concat(isManulifeView ? [] : staticSections.logout);
  }

  let newAccountSection: Array<any> = [];

  if (isAdmin && isVisibleToAdmin()) {
    newAccountSection.push({
      ...dynamicSections.admin,
      group: 1,
    });
  }

  newAccountSection = newAccountSection
    .concat([
      {
        ...dynamicSections.help,
        group: 1,
      },
      {
        ...staticSections.language,
        group: 2,
      },
      ...accountSections.map((item: SubSection) =>
        item.name === 'settings'
          ? Object.assign({} as SubSection, item, { group: 2 })
          : item,
      ),
      {
        ...staticSections.keyboardShortcuts,
        group: 2,
      },
      {
        ...staticSections.accessibilityTips,
        group: 2,
      },
    ])
    .concat(
      isManulifeView
        ? []
        : {
            ...staticSections.logout,
            group: 2,
          },
    )
    .concat([...integrationSections]);

  if (pensionBenefitItem) {
    newAccountSection.splice(1, 0, pensionBenefitItem);
  }

  return newAccountSection;
};

const getDataFromState = (data: any) =>
  pick(data, ['title', 'mainMenu', 'order']);

const getObjectFromState = (state: any) =>
  Object.assign(
    {},
    pick(state, ['group', 'url', 'name', 'stateParams']),
    Object.assign({}, state, { data: getDataFromState(state.data) }),
  );

const sortSections = (menu: Menu) => {
  Object.keys(menu).forEach(sectionKey => {
    menu[sectionKey].sections.sort(
      (a, b) => (a.data.order as number) - (b.data.order as number),
    );
  });
};

// Generate menu from state and features
export const createFromList = async (
  routesModules: Module[],
  featureList: Array<Feature>,
): Promise<Menu> => {
  const model = getInitialModel();

  const modulesWithData: Array<Module | undefined> = routesModules.filter(
    (state?: Module) => state?.data,
  );
  for (let i = 0; i < modulesWithData.length; i++) {
    const state = modulesWithData[i];
    const data = state?.data;
    const restrictions = state?.restrictions ? state.restrictions : {};
    const section = data?.section;

    if (data?.includeInMenu) {
      // Check the restrictions

      if (isEmpty(restrictions.user) || featureList.length === 0) {
        if (state?.shouldGrantAccess) {
          const shouldGrant = await state.shouldGrantAccess();
          if (shouldGrant && section) {
            model[section]['sections'].push(
              Object.assign({}, getObjectFromState(state), { group: 0 }),
            );
          }
        } else if (section) {
          model[section]['sections'].push(
            Object.assign({}, getObjectFromState(state), { group: 0 }),
          );
        }
      } else {
        const showInMenu =
          restrictions.company && restrictions.user
            ? handleComplexRestrictions(
                restrictions.user,
                restrictions.company,
                restrictions.operator,
                restrictions.minimum,
              ).showInMenu
            : handleRestrictions(
                restrictions.user,
                restrictions.operator,
                restrictions.minimum,
              ).showInMenu;
        if (showInMenu && section) {
          model[section]['sections'].push(
            Object.assign({}, getObjectFromState(state), { group: 0 }),
          );
        }
      }
    }
  }

  model.myAccount.sections = await buildAccountSection(
    isAdmin(),
    isSharedAccount(),
    model.myAccount.sections,
  );

  sortSections(model);

  return model;
};

// Return state category
export const getCategory = (menuName: string) =>
  Object.keys(MENU_STATES_MAPPING).find((key: string) =>
    MENU_STATES_MAPPING[key].includes(menuName),
  );

// Is category active
export const isActive = (menuName?: string) => {
  if (!menuName) return false;

  const state = getCurrentState();
  const userId = getUrlParameter('userId');
  // in ui-router, the dot Notation means a nested state
  // https://github.com/angular-ui/ui-router/wiki/Nested-States-&-Nested-Views
  const parentStateName = state?.name?.split('.')[0];

  const isMenuActive =
    MENU_STATES_MAPPING[menuName] &&
    MENU_STATES_MAPPING[menuName].indexOf(parentStateName) !== -1 &&
    (parentStateName === 'profile'
      ? !userId ||
        userId === EncryptUserHelpers.encryptUserId(getUser()['user_id'])
      : true);

  return isMenuActive;
};
