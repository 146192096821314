import type {
  // SignupMainAction,
  Model,
} from './types';
import * as Storage from 'globals/storage';

export const EVENT = {
  RESET: 'SIGNUP_MAIN/RESET',
  UPDATE_DATA: 'SIGNUP_MAIN/UPDATE_DATA',
  UPDATE_PASSWORD_REQUIREMENTS: 'SIGNUP_MAIN/UPDATE_PASSWORD_REQUIREMENTS',
} as const;

export const initialState: Model = {
  initData: {},
  submissionModel: {},
  passwordRequirements: null,
  signUpResponse: Storage.get(Storage.getKeys().signupResponse) ?? null,
};

function signUpMainReducer(state = initialState, action): Model {
  switch (action.type) {
    case EVENT.RESET:
      return initialState;

    case EVENT.UPDATE_DATA:
      const { key, data } = action;

      if (Array.isArray(data)) {
        return { ...state, [key]: [...data] };
      }

      return { ...state, [key]: { ...state[key], ...data } };

    case EVENT.UPDATE_PASSWORD_REQUIREMENTS:
      const { requirements } = action;
      return { ...state, passwordRequirements: requirements };

    default:
      return state;
  }
}

export default signUpMainReducer;
