import { combineReducers } from 'redux';
import notifications from '../notifications/state/reducer';
import type { Model as MenuModel } from 'state/menu/types';
import { menuReducer } from 'state/menu/reducer';
import { NotificationsModel } from '../notifications/state/types';

export type HeaderModel = {
  notifications: NotificationsModel;
  menu: MenuModel;
  sectionsMenu: {
    isOnScreen: boolean;
  };
};

export default combineReducers({
  notifications,
  menu: menuReducer,
});
