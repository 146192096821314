/**
 *  @module TotalBanner
 *  @overview Shows the total rewards an employee has had since they joined
 */

import { boldify, getLocalisedCurrencyString } from 'globals/helpers';
import styles from './total-banner.module.scss';

type Props = {
  amount: number;
};

const TotalBanner = ({ amount }: Props) => {
  const formattedAmount = getLocalisedCurrencyString(amount);
  return (
    <div className={styles.banner}>
      <div className={styles.text}>
        {boldify(
          polyglot.t('rewards.available_reward.total_awarded', {
            amount: formattedAmount,
          }),
          formattedAmount,
        )}
      </div>
    </div>
  );
};

TotalBanner.displayName = 'TotalBanner';

export default TotalBanner;
