export const tieringHub = 'tiering';

export const routes = {
  TIERING_ACTIVITY: {
    name: 'tieringActivity',
    path: '/tiering/activity',
  },
  TIERING_HUB: {
    name: tieringHub,
    path: '/tiering',
  },
  TIERING_HUB_ACTIVITY_MODAL: {
    name: 'tieringHubActivityModal',
    path: '/tiering/custom-activity/:activityId',
  },
  TIER_REWARDS: {
    name: 'tierRewards',
    path: '/offers/tiering-rewards/:offerId?',
  },
} as const;

export const WELLBEING_NOT_AVAILABLE =
  'The user is not in a tier with access to this reward';

export const tierNames = ['bronze', 'silver', 'gold', 'platinum'] as const;

export const REGRESSION_TIER_NAMES = {
  SILVER: 'silver',
  GOLD: 'gold',
  PLAT: 'platinum',
} as const;
