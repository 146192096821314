import type { PriceRangeData } from '../../../gift-cards.types';
import { getFullPrice } from 'globals/helpers';
import styles from './price-range-input.module.scss';

import DefaultInput from 'inputs-comp/default-input-comp/default-input-comp';

type Props = {
  onInputChange: (value: number, err: boolean) => void;
  priceRange: PriceRangeData;
  availableBalance: number | null | undefined;
};

const PriceRangeInput = (props: Props) => {
  const {
    priceRange: { min_price, max_price },
    onInputChange,
    availableBalance,
  } = props;
  const rangeWithCurrency = {
    min: getFullPrice(min_price, 2, true),
    max: getFullPrice(max_price, 2, true),
  } as const;

  return (
    <div>
      <div className={styles['price-range-input__label']}>
        {props.priceRange &&
          polyglot.t('giftcards.enter_value_range', rangeWithCurrency)}
      </div>
      <DefaultInput
        placeholderText={polyglot.t('giftcards.gift_card_amount')}
        type='number'
        onInputChange={onInputChange}
        inputClassName={'input--gift-card-price'}
        immediateEvaluation={true}
        extraErrors={[
          {
            key: 'invalidRangeValue',
            message: polyglot.t('giftcards.invalid_range', rangeWithCurrency),
            isError: (value: number) =>
              !value ||
              isNaN(value) ||
              value % 1 !== 0 ||
              !(
                min_price.amount / 100 <= value &&
                value <= max_price.amount / 100
              ),
          },
          {
            key: 'insufficientBalance',
            message: polyglot.t('giftcards.insufficient_balance'),
            isError: (value: number) =>
              availableBalance && availableBalance < value,
          },
        ]}
      />
    </div>
  );
};

PriceRangeInput.displayName = 'PriceRangeInput';

export default PriceRangeInput;
