import { isSharedAccount } from '../stores/user-store';
import { get, storageKeys, StorageKey } from './storage';
import { useStorage } from './hooks/use-storage';
import noop from 'lodash/noop';

export type UseGlobalContentPreferenceReturn = {
  hasSetGlobalContentPreference: boolean;
  globalContentPreference: boolean | null;
  setGlobalContentPreference: (arg1: boolean) => void;
};

type OnPreferenceChangeCallback = (useGlobalContent: boolean) => void;

type Headers = {
  [key: string]: string;
};

export const ContentBannerSections = Object.freeze({
  EAP: 'EAP',
  ASSESSMENTS: 'ASSESSMENTS',
  PROGRAMS: 'PROGRAMS',
});

export type ContentBannerSection =
  (typeof ContentBannerSections)[keyof typeof ContentBannerSections];

type SectionToKeyMapping = Partial<Record<ContentBannerSection, StorageKey>>;

const sectionToKeyMapping: SectionToKeyMapping = {
  [ContentBannerSections.EAP]: storageKeys.useGlobalContent,
  [ContentBannerSections.ASSESSMENTS]: storageKeys.useAssessmentsGlobalContent,
};

export const getGlobalContentPreferenceFromStorage = (
  section: ContentBannerSection,
): boolean => {
  const storageKey = sectionToKeyMapping[section];

  if (!storageKey) {
    throw new Error(
      `No mapping from content banner section ${section}
        to storage key found. Please add one in sectionToKeyMapping.`,
    );
  }

  const useGlobalContent = get<string | null>(
    storageKeys.useGlobalContent,
    !isSharedAccount(),
  );

  const shouldUseGlobalContent = JSON.parse(useGlobalContent || 'false');

  return !!shouldUseGlobalContent;
};

type GlobalContentPreferenceHeaders = {
  ['Request-Global-Content']: boolean;
};

export const getGlobalContentPreferenceHeaders = (
  section: ContentBannerSection,
): GlobalContentPreferenceHeaders => {
  const preference = getGlobalContentPreferenceFromStorage(section);
  return {
    'Request-Global-Content': preference,
  };
};

export const canShowGlobalContentBannerBasedOnHeaders = (
  headers: Headers,
): boolean => {
  return headers['translated-global-content-exists'] === 'true';
};

interface ResponseWithGlobalContentData {
  contentLanguage?: string | null | undefined;
  translatedGlobalContentExists: boolean;
}

export const canShowGlobalContentBannerBasedOnResponse = (
  response?: ResponseWithGlobalContentData | null,
): boolean => {
  return !!response?.translatedGlobalContentExists;
};

export const canShowGlobalContentBanner = (
  responseAllowsBannerToBeShown: boolean,
  hasSetGlobalContentPreference: boolean,
  responseLanguageCode: string,
  userLanguageCode: string,
): boolean => {
  return (
    !hasSetGlobalContentPreference &&
    responseAllowsBannerToBeShown &&
    responseLanguageCode !== userLanguageCode
  );
};

export const useGlobalContentPreference = (
  section: ContentBannerSection,
  onPreferenceChange: OnPreferenceChangeCallback = noop,
): UseGlobalContentPreferenceReturn => {
  const storageKey = sectionToKeyMapping[section];

  if (!storageKey) {
    throw new Error(
      `No mapping from content banner section ${section}
        to storage key found. Please add one in sectionToKeyMapping.`,
    );
  }

  const [globalContentPreference, setGlobalContentPreferenceState] =
    useStorage<boolean>(storageKey, null, !isSharedAccount());

  const setGlobalContentPreference = (preference: boolean) => {
    setGlobalContentPreferenceState(preference);
    onPreferenceChange(preference);
  };

  const hasSetGlobalContentPreference = globalContentPreference !== null;

  return {
    setGlobalContentPreference,
    globalContentPreference,
    hasSetGlobalContentPreference,
  };
};
