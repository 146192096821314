import fetch from 'globals/wa-fetch';
import { AppLogger } from '@workivate/tho-web-shared';
import { goTo } from 'router/navigation';
import { getUser } from 'stores/user-store';
import type { Dispatch, ThunkedAction } from 'globals/types';
import type { MerchantsResponse, Merchant } from 'globals/perks.types';
import type { Model, CinemaCodesResponse } from './types';
import type { Model as CinemasModel } from '../../cinemas.types';
import type { CarouselResponse } from '../../../shop-online/state/types';
import { showToast } from 'react-components/toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';

export function getCinemasMerchants(
  offset: number,
  limit: number,
): Promise<MerchantsResponse> {
  const urlParams = { limit, offset } as const;

  return fetch('/merchants?primary_feature=benefit_cinema', {
    urlParameters: urlParams,
    version: 1.5,
    token: true,
  });
}

export function getCinemaCodes(
  offsetRef?: string,
): Promise<CinemaCodesResponse> {
  return fetch(`/views/users/${getUser().user_id}/cinema-ticket-orders`, {
    version: 1.5,
    token: true,
    urlParameters: offsetRef ? { offset_ref: offsetRef } : {},
  });
}

export function fetchCinemaCodes(offsetRef?: string): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'CINEMAS_HUB/FETCH_CINEMA_CODES' });
    return getCinemaCodes(offsetRef).then(
      (response: CinemaCodesResponse) =>
        dispatch({
          type: 'CINEMAS_HUB/FETCH_CINEMA_CODES_SUCCESS',
          response,
        }),
      () => goTo('cinemasError'),
    );
  };
}

export function fetchCinemasMerchants(
  reset: boolean,
  isSearch = false,
): ThunkedAction {
  return function (
    dispatch: Dispatch,
    getState: () => {
      cinemas: {
        hub: Model;
      };
    },
  ) {
    const {
      cinemas: {
        hub: {
          fetching,
          paging: { offset, limit, isLastPage },
        },
      },
    } = getState();

    if (fetching || isLastPage) {
      return Promise.resolve();
    }

    dispatch({ type: 'CINEMAS_HUB/FETCH_MERCHANTS' });

    return getCinemasMerchants(reset ? 0 : offset, isSearch ? 500 : limit).then(
      (response: MerchantsResponse) =>
        dispatch({
          type: 'CINEMAS_HUB/FETCH_MERCHANTS_SUCCESS',
          response,
        }),
      () => goTo('cinemasError'),
    );
  };
}

const filterCinemasPromise = (
  merchants: Array<Merchant>,
  query: string,
): Promise<Array<Merchant>> => {
  return new Promise(resolve => {
    if (!query || typeof query !== 'string') {
      resolve([]);
    }

    resolve(
      merchants.filter((merchantItem: Merchant) => {
        const merchantName = (merchantItem['name'] || '').toUpperCase();
        const newQuery = query.toUpperCase();

        if (!merchantName) {
          return false;
        }

        return merchantName.indexOf(newQuery) !== -1;
      }),
    );
  });
};

export const filterCinemasMerchants =
  (searchTerm: string) =>
  async (
    dispatch: Dispatch,
    getState: () => {
      cinemas: CinemasModel;
    },
  ) => {
    const items = getState().cinemas.hub.merchants;

    try {
      const filteredMerchants = await filterCinemasPromise(items, searchTerm);

      dispatch({
        type: 'CINEMAS_HUB/FILTER_MERCHANTS',
        payload: { filteredMerchants },
      });
    } catch (error: any) {
      AppLogger.error(error);
    }
  };

export const filteringCinemaMerchants = () => ({
  type: 'CINEMAS_HUB/FILTERING_MERCHANTS',
});

export function getCarousel(): Promise<CarouselResponse> {
  return fetch('/benefit/online-shop/carousel', {
    token: true,
    version: 1.3,
  });
}

export function fetchCarousel(): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'CINEMAS_HUB/FETCH_CAROUSEL' });

    return getCarousel()
      .then((response: CarouselResponse) => {
        dispatch({
          type: 'CINEMAS_HUB/FETCH_CAROUSEL_SUCCESS',
          resource: response.body,
        });
      })
      .catch(() => {
        dispatch(
          showToast({
            type: ToastType.ERROR,
            message: window.polyglot.t('shop_online.error'),
          }),
        );
        dispatch({
          type: 'CINEMAS_HUB/FETCH_CAROUSEL_FAILURE',
        });
      });
  };
}

export const reset = () => ({ type: 'CINEMAS_HUB/RESET' });
