import { injectAsyncReducer } from '../../hermes-redux';

import { AppointmentsRoutes } from './state/constants';
import { MyCareRoutes } from '../my-care/my-care.constants';
import { restrictions as restrictionsObj } from 'state/features-list/constants';
import {
  shouldGrantAccessToAppointmentsSelectionFlow,
  shouldGrantAccessToCounsellorSelectionFlow,
  shouldGrantAccessToViewAppointments,
} from './state/helpers';
import USER_ROLES from 'constants/user-roles.constant';

const {
  APPOINTMENT_CANCEL,
  APPOINTMENT_CONFIRMATION,
  APPOINTMENT_DETAILS,
  APPOINTMENT_INTRO,
  APPOINTMENT_TIMESLOT,
  APPOINTMENT_TYPE,
  CONTACT_DETAILS,
  COUNSELLOR_PROFILE,
  COUNSELLOR_SELECTION,
  USER_LOCATION,
  VIEW_APPOINTMENTS,
  PAYMENT_DETAILS,
  TEAMS_PAYMENT_REDIRECT,
} = AppointmentsRoutes;

const createResolver = pageImport =>
  Promise.all([
    pageImport,
    import(/* webpackChunkName: "appointments-reducer" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('appointments', reducer.default);
    return component;
  });

const checkConsent = () => ({
  consentType: 'counsellor_choice',
  customBackLink: MyCareRoutes.MY_CARE_HUB,
});

const restrictions = {
  user: [
    restrictionsObj['counsellor_access'],
    restrictionsObj['counsellor_appointments'],
  ],
};

const appointmentsModule = {
  appointmentCancel: {
    name: APPOINTMENT_CANCEL,
    url: '/appointments/view-appointments/:id/cancel',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-appointment-cancel" */ './containers/cancel-appointment/cancel-appointment'
        ),
      ),
    restrictions,
    data: {
      title: 'appointments.view_appointments.appointment_cancelled.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  appointmentConfirmation: {
    name: APPOINTMENT_CONFIRMATION,
    url: '/appointments/appointment-confirmation',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-appointment-confirmation" */ './containers/appointment-confirmation/appointment-confirmation'
        ),
      ),
    restrictions,
    checkConsent,
    shouldGrantAccess: shouldGrantAccessToAppointmentsSelectionFlow,
    data: {
      title: 'appointments.appointment_confirmation.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  appointmentDetails: {
    name: APPOINTMENT_DETAILS,
    url: '/appointments/view-appointments/:id',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-appointment-details" */ './containers/appointment-details/appointment-details'
        ),
      ),
    restrictions,
    data: {
      title: 'appointments.view_appointments.appointment_details.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  appointmentIntro: {
    name: APPOINTMENT_INTRO,
    url: '/appointments/intro',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-appointment-intro" */ './containers/appointment-intro/appointment-intro'
        ),
      ),
    restrictions,
    shouldGrantAccess: shouldGrantAccessToCounsellorSelectionFlow,
    data: {
      title: 'appointments.appointment_intro.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  appointmentType: {
    name: APPOINTMENT_TYPE,
    url: '/appointments/appointment-type',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-appointment-type" */ './containers/appointment-type/appointment-type'
        ),
      ),
    restrictions,
    shouldGrantAccess: shouldGrantAccessToAppointmentsSelectionFlow,
    data: {
      title: 'appointments.appointment_type.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  contactDetails: {
    name: CONTACT_DETAILS,
    url: '/appointments/contact-details',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-contact-details" */ './containers/contact-details/contact-details'
        ),
      ),
    restrictions,
    shouldGrantAccess: shouldGrantAccessToAppointmentsSelectionFlow,
    data: {
      title: 'appointments.contact_details.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  counsellorProfile: {
    name: COUNSELLOR_PROFILE,
    url: '/appointments/counsellor-profile/:id',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-view-counsellor" */ './containers/counsellor-profile/counsellor-profile'
        ),
      ),
    restrictions,
    data: {
      title: 'appointments.counsellor_profile.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  counsellorSelection: {
    name: COUNSELLOR_SELECTION,
    url: '/appointments/counsellor-selection',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-counsellor-selection" */ './containers/counsellor-selection/counsellor-selection'
        ),
      ),
    restrictions,
    shouldGrantAccess: shouldGrantAccessToCounsellorSelectionFlow,
    data: {
      title: 'appointments.counselor_selection.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  timeslotSelection: {
    name: APPOINTMENT_TIMESLOT,
    url: '/appointments/timeslot-selection',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-timeslot-selection" */ './containers/timeslot-selection/timeslot-selection'
        ),
      ),
    restrictions,
    shouldGrantAccess: shouldGrantAccessToAppointmentsSelectionFlow,
    data: {
      title: 'appointments.timeslot_selection.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  userLocation: {
    name: USER_LOCATION,
    url: '/appointments/user-location',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-user-location" */ './containers/user-location/user-location'
        ),
      ),
    restrictions,
    shouldGrantAccess: shouldGrantAccessToCounsellorSelectionFlow,
    data: {
      title: 'appointments.user_location.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  viewAppointments: {
    name: VIEW_APPOINTMENTS,
    url: '/appointments/view-appointments',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-view-appointments" */ './containers/view-appointments/view-appointments'
        ),
      ),
    restrictions,
    shouldGrantAccess: shouldGrantAccessToViewAppointments,
    data: {
      title: 'appointments.view_appointments.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  paymentDetails: {
    name: PAYMENT_DETAILS,
    url: '/appointments/payment-details',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-contact-details" */ './containers/payment-details/payment-details'
        ),
      ),
    restrictions,
    shouldGrantAccess: shouldGrantAccessToAppointmentsSelectionFlow,
    data: {
      title: 'appointments.payment_details.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  teamsPaymentRedirect: {
    name: TEAMS_PAYMENT_REDIRECT,
    url: '/appointments/teams-payment-redirect',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "appointments-teams-payment-redirect" */ './containers/teams-payment-redirect/teams-payment-redirect'
        ),
      ),
    restrictions,
    shouldGrantAccess: shouldGrantAccessToAppointmentsSelectionFlow,
    data: {
      title: 'appointments.payment_details.title',
      includeInMenu: false,
      hideFooter: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default appointmentsModule;
