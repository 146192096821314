// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gift-cards-list-module__container___ilZ6I {
  max-width: 100%;
}
.gift-cards-list-module__container__selected-category___IVh6z {
  margin: 0 0 20px 0;
  max-width: 100%;
  text-align: left;
}
.gift-cards-list-module__container__pagination-container___y0eqW {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./client/app/pages/gift-cards/shared/gift-cards-list.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ","sourcesContent":[".container {\n  max-width: 100%;\n\n  &__selected-category {\n    margin: 0 0 20px 0;\n    max-width: 100%;\n    text-align: left;\n  }\n\n  &__pagination-container {\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `gift-cards-list-module__container___ilZ6I`,
	"container__selected-category": `gift-cards-list-module__container__selected-category___IVh6z`,
	"container__pagination-container": `gift-cards-list-module__container__pagination-container___y0eqW`
};
export default ___CSS_LOADER_EXPORT___;
