import type { Dispatch } from 'globals/types';
import { GIFT_CARD_HELP_LINK } from 'pages/gift-cards/shared/constants';
import { CINEMA_HELP_LINK } from 'pages/cinemas/common/constants';
import { openZendeskLinkWithToken } from 'state/global/actions';

import styles from './need-help.module.scss';

type Props = {
  dispatch: Dispatch;
  referrer: string;
};

class NeedHelp extends React.Component<Props> {
  static displayName = 'NeedHelpLink';

  constructor(props: Props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    const { referrer, dispatch } = this.props;
    if (referrer === 'giftcards' || referrer === 'spotRewards') {
      dispatch(openZendeskLinkWithToken(GIFT_CARD_HELP_LINK));
    } else if (referrer === 'cinema') {
      dispatch(openZendeskLinkWithToken(CINEMA_HELP_LINK));
    }
  };

  handleKey = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      this.handleClick();
    }
  };

  render() {
    const { referrer } = this.props;
    return (
      <a
        onClick={this.handleClick}
        onKeyDown={this.handleKey}
        target={'_blank'}
        className={styles['need-help']}
        tabIndex={0}
      >
        {referrer === 'cinema'
          ? polyglot.t('cinemas.cant_find')
          : polyglot.t('giftcards.cant_find')}
      </a>
    );
  }
}

export default NeedHelp;
