import USER_ROLES from 'constants/user-roles.constant';

const retailerRedirectModule = {
  name: 'retailerRedirect',
  url: '/retailer-redirect',
  resolver: () =>
    import(/* webpackChunkName: "cashback-redirect" */ './retailer-redirect'),
  data: {
    hideNavbar: true,
    title: 'components.main_menu.redirecting',
    authorizedRoles: [USER_ROLES.all],
  },
};

export default retailerRedirectModule;
