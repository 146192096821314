import { goTo } from 'router/navigation';

import PerksPage from 'perks/layouts/full-width';
import ErrorComp from 'wa-storybook/components/general/error-comp/error-comp';

import styles from './error-page.module.scss';

type Props = {
  referrer: string;
};

const GiftCardsErrorPage = (props: Props) => {
  return (
    <PerksPage>
      <div className={styles.container}>
        <ErrorComp
          title={window.polyglot.t('giftcards.error_loading')}
          cta={window.polyglot.t('giftcards.go_back')}
          action={() => goTo(props.referrer)}
        />
      </div>
    </PerksPage>
  );
};

GiftCardsErrorPage.displayName = 'GiftCardsErrorPage';

export default GiftCardsErrorPage;
