import { getStore } from '../../hermes-redux';
import { getUser, isSharedAccount } from '../../stores/user-store';
import { getConsents } from '../../state/consent/actions';
import { CONSENT_CACHE_MILLIS } from './constants';

class ConsentService {
  currentRequest?: Promise<undefined> | null;
  loadConsentsData(
    bypassCache = false,
    isCreatingLimitedAccount = false,
  ): Promise<void> {
    const { consent } = getStore().getState();

    const user = getUser();

    const expired =
      consent.lastUpdated === null ||
      new Date().getTime() - (consent.lastUpdated as number) >
        CONSENT_CACHE_MILLIS;
    const bustCache = bypassCache || expired;

    const canRequestConsentData =
      (!isSharedAccount() || isCreatingLimitedAccount) &&
      (!consent.consents.length || bustCache) &&
      user.user_id;

    if (canRequestConsentData) {
      if (!this.currentRequest) {
        this.currentRequest = getStore()
          .dispatch(getConsents(user.user_id))
          .finally(() => {
            this.currentRequest = null;
          });
      }

      return this.currentRequest || Promise.resolve();
    }

    return Promise.resolve();
  }
}

export const consentService = new ConsentService();
