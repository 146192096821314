import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

export const listingRegex = /^\/life\/[^/]+\/categories\/.*\/listing$/;

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "eap" */ './employee-assistance-comp'),
    import(/* webpackChunkName: "eap" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer(`life`, reducer.employeeAssistanceReducer);
    return component;
  });
}

const employeeAssistanceModule = {
  listing: {
    name: 'employeeAssistanceCategoriesListing',
    url: '/life/:contentSection/categories/*',
    /* wildcard in middle is not supported anymore in react-router v6
    (https://reactrouter.com/en/main/upgrading/v5#note-on-route-path-patterns)
    Since listing and categories both have the same path,
    we distinguish between them by the regex
    */
    match: listingRegex,
    resolver,
    restrictions: {
      user: [restrictions['eap_assistance'], restrictions['eap_hwr_toolkits']],
      operator: 'OR',
    },
    data: {
      mainMenu: 'employeeAssistance',
      section: 'life',
      includeInMenu: false,
      showSearch: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  categories: {
    name: 'employeeAssistanceCategories',
    url: '/life/:contentSection/categories/*',
    resolver,
    restrictions: {
      user: [restrictions['eap_assistance'], restrictions['eap_hwr_toolkits']],
      operator: 'OR',
    },
    data: {
      mainMenu: 'employeeAssistance',
      section: 'life',
      includeInMenu: false,
      showSearch: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  search: {
    name: 'employeeAssistanceSearch',
    url: '/life/:contentSection/search',
    resolver,
    restrictions: {
      user: [restrictions['eap_assistance'], restrictions['eap_hwr_toolkits']],
      operator: 'OR',
    },
    data: {
      mainMenu: 'employeeAssistance',
      section: 'life',
      includeInMenu: false,
      showSearch: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  internationalNumbers: {
    name: 'employeeAssistanceInternationalNumbers',
    url: '/life/employee-assistance/international-numbers',
    resolver,
    restrictions: {
      user: [restrictions['eap_assistance'], restrictions['eap_hwr_toolkits']],
      operator: 'OR',
    },
    data: {
      includeInMenu: false,
      showSearch: false,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  articles: {
    name: 'employeeAssistanceArticles',
    url: '/life/:contentSection/articles/*',
    resolver,
    restrictions: {
      user: [restrictions['eap_assistance'], restrictions['eap_hwr_toolkits']],
      operator: 'OR',
    },
    data: {
      mainMenu: 'employeeAssistance',
      section: 'life',
      includeInMenu: false,
      showSearch: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  error: {
    name: 'employeeAssistanceError',
    url: '/life/:contentSection/error',
    resolver,
    restrictions: {
      user: [restrictions['eap_assistance'], restrictions['eap_hwr_toolkits']],
      operator: 'OR',
    },
    data: {
      title: 'components.main_menu.employee_assistance',
      mainMenu: 'employeeAssistance',
      section: 'life',
      includeInMenu: false,
      showSearch: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  eapHome: {
    name: 'employeeAssistanceHome',
    url: '/life/employee-assistance',
    resolver,
    restrictions: {
      user: [restrictions['eap_assistance']],
      operator: 'OR',
    },
    data: {
      title: 'components.main_menu.employee_assistance',
      mainMenu: 'employeeAssistance',
      section: 'life',
      showSearch: false,
      includeInMenu: true,
      authorizedRoles: [USER_ROLES.employee],
      shortcutKeyCode: 52,
    },
  },
  hwrHome: {
    name: 'hwrHome',
    url: '/life/hwr',
    resolver,
    restrictions: {
      user: [restrictions['eap_hwr_toolkits']],
      operator: 'OR',
    },
    data: {
      title: 'components.main_menu.hwr_toolkits',
      mainMenu: 'hwr',
      section: 'life',
      showSearch: false,
      includeInMenu: true,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default employeeAssistanceModule;
