import Spinner from 'wa-storybook/components/general/spinner/spinner';
import styles from './card-overlay-with-icon.module.scss';

import classNames from 'classnames';
import { onEnterOrSpace } from 'wa-storybook/global/helpers';
import { CSSProperties } from 'react';

type Props = {
  image?: string | null;
  imageAlt: string;
  logo?: string | null;
  imageTitle: string;
  onClick: () => void;
  isActive: boolean;
  isActivityTriggered: boolean;
  icon?: string;
  style?: CSSProperties;
  className: string | string[];
  backgroundColor?: string;
  imageStyle?: CSSProperties;
  imageClassName: string | string[];
  onImageError?: () => void;
};

type State = {
  displaySpinner: boolean;
};

const isCloudFrontPicture = url => {
  if (!url) {
    return;
  }
  return url.includes('cloudfront.net');
};

class CardOverlayWithIcon extends React.Component<Props, State> {
  static displayName = 'CardOverlayWithIcon';

  constructor(props: Props) {
    super(props);
    this.state = {
      displaySpinner: false,
    };
  }

  handleClick = (event: React.SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation();
    this.setState({ displaySpinner: true });
    this.props.onClick();
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.isActive !== prevProps.isActive) {
      this.setState({ displaySpinner: false });
    }
  }

  render() {
    const {
      image,
      logo,
      backgroundColor,
      imageTitle,
      icon,
      imageAlt,
      isActive,
      isActivityTriggered,
      style,
      className,
      imageClassName,
      imageStyle,
      onImageError,
    } = this.props;

    return (
      <div
        className={classNames(
          styles.overlay,
          Array.isArray(className)
            ? className.map(className => styles[className])
            : styles[className],
        )}
        style={style}
      >
        {backgroundColor ? (
          <div
            className={classNames(
              styles['overlay-image'],
              styles['full-height'],
            )}
            style={{ backgroundColor: backgroundColor }}
          />
        ) : (
          !!image && (
            <img
              alt={imageAlt}
              src={image}
              onError={onImageError}
              className={classNames(
                styles['overlay-image'],
                logo && styles['overlay-image--darkened'],
                Array.isArray(imageClassName)
                  ? imageClassName.map(className => styles[className])
                  : styles[imageClassName],
              )}
              style={imageStyle}
            />
          )
        )}
        {logo && (
          <img
            alt={imageAlt}
            src={logo}
            className={
              styles[`logo${isCloudFrontPicture(logo) ? '--cashback' : ''}`]
            }
          />
        )}
        {imageTitle && <div className={styles['image-gradient']} />}
        <div className={styles['image-title__wrapper']}>
          <div className={styles['image-title']}>{imageTitle}</div>
        </div>
        {!!icon &&
          (this.state.displaySpinner ? (
            <div className={styles['spinner__container']}>
              <Spinner isSmall />
            </div>
          ) : (
            <div
              role='checkbox'
              tabIndex={0}
              aria-label={polyglot.t('shop_online.favourite')}
              aria-checked={isActive}
              className={classNames(
                styles['icon__wrapper'],
                'white-flag-icon',
                'white-flag-icon__box',
              )}
              onClick={this.handleClick}
              onKeyDown={onEnterOrSpace(this.handleClick)}
            >
              <div className='white-flag-icon__icon'>
                <div
                  className={`white-icon-wrapper
                  ${isActivityTriggered ? 'animated' : ''}
                  ${isActive ? 'active' : ''}`}
                >
                  <div className={`white-icon ${icon}`} />
                  <div className='white-ring' />
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default CardOverlayWithIcon;
