import styles from './carousel.module.scss';
import classNames from 'classnames';
import { resolveClassNames } from 'wa-storybook/helpers/classes';
import ChevronNextPensionBenefits from 'wa-storybook/components/general/icon/components/chevron-next-pension-benefits';
import ChevronBackPensionBenefits from 'wa-storybook/components/general/icon/components/chevron-back-pension-benefits';

type Props = {
  isPrev: boolean;
  onClick?: () => void;
  arrowClassName?: string | Array<string>;
};

const CarouselArrowPensionBenefits = (props: Props) => {
  const { isPrev, onClick, arrowClassName } = props;

  if (!onClick) return null;

  const Chevron = isPrev
    ? ChevronBackPensionBenefits
    : ChevronNextPensionBenefits;

  return (
    <button
      aria-hidden
      tabIndex={-1}
      // This will prevent the focus when clicking an element
      onMouseDown={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
      onClick={onClick}
      className={classNames(
        styles['arrow-container'],
        styles[`arrow-container--pension-benefits`],
        isPrev
          ? classNames(
              styles['arrow-container--prev'],
              styles['arrow-container--prev-pension-benefits'],
            )
          : classNames(
              styles['arrow-container--next'],
              styles['arrow-container--next-pension-benefits'],
            ),
        resolveClassNames(arrowClassName, styles),
      )}
      data-hook={props.isPrev ? 'prev-arrow' : 'next-arrow'}
    >
      <div
        className={classNames(
          styles['arrow-container--icon'],
          styles[`arrow-container--icon-pension-benefits`],
        )}
      >
        <Chevron />
      </div>
    </button>
  );
};

CarouselArrowPensionBenefits.displayName = 'CarouselArrowPensionBenefits';

export default CarouselArrowPensionBenefits;
