import queryString from 'query-string';
import { set, get, getKeys } from 'globals/storage';
import * as Storage from 'globals/storage';

export const testableFeaturesEnabled = (): boolean => {
  if (IS_PRODUCTION) {
    return false;
  }

  const sessionStorageTestableFlag = get(
    getKeys().testableFeaturesEnabled,
    false,
  );
  const { enableTestableFeatures: queryStringTestableFlag } = queryString.parse(
    location.search,
  );

  // Query string takes priority over everything else
  // this is required because there are places where testable flags can be used(such as routes, or constants)
  // where 'setTestableFlag' might not have been called by the time that code is evaluated.
  // In these cases the flag will still be in the query string, so we can use this to determine the correct state.
  if (queryStringTestableFlag !== undefined) {
    return JSON.parse(queryStringTestableFlag as string);
  }

  return sessionStorageTestableFlag !== null
    ? !!sessionStorageTestableFlag
    : window.WAM.ENV.testableDefault;
};

export const setTestableFlag = (isEnabled: boolean): void => {
  if (!IS_PRODUCTION) {
    set(getKeys().testableFeaturesEnabled, isEnabled, false);
  }
};

export const isUnifiedLoginEnabled = () =>
  localStorage.getItem(Storage.storageKeys.isUnifiedLoginEnabled) === 'true' &&
  !IS_PRODUCTION;
