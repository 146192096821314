import USER_ROLES from 'constants/user-roles.constant';
import { SIGN_UP_MODULE_NAME } from '../constants';

function resolver() {
  return Promise.resolve(import('./view/signup-main-comp'));
}

const signupMainModule = {
  name: SIGN_UP_MODULE_NAME,
  url: '/signup',
  resolver,
  data: {
    title: 'components.main_menu.signup_new',
    authorizedRoles: [USER_ROLES.all],
  },
} as const;

export default signupMainModule;
