import { PARTICIPATION_TYPE } from '../hub/constants';

export const MODULE_NAME = 'challengeDetails';

export type ParticipationTypeParameter =
  (typeof PARTICIPATION_TYPE)[keyof typeof PARTICIPATION_TYPE];

export const INTRODUCTION_IDS = {
  ABOUT: 'about',
  GOAL: 'goal',
  JOINING: 'joining',
  TERMS: 'terms',
} as const;
