// RouterContext.js
import { createContext, useContext, useState, ReactNode } from 'react';

import { Module } from 'router/types';

type RouterContextState = {
  previousRoute: Module;
  currentRoute: Module;
  updateRouterState: (route: Module) => void;
};

type Props = {
  children?: ReactNode;
};

const routerState = {
  previousRoute: {} as Module,
  currentRoute: {} as Module,
};

const RouterContext = createContext<RouterContextState>({
  ...routerState,
  updateRouterState: () => null,
});

export const RouterStateProvider = ({ children }: Props) => {
  const [currentRoute, setCurrentRoute] = useState({} as Module);
  const [previousRoute, setPreviousRoute] = useState({} as Module);

  const updateRouterState = (route: Module) => {
    setPreviousRoute(currentRoute);
    setCurrentRoute(route);

    routerState.previousRoute = { ...currentRoute };
    routerState.currentRoute = { ...route };
  };

  return (
    <RouterContext.Provider
      value={{ currentRoute, previousRoute, updateRouterState }}
    >
      {children}
    </RouterContext.Provider>
  );
};

export const useRouterState = () => useContext(RouterContext);

export const getRouterState = () => {
  return routerState;
};
