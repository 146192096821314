import type { Dispatch, RefPagingAPI } from 'globals/types';
import type { CinemaOrder } from 'pages/cinemas/hub/state/types';
import type { GiftCardOrder } from 'pages/gift-cards/gift-cards.types';
import { fetchGiftCardsOrders } from 'pages/gift-cards/hub/state/actions';
import { fetchCinemaCodes } from 'pages/cinemas/hub/state/actions';

import Button from 'wa-storybook/components/general/button/button';
import Spinner from 'wa-storybook/components/general/spinner/spinner';
import CinemaCodes from './components/codes/cinema-codes';
import GiftCardCodes from './components/codes/gift-card-codes';
import NeedHelp from './components/need-help/need-help';
import ModalHeader from 'wa-storybook/components/general/modal-portal-comp/components/modal-header/modal-header';

import styles from './codes-modal.module.scss';

type Props = {
  closeModal: (evt: React.SyntheticEvent<HTMLButtonElement>) => void;
  dispatch: Dispatch;
  fetchingOrders: boolean;
  gcOrder?: Array<GiftCardOrder>;
  cinOrder?: Array<CinemaOrder>;
  ordersPaging: RefPagingAPI;
  referrer: string;
};

class CodesModal extends React.Component<Props> {
  static displayName = 'CodesModal';

  static defaultProps: {
    cinOrder: Array<CinemaOrder>;
    gcOrder: Array<GiftCardOrder>;
  };

  loadMore = (offset?: string) => {
    if (this.props.referrer === 'cinema') {
      this.props.dispatch(fetchCinemaCodes(offset));
    } else {
      this.props.dispatch(fetchGiftCardsOrders(this.props.referrer, offset));
    }
  };

  render() {
    const {
      cinOrder,
      gcOrder,
      fetchingOrders,
      ordersPaging: { offset_ref, is_last_page },
      dispatch,
      closeModal,
      referrer,
    } = this.props;
    return (
      <div className={styles.modal}>
        <ModalHeader
          label={
            referrer === 'cinema'
              ? polyglot.t('cinemas.cinema_codes')
              : polyglot.t('giftcards.giftcard_codes')
          }
        />
        {fetchingOrders ? (
          <div className={styles['spinner-container']}>
            <Spinner top={'calc(50% - 30px)'} />
          </div>
        ) : !fetchingOrders ? (
          <div className={styles.codes}>
            {referrer === 'cinema' ? (
              <CinemaCodes cinOrder={cinOrder} />
            ) : (
              <GiftCardCodes gcOrder={gcOrder} />
            )}
            {!is_last_page && (
              <div className={styles.more}>
                <div className={styles['more__button']}>
                  <Button
                    dataHook='show more'
                    onClick={() => this.loadMore(offset_ref)}
                    text={polyglot.t('global.show_more')}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={styles['no-codes']}>
            {referrer === 'cinema'
              ? polyglot.t('cinemas.no_cinema_code')
              : polyglot.t('giftcards.no_gift_card_code')}
          </div>
        )}
        <div className={styles.footer}>
          <NeedHelp dispatch={dispatch} referrer={referrer} />
          <div className={styles['footer--button']}>
            <Button
              dataHook='close'
              onClick={closeModal}
              text={polyglot.t('confirm_modal.ok')}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CodesModal;
