import type { Model, CompanySettingsAction } from './types';

export const initialState = {
  grouping: {
    post: false,
    subscription: false,
  },
};

export function companySettingsReducer(
  state: Model = initialState,
  action: CompanySettingsAction,
): Model {
  switch (action.type) {
    case 'COMPANY_SETTINGS/GET_SETTINGS_SUCESS':
      return Object.assign({}, state, action.settings);

    default:
      return state;
  }
}
