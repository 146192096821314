import classNames from 'classnames';
import type { Price } from 'globals/perks.types';
import { includesVAT } from '../../../state/helpers';

import DiscountedPrice from '../../../../shared/discounted-price';
import Button from 'wa-storybook/components/general/button/button';

import styles from './buy-now.module.scss';

type Props = {
  price: Price;
  discount: number;
  onBuyNow: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  label: string;
  isDisabled: boolean;
  referrer: string;
};

const BuyNow = (props: Props) => {
  const { price, discount, label, onBuyNow, isDisabled, referrer } = props;
  const isSpotRewards = referrer === 'spotRewards';

  const discountedPrice: Price = price
    ? !isSpotRewards
      ? {
          originalAmount: price.amount,
          amount: price.amount - (discount / 100) * price.amount,
          currency: price.currency,
        }
      : {
          amount: price.amount,
          currency: price.currency,
        }
    : { amount: 0, currency: 'GBP' };

  return (
    <div className={styles.container}>
      {!isSpotRewards && price.amount > 0 && (
        <DiscountedPrice
          price={price}
          discountedPrice={discountedPrice}
          includesVAT={includesVAT()}
        />
      )}
      <div
        className={classNames(
          styles.button,
          price.amount && styles['button--spacing'],
        )}
      >
        <Button
          dataHook='buy-now'
          text={label}
          onClick={onBuyNow}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

BuyNow.displayName = 'BuyNow';

export default BuyNow;
