import fetch from 'globals/wa-fetch';
import type { Dispatch, ThunkedAction } from 'globals/types';
import type { PerksHomePageResponse, Model } from './types';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { getUser } from 'stores/user-store';
import { isFeatureEnabled } from 'stores/features-list-store';
import FeatureKeys from 'app/constants/feature-keys';

export const reset = () => ({ type: 'PERKS_HOME_PAGE/RESET' });

// Seems like this function is not called anywhere, a candidate for removal?
function getPerksHomePageInfo() {
  return fetch(
    '/perks-api/perks/summary',
    { token: true, method: 'get' },
    null,
    false,
    true,
  );
}

const PERKS_HOME_PAGE_CACHE_LIMIT_MINUTES = 5;

export function fetchPerksHomePageInfo(): ThunkedAction {
  return function (
    dispatch: Dispatch,
    getState: () => {
      perksHomePage: Model;
    },
  ) {
    const perksHomePage = getState().perksHomePage;

    if (perksHomePage) {
      const { addedAt, data, fetching, userId } = perksHomePage;

      const canFetch = () =>
        ([
          FeatureKeys.BENEFIT_ONLINE_SHOP,
          FeatureKeys.BENEFIT_RESTAURANT,
          FeatureKeys.BENEFIT_CINEMA,
          FeatureKeys.BENEFIT_COLLEAGUE_OFFER,
          FeatureKeys.BENEFIT_GLOBAL_GIFT_CARDS,
          FeatureKeys.BENEFIT_IN_STORE_OFFER,
        ].some(item => isFeatureEnabled(item)) &&
          data.length === 0 &&
          !fetching) ||
        (userId === getUser()['user_id'] &&
          addedAt &&
          differenceInMinutes(new Date(), addedAt) >=
            PERKS_HOME_PAGE_CACHE_LIMIT_MINUTES);

      if (canFetch()) {
        dispatch({ type: 'PERKS_HOME_PAGE/FETCH_INFO' });
        return getPerksHomePageInfo().then(
          (response: PerksHomePageResponse) => {
            dispatch({ type: 'PERKS_HOME_PAGE/FETCH_INFO_SUCCESS', response });
          },
        );
      }
    }
  };
}
