import { HUB_NAME } from './constants';
import USER_ROLES from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';

const hubPageModule = {
  name: HUB_NAME,
  url: '/challenges',
  resolver: () => import(/* webpackChunkName: "challenges" */ './hub-page'),
  restrictions: {
    user: [restrictions['eap_challenges']],
  },
  data: {
    title: 'components.main_menu.well_being_challenges',
    mainMenu: 'challengesHub',
    section: 'wellBeing',
    includeInMenu: true,
    authorizedRoles: [USER_ROLES.employee],
    order: 3,
    hideFooter: true,
  },
};

export default hubPageModule;
