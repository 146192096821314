import { isSharedAccount } from 'stores/user-store';
import USER_ROLES from 'constants/user-roles.constant';

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "profile-edit" */ './view/profile-edit'),
  ]).then(([component]) => component);
}

const profileEditModule = {
  name: 'profileEdit',
  url: '/profile/edit',
  shouldGrantAccess() {
    return Promise.resolve(!isSharedAccount());
  },
  resolver,
  data: {
    title: 'components.main_menu.edit_profile',
    section: 'myAccount',
    mainMenu: 'profile',
    includeInMenu: false,
    hideFooter: true,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default profileEditModule;
