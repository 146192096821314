import type { Model, MenuAction } from './types';
import { getInitialModel } from './constants';

export const initialState = getInitialModel();

export function menuReducer(
  state: Model = initialState,
  action: MenuAction,
): Model {
  switch (action.type) {
    case 'MENU/UPDATE_MENU':
      return action.menu;
    case 'MENU/FETCH_ZENDESK_TOKEN':
      return {
        ...state,
        fetching: true,
      };
    case 'MENU/FETCH_ZENDESK_TOKEN_SUCCESS':
      return {
        ...state,
        fetching: false,
        zendeskToken: action.zendeskToken,
      };
    case 'MENU/FETCH_ZENDESK_TOKEN_FAILURE':
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
