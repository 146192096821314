import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(
      /* webpackChunkName: "wellness-tools" */ './view/wellness-tools-comp'
    ),
    import(/* webpackChunkName: "wellness-tools" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('wellnessTools', reducer.wellnessToolsReducer);
    return component;
  });
}

const wellnessToolsModule = {
  name: 'wellnessTools',
  url: '/wellness-tools',
  resolver,
  restrictions: {
    user: [restrictions['eap_cerner']],
  },
  data: {
    title: 'components.main_menu.wellness_tools',
    mainMenu: 'wellnessTools',
    section: 'life',
    includeInMenu: true,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default wellnessToolsModule;
