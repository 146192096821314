import type { Model, CompanyAction } from './types';

export const initialState: Model = {} as Model;

export function companyReducer(
  state: Model = initialState,
  action: CompanyAction,
): Model {
  switch (action.type) {
    case 'COMPANY/SET_COMPANY':
      return { ...state, ...action.company };

    default:
      return state;
  }
}
