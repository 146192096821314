import USER_ROLES from 'constants/user-roles.constant';

function resolver() {
  return Promise.resolve(import('./view/sso-redirect'));
}

const ssoRedirectModule = {
  name: 'ssoRedirect',
  url: '/sso-redirect',
  resolver,
  data: {
    authorizedRoles: [USER_ROLES.all],
  },
} as const;

export default ssoRedirectModule;
