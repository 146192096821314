import type { Action, Dispatch, ThunkedAction } from 'globals/types';
import type { Price } from 'globals/perks.types';
import type {
  GiftCard,
  GiftCardResponse,
  GiftCardsOrder,
  GiftCardsOrderItem,
} from '../../gift-cards.types';
import type { Model } from './types';
import { escapeGiftCardId } from './helpers';

import fetch from 'globals/wa-fetch';
import { goTo } from 'router/navigation';
import { set, storageKeys } from 'globals/storage';
import * as thisModule from './actions';

export function fetchGiftCard(id: string): Promise<GiftCardResponse> {
  return fetch(`/offers/${escapeGiftCardId(id)}`, {
    version: 1.5,
    token: true,
  });
}

export function addGiftCardToOrder(): ThunkedAction {
  return (
    dispatch: Dispatch,
    getState: () => {
      giftcards: {
        details: Model;
      };
    },
  ) => {
    const {
      giftcards: {
        details: {
          giftCard: { sku },
          order,
          order: { items },
        },
      },
    } = getState();
    const newItems: Array<GiftCardsOrderItem> = [
      // @ts-expect-error - TS2322 - Type '{ sku: string; quantity: number; customizations?: { face_value: Price; } | undefined; }' is not assignable to type 'GiftCardsOrderItem'.
      {
        ...items[0],
        sku,
        quantity: 1,
      },
    ];
    const newOrder: GiftCardsOrder = Object.assign({}, order, {
      items: newItems,
    });

    dispatch({ type: 'GIFT_CARDS_DETAILS/UPDATE_GIFT_CARDS_ORDER', newOrder });
  };
}

export function getGiftCard(id: string, referrer: string): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'GIFT_CARDS_DETAILS/FETCH_GIFT_CARD' });

    //even though this function is declared in this file, using the imported version of it instead so
    //that, when running tests, it avoids the real function being executed instead of a mocked version
    return thisModule.fetchGiftCard(id).then(
      (response: GiftCardResponse) => {
        dispatch({
          type: 'GIFT_CARDS_DETAILS/FETCH_GIFT_CARD_SUCCESS',
          response,
        });
        dispatch(thisModule.addGiftCardToOrder());
        set<GiftCard>(storageKeys.giftCardsSelectedGiftCard, response.body);
      },
      () => {
        if (referrer) {
          goTo(`${referrer}Error`);
        } else {
          goTo('giftcardsError');
        }
      },
    );
  };
}

export function updateValue(value: Price, error: boolean): ThunkedAction {
  return (
    dispatch: Dispatch,
    getState: () => {
      giftcards: {
        details: Model;
      };
    },
  ) => {
    const {
      giftcards: {
        details: {
          invalidValue,
          order,
          order: { items },
          giftCard: {
            value: { data },
          },
        },
      },
    } = getState();
    const newItems: Array<GiftCardsOrderItem> = [
      // @ts-expect-error - TS2322 - Type '{ customizations: { face_value: Price; }; sku?: string | undefined; quantity?: number | undefined; }' is not assignable to type 'GiftCardsOrderItem'.
      {
        ...items[0],
        customizations: { face_value: value },
      },
    ];
    const newOrder: GiftCardsOrder = Object.assign({}, order, {
      items: newItems,
      expected_price: {
        amount: value.amount - (data.discount / 100) * value.amount,
        currency: value.currency,
      },
      total: value,
    });

    set<GiftCardsOrder>(storageKeys.giftCardsOrder, newOrder);

    if (invalidValue !== error) {
      dispatch({ type: 'GIFT_CARDS_DETAILS/INVALID_VALUE', flag: error });
    }
    dispatch({ type: 'GIFT_CARDS_DETAILS/UPDATE_GIFT_CARDS_ORDER', newOrder });
  };
}

export const reset = (): Action => ({ type: 'GIFT_CARDS_DETAILS/RESET' });
