import type { Price } from 'globals/perks.types';
import { getFullPrice } from 'globals/helpers';

import styles from './discounted-price.module.scss';

type Props = {
  price: Price;
  discountedPrice: Price;
  includesVAT: boolean;
};

const DiscountedPrice = ({ price, discountedPrice, includesVAT }: Props) => (
  <div className={styles.prices}>
    {price && price.amount > 0 && (
      <div>
        <div className={styles['discounted-price']}>
          {getFullPrice(discountedPrice, 2, true)}
        </div>
        <div className={styles.price}>{getFullPrice(price, 2, true)}</div>
        {includesVAT && (
          <div className={styles.vat}>{polyglot.t('giftcards.vat_inc')}</div>
        )}
      </div>
    )}
  </div>
);

DiscountedPrice.displayName = 'DiscountedPrice';
export default DiscountedPrice;
