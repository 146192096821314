export default function waURL(input) {
  const pos = input.indexOf('://');
  const isInternal = pos > -1;

  // TODO: To be removed once the API changes the internal_urls to match documentation
  const isCorporateUrl = input.indexOf('corporate-challenge');
  const url = isInternal ? input.substr(pos + 2) : input;

  return isCorporateUrl ? url.replace('corporate-', '') : url;
}
