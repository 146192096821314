import classNames from 'classnames';
import omit from 'lodash/omit';
import find from 'lodash/find';
import { NUMERICAL_AND_SYMBOLS, ALPHABETICAL_AND_SYMBOLS } from 'globals/regex';
import styles from './default-input-comp.module.scss';
import { sanitizeHtml } from 'wa-storybook/helpers/sanitize';
import { ReactNode } from 'react';

type ExtraError = {
  key: string;
  message: string;
  isError?: any;
};

type Props = {
  emptyErrorMessage?: string;
  extraErrors: Array<ExtraError>;
  forceValidation?: boolean;
  holderClass?: string;
  holderClassNames?: string;
  id?: string;
  initValue?: string;
  inputClassName?: string;
  autoComplete?: string;
  isDisabled?: boolean;
  isTextArea?: boolean;
  labelText?: string;
  labelText2?: string;
  maxlength?: string;
  minlength?: string;
  onInputChange: any;
  placeholderText?: string;
  required?: boolean;
  reset?: boolean;
  disabled?: boolean;
  type?: string;
  immediateEvaluation?: boolean;
  visuallyHideLabel?: boolean;
  removeNumbersAndSymbols?: boolean;
  onlyNumbers?: boolean;
};
type State = {
  value: string;
  currentError: boolean | string;
  hasBlurred: boolean;
  isVirgin: boolean;
};
const InputOrTextArea = (props: Props) => {
  const attributes = omit(props, 'isTextArea');
  return props.isTextArea ? (
    <textarea {...attributes} />
  ) : (
    <input {...attributes} />
  );
};

class DefaultInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: '',
      currentError: false,
      hasBlurred: false,
      isVirgin: true,
    };
  }

  static defaultProps = {
    reset: false,
    type: 'text',
    extraErrors: [],
    required: true,
    initValue: '',
    forceValidation: false,
    isTextArea: false,
    inputStyle: {},
    immediateEvaluation: false,
    removeNumbersAndSymbols: false,
    onlyNumbers: false,
  };

  componentDidMount() {
    if (this.props.initValue) {
      this.setState({ value: this.props.initValue }, this.handleBlur);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.reset) {
      this.setState({
        value: '',
        currentError: false,
        hasBlurred: false,
        isVirgin: true,
      });
    }

    if (nextProps.forceValidation) {
      this.setState({
        currentError: this.getCurrentError(
          nextProps.extraErrors,
          this.state.value,
          true,
        ),
        isVirgin: false,
        hasBlurred: true,
      });
    }
  }

  removeNumbersAndSymbols = (str: string) =>
    str.replace(NUMERICAL_AND_SYMBOLS, '');

  removeAlphabeticAndSymbols = (str: string) =>
    str.replace(ALPHABETICAL_AND_SYMBOLS, '');

  getCurrentError = (
    extraErrors: Array<ExtraError>,
    value: string,
    isSubmit?: boolean | null,
  ) => {
    const isEmpty = !value;
    const preventVirginError = isSubmit ? true : !this.state.isVirgin;
    let activeError;

    if (extraErrors.length > 0) {
      activeError = find(
        extraErrors,
        extraError => extraError.isError(value) === true,
      );
    }

    return isEmpty && this.props.required && preventVirginError
      ? 'empty'
      : activeError
        ? activeError.key
        : false;
  };
  handleBlur = () => {
    if (this.props.onInputChange) {
      this.props.onInputChange(
        this.state.value,
        this.getCurrentError(this.props.extraErrors, this.state.value),
      );
    }

    this.setState({
      hasBlurred: true,
      isVirgin: false,
      currentError: this.getCurrentError(
        this.props.extraErrors,
        this.state.value,
      ),
    });
  };
  handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { removeNumbersAndSymbols, onlyNumbers } = this.props;

    this.setState(
      {
        value: removeNumbersAndSymbols
          ? this.removeNumbersAndSymbols(e.target.value)
          : onlyNumbers
            ? this.removeAlphabeticAndSymbols(e.target.value)
            : e.target.value,
        currentError: this.getCurrentError(
          this.props.extraErrors,

          e.target.value,
        ),
      },
      () => {
        const value = removeNumbersAndSymbols
          ? this.removeNumbersAndSymbols(this.state.value)
          : onlyNumbers
            ? this.removeAlphabeticAndSymbols(this.state.value)
            : this.state.value;
        if (this.props.immediateEvaluation && this.props.onInputChange) {
          this.props.onInputChange(
            value,
            this.getCurrentError(this.props.extraErrors, value),
          );
        }
      },
    );
  };
  render() {
    const errors: ExtraError[] = [
      {
        key: 'empty',
        message:
          this.props.emptyErrorMessage ||
          polyglot.t('sign_up.default_input.error_ms'),
      },
    ].concat(this.props.extraErrors);

    const shouldDisplayErrors =
      (this.state.hasBlurred && !this.state.isVirgin) ||
      this.props.forceValidation;

    const inputError =
      this.state.hasBlurred && !this.state.isVirgin && this.state.currentError;
    return (
      <div
        className={classNames(
          styles['input-wrapper'],
          this.props.holderClassNames,
          this.props.holderClass && styles[this.props.holderClass],
        )}
        role='alert'
      >
        <label
          id='input-label'
          htmlFor={this.props.id}
          aria-label={this.props.placeholderText || this.props.labelText}
          className={classNames(
            styles.label,
            this.props.visuallyHideLabel && styles['label--hidden'],
          )}
        >
          {this.props.labelText}
          {this.props.labelText2 ? (
            <span className={styles.label2}>{this.props.labelText2}</span>
          ) : null}
        </label>
        <InputOrTextArea
          aria-required={this.props.required}
          aria-labelledby='input-label'
          autoComplete='off'
          disabled={this.props.isDisabled}
          id={this.props.id}
          type={this.props.type}
          // @ts-expect-error
          value={this.state.value}
          placeholder={this.props.placeholderText || this.props.labelText}
          name={this.props.id}
          maxLength={this.props.maxlength}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          minLength={this.props.minlength || null}
          isTextArea={this.props.isTextArea}
          className={classNames(
            'no-focus',
            styles['input'],
            inputError && styles['input-error'],
            this.props.inputClassName && styles[this.props.inputClassName],
          )}
        />
        {shouldDisplayErrors &&
          errors.reduce((result: ReactNode | null, extraError) => {
            if (result) {
              return result;
            }

            if (extraError && this.state.currentError === extraError.key) {
              return (
                <div className={styles['error-message']}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(extraError.message),
                    }}
                  />
                </div>
              );
            }

            return null;
          }, null)}
      </div>
    );
  }
}

export default DefaultInput;
