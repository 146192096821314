import type { OrderConfirmationResponse, OrderProps } from './state/types';
import noop from 'lodash/noop';
import { referrer as spotRewardsReferrer } from '../spot-rewards/view/spot-rewards-comp';
import { referrer as giftCardsReferrer } from '../gift-cards/gift-cards-comp';
import { referrer as cinemaReferrer } from '../cinemas/cinemas-comp';
import { AnalyticsEvent } from 'services/google-analytics/analytics-events';

export const titleMap = {
  [cinemaReferrer]: 'components.main_menu.cinemas',
  [giftCardsReferrer]: 'components.main_menu.gift_cards',
  [spotRewardsReferrer]: 'components.main_menu.rewards',
} as const;

const triggerGiftCardsPurchaseGAEvent = (
  order: OrderProps,
  orderRes: OrderConfirmationResponse,
) => {
  if (order.type !== 'giftcards') {
    return;
  }

  const { giftCard, items } = order;

  if (giftCard && items) {
    const getCategoryByIndex = (index: number) =>
      giftCard?.categories?.[index]?.id || '';

    const getOrderItems = items => {
      return items.map(item => ({
        item_id: item.sku || '',
        item_name: item.title,
        item_category: getCategoryByIndex(0),
        item_category2: getCategoryByIndex(1),
        item_category3: getCategoryByIndex(2),
        item_category4: getCategoryByIndex(3),
        item_category5: getCategoryByIndex(4),
        price: item.customizations.price.originalAmount,
        quantity: 1,
        discount: item.customizations.price.amount,
        affiliation: giftCard.provider,
        location_id: '',
      }));
    };

    AnalyticsEvent.Perks.GiftCard.Checkout.ConfirmPayment.clicked({
      content_type: 'GiftCard',
      transaction_id: orderRes.transaction_id,
      affiliation: giftCard.provider,
      currency: orderRes.transaction_currency,
      value: orderRes.transaction_amount,
      items: getOrderItems(items),
    });
  }
};

const triggerCinemaPurchaseGAEvent = (
  order: OrderProps,
  orderRes: OrderConfirmationResponse,
) => {
  if (order.type !== 'cinema') {
    return;
  }

  const { selectedCinema, items } = order;

  if (selectedCinema && items) {
    const getOrderItems = items => {
      return items.map(item => ({
        item_id: selectedCinema.merchant.id,
        item_name: item.title,
        item_brand: selectedCinema.merchant.name,
        item_category: selectedCinema.primary_category,
        price: item.customizations.price.amount,
        quantity: item.quantity,
        location_id: selectedCinema.name,
      }));
    };

    AnalyticsEvent.Perks.Cinema.Checkout.ConfirmPayment.clicked({
      content_type: 'Cinema',
      transaction_id: orderRes.transaction_id,
      affiliation: selectedCinema.merchant.name,
      currency: orderRes.transaction_currency,
      value: orderRes.transaction_amount,
      items: getOrderItems(items),
    });
  }
};

export const triggerGAEvent = (
  order: OrderProps,
  orderRes: OrderConfirmationResponse,
) => {
  switch (order.type) {
    case 'cinema':
      return triggerCinemaPurchaseGAEvent(order, orderRes);

    case 'giftcards':
      return triggerGiftCardsPurchaseGAEvent(order, orderRes);

    default:
      return noop;
  }
};
