import USER_ROLES from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';

export const getForm = () =>
  import(
    /* webpackChunkName: "confidential-support-request" */ 'app/pages/confidential-support-request/confidential-support-request-form'
  );
export const getCompletePage = () =>
  import(
    /* webpackChunkName: "confidential-support-request" */ 'app/pages/confidential-support-request/confidential-support-request-complete'
  );

const confidentialSupportRequestModule = {
  form: {
    name: 'confidentialSupportRequest',
    url: '/confidential-support-request',
    resolver: getForm,
    restrictions: {
      user: [restrictions['submit_confidential_request_for_support']],
    },
    data: {
      title: 'components.confidential_request_form.title',
      section: 'life',
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  complete: {
    name: 'confidentialSupportRequestComplete',
    url: '/confidential-support-request/complete',
    resolver: getCompletePage,
    restrictions: {
      user: [restrictions['submit_confidential_request_for_support']],
    },
    data: {
      title: 'components.confidential_request_complete.title',
      section: 'life',
      authorizedRoles: [USER_ROLES.employee],
    },
  },
} as const;

export default confidentialSupportRequestModule;
