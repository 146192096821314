import userRoles from 'constants/user-roles.constant';
import {
  programDetail,
  moduleDetail,
  programDetailOverview,
  programMenu,
} from './programs.constants';
import { applyRestrictions } from '../../router/auth-handler';
import { restrictions } from 'state/features-list/constants';

export function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "program-detail" */ './view/program-detail'),
  ]).then(([component]) => {
    return component;
  });
}

export const shouldGrantProgramsAccess = (shouldRedirect = false) => {
  const result = applyRestrictions(
    {
      user: [restrictions['digital_programs_self_signup']],
    },
    shouldRedirect,
  );

  return Promise.resolve(result);
};

const programsModule = {
  moduleDetail: {
    name: moduleDetail,
    url: '/programs/:programId/modules/:moduleId',
    resolver,
    shouldGrantAccess: shouldGrantProgramsAccess,
    data: {
      title: 'components.main_menu.well_being_hub',
      mainMenu: moduleDetail,
      section: 'life',
      includeInMenu: false,
      authorizedRoles: [userRoles.employee],
      hideFooter: true,
    },
  },
  programDetailOverview: {
    name: programDetailOverview,
    url: '/programs/:programId/overview/:moduleOverviewId',
    resolver,
    shouldGrantAccess: shouldGrantProgramsAccess,
    data: {
      title: 'components.main_menu.well_being_hub',
      mainMenu: programDetailOverview,
      section: 'life',
      includeInMenu: false,
      authorizedRoles: [userRoles.employee],
      hideFooter: true,
    },
  },
  programDetail: {
    name: programDetail,
    url: '/programs/:programId',
    resolver,
    shouldGrantAccess: shouldGrantProgramsAccess,
    data: {
      title: 'components.main_menu.well_being_hub',
      mainMenu: programDetail,
      section: 'life',
      includeInMenu: false,
      authorizedRoles: [userRoles.employee],
      hideFooter: true,
    },
  },
  programMenu: {
    name: programMenu,
    url: '/programs/:programId/menu',
    resolver,
    shouldGrantAccess: shouldGrantProgramsAccess,
    data: {
      title: 'components.main_menu.well_being_hub',
      mainMenu: programMenu,
      section: 'life',
      includeInMenu: false,
      authorizedRoles: [userRoles.employee],
      hideFooter: true,
    },
  },
};

export default programsModule;
