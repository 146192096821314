import { combineReducers } from 'redux';

// Reducers
import { companyReducer } from './company/reducer';
import { modalReducer } from './modal/reducer';
import { sessionReducer } from './session/reducer';
import { featuresListReducer } from './features-list/reducer';
import { toasterReducer } from 'toaster-comp/state/reducer';
import { withdrawReducer } from '../react-components/withdraw-modal/state/reducer';
import { companySettingsReducer } from './company-settings/reducer';
import { wellnessReducer } from './wellness/reducer';
import { globalReducer } from './global/reducer';
import { createResponsiveStateReducer } from 'redux-responsive';
import { networkStatusReducer } from './network-status/reducer';
import checkoutReducer from '../pages/checkout-braintree/state/reducer';
import signupReducer from 'pages/signup/main/state/reducer';
import headerReducer from '../react-components/header/state/reducers';
import headReducer from '../react-components/head/state/reducer';
import { trustedAppsAndWebsitesReducer } from 'pages/trusted-apps-and-websites/state/reducer';
import { consentReducer } from 'state/consent/reducer';

function createReducer(asyncReducers?) {
  return combineReducers({
    browser: createResponsiveStateReducer(
      {
        mobile: 767,
        tablet: 1023,
        desktop: 1599,
      },
      {
        extraFields: ({ breakpoints }) => {
          const baseFontSize = 16;
          const html = document.getElementsByTagName('html')[0] as HTMLElement;
          const htmlFontSize = parseFloat(
            window.getComputedStyle(html, null).getPropertyValue('font-size'),
          );

          const zoomRatio = htmlFontSize / baseFontSize;

          return {
            isAdjusted: {
              mobile: window.innerWidth <= breakpoints.mobile * zoomRatio,
              tablet:
                window.innerWidth <= breakpoints.tablet * zoomRatio &&
                window.innerWidth > breakpoints.mobile * zoomRatio,
              desktop:
                window.innerWidth <= breakpoints.desktop * zoomRatio &&
                window.innerWidth > breakpoints.tablet * zoomRatio,
            },
          };
        },
      },
    ),
    networkStatus: networkStatusReducer,
    global: globalReducer,
    session: sessionReducer,
    featuresList: featuresListReducer,
    company: companyReducer,
    toaster: toasterReducer,
    modal: modalReducer,
    signup: signupReducer,
    withdraw: withdrawReducer,
    companySettings: companySettingsReducer,
    wellness: wellnessReducer,
    header: headerReducer,
    head: headReducer,
    checkout: checkoutReducer,
    trustedAppsAndWebsites: trustedAppsAndWebsitesReducer,
    consent: consentReducer,
    ...asyncReducers,
  });
}

export default createReducer;
