import { client } from 'graphql/client';
import { MyCareRoutes } from 'pages/my-care/my-care.constants';
import { PreAssessmentDocument, PreAssessmentQuery } from 'graphql-types';
import { checkIfCareIssueIsSelected } from 'pages/care-issues/care-issues.helpers';

export const getNextAssessmentId = async () => {
  const { data } = await client.query<PreAssessmentQuery>({
    query: PreAssessmentDocument,
    fetchPolicy: 'network-only',
  });

  const nextAssessmentId = data?.preAssessment?.nextAssessment?.id;

  return nextAssessmentId;
};

export const shouldGrantAccessToCareAssessments = async () => {
  try {
    const hasCareIssueSelected = await checkIfCareIssueIsSelected();
    const nextAssessmentId = await getNextAssessmentId();

    if (!hasCareIssueSelected) {
      return {
        provideAccess: false,
        redirectTo: MyCareRoutes.MY_CARE_HUB,
      };
    }

    return {
      provideAccess: true,
      redirectTo: '',
      params: {
        assessmentId: nextAssessmentId,
      },
    };
  } catch {
    return {
      provideAccess: false,
      redirectTo: MyCareRoutes.MY_CARE_HUB,
    };
  }
};

export const shouldGrantAccessToRetake = async () => {
  const hasCareIssueSelected = await checkIfCareIssueIsSelected();

  return Promise.resolve(hasCareIssueSelected);
};
