// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** function to get dimensions
  usage example to get \`tablet\` value from \`\$page-max-widths\`
  .class {
    max-width: dim(\$page-max-widths, tablet);
  }

 */
.text-block-module__item___l_jF4:not(:last-of-type) {
  margin-bottom: 30px;
}
.text-block-module__item___l_jF4 a {
  text-decoration: underline;
  color: var(--primary600, #4b286d);
}
.text-block-module__item___l_jF4 a:hover {
  color: var(--primary700, #371e47);
}

.text-block-module__title___XlsGQ {
  font-size: 1.25rem;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./node_modules/wa-storybook/styles/tools/functions/_widths.scss","webpack://./client/app/react-components/perks/components/text-block.module.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;ACGE;EACE,mBAAA;AAKJ;AAFE;EACE,0BAAA;EACA,iCAAA;AAIJ;AAHI;EACE,iCAAA;AAKN;;AAAA;EACE,kBAAA;EACA,mBAAA;AAGF","sourcesContent":["/** function to get dimensions\n  usage example to get `tablet` value from `$page-max-widths`\n  .class {\n    max-width: dim($page-max-widths, tablet);\n  }\n\n */\n\n@function dim($prop, $key) {\n  @return map-get($prop, $key);\n}\n","@import '~wa-storybook/styles/utils';\n\n.item {\n  &:not(:last-of-type) {\n    margin-bottom: 30px;\n  }\n\n  a {\n    text-decoration: underline;\n    color: color(primary600);\n    &:hover {\n      color: color(primary700);\n    }\n  }\n}\n\n.title {\n  font-size: rem(20);\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `text-block-module__item___l_jF4`,
	"title": `text-block-module__title___XlsGQ`
};
export default ___CSS_LOADER_EXPORT___;
