import fetch from 'globals/wa-fetch';
import { restrictions } from 'state/features-list/constants';

import USER_ROLES from 'constants/user-roles.constant';

const svmGiftCardsModule = {
  url: '/svm-giftcards/:giftcard_id?reload&value',
  data: {
    title: 'components.main_menu.colleague_offers',
    authorizedRoles: [USER_ROLES.employee],
  },
  restrictions: {
    user: [restrictions['benefit_colleague_offer']],
  },
  shouldGrantAccess(_, params) {
    // TODO TEST THIS
    if (params['giftcard_id']) {
      fetch(`/gift-cards/${params['giftcard_id']}/purchase-url`, {
        method: 'post',
        token: true,
        version: 1.4,
        body: {
          value:
            params['value'] && params['value'] !== undefined
              ? parseInt(params['value'], 10)
              : 10,
          quantity: 1,
          is_reload:
            params['reload'] && params['reload'] !== undefined
              ? !!params['reload']
              : false,
        },
      })
        .then(res => {
          if (!res.error) {
            window.location = res.body.url;
            return Promise.resolve(res.body.url);
          }
          return Promise.reject();
        })
        .catch(() => {
          return Promise.reject();
        });
    }
  },
};

export default svmGiftCardsModule;
