import type { Model, UserAction, Interest } from './types';
import USER_ROLES from 'constants/user-roles.constant';

export const getInitialState = (): Model => {
  return {
    isLoading: false,
    role: 'all',
    user: null,
    interestList: [],
    fetching: false,
    isLoggingOut: false,
    loginError: '',
  };
};

export function sessionReducer(
  state = getInitialState(),
  action: UserAction,
): Model {
  switch (action.type) {
    case 'SESSION/LOADING_STARTED':
      return {
        ...state,
        isLoading: true,
      };

    case 'SESSION/LOADING_COMPLETED':
      return {
        ...state,
        isLoading: false,
      };

    case 'SESSION/SET_USER':
      return {
        ...state,
        user: action.newUser,
        role: 'employee',
        interestList: action.newUser['interest_list'].slice(),
        fetching: false,
        loginError: '',
      };

    case 'SESSION/RESET_USER':
      return getInitialState();

    case 'SESSION/UPDATE_USER_REQUEST':
      return Object.assign({}, state, { fetching: true });

    case 'SESSION/UPDATE_USER_SUCCESS':
      return {
        ...state,
        user: { ...state.user, ...action.newUser },
        interestList: action.newUser['interest_list'].slice(),
        role: 'employee',
        fetching: false,
        loginError: '',
      };

    case 'SESSION/UPDATE_USER_ERROR':
      return Object.assign({}, state, { fetching: false });

    case 'SESSION/CHANGE_INTEREST':
      const { interest } = action;

      let interestList = state.interestList.filter(
        (item: Interest) => item['interest_id'] !== interest['interest_id'],
      );

      if (state.interestList.length === interestList.length) {
        interestList = interestList.concat(interest);
      }

      return { ...state, interestList };

    case 'SESSION/RESET_INTERESTS':
      const interests = state.user ? state.user['interest_list'].slice() : [];

      return { ...state, interestList: interests };

    case 'SESSION/IS_LOGGING_OUT':
      return {
        ...state,
        isLoggingOut: action.payload,
      };

    case 'SESSION/LOGIN_USER':
      return Object.assign({}, state, { fetching: true });
    case 'SESSION/LOGIN_USER_SUCCESS':
      return Object.assign({}, state, { fetching: false });
    case 'SESSION/LOGIN_USER_FAILURE':
      return Object.assign({}, state, {
        fetching: false,
        loginError: action.loginError,
      });
    case 'SESSION/LOGOUT_USER':
      return Object.assign({}, state, {
        role: USER_ROLES.all,
        user: null,
        fetching: false,
      });

    default:
      return state;
  }
}
