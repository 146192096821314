import userRoles from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';
import { programsAll } from './constants';

function programsResolver() {
  return Promise.all([
    import(
      /* webpackChunkName: "programs-all" */ './containers/programs-all/programs-all.container'
    ),
  ]).then(([component]) => {
    return component;
  });
}

const programsAllModule = {
  name: programsAll,
  url: '/programs',
  resolver: programsResolver,
  restrictions: {
    user: [restrictions.digital_programs_self_signup],
  },
  data: {
    title: 'components.main_menu.programmes',
    order: 1,
    mainMenu: 'programsAll',
    section: 'wellBeing',
    includeInMenu: true,
    authorizedRoles: [userRoles.employee],
    hideFooter: true,
  },
};

export default programsAllModule;
