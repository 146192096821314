import type { Dispatch } from 'globals/types';
import type { Model } from '../state/types';

import { getUrlParameter } from 'router/navigation';
import { openZendeskLinkWithToken } from 'app/state/global/actions';
import { GIFT_CARD_HELP_LINK } from '../../shared/constants';

import PerksPage from 'perks/layouts/full-width';

import OneThirdPerksPage from 'perks/layouts/one-third';

import TwoThirdsPerksPage from 'perks/layouts/two-thirds';

import TextBlock from 'perks/components/text-block';

import NeedHelp from 'perks/components/need-help/need-help';
import CustomInner from '../../../../react-components/perks/components/custom-inner';

import BuyGiftCard from './components/buy-gift-card/buy-gift-card';
import GiftCardOffer from '../../shared/gift-card-offer';

import { reset as resetGiftCard, getGiftCard } from '../state/actions';

import Spinner from 'wa-storybook/components/general/spinner/spinner';
import PageTitle from 'head/state/actions';
import { referrer as spotRewardsReferrer } from '../../../spot-rewards/view/spot-rewards-comp';

type Props = {
  balance?: number;
  details: Model;
  dispatch: Dispatch;
  referrer: string;
};

export default class GiftCardsDetailsPage extends React.Component<Props> {
  static displayName = 'GiftCardsDetailsPage';

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(
      getGiftCard(getUrlParameter('id'), this.props.referrer),
    );
  }

  componentDidUpdate() {
    this.updatePageTitle();
  }

  componentWillUnmount() {
    this.props.dispatch(resetGiftCard());
  }

  updatePageTitle = () => {
    const {
      details: { giftCard: { title } = {} },
      referrer,
      dispatch,
    } = this.props;

    if (!title) return;

    dispatch(
      PageTitle.setCurrentSection(
        `${title} - ${polyglot.t(
          referrer === spotRewardsReferrer
            ? 'components.main_menu.rewards'
            : 'components.main_menu.gift_cards',
        )}`,
      ),
    );
  };

  renderInner = () => {
    const {
      details: {
        giftCard: { title, description },
      },
    } = this.props;
    return (
      <CustomInner
        title={title ? title : ''}
        description={description ? description : ''}
      />
    );
  };

  render() {
    const {
      balance,
      details: { fetching, giftCard, invalidValue, order },
      dispatch,
      referrer,
    } = this.props;

    const { description, terms_and_conditions: terms } = giftCard || {};
    const deliveryInformation = polyglot.t('giftcards.delivery_information');

    const lwTerms = `<b>${polyglot.t(
      'reward_details.details_comp.lifeworks_tc',
    )}</b><br>${polyglot.t('giftcards.terms_conditions')}<br><br>`;

    const combined = terms
      ? `${lwTerms}<b>${polyglot.t(
          'reward_details.details_comp.provider_tc',
        )}</b><br>${terms}`
      : lwTerms;

    return (
      <PerksPage>
        {fetching || !giftCard ? (
          <Spinner />
        ) : (
          <div>
            <OneThirdPerksPage
              aria-label={polyglot.t('shop_online.aria.store_information')}
              className='one-third-page--gift-card-details'
            >
              <GiftCardOffer
                giftCard={giftCard}
                renderInner={this.renderInner}
                className='card--gift-card-details'
                overlayClassName='overlay--gift-card-details'
                overlayImageClassName='overlay-image--gift-card-details'
                referrer={referrer}
              />
              <NeedHelp
                isOnHub={false}
                className={'container--gift-card-details'}
                action={() =>
                  dispatch(openZendeskLinkWithToken(GIFT_CARD_HELP_LINK))
                }
                label={window.polyglot.t('giftcards.ask_question')}
                title={`${window.polyglot.t(
                  'giftcards.need_help',
                )} ${window.polyglot.t('giftcards.cant_find')}`}
              />
            </OneThirdPerksPage>
            <TwoThirdsPerksPage className='two-thirds-page--gift-card-details'>
              <BuyGiftCard
                invalidValue={invalidValue}
                giftCard={giftCard}
                order={order}
                dispatch={dispatch}
                balance={balance}
                referrer={referrer}
              />
              <TextBlock
                items={[
                  {
                    title: polyglot.t('giftcards.delivery_information_title'),
                    text:
                      deliveryInformation ||
                      polyglot.t('giftcards.no_delivery_information'),
                  },
                  {
                    title: polyglot.t('giftcards.terms_conditions_title'),
                    text:
                      combined ||
                      polyglot.t('giftcards.no_terms_and_conditions'),
                  },
                  {
                    title: polyglot.t('giftcards.about_retailer_title'),
                    text: description || polyglot.t('giftcards.no_description'),
                  },
                ]}
              />
            </TwoThirdsPerksPage>
          </div>
        )}
      </PerksPage>
    );
  }
}
