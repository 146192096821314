export const moveChildKeys = (obj: Window['WAM']['ENV']) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const result = {};

  for (const key in obj) {
    if (key === '<<') {
      // If the key is '<<' which is the 'spread' for YAML, move its child keys up one level
      const childObj = obj[key];
      for (const childKey in childObj) {
        result[childKey] = childObj[childKey];
      }
    } else {
      // Recursively call for non-'<<' keys
      result[key] = moveChildKeys(obj[key]);
    }
  }

  if (result['<<']) {
    // double check that we for sure got all cases of obj['<<'] as in the case of 'integration'
    // the initial pass didn't seem to be enough since it spreads testVars first
    return moveChildKeys(result as Window['WAM']['ENV']);
  } else {
    return result;
  }
};
