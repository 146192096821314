import { UsefulLink } from './types';

export const GET_PAYMENT_DETAILS_START = 'SETTINGS/GET_PAYMENT_DETAILS_START';
export const GET_PAYMENT_DETAILS_SUCCESS =
  'SETTINGS/GET_PAYMENT_DETAILS_SUCCESS';
export const GET_PAYMENT_DETAILS_REJECTED =
  'SETTINGS/GET_PAYMENT_DETAILS_REJECTED';
export const REMOVE_PAYMENT = 'SETTINGS/REMOVE_PAYMENT';
export const REMOVE_PAYMENT_SUCCESS = 'SETTINGS/REMOVE_PAYMENT_SUCCESS';
export const REMOVE_PAYMENT_REJECTED = 'SETTINGS/REMOVE_PAYMENT_REJECTED';
export const REMOVE_WITHDRAWAL = 'SETTINGS/REMOVE_WITHDRAWAL';
export const REMOVE_WITHDRAWAL_SUCCESS = 'SETTINGS/REMOVE_WITHDRAWAL_SUCCESS';
export const REMOVE_WITHDRAWAL_REJECTED = 'SETTINGS/REMOVE_WITHDRAWAL_REJECTED';

// All links for zendesk live inside web-localise under en_GB-shared.json
// They can be found "zendesk" base path.
// We need to add keys for both `live` and `test`. The key name MUST match.
// Then, those changes need to be merged,translated and deployed in order for
// this to work properly.
// We need to only pass the key name

export const COMMON_LINKS: UsefulLink[] = [
  {
    type: 'link',
    label: 'settings.links.label_2',
    link: 'help_centre',
    dependantFeatures: [
      {
        name: 'originator_app_support',
        value: false,
      },
    ],
  },
  {
    type: 'button',
    buttonName: 'appSupport',
    label: 'settings.links.label_6',
    dependantFeatures: [
      {
        name: 'originator_app_support',
        value: true,
      },
    ],
  },
  {
    type: 'link',
    label: 'settings.links.label_3',
    link: 'privacy_policy',
  },
  {
    type: 'link',
    label: 'settings.links.label_4',
    link: 'user_terms',
  },
];

export const ALL_LINKS: UsefulLink[] = [
  ...COMMON_LINKS,
  {
    type: 'link',
    label: 'settings.links.label_5',
    link: 'delete_account',
    dependantFeatures: [
      {
        name: 'originator_app_support',
        value: false,
      },
    ],
  },
  {
    type: 'button',
    label: 'settings.links.label_5',
    buttonName: 'deleteAccount',
    dependantFeatures: [
      {
        name: 'originator_app_support',
        value: true,
      },
    ],
  },
];
