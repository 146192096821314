import USER_ROLES from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';
import { JournalingRoutes } from './journaling.constants';

const createResolver = pageImport =>
  Promise.all([pageImport]).then(([component]) => {
    return component;
  });

const journalingModule = {
  journalingHistory: {
    name: JournalingRoutes.HISTORY,
    url: '/journal/history',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "journal-history" */ '../journaling/container/journal-history/journal-history'
        ),
      ),
    restrictions: {
      user: [
        restrictions['care_plans'],
        restrictions['counsellor_appointments'],
        restrictions['care_coordination_specialists_messaging'],
      ],
      operator: 'OR',
    },
    data: {
      title: 'components.main_menu.journal_history',
      section: 'myCare',
      mainMenu: 'myCare',
      includeInMenu: false,
      hideFooter: true,
      order: 0,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
  journalingNewEntry: {
    name: JournalingRoutes.NEW_ENTRY,
    url: '/journal/new-entry',
    resolver: () =>
      createResolver(
        import(
          /* webpackChunkName: "journal-new-entry" */ '../journaling/container/new-entry/new-entry'
        ),
      ),
    restrictions: {
      user: [
        restrictions['care_plans'],
        restrictions['counsellor_appointments'],
        restrictions['care_coordination_specialists_messaging'],
      ],
      operator: 'OR',
    },
    data: {
      title: 'components.main_menu.journal_new_entry',
      section: 'myCare',
      mainMenu: 'myCare',
      includeInMenu: false,
      hideFooter: true,
      order: 0,
      authorizedRoles: [USER_ROLES.employee],
    },
  },
};

export default journalingModule;
