import USER_FIELDS from 'constants/user-fields.constant';
import {
  isSharedAccount,
  isDependantAccount,
  getUser,
} from 'stores/user-store';
import { isLimitedAccount } from 'state/session/helpers';
import type { Company } from './types';
import { UserFields } from 'pages/directory/state/types';

export function mapUserFields(data: any): Partial<UserFields> | boolean {
  return Object.keys(data).reduce((accum, key) => {
    // Override available properties based on the account type.
    // This is so that companies who use SSO(and would therefore have the editing of these fields disabled)
    // can still allow shared users to create limited accounts, and dependant users to edit their profiles.
    if (
      isSharedAccount() ||
      isDependantAccount(getUser()) ||
      isLimitedAccount(getUser())
    ) {
      const nonPersonalAccountOverrides = [
        USER_FIELDS.first_name,
        USER_FIELDS.last_name,
        USER_FIELDS.email,
      ];

      if (nonPersonalAccountOverrides.includes(key)) {
        accum[USER_FIELDS[key]] = true;
        return accum;
      }
    }

    accum[USER_FIELDS[key] || key] = !(data[key] || []).length;

    return accum;
  }, {});
}

export function isEmpty(company: Company) {
  return company['users_total'] === 1;
}
