import type { Dispatch, ThunkedAction } from 'globals/types';
import type { FeaturesList } from './types';
import { updateMenu } from '../menu/actions';
import { initFeed } from '../../pages/feed/state/actions';

/**
 * This function exists for the purpose of accounts that have a different
 * set of permissions than the company itself, e.g. limited accounts.
 * This function runs once the user is logged in
 *
 * `./client/app/run.js` loginFromStorageToken()
 * `./client/app/state/session/actions.js` login()
 */
export function updateFeaturesList(featuresList: FeaturesList): ThunkedAction {
  return function (dispatch: Dispatch) {
    dispatch({ type: 'FEATURES_LIST/UPDATE_FEATURES_LIST', featuresList });
    dispatch(updateMenu(featuresList));
    dispatch(initFeed());
  };
}
