import { isSharedAccount } from 'stores/user-store';
import USER_ROLES from 'constants/user-roles.constant';
import { injectAsyncReducer } from 'app/hermes-redux';

function resolver() {
  return Promise.all([
    import(/* webpackChunkName: "profile" */ './view/profile-comp'),
    import(/* webpackChunkName: "profile" */ './state/reducer'),
  ]).then(([component, reducer]) => {
    injectAsyncReducer('profile', reducer.profileReducer);
    return component;
  });
}

const profileModule = {
  name: 'profile',
  url: '/profile/:userId?',
  shouldGrantAccess() {
    return Promise.resolve(!isSharedAccount());
  },
  resolver,
  data: {
    title: 'components.main_menu.profile',
    section: 'myAccount',
    mainMenu: 'profile',
    includeInMenu: true,
    authorizedRoles: [USER_ROLES.employee],
    shortcutKeyCode: 55,
    hideFooter: true,
  },
};

export default profileModule;
