import type { ToasterAction, Model } from './types';

export const initialState = {
  toasts: [],
};

export function toasterReducer(
  state: Model = initialState,
  action: ToasterAction,
): Model {
  const { toasts } = state;

  switch (action.type) {
    case 'TOASTER/RESET_TOAST':
      return initialState;
    case 'TOASTER/HIDE_TOAST':
      return {
        toasts: toasts.filter(toast => toast.id !== action.toast.id),
      };
    case 'TOASTER/SHOW_TOAST':
      return {
        toasts: Array.prototype.concat([], toasts, action.toast),
      };
    default:
      return state;
  }
}
