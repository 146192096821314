import styles from './perks-block.module.scss';
import classnames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  title?: string;
  children: ReactNode;
  'aria-label'?: string;
  noBorder?: boolean;
};

const PerksBlock = (props: Props) => (
  <section
    aria-label={props['aria-label']}
    className={classnames(styles.container, props.noBorder && styles.noBorder)}
  >
    {props.title && <div className={styles.title}>{props.title}</div>}
    {props.children}
  </section>
);

PerksBlock.displayName = 'PerksBlock';

export default PerksBlock;
