import styles from './carousel.module.scss';
import classNames from 'classnames';
import { resolveClassNames } from 'wa-storybook/helpers/classes';
import ChevronNext from 'wa-storybook/components/general/icon/components/chevron-next';
import ChevronBack from 'wa-storybook/components/general/icon/components/chevron-left';

type Props = {
  isPrev: boolean;
  onClick?: () => void;
  arrowClassName?: string | Array<string>;
  isFeedFeaturedArticles?: boolean;
};

const CarouselArrow = ({
  isPrev,
  onClick,
  arrowClassName,
  isFeedFeaturedArticles,
}: Props) => {
  if (!onClick) return null;

  const Chevron = isPrev ? ChevronBack : ChevronNext;

  return (
    <button
      aria-hidden
      tabIndex={-1}
      // This will prevent the focus when clicking an element
      onMouseDown={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
      onClick={onClick}
      className={classNames(
        styles['arrow-container'],
        isPrev
          ? classNames(
              styles['arrow-container--prev'],
              isFeedFeaturedArticles && styles['feed-featured-articles--prev'],
            )
          : styles['arrow-container--next'],
        resolveClassNames(arrowClassName, styles),
      )}
      data-hook={isPrev ? 'prev-arrow' : 'next-arrow'}
    >
      <div className={styles['arrow-container__content']}>
        <div className={styles['arrow-container__icon']}>
          <Chevron />
        </div>
      </div>
    </button>
  );
};

CarouselArrow.displayName = 'CarouselArrow';

export default CarouselArrow;
