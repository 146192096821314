import type { OrderProps, BtNonce } from './types';
import {
  updateCheckoutAction,
  tokenReceivedAction,
  requestTokenAction,
  tokenFailedAction,
  submitOrderAction,
  submitSpotRewardsOrderAction,
  orderFulfilledAction,
  orderFailedAction,
  resetAction,
  resetTokenAction,
} from './constants';
import { Action } from 'globals/types';

export const resetState = () => ({
  type: resetAction,
});

export const resetToken = () => ({
  type: resetTokenAction,
});

export const updateCheckout = (order: OrderProps): Action => ({
  type: updateCheckoutAction,
  order,
});

export const requestToken = () => ({ type: requestTokenAction });

export const tokenReceived = (token: string) => ({
  type: tokenReceivedAction,
  token,
});

export const tokenFailed = () => ({ type: tokenFailedAction });

export const submitOrder = (
  btNonce: BtNonce,
  order: OrderProps,
  email: string,
) => ({ type: submitOrderAction, btNonce, order, email });

export const submitSpotRewardsOrder = (
  order: OrderProps,
  paymentMethod: any,
  email: string,
) => ({ type: submitSpotRewardsOrderAction, order, paymentMethod, email });

export const orderFulfilled = (id: string) => ({
  type: orderFulfilledAction,
  id,
});

export const orderFailed = (error: string) => ({
  type: orderFailedAction,
  error,
});
