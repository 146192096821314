import { restrictions } from 'state/features-list/constants';
import USER_ROLES from 'constants/user-roles.constant';

const wellnessCategoriesModule = {
  name: 'wellnessCategories',
  url: '/wellness/categories',
  resolver: () =>
    import(
      /* webpackChunkName: "wellness-categories" */ './wellness-categories-comp'
    ),
  restrictions: {
    user: [restrictions['eap_wellness_category_limit']],
  },
  data: {
    title: 'components.main_menu.wellness_categories',
    includeInMenu: false,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default wellnessCategoriesModule;
