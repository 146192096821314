import Button from 'wa-storybook/components/general/button/button';

import styles from './need-help.module.scss';
import classNames from 'classnames';

export type Props = {
  isOnHub?: boolean;
  title?: string;
  action?: () => void;
  dataHook?: string;
  description?: string;
  label?: string;
  className?: string;
  titleClassName?: string;
  isDark?: boolean;
  icon?: React.FC<any>;
  link?: {
    href: string;
    text: string;
  };
};

const NeedHelp = (props: Props) => {
  const { className, dataHook, description, isOnHub, icon } = props;
  return (
    <div
      className={classNames(
        styles.container,
        isOnHub && styles['container--hub'],
        Array.isArray(className)
          ? className.map(className => styles[className])
          : className && styles[className],
      )}
    >
      <h4
        className={classNames(
          styles.header,
          isOnHub && styles['header--hub'],
          props.titleClassName && styles[props.titleClassName],
        )}
      >
        {props.title}
      </h4>
      {description && <div className={styles.description}>{description}</div>}
      {props.action && props.label && (
        <div
          className={classNames(
            styles.button,
            isOnHub && styles['button--hub'],
          )}
        >
          <Button
            dataHook={dataHook || 'need-help'}
            onClick={props.action}
            text={props.label}
            theme={
              props.isDark ? 'contained--on-primary' : 'outlined--on-surface'
            }
            icon={icon}
          />
        </div>
      )}
      {props.link && (
        <a href={props.link.href}>
          <div className={styles['container__help-link']}>
            {props.link.text}
          </div>
        </a>
      )}
    </div>
  );
};

NeedHelp.displayName = 'NeedHelp';

export default NeedHelp;
