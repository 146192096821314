import classNames from 'classnames';
import styles from './code-comp.module.scss';

type Props = {
  title?: string;
  codes: Array<string>;
  instruction?: string;
  className?: string;
};

const CodeComp = (props: Props) => {
  return (
    <div className={classNames(props.className)}>
      {props.title && (
        <div className={classNames(styles.title)}>{props.title}</div>
      )}
      {props.codes.map((code: string, index: number) => (
        <div
          className={classNames(styles.codeContainer)}
          key={index}
          tabIndex={0}
          aria-label={code}
        >
          <span className={classNames(styles.code)}>{code}</span>
        </div>
      ))}
      {props.instruction && (
        <div className={classNames(styles.instruction)}>
          {props.instruction}
        </div>
      )}
    </div>
  );
};

export default CodeComp;
