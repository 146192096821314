import USER_ROLES from 'constants/user-roles.constant';

const redirectingModule = {
  name: 'redirecting',
  url: '/redirecting',
  resolver: () => import(/* webpackChunkName: "redirecting" */ './redirecting'),
  data: {
    hideNavbar: true,
    title: 'components.main_menu.redirecting',
    authorizedRoles: [USER_ROLES.all],
  },
};

export default redirectingModule;
