import { FeedAction, Model } from './types';
import {
  concatPosts,
  insertPosts,
  findPostIndexWithId,
  getIsAllPostsNew,
} from './helpers';
import GROUPS from 'pages/groups/state/constants';
import { Group } from 'globals/types';

export enum EVENT {
  RESET = 'FEED/RESET',
  RESET_FEED = 'FEED/RESET_FEED',
  UPDATE_FEED = 'FEED/UPDATE_FEED',
  RESET_UPDATE_CACHE = 'FEED/RESET_UPDATE_CACHE',
  INIT_FEED = 'FEED/INIT',
  UPDATE_DETAILED_POST = 'FEED/UPDATE_DETAILED_POST',
  UPDATE_POST = 'FEED/UPDATE_POST',
  UPDATE_SUBSCRIBED_GROUPS = 'FEED/UPDATE_SUBSCRIBED_GROUPS',
  UPDATE_ACTIVE_GROUP = 'FEED/UPDATE_ACTIVE_GROUP',
  ADD_NEW_POST = 'FEED/ADD_NEW_POST',
  SET_PAGE_POSITION = 'FEED/SET_PAGE_POSITION',
  RESET_POST_DETAIL = 'FEED/RESET_POST_DETAIL',
  FETCH_INSTORE_OFFER = 'FEED/FETCH_INSTORE_OFFER',
  FETCH_INSTORE_OFFER_SUCCESS = 'FEED/FETCH_INSTORE_OFFER_SUCCESS',
  FETCH_INSTORE_OFFER_FAILURE = 'FEED/FETCH_INSTORE_OFFER_FAILURE',
  FETCH_FEATURED_PERKS_ARTICLE = 'FEED/FETCH_FEATURED_PERKS_ARTICLE',
  FETCH_FEATURED_PERKS_ARTICLE_SUCCESS = 'FEED/FETCH_FEATURED_PERKS_ARTICLE_SUCCESS',
  FETCH_FEATURED_PERKS_ARTICLE_FAILURE = 'FEED/FETCH_FEATURED_PERKS_ARTICLE_FAILURE',
}

const initFeedState = {
  feedPosts: [],
  paging: {
    limit: 5,
    offsetRef: '',
    offset: 0,
    totalCount: 0,
    isLastPage: false,
  },
  postDetails: {},
  isNoPosts: true,
  isListLoaded: false,
  pagePosition: 0,
  updateCache: false,
};

export const initialState: Model = {
  ...initFeedState,
  currentFilter: null,
  dropdownFilters: [],
  defaultFilter: {
    name: '',
    feature: null,
    value: '',
  },
  group: {} as Group,
  subscribedGroups: null,
  offer: {
    fetching: false,
    instoreOffers: [],
    totalCount: 0,
  },
  featuredPerksArticle: {
    resources: null,
    fetching: false,
    error: null,
  },
};

export function feedReducer(
  state: Model = initialState,
  action: FeedAction,
): Model {
  const { feedPosts, isNoPosts, paging } = state;

  switch (action.type) {
    case EVENT.RESET:
      return initialState;

    case EVENT.RESET_FEED:
      return { ...state, ...initFeedState, currentFilter: action.filter };

    case EVENT.RESET_POST_DETAIL:
      return { ...state, postDetails: {} };

    case EVENT.INIT_FEED:
      return {
        ...state,
        currentFilter: null,
        dropdownFilters: [],
        defaultFilter: {},
        group: {},
        subscribedGroups: null,
        ...action.initData,
      };

    case EVENT.UPDATE_SUBSCRIBED_GROUPS:
      const subscribedGroups = action.groups.filter(
        (group: any) => group.state === GROUPS.STATE.ACTIVE,
      );

      return { ...state, subscribedGroups };

    case EVENT.UPDATE_ACTIVE_GROUP:
      const group =
        action.group.state === GROUPS.STATE.ACTIVE ? action.group : {};

      return { ...state, group };

    case EVENT.UPDATE_FEED:
      const { data, filter, updateCache } = action;

      const isAllPostsNew = getIsAllPostsNew(feedPosts, data.body);

      return {
        ...state,
        currentFilter: filter,
        feedPosts: concatPosts(feedPosts, data.body, updateCache),
        isListLoaded: true,
        isNoPosts: isNoPosts && !(data.body || []).length,
        paging:
          updateCache && !isAllPostsNew
            ? paging
            : {
                ...paging,
                offsetRef: data.paging['offset_ref'],
                isLastPage: data.paging['is_last_page'],
                totalCount: data.paging['total_count'],
              },
        updateCache,
      };

    case EVENT.RESET_UPDATE_CACHE:
      return { ...state, updateCache: false };

    case EVENT.ADD_NEW_POST:
      return { ...state, feedPosts: insertPosts(feedPosts, [action.post]) };

    case EVENT.UPDATE_DETAILED_POST:
      return { ...state, postDetails: action.post };
    case EVENT.UPDATE_POST:
      const postIndex = findPostIndexWithId(feedPosts, action.post['post_id']);
      const posts = ([] as any[]).concat(
        feedPosts.slice(0, postIndex),
        action.post,
        feedPosts.slice(postIndex + 1),
      );

      return { ...state, feedPosts: posts };

    case EVENT.SET_PAGE_POSITION:
      return { ...state, pagePosition: action.pagePosition };

    case EVENT.FETCH_INSTORE_OFFER:
      return {
        ...state,
        offer: {
          ...action.payload,
        },
      };
    case EVENT.FETCH_INSTORE_OFFER_SUCCESS:
      return {
        ...state,
        offer: {
          ...action.payload,
        },
      };
    case EVENT.FETCH_INSTORE_OFFER_FAILURE:
      return {
        ...state,
        offer: {
          ...action.payload,
        },
      };
    case EVENT.FETCH_FEATURED_PERKS_ARTICLE:
      return {
        ...state,
        featuredPerksArticle: {
          ...state.featuredPerksArticle,
          fetching: true,
        },
      };
    case EVENT.FETCH_FEATURED_PERKS_ARTICLE_SUCCESS:
      return {
        ...state,
        featuredPerksArticle: {
          fetching: false,
          resources: action.payload.resources,
          error: null,
        },
      };
    case EVENT.FETCH_FEATURED_PERKS_ARTICLE_FAILURE:
      return {
        ...state,
        featuredPerksArticle: {
          fetching: false,
          resources: null,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}
