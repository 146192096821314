import styles from './one-third.module.scss';
import classNames from 'classnames';

type Props = {
  children?: any;
  style?: any;
  className?: string;
  ['aria-label']?: string;
};

const OneThirdPage = (props: Props) => (
  <section
    aria-label={props['aria-label']}
    className={classNames(
      styles['one-third-page'],
      props.className && styles[props.className],
    )}
    style={props.style}
  >
    {props.children}
  </section>
);

OneThirdPage.displayName = 'OneThirdPage';

export default OneThirdPage;
