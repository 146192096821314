/**
 *  @module Spot Rewards Card
 *  @overview Rewards card
 */

import CardRewards from 'wa-storybook/components/general/card/card-rewards';
import cloudinary from 'globals/cloudinary';
import { getFullPrice } from 'globals/helpers';
import { format } from 'date-fns';

type Props = {
  article: {
    details: {
      first_name: string;
      image_profile: {
        url: string;
      };
      user_id: string;
      last_name: string;
    };
    target_settlement: {
      amount: number;
    };
    date: number;
  };
};

const SpotRewardsCard = (props: Props) => {
  const isRetinaScreen = window.devicePixelRatio
    ? window.devicePixelRatio > 1
    : false;
  const filter = isRetinaScreen ? `c_fill,w_112,h_112` : `c_fill,w_56,h_56`;
  const {
    article: {
      date,
      details,
      details: {
        first_name: firstName,
        image_profile: { url: imageUrl } = {},
        last_name: lastName,
      } = {},
      target_settlement: targetSettlement,
    },
  } = props;

  const avatar =
    typeof details === 'object'
      ? {
          imageUrl: cloudinary(imageUrl, filter),
          title: `${firstName} ${lastName}`,
        }
      : undefined;

  return (
    <CardRewards
      avatar={avatar}
      amount={`${getFullPrice(targetSettlement.amount / 100)} ${polyglot.t(
        'rewards.components.reward',
      )}`}
      date={format(new Date(date * 1000), 'MM/dd/yyyy')}
      imageUrl={require('./../../assets/icn-spot-rewards.svg')}
      type={polyglot.t('rewards.components.title')}
    />
  );
};

SpotRewardsCard.displayName = 'SpotRewardsCard';

export default SpotRewardsCard;
