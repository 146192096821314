import { getStore } from 'app/hermes-redux';
import { performTenancyLookup, lookupKeys } from '../globals/regional-api';
import * as CompanyActions from 'state/company/actions';
import * as GlobalActions from 'state/global/actions';
import { mapUserFields, isEmpty } from 'app/state/company/helpers';
import { getCompanySettings as reduxGetCompanySettings } from 'state/company-settings/actions';
import fetch from 'globals/wa-fetch';
import PageTitle from 'head/state/actions';
import { getSubdomainFromHost } from 'router/navigation';
import Language from 'language';
import { AppLogger } from '@workivate/tho-web-shared';
// TODO: for now the company store is linked to redux state, should be removed soon

export function setCompany(comp, initialiseCompany?) {
  const dispatch = getStore().dispatch;
  if (comp['nickname']) {
    dispatch(PageTitle.setCompanyName(comp['nickname']));
  }
  dispatch(CompanyActions.setCompany(comp));

  if (initialiseCompany) {
    dispatch(GlobalActions.initialiseCompany(true));
  }
}

export function getCompany() {
  return getStore().getState().company || {};
}

export function getProfileVisibility() {
  const visibility = getCompany()['user_profile_visibility'] || {};

  return mapUserFields(visibility);
}

export function getProfileEditability() {
  const editability = getCompany()['user_profile_editability'] || {};

  return mapUserFields(editability);
}

export function getCompanyCreatedOn() {
  const currentCompany = getCompany();

  return currentCompany['created_on'];
}

export function isCompanyEmpty() {
  return isEmpty(getCompany());
}

export function isGroupingFeature(feature) {
  return getStore().getState().companySettings.grouping[feature];
}

export function getCompanySettings() {
  return getStore().dispatch(reduxGetCompanySettings(getCompany()));
}

export const setPreLoginCompanyDetails = async (newLocale?: string) => {
  const companyResponse = await fetchPreLoginCompanyDetails(
    getSubdomainFromHost(),
  );

  const { branding: company, sso } = companyResponse.body;

  if (company?.status === 200) {
    if (sso?.status === 200) {
      company.sso = sso;
    }

    setCompany(company);
  } else {
    // Set an empty object if the company wasn't returned from the lookup service
    setCompany({});
  }
  await Language.set({
    locale: newLocale,
    resetGlobalContent: false,
  });
};

const fetchPreLoginCompanyDetails = subdomain =>
  performTenancyLookup(lookupKeys.domain, subdomain, Language.getLocale());

export const fetchCompany = companyID => {
  if (!companyID) {
    const error = 'no company id passed';

    AppLogger.error(error);

    return Promise.reject(error);
  }

  return fetch(`/companies/${companyID}`, {
    token: true,
    version: 1.3,
  });
};

// data: could be 'nickname', 'image_logo', 'image_cover', 'image_square_logo'
export function getBrandedData(keyName) {
  const { company } = getStore().getState();
  const asset = company[keyName];
  const hasAsset = !!company && !!asset;

  // only if nickname we want to return a string, because nickname is just a string
  if (keyName === 'nickname' && !hasAsset) return '';

  if (!hasAsset) return {};

  // if object we have an image and the server is returning an object for images
  // if nickname just return the string as it is
  // as ''.url === undefined => true this condition will return the string if no .url property in the string.
  return asset;
}
