import ReactDOM from 'react-dom/client';

import queryString from 'query-string';
import { calculateResponsiveState } from 'redux-responsive';
import isEmpty from 'lodash/isEmpty';

import { getStore, initializeStore } from 'app/hermes-redux';
import { createSubscription } from 'hermes-redux.helpers';

import { updateUser, logout } from 'state/session/actions';
import { startPostLoginFlow } from './state/post-login/actions';
import { SIGNUP_CODES } from 'pages/signup/constants';
import { setUser, getUser, getMe } from 'stores/user-store';
import * as CompanyActions from 'stores/company-store';
import { updateFeaturesList } from 'state/features-list/actions';

import rootSagas from './state/sagas';
import { goTo, getSubdomainFromHost, history } from 'router/navigation';
import {
  get as getStorage,
  setPersistence,
  isLocalStorageSupported,
  remove as removeFromStorage,
  getKeys as getStorageKeys,
  set as setStorage,
} from 'globals/storage';
import { testableFeaturesEnabled, setTestableFlag } from 'globals/testable';
import Language from 'language';
import {
  clearLogoutStorage,
  setSubDomainCookie,
} from 'app/state/session/helpers';
import {
  sessionInvalidationErrorCodes,
  sessionInvalidationHTTPStatus,
} from 'wa-storybook/components/modal/session-invalidation-alert-modal/constants';
import { getToken } from 'state/session/helpers';
import { showToast } from 'react-components/toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';

import Spinner from 'wa-storybook/components/general/spinner/spinner';
import GoogleAnalytics from 'services/google-analytics/analytics';
import type { User } from './state/session/types';
import type { MFAData } from './pages/mfa-setup/state/types';
import { updateData } from 'pages/signup/main/state/actions';
import { mfaChallengeNames } from './pages/mfa-setup/state/constants';
import getErrorMessage from './constants/error-messages';
import { ExperimentsProvider } from 'globals/experiments/experiments';
import { AppLogger } from '@workivate/tho-web-shared';
import Button from 'wa-storybook/components/general/button/button';
import Card from 'wa-storybook/components/general/card/card';
import { sanitizeHtml } from 'wa-storybook/helpers/sanitize';

import { runOnWebWorker } from 'app/run-on-web-worker';
import './run.css';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { RouterStateProvider } from 'router/router-state-provider';
import { initiliaseWondering } from 'globals/wondering/helpers';

const container = document.getElementById('react-view');

if (!container) {
  throw new Error('Could not find the react container');
}

const renderRoot = ReactDOM.createRoot(container);

const experimentsObject = experiments;

const loginUser = user => {
  if (user['signup_state'] === SIGNUP_CODES.SSO_SIGNUP_PROVISIONED) {
    // If sso signup flow needs to be completed
    getStore().dispatch(
      updateData('initData', {
        user,
        token: getToken(),
        signup_state: user['signup_state'],
      }),
    );

    // if the user needs to complete the signup flow,
    // we need to store the original url in order to redirect the user back to the original page after signup
    setStorage(
      getStorageKeys().signupReturnUrl,
      window.location.pathname,
      false,
    );

    goTo('signup');
    return user;
  }

  setUser(user);

  return user;
};

const setCompanyDuringLogin = company => {
  CompanyActions.setCompany(company, true);
  setSubDomainCookie(company.wa_subdomain);
};

const replaceFeaturesList = () => {
  const user = getUser();

  // Replace the features list from the company with the features list from the user
  if (user['features_list'].length !== 0) {
    getStore().dispatch(updateFeaturesList(user['features_list']));
  }

  runOnWebWorker(startPostLoginFlow);
};

const handleLoginError = res => {
  const { code } = res.error || {};

  const isSessionInvalidating =
    res.status === sessionInvalidationHTTPStatus &&
    Object.values(sessionInvalidationErrorCodes).indexOf(code) > -1;

  if (isSessionInvalidating) {
    clearLogoutStorage();

    return;
  }

  getStore()
    .dispatch(logout(false))
    .then(() => {
      clearLogoutStorage();

      goTo('/');
    });
};

export const configureUserLocale = async user => {
  const localStorageLocale = Language.getInstance()?.locale;

  // When logging in with a token from a native device (EAP webview) we need to
  // use the device language, otherwise EAP can show mixed translations for users
  // where their locale does not match their device language.
  const isComingFromNative = getStore().getState().global.isComingFromNative;
  const locale = isComingFromNative
    ? navigator.language.replace('-', '_')
    : localStorageLocale || user.locale;

  // If the device language has changed since last time the native
  // user used EAP, then remove the global content flag.
  if (isComingFromNative && localStorageLocale !== locale) {
    removeFromStorage(getStorageKeys().useGlobalContent);
    removeFromStorage(getStorageKeys().usePerksGlobalContent);
    removeFromStorage(getStorageKeys().useAssessmentsGlobalContent);
  }

  return Language.set({
    locale: locale || user.locale,
    isSharedAccount: user.is_shared_account,
  });
};

const loginFromStorageToken = async () => {
  try {
    Language.loadTranslations(Language.getLocale(), true);

    const { body: userMeResponse } = await getMe();
    const authedUserResponse = loginUser(userMeResponse);
    configureUserLocale(authedUserResponse);

    const companyID = getUser().company_id;
    const { body: company } = await CompanyActions.fetchCompany(companyID);
    setCompanyDuringLogin(company);

    replaceFeaturesList();
  } catch (res) {
    return handleLoginError(res);
  }
};

export async function initPreLoginCompany() {
  // app and login subdomain will never have company details
  // so we don't need to make a request.
  if (getSubdomainFromHost() === 'app' || getSubdomainFromHost() === 'login') {
    return CompanyActions.setCompany({});
  }
  try {
    await CompanyActions.setPreLoginCompanyDetails();
  } catch {
    CompanyActions.setCompany({});
    getStore().dispatch(
      showToast({
        type: ToastType.ERROR,
        message: polyglot.t('api_errors.something_wrong'),
      }),
    );
  }
}

function renderLoadingSpinner() {
  renderRoot.render(<Spinner top='calc(50% - 240px)' />);
}

// these message are not translated because the translations are not loaded yet
// we cover English and French
function renderInitHermesError(locale = 'en_GB') {
  let errorMessage = 'Something went wrong...';
  let buttonText = 'Try again';

  const eapUrl = new URL(window.WAM.ENV.eapUrl);

  let eapMessage = `If you&apos;re in a dangerous situation or need urgent help, <a href='${eapUrl}' target='_blank' rel='noopener noreferrer'>please call us</a>. We&apos;ll help you through your current situation, or put you in touch with someone who can.`;

  if (locale === 'fr_CA' || locale === 'fr_FR') {
    errorMessage = "Une erreur s'est produite......";
    buttonText = 'Essayez de nouveau';
    eapMessage = `Si vous vous trouvez dans une situation dangereuse et avez besoin d'aide en urgence, <a href='${eapUrl}' target='_blank' rel='noopener noreferrer'>appelez-nous</a>. Nous vous aiderons à surmonter vos difficultés ou vous mettrons en contact avec quelqu'un qui pourra vous aider.`;
  }

  renderRoot.render(
    <Card className='container'>
      <p>{errorMessage}</p>
      <Button
        dataHook='try-again-button'
        onClick={() => window.location.reload()}
        text={buttonText}
      />
      <p
        className='eap-message'
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(eapMessage),
        }}
      />
    </Card>,
  );
}

const addListenerForViewportBreakpointsUpdateTrigger = store => {
  let previousFontSize = '16px';

  const viewportCheck = () => {
    const htmlElement = document.getElementsByTagName('html')[0] as HTMLElement;
    const computedFontSize = window
      .getComputedStyle(htmlElement, null)
      .getPropertyValue('font-size');

    if (computedFontSize !== previousFontSize) {
      store.dispatch(calculateResponsiveState(window));
      previousFontSize = computedFontSize;
    }
  };

  setInterval(viewportCheck, 1500);
};

export function renderHermes() {
  import(/* webpackPreload: true */ 'pages/hermes/hermes').then(
    ({ default: Hermes }) => {
      // rendering main component

      renderRoot.render(
        // @ts-ignore
        <HistoryRouter history={history}>
          <RouterStateProvider>
            <ExperimentsProvider experiments={experimentsObject}>
              <Hermes experiments={experimentsObject} />
            </ExperimentsProvider>
          </RouterStateProvider>
        </HistoryRouter>,
      );
    },
  );
}

export const beginMFASetup = (userId: string) => {
  setPersistence(true);
  setStorage(getStorageKeys().mfaSetup, { userId });
  goTo('/mfa-setup/landing');
};

export async function initHermes(params?: {
  user?: User;
  mfa?: MFAData;
  error?: {
    code: number;
  };
}) {
  const { user, mfa, error } = params || {};

  renderLoadingSpinner();

  try {
    initiliaseWondering();

    if (!isLocalStorageSupported()) {
      (window as Window).location = '/private-browsing';
      return;
    }

    if (window.WAM.ENV.maintenance) {
      (window as Window).location = '/maintenance';
      return;
    }

    // Allow testable flag to be disabled by query string.
    const { enableTestableFeatures = testableFeaturesEnabled() } =
      queryString.parse(location.search);

    setTestableFlag(JSON.parse(enableTestableFeatures as string));

    const store = initializeStore();
    store.runSaga(rootSagas);

    // Register state listeners
    createSubscription(
      [
        'session.user.anon_user_id',
        'session.user.birthday_date',
        'session.user.gender',
        'session.user.account_type',
        'session.user.originator',
        'session.user.role',
      ],
      GoogleAnalytics.setUser,
    )(store);

    createSubscription(
      [
        'company.company_id',
        'company.name',
        'company.demo',
        'company.internal',
      ],
      GoogleAnalytics.setCompanyProperties,
    )(store);

    if (mfa) {
      const { challenge_name: challengeName } = mfa;
      if (challengeName === mfaChallengeNames.MFA_SETUP) {
        const userId = user?.user_id;
        await Language.set({ shouldLoadAllTranslations: true });
        renderHermes();
        if (userId) {
          beginMFASetup(userId);
        }
        return;
      }
    }
    if (user) {
      try {
        loginUser(user);

        await configureUserLocale(user);

        // Company data from auth/mobile endpoint is incomplete, we need to fetch it from companies endpoint
        const { body: company } = await CompanyActions.fetchCompany(
          user['company_id'],
        );

        setCompanyDuringLogin(company);
        replaceFeaturesList();

        // I think this is when you have a token in the URL and when we login in the node server
        await Language.set({
          locale: user.locale,
          shouldLoadAllTranslations: true,
        });
      } catch (e) {
        handleLoginError(e);
      }
      // getToken() could return either a full wam token or a restricted wam
      // token, so we need to check if we're in the MFA flow before continuing to login
    } else if (!isEmpty(getToken()) && !getStorage(getStorageKeys().mfaSetup)) {
      // auto login from storage => update user from api, check token validity.
      await loginFromStorageToken();
    } else {
      await Language.set();

      runOnWebWorker(initPreLoginCompany);
    }

    const { lang: queryStringLocale } = queryString.parse(location.search);

    if (user && queryStringLocale && user.locale !== queryStringLocale) {
      getStore().dispatch(
        updateUser({ locale: Language.getLocale() }, false, false),
      );
    }

    Language.loadTranslations(Language.getLocale(), true);

    if (error) {
      getStore().dispatch(
        showToast({
          type: ToastType.ERROR,
          message: getErrorMessage(error.code),
        }),
      );
    }

    //set interval check for updating viewport break points on text-only zoom
    addListenerForViewportBreakpointsUpdateTrigger(store);

    renderHermes();
  } catch (error) {
    renderInitHermesError(Language.getLocale());

    AppLogger.error('Error in initHermes', error);
  }
}
